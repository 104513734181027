import { useMutation } from '@apollo/client';
import { observer } from 'mobx-react';
import { clone } from 'mobx-state-tree';
import React, { useState } from 'react';
import { toast } from 'react-toastify';
import { Dropdown, Icon, Table } from 'tabler-react';

import { TOGGLE_DISABLE_ADMIN } from '../../graphql/TOGGLE_DISABLE_ADMIN';
import UpdateAdmin from './Forms/UpdateAdmin';

interface IAdminListItemProps {
  admin: any;
}

const AdminListItem = ({ admin }: IAdminListItemProps) => {
  const [isEditing, setIsEditing] = useState<boolean>(false);

  const adminClone = clone(admin);

  const [toggleDisableAdmin] = useMutation(TOGGLE_DISABLE_ADMIN, {
    onCompleted: () =>
      toast.success(`Admin ${admin.is_disabled ? 'Disabled' : 'Enabled'}`)
  });

  const handleToggleDisableAdmin = async (adminId: string) => {
    await toggleDisableAdmin({
      variables: {
        adminId,
        is_disabled: !adminClone.is_disabled
      },
      update(_, { data: { toggleDisableAdmin } }) {
        if (toggleDisableAdmin) {
          admin.set('is_disabled', toggleDisableAdmin.is_disabled);
        }
      }
    });
  };

  return isEditing ? (
    <UpdateAdmin
      setIsEditing={setIsEditing}
      adminClone={adminClone}
      admin={admin}
    />
  ) : (
    <>
      <Table.Col>{admin.username}</Table.Col>
      <Table.Col>
        <span
          className={`status-icon ${
            admin.is_disabled ? 'bg-danger' : 'bg-success'
          }`}
        />{' '}
        {admin.is_disabled ? 'Disabled' : 'Active'}
      </Table.Col>
      <Table.Col>
        <Dropdown
          triggerContent="Actions"
          items={
            <Dropdown.Item
              className={!admin.is_disabled ? 'text-danger' : 'text-success'}
              icon={
                !admin.is_disabled
                  ? 'x-circle text-danger'
                  : 'check-circle text-success'
              }
              onClick={() => handleToggleDisableAdmin(admin.id)}
            >
              {!admin.is_disabled ? 'Disable' : 'Enable'}
            </Dropdown.Item>
          }
        />
      </Table.Col>
      <Table.Col>
        <Icon link name="edit" onClick={() => setIsEditing(true)} />
      </Table.Col>
    </>
  );
};

export default observer(AdminListItem);
