import { useMutation, useQuery } from "@apollo/client";
import React from "react";
import { toast } from "react-toastify";
import { Button, Card, Form, Grid, Icon, Text } from "tabler-react";
import { CREATE_CAMP_REGISTRATION_QUESTION } from "../../../graphql/CREATE_CAMP_REGISTRATION_QUESTION";
import { DELETE_CAMP_REGISTRATION_QUESTION } from "../../../graphql/DELETE_CAMP_REGISTRATION_QUESTION";
import { GET_REGISTRATION_QUESTIONS } from "../../../graphql/GET_REGISTRATION_QUESTIONS";
import { Types } from "../../../types/graphql";
import RegistrationQuestionsModal from "../../Team/Modals/RegistrationQuestionsModal";

interface ICalendarEventQuestionsProp {
  event: Types.Camp;
}

const CalendarEventQuestions = ({ event }: ICalendarEventQuestionsProp) => {
  const { data, loading } = useQuery(GET_REGISTRATION_QUESTIONS, {
    variables: {
      filter: {
        campId: Number(event.id),
        teamId: event.team_id,
      },
    },
  });
  const [selectedQuestion, setSelectedQuestion] = React.useState(null);
  const [isOpen, setIsOpen] = React.useState<boolean>(false);

  const [addCampRegistrationQuestion] = useMutation(
    CREATE_CAMP_REGISTRATION_QUESTION,
    {
      onCompleted: (result) => {
        if (result.addCampRegistrationQuestion) {
          toast.success("Registration question added to camp");
        } else {
          toast.error("Something went wrong while trying to add question.");
        }
      },
    },
  );

  const [removeCampRegistrationQuestion] = useMutation(
    DELETE_CAMP_REGISTRATION_QUESTION,
    {
      onCompleted: (result) => {
        if (result.removeCampRegistrationQuestion) {
          toast.success("Registration option deleted");
        } else {
          toast.error("Something went wrong while trying to delete option.");
        }
      },
    },
  );

  const optionToggle = (option, isChecked) => {
    if (isChecked) {
      addCampRegistrationQuestion({
        variables: {
          campId: Number(event.id),
          registrationQuestionId: option.id,
        },
        refetchQueries: [
          {
            query: GET_REGISTRATION_QUESTIONS,
            variables: {
              filter: {
                campId: Number(event.id),
                teamId: event.team_id,
              },
            },
          },
        ],
      });
    } else {
      if (
        window.confirm(
          "Are you sure you want to delete this question from camp: " +
            option.name,
        )
      ) {
        removeCampRegistrationQuestion({
          variables: {
            campId: Number(event.id),
            registrationQuestionId: option.id,
          },
          refetchQueries: [
            {
              query: GET_REGISTRATION_QUESTIONS,
              variables: {
                filter: {
                  campId: Number(event.id),
                  teamId: event.team_id,
                },
              },
            },
          ],
        });
      }
    }
  };

  return (
    <Card>
      <Card.Header>
        <Card.Title>Registration Questionaire</Card.Title>
        <Card.Options>
          <Button
            outline
            icon={"plus"}
            color={"primary"}
            size={"sm"}
            onClick={(e) => {
              e.preventDefault();
              setSelectedQuestion(null);
              setIsOpen(!isOpen);
            }}
          />
          <RegistrationQuestionsModal
            isModalOpen={isOpen}
            handleToggleModal={() => setIsOpen(!isOpen)}
            team_id={Number(event.team_id)}
            camp_id={Number(event.id)}
            registrationQuestion={selectedQuestion}
          />
        </Card.Options>
      </Card.Header>
      <Card.Body>
        {!loading &&
          data?.registrationQuestions.map((option) => {
            if (
              option.sport_type_id &&
              option.sport_type_id !== Number(event.sport_type_id)
            )
              return null;
            if (
              option.location_id &&
              Number(option.location_id) !== event.location_id
            )
              return null;
            return (
              <Grid.Row key={option.id}>
                <Grid.Col md={1}>
                  {option.camp_id && (
                    <Icon
                      name={"edit"}
                      className={"text-success mr-2 float-right"}
                      onClick={() => {
                        setSelectedQuestion(option);
                        setIsOpen(!isOpen);
                      }}
                    />
                  )}
                </Grid.Col>
                <Grid.Col>
                  <Form.Checkbox
                    name="status"
                    label={
                      <>
                        <Text className="">{option.name}</Text>
                        <Text.Small className="text-muted">
                          {option.description}
                        </Text.Small>
                      </>
                    }
                    checked={option?.is_exist}
                    onChange={(e) => {
                      optionToggle(option, e.target.checked);
                    }}
                  />
                </Grid.Col>
              </Grid.Row>
            );
          })}
      </Card.Body>
    </Card>
  );
};

export default CalendarEventQuestions;
