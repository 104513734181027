import React, { useMemo } from 'react';
import { Loading } from 'components/Loading';
import { useRootStore } from '../../../hooks';
import { useMutation, useQuery } from '@apollo/client';
import { GET_CAMPS as GET_CAMPS_TYPE } from '../../../graphql/types/GET_CAMPS';
import { GET_CAMPS } from '../../../graphql/GET_CAMPS';
import Modal from '../../Modal';
import { Formik } from 'formik';
import { Button } from 'tabler-react';
import { GET_GROUPS } from '../../../graphql/GET_GROUPS';
import { EDIT_GROUP } from '../../../graphql/EDIT_GROUP';
import { toast } from 'react-toastify';
import { Types } from '../../../types/graphql'

interface AddGroupToEventModalProps {
  isOpen: boolean;
  toggle: () => void;
  groupId: string;
}

const AddGroupToEventModal = ({
  groupId,
  toggle,
  isOpen
}: AddGroupToEventModalProps) => {
  const { currentCoachTeam } = useRootStore();

  const { data, loading, error } = useQuery<GET_CAMPS_TYPE>(GET_CAMPS, {
    variables: {
      filter: {
        team_id: currentCoachTeam.id,
        isDateAgnostic: true,
        isPresent: true
      }
    }
  });

  if (loading || !data) {
    return <Loading />
  }

  if (error) {
    console.log(error)
    return <p>Error: {error.message}</p>
  }

  return <AddGroupToEventModalInner groupId={groupId} toggle={toggle} isOpen={isOpen} camps={data.getCamps as Types.Camp[]} />
}

const AddGroupToEventModalInner = ({
  groupId,
  toggle,
  isOpen,
  camps
}: {
  isOpen: boolean,
  toggle: () => void,
  groupId: string,
  camps: Types.Camp[]
}) => {
  const { currentCoachTeam } = useRootStore();
  const [addGroupToCamp] = useMutation(EDIT_GROUP, {
    onCompleted: () => {
      toast.success('Group added to event');
    }
  });

  const eventRows = Array.from(camps).sort((a, b) => {
    const da = new Date(a.start)
    const db = new Date(b.start)

    return da > db ? -1 : 1;
  })

  const renderForm = (formikData) => {
    return (
      <div>
        <div className="modal-body">
          <div className="form-group">
            <select
              id="event"
              name="camp_id"
              className="form-control"
              onChange={formikData.handleChange}
              value={formikData.values.camp_id}
            >
              <option value="">Select Event</option>
              {eventRows
                .filter((event) => new Date(event.end) > new Date())
                .reverse()
                .map((event) => (
                  <option key={event.id} value={event.id}>
                    {event.camp_name}
                  </option>
                ))}
            </select>
            <span className={'text-danger'}>
              {formikData.errors.camp_id &&
                formikData.touched.camp_id &&
                formikData.errors.camp_id}
            </span>
          </div>
        </div>
        <div className="border-0 modal-footer">
          <Button pill size={'sm'} color={'white'} onClick={toggle}>
            CANCEL
          </Button>
          <Button
            pill
            size="sm"
            color="gray-dark"
            onClick={formikData.handleSubmit}
          >
            MOVE GROUP
          </Button>
        </div>
      </div>
    );
  };
  return (
    <Modal
      content={
        <Formik
          enableReinitialize={true}
          initialValues={{
            group_id: groupId,
            camp_id: ''
          }}
          onSubmit={(values, { setFieldError }) => {
            if (!values.camp_id) {
              setFieldError('camp_id', 'Please select an event');
              return;
            }
            addGroupToCamp({
              variables: {
                updateGroupInput: {
                  id: values.group_id,
                  camp_id: values.camp_id
                }
              },
              refetchQueries: [
                {
                  query: GET_GROUPS,
                  variables: {
                    filter: {
                      camp_id: null,
                      team_id: currentCoachTeam.id,
                      status: 'Active'
                    }
                  }
                },
                {
                  query: GET_GROUPS,
                  variables: {
                    filter: {
                      camp_id: Number(values.camp_id),
                      team_id: currentCoachTeam.id,
                      status: 'Active'
                    }
                  }
                }
              ]
            });
          }}
        >
          {(formikData) => renderForm(formikData)}
        </Formik>
      }
      open={isOpen}
      onClose={toggle}
      title={'Move Group to an Event'}
    />
  );
};

export default AddGroupToEventModal;
