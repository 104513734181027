import JobList from 'components/Jobs/JobList';
import React from 'react';

const RiggerHome = () => {
  return (
    <div className="container">
      <JobList />
    </div>
  );
};

export default RiggerHome;
