import { observer } from 'mobx-react';
import React, { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { Alert, Card, Dimmer } from 'tabler-react';

import { locationStoreContext } from '../../contexts/LocationStoreContext';
import UpdateLocation from './Form/UpdateLocation';
import { GET_LOCATION_BY_ID } from '../../graphql/GET_LOCATION_BY_ID';
import { useQuery } from '@apollo/client';
import { clone } from 'mobx-state-tree';

const LocationDetails = () => {
  const locationStore = useContext(locationStoreContext);
  const { locationId }: any = useParams();
  const [location, setLocation] = useState(null);
  const { loading, data } = useQuery(GET_LOCATION_BY_ID, {
    variables: {
      id: locationId
    }
  });

  useEffect(() => {
    if (!loading && data.location) {
      locationStore.storeLocation(data.location);
      setLocation(locationStore.getLocationById(locationId));
    }
     
  }, [loading, data]);

  const locationClone = location ? clone(location) : null;

  return (
    <Dimmer active={loading} loader={loading}>
      {location ?
        <Card statusColor='blue'>
          <UpdateLocation location={location} locationClone={locationClone} />
        </Card> :
        <Alert type='danger'>{`location with id: ${locationId} not found`}</Alert>
      }
    </Dimmer>
  );
};

export default observer(LocationDetails);
