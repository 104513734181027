import React from 'react';
import { Link } from 'react-router-dom';
import { Card, Grid, Icon } from 'tabler-react';

import { useRootStore } from '../../hooks/useRootStore';
import RecentCoachListLItem from './RecentCoachListItem';
import { observer } from 'mobx-react';

const RecentCoachList = () => {
  const rootStore = useRootStore();
  const { recentCoaches } = rootStore;

  return (
    <Grid.Col md={6} lg={4} xs={12} className="grid-col mb-0">
      <Card>
        <Card.Header>
          <Card.Title>
            <Icon name="users" className="mr-2 ml-0 text-success" />
            <Link to="/admin/coach-list">Team Accounts (coaches)</Link>
          </Card.Title>
        </Card.Header>
        <Card.Body>
          <ul className="list-unstyled list-separated">
            {recentCoaches.map((coach) => (
              <RecentCoachListLItem coach={coach} key={coach.id} />
            ))}
          </ul>
        </Card.Body>
      </Card>
    </Grid.Col>
  );
};

export default observer(RecentCoachList);
