import React from 'react';
import { Button, Dropdown, Text } from 'tabler-react';
import { toast } from 'react-toastify';
import { useMutation } from '@apollo/client';
import { UPDATE_JOB } from '../../graphql/UPDATE_JOB';
import { GET_JOB_LIST } from '../../graphql/GET_JOB_LIST';
import { useRootStore } from '../../hooks';
import { jobsStatusList } from './JobList';
import useGetCurrentUserType from '../../modules/common/hooks/useGetCurrentUserType';

const priorityColor = {
  Low: 'btn-white text-muted',
  Normal: 'btn-white text-info',
  High: 'btn-pink',
  RUSH: 'btn-danger'
};
const JobPrioritySelectForm = ({ job }) => {
  const rootStore = useRootStore();
  const { currentCoachTeam } = rootStore;
  const { isAdmin } = useGetCurrentUserType();
  
  const priorityList = [
    {
      value: <Text className={'text-muted'}>Low</Text>,
      onClick: () => statusHandleClick('Low')
    },
    {
      value: <Text className={'text-info'}>Normal</Text>,
      onClick: () => statusHandleClick('Normal')
    },
    {
      value: <Text className={'text-pink'}>High</Text>,
      onClick: () => statusHandleClick('High')
    },
    {
      value: <Text className={'text-danger'}>RUSH</Text>,
      onClick: () => statusHandleClick('RUSH')
    }
  ];

  const filterOption = !isAdmin ? { team_id: currentCoachTeam?.id } : {};

  const [updateJob] = useMutation(UPDATE_JOB);
  const statusHandleClick = async (priority) => {
    await updateJob({
      variables: {
        updateJobInput: {
          id: job.id,
          priority: priority
        }
      },
      refetchQueries: [
        {
          query: GET_JOB_LIST,
          variables: {
            jobListFilter: {
              status: Object.keys(jobsStatusList).map((stat) => {
                if (jobsStatusList[stat].adminOnly && !isAdmin)
                  return undefined;
                return stat;
              }), //Object.keys(jobsStatusList),
              order: 'desc',
              ...filterOption
            }
          }
        }
      ]
    });
    toast.success(`Priority updated to: ${priority}`);
  };

  return (
    <Dropdown
      toggle={false}
      className={'ml-2'}
      triggerContent={<Button icon="alert-circle" className={`btn-sm ${priorityColor[job.priority]}`} />}
      size={1}
      itemsObject={
        priorityList
      }
    />
  );
};

export default JobPrioritySelectForm;
