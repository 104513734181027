import { values } from 'mobx';
import { cast, Instance, SnapshotIn, types } from 'mobx-state-tree';

const Product = types
  .model({
    amount: types.optional(types.number, 0, [null]),
    created_on: types.optional(types.string, '', [null]),
    currency: types.optional(types.string, '', [null]),
    description: types.optional(types.string, '', [null]),
    id: types.optional(types.number, 0, [null]),
    image: types.optional(types.string, '', [null]),
    status: types.optional(types.string, '', [null]),
    team_id: types.optional(types.number, 0, [null]),
    title: types.optional(types.string, '', [null]),
    quantity: types.optional(types.string, '', [null]),
    product_type_id: types.optional(types.string, '', [null]),
    account_type_id: types.optional(types.number, 0, [null]),
    code: types.optional(types.string, '', [null])
  })
  .actions((self) => ({
    set<
      K extends keyof SnapshotIn<typeof self>,
      T extends SnapshotIn<typeof self>
    >(key: K, value: T) {
      self[key] = cast(value);
    }
  }));

export const ProductStoreDefault = () => {
  return ProductStore.create({
    products: undefined
  });
};

export const ProductStore = types
  .model({
    products: types.optional(types.array(Product), [])
  })
  .views((self) => ({
    getProductById(productId: number) {
      return values(self.products).find((product) => product.id === productId);
    }
  }))
  .actions((self) => ({
    addProduct(newProduct) {
      self.products.push(newProduct);
    },
    sortProducts() {
      const sortedProducts = self.products
        .slice()
        .sort((productA, productB) =>
          productA.title.localeCompare(productB.title)
        );
      self.products.replace(sortedProducts);
    },
    deleteProduct(id: number) {
      self.products.splice(
        self.products.findIndex((e) => e.id === id),
        1
      );
    }
  }));

export interface ProductStoreType extends Instance<typeof ProductStore> {}
