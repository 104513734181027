import { useEffect, useState } from "react";

function UseBrowserWidth() {
  const [width, setWidth] = useState(window.innerWidth);

  useEffect(() => {
    function handleResize() {
      setWidth(window.innerWidth);
    }

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return width;
}

export function UseIsMobile() {
  return UseBrowserWidth() < 768;
}

export default UseBrowserWidth;
