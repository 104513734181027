import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import React, { useEffect, useRef, useState } from 'react';
import AsyncCreatableSelect from 'react-select/async-creatable';
import { toast } from 'react-toastify';
import { ADD_SEAL } from '../../../graphql/ADD_SEAL';
import { GET_SEALS_SEARCH } from '../../../graphql/GET_SEALS_SEARCH';
import { GET_SEAL_BY_NAME } from '../../../graphql/GET_SEAL_BY_NAME';
import { TEAM_DETAILS_QUERY } from '../../../graphql/TEAM_DETAILS_QUERY';
import { useRootStore } from '../../../hooks';
import { useAutocomplete } from '../../../hooks/useAutocomplete';
import formatSeal from '../../../utils/formatSeal';
import autoCompleteStyles from '../../Students/Forms/autocompleteStyles';
import { EUserTypes } from '../../User/userTypes';

interface SealSearchFormProps {
  setValue: any;
  defaultValue: any;
}

const SealSearchForm = ({ setValue, defaultValue }: SealSearchFormProps) => {
  const { currentUser, currentCoachTeam } = useRootStore();
  const [currentTeamCoaches, setCurrentTeamCoaches] = useState([]);
  const [currentSeal, setCurrentSeal] = useState(defaultValue);
  const [sealError, setSealError] = useState({ error: false, message: '' });
  const sealRef = useRef(null);

  const [addSeal] = useMutation(ADD_SEAL);

  const { data: dataCoaches } = useQuery(TEAM_DETAILS_QUERY, {
    variables: {
      team_id: currentCoachTeam?.id ?? 0
    }
  });

  useEffect(() => {
    if (dataCoaches?.geCoachesByTeamId) {
      setCurrentTeamCoaches(
        dataCoaches.geCoachesByTeamId
          .map((coach) => coach.is_active && coach.id)
          .filter(Boolean)
      );
    }
  }, [dataCoaches]);

  const options =
    currentUser.type === EUserTypes.rigger.toString()
      ? {
          filter: {
            field: 'coach_id',
            value: currentTeamCoaches
          }
        }
      : {};

  const { loadOptions: loadSeals } = useAutocomplete({
    query: GET_SEALS_SEARCH,
    options: options
  });

  const [findSeal] = useLazyQuery(GET_SEAL_BY_NAME, {
    onCompleted: async (result) => {
      if (!result.sealByName) {
        const newOption = await addSeal({
          variables: {
            addSealInput: {
              rigger_seal: sealRef.current
            }
          }
        });
        setValue('user_rigger_seal_id', newOption?.data?.addSeal?.id);
        setCurrentSeal(newOption?.data?.addSeal);
        setSealError({ error: false, message: '' });
        toast.success(`Seal ${sealRef.current} added successfully!`);
      } else {
        setSealError({
          error: true,
          message: `${sealRef.current} already exists`
        });
      }
    }
  });

  const handleCreate = async (inputValue: string) => {
    sealRef.current = inputValue;
    const pattern = /^([a-z0-9]){9,}$/i;
    if (pattern.test(inputValue)) {
      setSealError({ error: false, message: '' });
      findSeal({
        variables: {
          filter: inputValue
        }
      });
    } else {
      setSealError({
        error: true,
        message: `Seal should be at least 9 alphanumeric characters: ${sealRef.current}`
      });
    }
  };

  return (
    <>
      <AsyncCreatableSelect
        backspaceRemovesValue={true}
        className="input-group"
        components={{
          DropdownIndicator: () => null,
          IndicatorSeparator: () => null,
          LoadingIndicator: () => null
        }}
        defaultValue={null}
        escapeClearsValue={true}
        getOptionLabel={(option: any) => {
          if (option.rigger_seal)
            return (
              <>
                {option.coach?.first_name} {option.coach?.last_name}
                <span className="text-muted">
                  {' '}
                  {formatSeal(option.rigger_seal)}
                </span>
              </>
            );

          return option.label;
        }}
        getOptionValue={(option: any) => option.id}
        isClearable={true}
        defaultOptions
        loadOptions={loadSeals}
        name="user_rigger_seal_id"
        onChange={(e) => {
          if (e) {
            setValue('user_rigger_seal_id', e.id);
            setCurrentSeal(e);
          }
        }}
        value={currentSeal}
        onCreateOption={handleCreate}
        placeholder="Cert. No./Seal"
        styles={autoCompleteStyles}
      />
      <span className="field-error text-danger">
        {sealError.error && sealError.message}
      </span>
    </>
  );
};

export default SealSearchForm;
