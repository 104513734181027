import { useMutation } from "@apollo/client";
import {
  countries,
  locationTypeOptions,
  objectTypeOptions,
} from "components/constants";
import FormField from "components/FormField";
import { Formik } from "formik";
import { GET_LOCATIONS } from "graphql/GET_LOCATIONS";
import { UPDATE_LOCATION } from "graphql/UPDATE_LOCATION";
import { observer } from "mobx-react";
import { applySnapshot, getSnapshot } from "mobx-state-tree";
import autocompleteStyles from "modules/common/styles/autocompleteStyles";
import React from "react";
import PhoneInput from "react-phone-input-2";
import Select from "react-select";
import { toast } from "react-toastify";
import { Button, Card, Form, Grid, Icon } from "tabler-react";
import useReactRouter from "use-react-router";
import * as Yup from "yup";

const updateLocationValidationSchema = Yup.object().shape({
  name: Yup.string().required("This field is required."),
  description: Yup.string().required("This field is required."),
});

export enum EInputName {
  chamber_diameter = "chamber_diameter",
  chamber_height = "chamber_height",
  country = "country",
  is_frozen = "is_frozen",
  phone = "phone",
  object_type = "object_type",
  open_since = "open_since",
  status = "status",
  type = "type",
}

interface IUpdateLocationProps {
  location: any;
  locationClone: any;
}

const UpdateLocation = ({ location, locationClone }: IUpdateLocationProps) => {
  const { history } = useReactRouter();
  const [updateLocation] = useMutation(UPDATE_LOCATION, {
    refetchQueries: ["GET_LOCATIONS"],
  });

  const mstHandleChange = (e, inputName = "") => {
    switch (inputName) {
      case EInputName.is_frozen:
        locationClone.set(EInputName.is_frozen, e.target.checked);
        break;

      case EInputName.object_type:
        locationClone.set(EInputName.object_type, e.value);
        break;

      case EInputName.country:
        locationClone.set(EInputName.country, e.name);
        break;

      case EInputName.type:
        locationClone.set(EInputName.type, e.value);
        break;

      case EInputName.chamber_diameter:
        locationClone.set(
          EInputName.chamber_diameter,
          parseFloat(e.target.value),
        );
        break;

      case EInputName.chamber_height:
        locationClone.set(
          EInputName.chamber_height,
          parseFloat(e.target.value),
        );
        break;

      default:
        locationClone.set(e.target.name, e.target.value);
        break;
    }
  };

  return (
    <Formik
      enableReinitialize={true}
      initialValues={{
        id: locationClone.id,
        name: locationClone.name,
        description: locationClone.description,
        phone: locationClone.phone,
        phone_country_code: locationClone.phone_country_code,
        email: locationClone.email,
        address: locationClone.address,
        country: locationClone.country,
        city: locationClone.city,
        province: locationClone.province,
        postal_code: locationClone.postal_code,
        locality: locationClone.locality,
        region: locationClone.region,
        latitude: locationClone.latitude,
        longitude: locationClone.longitude,
        is_frozen: locationClone.is_frozen,
        type: locationClone.type,
        object_type: locationClone.object_type,
        tunnel_type: locationClone.tunnel_type,
        manufacturer: locationClone.manufacturer,
        chamber_diameter: locationClone?.chamber_diameter || undefined,
        chamber_height: locationClone?.chamber_height || undefined,
        open_since: locationClone.open_since,
        url: locationClone.url,
      }}
      validationSchema={updateLocationValidationSchema}
      onSubmit={async (values, { setSubmitting }) => {
        await updateLocation({
          // update: (cache, { data: { updateLocation } }) => {
          //   if (!updateLocation) {
          //     return cache;
          //   }
          //
          //   const getLocationsQuery = {
          //     query: GET_LOCATIONS,
          //     variables: {
          //       order: ELocationOrder.name,
          //       filter: {
          //         type: location.type,
          //         is_frozen: false,
          //         limit: DEFAULT_LIMIT,
          //       },
          //       page: DEFAULT_PAGE,
          //     },
          //   };
          //
          //   const { locations } =
          //     cache.readQuery<GET_LOCATIONS_TYPE>(getLocationsQuery);
          //
          //   return cache.writeQuery({
          //     data: {
          //       locations: { ...locations, updateLocation },
          //     },
          //     ...getLocationsQuery,
          //   });
          // },

          variables: {
            location: {
              ...values,
              open_since: locationClone.open_since || null,
            },
          },
        });

        applySnapshot(location, getSnapshot(locationClone));
        toast.success("Location Updated.");
        setSubmitting(false);
      }}
    >
      {({
        values,
        errors,
        touched,
        isSubmitting,
        handleSubmit,
        setFieldValue,
      }) => (
        <form onSubmit={handleSubmit}>
          <Card.Header className="pl-3">
            <Card.Title>
              <Icon name="map-pin" className={`mr-2 ml-0 text-success`} />
              {location.name || "ID Not Found"}
            </Card.Title>
            <Card.Options>
              <Form.Group className="mb-0 mt-2">
                <Form.Switch
                  type="checkbox"
                  name="is_frozen"
                  onChange={(e) => mstHandleChange(e, EInputName.is_frozen)}
                  checked={values.is_frozen}
                  value={values.is_frozen}
                  label="Frozen"
                />
              </Form.Group>
            </Card.Options>
          </Card.Header>
          <Card.Body>
            <Grid.Row>
              <Grid.Col>
                <Form.Group label="Name">
                  <Form.Input
                    name="name"
                    onChange={mstHandleChange}
                    placeholder="Name"
                    value={values.name}
                  />
                  <span className="field-error text-danger">
                    {errors.name && touched.name && errors.name}
                  </span>
                </Form.Group>
              </Grid.Col>
            </Grid.Row>
            <Grid.Row>
              <Grid.Col>
                <Form.Group label="Description">
                  <Form.Textarea
                    name="description"
                    onChange={mstHandleChange}
                    placeholder="description"
                    rows={8}
                    value={values.description || ""}
                  />
                  <span className="field-error text-danger">
                    {errors.description &&
                      touched.description &&
                      errors.description}
                  </span>
                </Form.Group>
              </Grid.Col>
            </Grid.Row>
            <Grid.Row>
              <Grid.Col>
                <FormField
                  label="Email"
                  placeholder="Email"
                  name="email"
                  type="text"
                  value={values.email}
                  onChange={mstHandleChange}
                />
              </Grid.Col>
              <Grid.Col>
                <Form.Group label="Phone number">
                  <PhoneInput
                    inputProps={{
                      className: "form-control w-100",
                      name: "phone",
                      required: true,

                      onChange: (e) => {
                        e.preventDefault();
                        const target = e.target;
                        const phone = target.value.split(" ");
                        const countryCode = phone.shift().replace(/\D/g, "");
                        const phoneNumber = phone.join("").replace(/\D/g, "");
                        setFieldValue("phone", phoneNumber);
                        setFieldValue("phone_country_code", countryCode);
                      },
                    }}
                    value={`${values.phone_country_code}${values.phone}`}
                  />
                </Form.Group>
              </Grid.Col>
            </Grid.Row>
            <Grid.Row>
              <Grid.Col>
                <FormField
                  label="URL"
                  placeholder="URL"
                  name="url"
                  type="text"
                  value={values.url}
                  onChange={mstHandleChange}
                />
              </Grid.Col>
            </Grid.Row>
            <Grid.Row>
              <Grid.Col>
                <FormField
                  label="Address"
                  placeholder="Address"
                  name="address"
                  type="text"
                  value={values.address}
                  onChange={mstHandleChange}
                />
              </Grid.Col>
              <Grid.Col>
                <FormField
                  label="City"
                  placeholder="City"
                  name="city"
                  type="text"
                  value={values.city}
                  onChange={mstHandleChange}
                />
              </Grid.Col>
            </Grid.Row>
            <Grid.Row>
              <Grid.Col lg="6" sm="12">
                <FormField
                  label="Province"
                  placeholder="Province"
                  name="province"
                  type="text"
                  value={values.province}
                  onChange={mstHandleChange}
                />
              </Grid.Col>
              <Grid.Col lg="3" sm="6">
                <FormField
                  label="Postal code"
                  placeholder="Postal code"
                  name="postal_code"
                  type="text"
                  value={values.postal_code}
                  onChange={mstHandleChange}
                />
              </Grid.Col>
            </Grid.Row>
            <Grid.Row>
              <Grid.Col lg="6" sm="12">
                <Form.Group>
                  <Form.Label>Country</Form.Label>
                  <Select
                    backspaceRemovesValue={true}
                    escapeClearsValue={true}
                    getOptionLabel={(option: { name: string }) => option.name}
                    getOptionValue={(option: { name: string }) => option.name}
                    isClearable={true}
                    name="country"
                    options={countries}
                    onChange={(e) => mstHandleChange(e, "country")}
                    styles={autocompleteStyles}
                    value={
                      values.country &&
                      countries[
                        Object.keys(countries).find(
                          (key) => countries[key].name === values.country,
                        )
                      ]
                    }
                  />
                </Form.Group>
              </Grid.Col>
            </Grid.Row>
            <Grid.Row>
              <Grid.Col lg="6" sm="12">
                <FormField
                  label="Region"
                  placeholder="Region"
                  name="region"
                  type="text"
                  value={values.region}
                  onChange={mstHandleChange}
                />
              </Grid.Col>
              <Grid.Col lg="6" sm="12">
                <FormField
                  label="Locality"
                  placeholder="Locality"
                  name="locality"
                  type="text"
                  value={values.locality}
                  onChange={mstHandleChange}
                />
              </Grid.Col>
            </Grid.Row>
            <Grid.Row className="mt-7">
              <Grid.Col lg="3" sm="6">
                <FormField
                  label="Latitude"
                  placeholder=""
                  disabled
                  name="latitude"
                  type="text"
                  value={values.latitude}
                  onChange={mstHandleChange}
                />
              </Grid.Col>
              <Grid.Col lg="3" sm="6">
                <FormField
                  label="Longitude"
                  placeholder=""
                  disabled
                  name="longitude"
                  type="text"
                  value={values.longitude}
                  onChange={mstHandleChange}
                />
              </Grid.Col>
            </Grid.Row>
            <Grid.Row className="mt-7">
              <Grid.Col lg="3" sm="6">
                <Form.Group label="Type">
                  <Select
                    name="type"
                    options={locationTypeOptions}
                    onChange={(value) =>
                      mstHandleChange(value, EInputName.type)
                    }
                    value={
                      values.type &&
                      locationTypeOptions[
                        Object.keys(locationTypeOptions).find(
                          (key) =>
                            locationTypeOptions[key].value === values.type,
                        )
                      ]
                    }
                  />
                </Form.Group>
              </Grid.Col>
              {values.type === "base" && (
                <Grid.Col lg="3" sm="6">
                  <Form.Group label="Object type">
                    <Select
                      name="object_type"
                      options={objectTypeOptions}
                      onChange={(value) =>
                        mstHandleChange(value, EInputName.object_type)
                      }
                      value={
                        values.object_type &&
                        objectTypeOptions[
                          Object.keys(objectTypeOptions).find(
                            (key) =>
                              objectTypeOptions[key].value ===
                              values.object_type,
                          )
                        ]
                      }
                    />
                  </Form.Group>
                </Grid.Col>
              )}
            </Grid.Row>
            {values.type === "tunnel" && (
              <Grid.Row>
                <Grid.Col>
                  <FormField
                    label="Tunnel type"
                    placeholder="Tunnel type"
                    name="tunnel_type"
                    type="text"
                    value={values.tunnel_type}
                    onChange={mstHandleChange}
                  />
                </Grid.Col>
                <Grid.Col>
                  <FormField
                    label="Manufacturer"
                    placeholder="Manufacturer"
                    name="manufacturer"
                    type="text"
                    value={values.manufacturer}
                    onChange={mstHandleChange}
                  />
                </Grid.Col>
                <Grid.Col>
                  <FormField
                    label="Chamber diameter"
                    placeholder="Chamber diameter"
                    name="chamber_diameter"
                    type="number"
                    value={values.chamber_diameter}
                    onChange={(e) =>
                      mstHandleChange(e, EInputName.chamber_diameter)
                    }
                  />
                </Grid.Col>
                <Grid.Col>
                  <FormField
                    label="Chamber height"
                    placeholder="Chamber height"
                    name="chamber_height"
                    type="number"
                    value={values.chamber_height}
                    onChange={(e) =>
                      mstHandleChange(e, EInputName.chamber_height)
                    }
                  />
                </Grid.Col>
                <Grid.Col>
                  <Form.Group label="Open since">
                    <input
                      className="form-control"
                      type="date"
                      name="open_since"
                      value={values.open_since}
                      onChange={mstHandleChange}
                      required={true}
                    />
                  </Form.Group>
                </Grid.Col>
              </Grid.Row>
            )}
          </Card.Body>
          <Card.Footer>
            <Button.List align="right">
              <Button
                pill
                color="white"
                size="sm"
                onClick={(e) => {
                  e.preventDefault();
                  history.goBack();
                }}
              >
                CANCEL
              </Button>
              <Button
                disabled={isSubmitting}
                loading={isSubmitting}
                pill
                color="gray-dark"
                size="sm"
                type="submit"
              >
                UPDATE
              </Button>
            </Button.List>
          </Card.Footer>
        </form>
      )}
    </Formik>
  );
};

export default observer(UpdateLocation);
