import gql from 'graphql-tag';

export const ADD_USER_EVENT = gql`
  mutation ADD_USER_EVENT($userEvent: AddUserEventInput!) {
    addUserEvent(userEvent: $userEvent) {
      camp_id
      camp_slot_id
      load_id
      location_id
      status
      team_id
      user_events_id
      user_event_type_id
      student_id
      created_on
    }
  }
`;
