import React from 'react';
import { Button } from 'tabler-react';
import useRouter from 'use-react-router';

const PageNoAccess = (props: any) => {
  const { history } = useRouter();
  return (
    <>
      <div className="page">
        <div className="page-single">
          <div className="container">
            <div className="row">
              <div className="col mx-auto">
                <div className="text-center mb-6">
                  <h1 className="mb-5 display-1 text-muted">401</h1>
                  <h2 className="mb-4">Unauthorized Access</h2>
                  <h4 className="mb-4 text-muted">
                    You are not authorized to access this page.
                  </h4>
                  <Button.List align="center">
                    <Button
                      icon="chevron-left"
                      color="primary"
                      className=""
                      onClick={() => history.push(`/`)}
                    >
                      GO HOME
                    </Button>
                  </Button.List>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PageNoAccess;
