import gql from 'graphql-tag';

export const GET_PRODUCTS_BY_FILTER_QUERY = gql`
    query GET_PRODUCTS_BY_FILTER_QUERY(
        $order: OrderOptions
        $filter: ProductFilter
    ) {
        products(order: $order, filter: $filter) {
            id
            account_type_id
            amount
            code
            created_on
            currency
            description
            image
            product_type_id
            quantity
            status
            team_id
            title
            total
        }

        productsTotal(filter: $filter){
            total
        }

        countProductsStatus(filter: $filter){
            status
            status_count
        }

        countProductsType(filter: $filter){
            product_type_id
            type_count
        }
    }
`;
