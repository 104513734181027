import { observer } from 'mobx-react';
import React, { useMemo } from 'react';
import { Container } from 'tabler-react';
import Wrapper from '../layout';
import RiggerJobsList from '../../components/Jobs/RiggerJobsList';
import { useParams } from 'react-router';
import { useRootStore } from '../../hooks';

const AdminRiggerJobsListPage = (props) => {
  const { riggerId } = useParams<{ riggerId: string }>();
  const rootStore = useRootStore();
  const coach = useMemo(() => {
    const coachItem = rootStore.getCoachById(Number(riggerId));
    return coachItem;
  }, [rootStore, riggerId]);
  return (
    <Wrapper {...props} title="Rigger Jobs">
      <Container>
        <RiggerJobsList riggerId={Number(riggerId)} rigger={coach} />
      </Container>
    </Wrapper>
  );
};
export default observer(AdminRiggerJobsListPage);
