import { useQuery } from '@apollo/client';
import React, { useEffect, useState } from 'react';
import { Card, Container, Dropdown, Grid } from 'tabler-react';

import { GET_TEAMS } from '../../graphql/GET_TEAMS';
import Wrapper from '../layout';
import { ProductStoreProvider } from '../../contexts/productContext';
import ProductsListAdmin from '../../components/Product/ProductListAdmin';
import { Types } from '../../types/graphql'

interface IselectedTeamState {
  team_id: string;
  team_name: string;
}

const DEFAULT_SELECTED_TEAMS = {
  team_id: '',
  team_name: ''
};

const ProductListPage = (props) => {
  const [teamsOptions, setTeamsOptions] = useState<Types.Team[]>([]);
  const [selectedTeam, setSelectedTeam] = useState<IselectedTeamState>(
    DEFAULT_SELECTED_TEAMS
  );
  const { loading, error, data } = useQuery(GET_TEAMS, {
    variables: {
      filter: { isPublic: true }
    }
  });

  useEffect(() => {
    if (!loading && data) {
      setTeamsOptions(data.teams);
    }
  }, [data]);  

  if (error) {
    return <p>Error: {`Error: ${error.message}`}</p>;
  }

  return (
    <Wrapper {...props} title="Product & Services">
      <ProductStoreProvider>
        <Container>
          <Card>
            <Card.Header>
              <Card.Title>Manage Products</Card.Title>
              <Card.Options>
                <Dropdown
                  arrow={true}
                  arrowPosition="right"
                  className="mr-3"
                  color="primary"
                  position="bottom-end"
                  size="sm"
                  type="button"
                  triggerContent={`Filter By Team: ${
                    selectedTeam?.team_name || 'All'
                  }`}
                  items={
                    <>
                      <Dropdown.Item
                        className="cursor-pointer m-0"
                        onClick={(e) => {
                          e.preventDefault();

                          setSelectedTeam(DEFAULT_SELECTED_TEAMS);
                        }}
                      >
                        All
                      </Dropdown.Item>
                      {teamsOptions.map((team) => (
                        <Dropdown.Item
                          key={team.id}
                          className="cursor-pointer m-0"
                          onClick={(e) => {
                            e.preventDefault();

                            setSelectedTeam({
                              team_id: team.id.toString(),
                              team_name: team.name
                            });
                          }}
                        >
                          {team.name}
                        </Dropdown.Item>
                      ))}
                    </>
                  }
                />
              </Card.Options>
            </Card.Header>
            <Card.Body>
              <Grid.Row>
                <Grid.Col>
                  <ProductsListAdmin team_id={parseInt(selectedTeam.team_id)} />
                </Grid.Col>
              </Grid.Row>
            </Card.Body>
          </Card>
        </Container>
      </ProductStoreProvider>
    </Wrapper>
  );
};

export default ProductListPage;
