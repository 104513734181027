import { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import axios, { AxiosResponse } from 'axios';
import gql from 'graphql-tag';

// set default to use credentials
axios.defaults.withCredentials = true;

export interface MeResponse {
  id?: number;
  user_id?: number;
  username?: string;
  type: string;
  email: string;
  first_name: string;
  last_name: string;
  phone_country_code: number;
  phone_number: string;
  profile_avatar: string;
  profile_picture: string;
  token?: string;
  email_private?: number;
  phone_private?: number;
  weight?: number;
}

export interface ServerResponse<T> {
  error?: string;
  data?: T;
}

export interface API {
  queryMe(): Promise<AxiosResponse<MeResponse>>;
  queryMeGraphQL(): Promise<ServerResponse<MeResponse>>;
}

class APIImpl implements API {
  client: ApolloClient<NormalizedCacheObject>

  constructor(client: ApolloClient<NormalizedCacheObject>) {
    this.client = client;
  }

  queryMe(): Promise<AxiosResponse<MeResponse>> {
    return axios.request<MeResponse>({
      method: 'GET',
      url: '/auth/me'
    });
  }

  logout(): Promise<AxiosResponse> {
    return axios.request({
      method: 'GET',
      url: '/auth/logout'
    });
  }

  queryMeGraphQL(): Promise<ServerResponse<MeResponse>> {
    return this.client
      .query({
        query: gql`
          query TEST {
            me {
              id
              first_name
              last_name
              email
              type
            }
          }
        `
      })
      .then(
        (result) => {
          const me: MeResponse = result.data.me;
          const r: ServerResponse<MeResponse> = {
            data: me
          };
          return r;
        },
        (reason) => {
          const r: ServerResponse<MeResponse> = { error: reason };
          return r;
        }
      );
  }
}

//class APITestImpl implements API {
//queryMe() {
//return new Promise<MeResponse>((resolve, reject) => {
//const me: MeResponse = {
//user_id: 0,
//type: 'test',
//email: 'test'
//}
//resolve(me)
//})
//}
//}

export const getAPI = (client) => {
  return new APIImpl(client);
};

//export const getTestAPI = () => {
//return new APITestImpl()
//}
