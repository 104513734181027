import React, { Fragment } from 'react';
import { formatMoney } from '../../utils/numberFormat';
import { Button, Tag } from 'tabler-react';
import CompensationTypeModalForm from './Forms/CompensationTypeModalForm';
import { toast } from 'react-toastify';
import { DELETE_COMPENSATION_TYPE } from '../../graphql/DELETE_COMPENSATION_TYPE';
import { useMutation } from '@apollo/client';
import { Types } from '../../types/graphql'

interface CompensationTypeListItemProps {
  compensationType: Types.CompensationType
}

const CompensationTypeListItem = ({
  compensationType
}: CompensationTypeListItemProps) => {
  const [isOpen, setIsOpen] = React.useState(false);
  const [deleteCompensationType] = useMutation(DELETE_COMPENSATION_TYPE, {
    refetchQueries: ['GET_COMPENSATION_TYPE_LIST'],
    onCompleted: () => {
      toast.success('Compensation Type Deleted');
    }
  });
  const handleDelete = () => {
    if (
      window.confirm('Are you sure you want to delete this compensation type?')
    ) {
      deleteCompensationType({
        variables: {
          id: compensationType.id
        }
      });
    }
  };
  return (
    <Fragment>
      <p className="mb-0">
        <Button
          icon={'x-circle'}
          className={'text-danger float-right'}
          color={'white'}
          onClick={handleDelete}
        />
        <strong
          className={'text-primary cursor-pointer'}
          onClick={() => setIsOpen(true)}
        >
          {compensationType.name}
        </strong>{' '}
      </p>
      <Tag>{compensationType.loadAssociationTypeName}</Tag>{' '}
      <Tag>{compensationType.roleName}</Tag>{' '}
      {formatMoney(Number(compensationType.amount))}{' '}
      <hr className="mx-0 my-1" />
      <CompensationTypeModalForm
        toggleModal={() => setIsOpen(false)}
        isOpen={isOpen}
        compensationType={compensationType}
      />
    </Fragment>
  );
};

export default CompensationTypeListItem;
