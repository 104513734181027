import { applySnapshot, onSnapshot } from 'mobx-state-tree';
import React, { createContext, ReactNode, useState } from 'react';

import {
  LoadStore,
  LoadStoreType,
  LoadStoreDefault
} from '../models/LoadStore';

export const LoadStoreContext = createContext<LoadStoreType | null>(null);

interface LoadStoreProvider {
  children: ReactNode;
}

const getOrCreateStore = () => {
  const loadStore = LoadStoreDefault();
  const initData = localStorage.getItem('loads');

  if (initData) {
    applySnapshot(loadStore, JSON.parse(initData));
    return loadStore;
  } else {
    return LoadStore.create();
  }
};

export const LoadStoreProvider = (props: LoadStoreProvider) => {
  const [loadStore] = useState(() => getOrCreateStore());

  onSnapshot(loadStore, (_snapshot) => {
    const snapshot = { ..._snapshot };
    const data = JSON.stringify(snapshot);
    localStorage.setItem('loads', data);
  });

  return (
    <LoadStoreContext.Provider value={loadStore}>
      {props.children}
    </LoadStoreContext.Provider>
  );
};
