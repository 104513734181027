import { observer } from 'mobx-react';
import React from 'react';

import LocationListItemView from '../../components/Location/LocationListItemView';
import { LocationStoreProvider } from '../../contexts/LocationStoreContext';
import Wrapper from '../layout';

const LocationMapPage = (props) => {
  return (
    <Wrapper {...props} title="Location Map">
      <LocationStoreProvider>
        <LocationListItemView />
      </LocationStoreProvider>
    </Wrapper>
  );
};

export default observer(LocationMapPage);
