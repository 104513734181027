import gql from 'graphql-tag';

export const GET_CALENDAR_EVENT_ENTRY_QUERIES = gql`
  query GET_CALENDAR_EVENT_ENTRY_QUERIES($team_id: String!) {
    events {
      id
      name
    }
    teamSports(team_id: $team_id) {
      sport_type_id
      name
      sub
    }
  }
`;
