import { useQuery } from "@apollo/client";
import React, { useEffect } from "react";
import Select from "react-select";
import { RegistrationGroupRoles } from "../../../../components/constants";
import { GET_CAMP_ROLES } from "../../../../graphql/GET_CAMP_ROLES";

interface CampRolesSelectProps {
  campId: string;
  setCampRoles: (_: any) => void;
}
const CampRolesSelect = ({ campId, setCampRoles }: CampRolesSelectProps) => {
  const [selectedRoles, setSelectedRoles] = React.useState([]);
  const [options, setOptions] = React.useState([]);
  const { data: campRolesData } = useQuery(GET_CAMP_ROLES, {
    variables: { campId },
  });

  useEffect(() => {
    if (campRolesData?.campRoles) {
      const selected = campRolesData.campRoles
        .filter((role) => role.isExists)
        .map((role) => role.roleId);
      setSelectedRoles(selected);
      setCampRoles(selected);
      const filteredRoles = campRolesData.campRoles
        .filter(
          (option) =>
            !!RegistrationGroupRoles.find(
              (role) => role.value === Number(option.roleId),
            ),
        )
        .sort((a, b) => {
          if (a.name < b.name) {
            return -1;
          }
          if (a.name > b.name) {
            return 1;
          }
          return 0;
        });
      setOptions(
        filteredRoles.map((role) => ({
          label: role.name,
          value: role.roleId,
        })),
      );
    }
  }, [campRolesData]);

  const changeRoleHandle = (selectedRolesValue) => {
    const selected = selectedRolesValue.map((role) => role.value);
    setSelectedRoles(selected);
    setCampRoles(selected);
  };
  return (
    <Select
      options={options}
      isMulti
      onChange={changeRoleHandle}
      value={options.filter((role) => selectedRoles.includes(role.value))}
    />
  );
};

export default CampRolesSelect;
