import { useQuery } from "@apollo/client";
import { Box } from "@material-ui/core";
import Modal from "components/Modal";
import { GET_PAYMENT } from "graphql/GET_PAYMENT";
import { GET_PAYMENT as GET_PAYMENT_TYPE } from "graphql/types/GET_PAYMENT";
import moment from "moment";
import React, { useMemo } from "react";
import { Link } from "react-router-dom";
import { Dropdown, Tag, Text } from "tabler-react";
import { formatMoney } from "utils/numberFormat";
import { Types } from "../../../../types/graphql";
import { useGetUserIsAdmin } from "../../../common/hooks/useGetCurrentUserType";

const PaymentInfo = ({
  label,
  value,
}: {
  label: string;
  value: string | number;
}) => {
  return (
    <p>
      {label}: <span className="font-weight-bold">{value}</span>
    </p>
  );
};

type PaymentDetailsProps = {
  payment: Types.Payments;
};

const PaymentDetails = ({ payment }: PaymentDetailsProps) => {
  const isAdmin = useGetUserIsAdmin();

  return (
    <Box>
      <Text className="d-block">
        <Tag
          color={payment.status === "Paid" ? "success" : ""}
          className="float-right"
        >
          {payment.status}
        </Tag>
        {payment.invoice_id && (
          <Link to={`/dzm/invoice/${payment.invoice_id}`} target="_blank">
            <Tag color="info" className="float-right cursor-pointer mr-2">
              INV: {payment.invoice_id}
            </Tag>
          </Link>
        )}
        <PaymentInfo
          label="Amount"
          value={`${formatMoney(
            payment.amount,
          )} ${payment.currency.toUpperCase()}`}
        />
      </Text>
      {payment.first_name && (
        <PaymentInfo
          label="Customer"
          value={`${payment.first_name} ${payment.last_name}`}
        />
      )}
      {payment.created_by ? (
        <PaymentInfo
          label="Submitted by"
          value={`${payment.coach_first_name} ${payment.coach_last_name}`}
        />
      ) : (
        <PaymentInfo
          label="Submitted by"
          value={`${
            payment.first_name
              ? payment.first_name + " " + payment.last_name
              : "Customer"
          }`}
        />
      )}
      <PaymentInfo
        label="Payment Date"
        value={moment(payment.created_on).format("DD MMM YYYY h:mm a")}
      />
      <PaymentInfo label="Payment ID" value={payment.id.toString()} />

      {payment.manual_payment && (
        <PaymentInfo label="Payment Method" value={payment.manual_payment} />
      )}
      {payment.description && (
        <PaymentInfo label="Description" value={payment.description} />
      )}
      {payment.processed_by !== "Manual Payment" && (
        <>
          <hr />
          <PaymentInfo label="Processed By" value={payment.processed_by} />
          {payment.card_customer_name && (
            <PaymentInfo
              label="Card Customer Name"
              value={payment.card_customer_name}
            />
          )}
          <Text>
            <span className="text-uppercase">{payment.card_brand}</span> ****
            {payment.last4}
          </Text>
          <PaymentInfo label="Card Country" value={payment.card_country} />
        </>
      )}
      {payment.application_fee !== null && isAdmin && (
        <PaymentInfo
          label="Application Fee"
          value={`${formatMoney(
            payment.application_fee,
          )} ${payment.currency.toUpperCase()}`}
        />
      )}
      {payment.fees && (
        <PaymentInfo
          label="Customer Fee"
          value={`${formatMoney(
            payment.fees,
          )} ${payment.currency.toUpperCase()}`}
        />
      )}
      {payment.total && (
        <PaymentInfo
          label={payment.invoice_id ? 'Invoice Total' : 'Total'}
          value={`${formatMoney(
            payment.total,
          )} ${payment.currency.toUpperCase()}`}
        />
      )}
    </Box>
  );
};

type ViewPaymentBtnProps = {
  toggleReceiptModal: () => void;
};

const ViewPaymentBtn = (props: ViewPaymentBtnProps) => {
  return (
    <>
      <Dropdown.Item
        icon="file"
        className="cursor-pointer"
        onClick={props.toggleReceiptModal}
      >
        Details
      </Dropdown.Item>
    </>
  );
};

export const ViewPaymentModal = ({
  payment_id,
  isReceiptModalOpen,
  toggleReceiptModal,
}) => {
  const { data } = useQuery<GET_PAYMENT_TYPE>(GET_PAYMENT, {
    variables: {
      payment_id: payment_id,
    },
  });

  const paymentData = useMemo(() => data?.getPayment, [data?.getPayment]);
  return (
    <Modal
      content={<PaymentDetails payment={paymentData} />}
      open={isReceiptModalOpen}
      onClose={toggleReceiptModal}
      title="Payment Details"
    />
  );
};

export default ViewPaymentBtn;
