import gql from "graphql-tag";

export const UPDATE_REGISTRATION_OPTION = gql`
  mutation UPDATE_REGISTRATION_OPTION(
    $id: ID!
    $name: String!
    $description: String!
    $fee_type_id: ID
    $option_value: String
    $option_type_id: ID!
    $team_id: ID
    $location_id: ID
    $reg_form: Int
    $apply_sanction_fee: Int
  ) {
    updateRegistrationOption(
      registrationOption: {
        id: $id
        name: $name
        location_id: $location_id
        description: $description
        team_id: $team_id
        option_value: $option_value
        option_type_id: $option_type_id
        fee_type_id: $fee_type_id
        reg_form: $reg_form
        apply_sanction_fee: $apply_sanction_fee
      }
    )
  }
`;
