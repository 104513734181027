import { useQuery } from '@apollo/client';
import { EUserFilterOptions } from 'components/enum';
import StudentLink from 'components/Students/StudentLink';
import { EUserTypes } from 'components/User/userTypes';
import { UserStoreContext } from 'contexts/userStoreContext';
import { GET_STUDENTS_BY_FILTER_QUERY } from 'graphql/GET_STUDENTS_BY_FILTER_QUERY';
import { useRootStore } from 'hooks';
import { EOrderOptions } from 'hooks/useAutocomplete';
import { observer } from 'mobx-react';
import { applySnapshot } from 'mobx-state-tree';
import { DEFAULT_PAGE } from 'modules/location/constants';
import React, { useContext, useEffect, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { Avatar, Dimmer, Icon, Table, Text } from 'tabler-react';
import useReactRouter from 'use-react-router';
import { formatDateToLocalString } from 'utils/dateFormat';
import { Types } from '../../types/graphql'

const DEFAULT_STUDENT_LIMIT = 50;

const CoachStudentList = () => {
  const rootStore = useRootStore();
  const userStore = useContext(UserStoreContext);
  const { history } = useReactRouter();
  const { currentCoachTeam, currentUser } = rootStore;

  const [studentsState, setStudentsState] = useState<
    Types.Student[]
  >([]);
  const [page, setPage] = useState<number>(2);

  const { data, loading, fetchMore } = useQuery(GET_STUDENTS_BY_FILTER_QUERY, {
    variables: {
      order: EOrderOptions.newest,
      filter: {
        limit: DEFAULT_STUDENT_LIMIT,
        offset: DEFAULT_STUDENT_LIMIT * (DEFAULT_PAGE - 1),
        team_id: currentCoachTeam?.id.toString(),
        filter_by: EUserFilterOptions.enabled
      }
    }
  });

  useEffect(() => {
    if (!loading && data) {
      setStudentsState(data?.students);
      applySnapshot(userStore, {
        users: data?.students
      });
    }
  }, [data, loading]);

  const routeToStudent = (student) => {
    userStore.loadUser(student);
    history.push('/user-details');
  };

  const onFetchMore = () => {
    setPage((prevNum) => prevNum + 1);
    return fetchMore({
      updateQuery: (cache, { fetchMoreResult }) => {
        if (!fetchMoreResult) {
          return cache;
        }

        return {
          ...cache,
          students: [...cache?.students ?? [], ...fetchMoreResult?.students ?? []]
        };
      },
      variables: {
        order: EOrderOptions.newest,
        filter: {
          limit: DEFAULT_STUDENT_LIMIT,
          offset: DEFAULT_STUDENT_LIMIT * (page - 1),
          team_id: currentCoachTeam?.id.toString(),
          filter_by: EUserFilterOptions.enabled
        }
      }
    });
  };

  return (
    <Dimmer active={loading} loader={loading}>
      <InfiniteScroll
        className="overflow-visible pb-3"
        dataLength={studentsState.length}
        hasMore={studentsState.length < (data?.studentsCount.total || 0)}
        loader={<Dimmer active={true} loader={true} />}
        next={onFetchMore}
        scrollThreshold="210px"
        endMessage={<Text className="text-center mt-5"></Text>}
      >
        {studentsState.length > 0 &&
        <Table>
          <Table.Header>
            <Table.Row>
              <Table.ColHeader></Table.ColHeader>
              {currentUser.type === EUserTypes.coach && (
                <>
                  <Table.ColHeader>Last Event</Table.ColHeader>
                  <Table.ColHeader></Table.ColHeader>
                  <Table.ColHeader></Table.ColHeader>
                </>
              )}
              {currentUser.type === EUserTypes.dzm && (
                <Table.ColHeader>Last Jump</Table.ColHeader>
              )}
              <Table.ColHeader></Table.ColHeader>
              <Table.ColHeader></Table.ColHeader>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {studentsState.map((studentItem) => {
              return (
                <Table.Row key={studentItem.id}>
                  <Table.Col>
                    <Avatar
                      size="md"
                      className="float-left mr-5 cursor-pointer"
                      status={`${
                        studentItem.registration_id ? 'success' : 'secondary'
                      }`}
                      imageURL={
                        (studentItem.profile_avatar &&
                          `/api/s3/uploads/${studentItem.profile_avatar}`) ||
                        '//www.gravatar.com/avatar?d=mp'
                      }
                      onClick={(e) => {
                        e.preventDefault();
                        routeToStudent(studentItem);
                      }}
                      to="#"
                    />
                    <StudentLink
                      routeToStudent={() => routeToStudent(studentItem)}
                      studentName={`${studentItem.first_name} ${studentItem.last_name}`}
                    />
                    <Text.Small className="d-block text-muted">
                      SKY.D: {studentItem.id}
                    </Text.Small>
                  </Table.Col>
                  {currentUser.type === EUserTypes.coach && (
                    <>
                      <Table.Col>
                        {formatDateToLocalString(studentItem.last_event)}
                      </Table.Col>
                      <Table.Col>
                        {formatDateToLocalString(studentItem.last_jump)}
                      </Table.Col>
                      <Table.Col>
                        {formatDateToLocalString(
                          studentItem.last_tunnel_session
                        )}
                      </Table.Col>
                    </>
                  )}
                  {currentUser.type === EUserTypes.dzm && (
                    <Table.Col>
                      {formatDateToLocalString(studentItem.last_jump)}
                    </Table.Col>
                  )}
                  <Table.Col></Table.Col>
                  <Table.Col>
                    <Icon
                      name="phone"
                      className={`${
                        studentItem.phone_number
                          ? 'text-gray-dark mr-2'
                          : 'text-muted mr-2'
                      }`}
                    />
                    <Icon
                      name="mail"
                      className={`${
                        studentItem.email ? 'text-gray-dark' : 'text-muted'
                      }`}
                    />
                  </Table.Col>
                </Table.Row>
              );
            })}
          </Table.Body>
        </Table>
        }
      </InfiniteScroll>
    </Dimmer>
  );
};

export default observer(CoachStudentList);
