import { useQuery } from '@apollo/client';
import { UserStoreContext } from 'contexts/userStoreContext';
import { GET_INVOICE_LIST } from 'graphql/GET_INVOICE_LIST';
import { GET_INVOICE_LIST as GET_INVOICE_LIST_TYPE } from 'graphql/types/GET_INVOICE_LIST';
import { useRootStore } from 'hooks';
import { observer } from 'mobx-react';
import { useGetUserIsStudent } from 'modules/common/hooks/useGetCurrentUserType';
import React, { useContext, useMemo, useState } from 'react';
import {
  Alert,
  Button,
  Card,
  Dimmer,
  Icon,
  Table,
  Tag,
  Text
} from 'tabler-react';
import useReactRouter from 'use-react-router';
import { formatMoney } from 'utils/numberFormat';

const DEFAULT_LIMIT = 5;

type AccountsInvoiceProps = {
  teamId?: number;
};

const AccountsInvoice = ({ teamId }: AccountsInvoiceProps) => {
  const isStudent = useGetUserIsStudent();
  const { user } = useContext(UserStoreContext);
  const { currentCoachTeam, currentUser } = useRootStore();

  const { history } = useReactRouter();

  const [page, setPage] = useState(0);

  const { data: invoiceData, loading: invoiceLoading } =
    useQuery<GET_INVOICE_LIST_TYPE>(GET_INVOICE_LIST, {
      variables: {
        invoiceListFilter: {
          team_id: teamId ?? Number(currentCoachTeam?.id),
          limit: DEFAULT_LIMIT,
          offset: page * DEFAULT_LIMIT,
          student_id: isStudent ? currentUser?.id : Number(user?.id)
        }
      }
    });

  const invoiceRows = useMemo(
    () => invoiceData?.invoiceList.invoices.map((item) => item) ?? [],
    [invoiceData?.invoiceList]  
  );

  return (
    <Card statusColor="gray-dark">
      <Card.Header>
        <Card.Title>
          <i className="fe fe-file text-primary mr-2" />
          Invoices
        </Card.Title>
      </Card.Header>

      <Dimmer active={invoiceLoading} loader={invoiceLoading}>
        <Table className="card-table">
          <Table.Body>
            {invoiceRows.length > 0 ? (
              <>
                {invoiceRows.map((invoice) => (
                  <Table.Row key={invoice.id}>
                    <Table.Col>
                      <span
                        className="cursor-pointer"
                        onClick={(e) => {
                          e.preventDefault();
                          history.push(`/dzm/invoice/${invoice.id}`);
                        }}
                      >
                        Inv: {invoice.id}
                        <Text.Small className="d-block text-muted">
                          {new Intl.DateTimeFormat('en-US').format(
                            new Date(invoice.created_on)
                          )}
                        </Text.Small>
                      </span>
                    </Table.Col>
                    <Table.Col className="text-right">
                      {formatMoney(invoice.invoice_amount)}
                      <Tag className="ml-1">{invoice.status}</Tag>
                    </Table.Col>
                  </Table.Row>
                ))}

                <Table.Row>
                  <Table.Col colSpan={2} alignContent={'center'}>
                    <Button
                      className="mr-5"
                      color="white"
                      disabled={!page}
                      onClick={() => setPage((prev) => prev - 1)}
                    >
                      <Icon name="chevron-left" className="mr-2 ml-0" />
                    </Button>
                    {page + 1}
                    <Button
                      className="ml-5"
                      color="white"
                      disabled={
                        page + 1 >=
                        invoiceData?.invoiceList.total / DEFAULT_LIMIT
                      }
                      onClick={() => setPage((prev) => prev + 1)}
                    >
                      <Icon name="chevron-right" className="mr-0 ml-2" />
                    </Button>
                  </Table.Col>
                </Table.Row>
              </>
            ) : (
              <Table.Row>
                <Table.Col colSpan="8">
                  <Alert type="info text-center">
                    <strong>No Invoices</strong>
                  </Alert>
                </Table.Col>
              </Table.Row>
            )}
          </Table.Body>
        </Table>
      </Dimmer>
    </Card>
  );
};

export default observer(AccountsInvoice);
