import gql from 'graphql-tag';

export const REMOVE_ROLE_FROM_STAFF = gql`
  mutation removeRoleFromStaff($teamStaff: StaffRoleInput!) {
    removeRoleFromStaff(teamStaff: $teamStaff) {
      roles {
        id
        key
        name
      }
    }
  }
`;
