import gql from 'graphql-tag';

export const GET_EXPIRING_RESERVE_LOGS = gql`
  query GET_EXPIRING_RESERVE_LOGS($team_id: Int, $month: String) {
    getExpiringReserveLogs(team_id: $team_id, month: $month) {
      event_date
      first_name
      last_name
      phone_country_code
      phone_number
      email
    }
  }
`;
