import gql from 'graphql-tag';

export const GET_INVOICE_LIST = gql`
  query GET_INVOICE_LIST($invoiceListFilter: InvoiceListFilter!) {
    invoiceList(invoiceListFilter: $invoiceListFilter) {
      invoices {
        id
        student_id
        invoice_type_id
        status
        invoice_notes
        created_by
        invoice_amount
        created_on
        company_name
        company_phone
        company_email
        customer_name
        customer_phone
        customer_email

        paymentDetail {
          total
          totalComp
          outstanding
          totalPaid
          totalFees
        }

        team {
          id
          name
          invoice_terms
        }

        student {
          id
          first_name
          last_name
          address1
          address2
          city
          country
          dob
          email
          jump_weight
          phone_number
          postal_code
          province
          registration_id
          weight
        }
      }

      statusCounts {
        status
        count
      }

      total
    }
  }
`;
