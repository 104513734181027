import { onSnapshot, applySnapshot } from 'mobx-state-tree';
import { autorun } from 'mobx';
import PropTypes from 'prop-types';
import React, { useState } from 'react';

import { API } from '../api';
import { NumbersStore } from '../models/NumbersStore';
import { rootStoreDefault } from '../models/RootStore';
import { StripeStore } from '../models/StripeStore';
import { NumbersStoreContext, RootStoreContext } from './RootStoreContext';
import { StripeStoreContext } from './stripeStoreContext';

const getOrCreateStore = (api, initData) => {
  const rootStore = rootStoreDefault(api);
  // if no data is supplied, then try to load it from localStorage
  if (initData) {
    applySnapshot(rootStore, initData);
  } else {
    try {
      initData = localStorage.getItem('root');
      initData = JSON.parse(initData);
      applySnapshot(rootStore, initData || {});
    } catch (e) {
      console.log('Error: unable to apply snapshot', initData);
    }
  }
  return rootStore;
};

interface RootStoreProviderProps {
  api: API;
  data?: any;
  children: React.ReactNode;
}

const RootStoreProvider: React.FunctionComponent<RootStoreProviderProps> = ({
  api,
  data,
  children
}: RootStoreProviderProps) => {
  const [numbersStore] = useState(() => NumbersStore.create());
  const [rootStore] = useState(() => getOrCreateStore(api, data));
  const [stripeStore] = useState(() => StripeStore.create());

  onSnapshot(rootStore, (_snapshot) => {
    const snapshot = { ..._snapshot };
    const data = JSON.stringify(snapshot);
    localStorage.setItem('root', data);
  });

  onSnapshot(stripeStore, (_snapshot) => {
    const snapshot = { ..._snapshot };
    const data = JSON.stringify(snapshot);
    localStorage.setItem('stripeStore', data);
  });

  // listen to new snapshots
  onSnapshot(rootStore, (snapshot) => {
    console.dir('rootStore snapshot', snapshot);
  });

  onSnapshot(numbersStore, (snapshot) => {
    console.dir('numbersStore snapshot', snapshot);
  });

  autorun(() => {
    console.log('autorun', rootStore, rootStore.testing);
    rootStore.me();
  });

  return (
    <RootStoreContext.Provider value={rootStore}>
      <NumbersStoreContext.Provider value={numbersStore}>
        <StripeStoreContext.Provider value={stripeStore}>
          {children}
        </StripeStoreContext.Provider>
      </NumbersStoreContext.Provider>
    </RootStoreContext.Provider>
  );
};

RootStoreProvider.propTypes = {
  api: PropTypes.any,
  children: PropTypes.any
};

export { RootStoreProvider };
