import React from 'react';
import { Button, Card, Alert } from 'tabler-react';
import { Types } from '../../types/graphql'

interface UserLocationListProps {
  locations: Types.Location[];
}
const UserLocationList = ({ locations }: UserLocationListProps) => {
  return (
    <Card statusColor="blue" title="Locations">
      <Card.Body>
        {locations.length === 0 ? (
          <Alert type="info text-center">
            <strong>No Data</strong>
          </Alert>
        ) : (
          <Button.List>
            {locations.map((location, idx) => (
              <React.Fragment key={idx}>
                <Button pill color="blue" size="sm" icon="map-pin">
                  {location.name}
                </Button>
              </React.Fragment>
            ))}
          </Button.List>
        )}
      </Card.Body>
    </Card>
  );
};

export default UserLocationList;
