import gql from "graphql-tag";

export const ADD_COACH = gql`
  mutation ADD_COACH($coach: AddCoachInput!) {
    addCoach(coach: $coach) {
      id
      first_name
      last_name
      email
      phone_number
      phone_country_code
      is_disabled_on
      registration_id
    }
  }
`;
