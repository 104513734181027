import React, { useEffect, useState } from 'react';
import { useQuery } from '@apollo/client';
import { Dimmer, Grid, Table } from 'tabler-react';
import GearListItem from './GearListItem';
import GearCardModalForm from './Modal/GearCardModalForm';
import { GET_GEARS_ADMIN } from '../../graphql/GET_GEARS_ADMIN';
import InfiniteScroll from 'react-infinite-scroll-component';
import GearModelModalForm from './Modal/GearModelModalForm';
import { DEFAULT_GEAR_LIMIT } from 'components/constants';
import { Types } from '../../types/graphql'

interface IGearListProps {
  nameFilter?: string;
}

const GearList = ({ nameFilter }: IGearListProps) => {
  const [userGears, setUserGears] = useState<Types.UserGear[]>(
    []
  );
  const [userGear, setUserGear] =
    useState<Types.UserGear>(null);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [page, setPage] = useState<number>(0);

  const [gearModel, setGearModel] = useState(null);
  const [isModelModalOpen, setIsModelModalOpen] = useState<boolean>(false);

  const { loading, error, data, fetchMore, refetch } = useQuery(
    GET_GEARS_ADMIN,
    {
      variables: {
        getAllUserGearOptionsAdmin: {
          limit: DEFAULT_GEAR_LIMIT,
          offset: 0
        }
      }
    }
  );

  useEffect(() => {
    if (!loading && !error && data)
      setUserGears(
        data.getallUserGears.filter(
          (g: Types.UserGear) =>
            g?.name.toLowerCase().includes(nameFilter.toLowerCase()) ||
            g?.gear_model?.name
              .toLowerCase()
              .includes(nameFilter.toLowerCase()) ||
            g.student?.first_name
              .toLowerCase()
              .includes(nameFilter.toLowerCase()) ||
            g.student?.last_name
              .toLowerCase()
              .includes(nameFilter.toLowerCase())
        )
      );
  }, [data, loading, error, nameFilter]);

  const handleToggleModal = () => {
    refetch().then((result) => setUserGears(result.data.getallUserGears));
    setIsModalOpen(!isModalOpen);
  };

  const handleToggleModelModal = () => {
    refetch().then((result) => setUserGears(result.data.getallUserGears));
    setIsModelModalOpen(!isModelModalOpen);
  };
  const onFetchMore = () => {
    setPage(() => page + 1);
    return fetchMore({
      updateQuery: (cache, { fetchMoreResult }) => {
        if (!fetchMoreResult) {
          return cache;
        }

        return {
          ...cache,
          getallUserGears: [
            ...cache.getallUserGears,
            ...fetchMoreResult.getallUserGears
          ]
        };
      },
      variables: {
        getAllUserGearOptionsAdmin: {
          limit: DEFAULT_GEAR_LIMIT,
          offset: DEFAULT_GEAR_LIMIT * (page + 1)
        }
      }
    });
  };
  return (
    <>
      <Grid.Col width={12}>
        <Grid.Row className="product-list">
          <Dimmer active={loading} loader={loading} />
          <InfiniteScroll
            className="overflow-visible pb-3"
            dataLength={userGears?.length}
            hasMore={userGears?.length < (userGears[0]?.total || 0)}
            loader={<Dimmer active={true} loader={true} />}
            next={onFetchMore}
            scrollThreshold="210px"
          >
            <Table responsive className="card-table table-vcenter text-nowrap">
              <Table.Header>
                <Table.Row>
                  <Table.ColHeader>ID</Table.ColHeader>
                  <Table.ColHeader>User</Table.ColHeader>
                  <Table.ColHeader>Type</Table.ColHeader>
                  <Table.ColHeader>Model</Table.ColHeader>
                  <Table.ColHeader>Manufacturer</Table.ColHeader>
                  <Table.ColHeader>Serial</Table.ColHeader>
                  <Table.ColHeader>Notes</Table.ColHeader>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {!loading &&
                  userGears?.map((userGearItem) => (
                    <Table.Row key={userGearItem.id}>
                      <GearListItem
                        userGear={userGearItem}
                        toggleModal={handleToggleModal}
                        toggleModelModal={handleToggleModelModal}
                        setUserGear={setUserGear}
                        setGearModel={setGearModel}
                      />
                    </Table.Row>
                  ))}
              </Table.Body>
            </Table>
          </InfiniteScroll>
        </Grid.Row>
      </Grid.Col>
      <GearCardModalForm
        userGear={userGear}
        toggleModal={handleToggleModal}
        isModalOpen={isModalOpen}
        studentId={userGear?.student_id}
      />
      <GearModelModalForm
        gearModel={gearModel}
        toggleModal={handleToggleModelModal}
        isModalOpen={isModelModalOpen}
      />
    </>
  );
};

export default GearList;
