import { useLazyQuery } from '@apollo/client';
import { UserStoreContext } from 'contexts/userStoreContext';
import { GET_STUDENT_BY_ID } from 'graphql/GET_STUDENT_BY_ID';
import { GET_STUDENT_BY_ID as GET_STUDENT_BY_ID_TYPE } from 'graphql/types/GET_STUDENT_BY_ID';
import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import useReactRouter from 'use-react-router';

interface IStudentLinkProp {
  studentId?: number;
  routeToStudent?: any;
  studentName: string;
}

const StudentLink = ({
  studentId,
  routeToStudent,
  studentName
}: IStudentLinkProp) => {
  const userStore = useContext(UserStoreContext);
  const { history } = useReactRouter();
  const { user } = userStore;

  const [getStudentData] = useLazyQuery<GET_STUDENT_BY_ID_TYPE>(
    GET_STUDENT_BY_ID,
    {
      onCompleted(data) {
        if (data) {
          userStore.detach(user);
          userStore.loadUser(data.getStudentById);
          history.push('/user-details');
        }
      }
    }
  );

  const alternateRouteToStudent = () => {
    getStudentData({
      variables: {
        student_id: studentId
      }
    });
  };

  return (
    <>
      <Link
        className="text-inherit"
        onClick={(e) => {
          e.preventDefault();
          if (routeToStudent) {
            routeToStudent();
          } else {
            alternateRouteToStudent();
          }
        }}
        to="#"
      >
        {studentName}
      </Link>
    </>
  );
};

export default StudentLink;
