import gql from "graphql-tag";

export const GET_NOTIFICATION_EMAILS = gql`
  query GET_NOTIFICATION_EMAILS($campId: ID!) {
    campNotificationEmails(campId: $campId) {
      id
      camp_id
      email
    }
  }
`;
