import React from 'react';

import UserList from '../../components/User/UserList';
import Wrapper from '../../pages/layout';

const StudentListPage = (props) => {
  return (
    <Wrapper {...props} title="User List">
      <div className="pr-3 pl-3">
        <UserList />
      </div>
    </Wrapper>
  );
};
export default StudentListPage;
