export const formatDate = (date: string | number | Date) => {
  const d = new Date(date)
  let month = '' + (d.getMonth() + 1)
  let day = '' + d.getDate()
  const year = d.getFullYear()

  if (month.length < 2) month = '0' + month;
  if (day.length < 2) day = '0' + day;

  return [year, month, day].join('-');
};

export const monthNames = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December'
];

const one_day = 1000 * 60 * 60 * 24;

export const dateFormat = (date, format) => {
  const current_date = new Date();

  const d = new Date(date),
    rawMonth = '' + d.getMonth(),
    day = '' + d.getDate(),
    year = d.getFullYear(),
    hour = d.getHours(),
    min = d.getMinutes();
  const month = (parseInt(rawMonth) + 1).toString();
  const monthdg = month.length < 2 ? '0' + month : month,
    daydg = day.length < 2 ? '0' + day : day,
    yeardg = year.toString().substr(3, 2);

  const hour_12 = hour > 12 ? hour - 12 : hour;
  const meridian = hour < 12 ? 'AM' : 'PM';
  const monthName = monthNames[rawMonth];

  switch (format) {
    case 'future_1':
      return `${(d.getTime() - current_date.getTime()) / one_day} days`;
    case 'future_2':
      break;
    case 'countdown_short':
      break;
    case 'countdown_long':
      break;
    case 'past_date':
      return `${(current_date.getTime() - d.getTime()) / one_day} days ago`;
    case 'full_date':
      return `${monthName} ${daydg}, ${year}`;
    case 'long_date':
      return `${monthName.substr(1, 3)} ${daydg}, ${year}`;
    case 'full_date_time':
      return `${monthName} ${daydg}, ${year} - ${hour_12}:${min} ${meridian}`;
    case 'long_date_time':
      return `${monthName.substr(
        1,
        3
      )} ${daydg}, ${year} - ${hour_12}:${min} ${meridian}`;
    case 'full_date_time_24':
      return `${monthName} ${daydg}, ${year} - ${hour}:${min}`;
    case 'long_date_time_24':
      return `${monthName.substring(1, 3)} ${daydg}, - ${year} ${hour}:${min}`;
    case 'short_date':
      return `${monthName.substring(1, 3)} ${day}, ${yeardg}`;
    case 'tiny_date':
      return `${monthName.substring(1, 3)} ${day}`;
    case 'long_numeric':
      return `${monthdg}/${daydg}/${year}`;
    case 'short_numeric':
      return `${month}/${day}/${yeardg}`;
    case 'full_date_m':
      return `${daydg} ${monthName}, ${year}`;
    case 'long_date_m':
      return `${daydg} ${monthName.substr(1, 3)}, ${year}`;
    case 'full_date_time_m':
      return `${daydg} ${monthName.substr(
        1,
        3
      )}, ${year} - ${hour_12}:${min} ${meridian}`;
    case 'long_date_time_m':
      return `${daydg} ${monthName.substr(
        1,
        3
      )}, ${year} - ${hour_12}:${min} ${meridian}`;
    case 'full_date_time_24_m':
      return `${daydg} ${monthName}, ${year} - ${hour}:${min}`;
    case 'long_date_time_24_m':
      return `${daydg} ${monthName.substr(1, 3)}, ${year} - ${hour}:${min}`;
    case 'tiny_date_m':
      return `${day} ${monthName.substring(1, 3)}`;
    case 'long_numeric_m':
      return `${daydg}/${monthdg}/${year}`;
    case 'short_numeric_m':
      return `${day}/${month}/${yeardg}`;
    case 'time':
      return `${hour_12}:${min} ${meridian}`;
    case 'time_24':
      return `${hour}:${min}`;
  }
};

export const formatDateToLocalString = (date?: string | number | Date) => {
  if (!date) {
    return '';
  }
  return new Date(date).toLocaleDateString();
};

/* We need to define each of these date formats

123 days - future_1
1 hour 45 min - future_2
Past date: 14 days ago / 1 hour ago

Imperial:
July 01, 2023 - full_date (full_date.i)
Jul 01, 2023 - long_date
July 01, 2023 - 9:55 PM - full_date_time
Jul 01, 2023 - 9:55 PM - long_date_time
July 01, 2023 - 21:55 - full_date_time24
Jul 01, 2023 - 21:55 - long_date_time24
Jul 1, 23 - short_date
Jul 1 - tiny_date
07/01/2023 - long_numeric
7/1/23 - short_numeric

Metric:
01 July, 2023 - full_date_m (full_date.m)
01 Jul, 2023
01 Jul, 2023 - 9:55 PM
01 Jul, 2023 - 21:55
1 Jul.
01/07/2023
1/7/23

Time Only:
9:55 PM - time
21:55 - time24

*/
