import React from 'react';
import Modal from '../../Modal';
import { Grid } from 'tabler-react';

const TShirtSummary = ({ isOpen, toggle, registrations }) => {
  const summary = registrations.reduce(
    (shirtSummary, { shirt_size, status }) => {
      if (status !== 'Confirmed') return shirtSummary;

      if (shirt_size === null) {
        shirt_size = 'Not Specified';
      }
      shirtSummary[shirt_size] = shirtSummary[shirt_size] + 1 || 1;
      return shirtSummary;
    },
    {}
  );
  return (
    <Modal
      content={
        <>
          <Grid.Row>
            <Grid.Col sm={4} md={4}>
              <strong>Shirt sizes</strong>
            </Grid.Col>
            <Grid.Col sm={3} md={3} className={'text-right'}>
              <strong>Count</strong>
            </Grid.Col>
          </Grid.Row>
          {Object.keys(summary).map((size) => (
            <Grid.Row key={size}>
              <Grid.Col sm={4} md={4}>
                {size}
              </Grid.Col>
              <Grid.Col sm={3} md={3} className={'text-right'}>
                {summary[size]}
              </Grid.Col>
            </Grid.Row>
          ))}
        </>
      }
      open={isOpen}
      onClose={toggle}
      title={'T-Shirt Summary'}
    />
  );
};

export default TShirtSummary;
