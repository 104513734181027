import { useQuery } from '@apollo/client';
import React, { useContext, useState } from 'react';
import { Button, Card, Dimmer, Form, Grid, Icon, Text } from 'tabler-react';
import { useRootStore } from 'hooks/useRootStore';
import moment from 'moment/moment';
import { UserStoreContext } from '../../contexts/userStoreContext';
import { GET_STUDENT_RECENT_LOGBOOK_LOG } from '../../graphql/GET_STUDENT_RECENT_LOGBOOK_LOG';
import { EUserTypes } from './userTypes';
import LogBookJumpModalForm from '../Logbook/LogBookJumpModalForm';
import LogBookSessionModalForm from '../Logbook/LogBookSessionModalForm';
import useGetCurrentUserType from '../../modules/common/hooks/useGetCurrentUserType';


export const daysBefore180 = (dateProp) => {
  const difference =
    new Date().getTime() -
    new Date(moment(dateProp).format('MM/DD/YYYY')).getTime();
  return 180 - Math.ceil(difference / (1000 * 3600 * 24));
};

const UserCurrencyCard = () => {
  const { user } = useContext(UserStoreContext);
  const { currentUser } = useRootStore();
  const { isDzm, isWtm } = useGetCurrentUserType();
  const [isJumpModalOpen, setIsJumpModalOpen] = useState<boolean>(false);
  const [isSessionModalOpen, setIsSessionModalOpen] = useState<boolean>(false);

  const { data: studentLogbookLogData, loading: studentLogbookLogLoading } =
    useQuery(GET_STUDENT_RECENT_LOGBOOK_LOG, {
      variables: {
        logbookLogFilter: {
          student_id: parseInt(user.id),
          sport_types_id: 2
        }
      }
    });
    const { data: flyerLogbookLogData, loading: flyerLogbookLogLoading } =
    useQuery(GET_STUDENT_RECENT_LOGBOOK_LOG, {
      variables: {
        logbookLogFilter: {
          student_id: parseInt(user.id),
          sport_types_id: 1
        }
      }
    });

  const handleJumpToggleModal = () => {
    setIsJumpModalOpen(!isJumpModalOpen);
  };
  const handleSessionToggleModal = () => {
    setIsSessionModalOpen(!isSessionModalOpen);
  };

  return (
    <Card statusColor="blue">
      <Card.Header className="d-block d-md-flex pt-3 pl-3">
        <Card.Title className="mb-3 mb-md-0">
          <Icon name="calendar" className="mr-2 ml-0 text-success" />
          Currency
        </Card.Title>
      </Card.Header>
      <Card.Body>
        <>
          <Dimmer
            active={studentLogbookLogLoading || flyerLogbookLogLoading}
            loader={studentLogbookLogLoading || flyerLogbookLogLoading}
          >
            <Grid.Row>
              {(currentUser.type === EUserTypes.dzm || currentUser.type === EUserTypes.wtm) && (
                <>
                <Grid.Col sm={12} lg={6} ignoreCol={true}>
                  <Form.Group className="mb-0" label="Last Jump">
                    <Form.InputGroup>
                      <input
                        className={
                          studentLogbookLogData?.getRecentLogbookLog.date
                            ? 'is-valid state-valid form-control'
                            : 'is-invalid state-invalid form-control'
                        }
                        name="last_jump"
                        placeholder="Unknown"
                        value={
                          studentLogbookLogData?.getRecentLogbookLog.date
                            ? moment(
                                studentLogbookLogData?.getRecentLogbookLog.date
                              ).format('MM/DD/YYYY')
                            : ''
                        }
                        disabled
                      />
                      {isDzm &&
                      <Form.InputGroupAppend>
                        <Button
                          icon="plus"
                          color={`${
                            studentLogbookLogData?.getRecentLogbookLog.date
                              ? 'success'
                              : 'danger'
                          }`}
                          className="text-white"
                          size="sm"
                          onClick={() => handleJumpToggleModal()}
                          disabled={isWtm}
                        >
                          Jump
                        </Button>
                      </Form.InputGroupAppend>
                      }   
                    </Form.InputGroup>
                    <Text>
                      <Text.Small className="text-muted text-secondary">
                        {studentLogbookLogData?.getRecentLogbookLog.location_name}
                        {/* ADD LOCATION NAME */}
                      </Text.Small>
                    </Text>
                  </Form.Group>
                </Grid.Col>
                <Grid.Col sm={12} lg={6} ignoreCol={true}>
                <Form.Group className="mb-0" label="Last Tunnel Session">
                  <Form.InputGroup>
                    <input
                      className={
                        flyerLogbookLogData?.getRecentLogbookLog.date
                          ? 'is-valid state-valid form-control'
                          : 'is-invalid state-invalid form-control'
                      }
                      name="last_session"
                      placeholder="Unknown"
                      value={
                        flyerLogbookLogData?.getRecentLogbookLog.date
                          ? moment(
                            flyerLogbookLogData?.getRecentLogbookLog.date
                            ).format('MM/DD/YYYY')
                          : ''
                      }
                      disabled
                    />
                    {isWtm &&
                    <Form.InputGroupAppend>
                      <Button
                        icon="plus"
                        color={`${
                          flyerLogbookLogData?.getRecentLogbookLog.date
                            ? 'success'
                            : 'danger'
                        }`}
                        className="text-white"
                        size="sm"
                        onClick={() => handleSessionToggleModal()}
                        disabled={isDzm}
                      >
                        Tunnel Session
                      </Button>
                    </Form.InputGroupAppend>
                    }
                  </Form.InputGroup>
                  <Text>
                    <Text.Small className="text-muted text-secondary">
                      {flyerLogbookLogData?.getRecentLogbookLog.location_name}
                      {/* ADD LOCATION NAME */}
                    </Text.Small>
                  </Text>
                </Form.Group>
              </Grid.Col>
              </>
              )}
            </Grid.Row>
          </Dimmer>
        </>
      </Card.Body>

      <LogBookJumpModalForm
        isModalOpen={isJumpModalOpen}
        logBookLog={null}
        toggleModal={() => handleJumpToggleModal()}
      />
      <LogBookSessionModalForm
        isModalOpen={isSessionModalOpen}
        logBookLog={null}
        toggleModal={() => handleSessionToggleModal()}
      />
    </Card>
  );
};

export default UserCurrencyCard;
