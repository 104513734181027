import React from 'react';
import { Container } from 'tabler-react';
import Wrapper from '../layout';
import TransactionList from '../../components/Transaction/TransactionList';
import { TransactionProvider } from '../../contexts/TransactionContext';

const TransactionListPage = (props) => {
  return (
    <Wrapper {...props} title="Transaction List">
      <Container>
        <TransactionProvider>
          <TransactionList />
        </TransactionProvider>
      </Container>
    </Wrapper>
  );
};

export default TransactionListPage;
