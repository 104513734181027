import { useMutation } from '@apollo/client';
import TextEditor from 'components/text-editor/TextEditor';
import { Formik } from 'formik';
import { SEND_EMAIL_TO_EVENT_REGISTERED_USERS } from 'graphql/SEND_EMAIL_TO_EVENT_REGISTERED_USERS';
import { useRootStore } from 'hooks';
import { observer } from 'mobx-react';
import ToRecipientsText from 'modules/user/registrations/components/texts/ToRecipientsText';
import React, { useMemo, useState } from 'react';
import { useParams } from 'react-router';
import { toast } from 'react-toastify';
import { Button, Card, Form, Grid, Text } from 'tabler-react';
import * as Yup from 'yup';

const sendEmailSchema = Yup.object().shape({
  message: Yup.string().required('This field is required.'),
  subject: Yup.string().required('This field is required.')
});

const initialValues = {
  message: '',
  subject: ''
};

type Props = {
  toggleModal: () => void;
};

const SendEmailToEventRegisteredUsersForm = ({ toggleModal }: Props) => {
  const { currentCoachTeam, currentUser } = useRootStore();
  const { campId } = useParams<{ campId: string }>();

  // Initialize state for each checkbox and the "Check All" checkbox
  const [checkedState, setCheckedState] = useState({});
  const [isAllChecked, setIsAllChecked] = useState(false);

  const handleSetCheckedState = (newState) => {
    setCheckedState(newState);
  };

  const handleSetIsAllChecked = (newState) => {
    setIsAllChecked(newState);
  };

  const [sendEmailMutation] = useMutation(SEND_EMAIL_TO_EVENT_REGISTERED_USERS);

  const studentIds = useMemo(
    () => Object.keys(checkedState).filter((key) => checkedState[key]) ?? [],
    [checkedState]
  );

  return (
    <Formik
      enableReinitialize={true}
      initialValues={initialValues}
      validationSchema={sendEmailSchema}
      onSubmit={async (values, { setSubmitting, resetForm }) => {
        const { message, subject } = values;

        await sendEmailMutation({
          variables: {
            coachId: currentUser.id,
            campId: Number(campId),
            message,
            subject,
            studentIds: studentIds.map(Number)
          }
        });

        toast.success('Email sent successfully.');

        setSubmitting(false);
        resetForm();
        toggleModal();
      }}
    >
      {({
        values,
        isSubmitting,
        handleBlur,
        handleChange,
        handleSubmit,
        setFieldValue
      }) => (
        <form className="mb-0" onSubmit={handleSubmit}>
          <Card.Body className="p-0">
            <Grid.Row className="mb-4">
              <Grid.Col>
                <Text>
                  From:{' '}
                  <strong>
                    {currentCoachTeam?.name ??
                      `${currentUser.first_name} ${currentUser.last_name}`}
                  </strong>{' '}
                  <i>
                    {'<'}
                    {currentCoachTeam?.team_email ?? currentUser.email}
                    {'>'}
                  </i>
                </Text>
              </Grid.Col>
            </Grid.Row>
            <Grid.Row className="mb-4">
              <Grid.Col className="d-flex align-items-center justify-content-between">
                <ToRecipientsText
                  checkedState={checkedState}
                  isAllChecked={isAllChecked}
                  setCheckedState={handleSetCheckedState}
                  setIsAllChecked={handleSetIsAllChecked}
                />
              </Grid.Col>
            </Grid.Row>
            <Grid.Row>
              <Grid.Col>
                <Form.Input
                  name="subject"
                  label="Subject*"
                  type="subject"
                  autoFocus
                  value={values.subject}
                  onBlur={handleBlur}
                  onChange={handleChange}
                />
              </Grid.Col>
            </Grid.Row>
            <Grid.Row className="mt-4">
              <Grid.Col>
                <Form.Group label="Message*">
                  <TextEditor
                    value={values.message}
                    onChange={(value) => setFieldValue('message', value)}
                  />
                </Form.Group>
              </Grid.Col>
            </Grid.Row>
          </Card.Body>
          <Card.Footer className="text-right px-0 pb-0 border-0">
            <Button
              icon="send"
              className="btn btn-primary"
              disabled={isSubmitting || !Object.keys(checkedState).length}
              loading={isSubmitting}
              type="submit"
            >
              SEND{isSubmitting ? 'ING' : ''}
            </Button>
          </Card.Footer>
        </form>
      )}
    </Formik>
  );
};

export default observer(SendEmailToEventRegisteredUsersForm);
