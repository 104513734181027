import EditProfileForm from "components/User/Forms/EditProfileForm";
import UserCredentialsForm from "components/User/Forms/UserCredentialsForm";
import UserDzLocationForm from "components/User/Forms/UserDzLocationForm";
import UserProfileForm from "components/User/Forms/UserProfileForm";
import UserCard from "components/User/Profile/UserCard";
import UserContactInfo from "components/User/Profile/UserContactInfo";
import UserActivitiesList from "components/User/UserActivitiesList";
import { UserStoreContext } from "contexts/userStoreContext";
import { useRootStore } from "hooks";
import { observer } from "mobx-react";
import { clone } from "mobx-state-tree";
import useGetCurrentUserType from "modules/common/hooks/useGetCurrentUserType";
import useGetStudentById from "modules/student/hooks/useGetStudentById";
import React, { useContext, useEffect } from "react";
import { Card, Grid } from "tabler-react";

const UserSettings = () => {
  const userStore = useContext(UserStoreContext);
  const { currentUser } = useRootStore();
  const { isAdmin, isStudent } = useGetCurrentUserType();

  const { data, loading } = useGetStudentById(String(currentUser?.id));

  const userClone = clone(currentUser);

  useEffect(() => {
    if (data?.getStudentById) {
      userStore.loadUser(data.getStudentById);
    }
  }, [data?.getStudentById]);

  //  const hasAuthMerits = data?.getStudentById.auth_merits.length > 0;

  return (
    <Grid.Row>
      <Grid.Col lg={4} sm={12} xs={12}>
        {!isAdmin && (
          <UserCard
            firstName={currentUser?.first_name}
            lastName={currentUser?.last_name}
            profileAvatar={currentUser?.profile_avatar}
            profilePicture={currentUser?.profile_picture}
          />
        )}
        <UserContactInfo user={currentUser} userClone={userClone} />
        {isStudent && !loading && (
          <>
            <UserDzLocationForm user={data?.getStudentById} />
          </>
        )}
        {isStudent && <UserActivitiesList />}
      </Grid.Col>
      <Grid.Col lg={8} sm={12} xs={12}>
        {isStudent && (
          <Card>
            <UserProfileForm userProfile={data?.getStudentById} />
          </Card>
        )}
        {!isStudent && !isAdmin && <EditProfileForm />}
        {isStudent && <UserCredentialsForm />}
      </Grid.Col>
    </Grid.Row>
  );
};

export default observer(UserSettings);
