import { observer } from 'mobx-react';
import React from 'react';
import Wrapper from '../layout';
import UserProfile from '../../components/User/UserProfile';

const UserProfilePage = (props) => {
  return (
    <Wrapper {...props} title="Profile">
      <div className="container">
        <UserProfile />
      </div>
    </Wrapper>
  );
};

export default observer(UserProfilePage);
