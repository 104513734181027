import gql from 'graphql-tag';

export const UPDATE_ADMIN_BY_ID = gql`
  mutation UPDATE_ADMIN_BY_ID($admin: UpdateAdmin!) {
    updateAdminById(admin: $admin) {
      id
      username
      is_disabled
    }
  }
`;
