import React, { useState } from 'react';
import { Container } from 'tabler-react';

import AddLoadModal from '../../../components/Load/Form/AddLoadModal';
import LoadsList from '../../../components/Load/LoadsList';
import { LoadStoreProvider } from '../../../contexts/LoadStoreContext';
import Wrapper from '../../layout';

const LoadListPage = (props) => {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  const toggleModal = () => setIsModalOpen(!isModalOpen);

  return (
    <Wrapper {...props} title="Load List">
      <Container>
        <LoadStoreProvider>
          <LoadsList toggleModal={toggleModal} />
          <AddLoadModal isModalOpen={isModalOpen} toggleModal={toggleModal} />
        </LoadStoreProvider>
      </Container>
    </Wrapper>
  );
};
export default LoadListPage;
