import useReactRouter from 'use-react-router';
import { Link } from 'react-router-dom';
import React from 'react';

const CalendarLink = (props) => {
  const { history } = useReactRouter();
  const { eventName, eventId } = props;
  const handleClick = () => {
    history.push(`/user/calendar/${eventId}`);
  };
  return (
    <Link
      className='text-inherit'
      onClick={(e) => {
        e.preventDefault();
        handleClick();
      }}
      to='#'
    >
      {eventName}
    </Link>
  );
};

export default CalendarLink;
