import { applySnapshot } from 'mobx-state-tree';
import { useContext } from 'react';
import useReactRouter from 'use-react-router';

import { StripeStoreContext } from '../contexts/stripeStoreContext';
import { useRootStore } from '../hooks/useRootStore';

const SetPaymentsPage = ({ match }) => {
  const { history } = useReactRouter();

  const stripeStore = useContext(StripeStoreContext);
  const rootStore = useRootStore();

  applySnapshot(stripeStore, {
    ...stripeStore,
    registration: match.params
  });

  applySnapshot(rootStore, {
    ...rootStore,
    isAuthenticated: true,
    pageState: 'AUTH'
  });

  history.replace('/payments');

  return null;
};

export default SetPaymentsPage;
