import CoachStudentList from "components/Students/CoachStudentList";
import { useRootStore } from "hooks/useRootStore";
import Wrapper from "pages/layout";
import React from "react";
import { CSVDownload } from "react-csv";
import { Button, Card, Container, Icon } from "tabler-react";
import { formatDateToLocalString } from "utils/dateFormat";
import { UseGetCoachStudents } from "../../modules/coach/hooks/UseGetCoachStudents";

const MembersPageCSV = () => {
  const { data, loading, loadData } = UseGetCoachStudents();

  let students = [];
  if (data?.students && !loading) {
    students =
      data?.students.map(
        ({
          id,
          first_name,
          last_name,
          phone_country_code,
          phone_number,
          email,
          current_balance,
          last_jump,
          last_tunnel_session,
          last_event,
        }) => ({
          id,
          first_name,
          last_name,
          phone_country_code,
          phone_number,
          email,
          current_balance,
          last_jump: formatDateToLocalString(last_jump),
          last_tunnel_session: formatDateToLocalString(last_tunnel_session),
          last_event: formatDateToLocalString(last_event),
        }),
      ) ?? [];
  }

  if (loading) {
    return (
      <Button loading color="secondary">
        Processing...
      </Button>
    );
  }

  return (
    <>
      {students.length > 0 && (
        <CSVDownload data={students} filename="students.csv" />
      )}
      <Button
        outline
        onClick={loadData}
        icon="download-cloud"
        color="secondary"
        disabled={loading}
      >
        CSV
      </Button>
    </>
  );
};
const MembersPage = (props) => {
  const { currentCoachTeam } = useRootStore();

  return (
    <Wrapper {...props} title="Customers">
      <Container>
        <Card>
          <Card.Header>
            <Card.Title>
              <Icon name="users" className="mr-2 ml-0 text-blue" />
              <strong>
                <a href="/">{currentCoachTeam.name}</a>
              </strong>{" "}
              // Members
            </Card.Title>
            <Card.Options>
              <Button.List className="m-0 p-0" align="right">
                <MembersPageCSV />
              </Button.List>
            </Card.Options>
          </Card.Header>
        </Card>
        <Card>
          <Card.Body>
            <CoachStudentList />
          </Card.Body>
        </Card>
      </Container>
    </Wrapper>
  );
};

export default MembersPage;
