import React from "react";
import Modal from "../../Modal";
import RegistrationGroupForm from "../Forms/RegistrationGroupForm";

const RegistrationGroupFormModal = ({ isOpen, toggle, group, campId }) => {
  return (
    <Modal
      content={
        <RegistrationGroupForm group={group} toggle={toggle} campId={campId} />
      }
      open={isOpen}
      onClose={toggle}
      title={group?.id ? "Edit Competition Group" : "New Competition Group"}
    />
  );
};

export default RegistrationGroupFormModal;
