import { Instance, types } from 'mobx-state-tree';

const RegistrationPayment = types.model({
  amount: types.string,
  camp_name: types.string,
  email: types.string,
  registration_code: types.string,
  registration_id: types.string
});

export const StripeStore = types
  .model({
    key: types.maybe(types.string),
    registration: types.maybe(RegistrationPayment)
  })
  .actions((self) => ({
    setRegistrationPayment(registrationPayment) {
      self.registration = RegistrationPayment.create(registrationPayment);
    }
  }));

export interface StripeStoreType extends Instance<typeof StripeStore> {}
