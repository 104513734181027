import gql from 'graphql-tag';

export const ADD_CAMP = gql`
  mutation ADD_CAMP($newCamp: AddCampInput!) {
    addCamp(newCamp: $newCamp) {
      auto_approval
      camp_description
      camp_email
      camp_name
      camp_phone
      camp_url
      camp_fees
      end
      event_type_name
      id
      is_public
      location
      location_id
      start
      status
    }
  }
`;
