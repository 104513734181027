import { observer } from 'mobx-react';
import Modal from '../../Modal';
import { Card, Grid, Header, Text } from 'tabler-react';
import React, { useMemo } from 'react';
import { formatAccountBalance } from '../../../utils/formatAccountBalance';
import { useRootStore } from '../../../hooks';

const ViewTransactionDetails = ({
  transaction,
  isModalOpen,
  toggleModal,
  teamId,
  studentId
}) => {
  const { currentCoachTeam } = useRootStore();
  // const { data: accountBalance } = useQuery<GET_ACCOUNT_BALANCE_TYPE>(
  //   GET_ACCOUNT_BALANCE,
  //   {
  //     variables: {
  //       studentId,
  //       teamId
  //     }
  //   }
  // );

  const amount = useMemo(() => {
    return formatAccountBalance(
      transaction.minutes,
      currentCoachTeam?.account_type_slug ?? transaction.accountTypeSlug
    );
  }, [transaction, currentCoachTeam]);
  return (
    <Modal
      content={
        <>
          <Grid.Row className="text-center">
            <Grid.Col>
              <h2>
                {transaction.studentFirstName} {transaction.studentLastName}
              </h2>
              <Text>{transaction.teamName}</Text>
            </Grid.Col>
          </Grid.Row>
          <Grid.Row className={'text-center my-3'}>
            <Grid.Col>
              <Header.H1>
                {transaction.timeAdded ? (
                  <Text.Small color="success">+ {amount}</Text.Small>
                ) : (
                  <Text.Small color="danger">- {amount}</Text.Small>
                )}
              </Header.H1>
            </Grid.Col>
          </Grid.Row>
          <Grid.Row className="text-center">
            <Grid.Col>
              {transaction.coachFirstName} {transaction.coachLastName}
            </Grid.Col>
          </Grid.Row>
          <Grid.Row className="text-center">
            <Grid.Col>
              {new Date(transaction.created_on).toLocaleString()}
            </Grid.Col>
          </Grid.Row>
          <Grid.Row className={'mt-3 text-center'}>
            <Grid.Col>{transaction.transactionTypeName}</Grid.Col>
          </Grid.Row>
        </>
      }
      open={isModalOpen}
      onClose={() => toggleModal(!isModalOpen)}
      title={'Transaction ID: ' + transaction.id}
    />
  );
};

export default observer(ViewTransactionDetails);
