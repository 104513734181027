import { onSnapshot, applySnapshot } from 'mobx-state-tree';
import React, { createContext, ReactNode, useState } from 'react';

import {
  TransactionStore,
  TransactionStoreType,
  TransactionStoreDefault
} from '../models/TransactionStore';

export const TransactionContext = createContext<TransactionStoreType | null>(
  null
);

interface TransactionProvider {
  children: ReactNode;
}

const getOrCreateStore = () => {
  const transactionStore = TransactionStoreDefault();
  const initData = localStorage.getItem('transactions');

  if (initData) {
    applySnapshot(transactionStore, JSON.parse(initData));
    return transactionStore;
  } else {
    return TransactionStore.create();
  }
};

export const TransactionProvider = (props: TransactionProvider) => {
  const [transactionStore] = useState(() => getOrCreateStore());

  onSnapshot(transactionStore, (_snapshot) => {
    const snapshot = { ..._snapshot };
    const data = JSON.stringify(snapshot);
    localStorage.setItem('transactions', data);
  });

  return (
    <TransactionContext.Provider value={transactionStore}>
      {props.children}
    </TransactionContext.Provider>
  );
};
