import gql from 'graphql-tag';

export const ADD_ROLE_TO_STAFF = gql`
  mutation ADD_ROLE_TO_STAFF($teamStaff: StaffRoleInput!) {
    addRoleToStaff(teamStaff: $teamStaff) {
      team {
        id
        name
      }
      studentDetails {
        id
        facebook_account_id
        notification_type_id
        email_verified
        phone_number
        phone_country_code
        phone_verified
        registration_id
        is_disabled_on
        created_on
        created_by
        updated_on
        updated_by
        preference_24h
        weight
        jump_weight
        preference_units
        email
        first_name
        last_name
        total_time
        balance
        profile_picture
        is_paid
        group_id
        team_id
        is_on_dashboard
        aid
        is_followed
        is_follower
        flag_type_id
        follower_flag_type
        is_camp_registered
        note
        user_duration
        option_name
        camp_slot_user_status
        registration_code
        status
        camp_id
        address1
        address2
        city
        country
        dob
        postal_code
        province
      }
      roles {
        id
        key
        name
      }
    }
  }
`;
