import React from 'react';
import { Button, Dimmer, Table } from 'tabler-react';
import { useQuery } from '@apollo/client';
import { GET_AIRCRAFTS } from '../../graphql/GET_AIRCRAFTS';

const AdminAircraftList = () => {
  const { data, loading } = useQuery(GET_AIRCRAFTS);
  return (
    <Dimmer active={loading}>
      <Table>
        <Table.Header>
          <Table.Row>
            <Table.ColHeader>ID</Table.ColHeader>
            <Table.ColHeader>Name</Table.ColHeader>
            <Table.ColHeader>Tail</Table.ColHeader>
            <Table.ColHeader>Type</Table.ColHeader>
            <Table.ColHeader>Capacity</Table.ColHeader>
            <Table.ColHeader>Team</Table.ColHeader>
            <Table.ColHeader></Table.ColHeader>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {!loading &&
            data &&
            data.aircraftsList.map((aircraft) => (
              <Table.Row key={aircraft.id}>
                <Table.Col>{aircraft.id}</Table.Col>
                <Table.Col>{aircraft.name}</Table.Col>
                <Table.Col>{aircraft.tail_number}</Table.Col>
                <Table.Col>{aircraft.aircraft_type}</Table.Col>
                <Table.Col>
                  {aircraft.capacity_min}/{aircraft.capacity_max}
                </Table.Col>
                <Table.Col>Team name</Table.Col>
                <Table.Col>
                  <Button
                    icon="edit"
                    color="white"
                    size="sm"
                    //onClick={() => history.push(`/aircraft/${aircraft.id}`)}
                  />
                </Table.Col>
              </Table.Row>
            ))}
        </Table.Body>
      </Table>
    </Dimmer>
  );
};

export default AdminAircraftList;
