import gql from "graphql-tag";

/**
 * Get id and name for now
 * We can extend this later
 */

export const GET_TEAM_BY_ID = gql`
  query GET_TEAM_BY_ID($teamId: ID!) {
    team(teamId: $teamId) {
      id
      name
      team_email
      team_phone
      team_phone_country_code
      default_currency
      invoice_terms
      stripe_account_id
      team_logo_square_image
      team_logo_wide_image
    }
  }
`;
