import { useMutation } from '@apollo/client';
import { Formik } from 'formik';
import React from 'react';
import { toast } from 'react-toastify';
import { Button, Form, Grid } from 'tabler-react';

import { UPDATE_INVOICE } from 'graphql/UPDATE_INVOICE';
import { GET_INVOICE } from 'graphql/GET_INVOICE';
import { Types } from '../../types/graphql'

interface IInvoiceEditNotesFormProps {
  invoice: Types.Invoice;
  setIsEdittingNotes: (isEdittingNotes: boolean) => void;
}

const InvoiceEditNotesForm = ({
  invoice,
  setIsEdittingNotes
}: IInvoiceEditNotesFormProps) => {
  const [updateInvoice] = useMutation(UPDATE_INVOICE);

  return (
    <Formik
      enableReinitialize={true}
      initialValues={{
        id: invoice.id,
        invoice_notes: invoice.invoice_notes
      }}
      onSubmit={async (values, { resetForm }) => {
        await updateInvoice({
          variables: {
            updateInvoiceInput: {
              id: values.id,
              invoice_notes: values.invoice_notes
            }
          },
          refetchQueries: [
            {
              query: GET_INVOICE,
              variables: {
                id: invoice?.id
              }
            }
          ]
        });
        setIsEdittingNotes(false);
        resetForm();
        toast.success('Invoice notes saved!');
      }}
    >
      {({ values, handleChange, isSubmitting, handleSubmit }) => (
        <>
          <Grid.Row>
            <Grid.Col width={12}>
              <Form.Group label="">
                <Form.Textarea
                  name="invoice_notes"
                  onChange={handleChange}
                  placeholder="Add notes to customer"
                  rows={3}
                  type="text"
                  value={values.invoice_notes}
                />
              </Form.Group>
            </Grid.Col>
          </Grid.Row>
          <Grid.Row>
            <Grid.Col width={12}>
              <Button
                disabled={isSubmitting}
                pill
                className="btn-gray-dark float-right btn-sm"
                onClick={handleSubmit}
              >
                {isSubmitting ? 'Processing...' : 'SUBMIT'}
              </Button>

              <Button
                pill
                color="white"
                className="float-right btn-sm"
                onClick={(e) => {
                  e.preventDefault();
                  setIsEdittingNotes(false);
                }}
              >
                CANCEL
              </Button>
            </Grid.Col>
          </Grid.Row>
        </>
      )}
    </Formik>
  );
};

export default InvoiceEditNotesForm;
