import gql from "graphql-tag";

export const GET_LOCATIONS = gql`
  query GET_LOCATIONS(
    $filter: LocationFilter
    $order: LocationOrder!
    $page: Int
  ) {
    locations(filter: $filter, order: $order, page: $page) {
      id
      key
      type
      name
      description
      address
      is_frozen
      latitude
      longitude
      email
      phone
      phone_country_code
      url
      distance
      postal_code
      city
      region
      province
      postal_code
      country
      locality
      status
      tunnel_type
      owner
      manufacturer
      open_since
      is_student_favorite
      is_team_added
      is_set_home
      total
      object_type
      chamber_diameter
      chamber_height
    }
  }
`;
