import React from 'react';
import { Link } from 'react-router-dom';
import { Button, Card, Grid, Tag, Text } from 'tabler-react';
import useReactRouter from 'use-react-router';

interface ITeamListItemProps {
  team: any;
}

const TeamListItem = ({ team }: ITeamListItemProps) => {
  const { history } = useReactRouter();

  return (
    <Grid.Col xs={12} sm={12} md={6} lg={4} xl={3}>
      <Card>
      <Card.Status
        color={
          team.is_published
            ? 'success'
            : 'secondary'
        }
        side
      />
        <Card.Header
          backgroundURL={
            team.team_logo_wide_image
              ? `/api/s3/uploads/${team.team_logo_wide_image}`
              : '//via.placeholder.com/800x300.png?text=+'
          }
          className="team-image"
        ></Card.Header>
        <Card.Body>
          <Grid.Col className="d-block">
          {team.slug &&
            <span className='text-muted float-right cursor-pointer' onClick={() => history.push(`/${team.slug}`)}>{team.slug}</span>
          }
          <Link
            to="#"
            className="text-inherit"
            onClick={() => history.push(`/team-details/${team.id}`)}
          >
            <h4>{team.name}</h4>
          </Link>
          </Grid.Col>
          <Grid.Col>
            {team.sports.map(
              (sport: { is_active: boolean; name: any }) =>
                sport?.is_active === true &&
              <Tag color="info" className="mr-1 mb-1">
                {sport.name}
              </Tag>
            )}
          </Grid.Col>
          <Grid.Col>
            {team.locations.map((location: { name: string }) =>
              <Button icon="map-pin" color="primary" size="sm" className="mr-1 mb-1">
                {location.name}
              </Button>
            )}
          </Grid.Col>
        </Card.Body>
        <Card.Footer>
          <Text className="float-left">
          <span
            className={`status-icon ${
              team.is_published ? 'bg-success' : 'bg-secondary'
            }`}
          />
          {`${team.is_published ? 'Active' : 'Inactive'}`}</Text>
          <Button.List align="right">
            <Button
              size="sm"
              color="secondary"
              onClick={() => history.push(`/team-details/${team.id}`)}
            >
              Manage
            </Button>
          </Button.List>
        </Card.Footer>
      </Card>
    </Grid.Col>
  );
};

export default TeamListItem;
