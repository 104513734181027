import React, { Fragment } from 'react';
import { formatMoney } from '../../utils/numberFormat';
import { Button, Tag } from 'tabler-react';
import { useMutation } from '@apollo/client';
import { DELETE_TEAM_LOAD_PAYMENT_TYPE } from '../../graphql/DELETE_TEAM_LOAD_PAYMENT_TYPE';
import { toast } from 'react-toastify';
import TeamLoadPaymentTypeModal from './Modal/TeamLoadPaymentTypeModal';
import { Types } from '../../types/graphql'

interface LoadPaymentTypeListItemProps {
  loadPaymentType: any;
}

const LoadPaymentTypeListItem = ({
  loadPaymentType
}: LoadPaymentTypeListItemProps) => {
  const [isOpen, setIsOpen] = React.useState(false);
  const [deleteTeamLoadPaymentType] = useMutation(
    DELETE_TEAM_LOAD_PAYMENT_TYPE,
    {
      refetchQueries: ['GET_TEAM_LOAD_PAYMENT_TYPES'],
      onCompleted: () => {
        toast.success('Load Payment Type Deleted');
      }
    }
  );

  const handleDelete = async (id) => {
    if (
      window.confirm('Are you sure you want to delete this load payment type?')
    ) {
      await deleteTeamLoadPaymentType({
        variables: {
          id
        }
      });
    }
  };

  return (
    <Fragment key={loadPaymentType.id}>
      <p className="mb-0">
        <Button
          icon={'x-circle'}
          className={'text-danger float-right'}
          color={'white'}
          onClick={() => handleDelete(loadPaymentType.id)}
        />
        <strong
          className={'text-primary cursor-pointer'}
          onClick={() => setIsOpen(true)}
        >
          {loadPaymentType.name}
        </strong>
      </p>
      <Tag>{loadPaymentType.load_payment_type_id}</Tag>{' '}
      {formatMoney(loadPaymentType.amount)}
      <hr className="mx-0 my-1" />
      <TeamLoadPaymentTypeModal
        isOpen={isOpen}
        toggleModal={() => setIsOpen(false)}
        loadPaymentType={loadPaymentType}
      />
    </Fragment>
  );
};

export default LoadPaymentTypeListItem;
