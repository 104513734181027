import { useQuery } from '@apollo/client';
import RecentCoachList from 'components/Coaches/RecentCoachList';
import MeritCount from 'components/Home/MeritsTotal';
import PaymentsCount from 'components/Home/PaymentsTotal';
import RegistrationsCount from 'components/Home/RegistrationsTotal';
import MembersCount from 'components/Home/StudentsTotal';
import RecentStudentList from 'components/Students/RecentStudentList';
import RecentStudentRegistrationsList from 'components/Students/RecentStudentRegistrationsList';
import { ADMIN_HOME_QUERY } from 'graphql/ADMIN_HOME_QUERY';
import { ADMIN_HOME_QUERY as ADMIN_HOME_QUERY_TYPE } from 'graphql/types/ADMIN_HOME_QUERY';
import { EOrderOptions } from 'hooks/useAutocomplete';
import React, { useMemo } from 'react';
import { Grid } from 'tabler-react';

const AdminHome = () => {
  const { loading: adminLoading, data: adminData } =
    useQuery<ADMIN_HOME_QUERY_TYPE>(ADMIN_HOME_QUERY, {
      variables: {
        registeredStudentLimit: 10,
        registeredStudentOrder: 'recent',
        studentOrder: EOrderOptions.newest,
        studentFilter: { limit: 10 }
      }
    });

  const recentStudents = useMemo(
    () => adminData?.students ?? [],
    [adminLoading, adminData?.students]
  );

  const recentRegisteredStudents = useMemo(
    () => adminData?.getRegisteredStudents ?? [],
    [adminLoading, adminData?.getRegisteredStudents]
  );

  return (
    <div className="container">
      <Grid.Row>
        <PaymentsCount />
        <RegistrationsCount />
        <MembersCount />
        <MeritCount />
      </Grid.Row>
      <Grid.Row>
        <RecentCoachList />
        <RecentStudentList recentStudents={recentStudents} />
        <RecentStudentRegistrationsList
          recentRegisteredStudents={recentRegisteredStudents}
        />
      </Grid.Row>

      {/**
       * @TODO Will be using this template in the future
       */}
      {/* <Grid.Row>
            <Grid.Col width={12} sm={12} lg={12}>
              <h3>This Month</h3>
            </Grid.Col>
            <Grid.Col width={6} sm={4} lg={2}>
              <StatsCard layout={1} movement={6} total="43" label="New Users" />
            </Grid.Col>
            <Grid.Col width={6} sm={4} lg={2}>
              <StatsCard
                layout={1}
                movement={-3}
                total="17"
                label="User Events"
              />
            </Grid.Col>
            <Grid.Col width={6} sm={4} lg={2}>
              <StatsCard
                layout={1}
                movement={9}
                total="7"
                label="New Replies"
              />
            </Grid.Col>
            <Grid.Col width={6} sm={4} lg={2}>
              <StatsCard
                layout={1}
                movement={3}
                total="27.3k"
                label="Followers"
              />
            </Grid.Col>
            <Grid.Col width={6} sm={4} lg={2}>
              <StatsCard
                layout={1}
                movement={-2}
                total="$95"
                label="Daily earnings"
              />
            </Grid.Col>
            <Grid.Col width={6} sm={4} lg={2}>
              <StatsCard
                layout={1}
                movement={-1}
                total="621"
                label="Products"
              />
            </Grid.Col>
          </Grid.Row> */}
    </div>
  );
};

export default AdminHome;
