import React, { useMemo } from 'react';
import { Dimmer, Grid, Table } from 'tabler-react';
import { GET_ALL_GEAR_MODELS } from '../../graphql/GET_ALL_GEAR_MODELS';
import { useQuery } from '@apollo/client';
import ModelListItem from './ModelListItem';
import { Types } from '../../types/graphql'

interface IModelListProps {
  nameFilter?: string;
}

const ModelList = ({ nameFilter }: IModelListProps) => {
  const { data, loading, error } = useQuery(GET_ALL_GEAR_MODELS);
  const models = useMemo(() => {
    if (!loading && !error && data)
      return data.getGearModels.filter(
        (model: Types.GearModel) =>
          model?.name.toLowerCase().includes(nameFilter.toLowerCase())
      );
    return null;
  }, [data, loading, error, nameFilter]);
  return (
    <>
      <Grid.Col width={12}>
        <Grid.Row></Grid.Row>
        <Grid.Row className="product-list">
          <Dimmer active={loading} loader={loading}>
            <Table responsive className="card-table table-vcenter text-nowrap">
              <Table.Header>
                <Table.Row>
                  <Table.ColHeader>ID</Table.ColHeader>
                  <Table.ColHeader>Name</Table.ColHeader>
                  <Table.ColHeader>Type</Table.ColHeader>
                  <Table.ColHeader>Manufacturer</Table.ColHeader>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {models &&
                  models.map((model: Types.GearModel) => (
                    <ModelListItem model={model} key={model.id} />
                  ))}
              </Table.Body>
            </Table>
          </Dimmer>
        </Grid.Row>
      </Grid.Col>
    </>
  );
};

export default ModelList;
