import debounce from 'lodash.debounce';
import { useEffect, useMemo, useRef } from 'react';

type CallbackProps = () => void | Promise<void>;

const useDebounceCallback = (callback: CallbackProps) => {
  const ref = useRef<CallbackProps>();

  useEffect(() => {
    ref.current = callback;
  }, [callback]);

  const debouncedCallback = useMemo(() => {
    const func = async () => {
      await ref.current?.();
    };

    return debounce(func, 300);
  }, []);

  return debouncedCallback;
};

export default useDebounceCallback;
