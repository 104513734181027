import gql from 'graphql-tag';

export const GET_ADMINS = gql`
  query GET_ADMINS($order: OrderOptions, $limit: Int) {
    getAdmins(order: $order, limit: $limit) {
      id
      username
      is_disabled
      token
    }
  }
`;
