import gql from 'graphql-tag';

export const GET_PRODUCTS_BY_TEAM = gql`
  query GET_PRODUCTS_BY_TEAM(
    $filter: ProductByTeamFilter
  ) {
    getProductsByTeam(filter: $filter) {
      id
      title
      amount
    }
  }
`;
