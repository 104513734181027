import { useRootStore } from "hooks";
import { observer } from "mobx-react";
import React, { FC } from "react";
import ReactS3Uploader from "react-s3-uploader";
import { Button, Dropdown } from "tabler-react";
import ErrorModal from "../../../components/Common/Modals/ErrorModal";

interface UploadImageDropdownProps {
  imageName?: string;
  onErrorHandler: (message: string) => void;
  onFinishedHandler: (response: { fileKey: string }) => void;
  callBack: (fileKey: string | null) => Promise<void>;
}

const UploadImageDropdown: FC<UploadImageDropdownProps> = ({
  callBack,
  onErrorHandler,
  onFinishedHandler,
  imageName,
}) => {
  const rootStore = useRootStore();
  const [isOpen, setIsOpen] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState("");
  const toggle = () => setIsOpen(!isOpen);

  const handleFileChange = (file, next) => {
    const isImage = file && file.type.startsWith("image/");
    const isValidSize = file && file.size <= 20 * 1024 * 1024; // 20MB in bytes

    if (!isImage) {
      setErrorMessage("Please upload an image file.");
      toggle();
      return;
    }

    if (!isValidSize) {
      setErrorMessage("File size must be less than 20MB.");
      toggle();
      return;
    }
    next(file);
  };

  return (
    <>
      {rootStore.loading ? (
        <Button
          loading
          color="secondary"
          className="border-0 p-1 shadow-none"
          block
        >
          Loading...
        </Button>
      ) : (
        <label
          htmlFor="s3-upload"
          className="dropdown-item cursor-pointer mb-0"
        >
          {imageName ? "Change" : "Upload"}
        </label>
      )}
      <ReactS3Uploader
        accept="image/*"
        autoUpload={true}
        className="d-none"
        contentDisposition="auto"
        id="s3-upload"
        signingUrl="/api/s3/sign"
        onError={onErrorHandler}
        onFinish={onFinishedHandler}
        onProgress={() => rootStore.setLoading(true)}
        uploadRequestHeaders={{
          "x-amz-acl": "private",
        }}
        preprocess={handleFileChange}
      />
      {imageName && (
        <Dropdown.Item
          to="#!"
          onClick={() => {
            callBack(null);
          }}
        >
          Remove
        </Dropdown.Item>
      )}
      <ErrorModal
        message={errorMessage}
        title={"Image upload error"}
        isOpen={isOpen}
        toggle={toggle}
      />
    </>
  );
};

export default observer(UploadImageDropdown);
