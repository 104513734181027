import React from 'react';
import { Formik } from 'formik';
import FormField from '../../FormField';
import { NumericFormat } from 'react-number-format';
import Select from 'react-select';
import { LoadPaymentTypes } from '../../constants';
import { Button, Card, Form } from 'tabler-react';
import { useMutation } from '@apollo/client';
import { ADD_TEAM_LOAD_PAYMENT_TYPE } from '../../../graphql/ADD_TEAM_LOAD_PAYMENT_TYPE';
import { EDIT_TEAM_LOAD_PAYMENT_TYPE } from '../../../graphql/EDIT_TEAM_LOAD_PAYMENT_TYPE';

interface TeamLoadPaymentTypeFormProps {
  toggleModal?: () => void;
  loadPaymentType: any;
}

const TeamLoadPaymentTypeForm = ({
  toggleModal,
  loadPaymentType
}: TeamLoadPaymentTypeFormProps) => {
  const [addTeamLoadPaymentType] = useMutation(ADD_TEAM_LOAD_PAYMENT_TYPE, {
    refetchQueries: ['GET_TEAM_LOAD_PAYMENT_TYPES']
  });
  const [updateTeamLoadPaymentType] = useMutation(EDIT_TEAM_LOAD_PAYMENT_TYPE, {
    refetchQueries: ['GET_TEAM_LOAD_PAYMENT_TYPES']
  });
  const renderForm = ({
    values,
    setFieldValue,
    handleChange,
    handleSubmit
  }) => {
    return (
      <Card>
        <Card.Body>
          <Form.Group label="Name">
            <FormField
              name={'name'}
              value={values.name}
              onChange={handleChange}
              placeholder="Name"
            />
          </Form.Group>
          <Form.Group label="Load Payment Type">
            <Select
              styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
              menuPortalTarget={document.body}
              name="load_payment_type_id"
              value={LoadPaymentTypes.map((loadPaymentType) => ({
                label: loadPaymentType.name,
                value: loadPaymentType.slug
              })).find((l) => l.value === values.load_payment_type_id)}
              options={LoadPaymentTypes.map((loadPaymentType) => ({
                label: loadPaymentType.name,
                value: loadPaymentType.slug
              }))}
              onChange={(option) =>
                setFieldValue('load_payment_type_id', option.value)
              }
            />
          </Form.Group>
          <NumericFormat
            customInput={FormField}
            name="amount"
            label="Amount"
            placeholder="Amount"
            value={values.amount}
            prefix="$"
            thousandSeparator=","
            onChange={(e) =>
              setFieldValue(
                'amount',
                parseFloat(e.target.value.replace('$', '').replaceAll(',', ''))
              )
            }
          />
        </Card.Body>
        <Card.Footer>
          <Button
            className={'float-right'}
            pill
            size={'sm'}
            color="gray-dark"
            onClick={() => {
              handleSubmit();
            }}
          >
            Save
          </Button>
          <Button
            className={'float-right'}
            pill
            size={'sm'}
            color="white"
            onClick={() => {
              if (toggleModal) {
                toggleModal()
              }
            }}
          >
            Cancel
          </Button>
        </Card.Footer>
      </Card>
    );
  };
  return (
    <Formik
      enableReinitialize={true}
      initialValues={{
        id: loadPaymentType ? loadPaymentType.id : undefined,
        name: loadPaymentType ? loadPaymentType.name : '',
        load_payment_type_id: loadPaymentType
          ? loadPaymentType.load_payment_type_id
          : undefined,
        team_id: loadPaymentType ? loadPaymentType.team_id : undefined,
        amount: loadPaymentType ? loadPaymentType.amount : 0
      }}
      onSubmit={(values, { setSubmitting, resetForm }) => {
        if (values.id) {
          updateTeamLoadPaymentType({
            variables: { updateTeamLoadPaymentType: values }
          });
        } else {
          addTeamLoadPaymentType({
            variables: { addTeamLoadPaymentType: values }
          });
        }
        resetForm();
        setSubmitting(false);
        if (toggleModal) {
          toggleModal()
        }
      }}
    >
      {(formData) => renderForm(formData)}
    </Formik>
  );
};

export default TeamLoadPaymentTypeForm;
