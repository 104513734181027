import { cast, detach, Instance, SnapshotIn, types } from 'mobx-state-tree'
import { Team } from 'models/TeamStore'

const AuthMerit = types.model({
  member_id: types.optional(types.string, '', [null])
})

const USPAMerit = types.model({
  first_name: types.optional(types.string, '', [undefined]),
  last_name: types.optional(types.string, '', [undefined]),
  email: types.optional(types.string, '', [undefined]),
  updated_on: types.optional(types.string, '', [undefined]),
  merits_uspa_member_expiration: types.optional(types.string, '', [null]),
  merits_uspa_member_id: types.optional(types.string, '', [null]),
  template_id: types.optional(types.string, '', [null]),
  template_title: types.optional(types.string, '', [null])
})

const Role = types.model({
  id: types.optional(types.string, '', [null]),
  key: types.optional(types.string, '', [null]),
  name: types.optional(types.string, '', [null])
})

const Seal = types.model({
  rigger_seal: types.optional(types.string, '', [undefined])
})

export const User = types
  .model('UserModel', {
    address1: types.optional(types.string, '', [null]),
    address2: types.optional(types.string, '', [null]),
    auth_merits: types.optional(types.array(AuthMerit), []),
    camp_id: types.optional(types.string, '', [null, undefined]),
    camp_name: types.optional(types.string, '', [null, undefined]),
    city: types.optional(types.string, '', [null]),
    country: types.optional(types.string, '', [null]),
    dob: types.optional(types.string, '', [null]),
    email: types.optional(types.string, '', [null]),
    first_name: types.optional(types.string, '', [null]),
    height: types.optional(types.integer, 0, [null, undefined]),
    id: types.optional(types.string, '', [null]),
    is_disabled_on: types.optional(types.string, '', [null, undefined]),
    jump_weight: types.optional(types.integer, 0, [null]),
    last_name: types.optional(types.string, '', [null]),
    middle_name: types.optional(types.string, '', [null, undefined]),
    phone_country_code: types.optional(types.number, 0, [null, undefined]),
    phone_number: types.optional(types.string, '', [null]),
    postal_code: types.optional(types.string, '', [null]),
    preference_24h: types.optional(types.boolean, false, [null, undefined]),
    preference_units: types.optional(types.boolean, false, [null, undefined]),
    preferred_name: types.optional(types.string, '', [null, undefined]),
    profile_avatar: types.optional(types.string, '', [null, undefined]),
    profile_picture: types.optional(types.string, '', [null, undefined]),
    province: types.optional(types.string, '', [null]),
    registration_id: types.optional(types.string, '', [null]),
    shirt_size_id: types.optional(types.number, 0, [null, undefined]),
    teams: types.optional(types.array(Team), []),
    token: types.optional(types.string, '', [null, undefined]),
    user_registrations_id: types.optional(types.integer, 0, [null, undefined]),
    uspa: types.optional(types.array(USPAMerit), []),
    weight: types.optional(types.integer, 0, [null])
  })
  .actions((self) => ({
    set<
      K extends keyof SnapshotIn<typeof self>,
      T extends SnapshotIn<typeof self>
    >(key: K, value: T[K]) {
      self[key] = cast(value)
    }
  }))

const OnDeckStudent = types.model({
  id: types.optional(types.string, '', [null]),
  studentDetails: types.maybe(User)
})

const Staff = types
  .model({
    roles: types.optional(types.array(Role), []),
    studentDetails: types.maybe(User),
    seal: types.optional(Seal, {}, [null, undefined])
  })
  .actions((self) => ({
    set<
      K extends keyof SnapshotIn<typeof self>,
      T extends SnapshotIn<typeof self>
    >(key: K, value: T[K]) {
      self[key] = cast(value)
    }
  }))

export const UserStoreDefault = () => {
  return UserStore.create({
    user: undefined
  })
}

export const UserStore = types
  .model({
    onDeckStudents: types.optional(types.array(OnDeckStudent), []),
    registeredStudents: types.optional(types.array(User), []),
    staffs: types.optional(types.array(Staff), []),
    user: types.maybe(User),
    users: types.optional(types.array(User), [])
  })
  .actions((self) => ({
    detach(treeItem) {
      if (treeItem) {
        detach(treeItem)
      }
    },
    loadUser(user: any) {
      self.user = User.create(user)
    }
  }))

export interface UserStoreType extends Instance<typeof UserStore> {}
