import gql from 'graphql-tag';

export const GET_LATEST_USER_EVENT_BY_TYPE_IDS = gql`
  query GET_LATEST_USER_EVENT_BY_TYPE_IDS(
    $student_id: Int!
    $team_id: Int!
    $user_event_type_ids: [Int!]!
  ) {
    getLatestUserEventByTypeIds(
      student_id: $student_id
      team_id: $team_id
      user_event_type_ids: $user_event_type_ids
    ) {
      id
      status
      user_event_type_id
      load_id
      location_id
      camp_id
      team_id
      student_id
      camp_id
      created_on
    }
  }
`;
