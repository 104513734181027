import gql from 'graphql-tag';

export const GET_ORGS = gql`
  query GET_ORGS($filter: OrgFilter) {
    orgs(filter: $filter) {
      id
      slug
      name
      org_type
      image
      image_favicon
      image_square
      email
      phone
      phone_country_code
      website
      address1
      address2
      city
      province
      postal_code
      country
    }
  }
`;
