import { useQuery } from '@apollo/client';
import productImagePlaceholder from 'assets/images/product-image-placeholder.png';
import ProductListItemEdit from 'components/Product/Forms/ProductListItemEdit';
import { EUserTypes } from 'components/User/userTypes';
import { ProductStoreContext } from 'contexts/productContext';
import * as DOMPurify from 'dompurify';
import { GET_TEAM_BY_ID } from 'graphql/GET_TEAM_BY_ID';
import { useRootStore } from 'hooks';
import { observer } from 'mobx-react';
import { clone } from 'mobx-state-tree';
import React, { useContext, useState } from 'react';
import ReactHtmlParser from 'react-html-parser';
import { useParams } from 'react-router';
import { Card, GalleryCard, Grid, Icon, Tag } from 'tabler-react';
import { formatMoney } from 'utils/numberFormat';
import { convertToTime } from 'utils/convertToTime';

const ProductListItemView = () => {
  const productStore = useContext(ProductStoreContext);
  const { productId } = useParams<{ productId: string }>();
  const { currentCoachTeam, currentUser } = useRootStore();
  const product = productStore.getProductById(parseInt(productId));

  const [isEditing, setIsEditing] = useState<boolean>(false);
  const { loading, data } = useQuery(GET_TEAM_BY_ID, {
    variables: {
      teamId: product.team_id
    }
  });

  if (!product) {
    return <p>Could not find product with id: {productId}</p>;
  }

  const productClone = clone(product);

  return isEditing ? (
    <ProductListItemEdit
      product={product}
      productClone={productClone}
      setIsEditing={setIsEditing}
    />
  ) : (
    <Grid.Row className="product-details">
      <Grid.Col lg={9} sm={12}>
        <Card>
          <Card.Header>
            <Card.Title>{product.title}</Card.Title>
            {((currentCoachTeam &&
              currentCoachTeam.id === parseInt(product.team_id)) ||
              currentUser.type === EUserTypes.admin) && (
              <Card.Options>
                <div className="float-right mr-5">
                  {' '}
                  {!loading && data.team.name}{' '}
                </div>
                <Icon link name="edit" onClick={() => setIsEditing(true)} />
              </Card.Options>
            )}
          </Card.Header>
          <Card.Body>
            <Grid.Row className="mb-3 textEditor-display ws-prewrap">
              <Grid.Col>
                {ReactHtmlParser(DOMPurify.sanitize(product.description))}
              </Grid.Col>
            </Grid.Row>
          </Card.Body>
          <Card.Footer>
            <h3 className="float-left">
              {formatMoney(product.amount)} {product.currency}
            </h3>
            {currentUser.type !== EUserTypes.student && (
              <>
                <Tag color="success" className="float-right ml-2">
                  {product.quantity !== product.amount && (
                    <>
                      {product.account_type_id === 1 &&
                        '' + convertToTime(product.quantity)}
                    </>
                  )}
                </Tag>
                <Tag color="gray-dark" className="float-right ml-2">
                  {product.account_type_id === 1 &&
                    formatMoney((product.amount / product.quantity) * 60) +
                      '/hr'}
                </Tag>
                <Tag className="float-right">
                  {product.status.toUpperCase()}
                </Tag>
              </>
            )}
          </Card.Footer>
        </Card>
      </Grid.Col>

      <Grid.Col lg={3} sm={10} xs={12}>
        <GalleryCard>
          <GalleryCard.Image
            className="product-image w-100"
            src={
              product.image
                ? `/api/s3/uploads/${product.image}`
                : productImagePlaceholder
            }
          />
        </GalleryCard>
      </Grid.Col>
    </Grid.Row>
  );
};

export default observer(ProductListItemView);
