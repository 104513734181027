import { useMutation, useQuery } from '@apollo/client';
import { Formik } from 'formik';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { Button, Card, Form } from 'tabler-react';

import { ADD_USER_EVENT } from '../../../graphql/ADD_USER_EVENT';
import { GET_LATEST_USER_EVENT_BY_TYPE_IDS } from '../../../graphql/GET_LATEST_USER_EVENT_BY_TYPE_IDS';
import { UPDATE_USER_EVENT } from '../../../graphql/UPDATE_USER_EVENT';
import { formatDate } from '../../../utils/dateFormat';
import DatePicker from '../../DatePicker';
import { EUserEvents, EUserEventsId } from '../UserEvents';

interface IDocumentFormProps {
  currentCoachTeam: any;
  student: any;
}

const DocumentForm = ({ currentCoachTeam, student }: IDocumentFormProps) => {
  const [isPaperWaiverSigned, setIsPaperWaiverSigned] = useState<boolean>(
    false
  );

  const [addUserEvent] = useMutation(ADD_USER_EVENT, {
    onCompleted: () => toast.success('Document updated.')
  });

  const [updateUserEvent] = useMutation(UPDATE_USER_EVENT, {
    onCompleted: () => toast.success('Document updated.')
  });

  const variables = {
    student_id: parseInt(student.id),
    team_id: parseInt(currentCoachTeam.id),
    user_event_type_ids: [EUserEventsId.physical_waiver]
  };

  const { loading, error, data } = useQuery(GET_LATEST_USER_EVENT_BY_TYPE_IDS, {
    variables
  });

  const documentExpire = (dateProp) =>{

    const yearAgo = new Date(new Date().setFullYear(new Date().getFullYear() -1));
    const pastDate = new Date(dateProp);

    return(yearAgo <= pastDate);
  }

  useEffect(() => {
    if (!loading && data) {
      setIsPaperWaiverSigned(
        Number(data.getLatestUserEventByTypeIds?.user_event_type_id) ===
        EUserEventsId.physical_waiver
      );
    }
  }, [data]);  

  if (error) {
    return <p>Error: {error.message}</p>;
  }

  return (
    <Formik
      enableReinitialize={true}
      initialValues={{
        id: data?.getLatestUserEventByTypeIds?.id || '',
        paper_waiver: data?.getLatestUserEventByTypeIds?.created_on
          ? moment(data?.getLatestUserEventByTypeIds?.created_on).format(
              moment.HTML5_FMT.DATE
            )
          : ''
      }}
      onSubmit={async (values, { setSubmitting }) => {
        if (isPaperWaiverSigned) {
          await updateUserEvent({
            variables: {
              userEvent: {
                id: values.id,
                status: EUserEvents.physical_waiver,
                user_event_type_id: EUserEventsId.physical_waiver,
                location_id: currentCoachTeam.default_location_id,
                team_id: currentCoachTeam.id,
                student_id: student.id,
                created_on: values.paper_waiver
              }
            },
            refetchQueries: [
              {
                query: GET_LATEST_USER_EVENT_BY_TYPE_IDS,
                variables
              }
            ]
          });
        } else {
          await addUserEvent({
            variables: {
              userEvent: {
                created_on: values.paper_waiver || formatDate(new Date()),
                location_id: currentCoachTeam.default_location_id,
                status: EUserEvents.physical_waiver,
                student_id: student.id,
                team_id: currentCoachTeam.id,
                user_event_type_id: EUserEventsId.physical_waiver
              }
            },
            refetchQueries: [
              {
                query: GET_LATEST_USER_EVENT_BY_TYPE_IDS,
                variables
              }
            ]
          });
        }

        setSubmitting(false);
      }}
    >
      {({ values, isSubmitting, handleSubmit, setFieldValue }) => (
        <form className="card" onSubmit={handleSubmit}>
          <Card.Header>
            <Card.Title>Documents</Card.Title>
          </Card.Header>
          <Card.Body>
            <Form.Group label="Paper Waiver">
              <Form.InputGroup>
                <DatePicker
                  className={`form-control ${
                    isPaperWaiverSigned && documentExpire(values.paper_waiver)
                      ? 'is-valid state-valid'
                      : 'is-invalid state-invalid'
                  }`}
                  name="paper_waiver"
                  value={values.paper_waiver}
                  minDate={new Date(new Date().setFullYear(new Date().getFullYear() -1))}
                  onChange={(date) =>
                    setFieldValue(
                      'paper_waiver',
                      formatDate(new Date(date).getTime())
                    )
                  }
                  placeholder="mm/dd/yyyy"
                />
                {!isPaperWaiverSigned && (
                  <Form.InputGroupAppend>
                    <Button color={isPaperWaiverSigned && documentExpire(values.paper_waiver)
                      ? 'green'
                      : 'red'} size="sm" type="submit">
                      Complete Now
                    </Button>
                  </Form.InputGroupAppend>
                )}
              </Form.InputGroup>
            </Form.Group>
          </Card.Body>
          {isPaperWaiverSigned && (
            <Card.Footer>
              <Button.List align="right">
                <Button
                  disabled={isSubmitting}
                  loading={isSubmitting}
                  pill
                  color="gray-dark"
                  size="sm"
                  type="submit"
                >
                  UPDATE
                </Button>
              </Button.List>
            </Card.Footer>
          )}
        </form>
      )}
    </Formik>
  );
};

export default DocumentForm;
