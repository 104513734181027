import React, { useMemo } from 'react';
import { Dimmer, Grid, Table } from 'tabler-react';
import { GET_ALL_GEAR_MANUFACTURERS } from '../../graphql/GET_ALL_GEAR_MANUFACTURERS';
import { useQuery } from '@apollo/client';
import ManufacturerListItem from './ManufacturerListItem';
import { Types } from '../../types/graphql'

interface IManufactuereListProps {
  nameFilter?: string;
}

const ManufactuereList = ({ nameFilter }: IManufactuereListProps) => {
  const { data, loading, error } = useQuery(GET_ALL_GEAR_MANUFACTURERS);
  const manufacturers = useMemo(() => {
    if (!loading && !error && data)
      return data.getGearManufacturers.filter(
        (manufacturer: Types.GearManufacturer) =>
          manufacturer?.name.toLowerCase().includes(nameFilter.toLowerCase())
      );
    return null;
  }, [data, loading, error, nameFilter]);
  return (
    <Dimmer active={loading} loader={loading}>
      <Grid.Col width={12}>
        <Grid.Row className="product-list">
          <Table responsive className="card-table table-vcenter text-nowrap">
            <Table.Header>
              <Table.Row>
                <Table.ColHeader>ID</Table.ColHeader>
                <Table.ColHeader>Name</Table.ColHeader>
                <Table.ColHeader>Slug</Table.ColHeader>
                <Table.ColHeader></Table.ColHeader>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {manufacturers &&
                manufacturers.map(
                  (
                    manufacturer: Types.GearManufacturer
                  ) => (
                    <ManufacturerListItem
                      manufacturer={manufacturer}
                      key={`manufacturer-${manufacturer.id}`}
                    />
                  )
                )}
            </Table.Body>
          </Table>
        </Grid.Row>
      </Grid.Col>
    </Dimmer>
  );
};

export default ManufactuereList;
