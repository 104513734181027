import gql from 'graphql-tag';

export const GET_AIRCRAFTS_BY_TEAM_ID = gql`
  query GET_AIRCRAFTS_BY_TEAM_ID($team_id: ID!) {
    teamAircrafts(teamId: $team_id) {
      id
      name
      tail_number
      aircraft_type
      aircraft_notes
      capacity_max
      capacity_min
    }
  }
`;
