import { useLazyQuery, useMutation } from "@apollo/client";
import { Formik } from "formik";
import React from "react";
import PhoneInput from "react-phone-input-2";
import { Button, Card, Form, Grid, Header } from "tabler-react";
import * as Yup from "yup";
import { GET_EMAIL_VALIDATION } from "../../../graphql/GET_EMAIL_VALIDATION";
import { GET_PHONE_VALIDATION } from "../../../graphql/GET_PHONE_VALIDATION";
import { GET_STUDENT_BY_ID } from "../../../graphql/GET_STUDENT_BY_ID";
import { UPDATE_STUDENT_MUTATION } from "../../../graphql/UPDATE_STUDENT_MUTATION";
import useSendActivationEmail from "../../../modules/student/hooks/useSendActivationEmail";
import { Types } from "../../../types/graphql";

const StudentClaimAccountForm = () => {
  const [showForm, setShowForm] = React.useState(false);
  const [student, setStudent] = React.useState<Types.Student>(null);
  return (
    <Card>
      <Card.Body>
        {showForm && student && (
          <StudentClaimAccountInnerForm
            student={student}
            setStudent={setStudent}
            setShowForm={setShowForm}
          />
        )}
        {!showForm && (
          <div>
            <StudentIDSearchField
              setShowForm={setShowForm}
              setStudent={setStudent}
            />
          </div>
        )}
      </Card.Body>
    </Card>
  );
};

export default StudentClaimAccountForm;

const StudentIDSearchField = ({ setShowForm, setStudent }) => {
  const [getStudentById] = useLazyQuery(GET_STUDENT_BY_ID);

  return (
    <Formik
      initialValues={{ studentId: "" }}
      onSubmit={async (values, { setFieldError, setSubmitting }) => {
        if (!values.studentId) {
          setFieldError("studentId", "SKY.D is required");
          return;
        }
        const result = await getStudentById({
          variables: { student_id: values.studentId },
        });

        if (!result?.data) {
          setFieldError(
            "studentId",
            `Account with ID ${values.studentId} not found!`,
          );
        } else {
          if (
            result.data.getStudentById.email &&
            result.data.getStudentById.registration_id
          ) {
            setFieldError(
              "studentId",
              `Account with ID ${values.studentId} already activated!`,
            );
          } else {
            setStudent(result.data.getStudentById);
            setShowForm(true);
          }
        }
        setSubmitting(false);
      }}
    >
      {({
        values,
        handleChange,
        handleSubmit,
        errors,
        touched,
        isSubmitting,
      }) => (
        <>
          <Header.H4>Account Search</Header.H4>
          <Form onSubmit={handleSubmit}>
            <Grid.Row>
              <Grid.Col md={8} lg={8} sm={12}>
                <Form.Group label={"User ID/SKY.D:"}>
                  <Form.Input
                    name="studentId"
                    value={values.studentId}
                    placeholder="Get SKY.D from your coach"
                    onChange={handleChange}
                  />
                  {errors.studentId && touched.studentId && (
                    <span className={"text-danger"}>{errors.studentId}</span>
                  )}
                </Form.Group>
              </Grid.Col>
              <Grid.Col md={4} lg={4} sm={12}>
                <Button
                  className={"float-right mt-5"}
                  pill
                  color={"primary"}
                  disabled={isSubmitting}
                >
                  Search
                </Button>
              </Grid.Col>
            </Grid.Row>
          </Form>
        </>
      )}
    </Formik>
  );
};

const StudentClaimAccountInnerFormValidationSchema = Yup.object().shape({
  first_name: Yup.string().required("This field is required."),
  last_name: Yup.string().required("This field is required."),
  email: Yup.string().email().required("This field is required."),
});
const StudentClaimAccountInnerForm = ({ student, setShowForm, setStudent }) => {
  const [updateStudent] = useMutation(UPDATE_STUDENT_MUTATION);
  const { sendActivationEmail } = useSendActivationEmail();

  const [validateEmail, { data: emailData }] = useLazyQuery(
    GET_EMAIL_VALIDATION,
    { fetchPolicy: "network-only" },
  );

  const [validatePhone, { data: phoneData }] = useLazyQuery(
    GET_PHONE_VALIDATION,
    { fetchPolicy: "network-only" },
  );

  const handleValidateEmail = async (email) => {
    if (email === "") return;

    const duplicateEmail = await validateEmail({
      variables: { email },
    });
  };

  const handleValidatePhone = async (phone) => {
    if (phone.length <= 1) return;
    const duplicatePhone = await validatePhone({
      variables: { phone },
    });
  };
  return (
    <Formik
      initialValues={{
        first_name: student.first_name ?? "",
        last_name: student.last_name ?? "",
        email: student.email ?? "",
        phone_number: student.phone_number ?? "",
        phone_country_code: student.phone_country_code ?? 1,
      }}
      validationSchema={StudentClaimAccountInnerFormValidationSchema}
      onSubmit={async (values, { resetForm, setFieldError, setSubmitting }) => {
        let errorFound = false;
        if (emailData?.validateEmail) {
          setFieldError(
            "email",
            "Email already exists. Login or reset password. Contact SUPPORT if you need further assistance.",
          );
          errorFound = true;
        }

        if (phoneData?.validatePhone) {
          setFieldError(
            "phone_number",
            "Phone number already exists. Contact SUPPORT.",
          );
          errorFound = true;
        }
        if (errorFound) {
          setSubmitting(false);
          return false;
        }
        await updateStudent({
          variables: {
            student: {
              ...values,
              id: student.id,
            },
          },
        });
        await sendActivationEmail({
          variables: {
            email: values.email,
          },
        });
        setSubmitting(false);
        resetForm();
        setStudent(null);
        setShowForm(false);
      }}
    >
      {({
        values,
        handleChange,
        handleSubmit,
        setFieldValue,
        errors,
        touched,
        isSubmitting,
      }) => (
        <>
          <Header.H4>Update Account Details</Header.H4>
          <Form onSubmit={handleSubmit}>
            <Form.Group label={"First Name*"}>
              <Form.Input
                name="first_name"
                value={values.first_name}
                onChange={handleChange}
              />
              <span className="field-error text-danger">
                {errors.first_name && touched.first_name && errors.first_name}
              </span>
            </Form.Group>

            <Form.Group label={"Last Name*"}>
              <Form.Input
                name="last_name"
                value={values.last_name}
                onChange={handleChange}
              />
              <span className="field-error text-danger">
                {errors.last_name && touched.last_name && errors.last_name}
              </span>
            </Form.Group>

            <Form.Group label={"Email*"}>
              <Form.Input
                name="email"
                value={values.email}
                onChange={handleChange}
                onBlur={(e) => handleValidateEmail(e.target.value)}
              />
              <span className="field-error text-danger">
                {errors.email && touched.email && errors.email}
              </span>
            </Form.Group>

            <Form.Group label={"Phone"}>
              <PhoneInput
                inputProps={{
                  className: "form-control w-100",
                  name: "phone",
                  onChange: (e) => {
                    e.preventDefault();
                    const target = e.target;
                    const phone = target.value.split(" ");
                    const countryCode = phone.shift().replace(/\D/g, "");
                    const phoneNumber = phone.join("").replace(/\D/g, "");
                    setFieldValue("phone", phoneNumber);
                    setFieldValue("phone_country_code", Number(countryCode));
                  },
                  required: true,
                  onBlur: async (e) => {
                    e.preventDefault();
                    await handleValidatePhone(
                      e.target.value.split(" ").join("").replace(/\D/g, ""),
                    );
                  },
                }}
                value={`${
                  values.phone_country_code
                }${values.phone_number.replace(/\D/g, "")}`}
              />
              <span className="field-error text-danger">
                {errors.phone_number &&
                  touched.phone_number &&
                  errors.phone_number}
              </span>
            </Form.Group>
            <Button
              pill
              icon="mail"
              color={"primary"}
              className={"float-right"}
              disabled={isSubmitting}
            >
              Send Activation Code
            </Button>
            <Button
              pill
              color={"white"}
              className={"float-right"}
              onClick={(e) => {
                e.preventDefault();
                setStudent(null);
                setShowForm(false);
              }}
            >
              Cancel
            </Button>
          </Form>
        </>
      )}
    </Formik>
  );
};
