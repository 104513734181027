import { useMutation, ApolloError } from '@apollo/client';
import { Formik } from 'formik';
import { observer } from 'mobx-react';
import React from 'react';
import { toast } from 'react-toastify';
import { Button, Card, Form, Grid } from 'tabler-react';
import * as Yup from 'yup';

import FormField from "components/FormField";
import { useRootStore } from "hooks/useRootStore";
import PhoneInput from "react-phone-input-2";
import { ADD_COACH } from "../../../graphql/ADD_COACH";
import { Types } from '../../../types/graphql'

const addCoachValidationSchema = Yup.object().shape({
  first_name: Yup.string().required("This field is required."),
  last_name: Yup.string().required("This field is required."),
  //  email: Yup.string()
  //    .email('Invalid email.')
  //    .required('This field is required.'),
  //  phone_number: Yup.string().required('This field is required.')
});

const initialValues = {
  first_name: "",
  last_name: "",
  email: "",
  phone_number: "",
  phone_country_code: null,
};

const AddCoach = () => {
  const rootStore = useRootStore();

  const [addCoach] = useMutation(ADD_COACH, {
    onError: (error: ApolloError) =>
      toast.error(error?.graphQLErrors[0]?.message),
    onCompleted: () => toast.success("Coach Added."),
  });

  return (
    <Formik
      enableReinitialize={true}
      initialValues={initialValues}
      validationSchema={addCoachValidationSchema}
      onSubmit={async (
        values: Types.AddCoachInput,
        { setSubmitting, resetForm }
      ) => {
        const newCoach = await addCoach({
          variables: {
            coach: {
              ...values,
            },
          },
        });

        // Add new coach to mst
        rootStore.addCoach(newCoach.data.addCoach);
        rootStore.sortCoaches();

        setSubmitting(false);
        resetForm();
      }}
    >
      {({
        values,
        isSubmitting,
        handleBlur,
        handleChange,
        handleSubmit,
        setFieldValue,
      }) => (
        <form className="card add-coach" onSubmit={handleSubmit}>
          <Card.Header>
            <Card.Title>Add Coach Account</Card.Title>
          </Card.Header>
          <Card.Body>
            <Grid.Row>
              <Grid.Col md={6} sm={12} xs={12}>
                <FormField
                  name="first_name"
                  label="First Name"
                  placeholder="First Name"
                  type="text"
                  value={values.first_name}
                  onBlur={handleBlur}
                  onChange={handleChange}
                />
              </Grid.Col>
              <Grid.Col md={6} sm={12} xs={12}>
                <FormField
                  name="last_name"
                  label="Last Name"
                  placeholder="Last Name"
                  type="text"
                  value={values.last_name}
                  onBlur={handleBlur}
                  onChange={handleChange}
                />
              </Grid.Col>
              <Grid.Col md={6} sm={12} xs={12}>
                <FormField
                  name="email"
                  label="Email"
                  placeholder="Email"
                  type="email"
                  value={values.email}
                  onBlur={handleBlur}
                  onChange={handleChange}
                />
              </Grid.Col>
              <Grid.Col md={6} sm={12} xs={12}>
                <Form.Group label="Phone">
                  <PhoneInput
                    inputProps={{
                      className: "form-control w-100",
                      name: "phone_number",
                      required: true,

                      onChange: (e) => {
                        e.preventDefault();
                        const target = e.target;
                        const phone = target.value.split(" ");
                        const countryCode = phone.shift().replace(/\D/g, "");
                        const phoneNumber = phone.join("").replace(/\D/g, "");
                        setFieldValue("phone_number", phoneNumber);
                        setFieldValue(
                          "phone_country_code",
                          Number(countryCode)
                        );
                      },
                    }}
                    value={`${values.phone_country_code}${values.phone_number}`}
                  />
                </Form.Group>
              </Grid.Col>
            </Grid.Row>
          </Card.Body>
          <Card.Footer className="text-right">
            <Button
              className="btn btn-primary"
              disabled={isSubmitting}
              loading={isSubmitting}
              type="submit"
            >
              ADD{isSubmitting ? "ING" : ""} ACCOUNT
            </Button>
          </Card.Footer>
        </form>
      )}
    </Formik>
  );
};

export default observer(AddCoach);
