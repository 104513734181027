import { useQuery } from '@apollo/client';
import LocationListItem from 'components/Location/LocationListItem';
import LocationSearchField from 'components/Students/Forms/LocationSearchField';
import { locationStoreContext } from 'contexts/LocationStoreContext';
import { GET_LOCATIONS } from 'graphql/GET_LOCATIONS';
import { observer } from 'mobx-react';
import { applySnapshot } from 'mobx-state-tree';
import {
  DEFAULT_LIMIT,
  DEFAULT_PAGE,
  ELocationOrder,
  ELocationType
} from 'modules/location/constants';
import React, { useContext, useEffect, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import {
  Alert,
  Button,
  Card,
  Dimmer,
  Dropdown,
  Form,
  Grid,
  Icon,
  Table
} from 'tabler-react';
import AddLocationModal from './Modals/AddLocationModal';

const LocationsList = () => {
  const [page, setPage] = useState<number>(2);
  const [filterType, setFilterType] = useState<ELocationType>(
    ELocationType.all
  );

  const [isFrozen, setFrozen] = useState<boolean>(false);

  const locationStore = useContext(locationStoreContext);
  const { locations } = locationStore;

  const filterArgs = {
    type: filterType,
    is_frozen: isFrozen,
    limit: DEFAULT_LIMIT
  };

  const { loading, error, data, fetchMore } = useQuery(GET_LOCATIONS, {
    variables: {
      order: ELocationOrder.name,
      filter: filterArgs,
      page: DEFAULT_PAGE
    }
  });

  useEffect(() => {
    if (!loading && data) {
      applySnapshot(locations, data.locations);
    }
  }, [data, filterType, isFrozen]);  

  const [isModalOpen, setIsModalOpen] = useState(false);

  if (error) {
    return <p>{`Error: ${error.message}`}</p>;
  }

  const onFetchMore = () => {
    setPage((prevNum) => prevNum + 1);
    return fetchMore({
      updateQuery: (cache, { fetchMoreResult }) => {
        if (!fetchMoreResult) {
          return cache;
        }
        return {
          ...cache,
          locations: [...cache.locations, ...fetchMoreResult.locations]
        };
      },
      variables: {
        order: ELocationOrder.name,
        filter: filterArgs,
        page: page
      }
    });
  };

  const handleFilterTypeClick = (value: ELocationType) => {
    setFilterType(value);
  };

  const handleFilterFropzenClick = () => {
    setFrozen(!isFrozen);
  };

  return (
    <Card>
      <AddLocationModal
        isOpen={isModalOpen}
        toggle={() => setIsModalOpen(false)}
      />
      <Card.Header>
        <Card.Title>
          <Icon name="map-pin" className="mr-2 ml-0 text-blue" />
          Locations
        </Card.Title>
        <Card.Options>
          <Button
            icon="plus"
            color="primary"
            size="sm"
            className="mr-2"
            onClick={() => setIsModalOpen(true)}
          />
          <LocationSearchField />
          <Dropdown
            className="text-capitalize text-right mx-2 cursor-pointer"
            size="sm"
            type="button"
            toggle={false}
            color="white"
            position="bottom-end"
            arrow={true}
            arrowPosition="right"
            triggerContent={`Filter By Type: ${filterType}`}
            items={
              <>
                <Dropdown.Item
                  className="m-0 text-right"
                  onClick={() => handleFilterTypeClick(ELocationType.dropzone)}
                >
                  {ELocationType.dropzone}
                </Dropdown.Item>
                <Dropdown.Item
                  className="m-0 text-right"
                  onClick={() => handleFilterTypeClick(ELocationType.tunnel)}
                >
                  {ELocationType.tunnel}
                </Dropdown.Item>
                <Dropdown.Item
                  className="m-0 text-right"
                  onClick={() => handleFilterTypeClick(ELocationType.speedfly)}
                >
                  {ELocationType.speedfly}
                </Dropdown.Item>
                <Dropdown.Item
                  className="m-0 text-right"
                  onClick={() => handleFilterTypeClick(ELocationType.base)}
                >
                  {ELocationType.base}
                </Dropdown.Item>
                <Dropdown.ItemDivider />
                <Dropdown.Item
                  className="m-0 text-right"
                  onClick={() => handleFilterTypeClick(ELocationType.all)}
                >
                  {ELocationType.all}
                </Dropdown.Item>
              </>
            }
          />
          <Form.Group className="mb-0 mt-2">
            <Form.Switch
              name="toggle"
              value="showFrozen"
              onClick={() => handleFilterFropzenClick()}
              label="Frozen"
            />
          </Form.Group>
        </Card.Options>
      </Card.Header>
      <Card.Body>
        <Dimmer active={loading} loader={loading}>
          <Grid.Col width={12}>
            <InfiniteScroll
              className="overflow-visible pb-3"
              dataLength={locations.length}
              hasMore={locations.length < data?.locations[0]?.total}
              loader={<Dimmer active={true} loader={true} />}
              next={onFetchMore}
              scrollThreshold="210px"
              endMessage={
                <Alert className="text-center" type="primary">
                  No {locations.length !== 0 && 'more'} location to show.
                </Alert>
              }
            >
              <Table className="card-table table-vcenter text-nowrap">
                <Table.Header>
                  <Table.Row>
                    <Table.ColHeader></Table.ColHeader>
                    <Table.ColHeader>Location Name</Table.ColHeader>
                    <Table.ColHeader>Address</Table.ColHeader>
                    <Table.ColHeader>Open Since</Table.ColHeader>
                    <Table.ColHeader>Status</Table.ColHeader>
                    <Table.ColHeader></Table.ColHeader>
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  {locations.map(
                    (location) =>
                      (location.type === filterType ||
                        filterType === ELocationType.all) && (
                        <React.Fragment key={location.id}>
                          <Table.Row>
                            <LocationListItem location={location} />
                          </Table.Row>
                        </React.Fragment>
                      )
                  )}
                </Table.Body>
              </Table>
            </InfiniteScroll>
          </Grid.Col>
        </Dimmer>
      </Card.Body>
    </Card>
  );
};

export default observer(LocationsList);
