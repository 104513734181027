import { onSnapshot, applySnapshot } from 'mobx-state-tree';
import React, { createContext, ReactNode, useState } from 'react';

import {
  ProductStore,
  ProductStoreType,
  ProductStoreDefault
} from '../models/ProductStore';

export const ProductStoreContext = createContext<ProductStoreType | null>(null);

interface ProductStoreProvider {
  children: ReactNode;
}

const getOrCreateStore = () => {
  const productStore = ProductStoreDefault();
  const initData = localStorage.getItem('products');

  if (initData) {
    applySnapshot(productStore, JSON.parse(initData));
    return productStore;
  } else {
    return ProductStore.create();
  }
};

export const ProductStoreProvider = (props: ProductStoreProvider) => {
  const [productStore] = useState(() => getOrCreateStore());

  onSnapshot(productStore, (_snapshot) => {
    const snapshot = { ..._snapshot };
    const data = JSON.stringify(snapshot);
    localStorage.setItem('products', data);
  });

  return (
    <ProductStoreContext.Provider value={productStore}>
      {props.children}
    </ProductStoreContext.Provider>
  );
};
