import { useQuery } from '@apollo/client'
import { useGetUserIsStudent } from 'modules/common/hooks/useGetCurrentUserType'
import AddLicenseModal from 'modules/org-credential/components/modals/AddLicenseModal'
import AddMembershipModal from 'modules/org-credential/components/modals/AddMembershipModal'
import QRCodeUserRegistration from 'modules/user/registrations/components/buttons/QRCodeUserRegistration'
import moment from 'moment'
import { useMemo, useState } from 'react'
import { Button, Card, Grid, Text } from 'tabler-react'
import { GET_STUDENT_GEARS } from '../../graphql/GET_STUDENT_GEARS'
import { GET_USER_LOCATION } from '../../graphql/GET_USER_LOCATION'
import { GET_USER_MERITS_BY_USER_ID } from '../../graphql/GET_USER_MERITS_BY_STUDENT_ID'
import { useRootStore } from '../../hooks'
import { Types } from '../../types/graphql'
import { formatHeight, getAge, getWingLoad } from '../../utils/numberFormat'
import {
  EUSPATitles,
  lifetimeMemberTemplateId,
  SHIRT_SIZES
} from '../constants'
import AADGearModalForm from '../Gear/Modal/AADGearModalForm'
import MainGearLoadLimit from '../Gear/Modal/MainGearLoadLimit'
import MainGearModalForm from '../Gear/Modal/MainGearModalForm'
import ShowGearModal from '../Gear/Modal/ShowGearModal'
import StudentLink from '../Students/StudentLink'
import { EUserTypes } from '../User/userTypes'
import RegistrationStudentDetailContactModal from './Forms/RegistrationStudentDetailContactModal'
import RegistrationStudentDetailDOBModal from './Forms/RegistrationStudentDetailDOBModal'
import RegistrationStudentDetailHeightModal from './Forms/RegistrationStudentDetailHeightModal'
import RegistrationStudentDetailShirtSizeModal from './Forms/RegistrationStudentDetailShirtSizeModal'
import RegistrationStudentDetailWeightModal from './Forms/RegistrationStudentDetailWeightModal'

interface IRegistrationStudentDetailsProps {
  userRegistrationDetails: Types.UserRegistrationsById
  campRequirements: string[]
}

const RegistrationStudentDetails = ({
  userRegistrationDetails,
  campRequirements
}: IRegistrationStudentDetailsProps) => {
  const { currentUser } = useRootStore()
  const isStudent = useGetUserIsStudent()
  const [isHeightModalOpen, setIsHeightModalOpen] = useState<boolean>(false)
  const [isWeightModalOpen, setIsWeightModalOpen] = useState<boolean>(false)
  const [isDOBModalOpen, setIsDOBModalOpen] = useState<boolean>(false)
  const [isShirtSizeModalOpen, setIsShirtSizeModalOpen] =
    useState<boolean>(false)
  const [isContactModalOpen, setIsContactModalOpen] = useState<boolean>(false)
  const [isGearModalOpen, setIsGearModalOpen] = useState<boolean>(false)
  const [isAADModalOpen, setIsAADModalOpen] = useState<boolean>(false)
  const [isShowGearModalOpen, setIsShowGearModalOpen] = useState<boolean>(false)
  const [mainGear, setMainGear] = useState(null)
  const [isGearSelectionOpen, setIsGearSelectionOpen] = useState(false)
  const [isAddMembershipModalOpen, setIsAddMembershipModalOpen] =
    useState(false)
  const [isAddLicenseModalOpen, setIsAddLicenseModalOpen] = useState(false)

  //MERITS
  const { data: userMeritsData } = useQuery(GET_USER_MERITS_BY_USER_ID, {
    variables: {
      userId: userRegistrationDetails?.student_id,
      userType: 'student'
    }
  })

  const { data: dataTunnelLocation, loading: tunnelLocationLoading } = useQuery(
    GET_USER_LOCATION,
    {
      variables: {
        student_id: Number(userRegistrationDetails?.student_id),
        flag_type_id: 6,
        location_type: 'tunnel'
      }
    }
  )

  const defaultTunnelLocation = useMemo(() => {
    if (!tunnelLocationLoading && !!dataTunnelLocation?.getUserLocation) {
      return dataTunnelLocation.getUserLocation
    }

    return null
  }, [dataTunnelLocation, tunnelLocationLoading])

  const { data: userLocation, loading: userLocationLoading } = useQuery(
    GET_USER_LOCATION,
    {
      variables: {
        student_id: Number(userRegistrationDetails?.student_id),
        flag_type_id: 6,
        location_type: 'dropzone'
      }
    }
  )

  const defaultHomeLocation = useMemo(() => {
    if (!userLocationLoading && !!userLocation?.getUserLocation) {
      return userLocation.getUserLocation
    }

    return null
  }, [userLocation, userLocationLoading])

  const hasLifetimeMembership = userMeritsData?.userMerits?.some(
    (item) => item.template_id === lifetimeMemberTemplateId
  )

  const memberLicense = useMemo(() => {
    if (hasLifetimeMembership) {
      return userMeritsData?.userMerits.find(
        (item) => item.template_title === 'Lifetime Member'
      )
    }

    return userMeritsData?.userMerits.find(
      (item) => item.template_title === EUSPATitles.Member
    )
  }, [hasLifetimeMembership, userMeritsData?.userMerits])
  //Gears
  const { data: gearsData, loading: gearsLoading } = useQuery(
    GET_STUDENT_GEARS,
    {
      variables: {
        getUserGearOptions: {
          student_id: Number(userRegistrationDetails?.student_id)
        }
      }
    }
  )

  const studentMainCanopy = useMemo(() => {
    if (!gearsLoading && gearsData?.getUserGears) {
      const canopy = gearsData.getUserGears.filter(
        (gear) => gear.gear_model.gear_type_id === 1
      )

      if (canopy.length >= 1) {
        setMainGear(canopy[0])
      }

      return canopy ?? null
    }

    return null
  }, [gearsData, gearsLoading])

  const studentAAD = useMemo(() => {
    if (!gearsLoading && gearsData?.getUserGears) {
      const aad = gearsData.getUserGears.filter(
        (gear) => gear.gear_model.gear_type_id === 4
      )

      if (aad.length === 0) return null

      return aad
    }

    return null
  }, [gearsData, gearsLoading])

  const orgMembership = userRegistrationDetails?.orgCredentials.find(
    (oc) => oc.membership
  )

  const orgCredentials = userRegistrationDetails?.orgCredentials
    .filter((oc) => oc.license || oc.nac_id)
    .sort((a, b) => a.license.localeCompare(b.license))

  return (
    <>
      {campRequirements && campRequirements.length > 0 && (
        //  currentUser.type !== EUserTypes.student &&
        <>
          <Card className="registration">
            {userRegistrationDetails?.profile_avatar && (
              <Card.Header
                className="card-header"
                backgroundURL={`/api/s3/uploads/${userRegistrationDetails?.profile_avatar}`}
              ></Card.Header>
            )}
            <Card.Body className="pl-3 pr-3">
              <Grid.Row className="border-bottom mb-3">
                <Grid.Col>
                  <h3 className="text-center">
                    {currentUser.type !== EUserTypes.student && (
                      <StudentLink
                        studentId={Number(userRegistrationDetails?.student_id)}
                        studentName={`${userRegistrationDetails.first_name} ${userRegistrationDetails.last_name}`}
                      />
                    )}
                    {currentUser.type === EUserTypes.student &&
                      `${userRegistrationDetails?.first_name} ${userRegistrationDetails?.last_name}`}
                  </h3>
                </Grid.Col>
              </Grid.Row>
              {currentUser.type !== EUserTypes.student && (
                <Grid.Row className="border-bottom mb-3">
                  <Grid.Col className="text-center border-right">
                    {userRegistrationDetails?.email ? (
                      <Button
                        className="my-3 text-primary"
                        icon="mail"
                        color={'white'}
                        onClick={(e) => {
                          e.preventDefault()
                          window.location.href =
                            'mailto:' + userRegistrationDetails?.email
                        }}
                      >
                        EMAIL
                      </Button>
                    ) : (
                      <Button
                        className="my-3 text-muted"
                        icon="mail"
                        color={'white'}
                        onClick={() => setIsContactModalOpen(true)}
                      >
                        No Email
                      </Button>
                    )}
                  </Grid.Col>
                  <Grid.Col className="text-center border-0">
                    {userRegistrationDetails?.phone_number ? (
                      <Button
                        className="my-3 text-primary"
                        icon="phone"
                        color={'white'}
                        onClick={(e) => {
                          e.preventDefault()
                          window.location.href =
                            'tel:' +
                            userRegistrationDetails?.phone_country_code +
                            userRegistrationDetails?.phone_number
                        }}
                      >
                        CALL
                      </Button>
                    ) : (
                      <Button
                        className="my-3 text-muted"
                        icon="phone"
                        color={'white'}
                        onClick={() => setIsContactModalOpen(true)}
                      >
                        No Phone
                      </Button>
                    )}
                  </Grid.Col>
                </Grid.Row>
              )}
              <Grid.Row className="mb-3">
                {campRequirements.includes('height') && (
                  <Grid.Col width={6} className="text-center">
                    <Text className="text-muted">Height</Text>
                    <h4
                      onClick={() => setIsHeightModalOpen(true)}
                      className="cursor-pointer"
                    >
                      {userRegistrationDetails?.height > 0 ? (
                        formatHeight(userRegistrationDetails?.height)
                      ) : (
                        <Button color="white" size="sm" className="text-muted">
                          Unknown
                        </Button>
                      )}
                    </h4>
                  </Grid.Col>
                )}

                {campRequirements.includes('weight') && (
                  <Grid.Col width={6} className="text-center">
                    <Text className="text-muted">Weight</Text>
                    <h4
                      onClick={() => setIsWeightModalOpen(true)}
                      className="cursor-pointer"
                    >
                      {userRegistrationDetails?.weight > 0 ? (
                        userRegistrationDetails?.weight + ' lbs'
                      ) : (
                        <Button color="white" size="sm" className="text-muted">
                          Unknown
                        </Button>
                      )}
                    </h4>
                  </Grid.Col>
                )}

                {campRequirements.includes('dob') && (
                  <Grid.Col width={6} className="text-center">
                    <Text className="text-muted">Age</Text>
                    <h4
                      onClick={() => setIsDOBModalOpen(true)}
                      className="cursor-pointer"
                    >
                      {userRegistrationDetails?.dob ? (
                        getAge(userRegistrationDetails?.dob)
                      ) : (
                        <Button color="white" size="sm" className="text-muted">
                          Unknown
                        </Button>
                      )}
                    </h4>
                  </Grid.Col>
                )}

                {/* Only display Wing Load if camp type is Skydiving */}
                {campRequirements.includes('wing_load') &&
                  userRegistrationDetails.sportTypeId === 2 && (
                    <Grid.Col width={6} className="text-center">
                      <Text className="text-muted">Wing Load</Text>
                      <h4>
                        {!!studentMainCanopy &&
                        mainGear &&
                        userRegistrationDetails?.weight > 0 ? (
                          <span
                            className={'cursor-pointer'}
                            onClick={() => {
                              if (studentMainCanopy.length > 1) {
                                setIsGearSelectionOpen(true)
                              }
                            }}
                          >
                            {getWingLoad(
                              Number(mainGear.gear_canopy_size),
                              Number(userRegistrationDetails?.weight)
                            )}
                            {studentMainCanopy.length > 1 && (
                              <i className="fe fe-more-horizontal ml-2" />
                            )}
                            <Text.Small className="text-muted d-block">
                              {mainGear.gear_model.name}{' '}
                              {mainGear.gear_canopy_size}
                            </Text.Small>
                          </span>
                        ) : (
                          <Button
                            color="white"
                            size="sm"
                            className="text-muted"
                            onClick={() => setIsGearModalOpen(true)}
                          >
                            Unknown
                          </Button>
                        )}
                      </h4>
                    </Grid.Col>
                  )}

                {campRequirements.includes('jump_count') && (
                  <Grid.Col width={6} className="text-center">
                    <Text className="text-muted">Total Jumps</Text>
                    <h4 className="cursor-pointer">
                      {userRegistrationDetails?.jump_count
                        ? userRegistrationDetails?.jump_count
                            .toString()
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                        : 'Unknown'}
                    </h4>
                  </Grid.Col>
                )}

                {campRequirements.includes('tunnel_time') && (
                  <Grid.Col width={6} className="text-center">
                    <Text className="text-muted">Total Tunnel Time</Text>
                    <h4 className="cursor-pointer">
                      {userRegistrationDetails?.tunnel_time
                        ? userRegistrationDetails?.tunnel_time
                            .toString()
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ',') + ' hours'
                        : 'Unknown'}
                    </h4>
                  </Grid.Col>
                )}

                {campRequirements.includes('country') && (
                  <Grid.Col width={6} className="text-center">
                    <Text className="text-muted">Country</Text>
                    <h4 className="cursor-pointer">
                      {userRegistrationDetails?.country ?? 'Unknown'}
                    </h4>
                  </Grid.Col>
                )}

                {campRequirements.includes('home_dz') && (
                  <Grid.Col width={6} className="text-center">
                    <Text className="text-muted">Home DZ</Text>
                    <h4 className="cursor-pointer">
                      {defaultHomeLocation?.name ?? 'Unknown'}
                    </h4>
                  </Grid.Col>
                )}

                {campRequirements.includes('home_tunnel') && (
                  <Grid.Col width={6} className="text-center">
                    <Text className="text-muted">Home Tunnel</Text>
                    <h4 className="cursor-pointer">
                      {defaultTunnelLocation?.name ?? 'Unknown'}
                    </h4>
                  </Grid.Col>
                )}
              </Grid.Row>
            </Card.Body>
            <Card.Footer className="pl-4 pr-4">
              {campRequirements.includes('shirt_size') && (
                <Button
                  color="white"
                  size="sm"
                  onClick={() => setIsShirtSizeModalOpen(true)}
                  className="float-left text-muted"
                >
                  Shirt Size:{' '}
                  {userRegistrationDetails?.shirt_size_id
                    ? SHIRT_SIZES.map((size) => {
                        if (
                          size.value === userRegistrationDetails?.shirt_size_id
                        ) {
                          return size.name
                        }
                      })
                    : 'Unknown'}
                </Button>
              )}

              {/* Only display AAD button list if camp type is Skydiving */}
              {userRegistrationDetails.sportTypeId === 2 && (
                <Button.List align="right">
                  {!studentAAD ? (
                    <Button
                      icon="plus-circle"
                      color="white"
                      size="sm"
                      className="text-muted"
                      onClick={() => setIsAADModalOpen(true)}
                    >
                      AAD
                    </Button>
                  ) : (
                    <Button
                      icon="check-circle"
                      color="white"
                      size="sm"
                      className="text-success"
                      onClick={() => setIsShowGearModalOpen(true)}
                    >
                      AAD
                    </Button>
                  )}
                </Button.List>
              )}
            </Card.Footer>

            {/* {campRequirements.includes("merits") && (
                <>
                  {memberLicense && (
                    <Card.Footer>
                      <Grid.Row width={12}>
                        <Grid.Col lg={6}>
                          {memberLicense?.merits_uspa_member_id && (
                            <Text.Small>
                              USPA #:{" "}
                              <strong>
                                {memberLicense?.merits_uspa_member_id}
                              </strong>
                            </Text.Small>
                          )}
                        </Grid.Col>
                        <Grid.Col className="text-right" lg={6}>
                          {memberLicense?.merits_uspa_member_expiration && (
                            <Text.Small className="text-right">
                              Expires:{" "}
                              <strong>
                                {moment(
                                  memberLicense?.merits_uspa_member_expiration,
                                ).format("MMM D, YYYY")}
                              </strong>
                            </Text.Small>
                          )}
                          {memberLicense?.merits_uspa_member_id &&
                            !memberLicense?.merits_uspa_member_expiration && (
                              <Text.Small className="text-right">
                                <i className="fe fe-star text-warning mr-1" />
                                Lifetime Member
                              </Text.Small>
                            )}
                        </Grid.Col>
                      </Grid.Row>
                    </Card.Footer>
                  )}
                </>
              )} */}
          </Card>
          {(userRegistrationDetails.org_id ||
            campRequirements.includes('member')) && (
            <Card>
              <Card.Body>
                <Grid.Row width={12}>
                  <Grid.Col width={2}>
                    <Button icon="award" color="info" size="lg" />
                  </Grid.Col>
                  <Grid.Col width={5}>
                    {orgMembership && (
                      <span
                        className="cursor-pointer"
                        onClick={() => setIsAddMembershipModalOpen(true)}
                      >
                        <Text>
                          {orgMembership.org_slug.toUpperCase()}{' '}
                          <strong>{orgMembership?.membership}</strong>
                        </Text>
                        <Text.Small className="text-muted">
                          {orgMembership.expires}
                        </Text.Small>
                      </span>
                    )}
                    {!orgMembership && (
                      <>
                        <Button
                          outline
                          icon="plus"
                          color="secondary"
                          size="sm"
                          onClick={() => setIsAddMembershipModalOpen(true)}
                        >
                          Org Membership
                        </Button>
                      </>
                    )}
                    <AddMembershipModal
                      orgId={
                        orgMembership && orgMembership.org_id
                          ? Number(orgMembership.org_id)
                          : Number(userRegistrationDetails.org_id)
                      }
                      orgName={userRegistrationDetails.orgName}
                      membership={orgMembership?.membership}
                      id={Number(orgMembership?.id)}
                      expires={orgMembership?.expires}
                      studentId={Number(userRegistrationDetails.student_id)}
                      isOpen={isAddMembershipModalOpen}
                      toggle={() =>
                        setIsAddMembershipModalOpen(!isAddMembershipModalOpen)
                      }
                    />
                  </Grid.Col>
                  <Grid.Col width={5}>
                    {orgCredentials.length > 0 && (
                      <span
                        className="cursor-pointer"
                        onClick={() => setIsAddLicenseModalOpen(true)}
                      >
                        <Text>
                          {orgCredentials[0]?.nac_slug?.toUpperCase() ||
                            orgCredentials[0].license}{' '}
                        </Text>
                        <Text.Small>
                          <strong>{orgCredentials[0]?.license_number}</strong>
                        </Text.Small>
                      </span>
                    )}
                    {orgMembership && orgCredentials.length <= 0 && (
                      <>
                        <Button
                          outline
                          icon="plus"
                          color="secondary"
                          size="sm"
                          onClick={() =>
                            setIsAddLicenseModalOpen(!isAddLicenseModalOpen)
                          }
                        >
                          License
                        </Button>
                      </>
                    )}
                    <AddLicenseModal
                      orgId={Number(userRegistrationDetails.org_id)}
                      orgName={userRegistrationDetails.orgName}
                      orgSlug={orgMembership?.org_slug}
                      license={orgCredentials[0]}
                      studentId={Number(userRegistrationDetails.student_id)}
                      isOpen={isAddLicenseModalOpen}
                      toggle={() =>
                        setIsAddLicenseModalOpen(!isAddLicenseModalOpen)
                      }
                    />
                  </Grid.Col>
                </Grid.Row>
              </Card.Body>
            </Card>
          )}
          {isStudent &&
            moment(userRegistrationDetails?.start).isAfter() &&
            moment(userRegistrationDetails?.end).isBefore() && (
              <QRCodeUserRegistration
                userRegistration={userRegistrationDetails}
              />
            )}
          <MainGearModalForm
            isModalOpen={isGearModalOpen}
            toggleModal={setIsGearModalOpen}
            student_id={Number(userRegistrationDetails?.student_id)}
            team_id={Number(userRegistrationDetails?.team_id)}
            student={userRegistrationDetails}
          />
          <AADGearModalForm
            isModalOpen={isAADModalOpen}
            toggleModal={setIsAADModalOpen}
            student_id={Number(userRegistrationDetails?.student_id)}
            team_id={Number(userRegistrationDetails?.team_id)}
            student={userRegistrationDetails}
          />
          <RegistrationStudentDetailHeightModal
            isModalOpen={isHeightModalOpen}
            toggleModal={setIsHeightModalOpen}
            student={userRegistrationDetails}
          />
          <RegistrationStudentDetailWeightModal
            isModalOpen={isWeightModalOpen}
            toggleModal={setIsWeightModalOpen}
            student={userRegistrationDetails}
          />
          <RegistrationStudentDetailDOBModal
            isModalOpen={isDOBModalOpen}
            toggleModal={setIsDOBModalOpen}
            student={userRegistrationDetails}
          />
          <RegistrationStudentDetailShirtSizeModal
            isModalOpen={isShirtSizeModalOpen}
            toggleModal={setIsShirtSizeModalOpen}
            student={userRegistrationDetails}
          />
          <RegistrationStudentDetailContactModal
            isModalOpen={isContactModalOpen}
            toggleModal={setIsContactModalOpen}
            student={userRegistrationDetails}
          />
          <MainGearLoadLimit
            student={userRegistrationDetails}
            gears={studentMainCanopy}
            toggleModal={setIsGearSelectionOpen}
            isModalOpen={isGearSelectionOpen}
            setMainGear={setMainGear}
          />
          <ShowGearModal
            toggleModal={setIsShowGearModalOpen}
            isModalOpen={isShowGearModalOpen}
            userGear={studentAAD && studentAAD[0]}
          />
        </>
      )}
    </>
  )
}
export default RegistrationStudentDetails
