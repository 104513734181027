import { useQuery } from '@apollo/client';
import { GET_INVOICE_LIST } from 'graphql/GET_INVOICE_LIST';
import { GET_INVOICE_LIST as GET_INVOICE_LIST_TYPE } from 'graphql/types/GET_INVOICE_LIST';
import { useRootStore } from 'hooks';
import { observer } from 'mobx-react';
import useGetCurrentUserType from 'modules/common/hooks/useGetCurrentUserType';
import moment from 'moment/moment';
import React, { useEffect, useState } from 'react';
import { Button, Card, Dimmer, Icon, Text } from 'tabler-react';
import useReactRouter from 'use-react-router';
import { formatMoney } from 'utils/numberFormat';

const JobsInvoiceList = ({invoices}) => {
  const { isStudent } = useGetCurrentUserType();
  const { currentCoachTeam } = useRootStore();
  const { history } = useReactRouter();

  if (!currentCoachTeam?.id) {
    return <>Loading invoices</>;
  }

  return (
      <Card className="mt-7">
        <Card.Header>
          <Icon name="file" className="mr-2 text-primary" />
          <a href="/invoices" className="text-gray-dark text-decoration-none">
            Invoices
          </a>
          <Card.Options>
            <Button.List align="right">
              <Button color="white" size="sm">
                <a href="/invoices" className="text-decoration-none text-muted">
                  See All
                </a>
              </Button>
            </Button.List>
          </Card.Options>
        </Card.Header>
        <Card.Body>
          { invoices?.map((invoice) => {
              return (
                <div
                  key={`invoice-${invoice.id}`}
                  className="text-decoration-none cursor-pointer"
                  onClick={(e) => {
                    e.preventDefault();
                    if (!isStudent)
                      history.push(
                        `/invoice/${invoice.student_id}/${invoice.id}`
                      );
                  }}
                >
                  <span className="float-right text-success">
                    {formatMoney(invoice.invoice_amount)}
                  </span>
                  <Text>
                    Inv: {invoice.id}
                    {invoice.status === 'Paid' && (
                      <i className="fe fe-check-circle text-success ml-2" />
                    )}
                    <Text.Small className="text-muted d-block mb-3">
                      {moment(invoice.created_on).format('MMM D')}
                      {' - '}
                      {invoice.student.first_name} {invoice.student.last_name}
                    </Text.Small>
                  </Text>
                </div>
              );
            })}
        </Card.Body>
      </Card>
  );
};

export default observer(JobsInvoiceList);
