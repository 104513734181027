import gql from "graphql-tag";

export const TOGGLE_REGISTRATION_SCORECARD = gql`
  mutation TOGGLE_REGISTRATION_SCORECARD(
    $user_registrations_id: ID
    $userScoreCard: Int
  ) {
    toggleRegistrationScoreCard(
      user_registrations_id: $user_registrations_id
      userScoreCard: $userScoreCard
    )
  }
`;
