import { IconQrcode } from '@tabler/icons-react';
import Modal from 'components/Modal';
import React, { useState } from 'react';
import QRCode from 'react-qr-code';
import { Avatar, Dropdown, Text } from 'tabler-react';

type QRCodeStudentNavItemProps = {
  token: string;
  id: any;
  firstName: string;
  lastName: string;
  profileAvatar: string;
};

const QRCodeStudentNavItem = ({ 
  token,
  id,
  firstName,
  lastName,
  profileAvatar 
}: QRCodeStudentNavItemProps) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  return (
    <>
      <Dropdown.Item className="pl-4 cursor-pointer d-lg-none" onClick={toggleModal}>
        <IconQrcode size="15" color="#9aa0ac" className="mr-3" />
        QR Code
      </Dropdown.Item>
      <Modal
        content={
          <div className="text-center">
            <a href={`/user/${token}`}>
              <QRCode
                className="mt-5"
                value={`https://portal.skycru.com/user/${token}`}
              />
            </a>
            <h2 className="mt-5">
              <Text className="d-block text-center mb-3">
                <Avatar
                //  status={profileAvatar && ('success')}
                  size="xxl"
                  className="mr-3"
                  imageURL={
                    (profileAvatar &&
                      `/api/s3/uploads/${profileAvatar}`) ||
                    '//www.gravatar.com/avatar?d=mp'
                  }
                />
              </Text>
              {firstName} {lastName}
            </h2>
            <Text>SKY.D: {id}</Text>
          </div>
        }
        open={isModalOpen}
        onClose={toggleModal}
      />
    </>
  );
};

export default QRCodeStudentNavItem;
