import gql from "graphql-tag";

export const GET_STUDENT_TEAM_LIST = gql`
  query GET_STUDENT_TEAM_LIST($student_id: ID!) {
    getTeamListByStudentId(student_id: $student_id) {
      id
      name
      slug
      is_active
    }
  }
`;
