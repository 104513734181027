import React, { useEffect, useMemo, useState } from 'react'
import { Button, Form, Grid } from 'tabler-react'
import { GET_REGISTRATION_QUESTION_ANSWER } from '../../graphql/GET_REGISTRATION_QUESTION_ANSWER'
import { useQuery } from '@apollo/client'
import FormField from '../FormField'
import { NumericFormat } from 'react-number-format'
import { validateQuestionResponse } from '../../utils/registrationQuestionUtil'
import { Types } from '../../types/graphql'

interface RegistrationQuestionFieldProps {
  question: Types.RegistrationQuestion
  responseChangeHandler: (e: string, questionId: number) => void
  registrationQuestionsResponse: string
  validateQuestion: boolean
  studentId: number
}

const RegistrationQuestionField = ({
  question,
  responseChangeHandler,
  registrationQuestionsResponse,
  validateQuestion,
  studentId
}: RegistrationQuestionFieldProps) => {
  const [clear, setClear] = useState(false);
  const [userAnswer, setUserAnswer] = useState(
    registrationQuestionsResponse || ''
  );

  const { data, loading } = useQuery(GET_REGISTRATION_QUESTION_ANSWER, {
    variables: {
      studentId: studentId,
      registrationQuestionId: question.id
    }
  });

  const questionError = useMemo(() => {
    if (question.registration_question_type_id === 4)
      return `Answer must be at least ${question.answer_restriction}`;

    if (question.registration_question_type_id === 5)
      return `Answer should not be more than ${question.answer_restriction}`;

    if (question.registration_question_type_id === 6)
      return `Answer must be at least ${question.answer_restriction} characters`;
    if (question.registration_question_type_id === 7)
      return `Answer should not be more than ${question.answer_restriction} characters`
     
  }, []);

  useEffect(() => {
    if (
      !loading &&
      data?.getPreviousRegistrationResponse &&
      !registrationQuestionsResponse &&
      !clear
    ) {
      handleResponseChange(data.getPreviousRegistrationResponse.user_answer);
    } else {
      handleResponseChange(registrationQuestionsResponse || '');
    }
     
  }, [data, loading, registrationQuestionsResponse]);

  const handleClear = () => {
    if (!clear) setClear(true);

    setUserAnswer('');
    responseChangeHandler('', question.id);
  };

  const handleResponseChange = (answer: string) => {
    setUserAnswer(answer);
    responseChangeHandler(answer, question.id);
  };
  return (
    <Grid.Col>
      <div className={`form-group mb-0`}>
        <label className="form-label">
          {question.description}
          {question.is_required && '*'}
          {(question.registration_question_type_id === 1 ||
            (question.registration_question_type_id > 5 &&
              question.registration_question_type_id < 8)) && (
            <Button
              className="mb-1 float-right"
              color="white"
              size="sm"
              onClick={() => handleClear()}
            >
              CLEAR
            </Button>
          )}
        </label>
        <div className="input-group">
          {!question.registration_question_type_id && (
            <textarea
              className={'form-control'}
              name={`question${question.id}`}
              placeholder={'Type your answer...'}
              onChange={(e) => handleResponseChange(e.target.value)}
              value={userAnswer}
            />
          )}
          {[1, 6, 7].includes(question.registration_question_type_id) && (
            <textarea
              className={'form-control'}
              name={`question${question.id}`}
              placeholder={'Type your answer...'}
              onChange={(e) => handleResponseChange(e.target.value)}
              value={userAnswer}
            />
          )}
          {[3, 4, 5].includes(question.registration_question_type_id) && (
            <NumericFormat
              className={'mb-0'}
              customInput={FormField}
              name={`question${question.id}`}
              value={userAnswer}
              onChange={(e) => handleResponseChange(e.target.value)}
            />
          )}
          {question.registration_question_type_id === 2 && (
            <>
              <Form.Radio
                className="cursor-pointer mr-2"
                label={'Yes'}
                name={`question${question.id}`}
                value={'Yes'}
                checked={userAnswer === 'Yes'}
                onChange={() => {
                  handleResponseChange('Yes');
                }}
              />
              <Form.Radio
                className="cursor-pointer"
                label={'No'}
                name={`question${question.id}`}
                value={'No'}
                checked={userAnswer === 'No'}
                onChange={() => {
                  handleResponseChange('No');
                }}
              />
            </>
          )}
          {question.registration_question_type_id === 9 && (
            <>
              {question.question_options?.map((option) => (
                <Form.Radio
                  key={option.id}
                  className="d-block cursor-pointer ml-2"
                  label={option.name}
                  name={`question${question.id}`}
                  value={option.name}
                  checked={userAnswer === option.name}
                  onChange={() => {
                    handleResponseChange(option.name);
                  }}
                />
              ))}
            </>
          )}
          {question.registration_question_type_id === 10 && (
            <Form.Checkbox
              className="d-block cursor-pointer ml-2"
              label={'Confirm'}
              name={`question${question.id}`}
              value={'Confirm'}
              checked={userAnswer === 'Confirm'}
              onChange={(e) => {
                const isChecked = e.target.checked;
                if (isChecked) handleResponseChange('Confirm');
                else handleResponseChange('');
              }}
            />
          )}
        </div>
        {/*  */}
        <small className={'d-inline'}>
          {question.registration_question_type_id === 7 &&
            `${question.answer_restriction} max characters. `}
          {question.registration_question_type_id === 6 &&
            `${question.answer_restriction} min characters. `}
          {question.registration_question_type_id === 5 &&
            `${question.answer_restriction} maximum. `}
          {question.registration_question_type_id === 4 &&
            `${question.answer_restriction} minimum. `}
        </small>

        <span className="field-error text-danger">
          {!validateQuestionResponse(question, userAnswer) && questionError}
          {!registrationQuestionsResponse &&
            validateQuestion &&
            question.is_required &&
            'This field is required'}
        </span>
      </div>
    </Grid.Col>
  );
};

export default RegistrationQuestionField;
