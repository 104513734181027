/**
 * Map of coach account types
 * coach: Coach
 * dzm: DZM
 * rigger: Rigger
 * events: Event Organizer
 * manufacturer: Manufacturer
 * org: Organization
 * wtm: Wind Tunnel
 * academy: Academy
 */

export const COACH_ACCOUNT_TYPES = {
  coach: 'Coach',
  dzm: 'DZM',
  events: 'Event Organizer',
  manufacturer: 'Manufacturer',
  org: 'Organization',
  rigger: 'Rigger',
  wtm: 'Wind Tunnel',
  academy: 'Academy'
};
