import React from 'react'
import Modal from '../../Modal'
import AADGearForm from '../Form/AADGearForm'
import { Types } from '../../../types/graphql'

interface AADGearModalFormProps {
  isModalOpen: boolean
  toggleModal: any
  student_id: number
  team_id: number
  student: any
}

const AADGearModalForm = ({
  isModalOpen,
  toggleModal,
  student_id,
  team_id,
  student
}: AADGearModalFormProps) => {
  const handleClose = () => {
    toggleModal(false);
  };


  return (
    <Modal
      content={
        <AADGearForm
          toggle={handleClose}
          student_id={student_id}
          team_id={team_id}
        />
      }
      open={isModalOpen}
      title={`${student.first_name} ${student.last_name}`}
      onClose={() => toggleModal(!isModalOpen)}
    />
  )
};

export default AADGearModalForm
