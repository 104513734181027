import PaymentHistory from "components/User/PaymentHistory";
import React from "react";
import { Card, Grid } from "tabler-react";

type RegistrationPaymentsProps = {
  userRegistrationDetails: any;
  outstanding: number;
  totalPaid: number;
};

const RegistrationPayments: React.FC<RegistrationPaymentsProps> = ({
  userRegistrationDetails,
  outstanding,
  totalPaid,
}: RegistrationPaymentsProps) => {
  return (
    <Card>
      <Card.Body>
        <Grid.Row className="">
          <Grid.Col>
            <div className="h5">Payments</div>
            <hr className="border border-primary my-1" />
            <PaymentHistory
              paymentsHistory={userRegistrationDetails.payments}
              remainingBalance={outstanding}
              totalPaid={totalPaid}
            />
          </Grid.Col>
        </Grid.Row>
      </Card.Body>
    </Card>
  );
};

export default RegistrationPayments;
