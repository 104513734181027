import React from 'react';
import { Container, Card, Table, Button, Dropdown } from 'tabler-react';
import Wrapper from 'pages/layout';

const TeamList = (props) => {
  return (
    <Wrapper {...props} title="Team List">
      <Container>
        <Card>
          <Card.Header>
            <Card.Title>Team List</Card.Title>
          </Card.Header>
          <Card.Body>
            <Table
              bodyItems={[
                {
                  key: 1,
                  item: [
                    { content: '1' },
                    { content: "Live Demo'" },
                    {
                      content: (
                        <Button.Dropdown color="primary">
                          <Dropdown.Item>Update</Dropdown.Item>
                          <Dropdown.Item>View</Dropdown.Item>
                          <Dropdown.ItemDivider />
                        </Button.Dropdown>
                      )
                    }
                  ]
                }
              ]}
              headerItems={[
                { content: 'Sr No.' },
                { content: 'Name' },
                { content: 'Action' }
              ]}
            />
          </Card.Body>
        </Card>
      </Container>
    </Wrapper>
  );
};
export default TeamList;
