import { useQuery } from "@apollo/client";
import React, { useCallback } from "react";
import { useParams } from "react-router";
import { Container, Dimmer } from "tabler-react";
import CompetitionGroupsList from "../../components/Calendar/CompetitionGroupList";
import EventGroupsList from "../../components/Calendar/EventGroupsList";
import { EEventTypes } from "../../components/enum";
import { GET_CAMP_BY_ID } from "../../graphql/GET_CAMP_BY_ID";
import { GET_CAMP_BY_ID as GET_CAMP_BY_ID_TYPE } from "../../graphql/types/GET_CAMP_BY_ID";
import { useRootStore } from "../../hooks";
import { useGetUserIsAdmin } from "../../modules/common/hooks/useGetCurrentUserType";
import useGetEventOrganizers from "../../modules/user/roles/hooks/useGetEventOrganizers";
import Wrapper from "../layout";
import PageNoAccess from "../PageNoAccess";

const EventGroups = (props) => {
  const { campId } = useParams<{ campId: string }>();
  const isAdmin = useGetUserIsAdmin();
  const { currentCoachTeam } = useRootStore();

  const { data: campData, loading: campLoading } =
    useQuery<GET_CAMP_BY_ID_TYPE>(GET_CAMP_BY_ID, {
      variables: {
        campId: Number(campId),
      },
    });
  const { isOrganizer, eventOrganizersDataLoading } = useGetEventOrganizers({
    eventId: Number(campId),
  });

  const hasAccess = useCallback(() => {
    return (
      isOrganizer ||
      (currentCoachTeam &&
        currentCoachTeam?.id === Number(campData?.getCampById?.team_id)) ||
      isAdmin
    );
  }, [currentCoachTeam, campData, isAdmin, isOrganizer]);

  if (!eventOrganizersDataLoading && !campLoading && !hasAccess()) {
    return <PageNoAccess />;
  }

  return (
    <Wrapper {...props} title="Groups">
      <Container>
        <Dimmer
          active={eventOrganizersDataLoading || campLoading}
          loader={eventOrganizersDataLoading || campLoading}
        >
          {!campLoading && !campData?.getCampById && (
            <div>
              <h2>No camp found</h2>
            </div>
          )}
          {!campLoading && campData?.getCampById && (
            <>
              {campData?.getCampById.event_type_id ===
              EEventTypes.competition ? (
                <CompetitionGroupsList
                  campId={campId}
                  campName={campData?.getCampById.camp_name}
                  teamId={campData?.getCampById.team_id}
                  students={campData?.getCampById.registered_students
                    .filter(
                      (s) =>
                        s.status_type &&
                        s.status_type.toLowerCase() === "confirmed",
                    )
                    .sort((a, b) => a.first_name.localeCompare(b.first_name))}
                />
              ) : (
                <EventGroupsList
                  campId={campId}
                  campName={campData?.getCampById.camp_name}
                  teamId={campData?.getCampById.team_id}
                  students={campData?.getCampById.registered_students
                    .filter(
                      (s) =>
                        s.status_type &&
                        s.status_type.toLowerCase() === "confirmed",
                    )
                    .sort((a, b) => a.first_name.localeCompare(b.first_name))}
                />
              )}
            </>
          )}
        </Dimmer>
      </Container>
    </Wrapper>
  );
};

export default EventGroups;
