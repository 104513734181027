import { useMutation, useQuery } from '@apollo/client';
import { Formik } from 'formik';
import { applySnapshot } from 'mobx-state-tree';
import React, { useState } from 'react';
import Select from 'react-select';
import { toast } from 'react-toastify';
import { Icon, Table } from 'tabler-react';

import FormField from "components/FormField";
import PhoneInput from "react-phone-input-2";
import { GET_TEAMS_BY_COACH_ID } from "../../../graphql/GET_TEAMS_BY_COACH_ID";
import { INSERT_TEAMS_TO_COACH_MUTATION } from "../../../graphql/INSERT_TEAMS_TO_COACH_MUTATION";
import { UPDATE_COACH_BY_ID } from "../../../graphql/UPDATE_COACH_BY_ID";
import { Types } from '../../../types/graphql'

interface InlineFormProps {
  coach: any;
  coachClone: Types.Coach;
  setIsEditing: React.Dispatch<React.SetStateAction<boolean>>;
}

const UpdateCoach = ({ coach, coachClone, setIsEditing }: InlineFormProps) => {
  const [selectedTeams, setSelectedTeams] = useState([]);
  const [teams, setTeams] = useState([]);
  const [teamUpdated, setTeamUpdated] = useState(false);

  const [updateCoachById] = useMutation(UPDATE_COACH_BY_ID);
  const [insertTeamsToCoach] = useMutation(INSERT_TEAMS_TO_COACH_MUTATION);

  const { loading, error, data } = useQuery(GET_TEAMS_BY_COACH_ID, {
    variables: {
      coachId: coach.id,
      fetch: "all",
    },
    fetchPolicy: "network-only",
  });

  const changeTeamHandle = (
    selectedTeams: Types.CoachTeam[]
  ) => {
    setTeamUpdated(true);
    if (selectedTeams) {
      const selected: number[] = selectedTeams.map(
        (team: Types.CoachTeam) => {
          return team.id;
        }
      );

      setSelectedTeams(selected);

      setTeams(
        teams.map((team: Types.CoachTeam) => {
          if (selected.includes(team.id)) {
            team.is_active = true;
          } else {
            team.is_active = false;
          }
          return team;
        })
      );
    } else {
      setTeams(
        teams.filter((team: Types.CoachTeam) => {
          team.is_active = false;
          return team;
        })
      );
    }
  };

  if (loading) {
    return <Table.Col>Loading...</Table.Col>;
  }

  if (error) {
    return <Table.Col>{`Error: ${error.message}`}</Table.Col>;
  }

  if (!loading && data && teams.length !== data.getTeamsByCoachId.length) {
    setTeams(data.getTeamsByCoachId);
  }

  return (
    <Formik
      enableReinitialize={true}
      initialValues={{
        id: coachClone.id,
        first_name: coachClone.first_name,
        last_name: coachClone.last_name,
        email: coachClone.email,
        phone_number: coachClone.phone_number,
        phone_country_code: coachClone.phone_country_code,
        teams: teams,
      }}
      onSubmit={async (values, { setSubmitting, resetForm }) => {
        if (teamUpdated) {
          await insertTeamsToCoach({
            variables: {
              coach_id: values.id,
              teams: selectedTeams,
            },
          });
        }
        await updateCoachById({
          variables: {
            coach: {
              id: values.id,
              first_name: values.first_name,
              last_name: values.last_name,
              email: values.email,
              phone_number: values.phone_number,
            },
          },
          update(_, { data: { updateCoachById } }) {
            if (updateCoachById) {
              applySnapshot(coach, { ...coachClone, ...values });
              toast.success("Coach Updated.");
            }
          },
        });

        setIsEditing(false);
        setSubmitting(false);
        resetForm();
      }}
    >
      {({ values, handleBlur, handleChange, handleSubmit, setFieldValue }) => (
        <>
          <Table.Col>
            <FormField
              name="first_name"
              onBlur={handleBlur}
              onChange={handleChange}
              type="text"
            />
            <FormField
              name="last_name"
              onBlur={handleBlur}
              onChange={handleChange}
              type="text"
            />
          </Table.Col>

          <Table.Col>
            <FormField
              name="email"
              onBlur={handleBlur}
              onChange={handleChange}
              type="email"
            />
          </Table.Col>
          <Table.Col>
            <PhoneInput
              inputProps={{
                className: "form-control w-100",
                name: "phone_number",
                required: true,

                onChange: (e) => {
                  e.preventDefault();
                  const target = e.target;
                  const phone = target.value.split(" ");
                  const countryCode = phone.shift().replace(/\D/g, "");
                  const phoneNumber = phone.join("").replace(/\D/g, "");
                  setFieldValue("phone_number", phoneNumber);
                  setFieldValue("phone_country_code", Number(countryCode));
                },
              }}
              value={`${values.phone_country_code}${values.phone_number}`}
            />
            <Select
              name="teams"
              isMulti
              getOptionLabel={(
                option: Types.CoachTeam
              ) => option.name}
              getOptionValue={(
                option: Types.CoachTeam
              ) => option.id}
              options={values.teams}
              onChange={changeTeamHandle}
              value={values.teams.filter(
                (team: Types.CoachTeam) =>
                  team.is_active === true
              )}
            />
          </Table.Col>
          <Table.Col>
            <Icon
              link
              name="save"
              className="text-primary mr-5"
              onClick={() => {
                handleSubmit();
              }}
            />
            <Icon link name="x" onClick={() => setIsEditing(false)} />
          </Table.Col>
        </>
      )}
    </Formik>
  );
};

export default UpdateCoach;
