import React from 'react'
import { Button, Card, Text } from 'tabler-react'

const CrmMessagesListItem = ({ coachName, id, message, created_on }) => {

  const [seeMore, setSeeMore] = React.useState(false)
  return (
    <div key={id}>
      <Card className="mb-0">
        <Card.Body className="bg-light">
          <Text style={{overflow:'hidden'}} className={`ws-prewrap textEditor-display ${!seeMore && 'h-9' }`}>
            {message}
          </Text>
          <Button.List align="center">
            <Button color="light" onClick={() => setSeeMore(!seeMore)}>{!seeMore ? "see more" : "see less"}</Button>
          </Button.List>
        </Card.Body>
      </Card>
      <Text.Small className="float-right text-muted m-1">
        <i className="fe fe-mail mr-2" />
        Email Blast
      </Text.Small>
      <Text.Small className="float-left text-muted m-1 pb-3">
        {'Sent: '}
        {created_on}
        {' by '}
        <strong>{coachName}</strong>
      </Text.Small>
    </div>
  )
}

export default CrmMessagesListItem
