import { useLazyQuery } from '@apollo/client';
import { observer } from 'mobx-react';
import { applySnapshot } from 'mobx-state-tree';
import React, { useContext, useEffect } from 'react';
import { Form, Grid } from 'tabler-react';

import { EventStoreContext } from '../../../contexts/eventStoreContext';
import { GET_TEAM_LOCATIONS_BY_SPORT_TYPE } from '../../../graphql/GET_TEAM_LOCATIONS_BY_SPORT_TYPE';

const EventActivityLocationForm = ({
  currentCoachTeam,
  eventEntryActivities,
  handleNext,
  mstHandleChange
}) => {
  const { eventEntry, eventEntryLocations } = useContext(EventStoreContext);

  const [getTeamLocationsBySportType, { loading, error, data }] = useLazyQuery(
    GET_TEAM_LOCATIONS_BY_SPORT_TYPE
  );

  useEffect(() => {
    if (!loading && !error && data) {
      applySnapshot(eventEntryLocations, data.getTeamLocationsBySportType);
    }
  }, [data]);  

  if (error) {
    return <p>Error: {`Error: ${error}`}</p>;
  }

  return (
    <>
      <Grid.Row>
        <Grid.Col>
          <Form.Group label="Activity">
            {eventEntryActivities
              .filter((activity) => !activity.sub)
              .map((activity) => (
                <div
                  className="switchradio position-relative d-inline-flex mr-3"
                  key={`${activity.sport_type_id}-${activity.name}`}
                >
                  <input
                    type="radio"
                    checked={
                      eventEntry.sport_type_id === activity.sport_type_id
                    }
                    value={activity.sport_type_id}
                    id={`${activity.sport_type_id}-${activity.name}`}
                    name="sport_type_id"
                    className="switchradio-input position-absolute"
                    onChange={(e) => {
                      getTeamLocationsBySportType({
                        variables: {
                          team_id: currentCoachTeam.id,
                          sport_type_id: parseInt(e.target.value),
                          filter: {}
                        }
                      });

                      // Reset location_id every time sport changes
                      eventEntry.set('location_id', null);

                      mstHandleChange(e);
                    }}
                    required={true}
                  />
                  <label
                    htmlFor={`${activity.sport_type_id}-${activity.name}`}
                    className="btn btn-secondary"
                  >
                    {activity.name}
                  </label>
                </div>
              ))}
          </Form.Group>
        </Grid.Col>
      </Grid.Row>

      {eventEntryLocations.length !== 0 && (
        <Grid.Row>
          <Grid.Col>
            <Form.Group label="Location">
              {eventEntryLocations.map(
                (location) =>
                  location.name && (
                    <div
                      className="switchradio position-relative d-inline-flex mr-3"
                      key={`${location.id}-${location.name}`}
                    >
                      <input
                        type="radio"
                        checked={eventEntry.location_id === Number(location.id)}
                        value={location.id}
                        id={`${location.id}-${location.name}`}
                        name="location_id"
                        className="switchradio-input position-absolute"
                        onChange={(e) => {
                          mstHandleChange(e);
                          handleNext();
                        }}
                        required={true}
                      />
                      <label
                        htmlFor={`${location.id}-${location.name}`}
                        className="btn btn-secondary"
                      >
                        {location.name}
                      </label>
                    </div>
                  )
              )}
            </Form.Group>
          </Grid.Col>
        </Grid.Row>
      )}
    </>
  );
};

export default observer(EventActivityLocationForm);
