import gql from 'graphql-tag';

export const GET_STUDENT_SPORTS = gql`
  query GET_STUDENT_SPORTS(
    $student_id: String!
  ) {
    studentSports(student_id: $student_id) {
      id
      name
      is_active
    }
  }`;

