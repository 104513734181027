import { Button } from "tabler-react";
import { formatMoney } from "utils/numberFormat";

export const ButtonPaid = ({
  totalPaid,
  remainingBalance,
  paymentStatus,
  onClick,
}: {
  totalPaid: number;
  remainingBalance: number;
  paymentStatus: string;
  onClick?: any;
}) => {
  return (
    <Button
      icon={paymentStatus === "Paid" ? "check-circle" : ""}
      color={paymentStatus === "Paid" ? "success" : "secondary"}
      size="sm"
      onClick={onClick}
    >
      {paymentStatus}: {formatMoney(totalPaid)}
    </Button>
  );
};

export const ButtonOwes = ({
  totalPaid,
  remainingBalance,
  compTotal,
  onClick,
}) => {
  return (
    <>
      {totalPaid > 0 && (
        <Button
        color={remainingBalance !== 0 ? "secondary" : "danger"}
          size="sm"
          className="mr-2 mb-1 d-block"
          onClick={onClick}
        >
          Paid: {formatMoney(totalPaid) + '/' + formatMoney(totalPaid + remainingBalance + compTotal)}
        </Button>
      )}
      <Button
        //  icon="alert-circle"
        color={"gray-dark"}
        size="sm"
        className="mr-2"
        disabled
      >
        Owes: {formatMoney(remainingBalance)}
      </Button>
    </>
  );
};

export const ButtonComp = ({ compTotal, onClick }) => {
  return (
    <Button
      outline
      color="secondary"
      size="sm"
      className="mr-2 mb-1 d-block"
      onClick={onClick}
    >
      Comp: {formatMoney(compTotal)}
    </Button>
  );
};

export const PaymentButtons = ({
  totalComp,
  totalPaid,
  outstanding,
  paymentStatus,
  status,
  onClick,
}) => {
  return (
    <>
      {(status === "Confirmed" || status === "Accepted") && (
        <>
          {totalComp > 0 && (
            <ButtonComp compTotal={totalComp / 100} onClick={onClick} />
          )}
          {outstanding > 0 && (
            <ButtonOwes
              totalPaid={totalPaid / 100}
              remainingBalance={outstanding / 100}
              compTotal={totalComp / 100}
              onClick={onClick}
            />
          )}
          {outstanding <= 0 && totalPaid !== 0 && (
            <ButtonPaid
              totalPaid={totalPaid / 100}
              remainingBalance={outstanding / 100}
              paymentStatus={paymentStatus}
              onClick={onClick}
            />
          )}
        </>
      )}
    </>
  );
};
