import gql from 'graphql-tag';

export const GET_JOBS_BY_STORAGE = gql`
  query GET_JOBS_BY_STORAGE($jobListFilter: JobListFilter!) {
    jobsContainerList(filter: $jobListFilter) {
      id
      user_gear_id
      job_type_id
      status
      job_storage
      created_on
      completed_on
      due_on
      unit_price
      quantity
      name

      student {
        id
        first_name
        last_name
      }

      user_gear {
        gear_canopy_size
        gear_serial
        gear_dom
        gear_model_name
        rig_name
      }

      invoice {
        id
        invoice_amount
        status
      }
    }
  }
`;
