import React, { useEffect, useState } from 'react';
import { Card, List } from 'tabler-react';
import TeamProgressionItem from './TeamProgressionItem';

const TeamProgressionsHeading = ({ heading, progressHeading }) => {
  const [teamProgressions, setTeamProgressions] = useState([]);
  const groupBySubHeader = (data) => {
    return data.reduce(function(storage, item) {
      const key = item?.subheading;
      storage[key] = storage[key] || [];
      storage[key].push(item);
      return storage;
    }, {});
  };

  useEffect(() => {
    setTeamProgressions(groupBySubHeader(progressHeading));
  }, [progressHeading]);
  return (<Card className={'mb-2'}><Card.Header>
    <Card.Title>{heading}</Card.Title>
  </Card.Header>

    {Object.keys(teamProgressions).map((subHeading) => {
      return (<List.Group key={subHeading}>
        <List.GroupItem action active>{subHeading}</List.GroupItem>
        {teamProgressions[subHeading].map((progress) => <TeamProgressionItem key={progress.id}
                                                                             progress={progress} />)}
      </List.Group>);
    })}


  </Card>);
};

export default TeamProgressionsHeading;
