import { gql } from '@apollo/client'

export const UPDATE_REGISTRATION_STATUS_BULK = gql`
  mutation UPDATE_REGISTRATION_STATUS_BULK(
    $bulkUpdateUserRegistrationStatus: BulkUpdateUserRegistrationStatus!
  ) {
    bulkUpdateUserRegistrationStatus(
      bulkUpdateUserRegistrationStatus: $bulkUpdateUserRegistrationStatus
    )
  }
`
