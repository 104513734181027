import { useMutation } from "@apollo/client";
import React from "react";
import { toast } from "react-toastify";
import { Avatar, Dropdown, Icon, Table, Tag, Text } from "tabler-react";
import { formatMoney } from "utils/numberFormat";
import { ADD_USER_EVENT } from "../../graphql/ADD_USER_EVENT";
import { DELETE_LOAD_USER } from "../../graphql/DELETE_LOAD_USER";
import { useRootStore } from "../../hooks";
import StudentLink from "../Students/StudentLink";
import { EUserEvents, EUserEventsId } from "../User/UserEvents";
import EditLoadPassengerModalForm from "./Form/EditLoadPassengerModalForm";

interface ILoadPassengerListItemProps {
  passenger: any;
  load: any;
}

const LoadPassengerListItem = ({
  passenger,
  load,
}: ILoadPassengerListItemProps) => {
  const { currentCoachTeam } = useRootStore();
  const [isOpen, setIsOpen] = React.useState(false);
  const [addUserEvent] = useMutation(ADD_USER_EVENT);
  const [deleteUserFromLoad] = useMutation(DELETE_LOAD_USER, {
    onCompleted: async () => {
      toast.success("Student added to load");
      await addUserEvent({
        variables: {
          userEvent: {
            status: EUserEvents.user_unloaded,
            user_event_type_id: EUserEventsId.user_unloaded,
            location_id: currentCoachTeam.default_location_id,
            team_id: currentCoachTeam.id,
            student_id: passenger.studentDetails.id,
          },
        },
      });
    },
    refetchQueries: ["GET_LOAD"],
  });
  const deletePassengerHandler = async () => {
    if (window.confirm("Are you sure you want to remove this passenger?")) {
      // delete passenger
      await deleteUserFromLoad({
        variables: {
          loadAssociatedUserId: passenger.id,
        },
      });
    }
  };
  return (
    <Table.Row>
      <Table.Col>
        <Avatar
          className="float-left mr-2"
          size="lg"
          imageURL={
            (passenger.studentDetails.profile_avatar &&
              `/api/s3/uploads/${passenger.studentDetails.profile_avatar}`) ||
            "//www.gravatar.com/avatar?d=mp"
          }
        />
        <StudentLink
          studentName={`${passenger.studentDetails.first_name} ${passenger.studentDetails.last_name}`}
          studentId={passenger.studentDetails.id}
        />

        <Tag color="white" className="text-success">
          {formatMoney(passenger.balance ?? 0)}
        </Tag>
        <Text.Small className="text-muted d-block">
          {passenger.association_type_name}
        </Text.Small>
      </Table.Col>
      <Table.Col>
        <Tag>{passenger.jump_type_name}</Tag>
      </Table.Col>
      <Table.Col>
        <Tag color="white" className="text-muted">
          Full Altitude / {formatMoney(passenger.compensation ?? 0)}
        </Tag>
      </Table.Col>
      <Table.Col className="text-right">
        <Dropdown
          className="float-right"
          toggle={false}
          arrow
          triggerContent={<Icon name="more-vertical" />}
          itemsObject={[
            {
              value: (
                <span className="cursor-pointer">
                  <i className="fe fe-edit mr-2" />
                  Edit
                </span>
              ),
              onClick: () => setIsOpen(true),
            },
            {
              value: (
                <span className="text-danger cursor-pointer">
                  <i className="fe fe-minus-circle mr-2 text-danger" />
                  Remove
                </span>
              ),
              onClick: deletePassengerHandler,
            },
          ]}
        />
        <EditLoadPassengerModalForm
          passenger={passenger}
          isOpen={isOpen}
          toggleModal={() => setIsOpen(false)}
          load={load}
        />
      </Table.Col>
    </Table.Row>
  );
};

export default LoadPassengerListItem;
