import { useMutation } from "@apollo/client";
import { teamPermissionOpts } from "components/Team/Form/teamContants";
import { Formik } from "formik";
import { UPDATE_TEAM_BY_ID } from "graphql/UPDATE_TEAM_BY_ID";
import { observer } from "mobx-react";
import { applySnapshot, getSnapshot } from "mobx-state-tree";
import { toast } from "react-toastify";
import { Button, Card, Form } from "tabler-react";
import * as Yup from "yup";

const featureValidation = Yup.object().shape({
  feature_cancel_transactions: Yup.boolean().oneOf([false, true]),
  feature_dashboard_groups: Yup.boolean().oneOf([false, true]),
  feature_dashboard: Yup.boolean().oneOf([false, true]),
  feature_disable_students: Yup.boolean().oneOf([false, true]),
  feature_edit_transactions: Yup.boolean().oneOf([false, true]),
  feature_locked_account_actions: Yup.boolean().oneOf([false, true]),
  feature_paid_status: Yup.boolean().oneOf([false, true]),
  feature_payments: Yup.boolean().oneOf([false, true]),
  feature_products: Yup.boolean().oneOf([false, true]),
  feature_recurring_transactions: Yup.boolean().oneOf([false, true]),
  feature_schedule_open: Yup.boolean().oneOf([false, true]),
  feature_student_progression: Yup.boolean().oneOf([false, true]),
  feature_transfer_time: Yup.boolean().oneOf([false, true]),
  feature_student_checkin: Yup.boolean().oneOf([false, true]),
});

const TeamPermissionFeature = ({ team, teamClone }) => {
  const [updateTeamById] = useMutation(UPDATE_TEAM_BY_ID);

  const mstHandleChange = (e) => {
    const target = e.target;

    teamClone.set(
      target.name,
      target.type === "checkbox" ? target.checked : target.value,
    );
  };

  return (
    <Formik
      enableReinitialize={true}
      initialValues={{
        id: parseInt(teamClone.id),
        feature_cancel_transactions: teamClone.feature_cancel_transactions,
        feature_disable_students: teamClone.feature_disable_students,
        feature_transfer_time: teamClone.feature_transfer_time,
        feature_recurring_transactions:
          teamClone.feature_recurring_transactions,
        feature_student_progression: teamClone.feature_student_progression,
        feature_schedule_open: teamClone.feature_schedule_open,
        feature_dashboard: teamClone.feature_dashboard,
        feature_dashboard_groups: teamClone.feature_dashboard_groups,
        feature_edit_transactions: teamClone.feature_edit_transactions,
        feature_paid_status: teamClone.feature_paid_status,
        feature_payments: teamClone.feature_payments,
        feature_products: teamClone.feature_products,
        feature_locked_account_actions:
          teamClone.feature_locked_account_actions,
        feature_student_checkin: teamClone.feature_student_checkin,
      }}
      validationSchema={featureValidation}
      onSubmit={async (values, { setSubmitting }) => {
        await updateTeamById({
          variables: {
            team: {
              ...values,
            },
          },
          update(_, { data: { updateTeamById } }) {
            if (updateTeamById) {
              applySnapshot(team, getSnapshot(teamClone));
              toast.success("Team Updated.");
            }
          },
        });

        setSubmitting(false);
      }}
    >
      {({ values, isSubmitting, handleSubmit }) => (
        <form onSubmit={handleSubmit}>
          <Form.Group>
            {teamPermissionOpts.map(
              (feature: { label: string; value: string }) => (
                <Form.Checkbox
                  checked={values[feature.value]}
                  key={feature.value}
                  label={feature.label}
                  onChange={(e) => mstHandleChange(e)}
                  name={feature.value}
                />
              ),
            )}
          </Form.Group>
          <Card.Footer className="px-0">
            <Button.List align="right">
              <Button
                size="sm"
                color="primary"
                disabled={isSubmitting}
                loading={isSubmitting}
                type="submit"
              >
                Update
              </Button>
            </Button.List>
          </Card.Footer>
        </form>
      )}
    </Formik>
  );
};

export default observer(TeamPermissionFeature);
