import StudentSearchForm from 'components/Students/Forms/StudentSearchForm';
import { useRootStore } from 'hooks';
import { observer } from 'mobx-react';
import FeatureAccessGate from 'modules/common/components/FeatureAccessGate';
import React from 'react';
import { Link } from 'react-router-dom';
import { Dropdown, Nav } from 'tabler-react';

const RiggerNav = () => {
  const { currentUser, currentCoachTeam } = useRootStore();

  return (
    <>
      <Nav.Item icon="home" to="/" value="/" className="d-print-none" />
      <FeatureAccessGate feature="feature_products">
        <Nav.Item icon="shopping-bag" to="/products" value="Products" />
      </FeatureAccessGate>
      <Nav.Item
        icon="alert-octagon"
        to="/coach/reserve-logs"
        value="Reserves"
        className="d-print-none"
      />
      <Nav.Item
        icon="box"
        to="/rigger/job-gear-storage"
        value="Storage"
        className="d-print-none"
      />
      <li className="nav-item ml-lg-auto mb-4 mb-lg-0 d-print-none">
        <Dropdown
          toggle={false}
          icon="calendar"
          position="bottom-end"
          flex
          className="mr-4 dropdown-header-icon-lg"
          items={
            <>
              <Link to="/user/calendar" className="dropdown-item d-none d-md-block">
                Calendar
              </Link>
            </>
          }
        />
        <Dropdown
          toggle={false}
          icon="pie-chart"
          position="bottom-end"
          flex
          className="mr-4 dropdown-header-icon-lg"
          items={
            <>
              <FeatureAccessGate feature="feature_invoices">
                <Link to="/invoices" className="dropdown-item">
                  Invoices
                </Link>
              </FeatureAccessGate>
              <FeatureAccessGate feature="feature_payments">
                <Link to="/payments" className="dropdown-item">
                  Payments
                </Link>
              </FeatureAccessGate>
              <FeatureAccessGate feature="feature_app_reports">
                  <Link to="/dzm/reports" className="dropdown-item">
                    Reports
                  </Link>
              </FeatureAccessGate>
            </>
          }
        />
        <Dropdown
          toggle={false}
          icon="users"
          position="bottom-end"
          flex
          className="mr-4 ml-4 dropdown-header-icon-lg"
          items={
            <>
              <Link to="/customers" className="dropdown-item">
                Customers
              </Link>
              <Link to="/staff" className="dropdown-item">
                TEAM Accounts
              </Link>
            </>
          }
        />
        <StudentSearchForm />
      </li>
    </>
  );
};

export default observer(RiggerNav);
