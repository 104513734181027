import React, { useContext, useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import RigCard from './RigCard';
import GearCard from './GearCard';
import { Button, Card, Grid, Icon } from 'tabler-react';
import UserCard from '../User/Profile/UserCard';
import { UserStoreContext } from '../../contexts/userStoreContext';
import RigGearModalButtons from './RigGearModalButtons';
import { useRootStore } from '../../hooks';
import { EUserTypes } from '../User/userTypes';
import { useQuery } from '@apollo/client';
import { GET_STUDENT_GEARS } from '../../graphql/GET_STUDENT_GEARS';
import { GET_RIGS } from '../../graphql/GET_RIGS';
import { Types } from '../../types/graphql'

interface IStudentSignupGearRigProps {
  setStep: any;
}

const StudentSignupGearRig = ({ setStep }: IStudentSignupGearRigProps) => {
  const userStore = useContext(UserStoreContext);
  const rootStore = useRootStore();
  const { currentUser } = rootStore;
  const { user } = userStore;
  const student_id = currentUser?.type === EUserTypes.student ? currentUser?.id : parseInt(user.id);

  const [userGears, setUserGears] = useState<Types.UserGear[]>(null);
  const [rigs, setRigs] = useState<Types.Rig[]>(null);

  const { loading, error, data } = useQuery(GET_STUDENT_GEARS, {
    variables: {
      getUserGearOptions: { student_id: student_id }
    }
  });

  const { loading: userRigsLoading, data: userRigsData } = useQuery(GET_RIGS, {
    variables: {
      rigListFilter: {
        student_id: student_id
      }
    }
  });

  useEffect(() => {
    if (!userRigsLoading && userRigsData) {
      setRigs(userRigsData.rigList);
    }
  }, [userRigsLoading, userRigsData]);

  useEffect(() => {
    if (!loading && !error && data) {
      setUserGears(data.getUserGears);
    }
     
  }, [data]);

  return (<>
    <Grid.Row>
      {currentUser && <Grid.Col lg={4} sm={12} xs={12}><UserCard
        firstName={user?.first_name}
        lastName={user?.last_name}
        profileAvatar={user?.profile_avatar}
        profilePicture={user?.profile_picture}
      />
      </Grid.Col>}
      <Grid.Col lg={currentUser ? 8 : 12} sm={12} xs={12}>
        <Card statusColor="blue">
          <Card.Header>
            <Card.Title>
              <Icon name="briefcase" className="mr-2 ml-0 text-blue" />
              Add your gear
            </Card.Title>
            <Card.Options>
              <RigGearModalButtons />
            </Card.Options>
          </Card.Header>
          <Card.Body>
            <RigCard rigs={rigs} />
            <GearCard userGears={userGears} />
          </Card.Body>
        </Card>
        <Button.List align="center">
          <Button
            color='white'
            size='lg'
            icon='chevron-left'
            onClick={() => {
              setStep(1);
            }
            }
          >
            BACK
          </Button>
          <Button
            color='white'
            size='lg'
            onClick={() => {
              setStep(3);
            }
            }
          >
            SKIP
          </Button>
          <Button
            color="primary"
            size="lg"
            onClick={() => {
              setStep(3);
            }
            }
          >
            NEXT <Icon name="chevron-right" />
          </Button>
        </Button.List>
      </Grid.Col>
    </Grid.Row>
  </>);
};

export default observer(StudentSignupGearRig);
