import { useMutation } from '@apollo/client';
import { Formik } from 'formik';
import { applySnapshot } from 'mobx-state-tree';
import React from 'react';
import { toast } from 'react-toastify';
import { Icon, Table } from 'tabler-react';

import FormField from '../../../components/FormField';
import { UPDATE_ADMIN_BY_ID } from '../../../graphql/UPDATE_ADMIN_BY_ID';

interface InlineFormProps {
  admin: any;
  adminClone: any;
  setIsEditing: React.Dispatch<React.SetStateAction<boolean>>;
}

const UpdateAdmin = ({ admin, adminClone, setIsEditing }: InlineFormProps) => {
  const [updateAdminById] = useMutation(UPDATE_ADMIN_BY_ID);

  return (
    <Formik
      enableReinitialize={true}
      initialValues={{
        id: adminClone.id,
        username: adminClone.username,
        password: ''
      }}
      onSubmit={async (values, { setSubmitting, resetForm }) => {
        await updateAdminById({
          variables: {
            admin: {
              id: values.id,
              username: values.username,
              password: values.password
            }
          },
          update(_, { data: { updateAdminById } }) {
            if (updateAdminById) {
              applySnapshot(admin, { ...adminClone, ...values });
              toast.success('Admin Updated.');
            }
          }
        });

        setSubmitting(false);
        resetForm();

        setIsEditing(false);
      }}
    >
      {({ handleBlur, handleChange, handleSubmit }) => (
        <>
          <Table.Col>
            <FormField
              name="username"
              onBlur={handleBlur}
              onChange={handleChange}
              type="text"
            />
          </Table.Col>
          <Table.Col>
            <FormField
              name="password"
              onBlur={handleBlur}
              onChange={handleChange}
              type="password"
              placeholder="PASSWORD"
            />
          </Table.Col>
          <Table.Col>
            <Icon
              link
              name="save"
              onClick={() => {
                handleSubmit();
              }}
            />
          </Table.Col>
          <Table.Col>
            <Icon link name="x-circle" onClick={() => setIsEditing(false)} />
          </Table.Col>
        </>
      )}
    </Formik>
  );
};

export default UpdateAdmin;
