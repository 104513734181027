import { useMutation, useQuery } from '@apollo/client'
import {
  ABCDLicensesAPFOptions,
  ABCDLicensesCSPAOptions,
  ABCDLicensesOptions
} from 'components/constants'
import { FAI_ORG_ID } from 'components/Students/Forms/ManualADLicenseForm'
import { Formik } from 'formik'
import { ADD_STUDENT_ORG_CREDENTIAL } from 'graphql/ADD_STUDENT_ORG_CREDENTIAL'
import { EDIT_STUDENT_ORG_CREDENTIAL } from 'graphql/EDIT_STUDENT_ORG_CREDENTIAL'
import { GET_ORGS } from 'graphql/GET_ORGS'
import { useRootStore } from 'hooks'
import { useGetUserIsStudent } from 'modules/common/hooks/useGetCurrentUserType'
import moment from 'moment'
import React, { useMemo } from 'react'
import Select from 'react-select'
import { toast } from 'react-toastify'
import { Button, Form, Grid } from 'tabler-react'
import { Types } from 'types/graphql'

type Props = {
  orgId: number
  orgName: string
  studentId: number
  license?: Types.OrgCredential
  orgSlug?: string
  toggle?: () => void
}

const AddLicenseForm: React.FC<Props> = ({
  orgId,
  orgName,
  orgSlug,
  studentId,
  license,
  toggle
}) => {
  const isStudent = useGetUserIsStudent()
  const { currentUser } = useRootStore()
  const [addStudentOrgCredential] = useMutation(ADD_STUDENT_ORG_CREDENTIAL, {
    refetchQueries: ['GET_ORG_CREDENTIALS', 'GET_USER_REGISTRATIONS_BY_IDS'],
    onCompleted: () => {
      toast.success('Credential added successfully')
    }
  })

  const [updateStudentOrgCredential] = useMutation(
    EDIT_STUDENT_ORG_CREDENTIAL,
    {
      refetchQueries: ['GET_ORG_CREDENTIALS', 'GET_USER_REGISTRATIONS_BY_IDS'],
      onCompleted: () => {
        toast.success('Credential updated successfully')
      }
    }
  )
  const { data: nacData, loading } = useQuery(GET_ORGS, {
    variables: {
      OrgFilter: {
        org_type: 'nac'
      }
    }
  })
  const nacList =
    nacData?.orgs
      .filter((i) => i.org_type === 'nac')
      .map((i) => ({
        value: i.id,
        name: i.name,
        slug: i.slug
      })) || []

  const licenseList = useMemo(() => {
    let selectedList = ABCDLicensesOptions
    if (orgId === 2) selectedList = ABCDLicensesCSPAOptions
    if (orgId === 3) selectedList = ABCDLicensesAPFOptions

    return selectedList.map((option) => {
      return {
        value: option,
        label: option
      }
    })
  }, [orgId])

  const customFilter = (option, searchText) => {
    if (
      option.data.name.toLowerCase().includes(searchText.toLowerCase()) ||
      option.data.slug.toLowerCase().includes(searchText.toLowerCase())
    ) {
      return true
    } else {
      return false
    }
  }

  const renderForm = ({
    handleChange,
    setFieldValue,
    handleSubmit,
    values,
    dirty
  }) => {
    return (
      <>
        <Grid.Row>
          <Grid.Col>
            <Form.Group
              label={`${orgSlug.toUpperCase()}  ${
                orgName ? `(${orgName})` : ''
              }`}
            ></Form.Group>
          </Grid.Col>
        </Grid.Row>

        <Grid.Row>
          <Grid.Col xs={12} sm={12} md={6} lg={6}>
            {Number(values.org_id) === FAI_ORG_ID && (
              <Select
                styles={{
                  menuPortal: (base) => ({ ...base, zIndex: 9999 })
                }}
                menuPortalTarget={document.body}
                className={'w-100'}
                label="NAC"
                name="nac_id"
                placeholder="Select NAC"
                searchText={false}
                options={nacList}
                getOptionLabel={(option) => (
                  <>
                    <span className="mr-1 font-weight-bold">
                      {option.slug.toUpperCase()}
                    </span>{' '}
                    {option.name}
                  </>
                )}
                filterOption={customFilter}
                onChange={(e) => {
                  setFieldValue('nac_id', e.value)
                }}
                value={
                  nacList.find(
                    (i) => Number(i.value) === Number(values.nac_id)
                  ) || null
                }
              />
            )}

            {Number(values.org_id) !== FAI_ORG_ID && (
              <Form.Group label="">
                <Select
                  styles={{
                    menuPortal: (base) => ({ ...base, zIndex: 9999 })
                  }}
                  menuPortalTarget={document.body}
                  name="license"
                  placeholder="Select license..."
                  searchText={false}
                  options={licenseList}
                  onChange={(e) => {
                    setFieldValue('license', e.value)
                  }}
                  value={
                    licenseList.find((i) => i.value === values.license) || null
                  }
                />
              </Form.Group>
            )}
          </Grid.Col>
          <Grid.Col sm={6} md={6}>
            <Form.Group label="">
              <Form.Input
                name="license_number"
                placeholder="Enter license number"
                value={values?.license_number}
                onChange={handleChange}
              />
            </Form.Group>
          </Grid.Col>
        </Grid.Row>
        <Grid.Row>
          <Grid.Col>
            <Button
              color="gray-dark"
              pill
              size="sm"
              className="float-right"
              onClick={handleSubmit}
            >
              SAVE
            </Button>
          </Grid.Col>
        </Grid.Row>
      </>
    )
  }
  return (
    <Formik
      enableReinitialize={true}
      initialValues={{
        org_id: license?.org_id || orgId,
        student_id: studentId,
        verified_on: license?.verified_on || null,
        verified_by: license?.verified_by || null,
        license: license?.license || '',
        license_number: license?.license_number || '',
        nac_id: license?.nac_id || null
      }}
      onSubmit={(values, { setSubmitting, resetForm }) => {
        if (!isStudent) {
          values.verified_by = currentUser.id.toString()
          values.verified_on = moment().format('YYYY-MM-DD')
        }
        if (license?.id) {
          if (isStudent) {
            values.verified_by = null
            values.verified_on = null
          }
          updateStudentOrgCredential({
            variables: {
              updateOrgCredentialInput: {
                id: license.id,
                ...values
              }
            }
          })
        } else {
          addStudentOrgCredential({
            variables: {
              addOrgCredentialInput: {
                ...values
              }
            }
          })
        }

        resetForm()
        setSubmitting(false)
        if (typeof toggle === 'function') {
          toggle()
        }
      }}
    >
      {(formData) => renderForm(formData)}
    </Formik>
  )
}

export default AddLicenseForm
