import React from 'react';
import { Grid, Page, StatsCard, Button } from 'tabler-react';

import Wrapper from 'pages/layout';

const ReportsPage = (props) => {
  return (
    <Wrapper {...props} title="Reports">
      <Page.Content title="Reports (coming soon)">
        <Grid.Row>
          <Grid.Col>
            <h3 className="float-left">Accounts</h3>
            <h3 className="float-right">
              <Button
                icon="chevrons-left"
                color="white"
                size="sm"
              >
                Last Month
              </Button>
              <Button
                className="ml-2"
                icon="chevron-left"
                color="white"
                size="sm"
              >
                Last Week
              </Button>
              <Button
                className="ml-2"
                icon=""
                color="primary"
                size="sm"
                disabled
              >
                This Week
              </Button>
            </h3>
          </Grid.Col>
        </Grid.Row>
        <Grid.Row>
          <Grid.Col sm={6} lg={3} xs={12} className="mb-0">
            <StatsCard
              layout={4}
              movement={3}
              total="$27,000"
              label="Payments"
              actions={0}
            />
          </Grid.Col>
          <Grid.Col sm={6} lg={3} xs={12} className="mb-0">
            <StatsCard layout={4} movement={-3} total="4:20" label="Used" />
          </Grid.Col>
          <Grid.Col sm={6} lg={3} xs={12} className="mb-0">
            <StatsCard
              layout={3}
              movement={-18}
              total="0:45"
              label="Credits"
              actions={0}
            />
          </Grid.Col>
          <Grid.Col sm={6} lg={3} xs={12} className="mb-0">
            <StatsCard
              layout={1}
              movement={9}
              total="7"
              label="Canceled Transactions"
            />
          </Grid.Col>
        </Grid.Row>

        <Grid.Row>
          <Grid.Col>
            <h3>Users</h3>
          </Grid.Col>
        </Grid.Row>
        <Grid.Row>
          <Grid.Col sm={6} lg={3} xs={12} className="mb-0">
            <StatsCard
              layout={2}
              movement={24}
              total="43"
              label="Positive Balance"
            />
          </Grid.Col>
          <Grid.Col sm={6} lg={3} xs={12} className="mb-0">
            <StatsCard
              layout={2}
              movement={0}
              total="5"
              label="Negative Balance"
            />
          </Grid.Col>
          <Grid.Col sm={6} lg={3} xs={12} className="mb-0">
            <StatsCard
              layout={2}
              movement={0}
              total="455"
              label="Zero Balance"
            />
          </Grid.Col>
          <Grid.Col sm={6} lg={3} xs={12} className="mb-0">
            <StatsCard layout={2} movement={1} total="12" label="New Users" />
          </Grid.Col>
        </Grid.Row>
      </Page.Content>
    </Wrapper>
  );
};

export default ReportsPage;
