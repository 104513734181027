import { useQuery } from "@apollo/client";
import { Box } from "@material-ui/core";
import { DEFAULT_LIMIT } from "components/constants";
import InvoiceListItem from "components/Invoices/InvoiceListItem";
import EstimateModalForm from "components/Invoices/Modals/EstimateModalForm";
import { GET_INVOICE_LIST } from "graphql/GET_INVOICE_LIST";
import { GET_INVOICE_LIST as GET_INVOICE_LIST_TYPE } from "graphql/types/GET_INVOICE_LIST";
import { useRootStore } from "hooks";
import { observer } from "mobx-react";
import { QueryParams } from "modules/common/constants/serviceParams";
import useGetCurrentUserType from "modules/common/hooks/useGetCurrentUserType";
import useDebounceCallback from "modules/common/utils/useDebounceCallback";
import { useMemo, useState } from "react";
import {
  Button,
  Card,
  Dimmer,
  Form,
  Grid,
  Icon,
  Table,
  Text,
} from "tabler-react";
import { Types } from "../../types/graphql";

const InvoiceList = () => {
  const { isStudent, isAdmin, isRigger, isDzm, isCoach } =
    useGetCurrentUserType();
  const { currentUser, currentCoachTeam } = useRootStore();

  const [isInvoiceModalOpen, setIsInvoiceModalOpen] = useState<boolean>(false);
  const [page, setPage] = useState(0);
  const [status, setStatus] = useState("");

  const [queryFilter, setQueryFilter] = useState<QueryParams>({
    limit: DEFAULT_LIMIT,
    offset: page * DEFAULT_LIMIT,
  });

  const { data, loading } = useQuery<GET_INVOICE_LIST_TYPE>(GET_INVOICE_LIST, {
    variables: {
      invoiceListFilter: {
        student_id: isStudent ? currentUser?.id : undefined,
        team_id: currentCoachTeam?.id ?? undefined,
        status,
        ...queryFilter,
        limit: DEFAULT_LIMIT,
        offset: page * DEFAULT_LIMIT,
      },
    },
  });

  const [value, setValue] = useState("");

  const onChange = () => {
    setPage(() => 0);

    setQueryFilter({
      ...queryFilter,
      q: value === "" ? undefined : value,
      offset: page,
    });
  };

  const debouncedOnChange = useDebounceCallback(onChange);

  const invoiceRows: Types.Invoice[] = useMemo(() => {
    if (!data?.invoiceList.invoices) {
      return [];
    }

    return (
      data.invoiceList.invoices.map((invoice) => {
        return invoice;
      }) ?? []
    );
  }, [data?.invoiceList, queryFilter.q, page, loading]);

  const pendingInvoiceTotal = useMemo(() => {
    const pendingInvoice = data?.invoiceList.statusCounts.filter(
      (invoice) => invoice.status === "Pending",
    );

    return pendingInvoice?.[0]?.count ?? 0;
  }, [data?.invoiceList]);

  const paidInvoiceTotal = useMemo(() => {
    const paidInvoice = data?.invoiceList.statusCounts.filter(
      (invoice) => invoice.status === "Paid",
    );

    return paidInvoice?.[0]?.count ?? 0;
  }, [data?.invoiceList]);

  const estimateInvoiceTotal = useMemo(() => {
    const estimateInvoice = data?.invoiceList.statusCounts.filter(
      (invoice) => invoice.status === "Estimate",
    );

    return estimateInvoice?.[0]?.count ?? 0;
  }, [data?.invoiceList]);

  const archivedInvoiceTotal = useMemo(() => {
    const archivedInvoice = data?.invoiceList.statusCounts.filter(
      (invoice) => invoice.status === "Archived",
    );

    return archivedInvoice?.[0]?.count ?? 0;
  }, [data?.invoiceList]);

  const total = useMemo(
    () => data?.invoiceList.total ?? 0,
    [data?.invoiceList],
  );

  return (
    <Grid.Row>
      <Grid.Col xs={12} lg={3}>
        <Form.Group className="mb-5">
          <Form.Input
            autoFocus
            type="search"
            placeholder="Search by customer..."
            name="search"
            value={value}
            onChange={(e) => {
              debouncedOnChange();
              setValue(e.target.value);
            }}
          />
        </Form.Group>

        <h5>Invoice Status</h5>
        <Form.Group>
          <div className="d-flex justify-content-between">
            <Form.Radio
              label="Pending"
              name="status"
              value={"Pending"}
              checked={status === "Pending"}
              onChange={() => {
                setStatus("Pending");
              }}
            />

            <Text className="text-muted">{pendingInvoiceTotal}</Text>
          </div>

          <div className="d-flex justify-content-between">
            <Form.Radio
              label="Paid"
              name="status"
              value={"Paid"}
              checked={status === "Paid"}
              onChange={() => {
                setStatus("Paid");
              }}
            />

            <Text className="text-muted">{paidInvoiceTotal}</Text>
          </div>

          <div className="d-flex justify-content-between">
            <Form.Radio
              label="Estimate"
              name="status"
              value={"Estimate"}
              checked={status === "Estimate"}
              onChange={() => {
                setStatus("Estimate");
              }}
            />

            <Text className="text-muted">{estimateInvoiceTotal}</Text>
          </div>

          <div className="d-flex justify-content-between">
            <Form.Radio
              label="Archived"
              name="status"
              value={"Archived"}
              checked={status === "Archived"}
              onChange={() => {
                setStatus("Archived");
              }}
            />

            <Text className="text-muted">{archivedInvoiceTotal}</Text>
          </div>
        </Form.Group>
      </Grid.Col>
      <Grid.Col xs={12} lg={9}>
        <EstimateModalForm
          invoice={null}
          toggleModal={setIsInvoiceModalOpen}
          isModalOpen={isInvoiceModalOpen}
        />

        <Card>
          <Card.Header>
            <Card.Title>
              <Icon name="file" className="mr-2 ml-0 text-blue" />
              Invoices
            </Card.Title>
            <Card.Options>
              {(isRigger || isDzm || isCoach) && (
                <Button
                  className="mr-3"
                  color="primary"
                  icon="plus"
                  onClick={() => setIsInvoiceModalOpen(true)}
                >
                  ESTIMATE
                </Button>
              )}
            </Card.Options>
          </Card.Header>
          <Card.Body>
            <Grid.Row>
              <Grid.Col width={12}>
                <Dimmer active={loading} loader={loading}>
                  <Table
                    responsive
                    className="card-table table-vcenter text-nowrap pb-5"
                  >
                    <Table.Header>
                      <Table.Row>
                        <Table.ColHeader>INV</Table.ColHeader>
                        {!isStudent && (
                          <Table.ColHeader>Customer</Table.ColHeader>
                        )}
                        {(isAdmin || isStudent) && (
                          <Table.ColHeader>Company</Table.ColHeader>
                        )}
                        <Table.ColHeader></Table.ColHeader>
                        <Table.ColHeader>Date</Table.ColHeader>
                        <Table.ColHeader>Amount</Table.ColHeader>
                        <Table.ColHeader>Outstanding</Table.ColHeader>
                        <Table.ColHeader>Status</Table.ColHeader>
                        {isStudent && <Table.ColHeader></Table.ColHeader>}
                        {!isStudent && <Table.ColHeader>Notes</Table.ColHeader>}
                      </Table.Row>
                    </Table.Header>
                    <Table.Body>
                      {invoiceRows.map((invoice: Types.Invoice) => (
                        <InvoiceListItem key={invoice.id} invoice={invoice} />
                      ))}
                    </Table.Body>
                  </Table>
                </Dimmer>
              </Grid.Col>
            </Grid.Row>
          </Card.Body>

          <Card.Footer>
            <Box display="flex" justifyContent="flex-end">
              <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                gridGap={16}
              >
                <Button
                  color="white"
                  disabled={!page}
                  onClick={() => setPage((prev) => prev - 1)}
                >
                  <Icon name="chevron-left" className="mr-2 ml-0" />
                </Button>
                <span>{page + 1}</span>
                <Button
                  color="white"
                  disabled={page + 1 >= data?.invoiceList.total / DEFAULT_LIMIT}
                  onClick={() => setPage((prev) => prev + 1)}
                >
                  <Icon name="chevron-right" className="mr-0 ml-2" />
                </Button>
              </Box>
            </Box>
          </Card.Footer>
        </Card>
      </Grid.Col>
    </Grid.Row>
  );
};

export default observer(InvoiceList);
