import { observer } from 'mobx-react';
import React from 'react';
import { Container } from 'tabler-react';

import LocationDetails from '../../components/Location/LocationDetails';
import { LocationStoreProvider } from '../../contexts/LocationStoreContext';
import Wrapper from '../layout';

const LocationDetailsPage = (props) => {
  return (
    <Wrapper {...props} title="Location Details">
      <Container>
        <LocationStoreProvider>
          <LocationDetails />
        </LocationStoreProvider>
      </Container>
    </Wrapper>
  );
};

export default observer(LocationDetailsPage);
