import { useMutation, ApolloError } from '@apollo/client'
import { RESET_PASSWORD_BY_EMAIL } from 'graphql/RESET_PASSWORD_BY_EMAIL'
import { toast } from 'react-toastify'

const useResetPasswordByEmail = () => {
  const [resetPasswordByEmail] = useMutation(RESET_PASSWORD_BY_EMAIL, {
    onCompleted: () => toast.success('Password reset email sent.'),
    onError: (error: ApolloError) => {
      toast.error(error?.graphQLErrors[0]?.message)
    }
  })

  return {
    resetPasswordByEmail
  }
}

export default useResetPasswordByEmail
