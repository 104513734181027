import { useContext } from "react";

import { RootStoreContext } from "contexts";
import { RootStoreType } from "models";

export const useRootStore = (): RootStoreType => {
  const store = useContext(RootStoreContext);
  if (!store) {
    throw new Error(
      "The RootStoreContext is not provided using the <RootStoreProvider />."
    );
  }
  return store;
};
