import Modal from "components/Modal";
import React from "react";
import AddMembershipForm from "../forms/AddMembershipForm";

interface Props {
  // Define your component's props here
  orgId: number;
  orgName: string;
  studentId: number;
  toggle?: () => void;
  membership?: string;
  expires?: string;
  id: number;
  isOpen: boolean;
}

const AddMembershipModal: React.FC<Props> = ({
  orgId,
  orgName,
  studentId,
  membership,
  expires,
  id,
  toggle,
  isOpen,
}) => {
  // Add your component logic here

  return (
    // Add your JSX code here
    <Modal
      title={`${membership ? "Edit" : "Add"} Organization Membership`}
      onClose={toggle}
      open={isOpen}
      content={
        <AddMembershipForm
          orgId={orgId}
          orgName={orgName}
          studentId={studentId}
          membership={membership}
          expires={expires}
          id={id}
          toggle={toggle}
        />
      }
    />
  );
};

export default AddMembershipModal;
