import React from 'react';
import Modal from '../Modal';
import { Card, Grid } from 'tabler-react';

interface JobNotesProps {
  notes: string;
  toggleModal: any;
  isModalOpen: any;
}

const JobNotes = ({ notes, toggleModal, isModalOpen }: JobNotesProps) => {

  return (<Modal
    content={
      <Card title="Job Notes">
        <Card.Body>
          <Grid.Row>
            <Grid.Col width={12}>
              {notes}
            </Grid.Col>
          </Grid.Row>
        </Card.Body>
      </Card>
    }
    open={isModalOpen}
    onClose={() => toggleModal(!isModalOpen)}
  />);
};

export default JobNotes;
