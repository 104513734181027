import { useMutation } from "@apollo/client";
import { formatDate } from "@fullcalendar/react";
import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";
import CurrencyInputField from "components/CurrencyInputField";
import FormField from "components/FormField";
import Modal from "components/Modal";
import { EPaymentOptions } from "components/Stripe/account-balance/StripeAccountBalanceModal";
import { EUserEventsId } from "components/User/UserEvents";
import { EUserTypes } from "components/User/userTypes";
import { manualPaymentOpts } from "components/constants";
import { Formik, FormikValues } from "formik";
import { ADD_USER_EVENT } from "graphql/ADD_USER_EVENT";
import { CHARGE_STRIPE_V2 } from "graphql/CHARGE_STRIPE_V2";
import { GET_ALL_USER_REGISTRATION_EVENTS } from "graphql/GET_ALL_USER_REGISTRATION_EVENTS";
import { GET_PAYMENTS } from "graphql/GET_PAYMENTS";
import { GET_USER_REGISTRATIONS_BY_IDS } from "graphql/GET_USER_REGISTRATIONS_BY_IDS";
import { INSERT_PAYMENT } from "graphql/INSERT_PAYMENT";
import { useRootStore } from "hooks";
import useGetCurrentUserType from "modules/common/hooks/useGetCurrentUserType";
import {
  calculateFees,
  calculateTotalWithFees,
  manualFormSchema,
  stripeFormSchema,
} from "modules/invoice/components/modals/InvoicePaymentModal";
import { EManualPaymentOpts } from "modules/payment/constants/manualPaymentOptions";
import { EPaymentStatus } from "modules/payment/constants/paymentStatus";
import React, { FC } from "react";
import { toast } from "react-toastify";
import { Button, Card, Form, Grid, Text } from "tabler-react";
import { formatMoney } from "utils/numberFormat";
import { Types } from "../../../../../types/graphql";

interface RegistrationPaymentModalProps {
  amount: number;
  currency: string;
  isModalOpen: boolean;
  name: string;
  paymentFee: number;
  userRegistrationDetails: Types.UserRegistrationsById;
  toggleModal: () => void;
}

const RegistrationPaymentModal: FC<RegistrationPaymentModalProps> = ({
  amount,
  currency,
  isModalOpen,
  name,
  paymentFee,
  userRegistrationDetails,
  toggleModal,
}) => {
  const stripe = useStripe();
  const elements = useElements();
  const { currentUser } = useRootStore();
  const team_id = Number(userRegistrationDetails?.team_id);
  const { isAdmin, isCoachSuperAdmin } = useGetCurrentUserType();

  const canMakeManualPayment = currentUser.type !== EUserTypes.student;
  const {
    camp_name,
    start,
    end,
    email,
    registration_code,
    user_registrations_id,
  } = { ...userRegistrationDetails };

  const [form, setForm] = React.useState("stripe");

  const student_id = Number(userRegistrationDetails.student_id);

  const [userEvent] = useMutation(ADD_USER_EVENT);
  const [chargeStripeV2] = useMutation(CHARGE_STRIPE_V2, {
    onCompleted: () => {
      userEvent({
        variables: {
          userEvent: {
            user_event_type_id: EUserEventsId.pay_complete,
            student_id,
            status: "Paid",
            camp_id: Number(userRegistrationDetails.camp_id),
            team_id,
            user_registration_id: user_registrations_id,
          },
        },
        refetchQueries: [
          {
            query: GET_ALL_USER_REGISTRATION_EVENTS,
            variables: {
              filter: {
                userRegistrationId: user_registrations_id,
              },
              limit: 20,
              page: 1,
            },
          },
        ],
      });
      toast.success("Payment saved.");
    },
  });
  const [insertPayment] = useMutation(INSERT_PAYMENT, {
    onCompleted: () => {
      userEvent({
        variables: {
          userEvent: {
            user_event_type_id: EUserEventsId.pay_complete,
            student_id,
            status: "Paid",
            camp_id: Number(userRegistrationDetails.camp_id),
            team_id,
            user_registration_id: user_registrations_id,
          },
        },
        refetchQueries: [
          {
            query: GET_ALL_USER_REGISTRATION_EVENTS,
            variables: {
              filter: {
                userRegistrationId: user_registrations_id,
              },
              limit: 20,
              page: 1,
            },
          },
        ],
      });
      toast.success("Payment saved.");
    },
  });

  const stripeTokenHandler = async ({ token, email, total, amount, fees }) => {
    const description = `${camp_name}: ${registration_code}`;

    try {
      await chargeStripeV2({
        refetchQueries: ["GET_USER_REGISTRATIONS_BY_IDS", "GET_PAYMENTS"],
        variables: {
          chargeParams: {
            source: token.id,
            total,
            amount,
            fees,
            description,
            currency,
            student_id,
            registration_id: user_registrations_id,
            team_id,
          },
        },
      });
      toggleModal();
    } catch (error: any) {
      toast.error(error.message);
    }
  };

  const renderStripeForm = ({
    errors,
    values,
    handleSubmit,
    isSubmitting,
    setFieldValue,
  }) => (
    <form onSubmit={handleSubmit} className="stripe-form">
      <fieldset disabled={isSubmitting}>
        <Grid.Row>
          <Grid.Col xs={12} sm={12} md={6} lg={6}>
            <CurrencyInputField
              name="total"
              value={values.total}
              setFieldValue={setFieldValue}
              prefix="$"
              customInput={undefined}
              className="input-xxl text-center border-bottom text-muted form-control"
            />
            <span className="field-error text-danger">
              {errors.total && errors.total}
            </span>
            <Grid.Col width={12} className="pl-0 pr-0">
              <Text.Small className="float-right text-muted">
                Fees: {formatMoney(Number(values.total) * paymentFee)}{" "}
                <span
                  className="cursor-help"
                  title={`${(paymentFee * 100).toFixed(
                    2,
                  )}% includes all processing fees, taxes, etc.`}
                >
                  <i className="fe fe-help-circle ml-1" />
                </span>
              </Text.Small>
              <Text.Small className="text-muted">{currency}</Text.Small>
            </Grid.Col>
          </Grid.Col>
          <Grid.Col xs={12} sm={12} md={6} lg={6}>
            <Grid.Row className="mb-4 mt-3">
              <Grid.Col className="text-right">
                {camp_name}
                <Text.Small className="d-block text-muted">
                  {formatDate(start)} - {formatDate(end)}
                </Text.Small>
              </Grid.Col>
            </Grid.Row>
            <Grid.Row className="mb-4">
              <Grid.Col className="text-right">
                <Text>
                  <span className="text-muted">Registration Code:</span>{" "}
                  {registration_code}
                </Text>
              </Grid.Col>
            </Grid.Row>
          </Grid.Col>
        </Grid.Row>

        <Grid.Row>
          <Grid.Col width={12}>
            <FormField
              appendleft={<Button icon={"mail"} color={"secondary"} disabled />}
              name="email"
              label="Email"
              type="text"
            />
          </Grid.Col>
        </Grid.Row>
        <Grid.Row>
          <Grid.Col width={12}>
            <FormField
              appendleft={<Button icon={"user"} color={"secondary"} disabled />}
              name="name"
              label="Name on Card"
              type="text"
            />
          </Grid.Col>
        </Grid.Row>
        <CardElement
          options={{
            style: {
              base: {
                fontSize: "16px",
                color: "#424770",
                "::placeholder": {
                  color: "#aab7c4",
                },
                lineHeight: "1.5rem",
              },
              invalid: {
                color: "#9e2146",
              },
            },
            classes: {
              base: "form-group form-control",
            },
          }}
        />
        <Button
          block
          color="primary"
          type="submit"
          disabled={!stripe || isSubmitting}
        >
          {isSubmitting
            ? "Processing..."
            : `PAY ${formatMoney(
                values.total + values.total * paymentFee,
                currency,
              )}`}
        </Button>
        <Text.Small className="text-muted">
          Powered by{" "}
          <a
            href="https://stripe.com/"
            target="_blank"
            rel="noopener noreferrer"
          >
            Stripe
          </a>
          .{" View Stripe "}
          <a
            href="https://stripe.com/legal/end-users"
            target="_blank"
            rel="noopener noreferrer"
          >
            Terms
          </a>
          {" and "}
          <a
            href="https://stripe.com/privacy"
            target="_blank"
            rel="noopener noreferrer"
          >
            Privacy
          </a>
          .
        </Text.Small>
      </fieldset>
    </form>
  );

  const renderManualForm = ({
    errors,
    values,
    handleChange,
    handleSubmit,
    isSubmitting,
    setFieldValue,
  }: FormikValues) => (
    <form onSubmit={handleSubmit}>
      <fieldset disabled={isSubmitting}>
        <Grid.Row>
          <Grid.Col xs={12} sm={12} md={6} lg={6}>
            <CurrencyInputField
              name="amount"
              value={values.amount}
              setFieldValue={setFieldValue}
              prefix="$"
              customInput={undefined}
              className="input-xxl text-center mb-3 border-bottom text-muted form-control"
            />
            <span className="field-error text-danger">
              {errors.total && errors.total}
            </span>
          </Grid.Col>
          <Grid.Col xs={12} sm={12} md={6} lg={6}>
            <Grid.Row className="mb-4 mt-3">
              <Grid.Col className="text-right">
                {camp_name}
                <Text.Small className="d-block text-muted">
                  {formatDate(start)} - {formatDate(end)}
                </Text.Small>
              </Grid.Col>
            </Grid.Row>
            <Grid.Row className="mb-4">
              <Grid.Col className="text-right">
                <Text>
                  <span className="text-muted">Registration Code:</span>{" "}
                  {registration_code}
                </Text>
              </Grid.Col>
            </Grid.Row>
          </Grid.Col>
        </Grid.Row>
        {/* HIDE TEMPORARILY - need to send email confirmation to student email is entered
      <Grid.Row>
        <Grid.Col>
          <Form.Group label="Email">
            <FormField
              appendleft={<Button icon={'mail'} color={'secondary'} disabled />}
              name="email"
              placeholder="Email"
            />
          </Form.Group>
        </Grid.Col>
      </Grid.Row>
*/}
        <Grid.Row>
          <Grid.Col xs={12} sm={6} md={6} lg={6}>
            <Form.Group>
              <Form.Select
                name="manual_payment"
                onChange={handleChange}
                className="text-uppercase"
              >
                {manualPaymentOpts.map((paymentType) => (
                  <option value={paymentType.value} key={paymentType.value}>
                    {paymentType.label}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>
          </Grid.Col>
          <Grid.Col xs={12} sm={6} md={6} lg={6}>
            <Button block color="primary" className="" type="submit">
              Log Payment of {formatMoney(values.amount, currency)}
            </Button>
          </Grid.Col>
        </Grid.Row>
      </fieldset>
    </form>
  );

  const StripeFormik = () => {
    return (
      <Formik
        enableReinitialize={true}
        validationSchema={stripeFormSchema({
          name,
          email: email ?? "",
          total: amount,
        })}
        initialValues={{
          name: name,
          email: email ?? "",
          total: amount,
        }}
        onSubmit={async (values, { resetForm, setSubmitting }) => {
          const { name, email, total } = values;

          if (!stripe || !elements) {
            return;
          }

          const card = elements.getElement(CardElement);
          const result = await stripe.createToken(card, {
            name,
            currency,
          });

          if (result.error) {
            toast.error(result.error.message);
            return;
          }

          await stripeTokenHandler({
            token: result.token,
            email,
            total,
            amount: calculateTotalWithFees(total, paymentFee),
            fees: calculateFees(total, paymentFee),
          });
          setSubmitting(false);
          resetForm();
          toggleModal();
        }}
      >
        {(formikData) => renderStripeForm(formikData)}
      </Formik>
    );
  };
  const ManualFormik = () => {
    return (
      <Formik
        enableReinitialize={true}
        initialValues={{
          amount: amount,
          manual_payment: EManualPaymentOpts.cash,
          registration_id: user_registrations_id,
          student_id,
          description: email,
        }}
        validationSchema={manualFormSchema}
        onSubmit={async (values, { setSubmitting, resetForm }) => {
          await insertPayment({
            variables: {
              paymentParams: {
                ...values,
                currency,
                processed_by: "Manual Payment",
                status:
                  values.manual_payment === EManualPaymentOpts.comp
                    ? EPaymentStatus.comp
                    : EPaymentStatus.paid,
                created_by: currentUser.id,
                team_id,
              },
            },
            refetchQueries: [
              {
                query: GET_USER_REGISTRATIONS_BY_IDS,
                variables: {
                  userRegistrationsId: user_registrations_id,
                  studentId: student_id,
                },
              },
              {
                query: GET_PAYMENTS,
                variables: {
                  params: {
                    camp_id: Number(userRegistrationDetails.camp_id),
                  },
                },
              },
            ],
          });
          setSubmitting(false);
          toggleModal();
        }}
      >
        {(formikData) => renderManualForm(formikData)}
      </Formik>
    );
  };

  return (
    <Modal
      content={
        <>
          {canMakeManualPayment && (
            <>
              <Card.Header className="px-0 mb-4">
                <Card.Title>
                  <Button
                    color="primary"
                    icon="credit-card"
                    outline={form !== EPaymentOptions.stripe}
                    onClick={() => setForm(EPaymentOptions.stripe)}
                    className={`${
                      form === EPaymentOptions.stripe ? "active" : ""
                    } mr-2`}
                  >
                    CARD
                  </Button>
                  <Button
                    color="info"
                    outline={form !== EPaymentOptions.manual}
                    onClick={() => setForm(EPaymentOptions.manual)}
                    className={`${
                      form === EPaymentOptions.manual ? "active" : ""
                    } mr-2`}
                  >
                    LOG PAYMENT
                  </Button>
                  {/* STUDENTS WILL HAVE THE OPTION TO PAY OTHER WAYS */}
                  {(isAdmin || isCoachSuperAdmin) && (
                    <Button
                      outline
                      icon="dollar-sign"
                      color="secondary"
                      disabled
                    />
                  )}
                </Card.Title>
              </Card.Header>

              {form === "stripe" ? <StripeFormik /> : <ManualFormik />}
            </>
          )}

          {!canMakeManualPayment && <StripeFormik />}
        </>
      }
      open={isModalOpen}
      title={name}
      onClose={toggleModal}
    />
  );
};

export default RegistrationPaymentModal;
