import { useQuery } from '@apollo/client';
import { observer } from 'mobx-react';
import { applySnapshot } from 'mobx-state-tree';
import React, { useContext, useEffect, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { Dimmer, Grid, Table } from 'tabler-react';

import { EUserTypes } from 'components/User/userTypes';
import { DEFAULT_PRODUCT_LIMIT } from 'components/constants';
import { useRootStore } from 'hooks';
import { ProductStoreContext } from '../../contexts/productContext';
import { GET_ALL_ACCOUNT_TYPES } from '../../graphql/GET_ALL_ACCOUNT_TYPES';
import { GET_PRODUCTS_BY_FILTER_QUERY } from '../../graphql/GET_PRODUCTS_BY_FILTER_QUERY';
import { GET_PRODUCT_TYPES } from '../../graphql/GET_PRODUCT_TYPES';
import { EOrderOptions } from '../../hooks/useAutocomplete';
import ProductListItemAdmin from './ProductListItemAdmin';

interface IProductsListAdminProps {
  team_id: number;
  statusList?: any;
  typeList?: any;
}

const ProductsListAdmin = ({ team_id, statusList, typeList }: IProductsListAdminProps) => {
  const [page, setPage] = useState(0);
  const { products } = useContext(ProductStoreContext);
  const { currentUser } = useRootStore();

  const { loading, error, data, fetchMore } = useQuery(
    GET_PRODUCTS_BY_FILTER_QUERY,
    {
      variables: {
        order: EOrderOptions.alphabet,
        filter: {
          team_id,
          status: statusList,
          product_type_id: typeList,
          limit: DEFAULT_PRODUCT_LIMIT,
          offset: 0
        }
      }
    }
  );

  const { loading: productTypeLoading, data: productTypeData } =
    useQuery(GET_PRODUCT_TYPES);
  const { loading: accountTypeLoading, data: accountTypeData } = useQuery(
    GET_ALL_ACCOUNT_TYPES
  );

  useEffect(() => {
    if (!loading && data) {
      setPage(1);
      applySnapshot(products, data.products);
    }
  }, [data, statusList, typeList]);  

  if (error) {
    return <p>{error.message}</p>;
  }

  const onFetchMore = () => {
    setPage((prevNum) => prevNum + 1);
    return fetchMore({
      updateQuery: (cache, { fetchMoreResult }) => {
        if (!fetchMoreResult) {
          return cache;
        }

        return {
          ...cache,
          products: [...cache.products, ...fetchMoreResult.products]
        };
      },
      variables: {
        order: EOrderOptions.alphabet,
        filter: {
          team_id,
          status: statusList,
          product_type_id: typeList,
          limit: DEFAULT_PRODUCT_LIMIT,
          offset: DEFAULT_PRODUCT_LIMIT * page
        }
      }
    });
  };

  return (
    <Dimmer active={loading} loader={loading}>
      {currentUser.type === EUserTypes.admin && (
        <>
          <InfiniteScroll
            className="overflow-visible pb-3"
            dataLength={products.length}
            hasMore={products.length < (data?.products[0]?.total || 0)}
            loader={<Dimmer active={true} loader={true} />}
            next={onFetchMore}
            scrollThreshold="210px"
          >
            <Grid.Col width={12}>
              <Grid.Row className="product-list">
                <Table
                  responsive
                  className="card-table table-vcenter text-nowrap"
                >
                  <Table.Header>
                    <Table.Row>
                      <Table.ColHeader>Title</Table.ColHeader>
                      <Table.ColHeader>Status</Table.ColHeader>
                      <Table.ColHeader>Amount</Table.ColHeader>
                      <Table.ColHeader></Table.ColHeader>
                      <Table.ColHeader></Table.ColHeader>
                      <Table.ColHeader>Code</Table.ColHeader>
                      <Table.ColHeader></Table.ColHeader>
                    </Table.Row>
                  </Table.Header>
                  <Table.Body>
                    {!productTypeLoading &&
                      !accountTypeLoading &&
                      products.map((product) => (
                        <Table.Row key={product.id}>
                          <ProductListItemAdmin
                            product={product}
                            productType={productTypeData.productTypes}
                            accountType={accountTypeData.getAllAccountTypes}
                          />
                        </Table.Row>
                      ))}
                  </Table.Body>
                </Table>
              </Grid.Row>
            </Grid.Col>
          </InfiniteScroll>
        </>
      )}
    </Dimmer>
  );
};

export default observer(ProductsListAdmin);
