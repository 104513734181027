import gql from 'graphql-tag';

export const GET_SPORT_TYPES = gql`
  query GET_SPORT_TYPES {
    getSportTypes {
      id
      slug
      name
      sub
    }
  }
`;
