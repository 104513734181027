import { cast, Instance, SnapshotIn, types } from 'mobx-state-tree';

const Aircraft = types.model({
  aircraft_notes: types.optional(types.string, '', [null]),
  aircraft_type: types.optional(types.string, '', [null]),
  capacity_max: types.optional(types.number, 0, [null]),
  capacity_min: types.optional(types.number, 0, [null]),
  id: types.optional(types.string, '', [null]),
  name: types.optional(types.string, '', [null]),
  tail_number: types.optional(types.string, '', [null])
});

const Location = types.model({
  id: types.optional(types.string, '', [null]),
  name: types.optional(types.string, '', [null]),
  type: types.optional(types.string, '', [null])
});

const Status = types.model({
  name: types.optional(types.string, '', [null]),
  slug: types.optional(types.string, '', [null])
});

const PassengerDetail = types.model({
  first_name: types.optional(types.string, '', [null]),
  id: types.optional(types.string, '', [null]),
  last_name: types.optional(types.string, '', [null])
});


const Passenger = types.model({
  //associationType: types.maybe(AssociationType),
  id: types.optional(types.string, '', [null]),
  //jumpType: types.maybe(JumpType),
  association_type_id: types.optional(types.string, '', [null, undefined]),
  association_type_name: types.optional(types.string, '', [null]),
  jump_type_id: types.optional(types.string, '', [null, undefined]),
  jump_type_name: types.optional(types.string, '', [null]),
  sequence: types.optional(types.number, 0, [null]),
  studentDetails: types.maybe(PassengerDetail)
});

const Load = types
  .model({
    aircraft: types.maybe(Aircraft),
    created_on: types.optional(types.string, '', [null]),
    id: types.optional(types.string, '', [null]),
    load_complete: types.optional(types.string, '', [null]),
    load_departure: types.optional(types.string, '', [null]),
    load_name: types.optional(types.string, '', [null]),
    location: types.maybe(Location),
    status: types.maybe(Status),
    team_id: types.optional(types.string, '', [null]),
    passengers: types.optional(
      types.array(types.maybe(Passenger)),
      [],
      [null, undefined]
    )
  })
  .actions((self) => ({
    set<
      K extends keyof SnapshotIn<typeof self>,
      T extends SnapshotIn<typeof self>
    >(key: K, value: T[K]) {
      self[key] = cast(value);
    }
  }));

export const LoadStoreDefault = () => {
  return LoadStore.create({
    loads: undefined
  });
};

export const LoadStore = types
  .model({
    aircrafts: types.optional(types.array(Aircraft), []),
    loads: types.optional(types.array(Load), [])
  })
  .actions((self) => ({}));

export interface LoadStoreType extends Instance<typeof LoadStore> {}
