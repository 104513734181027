import gql from 'graphql-tag';

export const GET_TEAMS_BY_STUDENT_ID = gql`
  query GET_TEAMS_BY_STUDENT_ID($student_id: ID!) {
    getTeamsByStudentId(student_id: $student_id) {
      id
      name
    }
  }
`;
