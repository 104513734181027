import gql from 'graphql-tag'

export const GET_TEAMS_BY_FILTER_QUERY = gql`
  query GET_TEAMS_BY_FILTER_QUERY($filter: String!) {
    teamByFilterQuery(filter: $filter) {
      id
      name
      slug
      is_public
      is_published
    }
  }
`
