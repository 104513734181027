import 'date-fns';
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  TimePicker as MaterialTimePicker
} from '@material-ui/pickers';
import { styled } from '@material-ui/core/styles';
import React from 'react';

const StyledTimePicker = styled(MaterialTimePicker)({
  margin: '0',
  '& div > input': {
    display: 'block',
    background: '#fff',
    padding: '0.375rem 0.75rem',
    fontSize: '0.9375rem',
    lineHeight: '1.6',
    color: '#495057',
    backgroundClip: 'padding-box',
    border: '1px solid rgba(0, 40, 100, 0.12)',
    borderRadius: '3px',
    transition: 'border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out',
    height: 'auto'
  },
  '& div::before': {
    content: 'none'
  },
  '& div::after': {
    content: 'none'
  },
  '&.is-invalid div > input': {
    borderColor: '#cd201f'
  },
  '&.state-invalid div > input': {
    paddingRight: '2rem',
    background: 'no-repeat center right 0.5rem/1rem'
  },
  '&.is-valid div > input': {
    borderColor: '#5eba00'
  },
  '&.state-valid div > input': {
    paddingRight: '2rem',
    background: 'no-repeat center right 0.5rem/1rem'
  }
});

interface ITimePickerProps {
  name: string;
  className?: string;
  minutesStep?: number;
  value: any;
  placeholder?: string;
  onChange: (value: Date) => void;
  readOnly?: boolean;
}

const TimePicker = ({
  name,
  className,
  minutesStep,
  value,
  placeholder,
  onChange,
  readOnly
}: ITimePickerProps) => {
  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <StyledTimePicker
        name={name}
        margin="normal"
        className={className}
        minutesStep={minutesStep || 1}
        value={value || null}
        onChange={(value) => onChange(value)}
        placeholder={placeholder || ''}
        readOnly={readOnly || false}
        variant="inline"
      />
    </MuiPickersUtilsProvider>
  );
};

export default TimePicker;
