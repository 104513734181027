import gql from 'graphql-tag';

export const ADD_STUDENT_TO_GROUP = gql`
  mutation ADD_STUDENT_TO_GROUP(
    $addGroupAssociatedUsersInput: AddGroupAssociatedUsersInput!
  ) {
    addStudentToGroup(
      addGroupAssociatedUsersInput: $addGroupAssociatedUsersInput
    )
  }
`;
