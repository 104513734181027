import { useMutation } from '@apollo/client';
import FormField from 'components/FormField';
import { Formik } from 'formik';
import { GET_INVOICE } from '../../graphql/GET_INVOICE'
import { GET_JOB_LIST } from 'graphql/GET_JOB_LIST';
import { UPDATE_JOB } from 'graphql/UPDATE_JOB';
import React from 'react';
import { NumericFormat } from 'react-number-format';
import { toast } from 'react-toastify';
import { Types } from '../../types/graphql'

interface IInvoiceEditJobQuantityProps {
  job: Types.Job;
  invoice: Types.Invoice;
  setIsEditting: (isEditting: boolean) => void;
}

const InvoiceEditJobUnitPrice = ({
  job,
  invoice,
  setIsEditting
}: IInvoiceEditJobQuantityProps) => {
  const [updateJob] = useMutation(UPDATE_JOB);

  return (
    <Formik
      enableReinitialize={true}
      initialValues={{
        id: job?.id ?? null,
        unit_price: job?.unit_price
      }}
      onSubmit={async (values, { resetForm, setSubmitting }) => {
        await updateJob({
          variables: {
            updateJobInput: {
              id: values.id,
              unit_price: values.unit_price
            }
          },
          refetchQueries: [
            {
              query: GET_JOB_LIST,
              variables: {
                jobListFilter: {
                  invoice_id: invoice.id
                }
              }
            },
            {
              query: GET_INVOICE,
              variables: {
                id: invoice.id
              }
            },
          ]
        });

        setSubmitting(false);
        resetForm();
        setIsEditting(false);
        toast.success('Job saved!');
      }}
    >
      {({ values, setFieldValue, submitForm }) => (
        <NumericFormat
          autoFocus
          className={'w-7 m-auto d-inline-flex'}
          customInput={FormField}
          name="unit_price"
          placeholder=""
          additionalgroupclasses={['mb-0']}
          value={values.unit_price}
          decimalScale={2}
          allowLeadingZeros={false}
          allowNegative={false}
          prefix="$"
          onBlur={submitForm}
          onKeyUp={(event) => {
            switch (event.keyCode) {
              case 13:
                submitForm();
                break;
              case 27:
                setIsEditting(false);
                break;
            }
          }}
          onChange={(e) =>
            setFieldValue(
              'unit_price',
              parseFloat(e.target.value.replace('$', '').replaceAll(',', ''))
            )
          }
        />
      )}
    </Formik>
  );
};

export default InvoiceEditJobUnitPrice;
