import gql from "graphql-tag";

export const ADD_NOTIFICATION_EMAIL = gql`
  mutation ADD_NOTIFICATION_EMAIL(
    $addCampNotificationEmailInput: AddCampNotificationEmailInput!
  ) {
    addCampNotificationEmail(
      addCampNotificationEmailInput: $addCampNotificationEmailInput
    )
  }
`;
