import { useQuery } from "@apollo/client";
import moment from "moment/moment";
import { useMemo } from "react";
import { Table, Tag } from "tabler-react";
import useReactRouter from "use-react-router";

import { GET_INVOICE_ITEMS } from "../../graphql/GET_INVOICE_ITEMS";
import { useRootStore } from "../../hooks";
import PrintInvoiceButton from "../../modules/invoice/components/PrintInvoiceButton";
import { Types } from "../../types/graphql";
import { formatMoney } from "../../utils/numberFormat";
import StudentLink from "../Students/StudentLink";
import { EUserTypes } from "../User/userTypes";

interface IInvoiceListItemProps {
  invoice: Types.Invoice;
}

const InvoiceListItem = ({ invoice }: IInvoiceListItemProps) => {
  const rootStore = useRootStore();
  const { currentUser } = rootStore;
  const { history } = useReactRouter();

  const { data: items } = useQuery(GET_INVOICE_ITEMS, {
    variables: {
      invoice_id: invoice.id,
    },
  });

  const total = useMemo(
    () =>
      items?.invoiceItems.reduce((sum, item) => {
        if (item?.job_id) {
          return sum + item.job.unit_price * item.job.quantity;
        } else {
          return sum + item.product.amount;
        }
      }, 0),

    [items?.invoiceItems],
  );

  const bins = useMemo(() => {
    const binList = items?.invoiceItems.reduce((sum, item) => {
      if (!sum.includes(item?.job.job_storage)) {
        sum.push(item?.job.job_storage);
      }
      return sum;
    }, []);

    return binList ?? [];
  }, [items?.invoiceItems]);

  const pdfDetails = useMemo(() => {
    if (invoice) {
      return {
        company_name: invoice.company_name,
        company_phone: invoice.company_phone,
        company_email: invoice.company_email,
        customer_name: invoice.customer_name,
        customer_phone: invoice.customer_phone,
        customer_email: invoice.customer_email,
        created_on: invoice.created_on,
        invoice_type: invoice.invoice_type_id,
        invoice_terms: invoice?.team?.invoice_terms,
      };
    }

    return null;
  }, [invoice]);

  const outstanding = invoice.paymentDetail.outstanding / 100;
  const invoiceStatus = outstanding <= 0 ? "Paid" : invoice.status;

  return (
    <>
      <Table.Row>
        <Table.Col>
          <span
            className={"cursor-pointer text-primary"}
            onClick={() => {
              if (currentUser.type !== EUserTypes.student)
                history.push(`/dzm/invoice/${invoice.id}`);
            }}
          >
            {invoice.id}
          </span>
        </Table.Col>
        {currentUser.type !== EUserTypes.student && (
          <Table.Col>
            <strong>
              <StudentLink
                studentId={Number(invoice.student.id)}
                studentName={`${invoice.student.first_name} ${invoice.student.last_name}`}
              />
            </strong>
          </Table.Col>
        )}
        <Table.Col className="align-left">
          {bins.map(
            (b) =>
              b && (
                <Tag className="ml-2" key={b}>
                  {b}
                </Tag>
              ),
          )}
        </Table.Col>
        {[EUserTypes.student.toString(), EUserTypes.admin.toString()].includes(
          currentUser.type,
        ) && <Table.Col>{invoice?.team?.name}</Table.Col>}
        <Table.Col>
          <span>{moment(invoice.created_on).format("D MMM, YYYY")}</span>
        </Table.Col>
        <Table.Col>{formatMoney(invoice.invoice_amount)}</Table.Col>
        <Table.Col>{formatMoney(outstanding)}</Table.Col>
        <Table.Col>
          <span
            className={`status-icon bg-${
              invoiceStatus === "Paid"
                ? "success"
                : invoiceStatus === "Archived"
                ? "danger"
                : "secondary"
            }`}
          />
          {invoiceStatus}
        </Table.Col>
        {currentUser.type === EUserTypes.student && (
          <Table.Col>
            {items?.invoiceItems && (
              <PrintInvoiceButton
                invoiceId={invoice.id}
                invoiceItems={items?.invoiceItems}
                pdfDetails={pdfDetails}
                total={total}
              />
            )}
          </Table.Col>
        )}
        {currentUser.type !== EUserTypes.student && (
          <Table.Col>{invoice.invoice_notes}</Table.Col>
        )}
      </Table.Row>
    </>
  );
};

export default InvoiceListItem;
