import Modal from 'components/Modal';
import React, { ReactNode } from 'react';
import { Button, Text } from 'tabler-react';

type StatusModalProps = {
  actions?: ReactNode;
  icon?: ReactNode;
  title: string;
  content: ReactNode;
  isModalOpen: boolean;
  toggleModal: () => void;
};

const StatusModal = ({
  actions,
  icon,
  isModalOpen,
  title,
  content,
  toggleModal
}: StatusModalProps) => {
  return (
    <Modal
      fullWidth={true}
      content={
        <div className="text-center">
          {icon}
          <h3 className="text-center">{title}</h3>
          <Text className="mt-7">{content}</Text>
        </div>
      }
      open={isModalOpen}
      onClose={toggleModal}
      actions={
        actions ?? (
          <Button color="success" onClick={toggleModal}>
            CLOSE
          </Button>
        )
      }
    />
  );
};

export default StatusModal;
