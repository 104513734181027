import { useMutation, useQuery } from "@apollo/client";
import { Formik } from "formik";
import React from "react";
import { toast } from "react-toastify";
import { Button, Form, Grid } from "tabler-react";
import useReactRouter from "use-react-router";
import * as Yup from "yup";
import { ADD_INVOICE } from "../../../graphql/ADD_INVOICE";
import { GET_INVOICE_LIST } from "../../../graphql/GET_INVOICE_LIST";
import { GET_TEAM_BY_ID } from "../../../graphql/GET_TEAM_BY_ID";
import { useRootStore } from "../../../hooks";
import { formatPhoneNumberWithCountryCode } from "../../../utils/phoneFormat";
import Modal from "../../Modal";
import StudentSearchField from "../../Students/Forms/StudentSearchField";

interface EstimateModalFormProps {
  invoice: any;
  toggleModal: any;
  isModalOpen: any;
}

const addEstimateValidation = Yup.object().shape({
  student_id: Yup.number().required("This field is required."),
});

const EstimateModalForm = ({
  invoice,
  toggleModal,
  isModalOpen,
}: EstimateModalFormProps) => {
  const rootStore = useRootStore();
  const { currentUser, currentCoachTeam } = rootStore;
  const { history } = useReactRouter();
  const [addInvoice] = useMutation(ADD_INVOICE);

  const { data: teamData } = useQuery(GET_TEAM_BY_ID, {
    variables: {
      teamId: currentCoachTeam?.id,
    },
  });

  const renderForm = ({
    values,
    handleSubmit,
    isSubmitting,
    handleChange,
    errors,
    touched,
    setFieldValue,
  }) => (
    <form onSubmit={handleSubmit}>
      <Grid.Row className="mb-4">
        <Grid.Col xs={12} sm={12} lg={6}>
          <Form.Group label="Customer">
            <StudentSearchField
              autoFocus={true}
              onChange={(param) => {
                if (!param) return;
                setFieldValue("student_id", parseInt(param.id));
                setFieldValue(
                  "customer_name",
                  `${param.first_name} ${param.last_name}`,
                );
                setFieldValue(
                  "customer_phone",
                  formatPhoneNumberWithCountryCode(
                    param.phone_number,
                    param.phone_country_code,
                  ),
                );
                setFieldValue("customer_email", param.email);
              }}
              defaultValue={""}
            />
            <span className="field-error text-danger">
              {errors.student_id && touched.student_id && errors.student_id}
            </span>
          </Form.Group>
        </Grid.Col>
      </Grid.Row>
      <Grid.Row>
        <Grid.Col xs={12} sm={12} lg={12}>
          <Form.Group label="Notes/Terms">
            <Form.Textarea
              type="text"
              name="invoice_notes"
              placeholder="Add notes to customer"
              rows={3}
              onChange={handleChange}
              value={values.invoice_notes}
            />
            <span className="field-error text-danger">
              {errors.invoice_notes &&
                touched.invoice_notes &&
                errors.invoice_notes}
            </span>
          </Form.Group>
        </Grid.Col>
      </Grid.Row>

      <Button
        disabled={isSubmitting}
        pill
        className="btn-gray-dark float-right btn-sm"
        onClick={handleSubmit}
      >
        {isSubmitting ? "Processing..." : "CREATE"}
      </Button>

      <Button
        pill
        color="white"
        className="float-right btn-sm"
        onClick={(e) => {
          e.preventDefault();
          toggleModal(false);
        }}
      >
        CANCEL
      </Button>
    </form>
  );
  return (
    <Modal
      content={
        <Formik
          enableReinitialize={true}
          validationSchema={addEstimateValidation}
          initialValues={{
            invoice_type_id: 2,
            student_id: "",
            invoice_amount: 0,
            status: "Estimate",
            invoice_notes: "",
            created_by: currentUser.id,
            company_name: teamData?.team.name,
            company_phone: formatPhoneNumberWithCountryCode(
              teamData?.team.team_phone,
              teamData?.team.team_phone_country_code,
            ),
            company_email: teamData?.team.team_email,
            customer_name: `${invoice?.student.first_name} ${invoice?.student.last_name}`,
            customer_phone: invoice?.student.phone_number,
            customer_email: invoice?.student.email,
            team_id: currentCoachTeam?.id,
          }}
          onSubmit={async (values, { resetForm, setSubmitting }) => {
            const filter = {
              status: undefined,
              team_id: currentCoachTeam?.id,
              limit: 100,
              offset: 0,
              student_id: undefined,
            };

            const newInvoice = await addInvoice({
              variables: {
                addInvoiceInput: {
                  invoice_type_id: values.invoice_type_id,
                  student_id: values.student_id,
                  invoice_amount: values.invoice_amount,
                  status: values.status,
                  invoice_notes: values.invoice_notes,
                  created_by: values.created_by,
                  company_name: values.company_name,
                  company_phone: values.company_phone,
                  company_email: values.company_email,
                  customer_name: values.customer_name,
                  customer_phone: values.customer_phone,
                  customer_email: values.customer_email,
                  team_id: values.team_id,
                },
              },
              refetchQueries: [
                {
                  query: GET_INVOICE_LIST,
                  variables: {
                    invoiceListFilter: {
                      ...filter,
                    },
                  },
                },
              ],
            });

            if (newInvoice?.data.addInvoice.id) {
              history.push(`/dzm/invoice/${newInvoice?.data.addInvoice.id}`);
            }
            setSubmitting(false);
            resetForm();
            toast.success("Estimate saved!");
            toggleModal(!isModalOpen);
          }}
        >
          {(formikData) => renderForm(formikData)}
        </Formik>
      }
      open={isModalOpen}
      onClose={() => toggleModal(!isModalOpen)}
    />
  );
};

export default EstimateModalForm;
