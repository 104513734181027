import Modal from 'components/Modal';
import AddCoachForm from 'modules/coach/components/forms/AddCoachForm';
import React, { FC } from 'react';

interface AddCoachModalProps {
  isModalOpen: boolean;
  toggleModal: () => void;
}

const AddCoachModal: FC<AddCoachModalProps> = ({
  isModalOpen,
  toggleModal
}) => {
  return (
    <Modal
      content={<AddCoachForm toggleModal={toggleModal} />}
      open={isModalOpen}
      onClose={toggleModal}
      title="Add TEAM Account"
    />
  );
};

export default AddCoachModal;
