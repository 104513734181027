import { useQuery } from '@apollo/client'
import { GET_EVENT_ORGANIZERS } from 'graphql/GET_EVENT_ORGANIZERS'
import { GET_EVENT_ORGANIZERS as GET_EVENT_ORGANIZERS_TYPE } from 'graphql/types/GET_EVENT_ORGANIZERS'
import { useRootStore } from '../../../../hooks'

const useGetEventOrganizers = (params: { eventId: number }) => {
  const { eventId } = params
  const { currentUser } = useRootStore()

  const { data: eventOrganizersData, loading: eventOrganizersDataLoading } =
    useQuery<GET_EVENT_ORGANIZERS_TYPE>(GET_EVENT_ORGANIZERS, {
      variables: {
        params: {
          role_id: 16,
          camp_id: Number(eventId)
        }
      }
    })

  const isOrganizer =
    eventOrganizersData?.getEventOrganizers.find(
      (eo) => eo.coach_id === currentUser.id
    )

  return {
    eventOrganizersData,
    eventOrganizersDataLoading,
    isOrganizer
  }
}

export default useGetEventOrganizers
