import { useMemo, useState } from 'react';
import { Button, Card, Form, Icon } from 'tabler-react';
import GearList from '../Gear/GearList';
import ModelList from '../Gear/ModelList';
import ManufactuereList from '../Gear/ManufacturerList';
import GearModelModalForm from '../Gear/Modal/GearModelModalForm';
import GearManufacturerModal from '../Gear/Modal/GearManufacturerModal';

const AdminGearsInformation = () => {
  const [view, setView] = useState<string>('gears');
  const title = useMemo(() => {
    if (view === 'gears') return 'Gears';
    if (view === 'models') return 'Models';
    if (view === 'manufacturers') return 'Manufacturers';
    return 'Gears';
  }, [view]);
  const [isModelModalOpen, setIsModelModalOpen] = useState<boolean>(false);
  const [isManufacturerModalOpen, setIsManufacturerModalOpen] =
    useState<boolean>(false);
  const [nameFilter, setNameFilter] = useState('');
  return (
    <>
      <Card>
        <Card.Header>
          <Card.Title>
            <Icon name="briefcase" className="mr-2 ml-0 text-blue" />
            {title}
          </Card.Title>
          <Card.Options>
            <Button
              outline
              icon="plus"
              className="mr-2"
              color="primary"
              onClick={(e) => {
                if (view === 'models') setIsModelModalOpen(true);
                if (view === 'manufacturers') setIsManufacturerModalOpen(true);
              }}
              disabled={view === 'gears'}
            />
            <Form.Input
              autoFocus
              type="search"
              placeholder="Search..."
              name="search"
              className="ml-3 w-25"
              value={nameFilter}
              onChange={(e) => {
                setNameFilter(e.target.value);
                e.persist();
              }}
            />
            <Button
              className="mr-3 ml-3"
              color={view === 'gears' ? 'primary' : 'secondary'}
              onClick={(e) => {
                if (view !== 'gears') setView('gears');
              }}
            >
              GEAR
            </Button>
            <Button
              className="mr-3"
              color={view === 'models' ? 'primary' : 'secondary'}
              size="sm"
              onClick={(e) => {
                if (view !== 'models') setView('models');
              }}
            >
              MODELS
            </Button>
            <Button
              className="mr-3"
              color={view === 'manufacturers' ? 'primary' : 'secondary'}
              onClick={(e) => {
                if (view !== 'manufacturers') setView('manufacturers');
              }}
            >
              MANUFACTURERS
            </Button>
          </Card.Options>
        </Card.Header>
        <Card.Body>
          {view === 'gears' && <GearList nameFilter={nameFilter} />}
          {view === 'models' && <ModelList nameFilter={nameFilter} />}
          {view === 'manufacturers' && (
            <ManufactuereList nameFilter={nameFilter} />
          )}
        </Card.Body>
      </Card>
      <GearModelModalForm
        gearModel={{
          name: null,
          gear_type_id: null,
          gear_manufacturer_id: null,
          gear_type_name: null,
          gear_manufacturer_name: null
        }}
        toggleModal={setIsModelModalOpen}
        isModalOpen={isModelModalOpen}
      />
      <GearManufacturerModal
        manufacturer={null}
        isOpen={isManufacturerModalOpen}
        toggleOpen={setIsManufacturerModalOpen}
      />
    </>
  );
};

export default AdminGearsInformation;
