import { useMutation } from "@apollo/client";
import React from "react";
import { toast } from "react-toastify";
import { Form } from "tabler-react";
import { TOGGLE_REGISTRATION_SCORECARD } from "../../../../../graphql/TOGGLE_REGISTRATION_SCORECARD";
import { Types } from "../../../../../types/graphql";

type ToggleUserRegistrationScorecardProps = {
  userRegistrationDetails: Types.UserRegistrationsById;
};
const ToggleUserRegistrationScorecard = ({
  userRegistrationDetails,
}: ToggleUserRegistrationScorecardProps) => {
  const [scoreCards, setScoreCards] = React.useState<boolean>(
    userRegistrationDetails.userScoreCard,
  );
  const [submitting, setSubmitting] = React.useState<boolean>(false);
  const [toggleRegistrationScoreCard] = useMutation(
    TOGGLE_REGISTRATION_SCORECARD,
    {
      onCompleted: (result) => {
        if (result.toggleRegistrationScoreCard) {
          toast.success(
            `Score Card ${scoreCards ? "shared" : "un-shared"} successfully`,
          );
        }
        setSubmitting(false);
      },
      refetchQueries: ["GET_USER_REGISTRATIONS_BY_IDS"],
    },
  );

  const handleToggleScoreCard = async () => {
    await toggleRegistrationScoreCard({
      variables: {
        user_registrations_id: userRegistrationDetails.user_registrations_id,
        userScoreCard: scoreCards ? 0 : 1,
      },
    });
  };
  return (
    <Form.Switch
      disabled={submitting}
      className="mr-3 float-right"
      name="score_cards"
      label="Share Score Card"
      checked={scoreCards}
      onChange={async (e) => {
        setSubmitting(true);
        setScoreCards(e.target.checked);
        await handleToggleScoreCard();
      }}
    />
  );
};

export default ToggleUserRegistrationScorecard;
