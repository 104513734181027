import { useMutation, useQuery, ApolloError } from '@apollo/client';
import { ADD_EVENT_ORGANIZER } from 'graphql/ADD_EVENT_ORGANIZER';
import { GET_COACHES } from 'graphql/GET_COACHES';
import { GET_EVENT_ORGANIZERS } from 'graphql/GET_EVENT_ORGANIZERS';
import { REMOVE_USER_ROLE } from 'graphql/REMOVE_USER_ROLE';
import {
  GET_COACHES as GET_COACHES_TYPE
} from 'graphql/types/GET_COACHES';
import useGetEventOrganizers from 'modules/user/roles/hooks/useGetEventOrganizers';
import React, { useMemo } from 'react';
import { useParams } from 'react-router';
import Select from 'react-select';
import { toast } from 'react-toastify';
import { Grid, Tag, Text } from 'tabler-react';
import { Types } from '../../../../../types/graphql'

const EventOrganizersSelectForm = () => {
  const { eventId } = useParams<{ eventId: string }>();

  const [addEventOrganizer] = useMutation(ADD_EVENT_ORGANIZER, {
    onCompleted: () => {
      toast.success('Coach added to event');
    },
    onError: (error: ApolloError) => {
      toast.error(error.graphQLErrors[0].message);
    }
  });

  const [removeUserRole] = useMutation(REMOVE_USER_ROLE, {
    onCompleted: () => {
      toast.success('Coach removed from event');
    },
    onError: (error: ApolloError) => {
      toast.error(error.graphQLErrors[0].message);
    }
  });

  const { data: coachesData } = useQuery<GET_COACHES_TYPE>(GET_COACHES, {
    variables: {
      filter: {
        is_active: true
      }
    }
  });

  const { eventOrganizersData } = useGetEventOrganizers({
    eventId: Number(eventId)
  });

  const coachOptions = useMemo(
    () =>
      coachesData?.getCoaches
        // Remove existing organizers from the list of coaches
        .filter(
          (coach) =>
            !eventOrganizersData?.getEventOrganizers.find(
              (organizer) => organizer.coach_id === coach.id
            )
        )
        .map(({ id, first_name, last_name }) => ({
          id,
          name: `${first_name} ${last_name}`
        })) ?? [],
    [coachesData?.getCoaches, eventOrganizersData?.getEventOrganizers]  
  );

  const eventOrganizerRows = useMemo(
    () =>
      eventOrganizersData?.getEventOrganizers.map(
        ({ id, coach_first_name, coach_last_name }) => ({
          id,
          name: `${coach_first_name} ${coach_last_name}`
        })
      ) ?? [],
    [eventOrganizersData?.getEventOrganizers]  
  );

  const onSelectCoach = async (option: Types.Coach) => {
    await addEventOrganizer({
      variables: {
        addEventOrganizerInput: {
          role_id: 16,
          camp_id: Number(eventId),
          coach_id: option.id
        }
      },
      refetchQueries: [
        {
          query: GET_EVENT_ORGANIZERS,
          variables: {
            params: {
              role_id: 16,
              camp_id: Number(eventId)
            }
          }
        }
      ]
    });
  };

  const onRemoveEventOrganizer = async (id: number) => {
    await removeUserRole({
      variables: {
        userRoleId: id
      },
      refetchQueries: [
        {
          query: GET_EVENT_ORGANIZERS,
          variables: {
            params: {
              role_id: 16,
              camp_id: Number(eventId)
            }
          }
        }
      ]
    });
  };

  return (
    <>
      <Grid.Row className="mb-3">
        <Grid.Col>
          <Select
            isClearable={true}
            getOptionLabel={(option) => option.name}
            getOptionValue={(option) => option.id}
            options={coachOptions}
            onChange={onSelectCoach}
            value={null}
            placeholder="Select Organizer..."
            styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
            menuPortalTarget={document.body}
            formatOptionLabel={(option) => (
              <div className="d-flex justify-content-between">
                <span>{option.name}</span>
                <Text muted>ID: {option.id}</Text>
              </div>
            )}
          />
        </Grid.Col>
      </Grid.Row>
      <Grid.Row>
        <Grid.Col>
          <Tag.List>
            {eventOrganizerRows?.map((role) => (
              <Tag
                key={role.id}
                remove
                onClick={async () => {
                  await onRemoveEventOrganizer(role.id);
                }}
              >
                {role.name}
              </Tag>
            ))}
          </Tag.List>
        </Grid.Col>
      </Grid.Row>
    </>
  );
};

export default EventOrganizersSelectForm;
