import React, { useMemo } from 'react';
import { Card, Dropdown, Header, Icon, Tag, Text } from 'tabler-react';
import { useRootStore } from '../../hooks';
import { EUserTypes } from '../User/userTypes';
import moment from 'moment/moment';
import { useMutation } from '@apollo/client';
import { ADD_OR_EDIT_USER_GEAR } from '../../graphql/ADD_OR_EDIT_USER_GEAR';
import { toast } from 'react-toastify';
import { GET_STUDENT_GEARS } from '../../graphql/GET_STUDENT_GEARS';
import { daysBefore180 } from '../User/Forms/UserReserveLogForm';
import { GET_RIGS } from '../../graphql/GET_RIGS';
import { Types } from '../../types/graphql'

interface GearCardParams {
  userGear: Types.UserGear;
  toggleModal: any;
  setUserGear: any;
  weight?: number;
  jumpWeight?: number;
  toggleJobModal: any;
}

const GearCardItem = ({
  userGear,
  toggleModal,
  setUserGear,
  weight,
  jumpWeight,
  toggleJobModal
}: GearCardParams) => {
  const rootStore = useRootStore();
  const { currentUser } = rootStore;
  const [updateUserGear] = useMutation(ADD_OR_EDIT_USER_GEAR, {
    onCompleted: () => {
      toast.success('Gear updated.');
    }
  });

  const refetch = [
    {
      query: GET_STUDENT_GEARS,
      variables: {
        getUserGearOptions: { student_id: userGear.student_id }
      }
    }
  ];

  const optionsRigger = [];
  if (currentUser?.type === EUserTypes.rigger)
    optionsRigger.push({
      value: 'New Job',
      onClick: () => toggleJobModal(userGear.id)
    });

  const optionsStudent = [];
  if (currentUser?.type === EUserTypes.student)
    optionsStudent.push(
      {
        value: (
          <span className="text-muted">
            {userGear.status === 'Lost/Missing' ? 'Not ' : 'Report '}
            Lost/Missing
          </span>
        ),
        onClick: () => {
          if (userGear.status === 'Lost/Missing') updateGearStatus('Active');
          else updateGearStatus('Lost/Missing');
        }
      },
      {
        value: (
          <span className="text-muted">
            {userGear.status === 'Stolen' ? 'Not ' : 'Report '}Stolen
          </span>
        ),
        onClick: () => {
          if (userGear.status === 'Stolen') updateGearStatus('Active');
          else updateGearStatus('Stolen');
        }
      },
      {
        value: (
          <span className="text-muted">
            {userGear.status === 'Selling' ? 'Not ' : ''}For Sale
          </span>
        ),
        onClick: () => {
          if (userGear.status === 'Selling') updateGearStatus('Active');
          else updateGearStatus('Selling');
        }
      }
    );

  const user_weight = weight;

  const removeGear = async () => {
    if (!window.confirm('Are you sure you want to remove this gear?'))
      return false;

    await updateUserGear({
      variables: {
        updateUserGearInput: {
          id: userGear.id,
          name: '',
          student_id: null,
          gear_notes: ''
        }
      },
      refetchQueries: [
        ...refetch,
        {
          query: GET_RIGS,
          variables: {
            rigListFilter: {
              student_id: userGear.student_id
            }
          }
        }
      ]
    });
  };

  const updateGearStatus = async (status) => {
    if (!window.confirm('Update gear status to: ' + status)) return;
    await updateUserGear({
      variables: {
        updateUserGearInput: {
          id: userGear.id,
          status: status
        }
      },
      refetchQueries: refetch
    });
  };

  const statusClass = useMemo(() => {
    return userGear.status === 'Active' ? 'success' : 'danger';
  }, [userGear.status]);

  const isReserveExpired = useMemo(() => {
    if (!userGear?.repack) return false;
    return daysBefore180(userGear?.repack.event_date) < 0;
  }, [userGear]);
  return (
    <Card>
      <Card.Body>
        <Dropdown
          className="float-right cursor-pointer"
          toggle={false}
          position="bottom-end"
          arrow={false}
          arrowPosition="right"
          triggerContent={<Icon name="more-vertical" />}
          itemsObject={[
            {
              value: 'Edit',
              onClick: () => {
                setUserGear(userGear);
                toggleModal(true);
              }
            },
            {
              value: 'Remove',
              onClick: () => {
                removeGear();
              }
            },
            ...optionsRigger,
            ...optionsStudent
          ]}
        />
        <Text muted>
          {userGear?.gear_model?.gear_type_name}
          {user_weight > 0 && userGear?.gear_canopy_size > 0 && (
            <span className="text-success">
              {' '}
              {(user_weight / userGear?.gear_canopy_size).toFixed(2)}{' '}
            </span>
          )}
        </Text>
        <Header.H4>
          <span className="text-muted">{userGear?.name}</span>{' '}
          {userGear?.gear_model?.name}{' '}
          {userGear?.gear_canopy_size > 0 && userGear.gear_canopy_size}
        </Header.H4>
        <Text.Small className={`text-black`}>
          <strong>{userGear?.gear_model?.gear_manufacturer_name}</strong>
          {userGear?.gear_model?.gear_manufacturer_name ? (
            ''
          ) : (
            <span className="text-muted">Who makes this?</span>
          )}
        </Text.Small>
        {userGear.gear_serial && (
          <Text.Small className={`d-block`}>
            <span className={`text-muted`}>S/N</span> {userGear?.gear_serial}
          </Text.Small>
        )}
        {userGear.gear_dom && (
          <Text.Small className={`d-block text-uppercase`}>
            <span className={`text-muted`}>D.O.M.</span>{' '}
            {moment(userGear.gear_dom).format('DD MMM YYYY')}
          </Text.Small>
        )}
        {userGear.repack && (
          <Text.Small className={`d-block text-uppercase`}>
            <span className={`text-muted`}>Repack</span>{' '}
            <span className={`${isReserveExpired && 'd-block text-danger'}`}>
              {moment(userGear.repack.event_date).format('DD MMM YYYY')}
              {userGear.repack &&
              daysBefore180(userGear.repack?.event_date) > 0 ? (
                ' (' + daysBefore180(userGear.repack?.event_date) + ' days)'
              ) : (
                <span className="text-danger">
                  {userGear.repack
                    ? ' (' +
                      daysBefore180(userGear.repack?.event_date) +
                      ' days)'
                    : 'Unknown'}
                </span>
              )}
            </span>
          </Text.Small>
        )}
        <Text className="d-block">
          {userGear?.gear_model?.gear_type_id !== 3 &&
            userGear?.gear_model?.gear_type_id < 6 && (
              <Tag
                color={userGear?.rig?.name ? 'info' : 'secondary'}
                className="mt-2 mr-2"
              >
                {userGear?.rig?.name ? userGear?.rig?.name : 'No Rig'}
              </Tag>
            )}
          <Tag className={`mt-2 ${statusClass}`}>
            {userGear.status !== 'Active' && userGear.status}
          </Tag>
        </Text>
      </Card.Body>
    </Card>
  );
};

export default GearCardItem;
