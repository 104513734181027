import { observer } from 'mobx-react';
import React from 'react';
import { Container } from 'tabler-react';

import ProductListItemEntry from '../../../components/Product/Forms/ProductListItemEntry';
import { ProductStoreProvider } from '../../../contexts/productContext';
import Wrapper from '../../layout';

const NewProductPage = (props) => {
  return (
    <Wrapper {...props} title="Products">
      <Container>
        <ProductStoreProvider>
          <ProductListItemEntry />
        </ProductStoreProvider>
      </Container>
    </Wrapper>
  );
};

export default observer(NewProductPage);
