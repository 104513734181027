import { useMutation, useQuery } from '@apollo/client';
import React from 'react';
import { Button } from 'tabler-react';
import { ADD_OR_REMOVE_COACH_STUDENT } from '../../../graphql/ADD_OR_REMOVE_COACH_STUDENT';
import { GET_COACH_STUDENT } from '../../../graphql/GET_COACH_STUDENT';

interface ICoachStudentToggleProps {
  coach_id: number;
  student_id: number;
}

const CoachStudentToggle = ({
  coach_id,
  student_id
}: ICoachStudentToggleProps) => {
  const [addOrRemoveCoachStudent] = useMutation(ADD_OR_REMOVE_COACH_STUDENT);
  const { data } = useQuery(GET_COACH_STUDENT, {
    variables: {
      coach_id: coach_id,
      student_id: student_id
    }
  });

  const handleToggleCoachStudent = () => {
    addOrRemoveCoachStudent({
      variables: {
        coach_id: coach_id,
        student_id: student_id
      },
      refetchQueries: [
        {
          query: GET_COACH_STUDENT,
          variables: {
            coach_id: coach_id,
            student_id: student_id
          }
        }
      ]
    });
  };
  return (
    <Button
      className={`${data?.getCoachStudent ? 'text-primary' : 'text-muted'}`}
      icon="star"
      color="white"
      size="sm"
      onClick={() => handleToggleCoachStudent()}
    />
  );
};

export default CoachStudentToggle;
