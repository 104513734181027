import gql from 'graphql-tag'

export const GET_REGISTERED_STUDENTS_COUNT = gql`
  query GET_REGISTERED_STUDENTS_COUNT($campId: Int!) {
    getRegisteredStudentsCount(campId: $campId) {
      id
      name
      type
      total
      color
    }
  }
`
