import gql from 'graphql-tag';

export const GET_COACHES = gql`
  query GET_COACHES($limit: Int, $order: String, $filter: GetCoachesFilter) {
    getCoaches(limit: $limit, order: $order, filter: $filter) {
      id
      first_name
      last_name
      email
      phone_number
      phone_country_code
      is_disabled_on
      registration_id
    }
  }
`;
