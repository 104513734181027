import { cast, Instance, SnapshotIn, types } from 'mobx-state-tree';

const Transaction = types
  .model({
    id: types.optional(types.string, '', [null]),
    created_on: types.optional(types.string, '', [null]),
    minutes: types.optional(types.number, 0, [null]),
    notes: types.optional(types.string, '', [null]),
    teamName: types.optional(types.string, '', [null]),
    teamId: types.optional(types.string, '', [null, undefined]),
    transactionTypeId: types.optional(types.number, 0, [null]),
    transactionTypeName: types.optional(types.string, '', [null]),
    transactionTypeSlug: types.optional(types.string, '', [null]),
    accountTypeSlug: types.optional(types.string, '', [null]),
    studentId: types.optional(types.string, '', [null]),
    studentFirstName: types.optional(types.string, '', [null]),
    studentLastName: types.optional(types.string, '', [null]),
    studentProfileAvatar: types.optional(types.string, '', [null]),
    coachFirstName: types.optional(types.string, '', [null]),
    coachLastName: types.optional(types.string, '', [null]),
    timeAdded: types.optional(types.number, 0, [null])
  })
  .actions((self) => ({
    set<
      K extends keyof SnapshotIn<typeof self>,
      T extends SnapshotIn<typeof self>
    >(key: K, value: T) {
      self[key] = cast(value);
    }
  }));

export const TransactionStoreDefault = () => {
  return TransactionStore.create({
    transactions: undefined
  });
};

export const TransactionStore = types
  .model({
    transactions: types.optional(types.array(Transaction), [])
  })
  .views((self) => ({}))
  .actions((self) => ({}));

export interface TransactionStoreType
  extends Instance<typeof TransactionStore> {
}
