import React, { useState } from 'react';
import moment from 'moment';
import { formatMoney } from '../../utils/numberFormat';
import { Button, Card, Tag, Text } from 'tabler-react';
import InvoiceDetailsModal from 'components/Invoices/Modals/InvoiceDetailsModal';
import { Types } from '../../types/graphql'

interface JobGearStorageItemGearProps {
  job: Types.JobContainer;
  handleRemoveItem: (jobId: number) => void;
}

const linkStyle = {
  background: 'none',
  border: 'none',
  padding: '0',
  font: 'inherit',
  cursor: 'pointer',
  textDecoration: 'none'
};

const JobGearStorageItemGear = ({
  job,
  handleRemoveItem
}: JobGearStorageItemGearProps) => {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <Card className="mb-2" key={job.id}>
      <Card.Status
        color={
          job.status === 'Hold'
            ? 'teal'
            : job.status === 'Closed' || job?.invoice?.status === 'Paid'
            ? 'success'
            : moment().diff(moment(job?.completed_on), 'days') > 30
            ? 'danger'
            : job?.invoice?.status === 'Pending'
            ? ''
            : 'secondary'
        }
        side
      />
      <Card.Body>
        <Text className="d-block">
          <Text.Small className="mr-4 text-muted">
            <strong>
              {job?.student?.first_name} {job?.student?.last_name}
            </strong>
            {job.user_gear_id && (
              <>
                <i className="fe fe-shopping-bag mr-1 ml-1" />
                <span className="text-muted">
                  {job?.user_gear?.gear_model_name}{' '}
                  {job?.user_gear?.gear_canopy_size ? job?.user_gear?.gear_canopy_size : ''}
                </span>
                <span className="ml-1">{job?.user_gear?.gear_serial}</span>
                <Tag color="info" className="ml-1">
                  {job?.user_gear?.rig_name}
                </Tag>
              </>
            )}
          </Text.Small>
        </Text>
        <Text.Small className="mr-2 text-muted">
          <i className="fe fe-calendar mr-1" />
          Intake: <strong>{moment(job.created_on).format('MMM D')}</strong>
          {job.status !== 'Invoice' && job.status !== 'Closed' && (
            <>
              <Tag className="ml-2">{job.status}</Tag>
            </>
          )}
        </Text.Small>
        {job?.completed_on && (
          <Text.Small className="text-muted">
            Completed:
            <span className="">
              <strong
                className={`${
                  moment().diff(moment(job?.completed_on), 'days') > 30
                    ? 'text-danger'
                    : ''
                }`}
              >
                {' '}
                {moment().diff(moment(job?.completed_on), 'days')} days ago
              </strong>
            </span>
          </Text.Small>
        )}
        <hr className="mt-3 mb-3" />
        <Text className="float-right">
          <Tag
            color={job?.invoice?.status === 'Paid' ? 'success' : ''}
            className="ml-2"
          >
            {job?.invoice?.status}
          </Tag>
          <Button
            icon="log-out"
            size="sm"
            color="white"
            className="text-muted"
            onClick={() => handleRemoveItem(job.id)}
          />
        </Text>
        <Text className="text-muted">
          {job?.invoice?.id && (
            <button
              style={linkStyle}
              //href={`/dzm/invoice/${job?.invoice?.id}`}
              className='text-primary'
              onClick={(e) => {
                e.preventDefault();
                setIsOpen(true);
              }}
            >
              <i className="fe fe-file mr-1" /> Inv:{' '}
              {job?.invoice?.id}
              <span className="text-success ml-2 text-decoration-none">
                {formatMoney(job?.invoice?.invoice_amount)}
              </span>
            </button>
          )}
        </Text>
      </Card.Body>
      {job?.invoice?.id && (
        <InvoiceDetailsModal
          isOpen={isOpen}
          invoiceId={job.invoice.id}
          closeModal={setIsOpen}
          key={job.invoice.id}
        />
      )}
    </Card>
  );
};

export default JobGearStorageItemGear;
