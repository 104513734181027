import gql from 'graphql-tag'

export const GET_REGISTRATION_STATUS_CONTENT = gql`
  query GET_REGISTRATION_STATUS_CONTENT($campId: Int!) {
    registrationStatusContents(campId: $campId) {
      id
      registration_status_id
      registration_status_information_id
      slug
      message
    }
  }
`
