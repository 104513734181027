import gql from 'graphql-tag';

export const GET_STUDENTS_ON_DASHBOARD = gql`
  query GET_STUDENTS_ON_DASHBOARD($team_id: ID!) {
    studentsOnDashboard(teamId: $team_id) {
      id
      load {
        load_name
        date
        load_departure
      }
      studentDetails {
        id
        address1
        address2
        aid
        balance
        camp_id
        camp_slot_user_status
        city
        country
        created_by
        created_on
        dob
        email
        email_verified
        facebook_account_id
        first_name
        flag_type_id
        follower_flag_type
        group_id
        height
        is_camp_registered
        is_disabled_on
        is_followed
        is_follower
        is_on_dashboard
        is_paid
        jump_weight
        last_name
        middle_name
        note
        notification_type_id
        option_name
        phone_country_code
        phone_number
        phone_verified
        postal_code
        preferred_name
        preference_24h
        preference_units
        profile_picture
        province
        registered
        registration_code
        registration_id
        role
        status
        team_id
        total
        total_time
        updated_by
        updated_on
        user_duration
        weight
        shirt_size_id

        auth_merits {
          member_id
        }

        uspa {
          first_name
          last_name
          email
          updated_on
          merits_uspa_member_id
          merits_uspa_member_expiration
          template_id
          template_title
        }
      }
      sequence
      jumpType {
        id
        slug
        name
        seq
      }
      associationType {
        id
        name
      }
    }
  }
`;
