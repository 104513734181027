import gql from "graphql-tag";

export const GET_STUDENTS_BY_FILTER_QUERY = gql`
  query GET_STUDENTS_BY_FILTER_QUERY(
    $order: OrderOptions
    $filter: StudentFilter
  ) {
    students(order: $order, filter: $filter) {
      id
      address1
      address2
      city
      country
      current_balance
      dob
      email
      first_name
      height
      is_disabled_on
      jump_weight
      last_event
      last_jump
      last_name
      last_tunnel_session
      middle_name
      phone_country_code
      phone_number
      postal_code
      preference_24h
      preference_units
      preferred_name
      profile_avatar
      profile_picture
      province
      registration_id
      token
      total
      weight

      auth_merits {
        member_id
      }

      uspa {
        first_name
        last_name
        email
        updated_on
        merits_uspa_member_id
        merits_uspa_member_expiration
        template_id
        template_title
      }

      teams {
        id
        name
        default_account_type_id
        default_location_id
        default_location_name
        feature_accounting
        feature_alpha
        feature_app_reports
        feature_booking_calendar
        feature_camps
        feature_cancel_transactions
        feature_coach_account
        feature_dashboard
        feature_dashboard_groups
        feature_disable_students
        feature_dzm_account
        feature_edit_transactions
        feature_locked_account_actions
        feature_manifest
        feature_open_add_students
        feature_paid_status
        feature_recurring_transactions
        feature_registrations
        feature_require_uspa
        feature_rigger_account
        feature_schedule_open
        feature_slot_registrations
        feature_student_progression
        feature_student_checkin
        feature_transfer_time
        is_public
        is_published
        preference_units
        team_bio
        team_coach_hour_fee
        team_email
        team_phone
        team_url
      }
    }
    studentsCount(filter: $filter) {
      total
    }
  }
`;
