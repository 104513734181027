import React from 'react';
import { Formik } from 'formik';
import FormField from '../../FormField';
import { Button, Form, Grid } from 'tabler-react';
import { ADD_AIRCRAFT } from '../../../graphql/ADD_AIRCRAFT';
import { useMutation } from '@apollo/client';
import { toast } from 'react-toastify';
import { ADD_TEAM_AIRCRAFT } from '../../../graphql/ADD_TEAM_AIRCRAFT';
import { UPDATE_AIRCRAFT } from '../../../graphql/UPDATE_AIRCRAFT';

interface AddAircraftFormProps {
  aircraft?: any;
  closeModal?: () => void;
  teamId?: number;
}

const AddAircraftForm = ({
  aircraft,
  closeModal,
  teamId
}: AddAircraftFormProps) => {
  const [addAircraft] = useMutation(ADD_AIRCRAFT, {
    onCompleted: (data) => {
      //IF DZM - create team_aircraft entry
      toast.success('Aircraft added successfully');
      if (closeModal) {
        closeModal()
      }
    },
    refetchQueries: ['GET_AIRCRAFTS_BY_TEAM_ID', 'GET_AIRCRAFTS']
  });

  const [addTeamAircraft] = useMutation(ADD_TEAM_AIRCRAFT, {
    onCompleted: (data) => {
      toast.success('Aircraft added successfully');
      if (closeModal) {
        closeModal()
      }
    },
    refetchQueries: ['GET_AIRCRAFTS_BY_TEAM_ID', 'GET_AIRCRAFTS']
  });

  const [updateAircraft] = useMutation(UPDATE_AIRCRAFT, {
    onCompleted: (data) => {
      toast.success('Aircraft updated successfully');
      if (closeModal) {
        closeModal()
      }
    },
    refetchQueries: ['GET_AIRCRAFTS_BY_TEAM_ID', 'GET_AIRCRAFTS']
  });
  const renderForm = ({ values, handleChange, handleSubmit }) => {
    return (
      <>
        <Grid.Row>
          <Grid.Col>
            <FormField
              name={'name'}
              label={'Name'}
              value={values.name}
              onChange={handleChange}
            />
          </Grid.Col>
          <Grid.Col>
            <FormField
              name={'tail_number'}
              label={'Tail Number'}
              value={values.tail_number}
              onChange={handleChange}
            />
          </Grid.Col>
        </Grid.Row>
        <Grid.Row>
          <Grid.Col>
            <FormField
              name={'aircraft_type'}
              label={'Type'}
              value={values.aircraft_type}
              onChange={handleChange}
            />
          </Grid.Col>
          <Grid.Col>
            <FormField
              name={'capacity_min'}
              label={'Min Capacity'}
              type={'number'}
              value={values.capacity_min}
              onChange={handleChange}
            />
          </Grid.Col>
          <Grid.Col>
            <FormField
              name={'capacity_max'}
              label={'Max Capacity'}
              type={'number'}
              value={values.capacity_max}
              onChange={handleChange}
            />
          </Grid.Col>
        </Grid.Row>
        <Grid.Row>
          <Grid.Col>
            <Form.Textarea
              name={'aircraft_notes'}
              label={'Aircraft Notes'}
              value={values.aircraft_notes}
              onChange={handleChange}
            />
          </Grid.Col>
        </Grid.Row>
        <Grid.Row>
          <Grid.Col>
            <Button
              color="gray-dark"
              onClick={handleSubmit}
              className={'float-right'}
            >
              SAVE
            </Button>
          </Grid.Col>
        </Grid.Row>
      </>
    );
  };
  return (
    <Formik
      enableReinitialize={true}
      initialValues={{
        name: aircraft?.name ?? '',
        tail_number: aircraft?.tail_number ?? '',
        aircraft_type: aircraft?.aircraft_type ?? '',
        capacity_min: aircraft?.capacity_min ?? '',
        capacity_max: aircraft?.capacity_max ?? '',
        aircraft_notes: aircraft?.aircraft_notes ?? ''
      }}
      onSubmit={(values, { setSubmitting, resetForm }) => {
        if (aircraft?.id) {
          updateAircraft({
            variables: { aircraft: { id: aircraft.id, ...values } }
          });
        } else {
          if (teamId) {
            addTeamAircraft({
              variables: {
                newTeamAircraft: {
                  teamId,
                  name: values.name,
                  tailNumber: values.tail_number,
                  type: values.aircraft_type,
                  notes: values.aircraft_notes,
                  capacityMax: values.capacity_max,
                  capacityMin: values.capacity_min
                }
              }
            });
          } else {
            addAircraft({ variables: { aircraft: values } });
          }
        }

        setSubmitting(false);
        resetForm();
      }}
    >
      {(formData) => renderForm(formData)}
    </Formik>
  );
};

export default AddAircraftForm;
