import { EUserTypes } from 'components/User/userTypes';
import { useRootStore } from 'hooks';
import { observer } from 'mobx-react';
import useIsFeatureAccessible from 'modules/common/hooks/useIsFeatureAccessible';
import React, { FC } from 'react';

interface FeatureAccessGateProps {
  feature: string;
}

const FeatureAccessGate: FC<FeatureAccessGateProps> = ({
  children,
  feature
}) => {
  const { currentUser, currentCoachTeam } = useRootStore();

  const { isFeatureAccessible } = useIsFeatureAccessible({
    currentCoachTeam,
    currentUserType: currentUser?.type as EUserTypes,
    feature
  });

  if (!isFeatureAccessible) {
    return null;
  }

  return <>{children}</>;
};

export default observer(FeatureAccessGate);
