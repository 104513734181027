import React, { useEffect, useState } from "react";
import { Card, List } from "tabler-react";
import { Types } from "../../types/graphql";
import StudentTeamProgressionItem from "./StudentTeamProgressionItem";
import useGetCurrentUserType from 'modules/common/hooks/useGetCurrentUserType'

interface IStudentTeamProgressionsHeadingProps {
  heading: string;
  studentProgressHeading: Types.StudentTeamProgression;
  studentId: number;
  campId?: number;
}

export const groupBySubHeader = (data) => {
  return data.reduce(function (storage, item) {
    const key = item?.subheading;
    storage[key] = storage[key] || [];
    storage[key].push(item);
    return storage;
  }, {});
};

const StudentTeamProgressionsHeading = ({
  heading,
  studentProgressHeading,
  studentId,
  campId,
}: IStudentTeamProgressionsHeadingProps) => {
  const [studentTeamProgressions, setStudentTeamProgressions] = useState([]);
  const { isStudent } = useGetCurrentUserType();
  useEffect(() => {
    setStudentTeamProgressions(groupBySubHeader(studentProgressHeading));
  }, [studentProgressHeading]);
  return (
    <Card className={"mb-0 border-0"}>
      <Card.Header className="bg-light">
        <Card.Title>{heading}</Card.Title>
      </Card.Header>
      {!isStudent &&
      <Card.Body>
        {Object.keys(studentTeamProgressions)
          .sort()
          .map((subHeading) => {
            return (
              <List.Group key={subHeading} className="mb-5">
                <List.GroupItem action active>
                  {subHeading}
                </List.GroupItem>
                {studentTeamProgressions[subHeading]
                  .sort((a, b) => a.title > b.title)
                  .map((progress) => (
                    <StudentTeamProgressionItem
                      campId={campId}
                      key={progress.id}
                      progress={progress}
                      studentId={studentId}
                    />
                  ))}
              </List.Group>
            );
          })}
      </Card.Body>
      }
    </Card>
  );
};

export default StudentTeamProgressionsHeading;
