export enum ELocationOrder {
  distance = 'distance',
  name = 'name'
}

export enum ELocationType {
  all = 'all',
  base = 'base',
  dropzone = 'dropzone',
  speedfly = 'speedfly',
  tunnel = 'tunnel'
}

export const DEFAULT_LIMIT = 50;
export const DEFAULT_PAGE = 1;
