import gql from 'graphql-tag';

export const GET_USER_LOCATIONS = gql`
  query GET_USER_LOCATIONS(
    $userLocationFilter: UserLocationFilter
  ) {
    getUserLocations(userLocationFilter: $userLocationFilter) {
      id
      student_id
      flag_type_id
      user_location_id
      name
    }
  }
`;
