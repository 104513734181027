import FormField from '../FormField';
import { NumericFormat } from 'react-number-format';
import React from 'react';
import { GET_JOB_LIST } from '../../graphql/GET_JOB_LIST';
import { GET_INVOICE } from '../../graphql/GET_INVOICE'
import { toast } from 'react-toastify';
import { Formik } from 'formik';
import { useMutation } from '@apollo/client';
import { UPDATE_JOB } from '../../graphql/UPDATE_JOB';
import { Types } from '../../types/graphql'

interface IInvoiceEditJobQuantityProps {
  job: any;
  invoice: Types.Invoice;
  setIsEditting: any;
}

const InvoiceEditJobQuantity = ({ job, invoice, setIsEditting }: IInvoiceEditJobQuantityProps) => {
  const invoiceId = invoice.id
  const [updateJob] = useMutation(UPDATE_JOB, {});


  return (<Formik
    enableReinitialize={true}
    initialValues={{
      id: job?.id ?? null,
      quantity: job?.quantity ?? 1

    }}
    onSubmit={async (values, { resetForm, setSubmitting }) => {

      await updateJob({
        variables: {
          updateJobInput: {
            id: values.id,
            quantity: values.quantity
          }
        },
        refetchQueries: [
          {
            query: GET_JOB_LIST,
            variables: {
              jobListFilter: {
                invoice_id: invoiceId
              }
            }
          },
          {
            query: GET_INVOICE,
            variables: {
              id: invoiceId
            }
          },
        ]
      });
      setSubmitting(false);
      resetForm();
      setIsEditting(false);
      toast.success("Job saved!");
    }}
  >
    {({ values, setFieldValue, submitForm }) =>
      <NumericFormat
        autoFocus
        className={'w-7 m-auto d-inline-flex'}
        customInput={FormField}
        name='quantity'
        placeholder='Qty'
        additionalgroupclasses={['mb-0']}
        value={values.quantity}
        decimalScale={2}
        allowLeadingZeros={false}
        allowNegative={false}
        onBlur={submitForm}
        onKeyUp={(event) => {
          switch (event.keyCode) {
            case 13:
              submitForm();
              break;
            case 27:
              setIsEditting(false);
              break;

          }
        }
        }
        onChange={(e) => setFieldValue('quantity', parseFloat(e.target.value))} />
    }
  </Formik>);
};

export default InvoiceEditJobQuantity;
