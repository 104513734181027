import Manifest from "components/manifest/manifest";
import React from "react";
import { LoadStoreProvider } from "../../contexts/LoadStoreContext";

const DZMHome = () => {
  return (
    <div className="container">
      <LoadStoreProvider>
        <Manifest />
      </LoadStoreProvider>
    </div>
  );
};

export default DZMHome;
