import { useMutation, useQuery } from '@apollo/client';
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { Button } from 'tabler-react';

import { ADD_USER_EVENT } from '../../graphql/ADD_USER_EVENT';
import { GET_LATEST_USER_EVENT_BY_TYPE_IDS } from '../../graphql/GET_LATEST_USER_EVENT_BY_TYPE_IDS';
import { EUserEventsId } from './UserEvents';

enum EUserEventStatus {
  checkedIn = 'Checked In',
  checkedOut = 'Checked Out'
}

interface IUserDZMCheckInAndOutProps {
  currentCoachTeam: any;
  student: any;
}

const UserDZMCheckInAndOut = ({
  currentCoachTeam,
  student
}: IUserDZMCheckInAndOutProps) => {
  const [checkedInDisabled, setCheckedInDisabled] = useState<boolean>(false);

  const [addUserEvent] = useMutation(ADD_USER_EVENT);

  const variables = {
    student_id: parseInt(student.id),
    team_id: parseInt(currentCoachTeam.id),
    user_event_type_ids: [EUserEventsId.checkin_dz, EUserEventsId.checkout_dz]
  };

  const { loading, error, data } = useQuery(GET_LATEST_USER_EVENT_BY_TYPE_IDS, {
    variables
  });

  useEffect(() => {
    if (!loading && data) {
      setCheckedInDisabled(
        Number(data.getLatestUserEventByTypeIds?.user_event_type_id) ===
        EUserEventsId.checkin_dz
      );
    }
  }, [data]);  

  if (error) {
    return <p>Error: {error.message}</p>;
  }

  const handleUserEventStatus = async (status: EUserEventStatus) => {
    await addUserEvent({
      variables: {
        userEvent: {
          status,
          user_event_type_id:
            status === EUserEventStatus.checkedIn
              ? EUserEventsId.checkin_dz
              : EUserEventsId.checkout_dz,
          location_id: currentCoachTeam.default_location_id,
          team_id: currentCoachTeam.id,
          student_id: student.id
        }
      },
      refetchQueries: [
        {
          query: GET_LATEST_USER_EVENT_BY_TYPE_IDS,
          variables
        }
      ]
    });

    setCheckedInDisabled((prev) => !prev);

    toast.success(`User ${status}`);
  };

  if (currentCoachTeam?.default_location_id === 0) {
    return (
      <>
        <Button color="red" size="sm" disabled>
          No location
        </Button>
      </>
    );
  }

  return (
    <>
      {checkedInDisabled ?
        <Button
          color="blue"
          size="sm"
          icon="log-out"
          onClick={(e) => {
            e.preventDefault();
            handleUserEventStatus(EUserEventStatus.checkedOut);
          }}
        >
          Checkout
        </Button>
        :
        <Button
          color="blue"
          size="sm"
          icon="log-in"
          onClick={(e) => {
            e.preventDefault();
            handleUserEventStatus(EUserEventStatus.checkedIn);
          }}
        >
          Checkin
        </Button>
        }


    </>
  );
};

export default UserDZMCheckInAndOut;
