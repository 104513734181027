import gql from "graphql-tag";

export const GET_STUDENTS_BY_ROLE = gql`
  query GET_STUDENTS_BY_ROLE($roleId: ID!, $teamId: ID!) {
    getStudentsByRole(roleId: $roleId, teamId: $teamId) {
      id
      first_name
      last_name
      email
      phone_number
      phone_country_code
    }
  }
`;
