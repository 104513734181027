import React from 'react';
import { Container } from 'tabler-react';

import StudentRegistrationList from '../../components/Students/StudentRegistrationList';
import Wrapper from '../layout';
import { EventStoreProvider } from '../../contexts/eventStoreContext';

const UserRegistrationPage = (props) => {
  return (
    <Wrapper {...props} title="User Registrations">
      <Container>
        <EventStoreProvider>
          <StudentRegistrationList />
        </EventStoreProvider>
      </Container>
    </Wrapper>
  );
};
export default UserRegistrationPage;
