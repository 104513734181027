import { useMutation, useQuery } from "@apollo/client";
import Modal from "components/Modal";
import StudentLink from "components/Students/StudentLink";
import AccountBalance from "components/User/AccountBalance";
import { EUserTypes } from "components/User/userTypes";
import { Formik } from "formik";
import { ACCOUNT_BALANCE_TRANSACTION } from "graphql/ACCOUNT_BALANCE_TRANSACTION";
import { GET_ACCOUNT_BALANCE } from "graphql/GET_ACCOUNT_BALANCE";
import { GET_ACCOUNT_TYPE_BY_TEAM_ID } from "graphql/GET_ACCOUNT_TYPE_BY_TEAM_ID";
import { GET_STUDENTS_ON_DASHBOARD } from "graphql/GET_STUDENTS_ON_DASHBOARD";
import { REMOVE_STUDENT_FROM_DASHBOARD } from "graphql/REMOVE_STUDENT_FROM_DASHBOARD";
import { GET_ACCOUNT_BALANCE as GET_ACCOUNT_BALANCE_TYPE } from "graphql/types/GET_ACCOUNT_BALANCE";
import { GET_ACCOUNT_TYPE_BY_TEAM_ID as GET_ACCOUNT_TYPE_BY_TEAM_ID_TYPE } from "graphql/types/GET_ACCOUNT_TYPE_BY_TEAM_ID";
import { useRootStore } from "hooks";
import { observer } from "mobx-react";
import React, { useEffect, useMemo, useState } from "react";
import { toast } from "react-toastify";
import {
  Button,
  Card,
  Dropdown,
  Form,
  Grid,
  Header,
  Icon,
  Progress,
  Text,
} from "tabler-react";
import * as Yup from "yup";
import { GET_PAYMENT_FEE_BY_TEAM_ID } from "../../graphql/GET_PAYMENT_FEE_BY_TEAM_ID";
import { GET_PRODUCTS_BY_FILTER_QUERY } from "../../graphql/GET_PRODUCTS_BY_FILTER_QUERY";
import { GET_STRIPE_ACCOUNT_ID_BY_TEAM_ID } from "../../graphql/GET_STRIPE_ACCOUNT_ID_BY_TEAM_ID";
import { GET_STUDENT_TRANSACTIONS_BY_DATE } from "../../graphql/GET_STUDENT_TRANSACTIONS_BY_DATE";
import { GET_PAYMENT_FEE_BY_TEAM_ID as GET_PAYMENT_FEE_BY_TEAM_ID_TYPE } from "../../graphql/types/GET_PAYMENT_FEE_BY_TEAM_ID";
import { GET_PRODUCTS_BY_FILTER_QUERY as GET_PRODUCTS_BY_FILTER_QUERY_TYPE } from "../../graphql/types/GET_PRODUCTS_BY_FILTER_QUERY";
import { GET_STRIPE_ACCOUNT_ID_BY_TEAM_ID as GET_STRIPE_ACCOUNT_ID_BY_TEAM_ID_TYPE } from "../../graphql/types/GET_STRIPE_ACCOUNT_ID_BY_TEAM_ID";
import { EOrderOptions } from "../../hooks/useAutocomplete";
import useGetCurrentUserType from "../../modules/common/hooks/useGetCurrentUserType";
import { toHoursAndMinutes } from "../../utils/convertToTime";
import { DEFAULT_PRODUCT_LIMIT } from "../constants";
import { Loading } from "../Loading";
import StripeAccountBalanceModal from "../Stripe/account-balance/StripeAccountBalanceModal";

interface IOnDeckListItemProps {
  onDeck: any;
  isEditable?: boolean;
  studentCompletedLoads?: any;
}

const OnDeckListItem = ({
  onDeck,
  isEditable,
  studentCompletedLoads,
}: IOnDeckListItemProps) => {
  const { currentUser, currentCoachTeam } = useRootStore();
  const { isCoachSuperAdmin } = useGetCurrentUserType();
  const [isCustomInputOpen, setIsCustomInputOpen] = useState(false);
  const [isAccountBalanceModalOpen, setIsAccountBalanceModalOpen] =
    useState(false);

  const toggleCustomInput = () => {
    setIsCustomInputOpen(!isCustomInputOpen);
  };

  const { data: stripeAccountData, loading: loading2 } =
    useQuery<GET_STRIPE_ACCOUNT_ID_BY_TEAM_ID_TYPE>(
      GET_STRIPE_ACCOUNT_ID_BY_TEAM_ID,
      {
        variables: {
          teamId: currentCoachTeam.id,
        },
      },
    );

  const { data: accountBalance, refetch: refetchAccountBalance } =
    useQuery<GET_ACCOUNT_BALANCE_TYPE>(GET_ACCOUNT_BALANCE, {
      variables: {
        studentId: onDeck.studentDetails.id,
        teamId: currentCoachTeam.id,
      },
    });

  const { data: productsData, loading: loadingProducts } =
    useQuery<GET_PRODUCTS_BY_FILTER_QUERY_TYPE>(GET_PRODUCTS_BY_FILTER_QUERY, {
      variables: {
        order: EOrderOptions.alphabet,
        filter: {
          team_id: currentCoachTeam.id,
          limit: DEFAULT_PRODUCT_LIMIT,
          offset: 0,
          status: "account",
        },
      },
    });

  const products = useMemo(() => {
    if (!productsData) {
      return [];
    }

    return productsData.products.map(
      ({ id, title, amount, quantity, currency }) => {
        return {
          value: id,
          label: title,
          id,
          title,
          amount,
          quantity,
          currency,
        };
      },
    );
  }, [productsData]);

  const { data: accountTypeData, loading: accountTypeLoading } =
    useQuery<GET_ACCOUNT_TYPE_BY_TEAM_ID_TYPE>(GET_ACCOUNT_TYPE_BY_TEAM_ID, {
      variables: {
        teamId: currentCoachTeam.id,
      },
    });

  const { data: paymentFee } = useQuery<GET_PAYMENT_FEE_BY_TEAM_ID_TYPE>(
    GET_PAYMENT_FEE_BY_TEAM_ID,
    {
      variables: {
        teamId: currentCoachTeam.id,
      },
    },
  );

  const stripeAccountId =
    stripeAccountData?.getStripeAccountIdByTeamId?.stripe_account_id;
  const refetch = () => {
    refetchAccountBalance();
  };

  const [accountBalanceTransaction] = useMutation(ACCOUNT_BALANCE_TRANSACTION, {
    onCompleted: () => toast.success("Time removed from account."),
  });

  const [removeStudentFromDashboard] = useMutation(
    REMOVE_STUDENT_FROM_DASHBOARD,
    {
      onCompleted: () => toast.success("User removed from deck."),
    },
  );

  const { data: transactionByDate, loading: transactionByDateLoading } =
    useQuery(GET_STUDENT_TRANSACTIONS_BY_DATE, {
      variables: {
        studentId: onDeck.studentDetails.id,
        teamId: currentCoachTeam.id,
        date: new Date().toISOString().split("T")[0],
      },
      skip: currentUser.type === EUserTypes.dzm,
    });

  const [timeToday, setTimeToday] = useState(0);

  const dailyTransactions = useMemo(() => {
    if (transactionByDate?.getStudentTransactionByDate) {
      return transactionByDate.getStudentTransactionByDate.filter(
        (transaction) => transaction.timeAdded === 0,
      );
    }

    return [];
  }, [transactionByDate, transactionByDateLoading]);

  useEffect(() => {
    if (transactionByDate?.getStudentTransactionByDate) {
      setTimeToday(
        transactionByDate.getStudentTransactionByDate
          .filter((transaction) => transaction.timeAdded === 0)
          .reduce((acc, transaction) => acc + transaction.minutes, 0),
      );
    }
  }, [transactionByDate, transactionByDateLoading]);

  const accountTypeId = useMemo(() => {
    if (accountTypeData?.getAccountTypeByTeamId) {
      return accountTypeData.getAccountTypeByTeamId.id;
    }
  }, [accountTypeData?.getAccountTypeByTeamId, accountTypeLoading]);

  const handleRemoveFromDashboard = async ({ studentDetails }) => {
    if (
      window.confirm(
        `Are you sure you want to remove ${studentDetails.first_name} ${studentDetails.last_name}?`,
      )
    ) {
      await removeStudentFromDashboard({
        variables: {
          team_id: currentCoachTeam.id,
          student_id: studentDetails.id,
        },
        refetchQueries: [
          {
            query: GET_STUDENTS_ON_DASHBOARD,
            variables: {
              team_id: currentCoachTeam.id,
            },
          },
        ],
      });
    }
  };

  const handleRemoveMinutes = async (minutes, student) => {
    if (
      window.confirm(
        `Are you sure you want to remove ${minutes} minutes from ${student.first_name} ${student.last_name}?`,
      )
    ) {
      await accountBalanceTransaction({
        variables: {
          payload: {
            account_type_id: accountTypeId,
            created_by: currentUser.id,
            minutes: -minutes,
            student_id: onDeck.studentDetails.id,
            team_id: currentCoachTeam.id,
            transaction_type_id: 2,
          },
        },
        refetchQueries: [
          {
            query: GET_ACCOUNT_BALANCE,
            variables: {
              studentId: onDeck.studentDetails.id,
              teamId: currentCoachTeam.id,
            },
          },
          {
            query: GET_STUDENT_TRANSACTIONS_BY_DATE,
            variables: {
              studentId: onDeck.studentDetails.id,
              teamId: currentCoachTeam.id,
              date: new Date().toISOString().split("T")[0],
            },
          },
        ],
      });
    }
  };

  if (loading2 || loadingProducts || !stripeAccountData || accountTypeLoading) {
    return <Loading />;
  }

  let dropdown_items = [];

  if (
    currentCoachTeam.account_type_slug === "time" &&
    isEditable &&
    onDeck.studentDetails.registration_id
  ) {
    dropdown_items = [
      {
        value: (
          <span className="cursor-pointer text-gray-dark">
            <i className="fe fe-clock mr-2 text-muted" />
            -0:10
          </span>
        ),
        onClick: () => handleRemoveMinutes(10, onDeck.studentDetails),
      },
      {
        value: (
          <span className="cursor-pointer text-gray-dark">
            <i className="fe fe-clock mr-2 text-muted" />
            -0:15
          </span>
        ),
        onClick: () => handleRemoveMinutes(15, onDeck.studentDetails),
      },
      {
        value: (
          <span className="cursor-pointer text-gray-dark">
            <i className="fe fe-clock mr-2 text-muted" />
            Account
          </span>
        ),
        onClick: () => setIsAccountBalanceModalOpen(true),
      },
    ];
  }

  if (
    currentCoachTeam.account_type_slug === "time" &&
    !onDeck.studentDetails.registration_id
  ) {
    dropdown_items = [
      {
        value: (
          <span className="cursor-pointer text-muted">
            <i className="fe fe-alert-circle mr-2 text-muted" />
            Unregistered User
          </span>
        ),
        //  onClick: () => handleRemoveMinutes(10, onDeck.studentDetails),
      },
    ];
  }

  const onDragStart = (e) => {
    e.dataTransfer.setData("studentId", onDeck.studentDetails.id);
  };

  return (
    <Grid.Col className="pl-0 pr-0" lg={12}>
      <div
        draggable={isEditable && onDeck.studentDetails.registration_id}
        onDragStart={
          isEditable && onDeck.studentDetails.registration_id && onDragStart
        }
      >
        <Card>
          <Card.Status
            color={
              !onDeck.studentDetails.registration_id ? "danger" : "primary"
            }
          />
          <Card.Body>
            <Dropdown
              className="float-right"
              toggle={false}
              arrow
              triggerContent={<Icon name="chevron-down" />}
              itemsObject={[
                ...dropdown_items,
                {
                  value: (
                    <span className="text-info cursor-pointer">
                      <i className="fe fe-log-out mr-2 text-info" />
                      Check Out
                    </span>
                  ),
                  onClick: () => handleRemoveFromDashboard(onDeck),
                },
              ]}
            />
            <Text muted>
              <AccountBalance
                accountBalance={
                  accountBalance?.getAccountBalance?.total_time ?? 0
                }
                slug={currentCoachTeam.account_type_slug}
              />
            </Text>
            <Header.H4 className="mb-1">
              <Icon
                name={`${
                  onDeck.studentDetails.registration_id
                    ? "user-check"
                    : "user-x"
                }`}
                className={`${
                  onDeck.studentDetails.registration_id
                    ? "text-success"
                    : "text-danger"
                } mr-1`}
              />

              <StudentLink
                studentId={onDeck.studentDetails.id}
                studentName={`${onDeck.studentDetails.first_name} ${onDeck.studentDetails.last_name}`}
              />
            </Header.H4>
            {((onDeck.studentDetails.registration_id && isCoachSuperAdmin) ||
              isCoachSuperAdmin) && (
              <>
                <Text.Small className={`text-black`}>
                  {currentUser.type === EUserTypes.dzm
                    ? `${studentCompletedLoads.length ?? 0} Jumps Today`
                    : currentCoachTeam.account_type_slug === "time"
                    ? `${toHoursAndMinutes(timeToday)} Used Today`
                    : ""}{" "}
                </Text.Small>
                <Progress size="sm">
                  {currentUser.type === EUserTypes.dzm ? (
                    <Progress.Bar
                      color="blue"
                      width={
                        studentCompletedLoads.length >= 5
                          ? 100
                          : (studentCompletedLoads.length / 5) * 100
                      }
                    />
                  ) : currentCoachTeam.account_type_slug === "time" ? (
                    <Progress.Bar
                      color="blue"
                      width={timeToday >= 60 ? 100 : (timeToday / 60) * 100}
                    />
                  ) : (
                    ""
                  )}

                  {}
                </Progress>
              </>
            )}
          </Card.Body>
        </Card>
      </div>

      <Modal
        maxWidth="xs"
        content={
          <Formik
            enableReinitialize={true}
            validationSchema={Yup.object().shape({
              minutes: Yup.number().required("Required"),
            })}
            initialValues={{
              minutes: "",
            }}
            onSubmit={async (values, { resetForm, setSubmitting }) => {
              const { minutes } = values;

              try {
                await accountBalanceTransaction({
                  variables: {
                    payload: {
                      account_type_id: accountTypeId,
                      created_by: currentUser.id,
                      minutes: -minutes,
                      student_id: onDeck.studentDetails.id,
                      team_id: currentCoachTeam.id,
                      transaction_type_id: 2,
                    },
                  },
                  refetchQueries: [
                    {
                      query: GET_ACCOUNT_BALANCE,
                      variables: {
                        studentId: onDeck.studentDetails.id,
                        teamId: currentCoachTeam.id,
                      },
                    },
                    {
                      query: GET_STUDENT_TRANSACTIONS_BY_DATE,
                      variables: {
                        studentId: onDeck.studentDetails.id,
                        teamId: currentCoachTeam.id,
                        date: new Date().toISOString().split("T")[0],
                      },
                    },
                  ],
                });
              } catch (error) {
                toast.error(error);
              }

              setSubmitting(false);
              toggleCustomInput();

              resetForm();
            }}
          >
            {({ handleSubmit, setFieldValue }) => (
              <form onSubmit={handleSubmit} className="d-flex flex-column">
                <Form.Input
                  autoFocus
                  name="minutes"
                  type="number"
                  placeholder=""
                  className="text-center text-xxl"
                  onChange={(e) => {
                    setFieldValue("minutes", Number(e.target.value));
                  }}
                />
                <div className="d-flex justify-content-end mt-3">
                  <Button
                    pill
                    type="button"
                    color="white"
                    size="sm"
                    className="mr-2"
                    onClick={toggleCustomInput}
                  >
                    CANCEL
                  </Button>
                  <Button pill type="submit" color="primary" size="sm">
                    REMOVE
                  </Button>
                </div>
              </form>
            )}
          </Formik>
        }
        open={isCustomInputOpen}
        title={""}
        onClose={toggleCustomInput}
      />
      <StripeAccountBalanceModal
        stripeAccountId={stripeAccountId}
        accountTypeSlug={accountTypeData?.getAccountTypeByTeamId.slug}
        paymentFee={
          paymentFee?.getPaymentFeeByTeamId.payment_fee_customer
            ? paymentFee?.getPaymentFeeByTeamId.payment_fee_customer / 100
            : 0
        }
        products={products}
        isModalOpen={isAccountBalanceModalOpen}
        studentAccountBalance={
          accountBalance?.getAccountBalance?.total_time ?? 0
        }
        user={onDeck.studentDetails}
        team={{ ...currentCoachTeam, id: currentCoachTeam.id.toString() }}
        onCompleted={() => refetch()}
        toggleModal={() => setIsAccountBalanceModalOpen(false)}
      />
    </Grid.Col>
  );
};

export default observer(OnDeckListItem);
