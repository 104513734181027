import React, { useContext } from 'react';
import { Table, Text } from 'tabler-react';
import { useRootStore } from "../../hooks";
import { EUserTypes } from "../User/userTypes";
import { Link } from 'react-router-dom';
import { UserStoreContext } from '../../contexts/userStoreContext';
import useReactRouter from 'use-react-router';
import formatSeal from '../../utils/formatSeal';
import moment from 'moment';
import useGetCurrentUserType from '../../modules/common/hooks/useGetCurrentUserType';
import { Types } from '../../types/graphql'


interface ReserveLogListItemProps {
  reserveLog: Types.ReserveLog;
  toggleModal: any;
  setReservelog: any;
}

const ReserveLogItem = ({ reserveLog, toggleModal, setReservelog }: ReserveLogListItemProps) => {
  const { currentUser } = useRootStore();
  const userStore = useContext(UserStoreContext);
  const { isAdmin } = useGetCurrentUserType();
  const { user } = userStore;
  const { history } = useReactRouter();

  const routeToStudent = (student) => {
    userStore.detach(user);
    userStore.loadUser(student);
    history.push('/user-details');


  };

  return (
    <>
      {isAdmin && (
        <Table.Col>
          <Link to='#' onClick={() => {
            toggleModal();
            setReservelog(reserveLog);
          }}>
            {reserveLog.id}
          </Link>
        </Table.Col>
      )}
      <Table.Col>
        <Link to='#' onClick={() => {
          toggleModal();
          setReservelog(reserveLog);
        }}>
          {moment(reserveLog.event_date).format("MM/DD/YYYY")}
        </Link>
      </Table.Col>
      <Table.Col>
        <Link
          className='text-decoration-none'
          to='#'
          onClick={(e) => {
            e.preventDefault();
            routeToStudent(reserveLog.student);
          }}
        >
          {reserveLog.student.first_name} {reserveLog.student.last_name}
        </Link>
        <Text.Small className="d-block text-muted">{reserveLog?.user_gear?.gear_model?.name}</Text.Small>
      </Table.Col>
      {currentUser.type === EUserTypes.admin && (
        <>
          <Table.Col>{reserveLog.location.name}</Table.Col>
          <Table.Col>{reserveLog.team.name}</Table.Col>
        </>
      )}
      <Table.Col>
        {reserveLog?.user_rigger_seal?.coach?.first_name} {reserveLog?.user_rigger_seal?.coach?.last_name}
        <Text.Small className="d-block text-muted">{formatSeal(reserveLog?.user_rigger_seal?.rigger_seal)}</Text.Small>
      </Table.Col>
      <Table.Col className="text-muted">{moment(reserveLog.event_date).add(180, 'days').format('MM/DD/YYYY')}</Table.Col>
      <Table.Col className="text-muted">
      {reserveLog?.reserve_notes && (
        <Link
          className='text-decoration-none text-muted'
          to='#'
          onClick={(e) => {
            toggleModal();
            setReservelog(reserveLog);
          }}
        >
          <i className='fe fe-file-text mr-1' />
        </Link>
        )}
      </Table.Col>
    </>
  );
};

export default ReserveLogItem;
