import { useLazyQuery, useQuery } from "@apollo/client";
import { observer } from "mobx-react";
import { applySnapshot } from "mobx-state-tree";
import moment from "moment";
import React, { useContext, useEffect, useState } from "react";
import { Button, Card, Dimmer, Grid, Table } from "tabler-react";
import { LoadStoreContext } from "../../contexts/LoadStoreContext";
import { LOAD_LIST_QUERIES } from "../../graphql/LOAD_LIST_QUERIES";
import { useRootStore } from "../../hooks/useRootStore";
import DatePicker from "../DatePicker";
import LoadListItem from "./LoadListItem";

interface ILoadsListProps {
  toggleModal: () => void;
}

const LoadsList = ({ toggleModal }: ILoadsListProps) => {
  const { aircrafts, loads } = useContext(LoadStoreContext);
  const { currentCoachTeam } = useRootStore();

  const [currentDate, setCurrentDate] = useState(new Date());

  const { loading, error, data } = useQuery(LOAD_LIST_QUERIES, {
    variables: {
      team_id: currentCoachTeam.id,
      date: currentDate,
    },
  });

  const [getLoads] = useLazyQuery(LOAD_LIST_QUERIES, {
    variables: {
      team_id: currentCoachTeam.id,
      date: currentDate,
    },
    onCompleted: (data) => {
      applySnapshot(aircrafts, data.teamAircrafts);
      applySnapshot(loads, data.loads);
    },
  });

  useEffect(() => {
    if (!loading && data) {
      applySnapshot(aircrafts, data.teamAircrafts);
      applySnapshot(loads, data.loads);
    }
  }, [data]);

  if (error) {
    return <p>Error: {`Error: ${error.message}`}</p>;
  }
  const dateChangeHandle = (date) => {
    setCurrentDate(date);
    getLoads();
  };
  return (
    <Card>
      <Card.Header>
        <Card.Title>Loads</Card.Title>
        <Card.Options>
          <Button
            className="text-secondary mr-2"
            color="white"
            icon="chevron-left"
            size="sm"
            onClick={() => {
              dateChangeHandle(moment(currentDate).subtract(1, "day").toDate());
            }}
          >
            prev
          </Button>
          <DatePicker
            name="date"
            format={"dd MMMM yyyy"}
            className="mr-2"
            value={currentDate}
            minDate={
              new Date(new Date().setFullYear(new Date().getFullYear() - 4))
            }
            placeholder="mm/dd/yyyy"
            onChange={(e) => {
              dateChangeHandle(e);
            }}
          />
          <Button
            outline
            icon="calendar"
            color="secondary"
            className="mr-2 pr-3 pl-3"
            size="sm"
            onClick={() => {
              dateChangeHandle(new Date());
            }}
          >
            Today
          </Button>
          <Button
            icon="plus"
            color="primary"
            size="sm"
            className="mr-3 pr-3 pl-3"
            onClick={toggleModal}
          />
        </Card.Options>
      </Card.Header>
      <Card.Body>
        <Grid.Col width={12}>
          <Dimmer active={loading} loader={loading}>
            <Table className="card-table table-vcenter text-nowrap">
              <Table.Header>
                <Table.Row>
                  <Table.ColHeader>Load</Table.ColHeader>
                  <Table.ColHeader>Departure</Table.ColHeader>
                  <Table.ColHeader>Aircraft</Table.ColHeader>
                  <Table.ColHeader>Capacity</Table.ColHeader>
                  <Table.ColHeader>Status</Table.ColHeader>
                  <Table.ColHeader></Table.ColHeader>
                  <Table.ColHeader></Table.ColHeader>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {loads.map((load) => {
                  return (
                    <Table.Row key={load.id}>
                      <LoadListItem load={load} />
                    </Table.Row>
                  );
                })}
              </Table.Body>
            </Table>
          </Dimmer>
        </Grid.Col>
      </Card.Body>
    </Card>
  );
};

export default observer(LoadsList);
