import { Formik } from 'formik';
import Modal from '../../Modal';
import React, { useMemo } from 'react';
import { Button, Form, Grid, Text } from 'tabler-react';
import { useMutation, useQuery } from '@apollo/client';
import { CREATE_CAMP_REGISTRATION_OPTION } from '../../../graphql/CREATE_CAMP_REGISTRATION_OPTION';
import { ADD_CAMP } from '../../../graphql/ADD_CAMP';
import { GET_CAMP_REGISTRATION_OPTIONS } from '../../../graphql/GET_CAMP_REGISTRATION_OPTIONS';
import useReactRouter from 'use-react-router';
import moment from 'moment/moment';
import { CREATE_CAMP_REGISTRATION_QUESTION } from '../../../graphql/CREATE_CAMP_REGISTRATION_QUESTION';
import { GET_REGISTRATION_QUESTIONS } from '../../../graphql/GET_REGISTRATION_QUESTIONS';
import { GET_TEAM_LOCATIONS_BY_SPORT_TYPE } from '../../../graphql/GET_TEAM_LOCATIONS_BY_SPORT_TYPE';
import { GET_CAMPS } from '../../../graphql/GET_CAMPS';
import { Types } from '../../../types/graphql'

interface ICalendarDuplicateProp {
  event: Types.Camp
  eventOptions: Types.CampRegistrationOption[]
  isModalOpen: boolean;
  toggleModal: (isModalOpen?: boolean) => void;
}

const CalendarDuplicate = ({
  event,
  eventOptions,
  isModalOpen,
  toggleModal
}: ICalendarDuplicateProp) => {
  const { history } = useReactRouter();
  const [addCampRegistrationOptions] = useMutation(
    CREATE_CAMP_REGISTRATION_OPTION
  );
  const [addCampRegistrationQuestion] = useMutation(
    CREATE_CAMP_REGISTRATION_QUESTION
  );
  const { data: questionData, loading: questionLoading } = useQuery(
    GET_REGISTRATION_QUESTIONS,
    {
      variables: {
        filter: {
          campId: Number(event.id),
          teamId: event?.team_id
        }
      }
    }
  );

  const questions =
    useMemo(() => {
      if (!questionLoading) {
        return questionData?.registrationQuestions.filter((q) => q.is_exist);
      }
      return [];
    }, [questionData, questionLoading]);

  const [addCamp] = useMutation(ADD_CAMP, {
    onCompleted: async (result) => {
      if (result?.addCamp) {
        const done = await addOptions(result?.addCamp);
        const addQuestionsDone = await addQuestions(result?.addCamp);
        if (done && addQuestionsDone) {
          history.push(`/user/calendar/${result?.addCamp.id}`);
        }
      }
    }
  });

  const duration = useMemo(() => {
    return moment(event.end).diff(moment(event.start), 'days');
  }, [event.start, event.end]);

  const addOptions = async (camp) => {
    eventOptions.map(async (option) => {
      await addCampRegistrationOptions({
        variables: {
          campRegistrationOption: {
            camp_id: Number(camp.id),
            registration_option_id: option.id
          }
        },
        refetchQueries: [
          {
            query: GET_CAMP_REGISTRATION_OPTIONS,
            variables: {
              campId: Number(camp.id),
              teamId: Number(event?.team_id)
            }
          }
        ]
      });
    });
    return true;
  };

  const addQuestions = async (camp) => {
    questions.map(async (question) => {
      await addCampRegistrationQuestion({
        variables: {
          campId: Number(camp.id),
          registrationQuestionId: question.id
        }
      });
    });
    return true;
  };

  const { loading, error, data } = useQuery(GET_TEAM_LOCATIONS_BY_SPORT_TYPE, {
    variables: {
      team_id: Number(event.team_id),
      sport_type_id: Number(event?.sport_type_id),
      filter: {}
    }
  });

  const locationBySportType = useMemo(() => {
    if (!loading && !error && data) {
      return data.getTeamLocationsBySportType;
    }
    return [];
  }, [data, loading, error]);

  const renderForm = ({
    values,
    handleSubmit,
    isSubmitting,
    handleChange,
    setFieldValue
  }) => (
    <form onSubmit={handleSubmit}>
      <Grid.Row>
        <Grid.Col>
          <div className="form-group">
            <div className="form-label">Start Date</div>
            <input
              className="form-control"
              type="date"
              name="start_date"
              value={values.start_date}
              onChange={handleChange}
              required={true}
            />
          </div>
        </Grid.Col>
      </Grid.Row>
      <Grid.Row>
        <Grid.Col>
          <Form.Group label="Location">
            {locationBySportType.map(
              (location) =>
                location.name && (
                  <div
                    className="switchradio position-relative d-inline-flex mr-3"
                    key={`${location.id}-${location.name}`}
                  >
                    <input
                      type="radio"
                      checked={
                        Number(values.location_id) === Number(location.id)
                      }
                      value={location.id}
                      id={`${location.id}-${location.name}`}
                      name="location_id"
                      className="switchradio-input position-absolute"
                      onChange={(e) => {
                        setFieldValue('location_id', Number(e.target.value));
                      }}
                      required={true}
                    />
                    <label
                      htmlFor={`${location.id}-${location.name}`}
                      className="btn btn-secondary"
                    >
                      {location.name}
                    </label>
                  </div>
                )
            )}
          </Form.Group>
        </Grid.Col>
      </Grid.Row>
      <Grid.Row>
        <Grid.Col>
          <Text className="text-muted pb-5">
            When duplicating an event, all details and settings will remain the
            same, other than the new selected start date. The event will be
            unpublished by default until you confirm it is ready to launch.
          </Text>
        </Grid.Col>
      </Grid.Row>
      <Button
        type="submit"
        disabled={isSubmitting}
        pill
        className="btn-gray-dark float-right btn-sm"
      >
        {isSubmitting ? 'Processing...' : 'SUBMIT'}
      </Button>

      <Button
        pill
        color="white"
        className="float-right btn-sm"
        onClick={(e) => {
          e.preventDefault();
          toggleModal();
        }}
      >
        CANCEL
      </Button>
    </form>
  );

  return (
    <Modal
      content={
        <Formik
          enableReinitialize={true}
          initialValues={{
            all_day: event.all_day,
            auto_approval: event.auto_approval,
            default_jump_count: event.default_jump_count,
            default_jump_price: event.default_jump_price,
            camp_description: event.camp_description,
            camp_email: event.camp_email,
            camp_fees: event.camp_fees,
            camp_fee_type_id: event.camp_fee_type_id
              ? Number(event.camp_fee_type_id)
              : null,
            camp_name: event.camp_name,
            camp_phone: event.camp_phone,
            camp_type_id: event.camp_type_id
              ? Number(event.camp_type_id)
              : null,
            camp_url: event.camp_url,
            created_by: Number(event.created_by),
            end: event.end,
            end_date: moment(event.end).format('YYYY-MM-DD'),
            end_time: moment(event.end).format('HH:mm'),
            event_type_id: Number(event.event_type_id),
            is_public: false,
            is_published: false,
            location_id: event.location_id,
            registration_information: event.registration_information,
            registration_status: event.registration_status,
            registration_type: event.registration_type,
            registration_waitlist: event.registration_waitlist,
            registrations_max: Number(event.registrations_max),
            slot_duration: Number(event.slot_duration),
            sport_type_id: event.sport_type_id
              ? Number(event.sport_type_id)
              : null,
            start: event.start,
            start_date: moment(event.start).format('YYYY-MM-DD'),
            start_time: moment(event.start).format('HH:mm'),
            status: event.status,
            students_per_slot: event.students_per_slot,
            student_registration: event.student_registration,
            student_slots: Number(event.student_slots),
            team_id: Number(event.team_id)
          }}
          onSubmit={async (values) => {
            const {
              start_time,
              end_time,
              start_date,
              end_date,
              start,
              end,
              ...newCamp
            } = values;
            const start_formatted = moment(
              start_date + ' ' + start_time
            ).format('YYYY-MM-DD HH:mm');
            const end_formatted = moment(
              moment(start_date).add(duration, 'days').format('YYYY-MM-DD') +
                ' ' +
                end_time
            ).format('YYYY-MM-DD HH:mm');

            await addCamp({
              variables: {
                newCamp: {
                  ...newCamp,
                  start: start_formatted,
                  end: end_formatted
                }
              },
              refetchQueries: [
                {
                  query: GET_CAMPS,
                  variables: {
                    filter: {
                      team_id: Number(event.team_id),
                      isDateAgnostic: true
                    }
                  }
                }
              ]
            });
            toggleModal();
          }}
        >
          {(formikData) => renderForm(formikData)}
        </Formik>
      }
      open={isModalOpen}
      onClose={() => toggleModal()}
      title="Duplicate Event"
    />
  );
};

export default CalendarDuplicate;
