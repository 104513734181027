import { useRootStore } from 'hooks'
import useGetCurrentUserType from 'modules/common/hooks/useGetCurrentUserType'
import QRCodeStudentNavItem from 'modules/student/components/nav-items/QRCodeStudentNavItem'
import React from 'react'
import { Dropdown } from 'tabler-react'
import useReactRouter from 'use-react-router'

const HeaderNavItems = () => {
  const { history } = useReactRouter()
  const { isRigger, isStudent } = useGetCurrentUserType()
  const { currentUser } = useRootStore()

  return (
    <>
      <Dropdown.Item
        className="cursor-pointer"
        icon="user"
        onClick={() => {
          history.push(`/user/profile`)
        }}
      >
        Profile
      </Dropdown.Item>
      <Dropdown.Item
        className="cursor-pointer"
        icon="settings"
        onClick={() => {
          history.push(`/user/settings`)
        }}
      >
        Settings
      </Dropdown.Item>
      {isStudent && currentUser?.token && (
        <QRCodeStudentNavItem
          token={currentUser.token}
          id={currentUser.id}
          firstName={currentUser.first_name}
          lastName={currentUser.last_name}
          profileAvatar={currentUser.profile_avatar}
        />
      )}
      <Dropdown.ItemDivider />
      {isRigger && (
        <>
          <Dropdown.Item
            className="cursor-pointer"
            icon="briefcase"
            onClick={() => {
              history.push(`/rigger/myjobs`)
            }}
          >
            My Jobs
          </Dropdown.Item>
          <Dropdown.ItemDivider />
        </>
      )}
      <Dropdown.Item
        className="cursor-pointer"
        icon="help-circle"
        onClick={() => {
          history.push(`/help`)
        }}
      >
        Help
      </Dropdown.Item>
      <Dropdown.Item
        className="cursor-pointer"
        icon="log-out"
        onClick={() => {
          localStorage.clear()
          window.location.href = '/auth/logout'
        }}
      >
        Log out
      </Dropdown.Item>
    </>
  )
}

export default HeaderNavItems;
