import React from 'react';

import LocationsList from '../../components/Location/LocationsList';
import { LocationStoreProvider } from '../../contexts/LocationStoreContext';
import Wrapper from '../layout';

const LocationList = (props) => {
  return (
    <Wrapper {...props} title="Location List">
      <div className="pr-3 pl-3">
        <LocationStoreProvider>
          <LocationsList />
        </LocationStoreProvider>
      </div>
    </Wrapper>
  );
};
export default LocationList;
