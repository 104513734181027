import React from 'react';
import { Container } from 'tabler-react';

import Wrapper from 'pages/layout';

const ReserveLogList = (props) => {

  return (
    <Wrapper {...props} title="Reserve Logs">
      <Container>

      </Container>
    </Wrapper>
  );
};
export default ReserveLogList;
