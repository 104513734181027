import React, { useState } from 'react';
import { Button, Card } from 'tabler-react';
import { useMutation } from '@apollo/client';
import { DELETE_BIN_ITEMS } from '../../graphql/DELETE_BIN_ITEMS';
import { EUserTypes } from '../User/userTypes';
import { useRootStore } from '../../hooks';
import { UPDATE_JOB } from '../../graphql/UPDATE_JOB';
import { toast } from 'react-toastify';
import JobGearStorageItemGear from './JobGearStorageItemGear';
import { GET_JOBS_BY_STORAGE } from '../../graphql/GET_JOBS_BY_STORAGE';

interface JobListItemProps {
  jobList: any;
}

const JobGearStorageItem = ({ jobList }: JobListItemProps) => {
  const rootStore = useRootStore();
  const { currentUser, currentCoachTeam } = rootStore;
  const [showMore, setShowMore] = useState(false);
  const [clearBin] = useMutation(DELETE_BIN_ITEMS, {
    onCompleted: () => toast.success('Bin is not empty.')
  });
  const [updateJob] = useMutation(UPDATE_JOB, {
    onCompleted: () => toast.success('Item removed from bin.')
  });
  const filterOption =
    currentUser.type !== EUserTypes.admin
      ? { team_id: currentCoachTeam?.id }
      : {};
  const handleClearBinClick = async () => {
    if (window.confirm('Clear all items from this bin?')) {
      await clearBin({
        variables: { binId: jobList[0]?.job_storage },
        refetchQueries: [
          {
            query: GET_JOBS_BY_STORAGE,
            variables: {
              jobListFilter: {
                order: 'asc',
                ...filterOption
              }
            }
          }
        ]
      });
    }
  };

  const handleRemoveItem = async (jobId) => {
    if (window.confirm('Remove item from bin?')) {
      await updateJob({
        variables: {
          updateJobInput: {
            id: jobId,
            job_storage: null
          }
        },
        refetchQueries: [
          {
            query: GET_JOBS_BY_STORAGE,
            variables: {
              jobListFilter: {
                order: 'asc',
                ...filterOption
              }
            }
          }
        ]
      });
    }
  };
  return (
    <>
      <Card className="mb-0 bg-light">
        <Card.Header>
          <Card.Title>
            Bin: <strong>{jobList[0]?.job_storage}</strong>
            {jobList.length > 1 && (
              <Button
                size={'sm'}
                className="bg-light text-muted"
                icon={showMore ? 'chevron-up' : 'chevron-down'}
                onClick={() => setShowMore(!showMore)}
              />
            )}
          </Card.Title>
          <Card.Options>
            <Button.List align="right">
              <Button
                icon="x"
                size="sm"
                className="bg-light text-muted"
                onClick={() => handleClearBinClick()}
              >
                CLEAR
              </Button>
            </Button.List>
          </Card.Options>
        </Card.Header>
      </Card>
      {jobList.slice(0, showMore ? jobList.length : 1).map((job) => (
        <JobGearStorageItemGear job={job} handleRemoveItem={handleRemoveItem} key={job.id} />
      ))}
    </>
  );
};

export default JobGearStorageItem;
