import gql from 'graphql-tag';

export const GET_TEAM_LOCATIONS = gql`
  query GET_TEAM_LOCATIONS($teamId: Int) {
    getTeamLocations(teamId: $teamId) {
      id
      type
      name
    }
  }
`;
