import React from 'react';
import AdminAircraftList from '../../components/Aircraft/AdminAircraftList';
import AddAircraftForm from '../../components/Aircraft/Form/AddAircraftForm';
import Wrapper from '../layout';
import { Card } from 'tabler-react';

const AircraftList = (props) => {
  return (
    <Wrapper {...props} title="Admin Aircraft">
      <div className="container">
        <Card>
          <Card.Header>
            <Card.Title>Manage Aircraft</Card.Title>
          </Card.Header>
          <Card.Body>
            <AdminAircraftList />
          </Card.Body>
        </Card>
        <AddAircraftForm />
      </div>
    </Wrapper>
  );
};

export default AircraftList;
