import { useQuery } from '@apollo/client';
import { observer } from 'mobx-react';
import { applySnapshot } from 'mobx-state-tree';
import React, { useEffect } from 'react';
import { Container, Dimmer } from 'tabler-react';
import useReactRouter from 'use-react-router';

import AdminList from '../../components/Admin/AdminList';
import { GET_ADMINS } from '../../graphql/GET_ADMINS';
import { useRootStore } from '../../hooks/useRootStore';
import Wrapper from '../layout';

const AdminListPage = (props) => {
  const { admins, currentUser } = useRootStore();
  const { history } = useReactRouter();

  if (currentUser.id !== 1) {
    history.push('/');
  }

  const { loading, error, data } = useQuery(GET_ADMINS);

  // Run once on mount
  useEffect(() => {
    if (!loading && data) {
      applySnapshot(admins, data.getAdmins);
    }
  }, [data]);  

  if (error) {
    return <p>{`Error: ${error.message}`}</p>;
  }

  return (
    <Wrapper {...props} title="Admin List">
      <Container>
        <Dimmer active={loading} loader={loading}>
          <AdminList admins={admins} />
        </Dimmer>
      </Container>
    </Wrapper>
  );
};
export default observer(AdminListPage);
