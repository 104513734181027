import React from 'react';
import { Link } from 'react-router-dom';
import { Avatar, Table, Text } from 'tabler-react';
import useGetCurrentUserType from 'modules/common/hooks/useGetCurrentUserType';
import { formatMoney } from '../../utils/numberFormat';
import { convertToTime } from 'utils/convertToTime';

interface IProducttListItemProps {
  product: any;
  productType: any;
  accountType: any;
}

const ProductListItemAdmin = ({
  product,
  productType,
  accountType
}: IProducttListItemProps) => {
  const { isAdmin } = useGetCurrentUserType();
  const MAX_TEXT_LENGTH = 35;

  const typeIndex = productType.findIndex(
    (types) => types.id === parseInt(product.product_type_id)
  );
  //const accountTypeIndex = accountType.findIndex((types) => types.id === parseInt(product.account_type_id));
  return (
    <>
      <Table.Col>
        <Link
          to={`/products/${product.id}`}
          className="text-decoration-none text-body"
        >
          <Avatar
            imageURL={
              (product.image && `/api/s3/uploads/${product.image}`) || ''
            }
            size="lg"
            className="mr-2 float-left bg-transparent"
          />
          {product.title.length > MAX_TEXT_LENGTH ? (
            <>
              <span
                dangerouslySetInnerHTML={{
                  __html: product.title.substring(0, MAX_TEXT_LENGTH)
                }}
              />
              ...
            </>
          ) : (
            product.title
          )}
        </Link>
        <Text.Small className="d-block text-muted">
          {productType[typeIndex]?.name}
        </Text.Small>
      </Table.Col>
      <Table.Col>{product.status.toUpperCase()}</Table.Col>
      <Table.Col>{formatMoney(product.amount)}</Table.Col>
      <Table.Col>
        {product.quantity !== product.amount && product.status === 'account' && (
          <>
            {product.account_type_id === 1 && convertToTime(product.quantity)}
            {product.account_type_id !== 1 && formatMoney(product.quantity)}
            {product.account_type_id === 1 &&
              ' (' +
                formatMoney((product.amount / product.quantity) * 60) +
                '/hr)'}
          </>
        )}
      </Table.Col>
      <Table.Col>{isAdmin && product.currency}</Table.Col>
      <Table.Col>{product.code}</Table.Col>
      <Table.Col></Table.Col>
    </>
  );
};

export default ProductListItemAdmin;
