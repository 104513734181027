import React from 'react';
import Modal from '../../Modal';
import TeamLoadPaymentTypeForm from '../Form/TeamLoadPaymentTypeForm';

interface TeamLoadPaymentTypeModalProps {
  isOpen: boolean;
  toggleModal: () => void;
  loadPaymentType: any;
}

const TeamLoadPaymentTypeModal = ({
  isOpen,
  loadPaymentType,
  toggleModal
}: TeamLoadPaymentTypeModalProps) => {
  return (
    <Modal
      content={
        <TeamLoadPaymentTypeForm
          loadPaymentType={loadPaymentType}
          toggleModal={toggleModal}
        />
      }
      open={isOpen}
      title={
        loadPaymentType.id ? 'Edit Load Payment Type' : 'Add Load Payment Type'
      }
      onClose={toggleModal}
    />
  );
};

export default TeamLoadPaymentTypeModal;
