import gql from 'graphql-tag'

export const GET_STUDENT_SEARCH_QUERY = gql`
  query GET_STUDENT_SEARCH_QUERY($q: String!) {
    getStudentSearchQuery(q: $q) {
      teams {
        teamId
        teamName
        teamSlug
      }

      locations {
        locationId
        locationName
        locationType
      }

      events {
        campId
        campName
        sportTypeName
        locationName
      }
    }
  }
`
