import gql from 'graphql-tag';

export const INSERT_DELETE_USER_SPORT = gql`
  mutation INSERT_DELETE_USER_SPORT($student_id: Int!, $sport_type_id: Int!) {
    insertDeleteUserSport(
      student_id: $student_id
      sport_type_id: $sport_type_id
    )
  }
`;
