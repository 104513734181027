import React, { useState } from 'react';
import { List, Form } from 'tabler-react';
import TeamProgressionTitleEdit from './Form/TeamProgressionTitleEdit';
import { useMutation } from '@apollo/client';
import { EDIT_TEAM_PROGRESSION } from '../../graphql/EDIT_TEAM_PROGRESSION';
import { GET_TEAM_PROGRESSIONS } from '../../graphql/GET_TEAM_PROGRESSIONS';

const TeamProgressionItem = ({ progress }) => {
  const [isEditting, setIsEditting] = useState(false);
  const [editTeamProgression] = useMutation(EDIT_TEAM_PROGRESSION);

  const handleToggleCheck = async (item) => {
    await editTeamProgression({
      variables: {
        editProgressionInput: {
          id: item.id,
          is_disabled: item.is_disabled === 1 ? 0 : 1
        }
      },
      refetchQueries: [
        {
          query: GET_TEAM_PROGRESSIONS, variables: {
            teamId: item.team_id
          }
        }
      ]
    });
  };
  return (
    <>
      <List.GroupItem action>
        {isEditting ? <TeamProgressionTitleEdit progression={progress} setEditting={setIsEditting} />
          : <div>
            <span className={'cursor-pointer'} onClick={() => setIsEditting(true)}>
              {progress.title}
            </span>
            <Form.Switch
              className="cursor-pointer float-right"
              type="checkbox"
              name="is_disabled"
              onChange={() =>
                handleToggleCheck(progress)
              }
              checked={progress.is_disabled ? false : true }
            />
          </div>
        }
      </List.GroupItem>
    </>
  );
};

export default TeamProgressionItem;
