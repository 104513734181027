import React, { createContext, ReactNode, useState } from 'react';
import { onSnapshot, applySnapshot } from 'mobx-state-tree';

import {
  UserStore,
  UserStoreType,
  UserStoreDefault
} from '../models/UserStore';

export const UserStoreContext = createContext<UserStoreType | null>(null);

interface UserStoreProvider {
  children: ReactNode;
}

const storeItem = 'userStore';

const getOrCreateStore = () => {
  const userStore = UserStoreDefault();
  const initData = localStorage.getItem(storeItem);

  if (initData) {
    applySnapshot(userStore, JSON.parse(initData));
    return userStore;
  } else {
    localStorage.setItem(storeItem, JSON.stringify(UserStore.create()));
    return UserStore.create();
  }
};

export const UserStoreProvider = (props: UserStoreProvider) => {
  const [userStore] = useState(() => getOrCreateStore());

  onSnapshot(userStore, (_snapshot) => {
    const snapshot = { ..._snapshot };
    localStorage.setItem(storeItem, JSON.stringify(snapshot));
  });

  return (
    <UserStoreContext.Provider value={userStore}>
      {props.children}
    </UserStoreContext.Provider>
  );
};
