export const SPORT_TYPE_LOCATION_MAPPING = new Map([
  [1, 'tunnel'],
  [2, 'dropzone'],
  [3, 'base']
]);

export type SportRowType = {
  id: number;
  hasSub: boolean;
};
