import React from 'react';
import { Container } from 'tabler-react';
import Wrapper from '../layout';
import TeamPublicDetails from '../../components/Team/TeamPublicDetails';

const TeamPublicPage = (props) => {
  return (
    <Wrapper {...props} title="Team Details">
      <Container>
        <TeamPublicDetails />
      </Container>
    </Wrapper>
  );
};
export default TeamPublicPage;
