import { useQuery } from "@apollo/client";
import { GET_SPORTS_BY_STUDENT_ID } from "../../../../graphql/GET_SPORTS_BY_STUDENT_ID";
import {
  GET_SPORTS_BY_STUDENT_ID as GET_SPORTS_BY_STUDENT_ID_TYPE,
} from "../../../../graphql/types/GET_SPORTS_BY_STUDENT_ID";
import { useRootStore } from "../../../../hooks";
import React, { useMemo } from "react";
import { Alert, Button, Card, Dimmer } from "tabler-react";
import useRouter from "use-react-router";
import { Types } from '../../../../types/graphql'

const UserSportsList = () => {
  const { currentUser } = useRootStore();
  const { history } = useRouter();

  const { data: studentSports, loading: studentSportsLoading } =
    useQuery<GET_SPORTS_BY_STUDENT_ID_TYPE>(GET_SPORTS_BY_STUDENT_ID, {
      variables: {
        student_id: currentUser?.id,
      },
    });

  const studentSportRows: Types.SportTypes[] =
    useMemo(
      (): Types.SportTypes[] =>
        studentSports?.getSportsByStudentId
          .filter(
            (sport) =>
              sport.is_active,
          )
          .map((item) => item) ??
        [],
      [studentSports],
    );

  return (
    <Card statusColor="blue" title="Activities">
      <Card.Body className="py-2">
        <Dimmer active={studentSportsLoading} loader={studentSportsLoading}>
          {studentSportRows.length <= 0 && (
            <>
              <Button.List align="center mt-3">
                <Button
                  color="primary"
                  size="sm"
                  className=""
                  onClick={(): void => history.push("/user/settings")}
                >
                  Add Activities
                </Button>
              </Button.List>
            </>
          )}
          <Button.List className="my-3" align="center">
            {studentSportRows.map(
              (
                sportType: Types.SportTypes,
              ): JSX.Element => (
                <Button pill color="secondary" size="sm" key={sportType.id}>
                  {sportType.name}
                </Button>
              ),
            )}
          </Button.List>
        </Dimmer>
      </Card.Body>
    </Card>
  );
};

export default UserSportsList;
