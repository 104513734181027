import gql from "graphql-tag";

export const GET_CAMP_OPTION_TYPES = gql`
  query GET_CAMP_OPTION_TYPES {
    feeTypes {
      id
      name
    }

    getRegistrationOptionTypes {
      id
      slug
      name
    }
  }
`;
