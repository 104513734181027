import React, { useState } from 'react';
import { Table } from 'tabler-react';
import OrgFormModal from './Modals/OrgFormModal';

const OrgListItem = ({ org }) => {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <>
      <Table.Row>
        <Table.Col>
          <span
            className={'cursor-pointer text-primary'}
            onClick={() => setIsOpen(true)}
          >
            {org.id}
          </span>
        </Table.Col>
        <Table.Col className="text-uppercase"><strong>{org.slug}</strong></Table.Col>
        <Table.Col>
          {org.image_favicon ? (
            <img
              className={'d-flex mb-1 mt-2 float-left'}
              src={`/api/s3/uploads/${org.image_favicon}`}
              width={'50px'}
              alt={'Groups'}
            />
          ) : (
            ''
          )}
          {org.name}</Table.Col>
        <Table.Col>{org.country}</Table.Col>
        <Table.Col>
          {org.org_type}
        </Table.Col>
      </Table.Row>
      <OrgFormModal org={org} isOpen={isOpen} toggle={() => setIsOpen(false)} />
    </>
  );
};

export default OrgListItem;
