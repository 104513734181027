import { useMutation, useQuery } from "@apollo/client";
import { ADD_STUDENT_TO_DASHBOARD } from "graphql/ADD_STUDENT_TO_DASHBOARD";
import { GET_STUDENT_ON_DASHBOARD_BY_IDS } from "graphql/GET_STUDENT_ON_DASHBOARD_BY_IDS";
import { REMOVE_STUDENT_FROM_DASHBOARD } from "graphql/REMOVE_STUDENT_FROM_DASHBOARD";
import { toast } from "react-toastify";
import { Button, Dimmer } from "tabler-react";
import { ADD_STUDENT_TO_TEAM } from "../../../../graphql/ADD_STUDENT_TO_TEAM";
import { GET_STUDENT_TEAM } from "../../../../graphql/GET_STUDENT_TEAM";

type CheckInOutButtonProps = {
  studentId: number;
  teamId: number;
  teamName: string;
};

const CheckInOutButton = ({
  studentId,
  teamId,
  teamName,
}: CheckInOutButtonProps) => {
  const teamIdValue = Number(teamId);
  const { data, loading } = useQuery(GET_STUDENT_TEAM, {
    variables: {
      student_id: studentId,
      team_id: teamIdValue,
    },
  });
  const [removeStudentFromDashboard] = useMutation(
    REMOVE_STUDENT_FROM_DASHBOARD,
    {
      onCompleted: () => {
        toast.success(`Checked out of ${teamName}!`);
      },
      refetchQueries: ["GET_STUDENT_ON_DASHBOARD_BY_IDS"],
    },
  );

  const [addStudentToDashboard] = useMutation(ADD_STUDENT_TO_DASHBOARD, {
    onCompleted: () => {
      toast.success(`Checked in to ${teamName}!`);
    },
    refetchQueries: ["GET_STUDENT_ON_DASHBOARD_BY_IDS"],
  });

  const { loading: dashboardStudentDataLoading, data: dashboardStudentData } =
    useQuery(GET_STUDENT_ON_DASHBOARD_BY_IDS, {
      variables: {
        team_id: teamIdValue,
        student_id: studentId,
      },
    });

  const [addStudentToTeam] = useMutation(ADD_STUDENT_TO_TEAM, {
    onCompleted: async () => {
      await addStudentToDashboard({
        variables: {
          team_id: teamIdValue,
          student_id: studentId,
        },
      });
    },
    refetchQueries: ["GET_STUDENT_TEAM"],
  });

  const handleToggleCheck = async () => {
    if (dashboardStudentData.getStudentOnDashboardByIds) {
      //remove from dashboard
      await removeStudentFromDashboard({
        variables: {
          team_id: teamIdValue,
          student_id: studentId,
        },
      });
    } else {
      // if no team, add to team
      // add to dashboard
      if (!data.getStudentTeam) {
        addStudentToTeam({
          variables: {
            student_id: studentId,
            team_id: teamIdValue,
          },
        });
      } else {
        await addStudentToDashboard({
          variables: {
            team_id: teamIdValue,
            student_id: studentId,
          },
        });
      }
    }
  };
  return (
    <Dimmer loading={loading} active={loading}>
      <Button
        block
        color={`${
          dashboardStudentData?.getStudentOnDashboardByIds ? "info" : "primary"
        }`}
        className="justify-content-center mt-5 mb-5"
        onClick={handleToggleCheck}
        icon={`log-${
          dashboardStudentData?.getStudentOnDashboardByIds ? "in" : "out"
        }`}
        disabled={
          dashboardStudentDataLoading ||
          dashboardStudentData?.getStudentOnDashboardByIds
        }
      >
        CHECK
        {dashboardStudentData?.getStudentOnDashboardByIds && " OUT"}
        {!dashboardStudentData?.getStudentOnDashboardByIds && " IN"}
      </Button>
    </Dimmer>
  );
};

export default CheckInOutButton;
