import React from 'react';
import Modal from '../../Modal';

interface ILoadJumpsProps {
  isOpen: boolean;
  toggleModal: () => void;
  jumps: string[];
}

const LoadJumps = ({ jumps, toggleModal, isOpen }: ILoadJumpsProps) => {
  return (
    <Modal
      open={isOpen}
      title="Jumps"
      onClose={toggleModal}
      content={
        <div>
          {jumps.map((jump, index) => (
            <div key={index}>{jump}</div>
          ))}
        </div>
      }
    />
  );
};

export default LoadJumps;
