import { useQuery } from "@apollo/client";
import React, { useEffect } from "react";
import AsyncSelect from "react-select/async";
import { GET_LOCATION_BY_IDS } from "../../../graphql/GET_LOCATION_BY_IDS";
import { GET_LOCATIONS_BY_FILTER_QUERY } from "../../../graphql/GET_LOCATIONS_BY_FILTER_QUERY";
import { GET_TEAM_LOCATIONS } from "../../../graphql/GET_TEAM_LOCATIONS";
import { useAutocomplete } from "../../../hooks/useAutocomplete";
import autoCompleteStyles from "../../../modules/common/styles/autocompleteStyles";
import { Types } from "../../../types/graphql";

const TeamLocationsSelect = ({
  teamId,
  setSelectedLocations,
  selectedLocations,
}) => {
  const [locations, setLocations] = React.useState<Types.Location[]>([]);

  const { data: teamLocationsData, loading: teamLocationsLoading } = useQuery(
    GET_TEAM_LOCATIONS,
    {
      variables: { teamId },
    },
  );

  useEffect(() => {
    if (!teamLocationsLoading && teamLocationsData) {
      setSelectedLocations(
        teamLocationsData.getTeamLocations.map((location) => {
          return Number(location.id);
        }),
      );
    }
  }, [teamLocationsData, teamLocationsLoading]);
  const { loadOptions } = useAutocomplete({
    query: GET_LOCATIONS_BY_FILTER_QUERY,
    minInputLength: 1,
  });

  const { data, loading, refetch } = useQuery(GET_LOCATION_BY_IDS, {
    variables: { ids: selectedLocations },
  });

  useEffect(() => {
    if (!loading && data) {
      setLocations(data.getByIds);
    } else setLocations([]);
  }, [data, loading]);

  const changeTeamLocationsHandle = (
    selectedLocationsValue: Types.Location[],
  ) => {
    if (selectedLocationsValue) {
      const selected: number[] = selectedLocationsValue.map(
        (location: Types.Location) => {
          return Number(location.id);
        },
      );
      setSelectedLocations(selected);
      refetch({ ids: selected });
    } else {
      setSelectedLocations([]);
    }
  };

  return (
    <AsyncSelect
      isMulti
      Nuser
      backspaceRemovesValue={true}
      components={{
        DropdownIndicator: () => null,
        IndicatorSeparator: () => null,
        LoadingIndicator: () => null,
      }}
      escapeClearsValue={true}
      getOptionLabel={(option: Types.Location) => option.name}
      getOptionValue={(option: Types.Location) => option.id}
      isClearable={true}
      loadOptions={loadOptions}
      onChange={changeTeamLocationsHandle}
      placeholder={"Search locations..."}
      styles={autoCompleteStyles}
      value={locations}
    />
  );
};

export default TeamLocationsSelect;
