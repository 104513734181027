import React from 'react';
import { Link } from 'react-router-dom';
import Moment from 'react-moment';
import moment from 'moment/moment';
import { EUserEvents } from './UserEvents';
import { Grid, Text } from 'tabler-react';
import { useRootStore } from 'hooks';
import { EUserTypes } from 'components/User/userTypes';

const UserEventContent = (userEvent) => {
  const {
    camp_id,
    camp_name,
    created_on,
    first_name,
    last_name,
    slug,
    status,
    student_id,
    user_duration,
    user_registration_id,
    invoice_id
  } = userEvent.event;

  const rootStore = useRootStore();
  const { currentUser } = rootStore;

  return (
    <>
      <Grid.Row className="mb-3">
        <Grid.Col lg={8}>
          <strong>
            {first_name && last_name ? `${first_name} ${last_name}` : status}
          </strong>{' '}
          {EUserEvents[slug]}
          <strong>{user_registration_id && ' ' + status} </strong>
          {invoice_id && (
            <>
              {' '}
              -{' '}
              <Link to={`/user/invoices/${invoice_id}`}>
                Invoice {invoice_id}
              </Link>
            </>
          )}
          {camp_id && (
            <>
              {user_duration && (
                <>
                  {' '}
                  - <strong>{user_duration} minutes</strong>
                </>
              )}{' '}
              <Link to={`/user/calendar/${camp_id}`}>{camp_name}</Link>
              {currentUser?.type === EUserTypes.admin && (
                <span>
                  {' | '}
                  <Link
                    to={`/events/registrations/${user_registration_id}/${student_id}`}
                  >
                    View {first_name}'s Registration
                  </Link>
                </span>
              )}
            </>
          )}
          <div>
            <small>
              <i>
                <Moment fromNow>{moment(created_on).format()}</Moment>
              </i>
            </small>
          </div>
        </Grid.Col>
        <Grid.Col lg={4}>
          <Text className="ml-auto float-right">
            {moment(created_on).format('M/D/YY - h:mma')}
          </Text>
        </Grid.Col>
      </Grid.Row>
    </>
  );
};

export default UserEventContent;
