import gql from 'graphql-tag';

export const GET_USER_POSTS = gql`
  query GET_USER_POSTS($userPostOptions: UserPostOptions!) {
    userPosts(userPostOptions: $userPostOptions) {
      id
      student_id
      coach_id
      camp_id
      content
      is_public
      created_on
      coach {
        first_name
        last_name
      }
      student {
        first_name
        last_name
      }
    }
  }
`;
