import React, { useEffect, useMemo, useState } from 'react';
import { useRootStore } from '../../../hooks';
import { useQuery } from '@apollo/client';
import { TEAM_DETAILS_QUERY } from '../../../graphql/TEAM_DETAILS_QUERY';
import Select from 'react-select';
import { GET_SEALS } from '../../../graphql/GET_SEALS';
import formatSeal from '../../../utils/formatSeal';

interface SealSearchFormProps {
  setValue: any;
  defaultValue: any;
}

const SealSelectForm = ({ setValue, defaultValue }: SealSearchFormProps) => {
  const { currentCoachTeam } = useRootStore();
  const [currentTeamCoaches, setCurrentTeamCoaches] = useState([]);
  const [currentSeal, setCurrentSeal] = useState(defaultValue);
  const [sealError] = useState({ error: false, message: '' });

  const { data: dataCoaches } = useQuery(TEAM_DETAILS_QUERY, {
    variables: {
      team_id: currentCoachTeam?.id ?? 0
    }
  });

  useEffect(() => {
    if (dataCoaches?.geCoachesByTeamId) {
      setCurrentTeamCoaches(
        dataCoaches.geCoachesByTeamId
          .map((coach) => coach.is_active && coach.id)
          .filter(Boolean)
      );
    }
  }, [dataCoaches]);

  const { data } = useQuery(GET_SEALS);
  const sealList = useMemo(() => {
    return data?.getSealList
      .filter((seal) => currentTeamCoaches.includes(seal.coach_id))
      .sort((a, b) => {
        if (a.coach.first_name < b.coach.first_name) {
          return -1;
        }
        if (a.coach.first_name > b.coach.first_name) {
          return 1;
        }
        return 0;
      });
  }, [data, currentTeamCoaches]);

  return (
    <>
      <Select
        name="user_rigger_seal_id"
        placeholder="Select seal"
        isMulti={false}
        getOptionLabel={(option) => (
          <>
            {option.coach?.first_name} {option.coach?.last_name}
            <span className="text-muted">
              {' '}
              {formatSeal(option.rigger_seal)}
            </span>
          </>
        )}
        getOptionValue={(option) => option.id}
        options={sealList}
        value={currentSeal}
        onChange={(e) => {
          setValue('user_rigger_seal_id', e.id);
          setCurrentSeal(e);
        }}
      />
      <span className="field-error text-danger">
        {sealError.error && sealError.message}
      </span>
    </>
  );
};

export default SealSelectForm;
