import gql from 'graphql-tag';

export const GET_TEAM_PROGRESSIONS = gql`
  query GET_TEAM_PROGRESSIONS($teamId: Int) {
    getTeamProgressions(teamId: $teamId) {
      id
      heading
      title
      is_disabled
      subheading
      video_url
      team_id
    }


  }
`;
