import { DateSelectArg, EventChangeArg } from '@fullcalendar/react';
import { values } from 'mobx';
import { cast, Instance, SnapshotIn, types } from 'mobx-state-tree';

export enum EEventStatus {
  event = 'Event',
  registrations = 'Registrations'
}

const RegisteredStudents = types.model({
  id: types.optional(types.string, '', [null]),
  first_name: types.optional(types.string, '', [null]),
  last_name: types.optional(types.string, '', [null]),
  registration_code: types.optional(types.string, '', [null]),
  user_registrations_id: types.optional(types.integer, 0, [null, undefined])
});

const Event = types
  .model({
    allDay: types.optional(types.boolean, false, [null, undefined]),
    auto_approval: types.optional(types.boolean, false, [null]),
    camp_description: types.optional(types.string, '', [null]),
    camp_email: types.optional(types.string, '', [null]),
    camp_name: types.optional(types.string, '', [null]),
    camp_image: types.optional(types.string, '', [null, undefined]),
    camp_phone: types.optional(types.string, '', [null]),
    camp_url: types.optional(types.string, '', [null]),
    camp_fees: types.optional(types.number, 0, [null]),
    end: types.optional(types.string, '', [null]),
    event_type_name: types.optional(types.string, '', [null]),
    id: types.string,
    is_public: types.optional(types.boolean, false, [null]),
    is_published: types.optional(types.boolean, false, [null, undefined]),
    location: types.optional(types.string, '', [null]),
    title: types.optional(types.string, '', [null, undefined]),
    start: types.optional(types.string, '', [null]),
    status: types.optional(types.string, '', [null]),
    location_id: types.optional(types.number, 0, [null]),
    registered_students: types.optional(types.array(RegisteredStudents), []),
    team_name: types.optional(types.string, '', [null, undefined]),
    student_slots: types.optional(types.boolean, false, [null, undefined]),
    students_per_slot: types.optional(types.number, 0, [null, undefined])
  })
  .actions((self) => ({
    set<
      K extends keyof SnapshotIn<typeof self>,
      T extends SnapshotIn<typeof self>
    >(key: K, value: T) {
      self[key] = cast(value);
    }
  }));

const EventTypes = types
  .model({
    id: types.optional(types.number, 0, [null, undefined]),
    name: types.optional(types.string, '', [null, undefined])
  })
  .actions((self) => ({
    set<
      K extends keyof SnapshotIn<typeof self>,
      T extends SnapshotIn<typeof self>
    >(key: K, value: T) {
      self[key] = cast(value);
    }
  }));

const EventEntry = types
  .model({
    all_day: types.optional(types.boolean, false),
    camp_name: types.optional(types.string, ''),
    camp_description: types.optional(types.string, ''),
    end_date: types.optional(types.string, ''),
    end_time: types.optional(types.string, '17:00'),
    event_type_id: types.maybeNull(types.number),
    location_id: types.maybeNull(types.number),
    sport_type_id: types.maybeNull(types.number),
    start_date: types.optional(types.string, ''),
    start_time: types.optional(types.string, '09:00'),
    status: types.optional(types.string, EEventStatus.registrations)
  })
  .actions((self) => ({
    set<
      K extends keyof SnapshotIn<typeof self>,
      T extends SnapshotIn<typeof self>
    >(key: K, value: T) {
      self[key] = cast(value);
    }
  }));

const EventEntryActivities = types
  .model({
    sport_type_id: types.optional(types.number, 0, [null, undefined]),
    name: types.optional(types.string, '', [null, undefined]),
    sub: types.optional(types.number, 0, [null, undefined])
  })
  .actions((self) => ({
    set<
      K extends keyof SnapshotIn<typeof self>,
      T extends SnapshotIn<typeof self>
    >(key: K, value: T) {
      self[key] = cast(value);
    }
  }));

const EventEntryLocations = types
  .model({
    id: types.optional(types.string, '', [null, undefined]),
    name: types.optional(types.string, '', [null, undefined])
  })
  .actions((self) => ({
    set<
      K extends keyof SnapshotIn<typeof self>,
      T extends SnapshotIn<typeof self>
    >(key: K, value: T) {
      self[key] = cast(value);
    }
  }));

export const EventStoreDefault = () => {
  return EventStore.create({
    events: undefined
  });
};

export const EventStore = types
  .model({
    events: types.optional(types.array(Event), []),
    eventEntry: types.optional(EventEntry, {}),
    eventEntryActivities: types.optional(types.array(EventEntryActivities), []),
    eventEntryLocations: types.optional(types.array(EventEntryLocations), []),
    eventTypes: types.optional(types.array(EventTypes), [])
  })
  .views((self) => ({
    getEventById(eventId: string) {
      return values(self.events).find((event) => event.id === eventId);
    }
  }))
  .actions((self) => ({
    addEvent(newEvent) {
      self.events.push(newEvent);
    },
    changeEvent(changeInfo: EventChangeArg) {
      const newEvent = changeInfo.event;
      const storedEvent = self.events.find((e) => e.id === changeInfo.event.id);
      if (storedEvent) {
        storedEvent.title = newEvent.title;
        storedEvent.start =
          newEvent.start.toString() || storedEvent.start.toString();
        storedEvent.end = newEvent.end.toString() || storedEvent.end.toString();
      }
    },
    deleteEvent(id: string) {
      self.events.splice(
        self.events.findIndex((e) => e.id === id),
        1
      );
    },
    initEventEntry(selectInfo: DateSelectArg) {
      self.eventEntry = EventEntry.create({
        start_date: selectInfo.startStr,
        end_date: selectInfo.endStr
      });
    },
    resetEventEntry() {
      self.eventEntry = EventEntry.create();
      self.eventEntryLocations.replace([]);
    }
  }));

export interface EventStoreType extends Instance<typeof EventStore> {}
