import gql from "graphql-tag";

export const GET_CAMP_DISCIPLINES = gql`
  query GET_CAMP_DISCIPLINES($campId: ID) {
    campDisciplines(campId: $campId) {
      disciplineId
      slug
      name
      isExists
    }
  }
`;
