import { useLazyQuery, useMutation } from "@apollo/client";
import { Formik, FormikValues } from "formik";
import debounce from "lodash.debounce";
import React, { useContext } from "react";
import PhoneInput from "react-phone-input-2";
import { toast } from "react-toastify";
import { Button, Grid, Header, Icon, List, Text } from "tabler-react";
import * as Yup from "yup";
import { UserStoreContext } from "../../../contexts/userStoreContext";
import { ACCOUNT_BALANCE_TRANSACTION } from "../../../graphql/ACCOUNT_BALANCE_TRANSACTION";
import { ADD_STUDENT } from "../../../graphql/ADD_STUDENT";
import { GET_EMAIL_VALIDATION } from "../../../graphql/GET_EMAIL_VALIDATION";
import { GET_PHONE_VALIDATION } from "../../../graphql/GET_PHONE_VALIDATION";
import { useRootStore } from "../../../hooks";
import { TransactionTypeId } from "../../../modules/account-balance/forms/AccountBalanceForm";
import useGetStudentByName from "../../../modules/student/hooks/useGetStudentByName";
import FormField from "../../FormField";
import StudentLink from "../StudentLink";

const addStudentValidationSchema = Yup.object().shape({
  first_name: Yup.string().required("This field is required."),
  last_name: Yup.string().required("This field is required."),
  email: Yup.string()
    .email()
    .when("require_contact", {
      is: false,
      then: Yup.string().required("This field is required."),
    }),
  phone_number: Yup.string().when("require_contact", {
    is: false,
    then: Yup.string().required("This field is required."),
  }),
  require_contact: Yup.boolean(),
});

interface AddStudentFormProps {
  handleClose?: () => void;
}

const AddStudentForm = ({ handleClose }: AddStudentFormProps) => {
  const userStore = useContext(UserStoreContext);
  const rootStore = useRootStore();
  const { currentCoachTeam, currentUser } = rootStore;
  const { user } = userStore;

  const [addStudent] = useMutation(ADD_STUDENT, {
    refetchQueries: ["GET_STUDENTS_BY_FILTER_QUERY"],
  });
  const [accountBalanceTransaction] = useMutation(ACCOUNT_BALANCE_TRANSACTION);

  const { data, loading, setName, name } = useGetStudentByName();

  const [validateEmail, { data: emailData }] = useLazyQuery(
    GET_EMAIL_VALIDATION,
    { fetchPolicy: "network-only" },
  );

  const [validatePhone, { data: phoneData }] = useLazyQuery(
    GET_PHONE_VALIDATION,
    { fetchPolicy: "network-only" },
  );

  const handleValidateEmail = async (email) => {
    if (email === "") return;

    validateEmail({
      variables: { email },
    });
  };

  const handleValidatePhone = async (phone) => {
    if (phone.length <= 1) return;
    return validatePhone({
      variables: { phone },
    });
  };

  const handleValidateName = debounce((fname, lname) => {
    if (!fname || !lname) return;

    setName({ ...name, firstName: fname, lastName: lname });
  });

  const routeToStudent = (student) => {
    userStore.detach(user);
    userStore.loadUser(student);
    const win = window.open("/user-details", "_blank");
    win.focus();
  };

  const renderForm = ({
    values,
    handleChange,
    setFieldValue,
    handleSubmit,
    isSubmitting,
    errors,
    touched,
  }: FormikValues) => {
    return (
      <Grid.Row>
        <Grid.Col>
          <form
            className="p-6 card Create-Account"
            method="POST"
            onSubmit={handleSubmit}
          >
            <input
              type={"hidden"}
              name={"require_contact"}
              value={values.require_contact}
            />
            <FormField
              type="text"
              label="First Name*"
              placeholder="First name"
              name="first_name"
              onChange={(e) => {
                setFieldValue("first_name", e.target.value);
              }}
              onBlur={(e) => {
                handleValidateName(e.target.value, values.last_name);
              }}
              value={values.first_name}
            />
            <FormField
              type="text"
              label="Last Name*"
              placeholder="Last name"
              name="last_name"
              onChange={(e) => {
                setFieldValue("last_name", e.target.value);
              }}
              onBlur={(e) => {
                handleValidateName(values.first_name, e.target.value);
              }}
              value={values.last_name}
            />
            <div className={"form-group"}>
              <label className="form-label">Phone (optional)</label>
              <PhoneInput
                inputProps={{
                  className: "form-control w-100",
                  name: "phone_number",
                  onChange: (e) => {
                    e.preventDefault();
                    const target = e.target;
                    const phone = target.value.split(" ");
                    const countryCode = phone.shift().replace(/\D/g, "");
                    setFieldValue(
                      "phone_number",
                      phone.join("").replace(/\D/g, ""),
                    );
                    setFieldValue("phone_country_code", Number(countryCode));
                  },
                  onBlur: async (e) => {
                    e.preventDefault();
                    await handleValidatePhone(
                      e.target.value.split(" ").join("").replace(/\D/g, ""),
                    );
                  },
                }}
                value={`${values.phone_country_code}${values.phone_number}`}
              />
              <span className="field-error text-danger">
                {errors.phone_number &&
                  touched.phone_number &&
                  errors.phone_number}
              </span>
            </div>
            <FormField
              type="email"
              label="Email (optional)"
              placeholder="Email"
              name="email"
              onBlur={(e) => handleValidateEmail(e.target.value)}
              onChange={handleChange}
              value={values.email}
            />

            <FormField
              type="text"
              label="Initial Balance"
            //  placeholder=""
              name="current_balance"
              onChange={handleChange}
              value={values.current_balance}
              appendright={
                <Button
                  type="submit"
                  icon="user-plus"
                  color={"primary"}
                  className={`ml-3 ${isSubmitting && "btn-loading"}`}
                  disabled={isSubmitting}
                >
                  Create Account
                </Button>
              }
            />
          </form>
        </Grid.Col>
        {data && data.length > 0 && !loading && (
          <Grid.Col>
            <Header.H4>Possible duplicates</Header.H4>
            <List.Group>
              {data.map((student) => (
                <List.GroupItem key={student.id}>
                  <>
                    <Icon
                      name={`${
                        student.registration_id ? "user-check" : "user-x"
                      }`}
                      className={`${
                        student.registration_id
                          ? "text-success"
                          : "text-secondary"
                      } mr-1`}
                    />
                    <StudentLink
                      studentName={`${student?.first_name} ${student?.last_name}`}
                      studentId={student.id}
                      routeToStudent={() => routeToStudent(student)}
                    />{" "}
                    - <Text.Small>{student.id}</Text.Small>
                    {student.email && (
                      <Icon
                        name={"mail"}
                        className={"ml-1 float-right text-success"}
                      />
                    )}
                    {student.phone_number && (
                      <Icon
                        name={"phone"}
                        className={"ml-1 float-right  text-success"}
                      />
                    )}
                  </>
                </List.GroupItem>
              ))}
            </List.Group>
          </Grid.Col>
        )}
      </Grid.Row>
    );
  };

  return (
    <>
      <Formik
        initialValues={{
          first_name: "",
          last_name: "",
          email: "",
          phone_number: "",
          phone_country_code: 1,
          team_id: currentCoachTeam.id,
          current_balance: "",
          require_contact: currentCoachTeam.feature_open_add_students,
        }}
        validationSchema={addStudentValidationSchema}
        onSubmit={async (
          values,
          { setSubmitting, resetForm, setFieldError },
        ) => {
          let errorFound = false;

          if (emailData?.validateEmail) {
            setFieldError(
              "email",
              "Email already exists. Login or reset password. Contact SUPPORT if you need further assistance.",
            );
            errorFound = true;
          }

          if (phoneData?.validatePhone) {
            setFieldError(
              "phone_number",
              "Phone number already exists. Contact SUPPORT.",
            );
            errorFound = true;
          }

          if (errorFound) {
            setSubmitting(false);
            return false;
          }

          const { current_balance, require_contact, ...rest } = values;

          const newStudent = await addStudent({
            variables: {
              student: {
                ...rest,
              },
            },
          });

          if (newStudent?.data.addStudent.id) {
            //Add balance
            await accountBalanceTransaction({
              variables: {
                payload: {
                  account_type_id: currentCoachTeam.account_type_id,
                  created_by: currentUser.id,
                  minutes: Number(current_balance),
                  student_id: newStudent?.data.addStudent.id,
                  team_id: currentCoachTeam.id,
                  transaction_type_id: TransactionTypeId.CREDIT,
                },
              },
            });

            userStore.loadUser(newStudent?.data.addStudent);
            toast.success("User account created!");
            setSubmitting(false);
            resetForm();
            if (handleClose) {
              handleClose();
            }
          }
        }}
      >
        {(formikData) => renderForm(formikData)}
      </Formik>
    </>
  );
};

export default AddStudentForm;
