import gql from 'graphql-tag';

export const GET_REGISTRATION_REQUIREMENTS = gql`
  query GET_REGISTRATION_REQUIREMENTS($filter: RegistrationRequirementFilter) {
    registrationRequirements(filter: $filter) {
      id
      name
      description
      camp_id
      location_id
      team_id
      registration_requirement_id
      requirement_type_name
      requirement_type_slug
      sport_type_id
      config
      registration_id
      is_exist
    }
  }
`;



