import gql from 'graphql-tag';

export const GET_STAR_LOCATION = gql`
  query GET_STAR_LOCATION(
    $student_id: Int,
    $location_id: Int
  ) {
    getStudentStarLocation(student_id: $student_id, location_id: $location_id) {
      id
      student_id
      flag_type_id
      user_location_id
      name
    }
  }
`;
