import Wrapper from 'pages/layout'

const EventControllerPage = (props) => {
  return (
    <Wrapper {...props} title="Event Controller">
      <div className="container">{/* <EventController /> */}</div>
    </Wrapper>
  )
}

export default EventControllerPage
