import gql from "graphql-tag";

export const UPDATE_NOTIFICATION_EMAIL = gql`
  mutation UPDATE_NOTIFICATION_EMAIL(
    $updateCampNotificationEmailInput: UpdateCampNotificationEmailInput!
  ) {
    updateCampNotificationEmail(
      updateCampNotificationEmailInput: $updateCampNotificationEmailInput
    )
  }
`;
