import React from 'react';
import { Form } from 'tabler-react';
import GearManufacturerImageUpload from './GearManufacturerImageUpload';
import { useRootStore } from '../../../hooks';
import { Types } from '../../../types/graphql'

interface ManufacturerListItemEditImageProps {
  manufacturer: Types.GearManufacturer;
  updateImage: (image: string | null) => void;
}

const ManufacturerListItemEditImage = ({
  manufacturer,
  updateImage
}: ManufacturerListItemEditImageProps) => {
  const rootStore = useRootStore();

  return (
    <Form.InputGroup>
      <GearManufacturerImageUpload
        rootStore={rootStore}
        setImage={updateImage}
        manufacturerId={manufacturer.id.toString()}
      />
    </Form.InputGroup>
  );
};

export default ManufacturerListItemEditImage;
