import gql from 'graphql-tag';

export const GET_EVENT_ORGANIZERS = gql`
  query GET_EVENT_ORGANIZERS($params: EventOrganizerInput) {
    getEventOrganizers(params: $params) {
      id
      coach_id
      coach_first_name
      coach_last_name
      coach_email
      coach_email_private
      coach_phone
      coach_phone_country_code
      coach_phone_private
      coach_profile_avatar
    }
  }
`;
