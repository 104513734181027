import React from "react";

import Modal from "../../Modal";
import AddLoad from "./AddLoad";

interface IAddLoadModalProps {
  isModalOpen: boolean;
  toggleModal: () => void;
  load?: any;
}

const AddLoadModal = ({
  isModalOpen,
  toggleModal,
  load,
}: IAddLoadModalProps) => {
  return (
    <Modal
      content={<AddLoad toggleModal={toggleModal} load={load} />}
      open={isModalOpen}
      title="Load Management"
      onClose={toggleModal}
    />
  );
};

export default AddLoadModal;
