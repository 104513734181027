import React from 'react';
import Modal from '../../Modal';
import { Formik } from 'formik';
import FormField from '../../FormField';
import { Button, Form, Grid, Header, Tag } from 'tabler-react';
import Select from 'react-select';
import { useMutation, useQuery } from '@apollo/client';
import { toast } from 'react-toastify';
import { GET_ALL_GEAR_TYPES } from '../../../graphql/GET_ALL_GEAR_TYPES';
import { GET_ALL_GEAR_MANUFACTURERS } from '../../../graphql/GET_ALL_GEAR_MANUFACTURERS';
import { ADD_OR_EDIT_GEAR_MODEL } from '../../../graphql/ADD_OR_EDIT_GEAR_MODEL';
import { Types } from '../../../types/graphql'

interface GearModelModalFormProps {
  isModalOpen: boolean;
  toggleModal: any;
  gearModel: any;
}

const GearModelModalForm = ({
                              isModalOpen,
                              toggleModal,
                              gearModel
                            }: GearModelModalFormProps) => {
  const [updateGearModel] = useMutation(ADD_OR_EDIT_GEAR_MODEL);

  const { data: typeData } = useQuery(GET_ALL_GEAR_TYPES);
  const { data: manufacturerData } = useQuery(GET_ALL_GEAR_MANUFACTURERS);

  const handleClose = () => {
    toggleModal();
  };

    const renderForm = ({
      values,
      handleSubmit,
      isSubmitting,
      handleChange,
      setFieldValue,
      errors,
      touched
    }) => (
      <form onSubmit={handleSubmit}>
        <Grid.Row>
          <Grid.Col width={6}>
            <Form.Group label="Name">
              <Form.InputGroup>
                <FormField
                  appendleft={
                    <Form.InputGroupPrepend>
                      <Tag>
                        <Header.H5 className="m-4">{gearModel.id}</Header.H5>
                      </Tag>
                    </Form.InputGroupPrepend>
                  }
                  type="text"
                  name="name"
                  onChange={handleChange}
                  value={values.name}
                />
              </Form.InputGroup>
            </Form.Group>
          </Grid.Col>
        </Grid.Row>

        <Grid.Row className="mb-4">
          <Grid.Col width={6}>
            <Form.Group label="Type">
              <Select
                name="gear_type_id"
                isMulti={false}
                styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
                menuPortalTarget={document.body}
                getOptionLabel={(option: Types.GearType) =>
                  option.name
                }
                getOptionValue={(option: Types.GearType) =>
                  option.id
                }
                options={typeData?.getGearTypes}
                onChange={(param) => {
                  setFieldValue('gear_type_id', param.id);
                }}
                value={typeData?.getGearTypes.filter(
                  (gearType) => gearType.id === values?.gear_type_id
                )}
              />
              <span className="field-error text-danger">
                {errors.gear_model_id &&
                  touched.gear_model_id &&
                  errors.gear_model_id}
              </span>
            </Form.Group>
          </Grid.Col>
          <Grid.Col width={6}>
            <Form.Group label="Manufacturer">
              <Select
                name="gear_manufacturer_id"
                isMulti={false}
                styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
                menuPortalTarget={document.body}
                getOptionLabel={(
                  option: Types.GearManufacturer
                ) => option.name}
                getOptionValue={(
                  option: Types.GearManufacturer
                ) => option.id}
                options={manufacturerData?.getGearManufacturers}
                onChange={(param) =>
                  setFieldValue('gear_manufacturer_id', param.id)
                }
                value={manufacturerData?.getGearManufacturers.filter(
                  (gearManufacturer) =>
                    gearManufacturer.id === values?.gear_manufacturer_id
                )}
              />
              <span className="field-error text-danger">
                {errors.gear_model_id &&
                  touched.gear_model_id &&
                  errors.gear_model_id}
              </span>
            </Form.Group>
          </Grid.Col>
        </Grid.Row>

        <Button
          type="submit"
          disabled={isSubmitting}
          pill
          className="btn-gray-dark float-right btn-sm"
        >
          {isSubmitting ? 'Processing...' : 'SUBMIT'}
        </Button>

        <Button
          pill
          color="white"
          className="float-right btn-sm"
          onClick={(e) => {
            e.preventDefault();
            handleClose();
          }}
        >
          CANCEL
        </Button>
      </form>
    );

  return (
    <Modal
      content={
        <Formik
          enableReinitialize={true}
          initialValues={{
            id: gearModel?.id ?? null,
            name: gearModel?.name ?? '',
            gear_type_id: gearModel?.gear_type_id ?? '',
            gear_manufacturer_id: gearModel?.gear_manufacturer_id ?? ''
          }}
          onSubmit={async (values, { resetForm, setSubmitting }) => {

            const isUpdated = await updateGearModel({
              variables: {
                updateGearModelInput: {
                  id: values?.id,
                  name: values?.name,
                  gear_type_id: values?.gear_type_id,
                  gear_manufacturer_id: values?.gear_manufacturer_id
                }
              },
              refetchQueries: ['GET_ALL_GEAR_MODELS']
            });

            if (!isUpdated.errors) {
              handleClose();
              toast.success('Gear model updated.');
              setSubmitting(false);
              resetForm();
            } else {
              toast.error('Update failed');
            }

          }}
        >
          {(formikData) => renderForm(formikData)}
        </Formik>
      }
      open={isModalOpen}
      onClose={() => toggleModal()}
    />
  );
};

export default GearModelModalForm;
