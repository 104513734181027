import gql from 'graphql-tag';

export const GET_USER_EVENT_BY_STUDENT_AND_TYPE = gql`
  query GET_USER_EVENT_BY_STUDENT_AND_TYPE(
    $studentId: Int!
    $userEventTypeId: Int!
  ) {
    getUserEventByStudentAndType(
      studentId: $studentId
      userEventTypeId: $userEventTypeId
    ) {
      user_events_id
      student_id
      created_on
      camp_id
      camp_name
      first_name
    }
  }
`;
