import gql from 'graphql-tag';

export const GET_STUDENT_GEARS = gql`
  query GET_STUDENT_GEARS($getUserGearOptions: GetUserGearOptions!) {
    getUserGears(getUserGearOptions: $getUserGearOptions) {
      id
      name
      student_id
      team_id
      gear_dom
      gear_canopy_size
      gear_serial
      gear_notes
      status
      student {
        id
        first_name
        last_name
      }
      team {
        id
        name
      }
      gear_model {
        id
        name
        gear_type_id
        gear_manufacturer_id
        gear_type_name
        gear_manufacturer_name
      }
      rig {
        id
        name
      }
      repack {
        id
        event_date
        reserve_notes
      }
    }
  }
`;
