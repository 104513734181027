import { useMutation } from '@apollo/client';
import React from 'react';
import { toast } from 'react-toastify';
import { Button, Table } from 'tabler-react';
import { formatMoney } from 'utils/numberFormat';
import { GET_INVOICE } from '../../graphql/GET_INVOICE';
import { UPDATE_INVOICE } from '../../graphql/UPDATE_INVOICE';
import { useRootStore } from '../../hooks';
import { EUserTypes } from '../User/userTypes';
import { DELETE_INVOICE_PRODUCT } from '../../graphql/DELETE_INVOICE_PRODUCT';
import { GET_INVOICE_ITEMS } from '../../graphql/GET_INVOICE_ITEMS';

interface IInvoiceDetailProductItemProps {
  product: any;//Types.Job;
  invoice_amount: number;
  invoice_id: number;
}

const InvoiceDetailProductItem = ({
                                    product,
                                    invoice_amount,
                                    invoice_id
                                  }: IInvoiceDetailProductItemProps) => {
  const [updateInvoice] = useMutation(UPDATE_INVOICE);
  const [deleteInvoiceProduct] = useMutation(DELETE_INVOICE_PRODUCT, {
    onCompleted: async () => {
      await updateInvoice({
        variables: {
          updateInvoiceInput: {
            id: invoice_id,
            invoice_amount: invoice_amount - (product.amount)
          }
        },
        refetchQueries: [
          {
            query: GET_INVOICE,
            variables: {
              id: invoice_id
            }
          }
        ]
      });
    }
  });
  const rootStore = useRootStore();
  const { currentUser } = rootStore;

  const statusHandleClick = async () => {
    if (!window.confirm('Remove item from this invoice?')) {
      return false;
    }

    await deleteInvoiceProduct({
      variables: {
        productId: product.id,
        invoiceId: invoice_id
      }, refetchQueries: [
        {
          query: GET_INVOICE_ITEMS, variables: {
            invoice_id: invoice_id
          }
        }
      ]
    });
    toast.success(`Item removed!`);
  };

  return (
    <Table.Row>
      <Table.Col>
        {product.title}
      </Table.Col>
      <Table.Col></Table.Col>
      <Table.Col className='text-center'>{formatMoney(product.amount)}</Table.Col>
      <Table.Col className='text-center'>{1}</Table.Col>
      <Table.Col className='text-right'>
        {formatMoney(product.amount)}
        {currentUser.type !== EUserTypes.student && (
          <Button
            icon='x'
            color='white'
            size='sm'
            className={`ml-3 pt-0 text-muted`}
            onClick={() => statusHandleClick()}
          />
        )}
      </Table.Col>
    </Table.Row>
  );
};

export default InvoiceDetailProductItem;
