import gql from 'graphql-tag';

export const GET_COACH_STUDENTS = gql`
  query GET_COACH_STUDENTS($coach_id: Int!) {
    getCoachStudents(coach_id: $coach_id) {
      student_id
      coach_id
      student {
        id
        first_name
        last_name
        profile_avatar
        address1
        address2
        city
        country
        dob
        email
        jump_weight
        phone_country_code
        phone_number
        postal_code
        province
        registration_id
        weight

        auth_merits {
          member_id
        }

        uspa {
          first_name
          last_name
          email
          updated_on
          merits_uspa_member_id
          merits_uspa_member_expiration
          template_id
          template_title
        }
      }
    }
  }
`;
