import React from 'react';
import { Link } from 'react-router-dom';
import { Grid, StampCard } from 'tabler-react';

import { useRootStore } from '../../hooks/useRootStore';
import formatNumber from '../../utils/numberFormat';
import { observer } from 'mobx-react';

const RegistrationsTotal = () => {
  const rootStore = useRootStore();
  const { campTotal, registrationsTotal } = rootStore;

  return (
    <Grid.Col sm={6} lg={3} xs={12} className="mb-0">
      <StampCard
        color="orange"
        icon="calendar"
        header={
          <Link to="/admin/event-list">
            {formatNumber(campTotal)} <small>Events</small>
          </Link>
        }
        footer={
          <Link to="/admin/user-registrations">
            {formatNumber(registrationsTotal)} Registrations
          </Link>
        }
      />
    </Grid.Col>
  );
};

export default observer(RegistrationsTotal);
