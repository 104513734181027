import Modal from '../../Modal';
import { Grid } from 'tabler-react';
import React from 'react';
import { getWingLoad } from '../../../utils/numberFormat';
import { Types } from '../../../types/graphql'

interface IMainGearLoadlimitProps {
  isModalOpen: boolean;
  toggleModal: (isModalOpen: boolean) => void;
  gears: Types.UserGear[];
  student: Types.UserRegistrationsById;
  setMainGear: (gear: Types.UserGear) => void;
}

const MainGearLoadLimit = ({ isModalOpen, toggleModal, gears, student, setMainGear }: IMainGearLoadlimitProps) => {
  return (
    <Modal
      content={
        <>
          {gears &&
            gears.map((gear: Types.UserGear) => {
              return (
                <Grid.Row key={gear.id}>
                  <Grid.Col>
                    {gear.gear_model.name} {gear.gear_canopy_size}
                  </Grid.Col>
                  <Grid.Col>
                    {getWingLoad(
                      Number(gear.gear_canopy_size),
                      Number(student?.weight)
                    )}
                  </Grid.Col>
                  <Grid.Col>
                    {/*
                        <Button onClick={() => {
                        setMainGear(gear);
                        toggleModal(!isModalOpen);
                      }} size='sm'>SELECT</Button>
                      */}
                  </Grid.Col>
                </Grid.Row>
              );
            })}
        </>
      }
      open={isModalOpen}
      title={`${student.first_name} ${student.last_name}`}
      onClose={() => toggleModal(!isModalOpen)}
    />
  );
};

export default MainGearLoadLimit;
