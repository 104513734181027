import React from 'react';
import { useRootStore } from 'hooks';
import { Container } from 'tabler-react';
import { useQuery } from '@apollo/client';
import { GET_STUDENT_CAMPS } from '../../graphql/GET_STUDENT_CAMPS';
import StudentEvents from '../../components/Students/StudentEvents';
import Wrapper from '../layout';

const StudentEventsPage = (props) => {
const { currentUser } = useRootStore();
const { data, loading } = useQuery(GET_STUDENT_CAMPS, {
    variables: { getStudentCampsInput: { studentId: currentUser.id } }
  });

  return (
    <Wrapper {...props} title="My Events">
      <Container>
        <StudentEvents
            studentId={currentUser.id}
            camps={data?.getStudentCamps}
            loading={loading}
        />
      </Container>
    </Wrapper>
  );
};

export default StudentEventsPage;
