import gql from "graphql-tag";

/**
 * Get id and name for now
 * We can extend this later
 */

export const GET_TEAM_BY_SLUG = gql`
  query GET_TEAM_BY_SLUG($teamSlug: String!) {
    teamBySlug(teamSlug: $teamSlug) {
      coaches {
        id
        first_name
        last_name
        email
        email_private
        phone_number
        phone_private
        profile_avatar
        is_active
        userRoles {
          role_id
          key
          name
          team_id
          camp_id
        }
      }
      default_location_name
      default_location_id
      default_currency
      invoice_terms
      id
      name
      slug
      team_email
      team_phone
      team_phone_country_code
      team_bio
      team_url
      team_logo_square_image
      team_logo_wide_image
      feature_student_checkin
    }
  }
`;
