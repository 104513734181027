import React from "react";
import { Link } from "react-router-dom";
import { Grid } from "tabler-react";
import logo from "../../assets/images/logo.png";
import StudentClaimAccountForm from "../../components/Students/Forms/StudentClaimAccountForm";
import FreshChatWidget from "../../widgets/FreshChatWidget";

const StudentAccountClaimPage = () => {
  return (
    <>
      <div className="page">
        <div className="page-single">
          <div className="container">
            <div className="row">
              <div className={`col mx-auto`}>
                <div className="text-center mb-6">
                  <Link to="/">
                    <img src={logo} className="w-75" alt="logo" />
                  </Link>
                </div>
                <Grid.Row>
                  <Grid.Col md={3} lg={3} sm={12}></Grid.Col>
                  <Grid.Col md={6} lg={6} sm={12}>
                    <StudentClaimAccountForm />
                  </Grid.Col>
                  <Grid.Col md={3} lg={3} sm={12}></Grid.Col>
                </Grid.Row>
              </div>
            </div>
          </div>
        </div>
      </div>
      <FreshChatWidget />
    </>
  );
};

export default StudentAccountClaimPage;
