import gql from 'graphql-tag';

export const GET_AIRCRAFTS = gql`
  query GET_AIRCRAFTS {
    aircraftsList {
      id
      name
      tail_number
      aircraft_type
      aircraft_notes
      capacity_max
      capacity_min
    }
  }
`;
