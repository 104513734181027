import gql from 'graphql-tag';

export const GET_STRIPE_ACCOUNT_ID_BY_TEAM_ID = gql`
  query GET_STRIPE_ACCOUNT_ID_BY_TEAM_ID($teamId: Int!) {
    getStripeAccountIdByTeamId(teamId: $teamId) {
      id
      stripe_account_id
    }
  }
`;
