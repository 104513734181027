import React, { useContext, useEffect, useMemo } from 'react';
import { useRootStore } from '../../../hooks';
import { useMutation, useQuery } from '@apollo/client';
import { GET_ALL_JUMP_TYPES } from '../../../graphql/GET_ALL_JUMP_TYPES';
import { ADD_LOAD_USER } from '../../../graphql/ADD_LOAD_USER';
import { toast } from 'react-toastify';
import { Formik } from 'formik';
import { Button, Form, Grid } from 'tabler-react';
import { LoadStoreContext } from '../../../contexts/LoadStoreContext';
import moment from 'moment';
import Select from 'react-select';
import Modal from '../../Modal';
import { LOAD_LIST_QUERIES } from '../../../graphql/LOAD_LIST_QUERIES';
import { formatDate } from '../../../utils/dateFormat';
import { applySnapshot } from 'mobx-state-tree';
import { LoadAssocType } from '../../constants';
import * as Yup from 'yup';
import { ADD_USER_EVENT } from '../../../graphql/ADD_USER_EVENT';
import { EUserEventsId } from '../../User/UserEvents';

interface IAddStudentToLoadModalProps {
  toggleModal?: () => void;
  studentId: number;
  isOpen: boolean;
}

const addStudentToLoadValidationSchema = Yup.object().shape({
  load_id: Yup.number().required('This field is required.'),
  association_type_id: Yup.number().required('This field is required.'),
  jump_type_id: Yup.number().required('This field is required.')
});

const AddStudentToLoadModal = ({
  toggleModal,
  studentId,
  isOpen
}: IAddStudentToLoadModalProps) => {
  const { currentCoachTeam } = useRootStore();
  const { data: jumptTypeData, loading: jumptTypeLoading } =
    useQuery(GET_ALL_JUMP_TYPES);

  const { aircrafts, loads } = useContext(LoadStoreContext);
  const [addUserEvent] = useMutation(ADD_USER_EVENT);

  const { loading, data } = useQuery(LOAD_LIST_QUERIES, {
    variables: {
      team_id: currentCoachTeam?.id,
      date: formatDate(new Date())
    }
  });

  useEffect(() => {
    if (!loading && data) {
      applySnapshot(aircrafts, data.teamAircrafts);
      applySnapshot(loads, data.loads);
    }
  }, [data]);

  const currentLoads = useMemo(() => {
    if (loads)
      return loads.filter((load) => {
        return (
          moment(load.load_departure).format('YYYY-MM-DD') ===
            moment().format('YYYY-MM-DD') && !load.load_complete
        );
      });

    return [];
  }, [loads]);

  const jumpTypes = useMemo(() => {
    if (jumptTypeData && !jumptTypeLoading) {
      return jumptTypeData.loadJumpTypes.map((jumpType) => ({
        label: jumpType.name,
        value: jumpType.id
      }));
    }
    return [];
  }, [jumptTypeData, jumptTypeLoading]);

  const [addLoadUser] = useMutation(ADD_LOAD_USER, {
    onCompleted: async () => {
      await addUserEvent({
        variables: {
          userEvent: {
            status: 'student loaded',
            user_event_type_id: EUserEventsId.user_loaded,
            location_id: currentCoachTeam.default_location_id,
            team_id: currentCoachTeam.id,
            student_id: studentId
          }
        }
      });
      toast.success('Student added to load');
    },
    refetchQueries: ['LOAD_LIST_QUERIES']
  });
  const renderForm = ({
    values,
    setFieldValue,
    handleSubmit,
    errors,
    touched
  }) => {
    return (
<>
          <Grid.Row>
            <Grid.Col>
              <Form.Group label={'Load*'}>
                <Select
                  name={'load_id'}
                  styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
                  menuPortalTarget={document.body}
                  getOptionLabel={(option) => option.load_name}
                  getOptionValue={(option) => option.id}
                  placeholder="Select a load"
                  options={currentLoads}
                  onChange={(option) => {
                    setFieldValue('load_id', option.id);
                  }}
                  value={currentLoads.find(
                    (cl: any) => cl.id === values.load_id
                  )}
                />
                <span className="field-error text-danger">
                  {errors.load_id && touched.load_id && errors.load_id}
                </span>
              </Form.Group>
            </Grid.Col>
          </Grid.Row>
          <Grid.Row>
            <Grid.Col>
              <Form.Group label={'Jumper Role*'}>
                <Select
                  name={'association_type_id'}
                  options={LoadAssocType}
                  styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
                  menuPortalTarget={document.body}
                  value={LoadAssocType.find(
                    (lat) => lat.value === values.association_type_id
                  )}
                  onChange={(option) => {
                    setFieldValue('association_type_id', option.value);
                  }}
                />
                <span className="field-error text-danger">
                  {errors.association_type_id &&
                    touched.association_type_id &&
                    errors.association_type_id}
                </span>
              </Form.Group>
            </Grid.Col>
          </Grid.Row>

          <Grid.Row>
            <Grid.Col>
              <Form.Group label={'Jump Type (optional)'}>
                <Select
                  name={'jump_type_id'}
                  styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
                  menuPortalTarget={document.body}
                  options={jumpTypes}
                  value={jumpTypes.find(
                    (jt) => jt.value === values.jump_type_id
                  )}
                  onChange={(option) => {
                    setFieldValue('jump_type_id', option.value);
                  }}
                />
                <span className="field-error text-danger">
                  {errors.jump_type_id &&
                    touched.jump_type_id &&
                    errors.jump_type_id}
                </span>
              </Form.Group>
            </Grid.Col>
          </Grid.Row>
          <Button.List align="right">
            <Button
              pill
              icon={'cloud'}
              color={'primary'}
              size={'sm'}
              className={'float-right'}
              onClick={handleSubmit}
            >
              BLUE SKIES
            </Button>
            <Button
              pill
              color={'white'}
              size={'sm'}
              className={'float-right'}
              onClick={toggleModal}
            >
              CANCEL
            </Button>
          </Button.List>
        </>
    );
  };
  return (
    <Modal
      content={
        <Formik
          validationSchema={addStudentToLoadValidationSchema}
          enableReinitialize={true}
          initialValues={{
            student_id: studentId,
            association_type_id: '',
            load_id: '',
            jump_type_id: '',
            sequence: 0,
            team_id: currentCoachTeam?.id
          }}
          onSubmit={(values) => {
            addLoadUser({ variables: { loadAssociatedUser: values } });
            if (toggleModal) {
              toggleModal()
            }
          }}
        >
          {(formaData) => renderForm(formaData)}
        </Formik>
      }
      open={isOpen}
      title="Load Manifest"
      onClose={toggleModal}
    />
  );
};

export default AddStudentToLoadModal;
