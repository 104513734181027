import gql from 'graphql-tag';

export const GET_ACCOUNT_BALANCE_PAYMENT_HISTORY = gql`
  query GET_ACCOUNT_BALANCE_PAYMENT_HISTORY(
    $input: AccountBalancePaymentHistoryInput
    $filter: AccountBalancePaymentHistoryFilter
  ) {
    getAccountBalancePaymentHistory(input: $input, filter: $filter) {
      id
      amount
      created_on
      processed_by
      manual_payment
      status
    }
  }
`;
