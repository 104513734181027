export const formatMoney = (amount: number, currency?: string) => {
  const formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: currency ?? "USD",
    minimumFractionDigits: 2,
  });
  return formatter.format(amount);
};

export const getCurrencySymbol = (currency) => {
  return (0)
    .toLocaleString("en-US", {
      style: "currency",
      currency: currency,
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    })
    .replace(/\d/g, "")
    .trim();
};

export default function (n: number) {
  return new Intl.NumberFormat("en-US").format(n);
}

export const formatHeight = (height: number) => {
  const feet = Math.floor(height / 12);
  const inches = Number((height % 12).toFixed(2));

  return `${feet} ft ${inches > 0 ? inches : ""} ${inches > 0 ? " in" : ""}`;
};

export const getWingLoad = (canopy, weight) => {
  return (weight / canopy).toFixed(2);
};

export const getAge = (dateString) => {
  const today = new Date();
  const birthDate = new Date(dateString);
  let age = today.getFullYear() - birthDate.getFullYear();
  const m = today.getMonth() - birthDate.getMonth();
  if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
    age--;
  }
  return age;
};
