import { useMutation } from '@apollo/client';
import { applySnapshot, getSnapshot } from 'mobx-state-tree';
import { Formik } from 'formik';
import React from 'react';
import AsyncSelect from 'react-select/async';
import { toast } from 'react-toastify';
import { Icon, Table, Text } from 'tabler-react';

import FormField from 'components/FormField';
import { GET_LOCATIONS_BY_FILTER_QUERY } from '../../../graphql/GET_LOCATIONS_BY_FILTER_QUERY';
import { UPDATE_CAMP_BY_ID } from '../../../graphql/UPDATE_CAMP_BY_ID';
import { useAutocomplete } from '../../../hooks/useAutocomplete';
import autoCompleteStyles from '../../Students/Forms/autocompleteStyles';
import { Types } from '../../../types/graphql'

interface IEventProps {
  id: string;
  name: string;
}

interface IUpdateEventProps {
  event: Types.Camp;
  eventClone: any;
  setIsEditing: React.Dispatch<React.SetStateAction<boolean>>;
}

const UpdateEvent = ({
  event,
  eventClone,
  setIsEditing
}) => {
  const [updateCampById] = useMutation(UPDATE_CAMP_BY_ID);

  const locationsOptsSelectCb = (params: IEventProps) => {
    if (!params) {
      return;
    }

    eventClone.changeLocationId(params.id);
    eventClone.changeLocationName(params.name);
  };

  const { loadOptions } = useAutocomplete({
    query: GET_LOCATIONS_BY_FILTER_QUERY
  });

  return (
    <Formik
      initialValues={{
        id: eventClone.id,
        camp_name: eventClone.camp_name
      }}
      onSubmit={async (values, { setSubmitting, resetForm }) => {
        await updateCampById({
          variables: {
            camp: {
              ...values,
              location_id: eventClone.location_id
            }
          },
          update(_, { data: { updateCampById } }) {
            if (updateCampById) {
              applySnapshot(event, getSnapshot(eventClone));
              toast.success('Event Updated.');
            }
          }
        });
        setIsEditing(false);
        setSubmitting(false);
        resetForm();
      }}
    >
      {({ handleBlur, handleChange, handleSubmit }) => {
        return (
          <>
            <Table.Col>
              <Text RootComponent="span" muted>
                {event.id}
              </Text>
            </Table.Col>
            <Table.Col>
              <FormField
                name="camp_name"
                onBlur={handleBlur}
                onChange={handleChange}
                type="text"
              />
            </Table.Col>

            <Table.Col>
              <div className="form-group">
                <AsyncSelect
                  backspaceRemovesValue={true}
                  cacheOptions={true}
                  className="input-group"
                  escapeClearsValue={true}
                  getOptionLabel={(option) => option.name}
                  getOptionValue={(option) => option.id}
                  isClearable={true}
                  loadOptions={loadOptions}
                  onChange={locationsOptsSelectCb}
                  placeholder="Search Location"
                  styles={autoCompleteStyles}
                  components={{
                    DropdownIndicator: () => null,
                    IndicatorSeparator: () => null,
                    LoadingIndicator: () => null
                  }}
                />
              </div>
            </Table.Col>
            <Table.Col>
              <Icon
                link
                name="save"
                onClick={() => {
                  handleSubmit();
                }}
              />
            </Table.Col>
            <Table.Col>
              <Icon link name="x-circle" onClick={() => setIsEditing(false)} />
            </Table.Col>
          </>
        );
      }}
    </Formik>
  );
};

export default UpdateEvent;
