import { observer } from 'mobx-react';
import React from 'react';
import { Container } from 'tabler-react';
import Wrapper from '../layout';
import StudentGearList from '../../components/Gear/StudentGearList';

const Gears = (props) => {

  return (
    <Wrapper {...props} title='Gear'>
      <Container>
        <StudentGearList />
      </Container>
    </Wrapper>
  );
};
export default observer(Gears);
