import gql from 'graphql-tag';

export const GET_ALL_GEAR_MODELS = gql`
    query GET_ALL_GEAR_MODELS {
        getGearModels {
            id
            name
            gear_type_id
            gear_manufacturer_id
            gear_type_name
            gear_manufacturer_name
        }
    }
`;
