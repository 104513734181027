import gql from "graphql-tag";

export const GET_JOB_LIST = gql`
  query GET_JOB_LIST($jobListFilter: JobListFilter!) {
    jobsList(filter: $jobListFilter) {
      id
      completed_on
      created_by
      created_on
      due_on
      job_notes
      job_storage
      job_type_id
      name
      priority
      product_id
      quantity
      rigger_id
      status
      student_id
      team_id
      unit_price
      user_gear_id

      total_count

      student {
        id
        first_name
        last_name
        address1
        address2
        city
        country
        dob
        email
        jump_weight
        phone_country_code
        phone_number
        postal_code
        province
        registration_id
        weight

        auth_merits {
          member_id
        }

        uspa {
          first_name
          last_name
          email
          updated_on
          merits_uspa_member_id
          merits_uspa_member_expiration
          template_id
          template_title
        }
      }

      user_gear {
        gear_canopy_size
        gear_serial
        gear_dom
        model
        gear_model {
          name
        }
        rig {
          id
          name
        }
      }

      team {
        name
        team_email
        team_phone
      }

      rigger {
        id
        first_name
        last_name
        email
        phone_number
        phone_country_code
      }

      product {
        title
        amount
        team_id
      }

      invoice {
        id
        invoice_type_id
        student_id
        invoice_amount
        status
        invoice_notes
        created_by
        created_on
        company_name
        company_phone
        company_email
        customer_name
        customer_phone
        customer_email
      }
    }
  }
`;
