import { observer } from 'mobx-react';
import React from 'react';
import { Container } from 'tabler-react';
import Wrapper from '../layout';
import LogbookList from '../../components/Logbook/LogbookList';

const LogbookListPage = (props) => {

  return (
    <Wrapper {...props} title="Coach List">
      <Container>
        <LogbookList />
      </Container>
    </Wrapper>
  );
};
export default observer(LogbookListPage);
