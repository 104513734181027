import { useQuery } from "@apollo/client";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import FullCalendar, {
  DateSelectArg,
  EventChangeArg,
  EventClickArg,
  EventContentArg,
} from "@fullcalendar/react";
import timeGridPlugin from "@fullcalendar/timegrid";
import { observer } from "mobx-react";
import { applySnapshot } from "mobx-state-tree";
import moment from "moment";
import React, { useContext, useEffect, useMemo } from "react";
import { Dimmer, Icon } from "tabler-react";
import useReactRouter from "use-react-router";
import { EventStoreContext } from "../../contexts/eventStoreContext";
import { GET_CAMPS } from "../../graphql/GET_CAMPS";
import { useRootStore } from "../../hooks/useRootStore";
import {
  useGetUserIsAdmin,
  useGetUserIsStudent,
} from "../../modules/common/hooks/useGetCurrentUserType";
import { Types } from "../../types/graphql";

interface ICalendarViewProps {
  toggleModal: () => void;
}

const CalendarView = ({ toggleModal }: ICalendarViewProps) => {
  const eventStore = useContext(EventStoreContext);
  const { events } = eventStore;

  const { currentCoachTeam, currentUser } = useRootStore();
  const { history } = useReactRouter();

  const isStudent = useGetUserIsStudent();
  const isAdmin = useGetUserIsAdmin();

  // change this to only allow teams with feature_camps to Create Events
  const canAddEvent: boolean = useMemo(() => {
    return currentCoachTeam && currentCoachTeam.feature_camps;
  }, [currentCoachTeam]);

  let filterOptions: object = { isDateAgnostic: true };

  // Coach Accounts can see only events for their Team
  if (currentCoachTeam) {
    filterOptions = {
      ...filterOptions,
      team_id: currentCoachTeam?.id.toString(),
    };
  }

  // Event Organizers with NO TEAM can only see events they are associated with
  // TO DO...

  // students can see all events that are published and public
  if (isStudent) {
    filterOptions = {
      ...filterOptions,
      isPublic: true,
      isPublished: true,
    };
  }

  const { loading, error, data } = useQuery(GET_CAMPS, {
    variables: { filter: { ...filterOptions } },
  });

  useEffect(() => {
    eventStore.resetEventEntry();

    if (!loading && data) {
      const modifiedEvents = data.getCamps.map((camp) =>
        Object.assign(
          {},
          {
            ...camp,
            title: camp.camp_name,
            allDay: camp.all_day,
            end: camp.all_day
              ? moment(camp.end).add(1, "d").format()
              : camp.end,
          },
        ),
      );

      if (!currentCoachTeam && !isStudent && !isAdmin) {
        applySnapshot(
          events,
          modifiedEvents.filter(
            (event: Types.Camp) =>
              !!event.organizers.find(
                (organizer) => organizer.coach_id === currentUser.id,
              ),
          ),
        );
      } else {
        applySnapshot(events, modifiedEvents);
      }
    }
  }, [data]);

  if (error) {
    return <p>{`Error: ${error.message}`}</p>;
  }

  function handleDateSelect(selectInfo: DateSelectArg) {
    if (!canAddEvent) {
      return null;
    }

    eventStore.initEventEntry(selectInfo);
    toggleModal();
  }

  const handleEventClick = (clickInfo: EventClickArg) => {
    history.push(`/user/calendar/${clickInfo.event.id}`);
  };

  const handleEventChange = (changeInfo: EventChangeArg) => {
    eventStore.changeEvent(changeInfo);
  };

  const renderEventContent = (eventContent: EventContentArg) => {
    const { event } = eventContent;

    return (
      <div
        className={`${
          event.extendedProps.is_published
            ? "cursor-pointer pl-1 text-white"
            : "cursor-pointer pl-1 bg-light text-primary"
        } `}
      >
        {event.title}
        <Icon
          className="ml-2"
          name={`${event.extendedProps.is_public ? "calendar" : "users"} `}
        />
      </div>
    );
  };

  return (
    <Dimmer active={loading} loader={loading}>
      <FullCalendar
        dayMaxEvents={true}
        events={events.slice()}
        headerToolbar={
          {
            // keep for reference, not currently needed
            // left: 'prev,next today',
            // center: 'title',
            // right: 'dayGridMonth,timeGridWeek,timeGridDay'
          }
        }
        initialView="dayGridMonth"
        plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
        selectable={true}
        selectMirror={true}
        select={handleDateSelect}
        eventContent={renderEventContent}
        eventClick={handleEventClick}
        eventChange={handleEventChange}
      />
    </Dimmer>
  );
};

export default observer(CalendarView);
