import gql from 'graphql-tag';

export const GET_INVOICE_ITEMS = gql`
  query GET_INVOICE_ITEMS($invoice_id: Int!) {
    invoiceItems(invoice_id: $invoice_id) {
      invoice_id
      job_id
      product_id

      job {
        id
        user_gear_id
        product_id
        rigger_id
        job_type_id
        priority
        status
        job_notes
        job_storage
        created_by
        created_on
        completed_on
        due_on
        student_id
        team_id
        unit_price
        quantity
        name

        user_gear {
          gear_canopy_size
          gear_serial
          gear_dom
          gear_model {
            name
          }
        }

        product {
          id
          title
          description
          amount
          team_id
          currency
          created_on
          image
          status
        }
      }

      product {
        id
        title
        description
        amount
        quantity
        currency
        created_on
        image
        status
        team_id
      }
    }
  }
`;
