import React from 'react';
import { Link } from 'react-router-dom';
import { Grid, StampCard } from 'tabler-react';

import { useRootStore } from '../../hooks/useRootStore';
import formatNumber from '../../utils/numberFormat';
import { observer } from 'mobx-react';

const MeritsTotal = () => {
  const rootStore = useRootStore();
  const { authMeritTotal, userMeritTotal } = rootStore;

  return (
    <Grid.Col sm={6} lg={3} xs={12} className="mb-0">
      <StampCard
        color="cyan"
        icon="award"
        header={
          <Link to="#">
            {formatNumber(authMeritTotal)} <small>Auth Merits</small>
          </Link>
        }
        footer={`${formatNumber(userMeritTotal)} user merits`}
      />
    </Grid.Col>
  );
};

export default observer(MeritsTotal);
