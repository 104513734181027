import { observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import { Card, Dimmer, Grid, Table } from 'tabler-react';

import StudentRegistrationListItem from './StudentRegistrationListItem';
import { useQuery } from '@apollo/client';
import { GET_REGISTERED_STUDENTS } from 'graphql/GET_REGISTERED_STUDENTS';
import { Types } from '../../types/graphql'

const STUDENT_REGISTRATIONS_ORDER = 'recent';

const StudentRegistrationList = () => {
  //const userStore = useContext(UserStoreContext);

  const [registeredStudents, setRegisteredStudents] = useState([]);

  const { loading, error, data } = useQuery(GET_REGISTERED_STUDENTS, {
    variables: { order: STUDENT_REGISTRATIONS_ORDER }
  });

  useEffect(() => {
    if (!loading && data) {
      setRegisteredStudents(data.getRegisteredStudents);
      // applySnapshot(userStore, {
      //   ...userStore,
      //   registeredStudents: data.getRegisteredStudents
      // });
    }
  }, [data]);  

  if (error) {
    return <p>{`Error: ${error.message}`}</p>;
  }

  return (
    <Dimmer active={loading} loader={loading}>
      <Card>
        <Card.Header>
          <Card.Title>Participant Registrations</Card.Title>
        </Card.Header>
        <Card.Body>
          <Grid.Col width={12}>
            <Table responsive className="card-table table-vcenter text-nowrap">
              <Table.Header>
                <Table.Row>
                  <Table.ColHeader className="w-1">ID</Table.ColHeader>
                  <Table.ColHeader>Name</Table.ColHeader>
                  <Table.ColHeader>Camp</Table.ColHeader>
                  <Table.ColHeader>Registration Code</Table.ColHeader>
                  <Table.ColHeader>Created</Table.ColHeader>
                  <Table.ColHeader></Table.ColHeader>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {registeredStudents.map(
                  (
                    student: Types.RegisteredStudents,
                    idx: number
                  ) => (
                    <Table.Row key={idx}>
                      <StudentRegistrationListItem student={student} />
                    </Table.Row>
                  )
                )}
              </Table.Body>
            </Table>
          </Grid.Col>
        </Card.Body>
      </Card>
    </Dimmer>
  );
};

export default observer(StudentRegistrationList);
