import gql from 'graphql-tag';

export const TOGGLE_DISABLE_ADMIN = gql`
  mutation TOGGLE_DISABLE_ADMIN($adminId: ID!, $is_disabled: Boolean) {
    toggleDisableAdmin(
      updateAdmin: { id: $adminId, is_disabled: $is_disabled }
    ) {
      id
      username
      is_disabled
    }
  }
`;
