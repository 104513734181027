import PaymentsList from 'modules/payment/lists/PaymentsList';
import React from 'react';
import { Container } from 'tabler-react';
import Wrapper from '../layout';

const PaymentsPage = (props) => {
  return (
    <Wrapper {...props} title="Payments">
      <Container>
        <PaymentsList />
      </Container>
    </Wrapper>
  );
};

export default PaymentsPage;
