import { useMutation } from '@apollo/client';
import { Formik } from 'formik';
import { observer } from 'mobx-react';
import React, { useContext } from 'react';
import AsyncSelect from 'react-select/async';
import { toast } from 'react-toastify';
import { Button, Dimmer, Form, Grid } from 'tabler-react';
import * as Yup from 'yup';
import { UserStoreContext } from '../../contexts/userStoreContext';
import { ADD_LOGBOOK_LOG } from '../../graphql/ADD_LOGBOOK_LOG';
import { GET_LOCATIONS_BY_FILTER_QUERY } from '../../graphql/GET_LOCATIONS_BY_FILTER_QUERY';
import { GET_STUDENT_RECENT_LOGBOOK_LOG } from '../../graphql/GET_STUDENT_RECENT_LOGBOOK_LOG';
import { useRootStore } from '../../hooks';
import { useAutocomplete } from '../../hooks/useAutocomplete';
import FormField from '../FormField';
import Modal from '../Modal';
import autoCompleteStyles from '../Students/Forms/autocompleteStyles';
import { EUserTypes } from '../User/userTypes';
import { Types } from '../../types/graphql'

interface LogBookSessionModalFormProps {
  isModalOpen: boolean;
  logBookLog?: any;
  toggleModal: any;
}

const logbookLogValidationSchema = Yup.object().shape({
  location_id: Yup.number().required('Location is required.'),
  date: Yup.date().required('Date is required.'),
//  log_spent_minutes: Yup.string().required('Time in tunnel is required.')
});

const LogBookSessionModalForm = ({
  logBookLog,
  isModalOpen,
  toggleModal
}: LogBookSessionModalFormProps) => {
  const userStore = useContext(UserStoreContext);
  const { user } = userStore;
  const { currentUser, currentCoachTeam } = useRootStore();

  const [addLogbookLog] = useMutation(ADD_LOGBOOK_LOG);

  const { loadOptions } = useAutocomplete({
    query: GET_LOCATIONS_BY_FILTER_QUERY
  });

  return (
    <>
      <Modal
        className="overflow-visible"
        content={
          <Grid.Col width={12}>
            <Dimmer active={false} loader={false}>
              <Formik
                enableReinitialize={true}
                validationSchema={logbookLogValidationSchema}
                initialValues={{
                  location_id: '',
                  date: '',
                  log_spent_minutes: '',
                  student_id: parseInt(user.id),
                  sport_types_id: 1,
                  created_by: currentUser.id
                }}
                onSubmit={async (values, { setSubmitting }) => {
                  const newLogbookLog = await addLogbookLog({
                    variables: {
                      addLogbookLog: {
                        location_id: parseInt(values.location_id),
                        date: values.date,
                        log_spent_minutes: values.log_spent_minutes,
                        student_id: values.student_id,
                        sport_types_id: values.sport_types_id,
                        created_by: values.created_by,
                        team_id: currentCoachTeam.id
                      }
                    },
                    refetchQueries: [
                      {
                        query: GET_STUDENT_RECENT_LOGBOOK_LOG,
                        variables: {
                          logbookLogFilter: {
                            student_id: parseInt(user.id),
                            sport_types_id:
                              currentUser.type === EUserTypes.dzm ? 2 : 1
                          }
                        }
                      }
                    ]
                  });

                  if (newLogbookLog?.data.addLogbookLog.id) {
                    toast.success('Session recorded.');
                    toggleModal(false);
                  }

                  setSubmitting(false);
                }}
              >
                {({ values, errors, touched, handleSubmit, setFieldValue }) => (
                  <Form onSubmit={handleSubmit}>
                    <Grid.Row>
                      <Grid.Col md={6} sm={12} xs={12}>
                        <Form.Group lassName="" label="Date">
                          <FormField
                            name="date"
                            value={values.date}
                            type={'date'}
                            max={new Date().toISOString().split('T')[0]}
                            onChange={(e) => {
                              setFieldValue('date', e.target.value);
                            }}
                            placeholder="mm/dd/yyyy"
                          />
                        </Form.Group>
                      </Grid.Col>
                    </Grid.Row>
{/*
                    <Grid.Row>
                      <Grid.Col md={6} sm={12} xs={12}>
                        <Form.Group lassName="" label="Tunnel Time">
                          <FormField
                            name="log_spent_minutes"
                            value={values.log_spent_minutes}
                            type={'time'}
                          //  max={'2:00'}
                            onChange={(e) => {
                              setFieldValue('log_spent_minutes', e.target.value);
                            }}
                            placeholder="h:mm"
                          />
                        </Form.Group>
                      </Grid.Col>
                    </Grid.Row>
*/}
                    <Grid.Row>
                      <Grid.Col lg={6} md={6} sm={12} xs={12}>
                        <Form.Group label="Location">
                          <AsyncSelect
                            backspaceRemovesValue={true}
                            className="input-group"
                            components={{
                              DropdownIndicator: () => null,
                              IndicatorSeparator: () => null,
                              LoadingIndicator: () => null
                            }}
                            escapeClearsValue={true}
                            getOptionLabel={(
                              option: Types.Location
                            ) => option.name}
                            getOptionValue={(
                              option: Types.Location
                            ) => option.id}
                            isClearable={true}
                            loadOptions={loadOptions}
                            name="location_id"
                            onChange={(e) => {
                              if (e) setFieldValue('location_id', e.id);
                            }}
                            placeholder="Search for default location"
                            styles={autoCompleteStyles}
                          />
                          <span className="field-error text-danger">
                            {errors.location_id &&
                              touched.location_id &&
                              errors.location_id}
                          </span>
                        </Form.Group>
                      </Grid.Col>
                    </Grid.Row>

                    <Grid.Row>
                      <Grid.Col>
                        <Button.List align="right">
                          <Button
                            className="mr-3"
                            pill
                            size="sm"
                            color="white"
                            onClick={(e) => {
                              e.preventDefault();
                              toggleModal();
                            }}
                          >
                            CANCEL
                          </Button>
                          <Button
                            className="mr-3"
                            color="gray-dark"
                            size="sm"
                            pill
                          >
                            SUBMIT
                          </Button>
                        </Button.List>
                      </Grid.Col>
                    </Grid.Row>
                  </Form>
                )}
              </Formik>
            </Dimmer>
          </Grid.Col>
        }
        open={isModalOpen}
        title=""
        onClose={toggleModal}
      />
    </>
  );
};

export default observer(LogBookSessionModalForm);
