import { useQuery } from '@apollo/client';
import * as DOMPurify from 'dompurify';
import { GET_CRM_MESSAGES } from 'graphql/GET_CRM_MESSAGES';
import { GET_CRM_MESSAGES as GET_CRM_MESSAGES_TYPE } from 'graphql/types/GET_CRM_MESSAGES';
import moment from 'moment';
import React, { FC, useMemo } from 'react';
import ReactHtmlParser from 'react-html-parser';
import { useParams } from 'react-router';
import CRMMessagesListItem from '../CRMMessagesListItem';

const CRMMessagesList: FC = () => {
  const { studentId, userRegId } = useParams<{
    studentId: string;
    userRegId: string;
  }>();

  const { data } = useQuery<GET_CRM_MESSAGES_TYPE>(GET_CRM_MESSAGES, {
    variables: {
      studentId: Number(studentId),
      userRegId: Number(userRegId)
    }
  });

  const crmMessageRows = useMemo(
    () =>
      data?.getCRMMessages.map(
        ({ coach_first_name, created_on, id, message }) => ({
          coachName: `${coach_first_name}`,
          created_on: moment(created_on).format('MMM DD, YYYY - h:mm'),
          id,
          message
        })
      ) ?? [],
    [data?.getCRMMessages]  
  );

  if (!crmMessageRows.length) {
    return <></>;
  }

  return (
    <>
      {crmMessageRows.map(({ coachName, created_on, id, message }) => (
        <CRMMessagesListItem key={id} coachName={coachName} created_on={created_on} id={id} message={ReactHtmlParser(DOMPurify.sanitize(message))} />
      ))}
    </>
  );
};

export default CRMMessagesList;
