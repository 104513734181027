import { useQuery } from "@apollo/client";
import { GET_STUDENTS_BY_PHONE } from "graphql/GET_STUDENTS_BY_PHONE";
import { useEffect, useState } from "react";
import { DEFAULT_LIMIT } from "../../../components/constants";
import { EUserFilterOptions } from "../../../components/enum";
import { GET_STUDENT_BY_EMAIL } from "../../../graphql/GET_STUDENT_BY_EMAIL";
import { GET_STUDENT_BY_ID } from "../../../graphql/GET_STUDENT_BY_ID";
import { GET_STUDENTS_BY_FILTER_QUERY } from "../../../graphql/GET_STUDENTS_BY_FILTER_QUERY";
import { GET_STUDENT_BY_EMAILQuery } from "../../../graphql/new/graphql";
import { GET_STUDENT_BY_ID as GET_STUDENT_BY_ID_TYPE } from "../../../graphql/types/GET_STUDENT_BY_ID";
import { useRootStore } from "../../../hooks";
import { EOrderOptions, IArgsOptions } from "../../../hooks/useAutocomplete";
import { Types } from "../../../types/graphql";
import { QueryParams } from "../../common/constants/serviceParams";
import { useGetUserIsAdmin } from "../../common/hooks/useGetCurrentUserType";
import { SEARCH_LIMIT } from "../constants";

const useGetSearchStudentQuery = () => {
  const [searchStudentData, setSearchStudentData] = useState<Types.Student[]>(
    [],
  );
  const isAdmin = useGetUserIsAdmin();
  const { currentCoachTeam } = useRootStore();
  const [queryFilter, setQueryFilter] = useState<QueryParams>({
    q: "",
    limit: DEFAULT_LIMIT,
  });

  let argsOptions: IArgsOptions = {
    order: EOrderOptions.alphabet,
    filter: { filter_by: EUserFilterOptions.all },
  };

  if (currentCoachTeam?.id) {
    argsOptions = {
      ...argsOptions,
      filter: {
        team_id: currentCoachTeam?.id.toString(),
        filter_by: EUserFilterOptions.enabled,
      },
    };
  }

  const { loading, error, data, refetch } = useQuery(
    GET_STUDENTS_BY_FILTER_QUERY,
    {
      variables: {
        ...argsOptions,
        filter: {
          ...argsOptions.filter,
          limit: SEARCH_LIMIT,
          search: queryFilter.q,
        },
      },
      skip: queryFilter.q.length === 0,
    },
  );

  const {
    data: dataById,
    loading: loadingById,
    refetch: refetchById,
  } = useQuery<GET_STUDENT_BY_ID_TYPE>(GET_STUDENT_BY_ID, {
    variables: {
      student_id: Number(queryFilter.q).toString(),
    },
    skip: queryFilter.q.length === 0 || isNaN(Number(queryFilter.q)),
  });

  const {
    data: dataByEmail,
    loading: loadingByEmail,
    refetch: refetchByEmail,
  } = useQuery<GET_STUDENT_BY_EMAILQuery>(GET_STUDENT_BY_EMAIL, {
    variables: {
      email: queryFilter.q,
    },
    skip: !isValidateEmail(queryFilter.q),
  });

  const {
    data: dataByPhone,
    loading: loadingByPhone,
    refetch: refetchByPhone,
  } = useQuery(GET_STUDENTS_BY_PHONE, {
    variables: {
      phone: queryFilter.q,
    },
    skip: queryFilter.q.replace(/\D/g, "").length < 7,
  });

  useEffect(() => {
    if (!loading && !loadingById) {
      let students = [];
      if (data) students = [...data.students];

      if (dataById && dataById.getStudentById && isAdmin)
        students.push(dataById.getStudentById);

      if (dataByEmail && dataByEmail.getStudentByEmail)
        students.push(dataByEmail.getStudentByEmail);

      if (dataByPhone && dataByPhone.getStudentsByPhone)
        dataByPhone.getStudentsByPhone.forEach((entry: Types.Student) =>
          students.push(entry),
        );

      setSearchStudentData(students);
    }
  }, [
    data,
    dataById,
    loading,
    loadingById,
    dataByEmail,
    loadingByEmail,
    loadingByPhone,
    isAdmin,
  ]);

  useEffect(() => {
    if (queryFilter.q.length > 0) {
      void refetch();

      if (Number(queryFilter.q) > 0) void refetchById();
      // check if queryFilter.q is valid email

      if (isValidateEmail(queryFilter.q)) void refetchByEmail();

      if (queryFilter.q.replace(/\D/g, "").length >= 7) void refetchByPhone();
    }
  }, [queryFilter, refetch, refetchByEmail, refetchById, refetchByPhone]);

  return {
    data: searchStudentData,
    dataById: isAdmin ? dataById : undefined,
    loading,
    error,
    queryFilter,
    setQueryFilter,
  };
};

export default useGetSearchStudentQuery;

const isValidateEmail = (email) => {
  return String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    );
};
