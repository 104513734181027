import { useQuery } from '@apollo/client'
import { Loading } from 'components/Loading'
import { GET_CAMP_TOTALS } from 'graphql/GET_CAMP_TOTALS'
import { GET_CAMP_TOTALS as GET_CAMP_TOTALS_TYPE } from 'graphql/types/GET_CAMP_TOTALS'
import { useParams } from 'react-router-dom'
import { Grid, StampCard, Text } from 'tabler-react'
import useReactRouter from 'use-react-router'
import formatNumber, { formatMoney } from 'utils/numberFormat'
import { parseIntNullable } from 'utils/numberParser'

const EventRegistrationTotalPayments = () => {
  const { campId } = useParams<{ campId: string }>()
  const campIdValue = parseIntNullable(campId)

  const { loading, error, data } = useQuery<GET_CAMP_TOTALS_TYPE>(
    GET_CAMP_TOTALS,
    {
      variables: {
        campId: campIdValue
      }
    }
  )

  if (loading || !data) {
    return <Loading />
  }

  if (error) {
    console.log(error)
    return <p>Error: {error.message}</p>
  }

  const payments = data.payments ?? []
  const pending = data.pending ?? {}
  return (
    <EventRegistrationTotalPaymentsInner
      pending={pending}
      payments={payments}
    />
  )
}

const EventRegistrationTotalPaymentsInner = ({ pending, payments }) => {
  const { history } = useReactRouter()
  return (
    <>
      <Grid.Col sm={6} lg={3} xs={12} className="pl-0">
        <span>
          <StampCard
            className="mb-0"
            color="success"
            icon="dollar-sign"
            // Total number of payments with Paid status for this camp
            header={
              <>
                <Text>
                  <span
                    className="cursor-pointer"
                    onClick={() => history.push(`/payments`)}
                  >
                    {formatNumber(payments.total)} <small>Payments</small>
                  </span>
                </Text>
              </>
            }
            // Total payment amounts with Paid status, Total Registration Revenue
            footer={
              <Text>
                {pending.potential_total
                  ? `${formatMoney(payments.totalPaidConfirmed)}`
                  : '$0.00'}
              </Text>
            }
          />
        </span>
      </Grid.Col>
      <Grid.Col sm={6} lg={3} xs={12} className="pl-0">
        <span>
          <StampCard
            className="mb-0"
            color="secondary"
            icon="dollar-sign"
            header={
              <Text>
                {formatNumber(payments.totalCompCount)}{' '}
                <small>Comp Payments</small>
              </Text>
            }
            footer={<Text>{`${formatMoney(payments.totalComp)}`}</Text>}
          />
        </span>
      </Grid.Col>
    </>
  )
}

export default EventRegistrationTotalPayments
