import gql from 'graphql-tag';

export const EDIT_TEAM_LOAD_PAYMENT_TYPE = gql`
  mutation EDIT_TEAM_LOAD_PAYMENT_TYPE(
    $updateTeamLoadPaymentType: UpdateTeamLoadPaymentType!
  ) {
    updateTeamLoadPaymentType(
      updateTeamLoadPaymentType: $updateTeamLoadPaymentType
    )
  }
`;
