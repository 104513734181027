import RiggerJobsListItem from './RiggerJobsListItem';
import { formatMoney } from '../../utils/numberFormat';
import React from 'react';
import { Button, Card, Table, Text } from 'tabler-react';
import RiggerJobListPdfButton from './RiggerJobListPdfButton';
import { monthNames } from '../../utils/dateFormat';

interface IRiggerJobListCardProp {
  jobs: any;
  period: any;
  keyName: any;
}

const RiggerJobListCard = ({ jobs, period, keyName }: IRiggerJobListCardProp) => {
  const title = `${monthNames[period[1] - 1]?.slice(0, 3)} ${
    period[period.length - 1]
  }`;
  return (
    <Card key={`jobList-${keyName}`}>
      <Card.Header>
        <Card.Title>
          {title}
        </Card.Title>
        <Card.Options>
          <Button.List>
            <RiggerJobListPdfButton
              jobList={jobs} title={title}
            />
          </Button.List>
        </Card.Options>
      </Card.Header>
      <Card.Body>
        <Table>
          <Table.Header>
            <Table.Row>
              <Table.ColHeader>Customer</Table.ColHeader>
              <Table.ColHeader>Service</Table.ColHeader>
              <Table.ColHeader>Gear</Table.ColHeader>
              <Table.ColHeader>Status</Table.ColHeader>
              <Table.ColHeader>Amount</Table.ColHeader>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {jobs?.map((job, index) => (
              <RiggerJobsListItem job={job} key={'job' + index} />
            ))}
          </Table.Body>
        </Table>
      </Card.Body>
      <Card.Footer className='mr-5 font-weight-bold'>
        <RiggerJobListPdfButton
          jobList={jobs} title={title}
        />
        <Button
          className='mr-2'
          icon='send'
          size='sm'
          color='primary'
          disabled
        ></Button>
        <Text className='float-right'>
          Total:{' '}
          {formatMoney(
            jobs.reduce((total, job) => {
              return total + (job.unit_price * job.quantity) * 0.75;
            }, 0)
          )}
        </Text>
      </Card.Footer>
    </Card>
  );
};

export default RiggerJobListCard;
