import { useQuery } from "@apollo/client";
import { Loading } from "components/Loading";
import { STATUS_ICON_COLOR_MAPPING } from "modules/user/registrations/constants/registrationStatusMapping";
import moment from "moment";
import React from "react";
import { Link } from "react-router-dom";
import { Alert, Button, Card, Icon, Text } from "tabler-react";
import useReactRouter from "use-react-router";
import { GET_STUDENT_CAMPS } from "../../graphql/GET_STUDENT_CAMPS";

interface IStudentRecentCamps {
  studentId: number;
  camps: any;
  currentCampId: number;
}

const StudentRecentCamps = ({
  studentId,
  currentCampId,
}: {
  studentId: number;
  currentCampId: number;
}) => {
  const { data, error, loading } = useQuery(GET_STUDENT_CAMPS, {
    variables: { getStudentCampsInput: { studentId: Number(studentId) } },
  });

  if (loading || !data) {
    return <Loading />;
  }

  if (error) {
    console.log(error);
    return <p>Error: {error.message}</p>;
  }

  const camps = [...data.getStudentCamps].slice(0, 10) || [];

  return (
    <StudentRecentCampsInner
      studentId={studentId}
      camps={camps}
      currentCampId={currentCampId}
    />
  );
};

const StudentRecentCampsInner = ({
  studentId,
  camps,
  currentCampId,
}: IStudentRecentCamps) => {
  const { history } = useReactRouter();

  return (
    <Card>
      <Card.Header>
        <Card.Title>
          <i className="fe fe-calendar text-primary mr-2" />
          Other Events
        </Card.Title>
      </Card.Header>
      <Card.Body>
        {camps.length <= 0 && (
          <Alert type="info text-center">
            <strong>No Events</strong>
          </Alert>
        )}
        {camps.length > 0 &&
          camps.map((camp) => {
            if (camp.camp_id === currentCampId) return null;
            return (
              <div key={camp.camp_id}>
                <span className="ml-3 text-muted float-right">
                  {camp.rating > 0 && (
                    <Button
                      icon="star"
                      color="white"
                      size="sm"
                      className="text-warning"
                      onClick={(e) => {
                        e.preventDefault();
                        history.push(
                          `/events/registrations/${camp.user_registrations_id}/${studentId}`,
                        );
                      }}
                    >
                      {camp.rating}
                    </Button>
                  )}
                  {camp.user_registrations_id && (
                    <Button
                      icon={
                        STATUS_ICON_COLOR_MAPPING.get(camp?.status)?.icon ??
                        "x-circle"
                      }
                      color="white"
                      size="sm"
                      className={
                        "ml-0 " +
                          STATUS_ICON_COLOR_MAPPING.get(camp?.status)
                            ?.className ?? "text-muted"
                      }
                      onClick={(e) => {
                        e.preventDefault();
                        history.push(
                          `/events/registrations/${camp.user_registrations_id}/${studentId}`,
                        );
                      }}
                      //  disabled={camp.team_id}
                    />
                  )}
                </span>
                <p>
                  <span className="">
                    <Link to={"/user/calendar/" + camp.camp_id}>
                      {camp.camp_name}
                    </Link>
                  </span>
                  <Text.Small className="d-block text-muted">
                    {camp.team_name} -{" "}
                    {moment(camp.start).format("MMM D, YYYY")}
                  </Text.Small>
                  <Text.Small className="d-block text-muted">
                    {camp.location.name}
                  </Text.Small>
                </p>
              </div>
            );
          })}
      </Card.Body>
    </Card>
  );
};

export default StudentRecentCamps;
