import React from 'react';
import { Card, Table, Button, Dropdown } from 'tabler-react';
import Wrapper from 'pages/layout';

const CoachList = (props) => {
  return (
    <Wrapper {...props} title="Coach List">
      <div className="container">
        <Card>
          <Card.Header>
            <Card.Title>Coach List</Card.Title>
          </Card.Header>
          <Card.Body>
            <Table
              bodyItems={[
                {
                  key: 1,
                  item: [
                    { content: '1' },
                    { content: 'Jon Doe' },
                    { content: 'jondoe@email.com' },
                    {
                      content: (
                        <Button.Dropdown color="primary">
                          <Dropdown.Item>Update</Dropdown.Item>
                          <Dropdown.Item>View</Dropdown.Item>
                          <Dropdown.ItemDivider />
                        </Button.Dropdown>
                      )
                    }
                  ]
                }
              ]}
              headerItems={[
                { content: 'ID' },
                { content: 'First Name' },
                { content: 'Email' },
                { content: 'Action' }
              ]}
            />
          </Card.Body>
        </Card>
      </div>
    </Wrapper>
  );
};
export default CoachList;
