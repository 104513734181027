import { useMutation, useQuery } from '@apollo/client';
import { Formik } from 'formik';
import { observer } from 'mobx-react';
import React, { useContext, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { Card, Dimmer, Form } from 'tabler-react';
import useGetCurrentUserType from "../../../modules/common/hooks/useGetCurrentUserType";
import { UserStoreContext } from '../../../contexts/userStoreContext';
import { ADD_OR_UPDATE_STUDENT_NOTES } from '../../../graphql/ADD_OR_UDPATE_STUDENT_NOTES';
import { GET_STUDENT_NOTE } from '../../../graphql/GET_STUDENT_NOTE';
import { useRootStore } from '../../../hooks/useRootStore';


const AddStudentNoteForm = () => {
  const rootStore = useRootStore();
  const { currentUser, currentCoachTeam } = rootStore;
  const isCoachSuperAdmin = useGetCurrentUserType();
  const { user } = useContext(UserStoreContext);

  const [note, setNote] = useState('');

  const [addOrUpdateNote] = useMutation(ADD_OR_UPDATE_STUDENT_NOTES, {
    onCompleted: () => toast.success('Canvas Updated.'),
    refetchQueries: ['GET_STUDENT_NOTE']
  });

  const { loading, error, data } = useQuery(GET_STUDENT_NOTE, {
    variables: {
      student_id: user.id,
      team_id: currentCoachTeam.id
    }
  });

  useEffect(() => {
    if (!loading && !error && data) {
      setNote(data.getStudentNoteBy?.note);
    }
  }, [data]);

  if (error) {
    return <p>Error: {error.message}</p>;
  }

  return (
    <Formik
      enableReinitialize={true}
      initialValues={{
        created_by: currentUser.id,
        note,
        student_id: user.id,
        student_team_id: currentCoachTeam.id
      }}
      key={note}
      //  validationSchema={noteStudentValidationSchema}
      onSubmit={async (values, { setSubmitting }) => {
        await addOrUpdateNote({
          variables: {
            input: values
          }
        });

        setSubmitting(false);
      }}
    >
      {({
          values,
          isSubmitting,
          handleBlur,
          handleChange,
          handleSubmit
        }) => (
        <form onSubmit={handleSubmit}>
          <Dimmer active={loading} loader={loading}>
            <Card.Body className="p-0 mb-4">
              <Form.Textarea
                //  className={errors.note && 'is-invalid'}
                name="note"
                onBlur={handleBlur}
                onChange={handleChange}
                placeholder="Depreciated. Use notes instead!"
                rows={2}
                type="text"
                value={values.note}
                disabled={!isCoachSuperAdmin}
              />
              {/* {errors.note && (
                <span className="field-error text-danger">{errors.note}</span>
              )}
              <Button.List className="mt-4" align="right">
                <Button
                //  disabled={isSubmitting}
                  loading={isSubmitting}
                  pill
                  icon="save"
                  color="gray-dark"
                  size="sm"
                  type="submit"
                  disabled
                />
              </Button.List>
              */}
            </Card.Body>
          </Dimmer>
        </form>
      )}
    </Formik>
  );
};

export default observer(AddStudentNoteForm);
