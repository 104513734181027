import React from 'react';
import Modal from '../../Modal';
import GearForm from '../Form/GearForm';

interface GearCardModalFormProps {
  isModalOpen: boolean;
  toggleModal: any;
  userGear: any;
  studentId: number;
}



const GearCardModalForm = ({
                             isModalOpen,
                             toggleModal,
                             userGear,
                             studentId
                           }: GearCardModalFormProps) => {


  return (
    <Modal
      content={
        <GearForm userGear={userGear} toggleModal={toggleModal} studentId={studentId} isModal={true}/>
      }
      open={isModalOpen}
      //  title={userGear?.id ? "Edit Gear" : "Add Gear"}
      onClose={() => toggleModal(!isModalOpen)}
    />
  );
};

export default GearCardModalForm;
