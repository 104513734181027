import gql from "graphql-tag";

export const GET_CAMP_ROLES = gql`
  query GET_CAMP_ROLES($campId: ID) {
    campRoles(campId: $campId) {
      roleId
      key
      name
      isExists
    }
  }
`;
