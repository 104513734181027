import { Grid, StampCard, Text } from 'tabler-react'
import formatNumber from 'utils/numberFormat'
type EventRegistrationTotalConfirmedProps = {
  participantCountByStatus: any
  confirmedOnly: boolean
  onClick: (param) => void
}
const EventRegistrationTotalConfirmed = ({
  participantCountByStatus,
  confirmedOnly,
  onClick
}: EventRegistrationTotalConfirmedProps) => {
  return (
    <EventRegistrationTotalConfirmedInner
      participantCountByStatus={participantCountByStatus}
      confirmedOnly={confirmedOnly}
      onClick={onClick}
    />
  )
}

const EventRegistrationTotalConfirmedInner = ({
  participantCountByStatus,
  confirmedOnly,
  onClick
}) => {
  const otherStatusKeys = participantCountByStatus.filter(
    (status) =>
      !['Confirmed', 'Pending', 'Waitlist'].includes(status.type) &&
      !['Rejected', 'Withdrawn', 'Confirmed', 'Pending', 'Waitlist'].includes(
        status.name
      )
  )
  const waitlistCount = participantCountByStatus.find(
    (status) => status.type === 'Waitlist' || status.name === 'Waitlist'
  ) ?? { name: 'Confirmed', count: 0 }
  const firstStatus = otherStatusKeys.shift() ?? []

  const confirmedStatus = participantCountByStatus.find(
    (status) => status.type === 'Confirmed' || status.name === 'Confirmed'
  ) ?? { name: 'Confirmed', count: 0 }
  const pendingStatus = participantCountByStatus.find(
    (status) => status.type === 'Pending' || status.name === 'Pending'
  ) ?? { name: 'Confirmed', count: 0 }

  return (
    <>
      <Grid.Col sm={6} lg={3} xs={12} className="pl-0">
        <span onClick={() => onClick('confirmed')} className="cursor-pointer">
          <StampCard
            className="mb-0"
            color="success"
            icon="users"
            header={
              <Text>
                {formatNumber(confirmedStatus.count ?? '0')}{' '}
                <small>{confirmedStatus.name}</small>
              </Text>
            }
            footer={
              <Text>
                {formatNumber(pendingStatus.count ?? '0')} {pendingStatus.name}
              </Text>
            }
          />
        </span>
      </Grid.Col>
      {/* {participantCountByStatus.phase1 > 0 && (
        <Grid.Col sm={6} lg={3} xs={12} className="pl-0">
          <StampCard
            className="mb-0"
            color="primary"
            icon="users"
            header={
              <Text>
                {formatNumber(participantCountByStatus.phase1 ?? '0')}{' '}
                <small>Phase 1</small>
              </Text>
            }
            footer={
              <Text>
                {formatNumber(participantCountByStatus.phase2 ?? '0')} Phase 2
              </Text>
            }
          />
        </Grid.Col>
      )} */}
      {!confirmedOnly && (
        <>
          <Grid.Col sm={6} lg={3} xs={12} className="pl-0">
            <span onClick={() => onClick('others')} className="cursor-pointer">
              <StampCard
                className="mb-0"
                color="info"
                icon="users"
                header={
                  <Text key={`status-count-${firstStatus.name}`}>
                    {formatNumber(firstStatus.count ?? 0)}{' '}
                    <small>{firstStatus.name}</small>
                  </Text>
                }
                footer={otherStatusKeys.map((status) => (
                  <Text key={`status-count-${status.name}`}>
                    {formatNumber(status.count ?? 0)} {status.name}
                  </Text>
                ))}
              />
            </span>
          </Grid.Col>
          {waitlistCount && (
            <Grid.Col sm={6} lg={3} xs={12} className="pl-0">
              <span
                onClick={() => onClick('waitlist')}
                className="cursor-pointer"
              >
                <StampCard
                  className="mb-0"
                  color="info"
                  icon="pause"
                  header={
                    <Text>
                      {formatNumber(waitlistCount.count ?? 0)}{' '}
                      <small>{waitlistCount.name}</small>
                    </Text>
                  }
                />
              </span>
            </Grid.Col>
          )}

          <Grid.Col sm={6} lg={3} xs={12} className="pl-0">
            <span
              onClick={() => onClick('rejected')}
              className="cursor-pointer"
            >
              <StampCard
                className="mb-0"
                color="danger"
                icon="x"
                header={
                  <Text>
                    {formatNumber(
                      participantCountByStatus.find(
                        (status) => status.name === 'Rejected'
                      )?.count ?? '0'
                    )}{' '}
                    <small>Rejected</small>
                  </Text>
                }
                footer={
                  <Text>
                    {formatNumber(
                      participantCountByStatus.find(
                        (status) => status.name === 'Withdrawn'
                      )?.count ?? '0'
                    )}{' '}
                    Withdrawn
                  </Text>
                }
              />
            </span>
          </Grid.Col>
        </>
      )}
    </>
  )
}

export default EventRegistrationTotalConfirmed
