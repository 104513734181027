import { Formik } from 'formik'
import * as Yup from 'yup'
import React from 'react'
import { Button, Card, Form, Text } from 'tabler-react'
import { validateEmail } from 'utils/validators'
import useResetPasswordByEmail from 'modules/common/hooks/useResetPasswordByEmail'
import FormField from 'components/FormField'

const ResetPasswordForm = () => {
  const { resetPasswordByEmail } = useResetPasswordByEmail()

  const passwordValidationSchema = Yup.object().shape({
    email: Yup.string().required('This field is required.')
  })

  const renderForm = ({ values, handleChange, handleSubmit, isSubmitting }) => (
    <>
      <Form className="card" onSubmit={handleSubmit}>
        <Card.Body className="p-6">
          <Card.Title>Oops... forgot your password?</Card.Title>
          <p className="text-muted">
            Enter your email address and we will send you a temporary password.
          </p>
          <FormField
            appendleft={
              <Button icon={'mail'} color={'secondary text-muted'} disabled />
            }
            name="email"
            placeholder="Email"
            onChange={handleChange}
            value={values.email}
            type="text"
          />
          <Form.Footer>
            <Button block color="primary" type="submit" disabled={isSubmitting}>
              RESET PASSWORD
            </Button>
          </Form.Footer>
        </Card.Body>
      </Form>
      <Text className="d-block text-center text-muted">
        <i className="fe fe-chevron-left mr-2" />
        <a href="/login">LOGIN</a>
      </Text>
    </>
  )

  return (
    <Formik
      validationSchema={passwordValidationSchema}
      enableReinitialize={true}
      initialValues={{
        email: ''
      }}
      onSubmit={(values, { setSubmitting, resetForm }) => {
        if (validateEmail(values.email)) {
          resetPasswordByEmail({
            variables: {
              email: values.email
            }
          })
        }

        setSubmitting(false)
        resetForm()
      }}
    >
      {(formikData) => renderForm(formikData)}
    </Formik>
  )
}

export default ResetPasswordForm
