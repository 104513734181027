import Modal from "components/Modal";
import React from "react";
import { Types } from "types/graphql";
import AddLicenseForm from "../forms/AddLicenseForm";

interface Props {
  orgId: number;
  orgName: string;
  orgSlug: string;
  studentId: number;
  toggle?: () => void;
  license?: Types.OrgCredential;
  isOpen: boolean;
}

const AddLicenseModal: React.FC<Props> = ({
  orgId,
  orgName,
  studentId,
  orgSlug,
  toggle,
  license,
  isOpen,
}) => {
  return (
    <Modal
      open={isOpen}
      onClose={toggle}
      title="License"
      content={
        <AddLicenseForm
          orgId={orgId}
          orgName={orgName}
          orgSlug={orgSlug}
          studentId={studentId}
          license={license}
          toggle={toggle}
        />
      }
    />
  );
};

export default AddLicenseModal;
