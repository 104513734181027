import { useMutation, useQuery } from '@apollo/client'
import { Loading } from 'components/Loading'
import { DELETE_PAYMENT } from 'graphql/DELETE_PAYMENT'
import { GET_INVOICE } from 'graphql/GET_INVOICE'
import { GET_INVOICE_PAYMENTS } from 'graphql/GET_INVOICE_PAYMENTS'
import { UPDATE_INVOICE } from 'graphql/UPDATE_INVOICE'
import { GET_INVOICE_PAYMENTS as GET_INVOICE_PAYMENTS_TYPE } from 'graphql/types/GET_INVOICE_PAYMENTS'
import DeletePaymentModal from 'modules/payment/modals/DeletePaymentModal'
import React, { useState } from 'react'
import { toast } from 'react-toastify'
import { Alert, Dropdown, Icon, Table } from 'tabler-react'
import { formatMoney } from 'utils/numberFormat'
import useGetCurrentUserType, {
  useGetUserIsAdmin,
  useGetUserIsStudent
} from 'modules/common/hooks/useGetCurrentUserType'
import { UPDATE_PAYMENT_STATUS } from '../../../../graphql/UPDATE_PAYMENT_STATUS'
import UpdatePayementModal from '../../../payment/modals/UpdatePaymentModal'

interface InvoicePaymentsTableProps {
  invoiceId: number
  payments: any
}

const InvoicePaymentsTable = ({ invoiceId }: { invoiceId: number }) => {
  const { loading, error, data } = useQuery<GET_INVOICE_PAYMENTS_TYPE>(
    GET_INVOICE_PAYMENTS,
    {
      variables: {
        invoice_id: invoiceId
      }
    }
  )

  if (loading || !data) {
    return <Loading />
  }

  if (error) {
    return <p>Error: {error.message}</p>
  }

  const payments =
    data.getInvoicePayments.map(
      ({
        id,
        amount,
        total,
        fees,
        manual_payment,
        card_brand,
        created_on,
        processed_by,
        status
      }) => ({
        id,
        amount,
        total,
        fees,
        manual_payment,
        card_brand,
        created_on,
        processed_by,
        status
      })
    ) ?? []

  return <InvoicePaymentsTableInner invoiceId={invoiceId} payments={payments} />
}

const InvoicePaymentsTableInner = ({
  invoiceId,
  payments
}: InvoicePaymentsTableProps) => {
  const [isDeletePaymentModalOpen, setisDeletePaymentModalOpen] =
    useState(false)
  const [isUpdatePaymentStatusModalOpen, setIsUpdatePaymentStatusModalOpen] =
    useState(false)
  const [paymentId, setPaymentId] = useState<number | null>(null)
  const [status, setStatus] = useState<string>('')
  const isStudent = useGetUserIsStudent()
  const isAdmin = useGetUserIsAdmin()
  const { isCoachAdmin } = useGetCurrentUserType()
  const toggleDeletePaymentModal = () => {
    setisDeletePaymentModalOpen(!isDeletePaymentModalOpen)
  }
  const toggleUpdatePaymentStatusModal = () => {
    setIsUpdatePaymentStatusModalOpen(!isUpdatePaymentStatusModalOpen)
  }

  const [deletePaymentMutation] = useMutation(DELETE_PAYMENT, {
    onCompleted: () => {
      toggleDeletePaymentModal()
      toast.success('Payment Deleted.')
    },
    refetchQueries: [
      {
        query: GET_INVOICE_PAYMENTS,
        variables: {
          invoice_id: invoiceId
        }
      }
    ]
  })
  const [updatePaymentStatus] = useMutation(UPDATE_PAYMENT_STATUS, {
    onCompleted: () => {
      toggleUpdatePaymentStatusModal()
      toast.success(`Payment ${status}.`)
    },
    refetchQueries: [
      {
        query: GET_INVOICE_PAYMENTS,
        variables: {
          invoice_id: invoiceId
        }
      }
    ]
  })

  const [updateInvoice] = useMutation(UPDATE_INVOICE, {
    variables: {
      updateInvoiceInput: {
        id: invoiceId,
        status: 'Pending'
      }
    },
    refetchQueries: [
      {
        query: GET_INVOICE,
        variables: {
          id: invoiceId
        }
      }
    ]
  })

  const onDeletePayment = async () => {
    await deletePaymentMutation({
      variables: {
        payment_id: paymentId
      }
    })

    await updateInvoice()
  }

  const onUpdatePaymentStatus = async () => {
    await updatePaymentStatus({
      variables: {
        payment_id: paymentId,
        status
      }
    })

    await updateInvoice()
  }

  const PaymentTableRowPopulated = ({ payment }) => {
    return (
      <Table.Row>
        <Table.Col>{payment.processed_by}</Table.Col>
        <Table.Col>
          {payment.card_brand ? payment.card_brand : payment.manual_payment}
        </Table.Col>
        <Table.Col>{formatMoney(payment.amount)}</Table.Col>
        <Table.Col>
          {payment.fees > 0 ? formatMoney(payment.fees) : ''}
        </Table.Col>
        <Table.Col>
          {new Date(payment.created_on).toLocaleDateString()}
        </Table.Col>
        <Table.Col>
          {payment.status}
          {!isStudent && payment.processed_by === 'Manual Payment' && (
            <Dropdown
              className="btn btn-white text-muted btn-sm pl-0 pr-0 float-right"
              toggle={false}
              icon="more-vertical"
              isNavLink={true}
              position="bottom-end"
              arrow={true}
              arrowPosition="right"
              itemsObject={
                isAdmin || isCoachAdmin
                  ? [
                      {
                        value: (
                          <>
                            <Icon link name="trash" /> Delete
                          </>
                        ),
                        onClick: (e) => {
                          e.preventDefault()
                          setPaymentId(Number(payment.id))
                          toggleDeletePaymentModal()
                        }
                      }
                    ]
                  : [
                      {
                        value: (
                          <>
                            <Icon link name="x-circle" /> Void
                          </>
                        ),
                        onClick: (e) => {
                          e.preventDefault()
                          if (payment.status === 'Void') return
                          setStatus('Void')
                          setPaymentId(Number(payment.id))
                          toggleUpdatePaymentStatusModal()
                        }
                      },
                      {
                        value: (
                          <>
                            <Icon link name="alert-circle" /> Refund
                          </>
                        ),
                        onClick: (e) => {
                          e.preventDefault()
                          if (payment.status === 'Refund') return
                          setStatus('Refund')
                          setPaymentId(Number(payment.id))
                          toggleUpdatePaymentStatusModal()
                        }
                      }
                    ]
              }
            />
          )}
        </Table.Col>
      </Table.Row>
    )
  }

  return (
    <>
      <Table className="card-table">
        <Table.Header>
          <Table.Row>
            <Table.ColHeader>Processed By</Table.ColHeader>
            <Table.ColHeader>Payment Type</Table.ColHeader>
            <Table.ColHeader>Amount</Table.ColHeader>
            <Table.ColHeader>Fees</Table.ColHeader>
            <Table.ColHeader>Date</Table.ColHeader>
            <Table.ColHeader></Table.ColHeader>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {payments.length > 0 ? (
            payments.map((payment) => (
              <PaymentTableRowPopulated payment={payment} key={payment.id} />
            ))
          ) : (
            <Table.Row>
              <Table.Col colSpan="8">
                <Alert type="info text-center">
                  <strong>No Payments</strong>
                </Alert>
              </Table.Col>
            </Table.Row>
          )}
        </Table.Body>
      </Table>
      <DeletePaymentModal
        isModalOpen={isDeletePaymentModalOpen}
        onConfirm={onDeletePayment}
        toggleModal={toggleDeletePaymentModal}
      />
      <UpdatePayementModal
        isModalOpen={isUpdatePaymentStatusModalOpen}
        onConfirm={onUpdatePaymentStatus}
        toggleModal={toggleUpdatePaymentStatusModal}
        status={status}
      />
    </>
  )
}

export default InvoicePaymentsTable
