import useGetEventOrganizers from "modules/user/roles/hooks/useGetEventOrganizers";
import React, { useMemo } from "react";
import { useParams } from "react-router";
import { Avatar, Card, Dimmer, Grid, Icon, Text } from "tabler-react";
import { formatPhoneNumber } from "../../../../../utils/phoneFormat";

const EventOrganizerList = () => {
  const { eventId } = useParams<{ eventId: string }>();
  const { eventOrganizersData, eventOrganizersDataLoading } =
    useGetEventOrganizers({
      eventId: Number(eventId),
    });

  const eventOrganizerRows = useMemo(
    () => eventOrganizersData?.getEventOrganizers.map((item) => item) ?? [],
    [eventOrganizersData?.getEventOrganizers],
  );

  return (
    <>
      {eventOrganizerRows?.length > 0 && (
        <Card>
          <Card.Header>
            <Card.Title>Event Organizers</Card.Title>
          </Card.Header>
          <Card.Body>
            <Dimmer
              active={eventOrganizersDataLoading}
              loader={eventOrganizersDataLoading}
            >
              <Grid.Row>
                {eventOrganizerRows?.map((eventOrganizer) => (
                  <Grid.Col
                    key={eventOrganizer.id}
                    xs={12}
                    sm={12}
                    md={6}
                    lg={4}
                  >
                    <Card className="p-2">
                      <Card.Body className="p-1">
                        <Avatar
                          size="lg"
                          className="float-left mr-3"
                          imageURL={
                            (eventOrganizer.coach_profile_avatar &&
                              `/api/s3/uploads/${eventOrganizer.coach_profile_avatar}`) ||
                            "//www.gravatar.com/avatar?d=mp"
                          }
                          to="#"
                        />

                        <h5 className="mb-0">{`${eventOrganizer.coach_first_name} ${eventOrganizer.coach_last_name}`}</h5>
                        <Text.Small>
                          <span>
                            {eventOrganizer.coach_email &&
                              eventOrganizer.coach_email_private === 0 && (
                                <a
                                  href={"mailto:" + eventOrganizer.coach_email}
                                >
                                  <Icon name="mail" className="mr-2" />
                                </a>
                              )}
                          </span>
                          <span>
                            {eventOrganizer.coach_phone_country_code &&
                              eventOrganizer.coach_phone &&
                              eventOrganizer.coach_phone_private !== 1 && (
                                <a
                                  href={`sms:+${
                                    eventOrganizer.coach_phone_country_code +
                                      eventOrganizer.coach_phone
                                  }`}
                                >
                                  <Icon
                                    name="message-circle"
                                    className="mr-2"
                                  />
                                </a>
                              )}
                          </span>
                        </Text.Small>
                      </Card.Body>
                    </Card>
                  </Grid.Col>
                ))}
              </Grid.Row>
            </Dimmer>
          </Card.Body>
        </Card>
      )}
    </>
  );
};

export default EventOrganizerList;
