import { useQuery } from '@apollo/client'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import InfiniteScroll from 'react-infinite-scroll-component'
import Moment from 'react-moment'
import { useParams } from 'react-router'
import { Alert, Card, Dimmer, Grid, Table, Text } from 'tabler-react'

import { GET_ALL_USER_EVENTS } from '../../graphql/GET_ALL_USER_EVENTS'
import UserEventContent from './UserEventContent'

export enum ETransactionTypes {
  purchased = 'added to',
  used = 'used from',
  transfer = 'transferred to',
  expiry = 'expired from',
  credit = 'credited to',
  cancel = 'canceled from',
  purchase = 'purchased from',
  pending = 'pending from'
}

export enum EUserEvents {
  skycru_terms = 'agreed to the Terms of use and Privacy Policy',
  checkin_location = 'checked in location',
  checkout_location = 'checked out location',
  digital_waiver = 'signed a digital Waiver',
  physical_waiver = 'signed a physical Waiver',
  gear_check = 'gear checked',
  skycru_login = 'logged in',
  skycru_logout = 'logged out',
  checkin_camp = 'checked in camp',
  checkout_camp = 'checked out camp',
  update_slots = 'updated a slot',
  slot_added = 'added a slot',
  slot_removed = 'removed a slot',
  skill_added = 'checked off a skill',
  skill_removed = 'unchecked a skill',
  user_loaded = 'added to load',
  user_unloaded = 'removed from load',
  deck_added = 'added to dashboard',
  deck_removed = 'removed from dashboard',
  user_note = 'notes updated',
  id_verify = 'ID verified',
  reg_added = 'registration added',
  reg_removed = 'registration removed',
  team_association = 'team association',
  user_update = 'user updated',
  checkin_dz = 'checked in dz',
  checkout_dz = 'checked out dz',
  password_reset = 'reset password',
  reserve_repack = 'reserve repack',
  job_added = 'Job Created',
  reg_status = 'status',
  pay_complete = 'registration payment',
  pay_delete = 'registration payment deleted',
  pay_refund = 'registration payment refunded',
  pay_void = 'payment voided',
  crm_message = 'CRM message created',
  unote_added = 'user note added',
  unote_update = 'user note updated',
  unote_delete = 'user note deleted',
  reg_ques_updated = 'question updated',
  job_status = 'job status updated'
}

export enum EUserEventsId {
  skycru_terms = 1,
  checkin_location = 2,
  checkout_location = 3,
  digital_waiver = 4,
  physical_waiver = 5,
  gear_check = 6,
  skycru_login = 7,
  skycru_logout = 8,
  checkin_camp = 9,
  checkout_camp = 10,
  update_slots = 11,
  slot_added = 12,
  slot_removed = 13,
  skill_added = 14,
  skill_removed = 15,
  user_loaded = 16,
  user_unloaded = 17,
  deck_added = 18,
  deck_removed = 19,
  user_note = 20,
  id_verify = 21,
  reg_added = 22,
  reg_removed = 23,
  team_association = 24,
  user_update = 25,
  checkin_dz = 26,
  checkout_dz = 27,
  password_reset = 28,
  reserve_repack = 29,
  job_added = 33,
  reg_status = 34,
  pay_complete = 35,
  pay_delete = 36,
  pay_refund = 37,
  reg_ques_updated = 38,
  unote_added = 39,
  unote_update = 41,
  unote_delete = 40,
  crm_message = 42,
  job_status = 43,
  pay_void = 44
}

const PAGE_LIMIT = 30;

const UserEvents = () => {
  const [userEvents, setUserEvents] = useState([]);
  const [pageNum, setPageNum] = useState<number>(2);
  const [totalCount, setTotalCount] = useState<number>(0);

  const { studentId }: any = useParams();

  let initArgs = {
    limit: PAGE_LIMIT,
    page: 1,
    filter: {}
  };

  if (studentId) {
    initArgs = {
      ...initArgs,
      filter: {
        studentId: studentId
      }
    };
  }

  const { loading, error, data, fetchMore } = useQuery(GET_ALL_USER_EVENTS, {
    variables: initArgs
  });

  useEffect(() => {
    if (!loading && !error && data) {
      const { getAllUserEvents, getTransactions } = data;

      setUserEvents([
        ...getAllUserEvents.userEvents,
        ...getTransactions.transactions
      ]);
      setTotalCount(getAllUserEvents.totalCount + getTransactions.totalCount);
    }
  }, [data, pageNum, totalCount]);

  if (error) {
    return <p>Error: ${error.message}</p>;
  }

  const onFetchMore = () => {
    setPageNum((prevNum) => prevNum + 1);
    return fetchMore({
      updateQuery: (cache, { fetchMoreResult }) => {
        if (!fetchMoreResult) {
          return cache;
        }

        return {
          ...cache,
          getAllUserEvents: {
            ...cache.getAllUserEvents,
            userEvents: [
              ...cache.getAllUserEvents.userEvents,
              ...fetchMoreResult.getAllUserEvents.userEvents
            ]
          },
          getTransactions: {
            ...cache.getTransactions,
            transactions: [
              ...cache.getTransactions.transactions,
              ...fetchMoreResult.getTransactions.transactions
            ]
          }
        };
      },
      variables: {
        limit: PAGE_LIMIT,
        page: pageNum
      }
    });
  };

  // Registrations - user_registrations, slot duration, user_events content


  // Transactions - student_times transactions, user_events content
  const TransactionsContent = (userEvent) => {
    const {
      coachFirstName,
      coachLastName,
      created_on,
      minutes,
      notes,
      studentFirstName,
      studentLastName,
      teamName,
      transactionTypeSlug
    } = userEvent.event;

    return (
      <>
      <Grid.Row className="mb-3">
        <Grid.Col lg={8}>
          <strong>
            {coachFirstName} {coachLastName}
          </strong>{' '}
          updated an account at <strong>{teamName}</strong>.{' '}
          <strong>{minutes} minutes</strong>{' '}
          {ETransactionTypes[transactionTypeSlug]}{' '}
          <strong>
            {studentFirstName} {studentLastName}
          </strong>
          {notes && <Text>Note: {notes}</Text>}
          <div>
            <small>
              <i>
                <Moment fromNow>{moment(created_on).format()}</Moment>
              </i>
            </small>
          </div>
        </Grid.Col>
        <Grid.Col lg={4}>
        <Text className="ml-auto float-right">
          {moment(created_on).format('M/D/YY - h:mm:ssa')}
        </Text>
        </Grid.Col>
        </Grid.Row>
      </>
    );
  };

  // Sort user events and transactions by created_on
  if (userEvents) {
    userEvents.sort(
      (userEventA, userEventB) =>
        new Date(userEventB.created_on).getTime() -
        new Date(userEventA.created_on).getTime()
    );
  }

  return (
    <Card>
      <Card.Header>
        <Card.Title>User Events</Card.Title>
      </Card.Header>
      <Card.Body>
        <Dimmer active={loading} loader={loading}>
          <InfiniteScroll
            className="overflow-visible pb-3"
            dataLength={userEvents.length}
            hasMore={userEvents.length < totalCount}
            loader={<Dimmer active={true} loader={true} />}
            next={onFetchMore}
            scrollThreshold="210px"
            endMessage={
              <Alert className="text-center" type="primary">
                No {userEvents.length !== 0 && 'more'} user activity to show.
              </Alert>
            }
          >
            <Table>
              <Table.Body>
                {userEvents.map((userEvent, idx) => (
                  <Table.Row key={idx}>

                      {userEvent.user_events_id ? (
                        <UserEventContent event={userEvent} />
                      ) : (
                        <TransactionsContent event={userEvent} />
                      )}

                  </Table.Row>
                ))}
              </Table.Body>
            </Table>
          </InfiniteScroll>
        </Dimmer>
      </Card.Body>
    </Card>
  );
};

export default UserEvents;
