import { useMutation } from "@apollo/client";
import { Formik } from "formik";
import React from "react";
import Select from "react-select";
import { Button, Form, Grid } from "tabler-react";
import { GET_USER_REGISTRATIONS_BY_IDS } from "../../../graphql/GET_USER_REGISTRATIONS_BY_IDS";
import { UPDATE_STUDENT_MUTATION } from "../../../graphql/UPDATE_STUDENT_MUTATION";
import { SHIRT_SIZES } from "../../constants";
import Modal from "../../Modal";

interface IRegistrationStudentDetailShirtSizeModalProps {
  isModalOpen: boolean;
  toggleModal: any;
  student: any;
}

const RegistrationStudentDetailShirtSizeModal = ({
  isModalOpen,
  toggleModal,
  student,
}: IRegistrationStudentDetailShirtSizeModalProps) => {
  const [updateStudent] = useMutation(UPDATE_STUDENT_MUTATION);

  const renderForm = ({
    values,
    handleSubmit,
    isSubmitting,
    setFieldValue,
  }) => (
    <form onSubmit={handleSubmit}>
      <Grid.Row className="mb-4">
        <Grid.Col xs={12} sm={12} lg={6}>
          <Form.Group label="Shirt Size">
            <Select
              backspaceRemovesValue={true}
              escapeClearsValue={true}
              getOptionLabel={(option: { name: string }) => option.name}
              getOptionValue={(option: { value: number }) => option.value}
              isClearable={true}
              name="shirt_size_id"
              options={SHIRT_SIZES}
              onChange={(params: { value: number }) => {
                setFieldValue("shirt_size_id", params?.value ?? null);
              }}
              styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
              menuPortalTarget={document.body}
              value={
                values.shirt_size_id &&
                SHIRT_SIZES.filter(
                  (size) => size.value === values.shirt_size_id,
                )
              }
            />
          </Form.Group>
        </Grid.Col>
      </Grid.Row>
      <Grid.Row>
        <Grid.Col className="pt-2" xs={12} sm={12} lg={12}>
          <Button
            type="submit"
            disabled={isSubmitting}
            pill
            className="btn-gray-dark float-right btn-sm"
          >
            {isSubmitting ? "Processing..." : "SAVE"}
          </Button>
        </Grid.Col>
      </Grid.Row>
    </form>
  );
  return (
    <Modal
      content={
        <Formik
          enableReinitialize={true}
          initialValues={{
            shirt_size_id: student?.shirt_size_id,
          }}
          onSubmit={async (
            values,
            { resetForm, setSubmitting, setFieldError },
          ) => {
            updateStudent({
              variables: {
                student: {
                  id: student?.student_id,
                  shirt_size_id: values.shirt_size_id,
                },
              },
              refetchQueries: [
                {
                  query: GET_USER_REGISTRATIONS_BY_IDS,
                  variables: {
                    userRegistrationsId: Number(student?.user_registrations_id),
                  },
                },
              ],
            });
            toggleModal(!isModalOpen);
            setSubmitting(false);
            resetForm();
          }}
        >
          {(formikData) => renderForm(formikData)}
        </Formik>
      }
      open={isModalOpen}
      title={`${student.first_name} ${student.last_name}`}
      onClose={() => toggleModal(!isModalOpen)}
    />
  );
};

export default RegistrationStudentDetailShirtSizeModal;
