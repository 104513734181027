import { applySnapshot, onSnapshot } from 'mobx-state-tree';
import React, { createContext, ReactNode, useState } from 'react';

import {
  EventStore,
  EventStoreType,
  EventStoreDefault
} from '../models/EventStore';

export const EventStoreContext = createContext<EventStoreType | null>(null);

interface EventStoreProvider {
  children: ReactNode;
}

const getOrCreateStore = () => {
  const eventStore = EventStoreDefault();
  const initData = localStorage.getItem('events');

  if (initData) {
    applySnapshot(eventStore, JSON.parse(initData));
    return eventStore;
  } else {
    return EventStore.create();
  }
};

export const EventStoreProvider = (props: EventStoreProvider) => {
  const [eventStore] = useState(() => getOrCreateStore());

  onSnapshot(eventStore, (_snapshot) => {
    const snapshot = { ..._snapshot };
    const data = JSON.stringify(snapshot);
    localStorage.setItem('events', data);
  });

  return (
    <EventStoreContext.Provider value={eventStore}>
      {props.children}
    </EventStoreContext.Provider>
  );
};
