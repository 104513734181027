import gql from 'graphql-tag';

export const GET_STUDENT_RECENT_LOGBOOK_LOG = gql`
  query GET_STUDENT_RECENT_LOGBOOK_LOG($logbookLogFilter: LogbookLogFilter!) {
    getRecentLogbookLog(filter: $logbookLogFilter) {
      id
      date
    }
  }
`;
