import gql from 'graphql-tag';

export const UPDATE_AIRCRAFT = gql`
  mutation UPDATE_AIRCRAFT($aircraft: UpdateAircraftInput!) {
    updateAircraft(aircraft: $aircraft) {
      id
      name
      tail_number
      aircraft_type
      aircraft_notes
      capacity_max
      capacity_min
    }
  }
`;
