import { useQuery } from '@apollo/client';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin, { Draggable } from '@fullcalendar/interaction';
import timeGridPlugin from '@fullcalendar/timegrid';
import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router';
import { Alert, Card, Dimmer, Grid } from 'tabler-react';
import { GET_CAMP_BY_ID } from '../../graphql/GET_CAMP_BY_ID';
import { Types } from '../../types/graphql'

const SlotCalendarView = () => {
  const { eventId } = useParams<{ eventId: string }>();

  const [event, setEvent] = useState(null);
  const [registeredStudents, setRegisteredStudents] = useState<
    Types.RegisteredStudents[]
  >([]);

  const { loading, error, data } = useQuery(GET_CAMP_BY_ID, {
    variables: {
      campId: Number(eventId)
    }
  });

  useEffect(() => {
    const draggableEl = document.getElementById('registered-students');
    new Draggable(draggableEl, {
      itemSelector: '.registered-student',
      eventData: (eventEl) => {
        const title = eventEl.getAttribute('title');
        const id = eventEl.getAttribute('data');
        return {
          title: title,
          id: id
        };
      }
    });
  }, []);

  useEffect(() => {
    if (!loading && data) {
      setEvent(data.getCampById);
      setRegisteredStudents(data.getCampById.registered_students);
    }
  }, [data]);  

  if (error) {
    return <p>Error: {error.message}</p>;
  }

  const hasRegisteredStudents = registeredStudents.length > 0;

  return (
    <Dimmer active={loading} loader={loading}>
      <Grid.Row>
        <Grid.Col lg={3} sm={12} xs="auto">
          <div className="card" id="registered-students">
            <Card.Header>
              <Card.Title><strong>{event?.camp_name}</strong><span className='d-block'>Participants</span></Card.Title>
            </Card.Header>
            <Card.Body>
              {!hasRegisteredStudents ? (
                <Alert type="info">
                  <strong>No Data</strong>
                </Alert>
              ) : (
                registeredStudents?.map(
                  (event) => (
                    <div
                      className="registered-student fc-h-event fc-daygrid-event fc-daygrid-block-event cursor-move mb-1 p-1"
                      title={`${event.first_name} ${event.last_name}`}
                      //   data={event.id}
                      key={event.id}
                    >
                      <div className="fc-event-main">
                        {`${event.first_name} ${event.last_name}`}
                      </div>
                    </div>
                  )
                )
              )}
            </Card.Body>
          </div>
        </Grid.Col>

        <Grid.Col lg={9} sm={12} xs="auto">
          {!loading && event && (
            <FullCalendar
              allDaySlot={false}
              droppable={true}
              eventDurationEditable={false}
              editable={true}
              headerToolbar={{
                right: 'title',
                left: 'today prev,next'
              }}
              initialDate={event.start}
              initialView="timeGridWeek"
              plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
              rerenderDelay={10}
              // ref={this.calendarComponentRef}
              // weekends={this.state.calendarWeekends}
              // events={calendarEvents.slice()}
              // eventDrop={this.drop}
              // drop={this.drop}
              // eventReceive={this.eventReceive}
              // eventClick={this.eventClick}
              // selectable={true}
            />
          )}
        </Grid.Col>
      </Grid.Row>
    </Dimmer>
  );
};

export default SlotCalendarView;
