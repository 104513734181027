import { ECampFeeTypes } from 'modules/camp/constants';
import { Types } from '../../../../types/graphql'

export const calcTotalFees = (params: {
  camp_fees?: number | null;
  user_registration_options?: Types.UserRegistrationOption[],
  slot_duration?: number | null;
  totalTime?: { total_duration?: number | null } | null;
  default_jump_price?: number | null;
  jump_quantity?: number | null;
} | Types.RegisteredStudents) => {
  const {
    slot_duration,
    totalTime,
    default_jump_price,
    jump_quantity
  } = params;
  const camp_fees = params.camp_fees ?? 0
  const user_registration_options = params.user_registration_options ?? []

  return (
    user_registration_options?.reduce((sum, option) => {
      switch (option.fee_type) {
        case ECampFeeTypes.slot:
          if (totalTime)
            return (
              sum +
              (Number(totalTime?.total_duration) / slot_duration) *
                option.option_value
            );

          return sum;

        case ECampFeeTypes.hour: {
          if (totalTime)
            return (
              sum +
              (Number(totalTime?.total_duration) / 60) *
                Number(option.option_value)
            );

          return sum;
        }

        case ECampFeeTypes.event:
          return sum + option.quantity * option.option_value;

        case ECampFeeTypes.jump:
          return sum + option.quantity * option.option_value;

        case ECampFeeTypes.day:
        default:
          return sum;
      }
    }, 0) +
    camp_fees +
    Number(default_jump_price) * jump_quantity
  );
};
