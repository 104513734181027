import moment from "moment/moment";
import React, { useContext, useEffect, useState } from "react";
import { EUserEvents, EUserEventsId } from "./UserEvents";
import { UserStoreContext } from "../../contexts/userStoreContext";
import { useMutation, useQuery } from "@apollo/client";
import { GET_USER_EVENT_BY_STUDENT_AND_TYPE } from "../../graphql/GET_USER_EVENT_BY_STUDENT_AND_TYPE";
import { Button, Icon } from 'tabler-react';
import { observer } from "mobx-react";
import Modal from "../Modal";
import { ADD_USER_EVENT } from "../../graphql/ADD_USER_EVENT";
import { toast } from "react-toastify";
import { useRootStore } from "../../hooks";
import useGetCurrentUserType from 'modules/common/hooks/useGetCurrentUserType';


const UserVerify = () => {
  const { isAdmin, isCoachSuperAdmin, isDzm, isWtm } = useGetCurrentUserType();
  const { user } = useContext(UserStoreContext);
  const rootStore = useRootStore();
  const { currentCoachTeam } = rootStore;

  const [idVerified, setIdVerified] = useState<string>('');
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  const { loading, error, data } = useQuery(
    GET_USER_EVENT_BY_STUDENT_AND_TYPE,
    {
      variables: {
        studentId: parseInt(user.id),
        userEventTypeId: EUserEventsId.id_verify
      }
    }
  );

  const [verifyStudent] = useMutation(ADD_USER_EVENT, {
    onCompleted: () => toast.success('User verified')
  });

  useEffect(() => {
    if (!loading && !error && data) {
      setIdVerified(data.getUserEventByStudentAndType?.created_on);
    }
  }, [data]);
  const handleToggleModal = () => setIsModalOpen(!isModalOpen);

  const handleConfirm = async () => {
    const verifyId = await verifyStudent({
      variables: {
        userEvent: {
          student_id: user.id,
          team_id: currentCoachTeam.id,
          user_event_type_id: EUserEventsId.id_verify,
          status: EUserEvents.id_verify
        }
      }
    });

    if (verifyId.data.addUserEvent) {
      setIdVerified(verifyId.data.addUserEvent.created_on);
    }

    handleToggleModal();
  };


  return(
    <>
    {idVerified ? (
        <Button disabled size='sm'
                className='btn btn-white float-left pl-0'>
          <Icon
            name='check-circle'
            className='mr-2 ml-0 text-success'
          />{' '}
          ID Verified: {' '}
          {moment(idVerified).format('M/D/YY')}
        </Button>
      ) : (
        <>
          <Modal
            actions={
              <>
                <Button
                  autoFocus
                  pill
                  size="sm"
                  color='white'
                  onClick={handleToggleModal}
                >
                  CANCEL
                </Button>
                <Button pill size="sm" color='gray-dark' onClick={handleConfirm}>
                  YES
                </Button>
              </>
            }
            content={`You have verified valid photo identification?`}
            open={isModalOpen}
            onClose={handleToggleModal}
            title={`Verify ID`}
          />
          <Button
            color='secondary'
            size='sm'
            icon='user-check'
            className='float-left text-muted'
            onClick={(e) => {
              e.preventDefault();
              handleToggleModal();
            }}
            disabled={!isAdmin && !isDzm && !isWtm}
          >
            Verify ID
          </Button>
        </>
      )}
    </>
  )
}

export default observer(UserVerify);
