import React, { useMemo, useState } from 'react';
import { Table, Tag, Text } from 'tabler-react';
import Select from 'react-select';
import { useMutation, useQuery } from '@apollo/client';
import { GET_ALL_GEAR_MANUFACTURERS } from '../../graphql/GET_ALL_GEAR_MANUFACTURERS';
import { GET_ALL_GEAR_MODELS } from '../../graphql/GET_ALL_GEAR_MODELS';
import { ADD_OR_EDIT_GEAR_MODEL } from '../../graphql/ADD_OR_EDIT_GEAR_MODEL';
import { toast } from 'react-toastify';
import { Types } from '../../types/graphql'

interface ModelListItemProps {
  model: Types.GearModel;
}

const ModelListItem = ({ model }: ModelListItemProps) => {
  const [isEditting, setIsEditting] = useState<boolean>(false);
  const { data, loading, error } = useQuery(GET_ALL_GEAR_MANUFACTURERS);
  const manufacturers = useMemo(() => {
    if (!loading && !error && data) return data.getGearManufacturers;
    return null;
  }, [data, loading, error]);
  const [updateGearModel] = useMutation(ADD_OR_EDIT_GEAR_MODEL, {
    onCompleted: (response) => {
      if (response.updateGearModel) {
        toast.success('Model updated successfully');
      }
    }
  });
  const handleSubmit = async (id: number) => {
    await updateGearModel({
      variables: {
        updateGearModelInput: {
          id: model.id,
          gear_manufacturer_id: id
        }
      },
      refetchQueries: [{ query: GET_ALL_GEAR_MODELS }]
    });
    setIsEditting(false);
  };
  return (
    <Table.Row key={model.id}>
      <Table.Col>{model.id}</Table.Col>
      <Table.Col>{model.name}</Table.Col>
      <Table.Col><Tag>{model.gear_type_name}</Tag></Table.Col>
      <Table.Col>
        {isEditting && (
          <Select
            name="gear_manufacturer_id"
            isMulti={false}
            getOptionLabel={(
              option: Types.GearManufacturer
            ) => option.name}
            getOptionValue={(
              option: Types.GearManufacturer
            ) => option.id}
            options={manufacturers}
            placeholder="Select Manufacturer"
            onChange={(param) => handleSubmit(param.id)}
            value={manufacturers.filter(
              (gearManufacturer) =>
                gearManufacturer.id === model?.gear_manufacturer_id
            )}
          />
        )}
        {!isEditting && (
          <Text className="cursor-pointer" onClick={setIsEditting}>
            {model.gear_manufacturer_name ? (
              model.gear_manufacturer_name
            ) : (
              <span
                className="text-pink"
              >
                Who makes this?
              </span>
            )}
          </Text>
        )}
      </Table.Col>
    </Table.Row>
  );
};

export default ModelListItem;
