import gql from "graphql-tag";

export const GET_REGISTRATION_OPTIONS = gql`
  query GET_REGISTRATION_OPTIONS($teamId: ID!) {
    getRegistrationOptions(teamId: $teamId) {
      id
      name
      description
      option_value
      team_id
      option_type_id
      option_type_name
      fee_type_id
      fee_type_name
      location_id
      location_name
      campRegistrationId
      is_active
    }
  }
`;
