import gql from "graphql-tag";

export const GET_STUDENT_CAMPS = gql`
  query GET_STUDENT_CAMPS($getStudentCampsInput: GetStudentCampsInput!) {
    getStudentCamps(getStudentCampsInput: $getStudentCampsInput) {
      camp_fee_type
      camp_fees
      camp_id
      camp_image
      camp_name
      created_on
      end
      rating
      registration_code
      registration_type
      slot_duration
      start
      status
      status_type
      team_id
      team_name
      team_currency
      user_registrations_id
      location {
        name
      }
    }
  }
`;
