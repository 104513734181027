import { useQuery } from "@apollo/client";
import { useMemo } from "react";
import { GET_GROUPS } from "../../../graphql/GET_GROUPS";
import { GET_REGISTRATION_GROUPS } from "../../../graphql/GET_REGISTRATION_GROUPS";
import { Types } from "../../../types/graphql";

interface RegistrationGroupsProps {
  registrationId?: number;
  status?: string;
}
const useGetRegistrationGroups = ({
  registrationId,
  status,
}: RegistrationGroupsProps) => {
  const { data: groupsData, loading: groupsLoading } = useQuery(GET_GROUPS, {
    variables: {
      filter: {
        user_registration_id: registrationId,
        status,
      },
    },
  });

  const { data: groupMemberData, loading: groupMembersLoading } = useQuery(
    GET_REGISTRATION_GROUPS,
    {
      variables: {
        registrationId: registrationId,
      },
    },
  );
  const groups = useMemo(() => {
    let groupsList: Types.Group[] = [];
    if (!groupsLoading && groupsData) {
      groupsList = [...groupsData.groupList];
    }
    if (!groupMembersLoading && groupMemberData) {
      groupMemberData.getParticipantCampRegistrationGroups.forEach((group) => {
        const found = groupsList && groupsList.find((g) => g.id === group.id);
        if (!found) {
          groupsList.push(group);
        }
      });
    }
    return groupsList;
  }, [groupsData, groupsLoading, groupMemberData, groupMembersLoading]);

  return { data: groups, loading: groupsLoading || groupMembersLoading };
};

export { useGetRegistrationGroups };
