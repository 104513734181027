import gql from 'graphql-tag';

export const ADD_SEAL = gql`
  mutation ADD_SEAL($addSealInput: AddSealInput!) {
    addSeal(addSealInput: $addSealInput) {
      id
      rigger_seal
      student_id
      coach_id
      coach{
        first_name
        last_name
      }
    }
  }
`;
