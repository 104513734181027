import gql from "graphql-tag";

export const GET_LOADS_BY_TEAM = gql`
  query GET_LOADS_BY_TEAM($team_id: ID!, $date: String) {
    loads(teamId: $team_id, date: $date) {
      created_on
      id
      load_complete
      load_departure
      team_id
      load_name
      load_notes

      aircraft {
        id
        name
        tail_number
        aircraft_type
        aircraft_notes
        capacity_max
        capacity_min
      }

      location {
        id
        type
        name
      }

      status {
        slug
        name
      }
    }
  }
`;
