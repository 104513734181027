import React from 'react';
import { Container } from 'tabler-react';

import Wrapper from 'pages/layout';

import AddCoach from 'components/Coaches/Forms/AddCoach';

const CoachFormPage = (props) => {
  return (
    <Wrapper {...props} title="Coach Form">
      <Container>
        <AddCoach />
      </Container>
    </Wrapper>
  );
};
export default CoachFormPage;
