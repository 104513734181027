import React from 'react';
import Modal from '../../Modal';
import { Formik } from 'formik';
import { EDIT_ORG } from '../../../graphql/EDIT_ORG';
import { ADD_ORG } from '../../../graphql/ADD_ORG';
import { useMutation } from '@apollo/client';
import FormField from '../../FormField';
import { Button, Form, Grid, Icon } from 'tabler-react';
import PhoneInput from 'react-phone-input-2';
import Select from 'react-select';
import * as Yup from 'yup';
import ReactS3Uploader from 'react-s3-uploader';
import { toast } from 'react-toastify';
import { useRootStore } from '../../../hooks';

export const org_types = [
  { value: 'org', label: 'Organization' },
  { value: 'nac', label: 'NAC' }
];

const OrgFormValidation = Yup.object().shape({
  name: Yup.string().required('This field is required.')
});
const OrgFormModal = ({ isOpen, toggle, org }) => {
  const rootStore = useRootStore();
  const [addOrg] = useMutation(ADD_ORG, {
    refetchQueries: ['GET_ORGS']
  });
  const [editOrg] = useMutation(EDIT_ORG, {
    refetchQueries: ['GET_ORGS']
  });

  const onError = (error) => {
    toast.error(error);
    rootStore.setLoading(false);
  };

  const renderForm = (formData) => {
    return (
      <form onSubmit={formData.handleSubmit}>
        <Grid.Row>
          <Grid.Col width={12}>
            <Form.Group>
              <FormField
                label="Name"
                name="name"
                type="text"
                value={formData.values.name}
                onChange={formData.handleChange}
              />
            </Form.Group>
          </Grid.Col>
          <Grid.Col width={4}>
            <FormField
              label="Slug"
              name="slug"
              type="text"
              value={formData.values.slug}
              onChange={formData.handleChange}
            />
          </Grid.Col>
          <Grid.Col>
            <Form.Group label={'Type'}>
              <Select
                options={org_types}
                value={org_types.find(
                  (type) => type.value === formData.values.org_type
                )}
                onChange={(selectedOption) => {
                  formData.setFieldValue('org_type', selectedOption.value);
                }}
              />
            </Form.Group>
          </Grid.Col>
        </Grid.Row>
        <Grid.Row>
          <Grid.Col width={4}>
            <Form.Group>
              <FormField
                label="Email"
                name="email"
                type="email"
                value={formData.values.email}
                onChange={formData.handleChange}
              />
            </Form.Group>
          </Grid.Col>
          <Grid.Col width={4}>
            <Form.Group>
              <FormField
                label="Website"
                name="website"
                type="text"
                value={formData.values.website}
                onChange={formData.handleChange}
              />
            </Form.Group>
          </Grid.Col>
          <Grid.Col width={4}>
            <Form.Group label={'Phone'}>
              <PhoneInput
                inputProps={{
                  className: 'form-control w-100',
                  name: 'phone',
                  onChange: (e) => {
                    e.preventDefault();
                    const target = e.target;

                    const phone = target.value.split(' ');
                    const countryCode = phone.shift().replace(/\D/g, '');

                    formData.setFieldValue(
                      'phone',
                      phone.join('').replace(/\D/g, '')
                    );
                    formData.setFieldValue('phone_country_code', countryCode);
                  }
                }}
                value={`${formData.values.phone_country_code}${formData.values.phone}`}
              />
            </Form.Group>
          </Grid.Col>
        </Grid.Row>
        <Grid.Row>
          <Grid.Col width={4}>
            <Form.Group>
              <FormField
                label="Address 1"
                name="address1"
                type="text"
                value={formData.values.address1}
                onChange={formData.handleChange}
              />
            </Form.Group>
          </Grid.Col>
          <Grid.Col width={4}>
            <Form.Group>
              <FormField
                label="Address 2"
                name="address2"
                type="text"
                value={formData.values.address2}
                onChange={formData.handleChange}
              />
            </Form.Group>
          </Grid.Col>
          <Grid.Col width={4}>
            <Form.Group>
              <FormField
                label="City"
                name="city"
                type="text"
                value={formData.values.city}
                onChange={formData.handleChange}
              />
            </Form.Group>
          </Grid.Col>
        </Grid.Row>
        <Grid.Row>
          <Grid.Col width={4}>
            <Form.Group>
              <FormField
                label="Province"
                name="province"
                type="text"
                value={formData.values.province}
                onChange={formData.handleChange}
              />
            </Form.Group>
          </Grid.Col>
          <Grid.Col width={4}>
            <Form.Group>
              <FormField
                label="Country"
                name="country"
                type="text"
                value={formData.values.country}
                onChange={formData.handleChange}
              />
            </Form.Group>
          </Grid.Col>
          <Grid.Col width={4}>
            <Form.Group>
              <FormField
                label="Postal Code"
                name="postal_code"
                type="text"
                value={formData.values.postal_code}
                onChange={formData.handleChange}
              />
            </Form.Group>
          </Grid.Col>
        </Grid.Row>
        <Grid.Row>
          <Grid.Col width={4}>
            <Form.Group label={'Image'}>
              <label htmlFor="s3-upload" className="cursor-pointer mb-2 mt-3">
                <Icon name={'image'} className="mr-2" />
                {formData.values.image !== null ? 'Change' : 'Upload'} Image
              </label>
              <ReactS3Uploader
                accept="image/*"
                autoUpload={true}
                className="d-none"
                contentDisposition="auto"
                id="s3-upload"
                signingUrl="/api/s3/sign"
                onError={onError}
                onFinish={(response) =>
                  formData.setFieldValue('image', response.fileKey)
                }
                onProgress={() => rootStore.setLoading(true)}
                uploadRequestHeaders={{
                  'x-amz-acl': 'private'
                }}
              />
              {formData.values.image && (
                <div>
                  <img
                    className={'d-flex mb-1 mt-2 float-left'}
                    src={`/api/s3/uploads/${formData.values.image}`}
                    width={'100px'}
                    alt={'Groups'}
                  />
                  <Button
                    icon={'x'}
                    className={'d-inline ml-2 mt-3'}
                    color={'white'}
                    onClick={() => formData.setFieldValue('image', null)}
                    size={'sm'}
                  >
                    Remove Image
                  </Button>
                </div>
              )}
            </Form.Group>
          </Grid.Col>
          <Grid.Col width={4}>
            <Form.Group label={'Image Square'}>
              <label
                htmlFor="s3-upload-image_square"
                className="cursor-pointer mb-2 mt-3"
              >
                <Icon name={'image'} className="mr-2" />
                {formData.values.image_square !== null
                  ? 'Change'
                  : 'Upload'}{' '}
                Image
              </label>
              <ReactS3Uploader
                accept="image/*"
                autoUpload={true}
                className="d-none"
                contentDisposition="auto"
                id="s3-upload-image_square"
                signingUrl="/api/s3/sign"
                onError={onError}
                onFinish={(response) =>
                  formData.setFieldValue('image_square', response.fileKey)
                }
                onProgress={() => rootStore.setLoading(true)}
                uploadRequestHeaders={{
                  'x-amz-acl': 'private'
                }}
              />
              {formData.values.image_square && (
                <div>
                  <img
                    className={'d-flex mb-1 mt-2 float-left'}
                    src={`/api/s3/uploads/${formData.values.image_square}`}
                    width={'100px'}
                    alt={'Groups'}
                  />
                  <Button
                    icon={'x'}
                    className={'d-inline ml-2 mt-3'}
                    color={'white'}
                    onClick={() => formData.setFieldValue('image_square', null)}
                    size={'sm'}
                  >
                    Remove Image
                  </Button>
                </div>
              )}
            </Form.Group>
          </Grid.Col>
          <Grid.Col width={4}>
            <Form.Group label={'Image Favicon'}>
              <label
                htmlFor="s3-upload-image_favicon"
                className="cursor-pointer mb-2 mt-3"
              >
                <Icon name={'image'} className="mr-2" />
                {formData.values.image_favicon !== null
                  ? 'Change'
                  : 'Upload'}{' '}
                Image
              </label>
              <ReactS3Uploader
                accept="image/*"
                autoUpload={true}
                className="d-none"
                contentDisposition="auto"
                id="s3-upload-image_favicon"
                signingUrl="/api/s3/sign"
                onError={onError}
                onFinish={(response) =>
                  formData.setFieldValue('image_favicon', response.fileKey)
                }
                onProgress={() => rootStore.setLoading(true)}
                uploadRequestHeaders={{
                  'x-amz-acl': 'private'
                }}
              />
              {formData.values.image_favicon && (
                <div>
                  <img
                    className={'d-flex mb-1 mt-2 float-left'}
                    src={`/api/s3/uploads/${formData.values.image_favicon}`}
                    width={'50px'}
                    alt={'Groups'}
                  />
                  <Button
                    icon={'x'}
                    className={'d-inline ml-2 mt-3'}
                    color={'white'}
                    onClick={() =>
                      formData.setFieldValue('image_favicon', null)
                    }
                    size={'sm'}
                  >
                    Remove Image
                  </Button>
                </div>
              )}
            </Form.Group>
          </Grid.Col>
        </Grid.Row>
        <button type="submit" className="btn btn-primary">
          Submit
        </button>
      </form>
    );
  };
  return (
    <Modal
      title="Organization"
      content={
        <Formik
          validationSchema={OrgFormValidation}
          initialValues={{
            id: org?.id || undefined,
            name: org?.name || '',
            email: org?.email || '',
            phone: org?.phone || null,
            website: org?.website || '',
            org_type: org?.org_type || 'org',
            slug: org?.slug || '',
            image: org?.image || null,
            image_favicon: org?.image_favicon || null,
            image_square: org?.image_square || null,
            phone_country_code: org?.phone_country_code || '1',
            address1: org?.address1 || '',
            address2: org?.address2 || '',
            city: org?.city || '',
            province: org?.province || '',
            postal_code: org?.postal_code || '',
            country: org?.country || ''
          }}
          enableReinitialize={true}
          onSubmit={async (values, { setSubmitting }) => {
            if (values.id) {
              await editOrg({ variables: { updateOrgInput: values } });
            } else {
              await addOrg({ variables: { addOrgInput: values } });
            }
            setSubmitting(false);
            toggle();
          }}
        >
          {(formData) => renderForm(formData)}
        </Formik>
      }
      open={isOpen}
      onClose={toggle}
    />
  );
};

export default OrgFormModal;
