import InvoiceModalForm from "components/Invoices/Modals/InvoiceModalForm";
import JobItem from "components/Jobs/JobItem";
import React, { useState } from "react";
import { Button, Card, Icon } from "tabler-react";
import { Types } from "../../../../types/graphql";

type Props = {
  jobs: Types.Job[];
  toggleModal: React.Dispatch<React.SetStateAction<boolean>>;
  setJob: any;
  onSubmit: () => void;
  type: string;
};

const GroupedJobCard = ({
  jobs,
  toggleModal,
  setJob,
  onSubmit,
  type,
}: Props) => {
  const [invoice, setInvoice] = useState(null);
  const [isInvoiceModalOpen, setIsInvoiceModalOpen] = useState(false);

  const job_ids = jobs.map((job) => job.id);
  const student = jobs[0]?.student;
  const totalAmount = jobs.reduce(
    (sum, item) => sum + item.unit_price * item.quantity,
    0,
  );

  const completedJobIds = jobs
    .filter((job) => job.status === "Complete")
    .map((job) => job.id);

  return (
    <>
      <Card className="mt-3">
        <Card.Header className="bg-light">
          <Card.Title>
            <Icon className="ml-0 mr-2 text-primary" name="briefcase" />
            <strong>
              {type === "rig" ? (
                jobs[0].user_gear?.rig?.name
              ) : (
                <>
                  {jobs[0].user_gear?.gear_type || ""}{" "}
                  {jobs[0].user_gear?.model || ""}{" "}
                  <span className={"text-muted"}>
                    {jobs[0].user_gear?.gear_serial}
                  </span>
                </>
              )}
            </strong>
          </Card.Title>

          <Card.Options>
            <Button
              outline
              color="primary"
              icon="file"
              size="sm"
              onClick={() => {
                setInvoice({
                  student_id: student?.id,
                  student: student,
                  invoice_amount: totalAmount,
                  job_ids,
                });
                setIsInvoiceModalOpen(true);
              }}
            >
              INVOICE {type.toUpperCase()}
            </Button>
          </Card.Options>
        </Card.Header>
        <Card.Body>
          {jobs.map((job) => (
            <JobItem
              job={job}
              key={job.id}
              toggleModal={toggleModal}
              setJob={setJob}
            />
          ))}
        </Card.Body>
      </Card>

      {invoice && (
        <InvoiceModalForm
          completedJobIds={completedJobIds}
          invoice={invoice}
          jobs={jobs}
          onSubmit={onSubmit}
          toggleModal={setIsInvoiceModalOpen}
          isModalOpen={isInvoiceModalOpen}
        />
      )}
    </>
  );
};

export default GroupedJobCard;
