import React, { useContext } from 'react';
import { useQuery } from '@apollo/client';
import { UserStoreContext } from '../../../contexts/userStoreContext';
import UserCard from '../../User/Profile/UserCard';
import { Button, Grid, Icon, Text } from 'tabler-react';
import { GET_STUDENT_SPORTS } from '../../../graphql/GET_STUDENT_SPORTS';
import UserActivityToggle from '../../User/Forms/UserActivityToggle';
import { observer } from 'mobx-react';
import { useRootStore } from '../../../hooks';

interface IStudentSignupActivityProps {
  setStep: any;
}

const StudentSignupActivity = ({ setStep }: IStudentSignupActivityProps) => {
  const userStore = useContext(UserStoreContext);
  const { user } = userStore;
  const rootStore = useRootStore();
  const { currentUser } = rootStore;

  const { data: sportData } = useQuery(
    GET_STUDENT_SPORTS,
    {
      variables: { student_id: user.id.toString() }
    }
  );

  return (<Grid.Row>
    {currentUser && <Grid.Col lg={4} sm={12} xs={12}><UserCard
      firstName={user?.first_name}
      lastName={user?.last_name}
      profileAvatar={user?.profile_avatar}
      profilePicture={user?.profile_picture}
    />
    </Grid.Col>}
    <Grid.Col lg={currentUser ? 8 : 12} sm={12} xs={12}>
      <Text className="d-block mb-5"><strong>Which activities do you participate in?</strong></Text>
      <UserActivityToggle sports={sportData?.studentSports} userObj={user} />
      <Button.List align='center'>
        <Button
          color='white'
          size='lg'
          onClick={() => {
            setStep(2);
          }
          }
        >
          SKIP
        </Button>
        <Button
          color='primary'
          size='lg'
          onClick={() => {
            setStep(2);
          }
          }
        >
          NEXT <Icon name="chevron-right" />
        </Button>
      </Button.List>
    </Grid.Col>
  </Grid.Row>);
};

export default observer(StudentSignupActivity);
