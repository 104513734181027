import { Formik, FormikValues } from "formik";
import { useRef } from "react";
import { Link } from "react-router-dom";
import { Card, Form, Text } from "tabler-react";
import * as Yup from "yup";

import FormField from "components/FormField";
import { useRootStore } from "../../../hooks";

const LoginFormVerify = () => {
  const { redirectUrl } = useRootStore();
  const formEl = useRef(null);
  const doHtmlFormPost = (values: any) => {
    const current: any = formEl.current;
    current.submit();
  };

  const loginValidationSchema = Yup.object().shape({
    email: Yup.string().required("This field is required."),
    password: Yup.string().required("This field is required."),
  });

  const renderForm = ({ handleSubmit, isSubmitting }: FormikValues) => {
    return (
      <>
        <form
          className="card verify-email"
          method="POST"
          action="/auth/login"
          ref={formEl}
          onSubmit={handleSubmit}
        >
          <Card.Body className="p-6">
            <FormField
              name="email"
              label="Email"
              placeholder="Enter your email"
              type="text"
            />
            <FormField
              name="password"
              type="password"
              label="Code"
              placeholder="Code"
            />
            <Text.Small className="text-muted">
              Enter the code received in your email
            </Text.Small>
            <FormField name="redirect" type="hidden" value={redirectUrl} />
            <Form.Footer className="mb-6">
              <button
                type="submit"
                className={`btn btn-primary btn-block ${
                  isSubmitting && "btn-loading"
                }`}
                disabled={isSubmitting}
              >
                VERIFY
              </button>
              <Link to="/reset-password" className="d-block text-center mt-2">
                Resend code?
              </Link>
            </Form.Footer>
          </Card.Body>
        </form>
      </>
    );
  };

  return (
    <Formik
      initialValues={{
        email: "",
        password: "",
        redirect: redirectUrl,
      }}
      validationSchema={loginValidationSchema}
      onSubmit={(values, { setSubmitting }) => {
        setSubmitting(false);
        doHtmlFormPost(values);
      }}
    >
      {(formikData) => renderForm(formikData)}
    </Formik>
  );
};

export default LoginFormVerify;
