import gql from 'graphql-tag';

export const GET_ACCOUNT_BALANCE = gql`
  query GET_ACCOUNT_BALANCE($studentId: ID!, $teamId: Int!) {
    getAccountBalance(studentId: $studentId, teamId: $teamId) {
      id
      account_type_slug
      is_disabled
      student_id
      team_id
      total_time
      updated_on
    }
  }
`;
