import gql from 'graphql-tag';

export const GET_COACH_STUDENT = gql`
  query GET_COACH_STUDENT($coach_id: Int!,$student_id: Int!) {
    getCoachStudent(coach_id: $coach_id, student_id: $student_id){
      student_id
      coach_id
    }
  }
`;
