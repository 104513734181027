import gql from 'graphql-tag';

export const GET_STUDENT_NOTE = gql`
  query GET_STUDENT_NOTE($student_id: ID!, $team_id: Int!) {
    getStudentNoteBy(student_id: $student_id, team_id: $team_id) {
      id
      student_id
      student_team_id
      note
      created_by
    }
  }
`;
