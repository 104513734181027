import gql from 'graphql-tag';

export const ADD_RIG = gql`
  mutation ADD_RIG($addRigInput: AddRigInput!) {
    addRig(addRigInput: $addRigInput) {
      id
      name
      student_id
      team_id
    }
  }
`;
