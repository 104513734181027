import { observer } from 'mobx-react';
import { Card, Form, Grid, Icon, Table } from 'tabler-react';
import AdminListItem from './AdminListItem';
import { Types } from '../../types/graphql'

const AdminList = ({ admins }) => {
  return (
    <Card>
      <Card.Header>
        <Card.Title>
          <Icon name="users" className="mr-2 ml-0 text-blue" /> Admins
        </Card.Title>
        <Card.Options>
          <Form.Group className="mb-0">
            <Form.Switch name="toggle" value="showDisabled" label="Disabled" />
          </Form.Group>
        </Card.Options>
      </Card.Header>
      <Card.Body>
        <Grid.Row>
          <Grid.Col>
            <Table>
              <Table.Header>
                <Table.Row>
                  <Table.ColHeader>Username</Table.ColHeader>
                  <Table.ColHeader>Status</Table.ColHeader>
                  <Table.ColHeader>Action</Table.ColHeader>
                  <Table.ColHeader>Edit</Table.ColHeader>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {admins.map((admin: Types.Admin) => (
                  <Table.Row key={admin.id}>
                    <AdminListItem admin={admin} />
                  </Table.Row>
                ))}
              </Table.Body>
            </Table>
          </Grid.Col>
        </Grid.Row>
      </Card.Body>
    </Card>
  );
};

export default observer(AdminList);
