import gql from 'graphql-tag';

export const ADD_GEAR_MODEL = gql`
  mutation ADD_GEAR_MODEL($addGearModelInput: AddGearModelInput!) {
    addGearModel(addGearModelInput: $addGearModelInput){
      id
      name
      gear_type_id
      gear_manufacturer_id
      gear_type_name
      gear_manufacturer_name
    }
  }
`;
