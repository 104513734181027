import React, { useContext, useMemo, useState } from 'react';
import { Table, Tag, Text } from 'tabler-react';
import { Link } from 'react-router-dom';
import useReactRouter from 'use-react-router';
import { UserStoreContext } from '../../contexts/userStoreContext';
import { formatDate } from '@fullcalendar/react';
import { useMutation, useQuery } from '@apollo/client';
import { GET_ALL_GEAR_MANUFACTURERS } from '../../graphql/GET_ALL_GEAR_MANUFACTURERS';
import { GET_ALL_GEAR_MODELS } from '../../graphql/GET_ALL_GEAR_MODELS';
import { ADD_OR_EDIT_GEAR_MODEL } from '../../graphql/ADD_OR_EDIT_GEAR_MODEL';
import { toast } from 'react-toastify';
import Select from 'react-select';
import { Types } from '../../types/graphql'

interface IGearListItemProps {
  userGear: Types.UserGear;
  toggleModal: () => void;
  setUserGear: (gear) => void;
  toggleModelModal: () => void;
  setGearModel: (model) => void;
}

const GearListItem = ({
  userGear,
  toggleModal,
  setUserGear,
  toggleModelModal,
  setGearModel
}: IGearListItemProps) => {
  const { history } = useReactRouter();
  const userStore = useContext(UserStoreContext);
  const { user } = userStore;

  const [isEditting, setIsEditting] = useState<boolean>(false);

  const { data, loading, error } = useQuery(GET_ALL_GEAR_MANUFACTURERS);

  const manufacturers = useMemo(() => {
    if (!loading && !error && data) return data.getGearManufacturers;
    return null;
  }, [data, loading, error]);

  const [updateGearModel] = useMutation(ADD_OR_EDIT_GEAR_MODEL, {
    onCompleted: (response) => {
      if (response.updateGearModel) {
        toast.success('Model updated successfully');
      }
    }
  });

  const routeToStudent = (student) => {
    userStore.detach(user);
    userStore.loadUser(student);
    history.push('/user-details');
  };
  const clickHandle = () => {
    setUserGear(userGear);
    toggleModal();
  };

  const modelClickHandle = (gearModel) => {
    setGearModel(gearModel);
    toggleModelModal();
  };

  const handleSubmit = async (id: number) => {
    await updateGearModel({
      variables: {
        updateGearModelInput: {
          id: userGear?.gear_model.id,
          gear_manufacturer_id: id
        }
      },
      refetchQueries: [{ query: GET_ALL_GEAR_MODELS }]
    });
    setIsEditting(false);
  };
  return (
    <>
      <Table.Col>
        <Link to="#" onClick={clickHandle}>
          {userGear.id}
        </Link>
      </Table.Col>
      <Table.Col>
        <Link
          className="text-decoration-none"
          to="#"
          onClick={(e) => {
            e.preventDefault();
            routeToStudent(userGear.student);
          }}
        >
          {userGear.student?.first_name} {userGear.student?.last_name}
        </Link>
      </Table.Col>
      <Table.Col>
        <Tag>{userGear.gear_model?.gear_type_name}</Tag>
      </Table.Col>
      <Table.Col> <span>{userGear.name && userGear.name + ': '}</span>
        <Link to="#" onClick={() => modelClickHandle(userGear.gear_model)}>
          <span className="pr-1">{userGear.gear_model?.name}</span>
          {userGear?.gear_canopy_size > 0 && userGear.gear_canopy_size}
        </Link>
        <br />
        <small>
          {formatDate(userGear.gear_dom, {
            month: 'short',
            year: 'numeric',
            day: '2-digit'
          })}
        </small>
      </Table.Col>
      <Table.Col>
        {isEditting && (
          <Select
            name="gear_manufacturer_id"
            isMulti={false}
            getOptionLabel={(
              option: Types.GearManufacturer
            ) => option.name}
            getOptionValue={(
              option: Types.GearManufacturer
            ) => option.id}
            options={manufacturers}
            placeholder="Select Manufacturer"
            onChange={(param) => handleSubmit(param.id)}
            value={manufacturers.filter(
              (gearManufacturer) =>
                gearManufacturer.id ===
                userGear.gear_model?.gear_manufacturer_id
            )}
          />
        )}
        {!isEditting && (
          <Text className="cursor-pointer" onClick={setIsEditting}>
            {userGear.gear_model?.gear_manufacturer_name ? (
              userGear.gear_model?.gear_manufacturer_name
            ) : (
              <span
                className="text-pink"
              >
                Who makes this?
              </span>
            )}
          </Text>
        )}
      </Table.Col>
      <Table.Col>{userGear.gear_serial}</Table.Col>
      <Table.Col>{userGear.gear_notes}</Table.Col>
    </>
  );
};

export default GearListItem;
