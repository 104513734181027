import gql from 'graphql-tag';

export const INSERT_PRODUCT_MUTATION = gql`
  mutation INSERT_PRODUCT_MUTATION($product: AddProductInput!) {
    addProduct(product: $product) {
      amount
      created_on
      currency
      description
      id
      image
      status
      team_id
      title
      total
      quantity
      product_type_id
      account_type_id
       code
    }
  }
`;
