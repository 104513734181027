import { useQuery } from '@apollo/client'
import UserRegistration from 'components/User/UserRegistration'
import { GET_USER_REGISTRATIONS_BY_IDS } from 'graphql/GET_USER_REGISTRATIONS_BY_IDS'
import { GET_USER_REGISTRATIONS_BY_IDS as GET_USER_REGISTRATIONS_BY_IDS_TYPE } from 'graphql/types/GET_USER_REGISTRATIONS_BY_IDS'
import { useRootStore } from 'hooks'
import { observer } from 'mobx-react'
import {
  useGetUserIsAdmin,
  useGetUserIsStudent
} from 'modules/common/hooks/useGetCurrentUserType'
import Wrapper from 'pages/layout'
import React, { useCallback } from 'react'
import { useParams } from 'react-router'
import { Container, Dimmer } from 'tabler-react'

import PageNoAccess from '../../pages/PageNoAccess'
import useGetEventOrganizers from '../../modules/user/roles/hooks/useGetEventOrganizers'

const AdminEventsRegistrationsPage = (props) => {
  const isStudent = useGetUserIsStudent()
  const isAdmin = useGetUserIsAdmin()

  const { currentUser, currentCoachTeam } = useRootStore()
  const { userRegId, studentId } = useParams<{
    userRegId: string,
    studentId: string
  }>()

  const { data: registrationData, loading: registrationDataLoading } =
    useQuery<GET_USER_REGISTRATIONS_BY_IDS_TYPE>(
      GET_USER_REGISTRATIONS_BY_IDS,
      {
        variables: {
          userRegistrationsId: Number(userRegId)
        }
      }
    )
  const campId = Number(registrationData?.getUserRegistrationsByIds.camp_id)
  const { eventOrganizersDataLoading, isOrganizer } = useGetEventOrganizers({
    eventId: campId
  })

  const hasAccess = useCallback(() => {
    return (
      isOrganizer ||
      (isStudent && currentUser?.id === Number(studentId)) ||
      (currentCoachTeam &&
        registrationData?.getUserRegistrationsByIds.team_id ===
          currentCoachTeam?.id) ||
      isAdmin
    )
  }, [
    currentCoachTeam,
    isOrganizer,
    currentUser,
    isStudent,
    registrationData,
    studentId,
    isAdmin
  ])

  if (!eventOrganizersDataLoading && !registrationDataLoading && !hasAccess()) {
    return <PageNoAccess />
  }

  return (
    <Wrapper {...props} title="User Registration">
      <Container>
        <Dimmer
          active={registrationDataLoading && eventOrganizersDataLoading}
          loader={registrationDataLoading && eventOrganizersDataLoading}
        >
          <UserRegistration />
        </Dimmer>
      </Container>
    </Wrapper>
  )
}

export default observer(AdminEventsRegistrationsPage)
