import React from 'react';

import TeamList from '../../components/Team/TeamList';
import { TeamStoreProvider } from '../../contexts/teamStoreContext';
import Wrapper from 'pages/layout';

const TeamListPage = (props) => {
  return (
    <Wrapper {...props} title="Team List">
      <TeamStoreProvider>
        <div className="pr-3 pl-3">
          <TeamList />
        </div>
      </TeamStoreProvider>
    </Wrapper>
  );
};
export default TeamListPage;
