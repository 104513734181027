import { HOSTNAMES } from 'components/constants'
import { Helmet } from 'react-helmet'
import { Link } from 'react-router-dom'
import LoginPostForm from '../components/User/Forms/LoginPostForm'
import SiteLogo from './layout/SiteLogo'

const LoginPage = (props: any) => {
  const envHost = HOSTNAMES.find((host) => host?.host === window.location.host)
  return (
    <>
      <Helmet>
        <title>{envHost?.siteName}</title>
      </Helmet>
      <div className="page">
        <div className="page-single">
          <div className="container">
            <div className="row">
              <div className="col col-login mx-auto">
                <div className="text-center mb-6">
                  <Link to="/">
                    <SiteLogo />
                  </Link>
                </div>
                <LoginPostForm {...props} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default LoginPage
