import { useQuery } from "@apollo/client";
import { Loading } from "components/Loading";
import { observer } from "mobx-react";
import React, { useContext } from "react";
import { Button, Card, Icon } from "tabler-react";

import { UserStoreContext } from "../../contexts/userStoreContext";
import { GET_RIGS } from "../../graphql/GET_RIGS";
import { GET_STUDENT_GEARS } from "../../graphql/GET_STUDENT_GEARS";
import { useRootStore } from "../../hooks";
import ReservelogCard from "../ReserveLogs/ReservelogCard";
import { EUserTypes } from "../User/userTypes";

import GearCard from "./GearCard";
import RigCard from "./RigCard";
import RigGearModalButtons from "./RigGearModalButtons";

const StudentGearList = () => {
  const userStore = useContext(UserStoreContext);
  const rootStore = useRootStore();
  const { currentUser } = rootStore;
  const { user } = userStore;
  const studentId =
    currentUser.type === EUserTypes.student ? currentUser.id : Number(user.id);

  const { loading, error, data } = useQuery(GET_STUDENT_GEARS, {
    variables: {
      getUserGearOptions: { student_id: studentId },
    },
  });

  const {
    loading: userRigsLoading,
    data: userRigsData,
    error: error2,
  } = useQuery(GET_RIGS, {
    variables: {
      rigListFilter: {
        student_id: studentId,
      },
    },
  });

  if (loading || userRigsLoading || !data || !userRigsData) {
    return <Loading />;
  }

  if (error) {
    console.log(error);
    return <p>Error: {error.message}</p>;
  }

  if (error2) {
    console.log(error2);
    return <p>Error: {error2.message}</p>;
  }

  const rigs = userRigsData.rigList.toSorted((a, b) =>
    a.name.localeCompare(b.name),
  );
  const userGears = [...data.getUserGears].sort((a, b) => {
    const gear_name_a = a?.gear_model.gear_type_name;
    const gear_name_b = b?.gear_model.gear_type_name;
    return gear_name_a.localeCompare(gear_name_b);
  });

  return (
    <StudentGearListInner
      userGears={userGears}
      rigs={rigs}
      studentId={studentId}
    />
  );
};

const StudentGearListInner = ({userGears, rigs, studentId}) => {
  const userStore = useContext(UserStoreContext);
  const rootStore = useRootStore();
  const { currentUser } = rootStore;
  const { user } = userStore;

  return (
    <>
      <Card statusColor="blue">
        <Card.Header>
          <Card.Title>
            <Icon name="briefcase" className="mr-2 ml-0 text-blue" />
            Gear
            {currentUser.type !== EUserTypes.student && (
              <span>
                {" "}
                // {user.first_name} {user.last_name}
              </span>
            )}
          </Card.Title>
          <Card.Options>
            <Button.List align="right">
              <RigGearModalButtons />
            </Button.List>
          </Card.Options>
        </Card.Header>
        <Card.Body>
          <RigCard rigs={rigs} />
          <GearCard userGears={userGears} />
        </Card.Body>
      </Card>
      <ReservelogCard />
    </>
  );
};

export default observer(StudentGearList);
