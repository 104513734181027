import React from 'react';
import { EventStoreProvider } from '../../contexts/eventStoreContext';
import CoachDashboard from '../../components/OnDeck/CoachDashboard';

const CoachHome = () => {
  return (
    <div className="container">
      <EventStoreProvider>
        <CoachDashboard />
      </EventStoreProvider>
    </div>
  );
};

export default CoachHome;
