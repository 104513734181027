import { useQuery } from '@apollo/client';
import StaffListItem from 'components/Staff/StaffListItem';
import { RETRIEVE_TEAM_STAFF } from 'graphql/RETRIEVE_TEAM_STAFF';
import { RETRIEVE_TEAM_STAFF as RETRIEVE_TEAM_STAFF_TYPE } from 'graphql/types/RETRIEVE_TEAM_STAFF';
import { useRootStore } from 'hooks';
import { observer } from 'mobx-react';
import React, { useMemo } from 'react';
import { useLocation } from 'react-router';
import { Alert, Card, Dimmer, Grid, Icon, Table } from 'tabler-react';
import useGetCurrentUserType from 'modules/common/hooks/useGetCurrentUserType';

const StaffList = () => {
  const { currentCoachTeam } = useRootStore();
  const { isAdmin, isCoachAdmin, isCoachSuperAdmin } = useGetCurrentUserType();

  const { search } = useLocation();

  const teamId = new URLSearchParams(search).get('team_id');

  const { loading, error, data } = useQuery<RETRIEVE_TEAM_STAFF_TYPE>(
    RETRIEVE_TEAM_STAFF,
    {
      variables: {
        team_id: currentCoachTeam?.id ?? teamId
      }
    }
  );

  const staffRows = useMemo(
    () =>
      data?.retrieveTeamStaff.map(({ roles, seal, studentDetails, team }) => ({
        roles,
        seal,
        studentDetails,
        team
      })) ?? [],
    [data?.retrieveTeamStaff]  
  );

  if (error) {
    return <p>Error: {`Error: ${error.message}`}</p>;
  }

  return (
    <Card>
      <Card.Header>
        <Card.Title>
          <Icon name="users" className="mr-2 ml-0 text-muted" />
          User Access
        </Card.Title>
      </Card.Header>
      <Card.Body>
        {!staffRows.length && (
          <Alert type="info text-center">
            <strong>No Data</strong>
          </Alert>
        )}

        {staffRows.length > 0 && (
          <Grid.Row>
            <Grid.Col>
              <Dimmer active={loading} loader={loading}>
                <Table>
                  <Table.Header>
                    <Table.Row>
                      <Table.ColHeader></Table.ColHeader>
                      <Table.ColHeader>Email</Table.ColHeader>
                      <Table.ColHeader>Phone</Table.ColHeader>
                      {(isAdmin || isCoachAdmin || isCoachSuperAdmin) && (
                        <>
                          <Table.ColHeader>Role(s)</Table.ColHeader>
                          <Table.ColHeader></Table.ColHeader>
                        </>
                      )}
                    </Table.Row>
                  </Table.Header>
                  <Table.Body>
                    {staffRows.map((staff) => (
                      <Table.Row key={staff.studentDetails.id}>
                        <StaffListItem staff={staff} />
                      </Table.Row>
                    ))}
                  </Table.Body>
                </Table>
              </Dimmer>
            </Grid.Col>
          </Grid.Row>
        )}
      </Card.Body>
    </Card>
  );
};

export default observer(StaffList);
