import React from 'react';
import { Container } from 'tabler-react';

import Wrapper from 'pages/layout';
import ReserveLogsList from "../../components/ReserveLogs/ReserveLogsList";

const ReserveLogList = (props) => {
  return (
    <Wrapper {...props} title="Reserve Logs">
      <Container>
          <ReserveLogsList />
      </Container>
    </Wrapper>
  );
};
export default ReserveLogList;
