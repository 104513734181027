import React, { useContext } from 'react'
import { Button, Card, Dimmer, Grid } from 'tabler-react'
import { observer } from 'mobx-react'
import ReservelogCardItem from './ReservelogCardItem'
import { useQuery } from '@apollo/client';
import { GET_USER_RESERVE_LOGS } from '../../graphql/GET_USER_RESERVE_LOGS'
import { UserStoreContext } from '../../contexts/userStoreContext'
import { EUserTypes } from '../User/userTypes'
import { useRootStore } from '../../hooks'
import { IconParachute } from '@tabler/icons-react'
import UserReserveLogForm from '../User/Forms/UserReserveLogForm'
import { useGetUserIsAdmin } from 'modules/common/hooks/useGetCurrentUserType'

const ReservelogCard = () => {
  const userStore = useContext(UserStoreContext)
  const { user } = userStore
  const rootStore = useRootStore()
  const { currentUser } = rootStore
  const isAdmin = useGetUserIsAdmin()

  const option = { student_id: user.id }
  if (currentUser.type === EUserTypes.student)
    option.student_id = currentUser.id.toString()

  const { data: userReserveLogData, loading: userReserveLogLoading } = useQuery(
    GET_USER_RESERVE_LOGS,
    {
      variables: {
        order: 'newest',
        reserveLogOptions: { ...option }
      }
    }
  )
  if (
    userReserveLogData?.getReserveLogs &&
    userReserveLogData?.getReserveLogs.length <= 0
  ) {
    return null
  }
  return (
    <Dimmer loader={userReserveLogLoading} active={userReserveLogLoading}>
      <Card statusColor="blue">
        <Card.Header>
          <Card.Title>
            <IconParachute size={20} className="mr-2 ml-0 text-blue" />
            Reserve Repacks
          </Card.Title>
          {currentUser.type !== EUserTypes.student ? (
            <Card.Options className="float-right">
              {isAdmin && (
                // NEED TO CREATE SETTING FOR STUDENT TO GET AUTOMATED REMINDERS 30 DAYS BEFORE RESERVE EXPIRES
                <Button icon="settings" color="white" size="sm" disabled />
              )}
              <UserReserveLogForm />
            </Card.Options>
          ) : (
            <Card.Options>
              <Button color="white" size="sm" className="text-muted" disabled>
                Repacks must be added by an authorized Rigger
              </Button>
            </Card.Options>
          )}
        </Card.Header>
        <Card.Body>
          <Grid.Row>
            {userReserveLogData?.getReserveLogs &&
              userReserveLogData.getReserveLogs.map((reservelog) => (
                <Grid.Col md={4} sm={12} xs={12} key={reservelog.id}>
                  <ReservelogCardItem reservelog={reservelog} />
                </Grid.Col>
              ))}
          </Grid.Row>
        </Card.Body>
      </Card>
    </Dimmer>
  )
}

export default observer(ReservelogCard);
