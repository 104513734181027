import { observer } from 'mobx-react';
import React, { useContext } from 'react';
import { Avatar, Card, Dimmer, Table, Text } from 'tabler-react';
import { GET_COACH_STUDENTS } from '../../graphql/GET_COACH_STUDENTS';
import { useQuery } from '@apollo/client';
import { useRootStore } from '../../hooks';
import { UserStoreContext } from '../../contexts/userStoreContext';
import useReactRouter from 'use-react-router';

const MyStudentsList = () => {
  const rootStore = useRootStore();
  const { currentUser } = rootStore;
  const userStore = useContext(UserStoreContext);
  const { user } = userStore;
  const { history } = useReactRouter();

  const { data, loading } = useQuery(GET_COACH_STUDENTS, {
    variables: {
      coach_id: Number(currentUser.id)
    }
  });

  const routeToStudent = (student) => {
    userStore.detach(user);
    userStore.loadUser(student);
    history.push('/user-details');
  };
  //getCoachStudent
  return (
    <Dimmer active={loading} loading={loading}>
      <Card>
        <Card.Header>
          <Card.Title>My Students</Card.Title>
        </Card.Header>
        <Card.Body>
          <Table>
            <Table.Header>
              <Table.Row>
                <Table.ColHeader></Table.ColHeader>
                <Table.ColHeader>Last Event</Table.ColHeader>
                <Table.ColHeader></Table.ColHeader>
                <Table.ColHeader></Table.ColHeader>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {data?.getCoachStudents.map((item) => {
                return (
                  <Table.Row>
                    <Table.Col>
                    <Avatar
                      size="md"
                      className="float-left mr-5 cursor-pointer"
                      status={`${item.student.registration_id ? 'success' : 'secondary'}`}
                      imageURL={
                        (item.student.profile_avatar &&
                          `/api/s3/uploads/${item.student.profile_avatar}`) ||
                        '//www.gravatar.com/avatar?d=mp'
                      }
                      onClick={(e) => {
                        e.preventDefault();
                        routeToStudent(item.student);
                      }}
                    />
                      <span
                      className='cursor-pointer'
                      onClick={(e) => {
                        e.preventDefault();
                        routeToStudent(item.student);
                      }}
                      >
                        {item.student.first_name} {item.student.last_name}
                        <Text.Small className="d-block text-muted">SKY.D: {item.student.id}</Text.Small>
                      </span>
                    </Table.Col>
                    <Table.Col></Table.Col>
                    <Table.Col></Table.Col>
                    <Table.Col></Table.Col>
                  </Table.Row>
                );
              })}
            </Table.Body>
          </Table>
        </Card.Body>
      </Card>
    </Dimmer>
  );
};

export default observer(MyStudentsList);
