import { values } from "mobx";
import { cast, Instance, SnapshotIn, types } from "mobx-state-tree";

const FeeTypes = types.model({
  id: types.optional(types.string, "", [null]),
  name: types.optional(types.string, "", [null]),
});

const RegistrationOptionTypes = types.model({
  id: types.optional(types.string, "", [null]),
  slug: types.optional(types.string, "", [null]),
  name: types.optional(types.string, "", [null]),
});

const RegistrationRequirements = types.model({
  description: types.optional(types.string, "", [null]),
  id: types.optional(types.string, "", [null]),
  location_id: types.optional(types.string, "", [null, undefined]),
  location_name: types.optional(types.string, "", [null, undefined]),
  name: types.optional(types.string, "", [null]),
  requirement_type_id: types.optional(types.string, "", [null, undefined]),
  team_id: types.optional(types.string, "", [null, undefined]),
});

const Sport = types.model({
  id: types.number,
  slug: types.optional(types.string, "", [null]),
  name: types.optional(types.string, "", [null]),
  is_active: types.boolean,
});

const Location = types.model({
  name: types.optional(types.string, "", [null]),
});

export const Team = types
  .model({
    coach_business_type: types.optional(types.string, "", [null, undefined]),
    default_account_type_id: types.optional(types.number, 0, [null]),
    default_currency: types.optional(types.string, "", [null, undefined]),
    default_location_id: types.optional(types.number, 0, [null]),
    default_location_name: types.optional(types.string, "", [null]),
    feature_accounting: types.optional(types.boolean, false, [null]),
    feature_alpha: types.optional(types.boolean, false, [null]),
    feature_app_reports: types.optional(types.boolean, false, [null]),
    feature_booking_calendar: types.optional(types.boolean, false, [null]),
    feature_camps: types.optional(types.boolean, false, [null]),
    feature_cancel_transactions: types.optional(types.boolean, false, [null]),
    feature_coach_account: types.optional(types.boolean, false, [null]),
    feature_dashboard: types.optional(types.boolean, false, [null]),
    feature_dashboard_groups: types.optional(types.boolean, false, [null]),
    feature_disable_students: types.optional(types.boolean, false, [null]),
    feature_dzm_account: types.optional(types.boolean, false, [null]),
    feature_edit_transactions: types.optional(types.boolean, false, [null]),
    feature_locked_account_actions: types.optional(types.boolean, false, [
      null,
    ]),
    feature_manifest: types.optional(types.boolean, false, [null]),
    feature_open_add_students: types.optional(types.boolean, false, [null]),
    feature_paid_status: types.optional(types.boolean, false, [null]),
    feature_payments: types.optional(types.boolean, false, [null, undefined]),
    feature_products: types.optional(types.boolean, false, [null, undefined]),
    feature_recurring_transactions: types.optional(types.boolean, false, [
      null,
    ]),
    feature_registrations: types.optional(types.boolean, false, [null]),
    feature_require_uspa: types.optional(types.boolean, false, [null]),
    feature_rigger_account: types.optional(types.boolean, false, [
      null,
      undefined,
    ]),
    feature_schedule_open: types.optional(types.boolean, false, [null]),
    feature_slot_registrations: types.optional(types.boolean, false, [null]),
    feature_student_progression: types.optional(types.boolean, false, [null]),
    feature_student_checkin: types.optional(types.boolean, false, [null]),
    feature_transfer_time: types.optional(types.boolean, false, [null]),
    feature_crm: types.optional(types.boolean, false, [null, undefined]),
    feature_invoices: types.optional(types.boolean, false, [null, undefined]),
    feature_waivers: types.optional(types.boolean, false, [null, undefined]),
    id: types.optional(types.string, "", [null]),
    is_public: types.optional(types.boolean, false, [null]),
    is_published: types.optional(types.boolean, false, [null]),
    locations: types.optional(types.array(Location), []),
    name: types.optional(types.string, "", [null]),
    paypal_me: types.optional(types.string, "", [null, undefined]),
    payment_fee_flat: types.optional(types.number, 0, [null, undefined]),
    payment_fee_customer: types.optional(types.number, 0, [null, undefined]),
    payment_fee_percentage: types.optional(types.number, 0, [null, undefined]),
    preference_units: types.optional(types.boolean, false, [null]),
    registration_requirements: types.optional(
      types.array(RegistrationRequirements),
      [],
    ),
    slug: types.optional(types.string, "", [null, undefined]),
    sports: types.optional(types.array(Sport), []),
    stripe_account_id: types.optional(types.string, "", [null, undefined]),
    student_contact_required: types.optional(types.boolean, false, [
      null,
      undefined,
    ]),
    team_bio: types.optional(types.string, "", [null]),
    team_coach_hour_fee: types.optional(types.number, 0, [null]),
    team_email: types.optional(types.string, "", [null]),
    team_favicon: types.optional(types.string, "", [null, undefined]),
    team_logo_header: types.optional(types.string, "", [null, undefined]),
    team_logo_wide_image: types.optional(types.string, "", [null, undefined]),
    team_logo_square_image: types.optional(types.string, "", [null, undefined]),
    team_phone: types.optional(types.string, "", [null]),
    team_phone_country_code: types.optional(types.string, "", [
      null,
      undefined,
    ]),
    team_promo_image: types.optional(types.string, "", [null, undefined]),
    team_url: types.optional(types.string, "", [null]),
  })
  .actions((self) => ({
    set<
      K extends keyof SnapshotIn<typeof self>,
      T extends SnapshotIn<typeof self>,
    >(key: K, value: T[K]) {
      self[key] = cast(value);
    },
    addRegReq(newRegReq) {
      self.registration_requirements.push(newRegReq);
    },
  }));

export const TeamStoreDefault = () => {
  return TeamStore.create({
    fee_types: undefined,
    registration_option_types: undefined,
    teams: undefined,
  });
};

export const TeamStore = types
  .model({
    fee_types: types.optional(types.array(FeeTypes), []),
    registration_option_types: types.optional(
      types.array(RegistrationOptionTypes),
      [],
    ),
    teams: types.optional(types.array(Team), []),
  })
  .views((self) => ({
    getTeamById(teamId: string) {
      return values(self.teams).find((team) => team.id === teamId);
    },
  }))
  .actions((self) => ({
    addTeam(newTeam) {
      self.teams.push(newTeam);
    },
  }));

export interface TeamStoreType extends Instance<typeof TeamStore> {}
