import { useQuery } from "@apollo/client";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { GET_STRIPE_ACCOUNT_ID_BY_TEAM_ID } from "graphql/GET_STRIPE_ACCOUNT_ID_BY_TEAM_ID";
import { GET_STRIPE_PUBLIC_KEY } from "graphql/GET_STRIPE_PUBLIC_KEY";
import { observer } from "mobx-react";
import { applySnapshot } from "mobx-state-tree";
import FeatureAccessGate from "modules/common/components/FeatureAccessGate";
import React, { useContext, useEffect, useState } from "react";
import { Button, Card, Dimmer, Grid, Icon } from "tabler-react";
import OnDeckListItem from "../../components/OnDeck/OnDeckListItem";
import { LoadStoreContext } from "../../contexts/LoadStoreContext";
import { UserStoreContext } from "../../contexts/userStoreContext";
import { GET_STUDENTS_ON_DASHBOARD } from "../../graphql/GET_STUDENTS_ON_DASHBOARD";
import { LOAD_LIST_QUERIES } from "../../graphql/LOAD_LIST_QUERIES";
import { useRootStore } from "../../hooks/useRootStore";
import { Types } from "../../types/graphql";
import GroupFormModal from "../Calendar/Modal/GroupFormModal";
import DashboardGroups from "../Coaches/DashboardGroups";

const Manifest = () => {
  const { aircrafts, loads } = useContext(LoadStoreContext);
  const { currentCoachTeam } = useRootStore();
  const { onDeckStudents } = useContext(UserStoreContext);
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const { loading, error, data } = useQuery(GET_STUDENTS_ON_DASHBOARD, {
    variables: {
      team_id: currentCoachTeam?.id,
    },
  });

  const [currentDate, setCurrentDate] = useState(new Date());

  const { data: loadsData } = useQuery(LOAD_LIST_QUERIES, {
    variables: {
      team_id: currentCoachTeam.id,
      date: currentDate,
    },
  });

  useEffect(() => {
    if (!loading && data) {
      applySnapshot(aircrafts, loadsData.teamAircrafts);
      applySnapshot(loads, loadsData.loads);
    }
  }, [loadsData]);

  useEffect(() => {
    if (!loading && data) {
      applySnapshot(onDeckStudents, data.studentsOnDashboard);
    }
  }, [data]);

  const {
    data: stripeAccountData,
    loading: loadingStripe,
    error: errorStripe,
  } = useQuery(GET_STRIPE_ACCOUNT_ID_BY_TEAM_ID, {
    variables: {
      teamIdValue: currentCoachTeam?.id,
    },
  });

  const { data: stripeData, loading: stripePubLoading } = useQuery(
    GET_STRIPE_PUBLIC_KEY,
  );

  const stripeKey = stripeData?.getStripePublicKey;
  const stripeAccountId =
    stripeAccountData?.getStripeAccountIdByTeamId?.stripe_account_id;

  if (error) {
    return <p>Error: {`Error: ${error.message}`}</p>;
  }
  return (
    <>
      <Card>
        <Card.Header>
          <Card.Title>
            <Icon name="home" className="mr-2 ml-0 text-primary" />
            {currentCoachTeam?.feature_manifest ? "Manifest" : "Dashboard"}
          </Card.Title>
          <Card.Options>
            <FeatureAccessGate feature="feature_dashboard_groups">
              <Button
                icon="plus"
                color="primary"
                size="sm"
                className="mr-3"
                onClick={() => setIsModalOpen(true)}
              >
                GROUP
              </Button>
            </FeatureAccessGate>
          </Card.Options>
        </Card.Header>
      </Card>
      <Grid.Col className="pl-0 pr-0" width={12}>
        <Dimmer active={loading} loader={loading}>
          <Grid.Col className="pl-0 pr-0" xs={12} sm={12} md={6} lg={9}>
            <DashboardGroups />
          </Grid.Col>
          <Grid.Col className="pl-0 pr-0" width={12}>
            <Grid.Row>
              {stripeKey && (
                <Elements
                  stripe={loadStripe(stripeKey, {
                    apiVersion: "2023-08-16",
                    stripeAccount: stripeAccountId,
                  })}
                >
                  {onDeckStudents.map((onDeck) => {
                    const studentCompletedLoads = getStudentLoads(
                      loads,
                      onDeck.studentDetails.id,
                    );
                    return (
                      <Grid.Col lg={3} sm={6} xs={12} key={onDeck.id}>
                        <OnDeckListItem
                          onDeck={onDeck}
                          studentCompletedLoads={studentCompletedLoads}
                        />
                      </Grid.Col>
                    );
                  })}
                </Elements>
              )}
            </Grid.Row>
          </Grid.Col>
        </Dimmer>
      </Grid.Col>
      <GroupFormModal
        isOpen={isModalOpen}
        toggle={setIsModalOpen}
        teamId={currentCoachTeam?.id.toString()}
      />
    </>
  );
};

export default observer(Manifest);

function getStudentLoads(loads, studentId) {
  if (!loads) return [];
  return (
    loads.filter(
      (load: Types.Load) =>
        load.status.slug === "complete" &&
        load.passengers.find((pass) => pass.studentDetails.id === studentId),
    ) || []
  );
}
