import { IconCircleCheck } from '@tabler/icons-react';
import Modal from 'components/Modal';
import React, { FC } from 'react';
import { Button, Text } from 'tabler-react';

interface AccountBalanceSuccessModalProps {
  amount: string;
  email: string;
  isModalOpen: boolean;
  toggleModal: () => void;
}

const AccountBalanceSuccessModal: FC<AccountBalanceSuccessModalProps> = ({
  amount,
  email,
  isModalOpen,
  toggleModal
}) => {
  return (
    <Modal
      fullWidth={true}
      content={
        <div className="text-center">
          <IconCircleCheck color="green" size="60" />
          <h3 className="text-center">Payment Successful!</h3>
          <Text className="mt-7">
            A payment of <span className="font-weight-bold">${amount}</span> has been successfully processed. A
            confirmation has been sent to <span className="font-weight-bold">{email}</span>.
          </Text>
        </div>
      }
      open={isModalOpen}
      onClose={toggleModal}
      actions={
        <Button.List align="center">
          <Button color="secondary" onClick={toggleModal}>
            DASHBOARD
          </Button>
          <Button color="success" onClick={toggleModal}>
            CLOSE
          </Button>
        </Button.List>
      }
    />
  );
};

export default AccountBalanceSuccessModal;
