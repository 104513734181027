import gql from "graphql-tag";

export const EDIT_GROUP_MEMBER = gql`
  mutation EDIT_GROUP_MEMBER(
    $updateGroupAssociatedUsersInput: UpdateGroupAssociatedUsersInput!
  ) {
    updateGroupMember(
      updateGroupAssociatedUsersInput: $updateGroupAssociatedUsersInput
    )
  }
`;
