import { useQuery } from "@apollo/client";
import React from "react";
import { Button, Card } from "tabler-react";
import { GET_TEAM_LOAD_PAYMENT_TYPES } from "../../graphql/GET_TEAM_LOAD_PAYMENT_TYPES";
import { useRootStore } from "../../hooks";
import LoadPaymentTypeListItem from "./LoadPaymentTypeListItem";
import TeamLoadPaymentTypeModal from "./Modal/TeamLoadPaymentTypeModal";

const LoadPaymentTypeList = () => {
  const { currentCoachTeam } = useRootStore();
  const [isOpen, setIsOpen] = React.useState(false);
  const { data, loading } = useQuery(GET_TEAM_LOAD_PAYMENT_TYPES, {
    variables: { teamId: currentCoachTeam.id },
  });

  const teamLoadPaymentTypes = React.useMemo(() => {
    if (!loading && data) {
      return data.getTeamLoadPaymentTypes;
    }
    return [];
  }, [data, loading]);

  return (
    <Card>
      <Card.Header>
        <Card.Title>Load Payment Types</Card.Title>
        <Card.Options>
          <Button
            color={"white"}
            size={"sm"}
            icon={"plus"}
            onClick={() => setIsOpen(true)}
          />
        </Card.Options>
      </Card.Header>
      <Card.Body>
        {teamLoadPaymentTypes.map((loadPaymentType: any) => (
          <LoadPaymentTypeListItem
            loadPaymentType={loadPaymentType}
            key={loadPaymentType.id}
          />
        ))}
      </Card.Body>
      <TeamLoadPaymentTypeModal
        isOpen={isOpen}
        toggleModal={() => setIsOpen(false)}
        loadPaymentType={{ team_id: currentCoachTeam.id }}
      />
    </Card>
  );
};

export default LoadPaymentTypeList;
