import { observer } from 'mobx-react';
import React from 'react';
import { Container } from 'tabler-react';
import Wrapper from '../layout';
import JobList from '../../components/Jobs/JobList';

const JobListPage = (props) => {

  return (
    <Wrapper {...props} title="Jobs List">
      <Container>
        <JobList />
      </Container>
    </Wrapper>
  );
};
export default observer(JobListPage);
