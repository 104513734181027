import gql from 'graphql-tag';

export const GET_ALL_JUMP_TYPES = gql`
  query GET_ALL_JUMP_TYPES {
    loadJumpTypes {
      id
      slug
      name
      seq
    }
  }
`;
