import gql from "graphql-tag";

export const GET_STUDENT_TRANSACTIONS_BY_DATE = gql`
  query GET_STUDENT_TRANSACTIONS_BY_DATE(
    $studentId: ID
    $teamId: ID
    $date: String
  ) {
    getStudentTransactionByDate(
      studentId: $studentId
      teamId: $teamId
      date: $date
    ) {
      id
      coachFirstName
      coachLastName
      created_on
      minutes
      notes
      timeAdded
      transactionTypeName
      transactionTypeSlug
      transactionTypeId
    }
  }
`;
