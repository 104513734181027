import React from 'react'
import Modal from '../../Modal'

interface ErrorModalProps {
  message: string
  type?: string
  title: string
  isOpen: boolean
  toggle: () => void
}

const ErrorModal = ({
  message,
  type,
  title,
  isOpen,
  toggle
}: ErrorModalProps) => {
  return (
    <Modal
      title={title}
      content={<p className={`text-warning`}>{message}</p>}
      open={isOpen}
      onClose={toggle}
    />
  )
}

export default ErrorModal
