import React from 'react';
import { Container } from 'tabler-react';

import Wrapper from 'pages/layout';
import MyStudentsList from '../../components/Coaches/MyStudentsList';

const MyStudentsListPage = (props) => {
  return (
    <Wrapper {...props} title='My Students'>
      <Container>
        <MyStudentsList />
      </Container>
    </Wrapper>
  );
};
export default MyStudentsListPage;
