import { useMutation } from '@apollo/client';
import { Formik } from 'formik';
import React, { useContext } from 'react';
import Select from 'react-select';
import { toast } from 'react-toastify';
import { Icon, Table } from 'tabler-react';

import FormField from '../../../components/FormField';
import { LoadStoreContext } from '../../../contexts/LoadStoreContext';
import { GET_LOADS_BY_TEAM } from '../../../graphql/GET_LOADS_BY_TEAM';
import { UPDATE_LOAD } from '../../../graphql/UPDATE_LOAD';
import { useRootStore } from '../../../hooks/useRootStore';
import { loadStatusOptions } from '../../constants';
import autoCompleteStyles from '../../Students/Forms/autocompleteStyles';
import ConfirmLoadPassengerModal from '../Modals/ConfirmLoadPassengerModal';

enum EReactSelectInputNames {
  aircraft_id = 'aircraft_id',
  status = 'status'
}

interface InlineFormProps {
  loadClone: any;
  setIsEditing: React.Dispatch<React.SetStateAction<boolean>>;
}

const UpdateInlineLoad = ({ loadClone, setIsEditing }: InlineFormProps) => {
  const { aircrafts } = useContext(LoadStoreContext);
  const { currentCoachTeam } = useRootStore();
  const [isOpen, setIsOpen] = React.useState(false);

  const [updateLoad] = useMutation(UPDATE_LOAD, {
    refetchQueries: [
      {
        query: GET_LOADS_BY_TEAM,
        variables: {
          team_id: currentCoachTeam.id
        }
      }
    ],
    onCompleted: () => toast.success('Load Updated')
  });

  return (
    <Formik
      enableReinitialize={true}
      initialValues={{
        id: loadClone.id,
        load_name: loadClone.load_name,
        status: loadClone.status.slug,
        aircraft_id: loadClone.aircraft.id,
        isConfirmed: false
      }}
      onSubmit={async (values, { setSubmitting, resetForm }) => {
        await updateLoad({
          variables: {
            load: {
              id: values.id,
              load_name: values.load_name,
              status: values.status,
              aircraft_id: values.aircraft_id
            }
          }
        });

        setSubmitting(false);
        resetForm();

        setIsEditing(false);
      }}
    >
      {({ values, handleBlur, handleChange, handleSubmit, setFieldValue }) => (
        <>
          <Table.Col>
            <FormField
              additionalinputclasses={['mt-4']}
              name="load_name"
              onBlur={handleBlur}
              onChange={handleChange}
              type="text"
            />
          </Table.Col>
          <Table.Col></Table.Col>
          <Table.Col>
            <Select
              backspaceRemovesValue={true}
              escapeClearsValue={true}
              getOptionLabel={(option) => option.name}
              getOptionValue={(option) => option.id}
              isClearable={false}
              name={EReactSelectInputNames.aircraft_id}
              options={aircrafts}
              onChange={(params) =>
                setFieldValue(EReactSelectInputNames.aircraft_id, params.id)
              }
              styles={autoCompleteStyles}
              value={
                values.aircraft_id &&
                aircrafts.filter(
                  (aircraftsOption) => aircraftsOption.id === values.aircraft_id
                )
              }
            />
          </Table.Col>
          <Table.Col></Table.Col>
          <Table.Col colSpan={2}>
            <Select
              name={EReactSelectInputNames.status}
              className="w-100"
              options={loadStatusOptions}
              isClearable={false}
              onChange={(params) =>
                setFieldValue(EReactSelectInputNames.status, params.value)
              }
              value={
                values.status &&
                loadStatusOptions[
                  Object.keys(loadStatusOptions).find(
                    (key) => loadStatusOptions[key].value === values.status
                  )
                ]
              }
            />
          </Table.Col>
          <Table.Col>
            <Icon
              className="mr-5"
              link
              name="save"
              onClick={() => {
                if (
                  loadClone.status.slug !== 'complete' &&
                  values.status === 'complete'
                ) {
                  //show modal
                  //pass submit handler to modal
                  setIsOpen(true);
                } else {
                  handleSubmit();
                }
              }}
            />
            <Icon link name="x" onClick={() => setIsEditing(false)} />
          </Table.Col>
          <ConfirmLoadPassengerModal
            isOpen={isOpen}
            toggleModal={() => setIsOpen(false)}
            passengers={loadClone.passengers}
            onSubmit={handleSubmit}
          />
        </>
      )}
    </Formik>
  );
};

export default UpdateInlineLoad;
