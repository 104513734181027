import React, { useContext, useMemo, useState } from 'react';
import { Button, Card, Dropdown, Grid, Icon, Text } from 'tabler-react';
import { useRootStore } from '../../hooks';
import { UserStoreContext } from '../../contexts/userStoreContext';
import { getWingLoad } from '../../utils/numberFormat';
import { daysBefore180 } from '../User/UserCurrencyCard';
import Modal from '../Modal';
import { DELETE_RIG } from '../../graphql/DELETE_RIG';
import { useMutation } from '@apollo/client';
import { toast } from 'react-toastify';

interface RigCardParams {
  rig: any;
  toggleModal: any;
  setRig: any;
}

const RigCardItem = ({ rig, toggleModal, setRig }: RigCardParams) => {
  const { user } = useContext(UserStoreContext);
  const { currentUser } = useRootStore();
  const container = rig?.rig_gear.filter((item) => item.gear_type_id === 5)[0];
  const main = rig?.rig_gear.filter((item) => item.gear_type_id === 1)[0];
  const reserve = rig?.rig_gear.filter((item) => item.gear_type_id === 2)[0];
  const aad = rig?.rig_gear.filter((item) => item.gear_type_id === 4)[0];

  const wingLoad = useMemo(() => {
    if (user && user?.weight) {
      return getWingLoad(main?.gear_canopy_size, user.weight);
    }
    if (currentUser && currentUser?.weight) {
      return getWingLoad(main?.gear_canopy_size, currentUser.weight);
    }
    return null;
  }, [user, currentUser, main]);

  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);

  const [deleteRig] = useMutation(DELETE_RIG, {
    onCompleted: () => {
      toast.success('Rig removed.');
    },
    refetchQueries: ['GET_RIGS']
  });

  const removeGear = () => {
    deleteRig({
      variables: {
        id: Number(rig.id)
      }
    });
  };
//  console.log(daysBefore180(reserve.event_date));
  return (
    <>
      <Card>
        <Card.Status color="info" side />
        <Card.Header className="bg-light">
          <Card.Title>
            <span
              className="text-muted cursor-pointer"
              onClick={() => {
                setRig(rig);
                toggleModal(true);
              }}
            >
              {rig?.name}
            </span>{' '}
            <span className="d-block">
              {container?.gear_model_name}
              <span className="ml-1 text-success">
                {wingLoad && main?.gear_canopy_size && wingLoad}
              </span>
            </span>
          </Card.Title>
          <Card.Options>
            <Dropdown
              className="float-right cursor-pointer"
              toggle={false}
              position="bottom-end"
              arrow={false}
              arrowPosition="right"
              triggerContent={<Icon name="more-vertical" />}
              itemsObject={[
                {
                  value: 'Remove',
                  onClick: () => {
                    setIsConfirmModalOpen(true);
                  }
                }
              ]}
            />
          </Card.Options>
        </Card.Header>
        <Card.Body>
          <Text.Small className={`text-black`}>
            <Button size="sm" color="secondary" className="mr-1 mb-1">
              {main?.gear_model_name ? (
                main?.gear_model_name + ' ' + main?.gear_canopy_size
              ) : (
                <span className="text-danger">Incomplete </span>
              )}
            </Button>
            <Button
              icon={
                reserve?.event_date &&
                daysBefore180(reserve.event_date) < 0 &&
                'x-circle'
              }
              size="sm"
              color={
                !!reserve?.event_date && daysBefore180(reserve.event_date) < 0
                  ? 'danger'
                  : 'secondary'
              }
              className={`mr-1 mb-1 ${
                !!reserve?.event_date && daysBefore180(reserve.event_date) < 0
                  ? 'bg-danger text-white'
                  : ''
              } `}
            >
              {reserve?.event_date && daysBefore180(reserve.event_date) > 0 && (
                <i className="fe fe-check text-success mr-2" />
              )}
              {reserve?.gear_model_name ? (
                reserve?.gear_model_name + ' ' + reserve?.gear_canopy_size
              ) : (
                <span className="text-danger">Incomplete </span>
              )}
            </Button>
            <Button
              color="secondary"
              size="sm"
              className={aad ? 'mb-1' : 'mb-1 text-muted'}
              disabled={!aad}
            >
              {aad?.gear_model_name ? aad?.gear_model_name : 'NO AAD'}
            </Button>
          </Text.Small>
        </Card.Body>
      </Card>
      <Modal
        open={isConfirmModalOpen}
        onClose={() => setIsConfirmModalOpen(false)}
        content={
          <>
            <Grid.Row className="mb-5">
              <Grid.Col width="12">
                <Text>Are you sure you want to remove this rig?</Text>
              </Grid.Col>
            </Grid.Row>
            <Button.List align="right">
              <Button
                color="secondary"
                onClick={() => setIsConfirmModalOpen(false)}
              >
                Cancel
              </Button>
              <Button
                icon="check-circle"
                color="primary"
                onClick={() => {
                  removeGear();
                  setIsConfirmModalOpen(false);
                }}
              >
                Yes
              </Button>
            </Button.List>
          </>
        }
      />
    </>
  );
};

export default RigCardItem;
