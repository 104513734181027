import { useMutation } from "@apollo/client";
import { Formik } from "formik";
import { observer } from "mobx-react";
import React from "react";
import { Button, Form } from "tabler-react";
import * as Yup from "yup";
import { EDIT_STUDENT_TEAM_PROGRESSION } from "../../../graphql/EDIT_STUDENT_TEAM_PROGRESSION";
import { GET_STUDENT_TEAM_PROGRESSION } from "../../../graphql/GET_STUDENT_TEAM_PROGRESSION";
import { Types } from "../../../types/graphql";

const addUserNoteValidationSchema = Yup.object().shape({
  notes: Yup.string().required("This field is required."),
});

interface IStudentProgressionNotesForm {
  studentTeamProgression: Types.StudentTeamProgression;
  setIsEdittingNote: any;
  studentId: number;
}

const StudentProgressionNotesForm = ({
  studentTeamProgression,
  setIsEdittingNote,
  studentId,
}: IStudentProgressionNotesForm) => {
  const [editStudentTeamProgression] = useMutation(
    EDIT_STUDENT_TEAM_PROGRESSION,
  );
  const initValues = {
    id: studentTeamProgression?.studentProgressionId,
    notes: studentTeamProgression?.studentProgressionNotes ?? "",
  };

  const renderForm = ({ values, handleSubmit, handleChange, errors }) => (
    <>
      <Form.Textarea
        //  className={errors.note && 'is-invalid'}
        className="mt-3"
        name="notes"
        placeholder="Add notes"
        rows={3}
        type="text"
        onChange={handleChange}
        value={values.notes}
      />
      {errors.notes && (
        <span className="field-error text-danger">{errors.notes}</span>
      )}
      <Button.List className="mt-4" align="right">
        <Button
          icon="x"
          color="white"
          size="sm"
          onClick={(e) => {
            e.preventDefault();
            setIsEdittingNote(false);
          }}
        ></Button>
        <Button
          icon="save"
          color="primary"
          size="sm"
          type="submit"
          onClick={() => handleSubmit()}
        />
      </Button.List>
    </>
  );
  return (
    <>
      <Formik
        enableReinitialize={true}
        validationSchema={addUserNoteValidationSchema}
        initialValues={initValues}
        onSubmit={async (values) => {
          await editStudentTeamProgression({
            variables: {
              editStudentProgressionInput: {
                ...values,
              },
            },
            refetchQueries: ["GET_STUDENT_TEAM_PROGRESSION"],
          });
          setIsEdittingNote(false);
        }}
      >
        {(formikData) => renderForm(formikData)}
      </Formik>
    </>
  );
};

export default observer(StudentProgressionNotesForm);
