import gql from 'graphql-tag';

export const ADD_TEAM = gql`
  mutation ADD_TEAM($team: TeamInput) {
    addTeam(team: $team) {
      id
      name
      coach_business_type
      default_account_type_id
      default_location_id
      default_location_name
      feature_accounting
      feature_alpha
      feature_app_reports
      feature_booking_calendar
      feature_camps
      feature_cancel_transactions
      feature_coach_account
      feature_dashboard
      feature_dashboard_groups
      feature_disable_students
      feature_dzm_account
      feature_edit_transactions
      feature_locked_account_actions
      feature_manifest
      feature_open_add_students
      feature_paid_status
      feature_recurring_transactions
      feature_registrations
      feature_require_uspa
      feature_rigger_account
      feature_schedule_open
      feature_slot_registrations
      feature_student_progression
      feature_transfer_time
      is_public
      is_published
      preference_units
      student_contact_required
      team_bio
      team_coach_hour_fee
      team_email
      team_logo_square_image
      team_logo_wide_image
      team_phone
      team_promo_image
      team_url

      students {
        id
        first_name
        last_name
      }

      sports {
        id
        slug
        name
        is_active
      }

      locations {
        name
      }
    }
  }
`;
