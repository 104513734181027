import { getSnapshot } from 'mobx-state-tree';
import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { Badge, Button, Table, Avatar, Text, Icon } from 'tabler-react';
import useReactRouter from 'use-react-router';
import { UserStoreContext } from '../../contexts/userStoreContext';

interface IUserListItemProps {
  user: any;
}

const UserListItem = ({ user }: IUserListItemProps) => {
  const userStore = useContext(UserStoreContext);
  const { history } = useReactRouter();
  const routeToStudent = (user) => {
    userStore.loadUser(getSnapshot(user));
    history.push('/user-details');
  };

  return (
    <>
      <Table.Col>
        <Avatar
          size="md"
          className="float-left mr-5 cursor-pointer"
          status={`${user.registration_id
              ? 'success'
              : 'secondary'
            }`
          }
          imageURL={
            (user.profile_avatar &&
              `/api/s3/uploads/${user.profile_avatar}`) ||
            '//www.gravatar.com/avatar?d=mp'
          }
          onClick={(e) => {
            e.preventDefault();
            routeToStudent(user);
          }}
          to='#'
        />
          <Link
          className='text-inherit text-decoration-none'
          onClick={(e) => {
            e.preventDefault();
            routeToStudent(user);
          }}
          to='#'
        >
          {user.first_name} {user.middle_name} {user.last_name} {user.preferred_name && '(' + user.preferred_name + ')'}
        </Link>
        <Text.Small className="d-block text-muted">
          SKY.D: {user.id}
        </Text.Small>
      </Table.Col>
      <Table.Col>
         {user.created_on} {user.preference_24h} {user.preference_units}
      </Table.Col>
      <Table.Col>
        <Icon
          name="phone"
          className={`${user.phone_number
            ? 'text-gray-dark mr-2'
            : 'text-muted mr-2'
          }`
        }
        />
        <Icon
          name="mail"
          className={`${user.email
            ? 'text-gray-dark'
            : 'text-muted'
          }`
        }
        />
      </Table.Col>
      <Table.Col>
        {(user.is_disabled_on &&
          new Date(user.is_disabled_on).toLocaleDateString())
        }
      </Table.Col>
      <Table.Col>
        {user.teams.map((team: { name: string; id: string }) => {
          return (
            <React.Fragment key={team.id}>
              <Badge color="secondary" size="sm" className="mr-1 mb-1 p-2">
                {team.name}
              </Badge>
            </React.Fragment>
          );
        })}
      </Table.Col>
      <Table.Col>
        <React.Fragment>
          <div className="dropdown">
            <Button color="secondary" size="sm" isDropdownToggle>
              Actions
            </Button>
          </div>
        </React.Fragment>
      </Table.Col>
    </>
  );
};

export default UserListItem;
