import { observer } from 'mobx-react';
import React, { useContext } from 'react';
import { Form, Grid } from 'tabler-react';

import { EventStoreContext } from '../../../contexts/eventStoreContext';
import { EEventStatus } from '../../../models/EventStore';

const EventRegistrationRequiredForm = ({ mstHandleChange }) => {
  const { eventEntry } = useContext(EventStoreContext);

  return (
    <Grid.Row>
      <Grid.Col>
        <Form.Group label="Will Registrations be used for this event?">
          <Form.Radio
            checked={eventEntry.status === EEventStatus.registrations}
            label="Yes"
            name="status"
            value={EEventStatus.registrations}
            onChange={mstHandleChange}
          />
          <Form.Radio
            checked={eventEntry.status === EEventStatus.event}
            label="No"
            name="status"
            value={EEventStatus.event}
            onChange={mstHandleChange}
          />
        </Form.Group>
      </Grid.Col>
    </Grid.Row>
  );
};

export default observer(EventRegistrationRequiredForm);
