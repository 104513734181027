import * as React from 'react';
import { Link } from 'react-router-dom';
import { Button, Page } from 'tabler-react';
import { ProductStoreProvider } from '../../../contexts/productContext';
import Wrapper from '../../layout';
import ProductPageContent from '../../../components/Product/ProductPageContent';

const ShowProductsPage = (props) => {

  return (
    <Wrapper {...props} title="Products">
      <ProductStoreProvider>
        <Page.Header>
          <div className="container">
            <div className="float-right">
              <Link to="/products/new">
                <Button color="blue" size="md" icon="plus">
                  PRODUCT
                </Button>
              </Link>
            </div>
            <h1 className="page-title">Products & Services</h1>
          </div>
        </Page.Header>
        <Page.Content>
          <ProductPageContent />
        </Page.Content>
      </ProductStoreProvider>
    </Wrapper>
  );
};

export default ShowProductsPage;
