import gql from 'graphql-tag';

export const GET_EMAIL_VALIDATION = gql`
  query GET_EMAIL_VALIDATION($email: String) {
    validateEmail(email: $email) {
      id
      address1
      address2
      city
      country
      dob
      email
      first_name
      height
      is_disabled_on
      is_disabled_on
      jump_weight
      last_name
      middle_name
      phone_country_code
      phone_number
      postal_code
      preference_24h
      preference_units
      preferred_name
      profile_avatar
      profile_picture
      province
      registration_id
      shirt_size_id
      token
      total
      weight
      nac
      gender
      jump_count
      auth_merits {
        member_id
      }

      uspa {
        first_name
        last_name
        email
        updated_on
        merits_uspa_member_id
        merits_uspa_member_expiration
        template_id
        template_title
      }
    }
  }
`;
