import React from 'react';
import { Button, Text } from 'tabler-react';
import useRouter from 'use-react-router';

const PageNotFound = (props: any) => {
  const { history } = useRouter();
  return (
    <>
      <div className="page">
        <div className="page-single">
          <div className="container">
            <div className="row">
              <div className="col mx-auto">
                <Text className="text-center mb-6">
                  <Text className="mb-5 h1 display-1 text-muted">404</Text>
                  <Text className="mb-4 h2">Oops... There has been a mistake...</Text>
                  <Text className="mb-4 h4 text-muted">
                    The page you are looking for could not be found.
                  </Text>
                  <Button.List align="center">
                    <Button
                      icon="chevron-left"
                      color="primary"
                      className=""
                      onClick={() => history.push(`/`)}
                    >
                      GO HOME
                    </Button>
                  </Button.List>
                </Text>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PageNotFound;
