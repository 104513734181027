import gql from "graphql-tag";

export const GET_PAYMENT = gql`
  query GET_PAYMENT($payment_id: Int!) {
    getPayment(payment_id: $payment_id) {
      id
      amount
      application_fee
      camp_name
      card_brand
      card_country
      card_customer_name
      coach_first_name
      coach_last_name
      created_on
      created_by
      currency
      description
      fees
      first_name
      invoice_id
      last_name
      last4
      manual_payment
      processed_by
      product_title
      status
      team_name
      total
    }
  }
`;
