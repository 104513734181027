import gql from 'graphql-tag';

export const ADD_STUDENT_ACCOUNT_BALANCE = gql`
  mutation ADD_STUDENT_ACCOUNT_BALANCE(
    $payload: AddStudentAccountBalancePayload!
  ) {
    addStudentAccountBalance(payload: $payload) {
      client_secret
      success
    }
  }
`;
