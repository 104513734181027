import { values } from 'mobx';
import { cast, detach, Instance, SnapshotIn, types } from 'mobx-state-tree';

const Location = types
  .model({
    id: types.string,
    key: types.optional(types.string, '', [null]),
    type: types.optional(types.string, '', [null]),
    name: types.optional(types.string, '', [null]),
    description: types.optional(types.string, '', [null]),
    address: types.optional(types.string, '', [null]),
    is_frozen: types.optional(types.boolean, false, [null]),
    latitude: types.optional(types.number, 0, [null]),
    longitude: types.optional(types.number, 0, [null]),
    email: types.optional(types.string, '', [null]),
    phone: types.optional(types.string, '', [null]),
    url: types.optional(types.string, '', [null]),
    city: types.optional(types.string, '', [null]),
    region: types.optional(types.string, '', [null]),
    province: types.optional(types.string, '', [null]),
    postal_code: types.optional(types.string, '', [null]),
    country: types.optional(types.string, '', [null]),
    locality: types.optional(types.string, '', [null]),
    status: types.optional(types.string, '', [null]),
    tunnel_type: types.optional(types.string, '', [null]),
    owner: types.optional(types.string, '', [null]),
    manufacturer: types.optional(types.string, '', [null]),
    open_since: types.optional(types.string, '', [null]),
    is_student_favorite: types.optional(types.boolean, false, [null]),
    is_team_added: types.optional(types.boolean, false, [null]),
    is_set_home: types.optional(types.boolean, false, [null]),
    object_type: types.optional(types.string, '', [null]),
    chamber_diameter: types.optional(types.number, 0, [null, undefined]),
    chamber_height: types.optional(types.number, 0, [null, undefined])
  })
  .actions((self) => ({
    set<
      K extends keyof SnapshotIn<typeof self>,
      T extends SnapshotIn<typeof self>
    >(key: K, value: T) {
      self[key] = cast(value);
    }
  }));

export const LocationStoreDefault = () => {
  return LocationStore.create({
    locations: undefined
  });
};

export const LocationStore = types
  .model({
    locations: types.optional(types.array(Location), [])
  })
  .views((self) => ({
    getLocationById(locationId: string) {
      return values(self.locations).find(
        (location) => location.id === locationId
      );
    }
  }))
  .actions((self) => ({
    detach(treeItem) {
      if (treeItem) {
        detach(treeItem);
      }
    },
    storeLocation(location: any) {
      if (!self.getLocationById(location.id))
        self.locations.push(Location.create(location));
    }
  }));

export interface LocationStoreType extends Instance<typeof LocationStore> {}
