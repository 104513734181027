import Modal, { DialogProps } from '@material-ui/core/Dialog';
import MuiDialogActions from '@material-ui/core/DialogActions';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import {
  createStyles,
  Theme,
  withStyles,
  WithStyles
} from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import React, { ReactNode } from 'react';

const styles = (theme: Theme) =>
  createStyles({
    root: {
      margin: 0,
      padding: theme.spacing(2)
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500]
    }
  });

export interface DialogTitleProps extends WithStyles<typeof styles> {
  id: string;
  children: React.ReactNode;
  onClose: () => void;
}

export const DialogTitle = withStyles(styles)((props: DialogTitleProps) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

export const DialogContent = withStyles((theme: Theme) => ({
  root: {
    padding: theme.spacing(2)
  }
}))(MuiDialogContent);

export const DialogActions = withStyles((theme: Theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2)
  }
}))(MuiDialogActions);

interface IDialogProps {
  actions?: ReactNode;
  className?: string;
  content: ReactNode;
  open: boolean;
  title?: string;
  onClose: () => void;
}

type ModalProps = IDialogProps & DialogProps;

const Dialog = (props: ModalProps) => {
  return (
    <Modal fullWidth={true} {...props}>
      {props?.title && (
        <DialogTitle id="modal-dialog-title" onClose={props.onClose}>
          {props.title}
        </DialogTitle>
      )}
      <DialogContent dividers>{props.content}</DialogContent>
      {props.actions && <DialogActions>{props.actions}</DialogActions>}
    </Modal>
  );
};

export default Dialog;
