import gql from 'graphql-tag';

export const GET_PAYMENT_FEE_BY_TEAM_ID = gql`
  query GET_PAYMENT_FEE_BY_TEAM_ID($teamId: Int!) {
    getPaymentFeeByTeamId(teamId: $teamId) {
      id
      payment_fee_flat
      payment_fee_customer
      payment_fee_percentage
    }
  }
`;
