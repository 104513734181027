import { useMutation } from "@apollo/client";
import { Formik } from "formik";
import React from "react";
import { toast } from "react-toastify";
import { Button } from "tabler-react";
import * as Yup from "yup";
import FormField from "../../../../components/FormField";
import { ADD_NOTIFICATION_EMAIL } from "../../../../graphql/ADD_NOTIFICATION_EMAIL";

type AddNotificationEmailFormProps = {
  campId: number;
};

const addCampNotificationEmailValidationSchema = Yup.object().shape({
  email: Yup.string().email().required("This field is required."),
});

const AddNotificationEmailForm = ({
  campId,
}: AddNotificationEmailFormProps) => {
  const [addCampNotificationEmail] = useMutation(ADD_NOTIFICATION_EMAIL, {
    onCompleted: () => {
      toast.success("Email added");
    },
    refetchQueries: ["GET_NOTIFICATION_EMAILS"],
  });
  return (
    <Formik
      initialValues={{
        email: "",
        camp_id: campId,
      }}
      validationSchema={addCampNotificationEmailValidationSchema}
      onSubmit={async (values, { setSubmitting, resetForm }) => {
        await addCampNotificationEmail({
          variables: {
            addCampNotificationEmailInput: values,
          },
        });
        resetForm();
        setSubmitting(false);
      }}
    >
      {({ values, handleChange, handleSubmit, isSubmitting }) => (
        <form onSubmit={handleSubmit}>
          <FormField
            appendleft={<Button icon="mail" size="sm" color="secondary" disabled />}
            appendright={values.email &&
              <Button
                size="sm"
                color="success"
                disabled={isSubmitting}
                onClick={handleSubmit}
              >
                ADD
              </Button>
            }
            name="email"
            type="email"
            placeholder="Add an email address"
            onChange={handleChange}
            value={values.email}
          />
        </form>
      )}
    </Formik>
  );
};

export { AddNotificationEmailForm };
