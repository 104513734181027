import { useQuery } from "@apollo/client";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import AccountBalance from "components/User/AccountBalance";
import { UserStoreContext } from "contexts/userStoreContext";
import { GET_ACCOUNT_BALANCE } from "graphql/GET_ACCOUNT_BALANCE";
import { GET_ACCOUNT_BALANCE_PAYMENT_HISTORY } from "graphql/GET_ACCOUNT_BALANCE_PAYMENT_HISTORY";
import { GET_INVOICE_LIST } from "graphql/GET_INVOICE_LIST";
import { GET_STRIPE_ACCOUNT_ID_BY_TEAM_ID } from "graphql/GET_STRIPE_ACCOUNT_ID_BY_TEAM_ID";
import { GET_STRIPE_PUBLIC_KEY } from "graphql/GET_STRIPE_PUBLIC_KEY";
import { GET_STUDENT_BY_ID } from "graphql/GET_STUDENT_BY_ID";
import { GET_STUDENT_TEAM_ACCOUNT_BALANCE_HISTORY } from "graphql/GET_STUDENT_TEAM_ACCOUNT_BALANCE_HISTORY";
import { GET_STUDENT_TIME_TRANSACTION_HISTORY } from "graphql/GET_STUDENT_TIME_TRANSACTION_HISTORY";
import { GET_ACCOUNT_BALANCE as GET_ACCOUNT_BALANCE_TYPE } from "graphql/types/GET_ACCOUNT_BALANCE";
import { GET_ACCOUNT_BALANCE_PAYMENT_HISTORY as GET_ACCOUNT_BALANCE_PAYMENT_HISTORY_TYPE } from "graphql/types/GET_ACCOUNT_BALANCE_PAYMENT_HISTORY";
import { GET_INVOICE_LIST as GET_INVOICE_LIST_TYPE } from "graphql/types/GET_INVOICE_LIST";
import { GET_STRIPE_ACCOUNT_ID_BY_TEAM_ID as GET_STRIPE_ACCOUNT_ID_BY_TEAM_ID_TYPE } from "graphql/types/GET_STRIPE_ACCOUNT_ID_BY_TEAM_ID";
import { GET_STUDENT_TEAM_ACCOUNT_BALANCE_HISTORY as GET_STUDENT_TEAM_ACCOUNT_BALANCE_HISTORY_TYPE } from "graphql/types/GET_STUDENT_TEAM_ACCOUNT_BALANCE_HISTORY";
import { GET_STUDENT_TIME_TRANSACTION_HISTORY as GET_STUDENT_TIME_TRANSACTION_HISTORY_TYPE } from "graphql/types/GET_STUDENT_TIME_TRANSACTION_HISTORY";
import { useRootStore } from "hooks/useRootStore";
import FeatureAccessGate from "modules/common/components/FeatureAccessGate";
import moment from "moment";
import StudentTeamsPageHeader from "pages/Student/StudentTeamsPageHeader";
import React, { useContext, useEffect, useMemo } from "react";
import { useParams } from "react-router";
import { Alert, Card, Container, Dimmer, Grid, Table, Tag } from "tabler-react";
import useReactRouter from "use-react-router";
import { formatMoney } from "utils/numberFormat";
import { parseIntNullable } from "utils/numberParser";
import AccountTransactionsPageTransactionItem from "../../components/Transaction/AccountTransactionsPageTransactionItem";
import useGetCurrentUserType from "../../modules/common/hooks/useGetCurrentUserType";
import { Types } from "../../types/graphql";
import Wrapper from "../layout";

const AccountTransactions = (props) => {
  return (
    <Wrapper {...props} title="Account Transactions">
      <AccountTransactionsInner {...props} />
    </Wrapper>
  );
};

const AccountTransactionsInner = (props) => {
  const { history } = useReactRouter();
  const { currentCoachTeam, currentUser } = useRootStore();
  const { isCoach } = useGetCurrentUserType();

  const { userId, teamId } = useParams<{ userId: string; teamId: string }>();

  const userIdValue = parseIntNullable(userId);
  const teamIdValue = parseIntNullable(teamId);
  const userStore = useContext(UserStoreContext);

  const { data: userData } = useQuery(GET_STUDENT_BY_ID, {
    variables: {
      student_id: userIdValue,
    },
  });
  useEffect(() => {
    if (userData && userData.getStudentById) {
      userStore.loadUser(userData.getStudentById);
    }
  });

  const {
    data: stripeAccountData,
    loading: loadingStripe,
    error: errorStripe,
  } = useQuery<GET_STRIPE_ACCOUNT_ID_BY_TEAM_ID_TYPE>(
    GET_STRIPE_ACCOUNT_ID_BY_TEAM_ID,
    {
      variables: {
        teamIdValue,
      },
    },
  );

  const { loading: teamBalanceLoading, data } =
    useQuery<GET_STUDENT_TEAM_ACCOUNT_BALANCE_HISTORY_TYPE>(
      GET_STUDENT_TEAM_ACCOUNT_BALANCE_HISTORY,
      {
        variables: {
          studentId: userId,
          teamId: teamIdValue,
          userType: currentUser.type,
        },
        fetchPolicy: "network-only",
      },
    );

  const { data: accountBalance, loading: accountBalanceLoading } =
    useQuery<GET_ACCOUNT_BALANCE_TYPE>(GET_ACCOUNT_BALANCE, {
      variables: {
        studentId: userId,
        teamId: teamIdValue,
      },
    });

  const {
    data: accountBalancePaymentHistory,
    loading: accountBalancePaymentHistoryLoading,
  } = useQuery<GET_ACCOUNT_BALANCE_PAYMENT_HISTORY_TYPE>(
    GET_ACCOUNT_BALANCE_PAYMENT_HISTORY,
    {
      variables: {
        input: {
          studentId: userId,
          teamId: teamIdValue,
        },
        filter: {
          limit: 5,
        },
      },
    },
  );

  const studentTeamRow = useMemo(
    () =>
      data?.getStudentTeamAccountBalanceHistory?.map(
        ({ id, account_type_slug, team_name, total_time, updated_on }) => ({
          id,
          account_type_slug,
          team_name,
          total_time,
          updated_on,
        }),
      ) ?? [],
    [data?.getStudentTeamAccountBalanceHistory, teamBalanceLoading],
  );

  const { data: transactions, loading: transactionsLoading } =
    useQuery<GET_STUDENT_TIME_TRANSACTION_HISTORY_TYPE>(
      GET_STUDENT_TIME_TRANSACTION_HISTORY,
      {
        variables: {
          studentId: userIdValue,
          teamId: teamIdValue,
          userType: currentUser.type,
        },
        fetchPolicy: "network-only",
      },
    );

  // Group by year, sort by latest year
  const transactionHistoryByYear = useMemo(() => {
    const grouped = transactions?.getStudentTimeTransactionHistory.reduce(
      (acc, transaction) => {
        const year = moment(transaction.createdOn).year();
        const yearGroup = acc[year] ?? [];
        return {
          ...acc,
          [year]: [...yearGroup, transaction],
        };
      },
      {},
    );

    return Object.entries(grouped ?? {})
      .sort(([a], [b]) => parseInt(b) - parseInt(a))
      .map(([year, transactions]) => ({
        year,
        transactions,
      }));
  }, [transactions?.getStudentTimeTransactionHistory]);

  const { data: stripeData, loading: stripePubLoading } = useQuery(
    GET_STRIPE_PUBLIC_KEY,
  );

  const { data: invoiceData, loading: invoiceLoading } =
    useQuery<GET_INVOICE_LIST_TYPE>(GET_INVOICE_LIST, {
      variables: {
        invoiceListFilter: {
          team_id: Number(teamId),
          student_id: Number(userId),
        },
      },
    });

  const accountBalanceData = useMemo(() => {
    return accountBalance?.getAccountBalance;
  }, [accountBalance, accountBalanceLoading]);

  const loading =
    invoiceLoading ||
    stripePubLoading ||
    transactionsLoading ||
    accountBalancePaymentHistoryLoading ||
    accountBalanceLoading ||
    teamBalanceLoading ||
    loadingStripe;

  const invoiceRows = invoiceData?.invoiceList.invoices ?? [];
  const stripeKey = stripeData?.getStripePublicKey;
  const stripeAccountId =
    stripeAccountData?.getStripeAccountIdByTeamId?.stripe_account_id;

  return (
    <Container>
      <Grid.Row>
        <Grid.Col>
          <Dimmer active={loading} loader={loading}>
            <Card>
              {stripeKey && (
                <Elements
                  stripe={loadStripe(stripeKey, {
                    apiVersion: "2023-08-16",
                    stripeAccount: stripeAccountId,
                  })}
                >
                  <StudentTeamsPageHeader
                    teamId={teamIdValue}
                    isStudentTeamNew={studentTeamRow.length <= 1}
                  />
                </Elements>
              )}
              <Card.Header className="gap-1">
                Available Balance:
                <AccountBalance
                  accountBalance={accountBalanceData?.total_time ?? 0}
                  slug={
                    currentCoachTeam?.account_type_slug ??
                    accountBalanceData?.account_type_slug
                  }
                />
              </Card.Header>
            </Card>
          </Dimmer>
        </Grid.Col>
      </Grid.Row>
      <Grid.Row>
        <Grid.Col lg={4} md={4} sm={12} xs={12}>
          <FeatureAccessGate feature="feature_payments">
            <Card statusColor="gray-dark">
              <Card.Header>
                <Card.Title>Payments</Card.Title>
              </Card.Header>
              <Dimmer
                active={accountBalancePaymentHistoryLoading}
                loader={accountBalancePaymentHistoryLoading}
              >
                <Table className="card-table">
                  <Table.Body>
                    {accountBalancePaymentHistory
                      ?.getAccountBalancePaymentHistory.length > 0 ? (
                      accountBalancePaymentHistory?.getAccountBalancePaymentHistory.map(
                        (accountBalancePaymentHistory) => (
                          <Table.Row key={accountBalancePaymentHistory.id}>
                            <Table.Col>
                              {new Intl.DateTimeFormat("en-US").format(
                                new Date(
                                  accountBalancePaymentHistory.created_on,
                                ),
                              )}
                            </Table.Col>
                            <Table.Col className="text-right">
                              {formatMoney(accountBalancePaymentHistory.amount)}
                              <Tag
                                color={
                                  accountBalancePaymentHistory.status === "Paid"
                                    ? "success"
                                    : "secondary"
                                }
                                className="ml-2"
                              >
                                {accountBalancePaymentHistory.status}
                              </Tag>
                            </Table.Col>
                          </Table.Row>
                        ),
                      )
                    ) : (
                      <Table.Row>
                        <Table.Col colSpan="8">
                          <Alert type="info text-center">
                            <strong>No Payments</strong>
                          </Alert>
                        </Table.Col>
                      </Table.Row>
                    )}
                  </Table.Body>
                </Table>
              </Dimmer>
            </Card>
          </FeatureAccessGate>
          <FeatureAccessGate feature="feature_invoices">
            <Card statusColor="gray-dark">
              <Card.Header>
                <Card.Title>Invoices</Card.Title>
              </Card.Header>
              <Dimmer active={invoiceLoading} loader={invoiceLoading}>
                <Table className="card-table">
                  <Table.Body>
                    {invoiceRows?.length > 0 ? (
                      invoiceRows?.map((invoice) => (
                        <Table.Row key={invoice.id}>
                          <Table.Col>
                            <span
                              className="cursor-pointer"
                              onClick={(e) => {
                                e.preventDefault();
                                history.push(`/dzm/invoice/${invoice.id}`);
                              }}
                            >
                              {new Intl.DateTimeFormat("en-US").format(
                                new Date(invoice.created_on),
                              )}
                            </span>
                          </Table.Col>
                          <Table.Col className="text-right">
                            {formatMoney(invoice.invoice_amount)}
                            <Tag className="ml-2 text-muted">
                              {invoice.status}
                            </Tag>
                          </Table.Col>
                        </Table.Row>
                      ))
                    ) : (
                      <Table.Row>
                        <Table.Col>
                          <Alert type="info text-center">
                            <strong>No Invoices</strong>
                          </Alert>
                        </Table.Col>
                      </Table.Row>
                    )}
                  </Table.Body>
                </Table>
              </Dimmer>
            </Card>
          </FeatureAccessGate>
          {!currentCoachTeam && (
            <Card>
              <Table className="card-table">
                <Table.Header>
                  <Table.Row className="bg-light">
                    <Table.ColHeader></Table.ColHeader>
                    <Table.ColHeader className="text-right">
                      Daily Balance History
                    </Table.ColHeader>
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  {studentTeamRow.length > 0 ? (
                    studentTeamRow.map((studentTeam) => (
                      <Table.Row key={studentTeam.id}>
                        <Table.Col>
                          {moment(studentTeam.updated_on).format("MM/DD/YYYY")}
                        </Table.Col>
                        <Table.Col className="text-right">
                          <AccountBalance
                            accountBalance={studentTeam.total_time}
                            slug={
                              currentCoachTeam?.account_type_slug ??
                              accountBalance?.getAccountBalance
                                ?.account_type_slug
                            }
                          />
                        </Table.Col>
                      </Table.Row>
                    ))
                  ) : (
                    <Table.Row>
                      <Table.Col colSpan="8">
                        <Alert type="info text-center">
                          <strong>No Data</strong>
                        </Alert>
                      </Table.Col>
                    </Table.Row>
                  )}
                </Table.Body>
              </Table>
            </Card>
          )}
        </Grid.Col>
        <Grid.Col lg={8} md={8} sm={12} xs={12}>
          <Card statusColor="gray-dark">
            <Card.Header>
              <Card.Title>Transactions</Card.Title>
            </Card.Header>
            <Table className="card-table">
              <Table.Body>
                {transactionHistoryByYear.length > 0 ? (
                  transactionHistoryByYear.map(
                    ({
                      year,
                      transactions,
                    }: {
                      year: string;
                      transactions: Types.StudentTimeTransactionHistory[];
                    }) => (
                      <React.Fragment key={year}>
                        <Table.Row className="bg-light">
                          <Table.ColHeader>{year}</Table.ColHeader>
                          <Table.ColHeader></Table.ColHeader>
                          <Table.ColHeader className="text-right"></Table.ColHeader>
                        </Table.Row>
                        {transactions.map((transaction) => (
                          <AccountTransactionsPageTransactionItem
                            key={transaction.id}
                            teamId={teamIdValue}
                            transaction={transaction}
                            studentId={userId}
                          />
                        ))}
                      </React.Fragment>
                    ),
                  )
                ) : (
                  <Table.Row>
                    <Table.Col colSpan="8">
                      <Alert type="info text-center">
                        <strong>No Data</strong>
                      </Alert>
                    </Table.Col>
                  </Table.Row>
                )}
              </Table.Body>
            </Table>
          </Card>
          {isCoach && currentCoachTeam && (
            <Alert type="info text-center">
              Transaction history limited to 5 years!{" "}
              <strong>
                <a href="/coach/pricing">Upgrade</a>
              </strong>
            </Alert>
          )}
        </Grid.Col>
      </Grid.Row>
    </Container>
  );
};

export default AccountTransactions;
