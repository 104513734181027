import axios from 'axios';
import React, { useCallback, useEffect, useState } from 'react';
import Moment from 'react-moment';
import { Button, Card, Grid, Icon, Text } from 'tabler-react';

import { EUserTypes } from 'components/User/userTypes';
import { useRootStore } from 'hooks/useRootStore';
import { useLocation } from 'react-router-dom';

const MeritCard = () => {
  const rootStore = useRootStore();
  const { currentUser } = rootStore;

  const [loading, setLoading] = useState(false);
  const [meritConfig, setMeritConfig] = useState(null);

  const merits: any[] = meritConfig?.merits ?? [];
  const location = useLocation();

  const queryServer = async (url: string) => {
    setLoading(true);

    try {
      const response = await axios.post(url);

      setMeritConfig(response.data);
    } catch (err: any) {
      console.log(err.message);
    }

    setLoading(false);
  };

  const current = useCallback(() => {
    queryServer('/auth/merit/current');
     
  }, []);

  const refresh = () => {
    queryServer('/auth/merit/refresh');
  };

  const unlink = () => {
    queryServer('/auth/merit/unlink');
  };

  useEffect(() => {
    current();
  }, [current]);

  if (currentUser?.type !== EUserTypes.student) {
    return null;
  }

  const Merit = (m: { data: any }) => {
    const { data } = m;
    return (
      <div className="pb-4">
        <h4 className="h4 mb-0">
          <i className="fe fe-award mr-2 text-blue" />
          {data.template_title}{' '}
          {data.merits_uspa_member_id && (
            <Text.Small color="secondary">
              ({data.merits_uspa_member_id})
            </Text.Small>
          )}
        </h4>
        {data.merits_uspa_member_expiration && (
          <Text.Small color="secondary" className="ml-6">
            Expires:{' '}
            <Moment
              format="MMMM DD, YYYY"
              date={data.merits_uspa_member_expiration}
            />
          </Text.Small>
        )}
      </div>
    );
  };

  return (
    <Card>
      <Card.Status className="bg-primary" />
      <Card.Header>
        <Card.Title>
          <Icon name="award" className="mr-2 ml-0 text-success" />
          USPA Credentials
        </Card.Title>
        <Card.Options>
          <Button.List align="right">
            {meritConfig?.linked && (
              <Button
                className="mb-3 text-success"
                color="white"
                icon="refresh-cw"
                disabled={loading}
                onClick={refresh}
                align="right"
              >
                REFRESH
              </Button>
            )}
            {!meritConfig?.linked && (
              <Button
                className="mb-3 text-muted"
                color="white"
                icon="plus"
              //  disabled={loading}
                onClick={refresh}
                align="right"
                disabled
              >
                ADD MANUALLY
              </Button>
            )}
          </Button.List>
        </Card.Options>
      </Card.Header>
      <Card.Body>
        <Grid.Row sm={12} md={12} className="pl-3">
          {meritConfig?.linked && (
            <div>
              {merits.length === 0 && (
                <Text.Small className="w-100 ml-3">
                  Linked, but no merits found
                </Text.Small>
              )}

              {merits.map((m: any) => (
                <Merit key={m.id} data={m} />
              ))}
            </div>
          )}
        </Grid.Row>
        <Grid.Row>
          {!meritConfig?.linked && (
            <>
              <Text className="w-100 ml-3">
                You are not linked to <a href='https://www.merits.com/resources/case-studies/uspa'
                                         target='_blank'
                                         rel='noopener noreferrer'>Merit</a>.
              </Text>
              <Button className="d-block p-0 m-3">
                <a
                  className="btn btn-primary text-white"
                  target="_blank"
                  href={meritConfig?.url}
                  rel="noopener noreferrer"
                >
                  Sync with Merit
                </a>
              </Button>
            </>
          )}
        </Grid.Row>
        {!meritConfig?.linked && (
        <Grid.Row>
          <Text.Small className='ml-3'>
            <i>Merit is used to provide digital credentials to USPA members.
            <a href='http://beta.uspa.org/sigma'
               target='_blank'
               rel='noopener noreferrer'
            > Learn More</a></i>
          </Text.Small>
          <Text.Small className='ml-3'>
            <i>If you do not have a Merit account, you can create one or add your credentials manually.</i>
          </Text.Small>
        </Grid.Row>
        )}
      </Card.Body>
      {meritConfig?.linked && location.pathname === '/user/settings' && (
      <Card.Footer className="pr-2">
        <Button.List align="right">
          <Button
            pill
            className="text-danger"
            icon="x-circle"
            disabled={loading}
            onClick={unlink}
            color="white"
            size="sm"
          >
            UNLINK MERIT
          </Button>
        </Button.List>
      </Card.Footer>
      )}
    </Card>
  );
};

export default MeritCard;
