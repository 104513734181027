import { useQuery } from '@apollo/client';
import { DEFAULT_LIMIT } from 'components/constants';
import { GET_INVOICE } from 'graphql/GET_INVOICE';
import { GET_INVOICE as GET_INVOICE_TYPE } from 'graphql/types/GET_INVOICE';
import { QueryParams } from 'modules/common/constants/serviceParams';
import { useEffect, useState } from 'react';

const useGetInvoice = (invoiceId: number) => {
  const [invoiceData, setInvoiceData] = useState<GET_INVOICE_TYPE>();

  const [queryFilter, setQueryFilter] = useState<QueryParams>({
    limit: DEFAULT_LIMIT
  });

  const { loading, error, data, refetch } = useQuery<GET_INVOICE_TYPE>(
    GET_INVOICE,
    {
      variables: {
        id: invoiceId
      }
    }
  );

  useEffect(() => {
    if (!loading) {
      setInvoiceData(data);
    }
  }, [data, loading]);

  useEffect(() => {
    void refetch();
  }, [queryFilter, refetch]);

  return {
    data: invoiceData,
    loading,
    error,
    queryFilter,
    setQueryFilter
  };
};

export default useGetInvoice;
