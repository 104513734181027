import React from 'react';
import { Form, Grid } from 'tabler-react';
import { formatMoney } from '../../../utils/numberFormat';

interface IStudentRegistrationOptionsFormProps {
  registrationOptions: any,
  loading: boolean,
  handleOptionsToggle: any
}

const StudentRegistrationOptionsForm = (
  { registrationOptions, loading, handleOptionsToggle }:
    IStudentRegistrationOptionsFormProps
) => {

  return (<Form.Group label='Options:'>
    {!loading && registrationOptions.map(option => {
      return (
        <Grid.Row key={option.id}>
          <Grid.Col>
            <Form.Switch
              name='status'
              label={<><p className='mb-0'>
                <strong>{option.name}</strong>
              </p>
                <p>
                  {formatMoney(Number(option.option_value))}{' '}
                  {option.fee_type_name}
                </p></>}
              value={option.id}
              onChange={(e) => handleOptionsToggle(e.target)}
            />
          </Grid.Col>
        </Grid.Row>
      )
        ;
    })}
  </Form.Group>);
};

export default StudentRegistrationOptionsForm;
