import gql from 'graphql-tag';

export const GET_REGISTRATION_QUESTION_ANSWER = gql`
  query GET_REGISTRATION_QUESTION_ANSWER(
    $studentId: Int!
    $registrationQuestionId: Int!
  ) {
    getPreviousRegistrationResponse(
      studentId: $studentId
      registrationQuestionId: $registrationQuestionId
    ) {
      user_answer
    }
  }
`;
