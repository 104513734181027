import gql from 'graphql-tag';

export const GET_USER_CONTACTS_BY_STUDENT_ID = gql`
  query GET_USER_CONTACTS_BY_STUDENT_ID($student_id: ID!) {
    getUserContactsByStudentId(student_id: $student_id) {
      id
      name
      phone
      student_id
    }
  }
`;
