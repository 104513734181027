export const NUMBER_ONE = 1;
export const NUMBER_TWO = 2;
export const NUMBER_THREE = 3;
export const NUMBER_FOUR = 4;
export const NUMBER_FIVE = 5;
export const NUMBER_SIX = 6;
export const NUMBER_SEVEN = 7;
export const NUMBER_EIGHT = 8;
export const NUMBER_NINE = 9;
export const NUMBER_TEN = 10;
export const NUMBER_ELEVEN = 11;
export const NUMBER_TWELVE = 12;
