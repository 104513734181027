import gql from 'graphql-tag'

export const GET_INVOICE_PAYMENTS = gql`
  query GET_INVOICE_PAYMENTS($invoice_id: Int!) {
    getInvoicePayments(invoice_id: $invoice_id) {
      id
      processed_by
      amount
      total
      fees
      card_brand
      created_on
      manual_payment
      status
    }
  }
`
