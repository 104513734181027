import RigCardItem from './RigCardItem';
import React, { useState } from 'react';
import { Grid } from 'tabler-react';
import { observer } from 'mobx-react';
import RigModal from './RigModal';
import { Types } from '../../types/graphql'

interface IRigCardProps {
  rigs: Types.Rig[];
}

const RigCard = ({ rigs }: IRigCardProps) => {
// UserRig states
  const [rig, setRig] = useState<Types.Rig>(null);
  const [isRigModalOpen, setIsRigModalOpen] = useState<boolean>(false);

  const handleRigToggleModal = (isOpen) => {
    setIsRigModalOpen(isOpen);
  };
  return (
    <>
      <RigModal
        rig={rig}
        toggleModal={handleRigToggleModal}
        isModalOpen={isRigModalOpen}
      />
      <Grid.Row>
        {rigs && rigs.map((userRig) =>
          <Grid.Col xl={4} lg={6} md={6} sm={12} xs={12} key={userRig.id}>
            <RigCardItem
              rig={userRig}
              toggleModal={handleRigToggleModal}
              setRig={setRig}
            />
          </Grid.Col>
        )}
      </Grid.Row>
    </>);
};

export default observer(RigCard);
