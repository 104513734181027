import { EUserTypes } from 'components/User/userTypes';

const useIsFeatureAccessible = (params: {
  feature: string;
  currentCoachTeam: any;
  currentUserType?: keyof typeof EUserTypes;
}) => {
  const { currentCoachTeam, currentUserType, feature } = params;

  const isFeatureAccessible = currentCoachTeam?.[feature]
    ? currentCoachTeam[feature]
    : false;

  return {
    isFeatureAccessible:
      currentUserType === EUserTypes.admin ? true : isFeatureAccessible
  };
};

export default useIsFeatureAccessible;
