import { useQuery } from '@apollo/client';
import { UserStoreContext } from 'contexts/userStoreContext';
import { GET_USER_ROLES } from 'graphql/GET_USER_ROLES';
import { GET_USER_ROLES as GET_USER_ROLES_TYPE } from 'graphql/types/GET_USER_ROLES';
import { useRootStore } from 'hooks';
import UserRoleModal from 'modules/user/roles/components/modals/UserRoleModal';
import React, { FC, useContext, useMemo, useState } from 'react';
import { Tag } from 'tabler-react';
import useGetCurrentUserType from '../../../../../modules/common/hooks/useGetCurrentUserType';
import { formatMoney } from '../../../../../utils/numberFormat';

const UserRoleList: FC = () => {
  const { user } = useContext(UserStoreContext);
  const { currentCoachTeam } = useRootStore();
  const { isCoachAdmin, isCoachSuperAdmin, isDzm, isWtm } = useGetCurrentUserType();

  const [isModalOpen, setIsModalOpen] = useState(false);

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  const { data } = useQuery<GET_USER_ROLES_TYPE>(GET_USER_ROLES, {
    variables: {
      params: {
        studentId: Number(user?.id),
        teamId: currentCoachTeam?.id
      }
    }
  });

  const existingRolesRow = useMemo(
    () =>
      data?.getUserRolesByTeamId.map(({ id, name, compensation }) => ({
        id,
        name,
        compensation
      })) ?? [],
    [data?.getUserRolesByTeamId]  
  );

  return (
    <Tag.List className="float-right">
      {(isCoachAdmin || isCoachSuperAdmin) && (
        <Tag className="card-body-click" onClick={toggleModal}>
          +
        </Tag>
      )}
      {existingRolesRow.map((role) => (
        <Tag key={role.id}>
          {role.name}{' '}
          <span className='ml-2 text-success'>{role.compensation && (isDzm || isWtm) &&  `${formatMoney(Number(role.compensation))}`}</span>
        </Tag>
      ))}

      <UserRoleModal
        action="addStudentRole"
        isModalOpen={isModalOpen}
        name={`${user?.first_name} ${user?.last_name}`}
        toggleModal={toggleModal}
      />
    </Tag.List>
  );
};

export default UserRoleList;
