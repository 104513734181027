import React from 'react';
import { Form } from 'tabler-react';
import { toast } from 'react-toastify';
import { UPDATE_GEAR_MANUFACTURER } from '../../../graphql/UPDATE_GEAR_MANUFACTURER';
import { useMutation } from '@apollo/client';
import { Types } from '../../../types/graphql'

interface ManufacturerListItemEditNameProps {
  manufacturer: Types.GearManufacturer;
  setEditing: (editing: boolean) => void;
}

const ManufacturerListItemEditName = ({
  manufacturer,
  setEditing
}: ManufacturerListItemEditNameProps) => {
  const [name, setName] = React.useState<string>(manufacturer.name);
  const [updateGearManufacturer] = useMutation(UPDATE_GEAR_MANUFACTURER, {
    onCompleted: (response) => {
      if (response.updateGearManufacturer) {
        toast.success('Manufacturer updated successfully');
        setEditing(false);
      }
    }
  });
  const handleSubmit = async (name: string) => {
    await updateGearManufacturer({
      variables: {
        manufacturer: {
          id: manufacturer.id,
          name
        }
      },
      refetchQueries: ['GET_ALL_GEAR_MANUFACTURERS']
    });
  };
  return (
    <Form.InputGroup>
      <Form.Input
        value={name}
        onChange={(e) => setName(e.target.value)}
        onBlur={(e) => handleSubmit(e.target.value)}
        onKeyPress={(e) => {
          if (e.key === 'Enter') handleSubmit(e.target.value);
        }}
      />
    </Form.InputGroup>
  );
};

export default ManufacturerListItemEditName;
