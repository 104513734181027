import gql from 'graphql-tag';

export const EDIT_REGISTRATION_STATUS_CONTENT = gql`
  mutation EDIT_REGISTRATION_STATUS_CONTENT(
    $registrationStatusContentUpdateInput: RegistrationStatusContentUpdateInput!
  ) {
    editRegistrationStatusContent(
      registrationStatusContentUpdateInput: $registrationStatusContentUpdateInput
    )
  }
`;
