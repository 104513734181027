import { useMutation } from "@apollo/client";
import React from "react";
import { toast } from "react-toastify";
import { Button, Grid, Text } from "tabler-react";
import Modal from "../../../../components/Modal";
import { DELETE_NOTIFICATION_EMAIL } from "../../../../graphql/DELETE_NOTIFICATION_EMAIL";
import { Types } from "../../../../types/graphql";
import { EditNotificationEmailForm } from "../forms/EditNotificationEmailForm";

type NotificationEmailListItemProps = {
  notificationEmail: Types.NotificationEmail;
};
const NotificationEmailListItem = ({
  notificationEmail,
}: NotificationEmailListItemProps) => {
  const [showEditForm, setShowEditForm] = React.useState(false);
  const [showDeleteModal, setShowDeleteModal] = React.useState(false);

  const toggleEditForm = () => {
    setShowEditForm(!showEditForm);
  };

  const toggleDeleteModal = () => {
    setShowDeleteModal(!showDeleteModal);
  };
  return (
    <Grid.Row key={notificationEmail.id}>
      <Grid.Col>
        {!showEditForm ? (
          <Text>
            <Button icon="mail" size="sm" color="white" disabled />
            <span className="cursor-pointer" onClick={toggleEditForm}>{notificationEmail.email}</span>
            <Button
              className="text-muted float-right"
              icon="x-circle"
              size="sm"
              color="white"
              onClick={toggleDeleteModal}
            />
          </Text>
        ) : (
          <EditNotificationEmailForm
            notificationEmail={notificationEmail}
            toggleEditForm={toggleEditForm}
          />
        )}
      </Grid.Col>
      <NotificationEmailListItemDeleteModal
        notificationEmail={notificationEmail}
        toggleDeleteModal={toggleDeleteModal}
        isOpen={showDeleteModal}
      />
    </Grid.Row>
  );
};

const NotificationEmailListItemDeleteModal = ({
  notificationEmail,
  toggleDeleteModal,
  isOpen,
}) => {
  const [deleteNotificationEmail] = useMutation(DELETE_NOTIFICATION_EMAIL, {
    refetchQueries: ["GET_NOTIFICATION_EMAILS"],
    onCompleted: () => {
      toast.success("Email deleted");
    },
  });
  return (
    <Modal
      content={
        <Text>
          Are you sure you want to remove {notificationEmail.email} from receiving notification emails?
        </Text>
      }
      title="Delete Email"
      actions={
        <>
          <Button color="secondary" onClick={toggleDeleteModal}>
            Cancel
          </Button>
          <Button
            color="danger"
            onClick={() => {
              deleteNotificationEmail({
                variables: { id: notificationEmail.id },
              });
              toggleDeleteModal();
            }}
          >
            Delete
          </Button>
        </>
      }
      open={isOpen}
      onClose={toggleDeleteModal}
    />
  );
};

export default NotificationEmailListItem;
