import { useQuery } from "@apollo/client";
import { Box } from "@material-ui/core";
import { DEFAULT_LIMIT } from "components/constants";
import { Loading } from "components/Loading";
import { GET_INVOICE_LIST } from "graphql/GET_INVOICE_LIST";
import { GET_JOB_LIST } from "graphql/GET_JOB_LIST";
import { JOB_STATUS_COUNT } from "graphql/JOB_STATUS_COUNT";
import { GET_INVOICE_LIST as GET_INVOICE_LIST_TYPE } from "graphql/types/GET_INVOICE_LIST";
import { JOB_STATUS_COUNT as JOB_STATUS_COUNT_TYPE } from "graphql/types/JOB_STATUS_COUNT";
import { useRootStore } from "hooks";
import { observer } from "mobx-react";
import { QueryParams } from "modules/common/constants/serviceParams";
import useGetCurrentUserType from "modules/common/hooks/useGetCurrentUserType";
import useDebounceCallback from "modules/common/utils/useDebounceCallback";
import {
  adminStatusList,
  baseStatusList,
} from "modules/job/constants/statusList";
import React, { useMemo, useState } from "react";
import { Alert, Button, Card, Form, Grid, Icon, Text } from "tabler-react";
import { Types } from "../../types/graphql";
import GearJobModalForm from "../Gear/Modal/GearJobModalForm";
import JobsInvoiceList from "../Invoices/JobsInvoiceList";
import JobListItem from "./JobListItem";

export const jobsStatusList = {
  Active: { adminOnly: false, label: "Active" },
  Open: { adminOnly: false, label: "Open" },
  Progress: { adminOnly: false, label: "Progress" },
  Invoice: { adminOnly: true, label: "Invoice" },
  Complete: { adminOnly: false, label: "Complete" },
  Hold: { adminOnly: false, label: "Hold" },
  Parts: { adminOnly: false, label: "Waiting for parts" },
  Selling: { adminOnly: false, label: "Selling" },
};

const CURRENT_STATUS_GROUPS = [
  "Active",
  "Open",
  "Hold",
  "Progress",
  "Complete",
];

const closedJobsStatus = ["Delete", "Closed"];

//group by student
const groupByStudent = (data: Types.Job[]) => {
  const student_ids = [];
  return data.reduce(function (storage, item) {
    const group = item?.student_id;
    if (!student_ids.includes(group)) {
      student_ids.push(group);
    }
    const key = student_ids.indexOf(group);
    storage[key] = storage[key] || [];
    storage[key].push(item);
    return storage;
  }, {});
};

const JobList = () => {
  const rootStore = useRootStore();
  const { currentUser, currentCoachTeam } = rootStore;
  const { isAdmin, isCoach, isRigger } = useGetCurrentUserType();
  const [page, setPage] = useState(1);
  const [searchValue, setSearchValue] = useState("");
  const [queryFilter, setQueryFilter] = useState<QueryParams>({
    q: undefined,
    limit: 0,
  });

  const {
    error: error1,
    loading: loading1,
    data: invoiceData,
    refetch: refetchInvoices,
  } = useQuery<GET_INVOICE_LIST_TYPE>(GET_INVOICE_LIST, {
    variables: {
      invoiceListFilter: {
        limit: 10,
        team_id: currentCoachTeam?.id,
      },
    },
  });

  const {
    error: error2,
    loading: loading2,
    data: jobData,
    fetchMore: fetchMoreJobs,
    refetch: refetchJobs,
  } = useQuery(GET_JOB_LIST, {
    variables: {
      jobListFilter: {
        status: status ? status : isAdmin ? adminStatusList : baseStatusList,
        team_id: isAdmin ? undefined : currentCoachTeam?.id,
        ...queryFilter,
        //limit: DEFAULT_LIMIT,
        offset: 0,
        order: "asc",
      },
    },
  });

  const { data: statusData, loading: statusLoading } =
    useQuery<JOB_STATUS_COUNT_TYPE>(JOB_STATUS_COUNT, {
      variables: {
        filter: {
          team_id: isAdmin ? undefined : currentCoachTeam?.id,
          q: searchValue === "" ? undefined : searchValue,
        },
      },
    });

  const jobs = useMemo(() => {
    if (!jobData?.jobsList) {
      return [];
    }

    const filteredJobs = jobData.jobsList
      .filter((job) => {
        if (!isAdmin) {
          return baseStatusList.includes(job.status);
        }
        return true;
      })
      .sort((a, b) => {
        return new Date(a.due_on).getTime() - new Date(b.due_on).getTime();
      })
      .sort((a, b) => {
        /*
         * Sort by priority
         * RUSH jobs first
         */
        if (a.priority === "RUSH" && b.priority !== "RUSH") return -1;
        if (a.priority !== "RUSH" && b.priority === "RUSH") return 1;
        if (a.priority === "RUSH" && b.priority === "RUSH") return 0;
      });

    return Object.values(groupByStudent(filteredJobs));
  }, [jobData?.jobsList]);

  const infiniteScrollData = useMemo(() => {
    if (!jobData?.jobsList || !jobData?.jobsList[0]) {
      return { length: 0, total: 0 };
    }

    return {
      length: jobData?.jobsList.length,
      total: jobData?.jobsList[0].total_count,
    };
  }, [jobData?.jobsList]);

  const currentStatusCount = useMemo(() => {
    if (!statusData?.jobStatusCount) {
      return 0;
    }

    // Accumulate status count for Active, Open, Hold, Progress, Complete, Parts, Selling
    return statusData.jobStatusCount.statusCounts.reduce((acc, item) => {
      if (CURRENT_STATUS_GROUPS.includes(item.status)) {
        return acc + item.count;
      }

      return acc;
    }, 0);
  }, [statusData?.jobStatusCount, statusLoading]);

  const partsStatusCount = useMemo(() => {
    if (!statusData?.jobStatusCount) {
      return 0;
    }

    return (
      statusData.jobStatusCount.statusCounts.find(
        (item) => item.status === "Parts",
      )?.count ?? 0
    );
  }, [statusData?.jobStatusCount, statusLoading]);

  const sellingStatusCount = useMemo(() => {
    if (!statusData?.jobStatusCount) {
      return 0;
    }

    return (
      statusData.jobStatusCount.statusCounts.find(
        (item) => item.status === "Selling",
      )?.count ?? 0
    );
  }, [statusData?.jobStatusCount, statusLoading]);

  const statusGroups = {
    admin: {
      Active: { statusList: ["Active"], label: "Active" },
      Open: { statusList: ["Open"], label: "Open" },
      Progress: { statusList: ["Progress"], label: "Progress" },
      Invoice: { statusList: ["Invoice"], label: "Invoice" },
      Complete: { statusList: ["Complete"], label: "Complete" },
      Hold: { statusList: ["Hold"], label: "Hold" },
      Parts: { statusList: ["Parts"], label: "Waiting for parts" },
      Selling: { statusList: ["Selling"], label: "Selling" },
      Delete: { statusList: ["Delete"], label: "Delete" },
      Closed: { statusList: ["Closed"], label: "Closed" },
    },
    rigger: {
      Current: {
        statusList: CURRENT_STATUS_GROUPS,
        label: "Current",
        count: currentStatusCount,
      },
      Parts: {
        statusList: ["Parts"],
        label: "Waiting for parts",
        count: partsStatusCount,
      },
      Selling: {
        statusList: ["Selling"],
        label: "Selling",
        count: sellingStatusCount,
      },
    },
  };

  const currentStatusGroups = statusGroups[currentUser.type.toLowerCase()];

  if (loading1 || loading2 || statusLoading || !jobData || !invoiceData) {
    return <Loading />;
  }

  if (error1) {
    console.log(error1);
    return <p>Error: {error1.message}</p>;
  }

  if (error2) {
    console.log(error2);
    return <p>Error: {error2.message}</p>;
  }

  const invoices = invoiceData.invoiceList.invoices;

  const onSubmit = () => {
    refetchJobs();
    refetchInvoices();
  };

  const onFetchMore = () => {
    setPage((prevNum) => prevNum + 1);
    return fetchMoreJobs({
      updateQuery: (cache, { fetchMoreResult }) => {
        if (!fetchMoreResult) {
          return cache;
        }

        return {
          ...cache,
          jobsList: [
            ...(cache?.jobsList ?? []),
            ...(fetchMoreResult?.jobsList ?? []),
          ],
        };
      },
      variables: {
        jobListFilter: {
          status: status ? status : isAdmin ? adminStatusList : baseStatusList,
          team_id: isAdmin ? undefined : currentCoachTeam?.id,
          ...queryFilter,
          limit: DEFAULT_LIMIT,
          offset: page * DEFAULT_LIMIT,
          order: "asc",
        },
      },
    });
  };

  const props = {
    invoices,
    jobs,
    page,
    setPage,
    queryFilter,
    setQueryFilter,
    onSubmit,
    searchValue,
    setSearchValue,
    currentStatusGroups,
    infiniteScrollData,
    onFetchMore,
  };
  return <JobListInner {...props} />;
};

const JobListInner = ({
  invoices,
  jobs,
  page,
  setPage,
  queryFilter,
  setQueryFilter,
  onSubmit,
  searchValue,
  setSearchValue,
  currentStatusGroups,
  infiniteScrollData,
  onFetchMore,
}) => {
  const [status, setStatus] = useState("");
  const { isAdmin, isCoach, isRigger } = useGetCurrentUserType();
  const [innerSearchValue, setInnerSearchValue] = useState(searchValue);
  const [queryClosedJobs, setQueryClosedJobs] = useState([]);

  const [isOpen, setIsOpen] = useState(false);
  const [job, setJob] = useState(null);

  const onChange = () => {
    setPage(() => 0);
    setSearchValue(innerSearchValue);
    setQueryFilter({
      ...queryFilter,
      q: innerSearchValue === "" ? undefined : innerSearchValue,
      offset: page,
    });
  };

  const debouncedOnChange = useDebounceCallback(onChange);

  return (
    <Box>
      <Card>
        <Card.Header>
          <Card.Title>
            <Icon name="briefcase" className="mr-2 ml-0 text-blue" />
            Service Dashboard
          </Card.Title>
          <Card.Options>
            {isAdmin && (
              <Form.Switch
                label="Inactive Jobs"
                checked={queryClosedJobs.length > 0}
                onChange={(e) => {
                  setQueryClosedJobs(
                    queryClosedJobs.length > 0 ? [] : closedJobsStatus,
                  );
                }}
                className={"float-right"}
              />
            )}
            {!isAdmin && (
              <Button
                className="mr-3"
                color="primary"
                icon="plus"
                size="sm"
                onClick={() => {
                  setJob(null);
                  setIsOpen(true);
                }}
              >
                NEW
              </Button>
            )}
          </Card.Options>
        </Card.Header>
      </Card>

      <Grid.Row>
        <Grid.Col xs={12} sm={12} lg={3}>
          <Form.Group className="mb-5">
            <Form.Input
              autoFocus
              type="search"
              placeholder="Search by customer..."
              name="search"
              value={innerSearchValue}
              onChange={(e) => {
                // debouncedOnChange()
                setInnerSearchValue(e.target.value);
              }}
              onKeyPress={(e) => {
                if (e.key === "Enter") {
                  debouncedOnChange();
                }
              }}
            />
          </Form.Group>

          <Box display="flex" gridGap={8} alignItems="center" marginBottom={2}>
            <h5 className="pl-2 mb-0">Job Status</h5>
            {status && (
              <Text
                size="sm"
                onClick={() => setStatus("")}
                color="secondary"
                className="cursor-pointer"
              >
                Reset
              </Text>
            )}
          </Box>

          <Form.Group className="pl-2 pr-2">
            {Object.keys(currentStatusGroups).map((group) => {
              let checked = false;
              let statusValue = status;
              const statusName = Array.isArray(status) ? "Current" : group;

              if (Array.isArray(status) || status === "") {
                statusValue = "Current";
              }

              if (statusValue === group) {
                checked = true;
              }

              return (
                <div className="d-flex justify-content-between" key={group}>
                  <Form.Radio
                    className="cursor-pointer"
                    label={currentStatusGroups[group].label}
                    name="status"
                    value={statusName}
                    checked={checked}
                    onChange={() => {
                      setStatus(statusName);
                    }}
                  />

                  <Text className="text-muted">
                    {currentStatusGroups[group]?.count ?? 0}
                  </Text>
                </div>
              );
            })}
          </Form.Group>
          {(isCoach || isRigger) && <JobsInvoiceList invoices={invoices} />}
        </Grid.Col>
        <Grid.Col xs={12} sm={12} lg={9}>
          <Grid.Row>
            <Grid.Col width={12}>
              {jobs.length <= 0 ? (
                <Alert type="info text-center">
                  <strong>No jobs found!</strong>
                </Alert>
              ) : (
                jobs?.map((groupedJobs, index) => {
                  const filterByStatus = groupedJobs.filter((job) => {
                    if (status === "" || status === "Current") {
                      return job.status !== "Selling";
                    }

                    return status && job.status === status;
                  }) as Types.Job[];

                  if (filterByStatus.length > 0) {
                    return (
                      <JobListItem
                        jobList={filterByStatus}
                        key={"job" + index}
                        toggleModal={setIsOpen}
                        setJob={setJob}
                        onSubmit={onSubmit}
                      />
                    );
                  }

                  return "";
                })
              )}
              {isOpen && (
                <GearJobModalForm
                  isModalOpen={isOpen}
                  toggleModal={setIsOpen}
                  userGearJob={job}
                  autoClose={true}
                />
              )}
            </Grid.Col>
          </Grid.Row>
        </Grid.Col>
      </Grid.Row>
    </Box>
  );
};

export default observer(JobList);
