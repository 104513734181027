import React from 'react';

import Wrapper from 'pages/layout';
import { useRootStore } from '../../hooks';
import AircraftList from '../../components/Aircraft/AircraftList';

const AircraftListPage = (props) => {
  const { currentCoachTeam } = useRootStore();
  const teamId = currentCoachTeam?.id;
  return (
    <Wrapper {...props} title="Manage Aircraft">
      <div className="container">
        <AircraftList teamId={teamId} />
      </div>
    </Wrapper>
  );
};

export default AircraftListPage;
