import gql from 'graphql-tag';

export const JOB_STATUS_COUNT = gql`
  query JOB_STATUS_COUNT($filter: JobStatusCountFilter) {
    jobStatusCount(filter: $filter) {
      statusCounts {
        status
        count
      }
    }
  }
`;
