import { useMutation } from "@apollo/client";
import { teamAccessOpts } from "components/Team/Form/teamContants";
import { Formik } from "formik";
import { UPDATE_TEAM_BY_ID } from "graphql/UPDATE_TEAM_BY_ID";
import { observer } from "mobx-react";
import { applySnapshot, getSnapshot } from "mobx-state-tree";
import { toast } from "react-toastify";
import { Button, Card, Form } from "tabler-react";
import * as Yup from "yup";

const featureValidation = Yup.object().shape({
  feature_accounting: Yup.boolean().oneOf([false, true]),
  feature_alpha: Yup.boolean().oneOf([false, true]),
  feature_app_reports: Yup.boolean().oneOf([false, true]),
  feature_booking_calendar: Yup.boolean().oneOf([false, true]),
  feature_camps: Yup.boolean().oneOf([false, true]),
  feature_crm: Yup.boolean().oneOf([false, true]),
  feature_invoices: Yup.boolean().oneOf([false, true]),
  feature_manifest: Yup.boolean().oneOf([false, true]),
  feature_open_add_students: Yup.boolean().oneOf([false, true]),
  feature_registrations: Yup.boolean().oneOf([false, true]),
  feature_slot_registrations: Yup.boolean().oneOf([false, true]),
  feature_waivers: Yup.boolean().oneOf([false, true]),
  feature_student_checkin: Yup.boolean().oneOf([false, true]),
});

const TeamAccessFeature = ({ team, teamClone }) => {
  const [updateTeamById] = useMutation(UPDATE_TEAM_BY_ID);

  const mstHandleChange = (e) => {
    const target = e.target;

    teamClone.set(
      target.name,
      target.type === "checkbox" ? target.checked : target.value,
    );
  };

  return (
    <Formik
      enableReinitialize={true}
      initialValues={{
        id: parseInt(teamClone.id),
        feature_accounting: teamClone.feature_accounting,
        feature_alpha: teamClone.feature_alpha,
        feature_app_reports: teamClone.feature_app_reports,
        feature_booking_calendar: teamClone.feature_booking_calendar,
        feature_camps: teamClone.feature_camps,
        feature_crm: teamClone.feature_crm,
        feature_invoices: teamClone.feature_invoices,
        feature_manifest: teamClone.feature_manifest,
        feature_open_add_students: teamClone.feature_open_add_students,
        feature_registrations: teamClone.feature_registrations,
        feature_slot_registrations: teamClone.feature_slot_registrations,
        feature_waivers: teamClone.feature_waivers,
      }}
      validationSchema={featureValidation}
      onSubmit={async (values, { setSubmitting }) => {
        await updateTeamById({
          variables: {
            team: {
              ...values,
            },
          },
          update(_, { data: { updateTeamById } }) {
            if (updateTeamById) {
              applySnapshot(team, getSnapshot(teamClone));
              toast.success("Team Updated.");
            }
          },
        });

        setSubmitting(false);
      }}
    >
      {({ values, isSubmitting, handleSubmit }) => (
        <form onSubmit={handleSubmit}>
          <Form.Group>
            {teamAccessOpts.map((feature: { label: string; value: string }) => (
              <Form.Checkbox
                checked={values[feature.value]}
                key={feature.value}
                label={feature.label}
                onChange={(e) => mstHandleChange(e)}
                name={feature.value}
              />
            ))}
          </Form.Group>
          <Card.Footer className="px-0">
            <Button.List align="right">
              <Button
                size="sm"
                color="primary"
                disabled={isSubmitting}
                loading={isSubmitting}
                type="submit"
              >
                Update
              </Button>
            </Button.List>
          </Card.Footer>
        </form>
      )}
    </Formik>
  );
};

export default observer(TeamAccessFeature);
