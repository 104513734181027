import gql from 'graphql-tag';

export const UPDATE_USER_CONTACT = gql`
  mutation UPDATE_USER_CONTACT($userContact: UserContactInput!) {
    updateUserContact(userContact: $userContact) {
      id
      name
      phone
      student_id
    }
  }
`;
