import { useMutation, useQuery } from "@apollo/client";
import { Formik } from "formik";
import React, { useMemo } from "react";
import Select from "react-select";
import { toast } from "react-toastify";
import { Button, Form, Grid } from "tabler-react";
import { EDIT_LOAD_USER } from "../../../graphql/EDIT_LOAD_USER";
import { GET_ALL_JUMP_TYPES } from "../../../graphql/GET_ALL_JUMP_TYPES";
import { LoadAssocType } from "../../constants";
import Modal from "../../Modal";

interface EditLoadPassengerModalFormProps {
  passenger: any;
  isOpen: boolean;
  toggleModal: () => void;
  load: any;
}

const EditLoadPassengerModalForm = ({
  toggleModal,
  passenger,
  isOpen,
  load,
}: EditLoadPassengerModalFormProps) => {
  const [updateLoadAssociatedUser] = useMutation(EDIT_LOAD_USER, {
    onCompleted: () => {
      toast.success("Passenger updated");
    },
    refetchQueries: ["GET_LOAD"],
  });

  const { data: jumpTypeData, loading: jumpTypeLoading } =
    useQuery(GET_ALL_JUMP_TYPES);

  const jumpTypes = useMemo(() => {
    if (jumpTypeData && !jumpTypeLoading) {
      return jumpTypeData.loadJumpTypes.map((jumpType) => ({
        label: jumpType.name,
        value: jumpType.id,
      }));
    }
    return [];
  }, [jumpTypeData, jumpTypeLoading]);

  return (
    <Modal
      open={isOpen}
      title={`${load.load_name}: ${passenger.studentDetails.first_name} ${passenger.studentDetails.last_name} `}
      onClose={toggleModal}
      content={
        <Formik
          enableReinitialize={true}
          initialValues={{
            id: passenger.id,
            jump_type_id: passenger.jump_type_id,
            association_type_id: Number(passenger.association_type_id),
            load_id: load.id,
            from_sequence: passenger.sequence,
          }}
          onSubmit={(values, { setSubmitting, resetForm }) => {
            updateLoadAssociatedUser({
              variables: {
                loadAssociatedUser: values,
              },
            });

            setSubmitting(false);
            resetForm();
            toggleModal();
          }}
        >
          {({ values, handleSubmit, setFieldValue, errors, touched }) => (
            <form onSubmit={handleSubmit}>
              <Grid.Row>
                <Grid.Col width="6">
                  <Form.Group label={"Role"}>
                    <Select
                      name={"association_type_id"}
                      placeholder="Select role..."
                      options={LoadAssocType}
                      styles={{
                        menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                      }}
                      menuPortalTarget={document.body}
                      value={LoadAssocType.find(
                        (lat) => lat.value === values.association_type_id,
                      )}
                      onChange={(option) => {
                        setFieldValue("association_type_id", option.value);
                      }}
                    />
                    <span className="field-error text-danger">
                      {errors.association_type_id &&
                        touched.association_type_id &&
                        errors.association_type_id}
                    </span>
                  </Form.Group>
                </Grid.Col>
                <Grid.Col width="6">
                  <Form.Group label={"Jump Type"}>
                    <Select
                      name={"jump_type_id"}
                      placeholder="Select jump type (optional)"
                      styles={{
                        menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                      }}
                      menuPortalTarget={document.body}
                      options={jumpTypes}
                      value={jumpTypes.find(
                        (jt) => jt.value === values.jump_type_id,
                      )}
                      onChange={(option) => {
                        setFieldValue("jump_type_id", option.value);
                      }}
                    />
                    <span className="field-error text-danger">
                      {errors.jump_type_id &&
                        touched.jump_type_id &&
                        errors.jump_type_id}
                    </span>
                  </Form.Group>
                </Grid.Col>
              </Grid.Row>
              <Button
                color="primary"
                pill
                size={"sm"}
                className="float-right"
                type="submit"
              >
                Submit
              </Button>
            </form>
          )}
        </Formik>
      }
    />
  );
};

export default EditLoadPassengerModalForm;
