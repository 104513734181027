import { useMutation, useQuery } from "@apollo/client";
import { Formik } from "formik";
import React, { useEffect } from "react";
import Select from "react-select";
import { toast } from "react-toastify";
import { Button, Card, Form, Grid } from "tabler-react";
import * as Yup from "yup";
import { ADD_STUDENT_TO_GROUP } from "../../../graphql/ADD_STUDENT_TO_GROUP";
import { GET_CAMP_ROLES } from "../../../graphql/GET_CAMP_ROLES";
import CampParticipantSearchField from "../../../modules/camp-groups/components/forms/CampParticipantSearchField";
import { Types } from "../../../types/graphql";
import Modal from "../../Modal";

interface AddStudentToRegistrationGroupModalProps {
  group: Types.Group;
  isOpen: boolean;
  toggle: () => void;
  campId: number;
}

const AddStudentToRegistrationGroupValidationSchema = Yup.object().shape({
  student_id: Yup.number().required("This field is required."),
  role_id: Yup.number().required("This field is required."),
});
const AddStudentToRegistrationGroupModal = ({
  group,
  isOpen,
  toggle,
  campId,
}: AddStudentToRegistrationGroupModalProps) => {
  const [addStudentToGroup] = useMutation(ADD_STUDENT_TO_GROUP, {
    onCompleted: () => {
      toast.success("User added to group");
      toggle();
    },
    refetchQueries: ["GET_GROUPS"],
  });

  const [campRoles, setCampRoles] = React.useState([]);
  const { data: campRolesData } = useQuery(GET_CAMP_ROLES, {
    variables: { campId },
  });

  useEffect(() => {
    if (campRolesData?.campRoles) {
      setCampRoles(
        campRolesData.campRoles
          .filter((role) => role.isExists)
          .map((role) => ({
            label: role.name,
            value: role.roleId,
          })),
      );
    }
  }, [campRolesData]);

  const renderForm = ({
    values,
    setFieldValue,
    handleSubmit,
    errors,
    touched,
  }) => {
    return (
      <Card>
        <Card.Body>
          <Grid.Row>
            <Grid.Col>
              <Form.Group>
                <CampParticipantSearchField
                  autoFocus={true}
                  onChange={(param) => {
                    if (!param) return;

                    if (group.members.find((student) => student.id === param)) {
                      toast.error(
                        "Participant is already a member of this group",
                      );
                      setFieldValue("student_id", null);
                      setFieldValue("registration_id", null);
                      return;
                    }
                    setFieldValue("student_id", Number(param.value));
                    setFieldValue(
                      "registration_id",
                      Number(param.user_registrations_id),
                    );
                  }}
                  defaultValue={""}
                  campId={campId}
                  status={"Confirmed"}
                />
                <span className="field-error text-danger">
                  {errors.student_id && touched.student_id && errors.student_id}
                </span>
              </Form.Group>
            </Grid.Col>

            <Grid.Col>
              <Form.Group>
                <Select
                  styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
                  menuPortalTarget={document.body}
                  options={campRoles}
                  name="role_id"
                  onChange={(option) => {
                    setFieldValue("role_id", option.value);
                  }}
                  value={campRoles.find((i) => i.value === values.role_id)}
                />
                <span className="field-error text-danger">
                  {errors.role_id && touched.role_id && errors.role_id}
                </span>
              </Form.Group>
            </Grid.Col>
          </Grid.Row>
        </Card.Body>
        <Card.Footer>
          <Button
            pill
            size={"small"}
            color={"gray-dark"}
            className={"float-right"}
            onClick={handleSubmit}
          >
            SUBMIT
          </Button>
          <Button
            pill
            size={"small"}
            color={"white"}
            className={"float-right"}
            onClick={toggle}
          >
            CANCEL
          </Button>
        </Card.Footer>
      </Card>
    );
  };
  return (
    <Modal
      open={isOpen}
      onClose={toggle}
      title={"Add Group Member"}
      content={
        <Formik
          initialValues={{
            group_id: group.id,
            team_id: group.team_id,
            role_id: null,
            student_id: null,
            registration_id: null,
          }}
          validationSchema={AddStudentToRegistrationGroupValidationSchema}
          onSubmit={(values, { setSubmitting, resetForm }) => {
            addStudentToGroup({
              variables: {
                addGroupAssociatedUsersInput: {
                  ...values,
                },
              },
            });

            setSubmitting(false);
            resetForm();
          }}
        >
          {(formData) => renderForm(formData)}
        </Formik>
      }
    />
  );
};

export default AddStudentToRegistrationGroupModal;
