import React, { useMemo, useState } from 'react';
import useGetCurrentUserType from '../../../modules/common/hooks/useGetCurrentUserType';
import { useRootStore } from '../../../hooks';
import { useMutation, useQuery } from '@apollo/client';
import { ADD_STUDENT_ORG_CREDENTIAL } from '../../../graphql/ADD_STUDENT_ORG_CREDENTIAL';
import { toast } from 'react-toastify';
import {
  ABCDLicensesAPFOptions,
  ABCDLicensesCSPAOptions,
  ABCDLicensesOptions
} from '../../constants';
import moment from 'moment/moment';
import { Formik } from 'formik';
import { Button, Form, Grid } from 'tabler-react';
import { GET_TEAM_ORGS } from '../../../graphql/GET_TEAM_ORGS';
import Select from 'react-select';
import { Types } from '../../../types/graphql'

const RegistrationOrgMeritForm = ({
  studentId,
  team_id,
  toggle,
  orgCredentials
}) => {
  const { isStudent, isAdmin } = useGetCurrentUserType();
  const { currentUser } = useRootStore();
  const [selectedOrg, setSelectedOrg] = useState<number>(null);
  const handleOrgChange = (orgId: number) => {
    setSelectedOrg(orgId);
  };

  // const { data: studentSports, loading: studentSportsLoading } =
  //   useQuery<GET_SPORTS_BY_STUDENT_ID_TYPE>(GET_SPORTS_BY_STUDENT_ID, {
  //     variables: {
  //       student_id: studentId
  //     }
  //   });
  //
  // const hasBaseSport = useMemo(() => {
  //   if (!studentSportsLoading && studentSports) {
  //     return !!studentSports?.getSportsByStudentId.find(
  //       (sport) => sport.is_active && sport.name === 'BASE'
  //     );
  //   }
  //   return false;
  // }, [studentSports, studentSportsLoading]);

  const orgFilter = !isAdmin && !isStudent ? team_id : null;
  const { data: orgData, loading: orgLoading } = useQuery(GET_TEAM_ORGS, {
    variables: { teamId: orgFilter }
  });

  const orgs = useMemo(
    () => {
      let orgFiltered;

      if (!orgLoading && orgData) {
        orgFiltered = orgData.teamOrgs.filter(
          (teamOrg: Types.TeamOrg) => {
            return !!orgCredentials.find((orgCredential) => {
              if (Number(orgCredential.org_id) === Number(teamOrg.org_id)) {
                return orgCredential;
              }
              return false
            });
          }
        );

        if (!selectedOrg && orgFiltered.length > 0) {
          handleOrgChange(Number(orgFiltered[0].org_id));
        }
        return orgFiltered ?? [];
      }
      return [];
    },
     
    [orgData, orgLoading, isAdmin, isStudent]
  );

  const customFilter = (option, searchText) => {
    if (
      option.data.name.toLowerCase().includes(searchText.toLowerCase()) ||
      option.data.slug.toLowerCase().includes(searchText.toLowerCase())
    )
      return true;

    return false;
  };

  const [addStudentOrgCredential] = useMutation(ADD_STUDENT_ORG_CREDENTIAL, {
    refetchQueries: ['GET_ORG_CREDENTIALS'],
    onCompleted: () => {
      toast.success('Credential added successfully');
    }
  });

  const licenseList = useMemo(() => {
    let selectedList = ABCDLicensesOptions;
    if (selectedOrg === 2) selectedList = ABCDLicensesCSPAOptions;
    if (selectedOrg === 3) selectedList = ABCDLicensesAPFOptions;

    return selectedList.map((option) => {
      return {
        value: option,
        label: option
      };
    });
  }, [selectedOrg]);
  const renderForm = ({
    handleChange,
    handleSubmit,
    values,
    setFieldValue,
    dirty
  }) => {
    return (
      <>
        <Grid.Row>
          <Grid.Col sm={6} md={6}>
            <Form.Group label="Organization">
              <Select
                styles={{
                  menuPortal: (base) => ({ ...base, zIndex: 9999 })
                }}
                menuPortalTarget={document.body}
                isDisabled={orgs.length === 0}
                name="organization"
                getOptionLabel={(option: Types.TeamOrg) => (
                  <>
                    <span className="mr-1 font-weight-bold">
                      {option.slug.toUpperCase()}
                    </span>
                    {option.name}
                  </>
                )}
                getOptionValue={(option: Types.TeamOrg) =>
                  option.org_id
                }
                filterOption={customFilter}
                options={orgs}
                onChange={(e) => {
                  handleOrgChange(Number(e.org_id));
                }}
                value={
                  orgs.find((org: Types.TeamOrg) => {
                    return Number(org.org_id) === selectedOrg;
                  }) || orgs[0]
                }
              />
            </Form.Group>
          </Grid.Col>
        </Grid.Row>
        <Grid.Row>
          <Grid.Col xs={12} sm={12} md={6} lg={6}>
            <Select
              name="license"
              placeholder="Select license..."
              searchText={false}
              options={licenseList}
              onChange={(e) => {
                setFieldValue('license', e.value);
              }}
              value={
                licenseList.find((i) => i.value === values.license) || null
              }
            />
          </Grid.Col>
          <Grid.Col xs={12} sm={12} md={6} lg={6}>
            <Form.Group label="">
              <Form.InputGroup>
                <Form.Input
                  name="license_number"
                  placeholder=""
                  value={values?.license_number}
                  onChange={handleChange}
                  disabled={values.verified_on && !isAdmin}
                />
              </Form.InputGroup>
            </Form.Group>
          </Grid.Col>
        </Grid.Row>
        <Grid.Row>
          <Grid.Col>
            <Button
              pill
              size="sm"
              color="gray-dark"
              className="ml-2 float-right"
              onClick={handleSubmit}
            >
              SAVE
            </Button>
            <Button
              pill
              size="sm"
              color="white"
              className="ml-2  float-right"
              onClick={toggle}
            >
              CANCEL
            </Button>
          </Grid.Col>
        </Grid.Row>
      </>
    );
  };
  return (
    <Formik
      enableReinitialize={true}
      initialValues={{
        org_id: selectedOrg,
        student_id: studentId,
        verified_on: null,
        verified_by: null,
        license: '',
        license_number: ''
      }}
      onSubmit={(values, { setSubmitting, resetForm }) => {
        if (!isStudent) {
          values.verified_by = currentUser.id.toString();
          values.verified_on = moment().format('YYYY-MM-DD');
        }
        addStudentOrgCredential({
          variables: {
            addOrgCredentialInput: {
              ...values
            }
          }
        });

        resetForm();
        setSubmitting(false);
        toggle();
      }}
    >
      {(formData) => renderForm(formData)}
    </Formik>
  );
};

export default RegistrationOrgMeritForm;
