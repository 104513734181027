import React from 'react';
import ReactS3Uploader from 'react-s3-uploader';
import { RootStoreType } from '../../../models';
import { toast } from 'react-toastify';
import { Icon } from 'tabler-react';

interface GearManufacturerImageUploadProps {
  rootStore: RootStoreType;
  setImage: (image: string) => void;
  manufacturerId: string;
}

const GearManufacturerImageUpload = ({
  rootStore,
  setImage,
  manufacturerId
}: GearManufacturerImageUploadProps) => {
  const onErrorHandler = (error) => {
    toast.error(error);
    rootStore.setLoading(false);
  };

  const onFinishedHandler = async (response: { fileKey: string }) => {
    rootStore.setLoading(false);
    setImage(response.fileKey);
  };
  return (
    <>
      <label
        htmlFor={`s3-upload-${manufacturerId}`}
        className="dropdown-item cursor-pointer mb-0"
      >
        <Icon name={'image'} /> Add Image
      </label>
      <ReactS3Uploader
        accept="image/*"
        autoUpload={true}
        className="d-none"
        contentDisposition="auto"
        id={`s3-upload-${manufacturerId}`}
        signingUrl="/api/s3/sign"
        onError={onErrorHandler}
        onFinish={onFinishedHandler}
        onProgress={() => rootStore.setLoading(true)}
        uploadRequestHeaders={{
          'x-amz-acl': 'private'
        }}
      />
    </>
  ) as JSX.Element;
};

export default GearManufacturerImageUpload;
