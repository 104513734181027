import { useQuery } from '@apollo/client'
import { GET_REGISTERED_STUDENTS_COUNT } from 'graphql/GET_REGISTERED_STUDENTS_COUNT'
import { GET_REGISTERED_STUDENTS_COUNT as GET_REGISTERED_STUDENTS_COUNT_TYPE } from 'graphql/types/GET_REGISTERED_STUDENTS_COUNT'

const useGetRegisteredStudentsCount = (campId: number) => {
  const { loading, data } = useQuery<GET_REGISTERED_STUDENTS_COUNT_TYPE>(
    GET_REGISTERED_STUDENTS_COUNT,
    {
      variables: {
        campId
      }
    }
  )

  return {
    loading,
    data
  }
}

export default useGetRegisteredStudentsCount
