import { observer } from 'mobx-react';
import React from 'react';
import { Container } from 'tabler-react';
import Wrapper from '../layout';
import RiggerJobsList from '../../components/Jobs/RiggerJobsList';
import { useRootStore } from '../../hooks';

const RiggerJobsListPage = (props) => {
  const { currentUser } = useRootStore();
  return (
    <Wrapper {...props} title="My Jobs">
      <Container>
        <RiggerJobsList riggerId={Number(currentUser.id)} />
      </Container>
    </Wrapper>
  );
};
export default observer(RiggerJobsListPage);
