import { useQuery } from "@apollo/client";
import { observer } from "mobx-react";
import React, { useMemo } from "react";
import { Alert, Button, Card, Grid, Icon } from "tabler-react";
import useRouter from "use-react-router";

import RigCard from "../../components/Gear/RigCard";
import StudentCamps from "../../components/Students/StudentCamps";
import StudentLocations from "../../components/Students/StudentLocations";
import UserCard from "../../components/User/Profile/UserCard";
import { GET_RIGS } from "../../graphql/GET_RIGS";
import { GET_STUDENT_CAMPS } from "../../graphql/GET_STUDENT_CAMPS";
import { useRootStore } from "../../hooks";
import {
  NUMBER_EIGHT,
  NUMBER_FOUR,
  NUMBER_THREE,
  NUMBER_TWELVE,
} from "../../modules/common/constants/defaultNumbers";
import { Types } from "../../types/graphql";

const StudentHome = () => {
  const { currentUser } = useRootStore();
  const { history } = useRouter();
  const { data, loading } = useQuery(GET_STUDENT_CAMPS, {
    variables: { getStudentCampsInput: { studentId: currentUser.id } },
  });

  const { loading: userRigsLoading, data: userRigsData } = useQuery(GET_RIGS, {
    variables: {
      rigListFilter: {
        student_id: currentUser.id,
      },
    },
  });

  const userRigs: Types.Rig[] = useMemo((): Types.Rig[] => {
    if (!userRigsLoading && userRigsData) {
      return userRigsData.rigList.toSorted(
        (a: Types.Rig, b: Types.Rig): number =>
          (a.name || "").localeCompare(b.name || ""),
      );
    }

    return [];
  }, [userRigsLoading, userRigsData]);

  return (
    <div className="container">
      <Grid.Row>
        <Grid.Col xs={NUMBER_THREE} sm={NUMBER_TWELVE} lg={NUMBER_FOUR}>
          <UserCard
            firstName={currentUser?.first_name}
            lastName={currentUser?.last_name}
            profileAvatar={currentUser?.profile_avatar}
            profilePicture={currentUser?.profile_picture}
          />
          <StudentCamps
            studentId={currentUser.id}
            camps={data?.getStudentCamps}
            loading={loading}
          />
        </Grid.Col>
        <Grid.Col xs={NUMBER_TWELVE} sm={NUMBER_TWELVE} lg={NUMBER_EIGHT}>
          <Card>
            <Card.Header>
              <Card.Title>
                <Icon name="briefcase" className="mr-2 ml-0 text-blue" />
                Gear
              </Card.Title>
              <Card.Options>
                {userRigs.length > 0 && (
                  <Button
                    color="white"
                    className="text-muted"
                    onClick={(): void => history.push("/user/gear")}
                  >
                    See All
                  </Button>
                )}
              </Card.Options>
            </Card.Header>
            <Card.Body>
              {!userRigsLoading && userRigs.length > 0 ? (
                <RigCard rigs={userRigs.slice(0, NUMBER_THREE)} />
              ) : (
                <>
                  <Alert type="info text-center">
                    <strong>Your Gear Bag is empty!</strong>
                  </Alert>
                  <Button.List align="center">
                    <Button
                      icon="plus"
                      color="primary"
                      size="sm"
                      className=""
                      onClick={(): void => history.push("/user/gear")}
                    >
                      GEAR
                    </Button>
                  </Button.List>
                </>
              )}
            </Card.Body>
          </Card>
          <StudentLocations studentId={currentUser.id} />
        </Grid.Col>
      </Grid.Row>
    </div>
  );
};

export default observer(StudentHome);
