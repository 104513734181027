import Modal from 'components/Modal';
import { IconQrcode } from '@tabler/icons-react';
import React, { useState } from 'react';
import QRCode from 'react-qr-code';
import { Text } from 'tabler-react';

type QRCodeStudentProps = {
  token: string;
  id: any;
  firstName: string;
  lastName: string;
};

const QRCodeStudent = ({
  token,
  id,
  firstName,
  lastName
}: QRCodeStudentProps) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  return (
    <div>
      <IconQrcode
        className="mr-2 text-white"
        onClick={toggleModal}
      />
      <Modal
        title="SCAN QR Code"
        content={
          <div className="text-center">
            <a href={`/user/${token}`}>
              <QRCode
                className="mt-5"
                value={`https://portal.skycru.com/user/${token}`}
              />
            </a>
            <h2 className="mt-5">
              {firstName} {lastName}
            </h2>
            <Text>SKY.D: {id}</Text>
          </div>
        }
        open={isModalOpen}
        onClose={toggleModal}
      />
    </div>
  );
};

export default QRCodeStudent;
