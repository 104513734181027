import Modal from 'components/Modal'
import useGetCurrentUserType from 'modules/common/hooks/useGetCurrentUserType'
import SendEmailToEventRegisteredUsersForm from 'modules/user/registrations/components/forms/SendEmailToEventRegisteredUsersForm'
import React, { useState } from 'react'
import { Dropdown } from 'tabler-react'

const SendEmailToEventRegisteredUsersButton = () => {
  const [isModalOpen, setIsModalOpen] = useState(false)
  const { isAdmin } = useGetCurrentUserType()

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen)
  }

  return (
    <>
      <Dropdown.Item
        icon="send"
        color="white"
        className="text-primary cursor-pointer"
        onClick={toggleModal}
        disabled={isAdmin}
      >
        EMAIL
      </Dropdown.Item>
      <Modal
        content={
          <SendEmailToEventRegisteredUsersForm toggleModal={toggleModal} />
        }
        open={isModalOpen}
        title="Compose Email"
        onClose={toggleModal}
      />
    </>
  )
}

export default SendEmailToEventRegisteredUsersButton
