import gql from 'graphql-tag';

export const GET_ALL_GEAR_MANUFACTURERS = gql`
  query GET_ALL_GEAR_MANUFACTURERS {
    getGearManufacturers {
      id
      slug
      name
      image
    }
  }
`;
