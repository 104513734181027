import gql from 'graphql-tag';

export const ADD_TEAM_AIRCRAFT = gql`
  mutation ADD_TEAM_AIRCRAFT($newTeamAircraft: NewTeamAircraft!) {
    createNewTeamAircraft(newTeamAircraft: $newTeamAircraft) {
      id
      name
      tail_number
      aircraft_type
      aircraft_notes
      capacity_max
      capacity_min
    }
  }
`;
