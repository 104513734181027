import { useMutation, useQuery } from "@apollo/client";
import FeatureAccessGate from "modules/common/components/FeatureAccessGate";
import React, { useContext, useMemo, useState } from "react";
import { useParams } from "react-router";
import Select from "react-select";
import { toast } from "react-toastify";
import { Button, Card, Table } from "tabler-react";
import useReactRouter from "use-react-router";
import { LoadStoreContext } from "../../contexts/LoadStoreContext";
import { ADD_LOAD_USER } from "../../graphql/ADD_LOAD_USER";
import { DELETE_LOAD_USER } from "../../graphql/DELETE_LOAD_USER";
import { GET_LOAD } from "../../graphql/GET_LOAD";
import { GET_STUDENTS_BY_ROLE } from "../../graphql/GET_STUDENTS_BY_ROLE";
import { UPDATE_LOAD } from "../../graphql/UPDATE_LOAD";
import { loadStatusOptions } from "../constants";
import AddLoadModal from "./Form/AddLoadModal";
import AddLoadPassengerModal from "./Form/AddLoadPassengerModal";
import LoadPassengerListItem from "./LoadPassengerListItem";

const LoadPilotSelect = ({
  loadId,
  toggle,
  teamId,
  currentPassengers,
  pilot,
}) => {
  const [addLoadUser] = useMutation(ADD_LOAD_USER, {
    onCompleted: async () => {
      toast.success("Pilot added");
    },
    refetchQueries: ["GET_LOAD"],
  });
  const [deleteUserFromLoad] = useMutation(DELETE_LOAD_USER, {
    refetchQueries: ["GET_LOAD"],
  });

  const { data, loading } = useQuery(GET_STUDENTS_BY_ROLE, {
    variables: {
      roleId: 6, // pilot role id
      teamId: teamId,
    },
  });

  const options = useMemo(() => {
    return (
      data?.getStudentsByRole
        .filter(
          (student) =>
            currentPassengers.indexOf(student.id) === -1 ||
            student.id == pilot?.studentDetails?.id,
        )
        .map((student) => ({
          label: `${student.first_name} ${student.last_name}`,
          value: student.id,
        })) ?? []
    );
  }, [data, currentPassengers, loading]);

  const handleChangePilot = async (value) => {
    if (pilot?.studentDetails?.id === value) {
      toggle();
      return;
    }
    if (pilot) {
      await deleteUserFromLoad({
        variables: {
          loadAssociatedUserId: pilot.id,
        },
      });
    }
    await addLoadUser({
      variables: {
        loadAssociatedUser: {
          student_id: value,
          association_type_id: 1,
          sequence: 0,
          load_id: loadId,
          team_id: teamId,
        },
      },
    });
    toggle();
  };

  return (
    <Select
      className="ml-3 d-inline-flex form-control-sm"
      options={options}
      name="student_id"
      value={options.find(
        (option) => option.value === pilot?.studentDetails?.id,
      )}
      onChange={(option) => handleChangePilot(option.value)}
    />
  );
};

const AircraftSelect = ({
  aircrafts,
  aircraftId,
  toggle,
  loadId,
  updateLoad,
}) => {
  const options = aircrafts.map((aircraft) => ({
    label: aircraft.name,
    value: aircraft.id,
  }));

  const handleChange = async (id) => {
    await updateLoad({
      variables: {
        load: {
          id: loadId,
          aircraft_id: id,
        },
      },
    });
    toggle();
  };

  return (
    <Select
      className="ml-3 d-inline-flex form-control-sm"
      options={options}
      name="student_id"
      value={options.find((option) => option.value === aircraftId)}
      onChange={(option) => handleChange(option.value)}
    />
  );
};

const LoadStatusSelect = ({ toggle, loadId, updateLoad, status }) => {
  const handleChange = async (value) => {
    await updateLoad({
      variables: {
        load: {
          id: loadId,
          status: value,
        },
      },
    });
    toggle();
  };

  return (
    <Select
      className="ml-3 d-inline-flex form-control-sm"
      options={loadStatusOptions}
      name="student_id"
      value={loadStatusOptions.find((option) => option.value === status)}
      onChange={(option) => handleChange(option.value)}
    />
  );
};

const LoadPassengerList = () => {
  const { history } = useReactRouter();
  const { loadId } = useParams<{ loadId: string }>();
  const { loads, aircrafts } = useContext(LoadStoreContext);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isShowPilotSelect, setIsShowPilotSelect] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [isShowAircraftSelect, setIsShowAircraftSelect] = useState(false);
  const [isShowLoadStatusSelect, setIsShowLoadStatusSelect] = useState(false);
  const [updateLoad] = useMutation(UPDATE_LOAD, {
    onCompleted: async () => {
      toast.success("Load updated");
    },
    refetchQueries: ["GET_LOAD"],
  });
  const { loading, data } = useQuery(GET_LOAD, {
    variables: {
      loadId,
    },
  });
  const load = useMemo(() => {
    if (data && !loading) return data.load;

    return null;
  }, [data, loading]);

  const pilot = useMemo(
    () =>
      load?.passengers?.find(
        (pilot) => pilot.association_type_name === "Pilot",
      ) ?? null,
    [load],
  );
  return (
    <Card>
      <Card.Header>
        <Card.Title>
          <span
            className="font-weight-bold cursor-pointer mr-1"
            onClick={() => history.push(`/dzm/loads`)}
          >
            Loads
          </span>
          //{" "}
          <span
            onClick={() => setIsEditModalOpen(true)}
            className={"cursor-pointer"}
          >
            {load?.load_name}
          </span>
          {isShowAircraftSelect ? (
            <AircraftSelect
              aircraftId={load.aircraft.id}
              aircrafts={aircrafts}
              updateLoad={updateLoad}
              loadId={load.id}
              toggle={() => setIsShowAircraftSelect(false)}
            />
          ) : (
            <Button
              outline
              color="secondary"
              size="sm"
              className="ml-3"
              onClick={() => setIsShowAircraftSelect(true)}
            >
              {load?.aircraft?.name}
            </Button>
          )}
          {isShowPilotSelect ? (
            <LoadPilotSelect
              toggle={() => setIsShowPilotSelect(false)}
              loadId={load?.id}
              teamId={load?.team_id}
              currentPassengers={load?.passengers.map(
                (p) => p.studentDetails.id,
              )}
              pilot={pilot}
            />
          ) : (
            <Button
              outline
              icon="user"
              color="info"
              size="sm"
              className="ml-2"
              onClick={() => setIsShowPilotSelect(true)}
            >
              {" "}
              {pilot
                ? `${pilot.studentDetails.first_name} ${pilot.studentDetails.last_name}`
                : "Add Pilot"}
            </Button>
          )}
        </Card.Title>
        <Card.Options>
          <Button
            outline
            icon="user-plus"
            color="primary"
            size="sm"
            className="mr-2"
            onClick={() => setIsModalOpen(true)}
          />
            <Button 
              color="white" 
              size="sm" 
              className={load?.aircraft?.capacity_max > load?.passengers.length ? 'text-success' : 'text-danger'}
              disabled
            >
              {load?.passengers ? load?.passengers.length : 0}/
              {load?.aircraft?.capacity_max}
            </Button>
            <Button 
              color="white" 
              size="sm" 
              className={load?.aircraft?.capacity_max > load?.passengers.length ? 'text-success' : 'text-danger'}
              disabled
            >
              {load?.aircraft?.capacity_max - load?.passengers.length} OPEN
            </Button>
            <Button color="white" size="sm" disabled>
              Load {loads.findIndex((ctxLoad) => ctxLoad.id === load?.id) + 1}
              {' of ' + loads.length}
            </Button>
          {isShowLoadStatusSelect ? (
            <LoadStatusSelect
              toggle={() => setIsShowLoadStatusSelect(false)}
              loadId={load.id}
              updateLoad={updateLoad}
              status={load?.status.slug}
            />
          ) : (
            <Button
              outline
              color="secondary"
              size="sm"
              className="mr-2"
              onClick={() => setIsShowLoadStatusSelect(true)}
            >
              {load?.status.name}
            </Button>
          )}
          <Button
            outline
            icon="check-circle"
            color="success"
            size="sm"
            className=""
            // disable button until load is complete status // disabled
            disabled={load?.status.name !== "Complete"}
          >
            Complete Load
          </Button>
        </Card.Options>
      </Card.Header>
      <Card.Body>
        <div className="table-responsive">
          <Table className="table card-table table-vcenter text-nowrap">
            <Table.Body>
              {load?.passengers
                .filter((passenger) => passenger.association_type_id != 1)
                .map((passenger, index) => (
                  <LoadPassengerListItem
                    passenger={passenger}
                    key={index}
                    load={load}
                  />
                ))}
            </Table.Body>
          </Table>
        </div>

        <AddLoadPassengerModal
          loadId={load?.id}
          isModalOpen={isModalOpen}
          toggleModal={() => setIsModalOpen(false)}
          currentPassengers={load?.passengers.map((p) => p.studentDetails.id)}
        />
        <AddLoadModal
          isModalOpen={isEditModalOpen}
          toggleModal={() => setIsEditModalOpen(false)}
          load={load}
        />
      </Card.Body>
    </Card>
  );
};

export default LoadPassengerList;
