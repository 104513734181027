import gql from 'graphql-tag';

export const GET_SEALS_SEARCH = gql`
    query GET_SEALS_SEARCH($filter: String!) {
        getSeals(filter: $filter) {
            id
            rigger_seal
            coach_id
            coach{
                first_name
                last_name
            }
        }
    }`;
