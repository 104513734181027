import gql from 'graphql-tag';

export const GET_PRODUCT_TYPES = gql`
  query GET_PRODUCT_TYPES{
    productTypes{
  id
  slug
  name
}
    }
`;
