import React from 'react';
import { Container } from 'tabler-react';
import Wrapper from 'pages/layout';
import InvoiceList from '../../components/Invoices/InvoiceList';

const StudentInvoicePage = (props) => {
  return (
    <Wrapper {...props} title="Invoices">
      <Container>
        <InvoiceList />
      </Container>
    </Wrapper>
  );
};
export default StudentInvoicePage;
