import gql from 'graphql-tag';

export const GET_TEAM_SPORTS = gql`
  query GET_TEAM_SPORTS($team_id: String!) {
    teamSports(team_id: $team_id) {
      sport_type_id
      team_id
      name
      slug
    }
  }
`;
