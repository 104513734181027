import { useQuery } from '@apollo/client';
import UserListItem from 'components/User/UserListItem';
import { EUserFilterOptions } from 'components/enum';
import { UserStoreContext } from 'contexts/userStoreContext';
import { GET_STUDENTS_BY_FILTER_QUERY } from 'graphql/GET_STUDENTS_BY_FILTER_QUERY';
import { GET_STUDENTS_BY_FILTER_QUERY as GET_STUDENTS_BY_FILTER_QUERY_TYPE } from 'graphql/types/GET_STUDENTS_BY_FILTER_QUERY';
import { EOrderOptions } from 'hooks/useAutocomplete';
import { observer } from 'mobx-react';
import { applySnapshot } from 'mobx-state-tree';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { CSVLink } from 'react-csv';
import InfiniteScroll from 'react-infinite-scroll-component';
import { Alert, Card, Dimmer, Form, Grid, Icon, Table } from 'tabler-react';
import { Types } from '../../types/graphql'

const DEFAULT_LIMIT = 30;
const DEFAULT_ORDER = EOrderOptions.newest;

const UserList = () => {
  const { users } = useContext(UserStoreContext);
  const [page, setPage] = useState<number>(1);
  const [filterOption, setFilterOption] = useState<EUserFilterOptions>(
    EUserFilterOptions.all
  );

  const { loading, error, data, fetchMore } = useQuery(
    GET_STUDENTS_BY_FILTER_QUERY,
    {
      variables: {
        order: DEFAULT_ORDER,
        filter: { limit: DEFAULT_LIMIT, offset: 0, filter_by: filterOption }
      }
    }
  );

  const { loading: exportStudentsLoading, data: exportStudents } =
    useQuery<GET_STUDENTS_BY_FILTER_QUERY_TYPE>(
      GET_STUDENTS_BY_FILTER_QUERY,
      {
        variables: {
          order: DEFAULT_ORDER,
          filter: { filter_by: filterOption }
        }
      }
    );

  const exportStudentsData = useMemo(
    () =>
      exportStudents?.students?.map(
        ({
           id,
           first_name,
           last_name,
           email,
           phone_country_code,
           phone_number,
           teams
         }) => ({
          id,
          name: `${first_name} ${last_name}`,
          email,
          phone: `${phone_country_code}${phone_number}`,
          teams: teams?.map(({ name }) => name).join(', ')
        })
      ) ?? [],  
    [exportStudents?.students, exportStudentsLoading]
  );

  useEffect(() => {
    if (!loading && data) {
      applySnapshot(users, data.students);
    }
  }, [data, filterOption]);  

  const onFetchMore = () => {
    setPage((prevNum) => prevNum + 1);
    return fetchMore({
      updateQuery: (cache, { fetchMoreResult }) => {
        if (!fetchMoreResult) {
          return cache;
        }
        return {
          ...cache,
          students: [...cache.students, ...fetchMoreResult.students]
        };
      },
      variables: {
        order: DEFAULT_ORDER,
        filter: {
          limit: DEFAULT_LIMIT,
          offset: DEFAULT_LIMIT * page,
          filter_by: filterOption
        }
      }
    });
  };

  if (error) {
    return <p>{`Error: ${error.message}`}</p>;
  }

  const handleToggleFilter = (e) => {
    setFilterOption(
      !e.target.checked ? EUserFilterOptions.all : EUserFilterOptions.disabled
    );
  };

  return (
    <Card>
      <Card.Header>
        <Card.Title>
          <Icon name="users" className="mr-2 ml-0 text-blue" /> User Accounts
        </Card.Title>
        <Card.Options className="gap-4">
          {!exportStudentsLoading && (
            <CSVLink data={exportStudentsData} filename="student-list.csv">
              <Icon name="download" color="white" />
            </CSVLink>
          )}

          <Form.Group className="mb-0">
            <Form.Switch
              name="toggle"
              value="showEnabled"
              label="Disabled"
              onChange={handleToggleFilter}
            />
          </Form.Group>
        </Card.Options>
      </Card.Header>
      <Card.Body>
        <Grid.Col width={12}>
          <Dimmer active={loading} loader={loading}>
            <InfiniteScroll
              className="overflow-visible pb-3"
              dataLength={users.length}
              hasMore={users.length < (data?.students[0]?.total || 0)}
              loader={<Dimmer active={true} loader={true} />}
              next={onFetchMore}
              scrollThreshold="210px"
              endMessage={
                <Alert className="text-center" type="primary">
                  No {users.length !== 0 && 'more'} user to show.
                </Alert>
              }
            >
              <Table>
                <Table.Header>
                  <Table.Row>
                    <Table.ColHeader></Table.ColHeader>
                    <Table.ColHeader></Table.ColHeader>
                    <Table.ColHeader></Table.ColHeader>
                    <Table.ColHeader></Table.ColHeader>
                    <Table.ColHeader></Table.ColHeader>
                    <Table.ColHeader></Table.ColHeader>
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  {users.map((user) => (
                    <Table.Row key={user.id}>
                      <UserListItem user={user} />
                    </Table.Row>
                  ))}
                </Table.Body>
              </Table>
            </InfiniteScroll>
          </Dimmer>
        </Grid.Col>
      </Card.Body>
    </Card>
  );
};

export default observer(UserList);
