import React from 'react';
import moment from 'moment';
import { Formik } from 'formik';
import useGetCurrentUserType from '../../../modules/common/hooks/useGetCurrentUserType';
import { useRootStore } from '../../../hooks';
import { useMutation } from '@apollo/client';
import { ADD_STUDENT_ORG_CREDENTIAL } from '../../../graphql/ADD_STUDENT_ORG_CREDENTIAL';
import { toast } from 'react-toastify';
import { EDIT_STUDENT_ORG_CREDENTIAL } from '../../../graphql/EDIT_STUDENT_ORG_CREDENTIAL';
import { Button, Form, Grid } from 'tabler-react';
import Select from 'react-select';
import { BASE_ORG_ID } from '../../constants';

const ManualRatingForm = ({ rating }) => {
  const { isStudent, isAdmin } = useGetCurrentUserType();
  const { currentUser } = useRootStore();
  const [addStudentOrgCredential] = useMutation(ADD_STUDENT_ORG_CREDENTIAL, {
    refetchQueries: ['GET_ORG_CREDENTIALS'],
    onCompleted: () => {
      toast.success('Credential added successfully');
    }
  });

  const [updateStudentOrgCredential] = useMutation(
    EDIT_STUDENT_ORG_CREDENTIAL,
    {
      refetchQueries: ['GET_ORG_CREDENTIALS'],
      onCompleted: () => {
        toast.success('Credential updated successfully');
      }
    }
  );

  const renderForm = ({
    handleChange,
    handleSubmit,
    values,
    setFieldValue,
    dirty
  }) => {
    return (
      <>
        <Grid.Row>
          <Grid.Col xs={12} sm={12} md={6} lg={6}>
            <Form.Group label="">
              {rating?.org_id === BASE_ORG_ID && !rating.id && (
                <Select
                  styles={{
                    menuPortal: (base) => ({ ...base, zIndex: 9999 })
                  }}
                  menuPortalTarget={document.body}
                  name="rating"
                  options={[
                    { label: 'Building', value: 'Building' },
                    { label: 'Antenna', value: 'Antenna' },
                    { label: 'Span', value: 'Span' },
                    { label: 'Earth', value: 'Earth' }
                  ]}
                  onChange={(e) => {
                    setFieldValue('rating', e.value);
                  }}
                  value={
                    values?.rating
                      ? {
                          label: values.rating,
                          value: values.rating
                        }
                      : null
                  }
                />
              )}
              {(rating?.org_id !== BASE_ORG_ID || rating.id) && (
                <>
                  <Form.InputGroup>
                    <Form.InputGroupAppend>
                      <Button
                        icon="award"
                        color="white"
                        className="border-0"
                        disabled
                      />
                    </Form.InputGroupAppend>
                    <Form.Input
                      //  icon="award"
                      name="rating"
                      placeholder={'Add rating...'}
                      value={values?.rating}
                      onChange={handleChange}
                      disabled={values.verified_on && !isAdmin}
                    />

                    {values.id && (
                      <Form.InputGroupAppend>
                        <Button
                          icon={
                            values.verified_on ? 'check-circle' : 'alert-circle'
                          }
                          color="white"
                          className={
                            values.verified_on ? 'text-success' : 'text-danger'
                          }
                          disabled={values.verified_on || isStudent}
                          onClick={() => {
                            const ratingClone = { ...values };
                            ratingClone.verified_by = currentUser.id.toString();
                            ratingClone.verified_on =
                              moment().format('YYYY-MM-DD');
                            updateStudentOrgCredential({
                              variables: {
                                updateOrgCredentialInput: ratingClone
                              }
                            });
                          }}
                        >
                          {values.verified_on ? 'Verified' : !values.verified_on && isStudent ? 'Unverified' : 'Verify'}
                        </Button>
                      </Form.InputGroupAppend>
                    )}
                  </Form.InputGroup>
                </>
              )}
            </Form.Group>
          </Grid.Col>
          <Grid.Col xs={12} sm={12} md={6} lg={6}>
            <Form.Group label="">
              <Form.InputGroup>
                {Number(rating?.org_id) !== BASE_ORG_ID && (
                  <Form.Input
                    valid={moment().isBefore(values.expires)}
                    tick={moment().isBefore(values.expires)}
                    invalid={moment().isAfter(values.expires)}
                    cross={moment().isAfter(values.expires)}
                    type="date"
                    name="expires"
                    value={values.expires}
                    onChange={handleChange}
                  />
                )}
                {Number(rating?.org_id) === BASE_ORG_ID && rating.id && (
                  <Form.Input
                    type="date"
                    name="expires"
                    value={moment.utc(rating.created_on).format('YYYY-MM-DD')}
                    onChange={handleChange}
                    disabled={true}
                  />
                )}
                {dirty && (
                  <Form.InputGroupAppend>
                    <Button
                      color="primary"
                      className="ml-2"
                      onClick={handleSubmit}
                      disable={!dirty}
                    >
                      {values.id ? 'UPDATE' : 'SAVE'}
                    </Button>
                  </Form.InputGroupAppend>
                )}
              </Form.InputGroup>
            </Form.Group>
          </Grid.Col>
        </Grid.Row>
      </>
    );
  };
  return (
    <Formik
      enableReinitialize={true}
      initialValues={{
        id: rating?.id || undefined,
        org_id: rating?.org_id,
        rating: rating?.rating,
        expires: rating?.expires || undefined,
        student_id: rating?.student_id,
        verified_on: rating?.verified_on || null,
        verified_by: rating?.verified_by || null
      }}
      onSubmit={(values, { setSubmitting, resetForm }) => {
        if (rating.id) {
          if (isStudent) {
            values.verified_by = null;
            values.verified_on = null;
          }
          updateStudentOrgCredential({
            variables: {
              updateOrgCredentialInput: {
                ...values
              }
            }
          });
        } else {
          if (!isStudent) {
            values.verified_by = currentUser.id.toString();
            values.verified_on = moment().format('YYYY-MM-DD');
          }
          addStudentOrgCredential({
            variables: {
              addOrgCredentialInput: {
                ...values
              }
            }
          });
        }
        resetForm();
        setSubmitting(false);
      }}
    >
      {(formData) => renderForm(formData)}
    </Formik>
  );
};

export default ManualRatingForm;
