import { useQuery } from '@apollo/client'
import GroupFormModal from 'components/Calendar/Modal/GroupFormModal'
import { GET_GROUPS } from 'graphql/GET_GROUPS'
import { useMemo, useState } from 'react'
import { Button, Card, Dimmer, Grid } from 'tabler-react'
import useRouter from 'use-react-router'
import { Types } from '../../types/graphql'
import CompetitionGroupsListItem from './CompetitionGroupsListItem'

interface CompetitionGroupsListProps {
  campId: string
  teamId: string
  campName: string
  students: Types.RegisteredStudents[]
}

const CompetitionGroupsList = ({
  campId,
  teamId,
  campName,
  students
}: CompetitionGroupsListProps) => {
  const { history } = useRouter()
  const [isModalOpen, setIsModalOpen] = useState(false)

  const { data, loading } = useQuery(GET_GROUPS, {
    variables: {
      filter: {
        camp_id: Number(campId),
        team_id: teamId,
        status: 'Active'
      }
    }
  })

  const groupList = useMemo(() => {
    if (data) {
      return data.groupList
    }

    return []
  }, [data?.groupList])

  return (
    <Card.Body>
      <Grid.Row>
        <Grid.Col>
          <Button
            color="primary"
            icon="plus"
            size="sm"
            className="float-right"
            onClick={() => {
              setIsModalOpen(true)
            }}
          >
            GROUP
          </Button>
        </Grid.Col>
      </Grid.Row>
      <Dimmer active={loading} loading={loading}>
        {data && (
          <>
            <Grid.Row>
              <Grid.Col md={3} lg={2}>
                {students.length > 0 && (
                  <div>
                    <h4>Participants</h4>
                    <div className="overflow-scrolly pr-1">
                      {students.map((student) => {
                        // Check if student is in multiple groups
                        // If so, display indicator icon
                        const studentGroups = groupList.filter((group) =>
                          group.members.some(
                            (member) => member.id === student.id
                          )
                        )
                        return (
                          <div key={student.id}>
                            <Button
                              block
                              outline={studentGroups.length === 0}
                              icon={
                                studentGroups.length === 1
                                  ? 'user'
                                  : studentGroups.length > 1
                                  ? 'users'
                                  : 'user-x'
                              }
                              color={
                                studentGroups.length > 1 ? 'info' : 'primary'
                              }
                              size="sm"
                              className="mb-2 text-left"
                              onClick={() => {
                                history.push(
                                  `/events/registrations/${student.user_registrations_id}/${student.id}`
                                )
                              }}
                            >
                              {`${student.first_name} ${student.last_name}`}
                            </Button>
                          </div>
                        )
                      })}
                    </div>
                  </div>
                )}
              </Grid.Col>
              <Grid.Col md={10}>
                <Grid.Row>
                  {groupList.map((group) => (
                    <Grid.Col md={4} key={group.id}>
                      <div>
                        <CompetitionGroupsListItem
                          group={group}
                          groupList={groupList}
                        />
                      </div>
                    </Grid.Col>
                  ))}
                </Grid.Row>
              </Grid.Col>
            </Grid.Row>
            <GroupFormModal
              isOpen={isModalOpen}
              toggle={setIsModalOpen}
              campId={campId}
              teamId={teamId}
            />
          </>
        )}
      </Dimmer>
    </Card.Body>
  )
}

export default CompetitionGroupsList
