import gql from 'graphql-tag';

export const GET_LOCATIONS_BY_FILTER_QUERY = gql`
  query GET_LOCATIONS_BY_FILTER_QUERY($filter: String!) {
    getLocationsByFilter(filter: $filter) {
      id
      name
      type
    }
  }
`;
