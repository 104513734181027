import { useFormikContext } from 'formik';
import UploadImageDropdown from 'modules/common/components/UploadImageDropdown';
import useGetCurrentUserType from 'modules/common/hooks/useGetCurrentUserType';
import { NewTeamOnboardingFormValues } from 'modules/team/components/forms/NewTeamOnboardingForm';
import React from 'react';
import { Card, Dropdown, GalleryCard, Text } from 'tabler-react';
import productImagePlaceholder from 'assets/images/product-image-placeholder.png';
import { toast } from 'react-toastify';
import { useRootStore } from 'hooks';
import { observer } from 'mobx-react';

const LogosStep = () => {
  const rootStore = useRootStore();

  const { isAdmin } = useGetCurrentUserType();

  const { values, setFieldValue } =
    useFormikContext<NewTeamOnboardingFormValues>();

  const onError = (error) => {
    toast.error(error);
    rootStore.setLoading(false);
  };

  const onFinished = (response: { fileKey: string }, imageName: string) => {
    setTeamImage(response.fileKey, imageName);
    rootStore.setLoading(false);
  };

  const setTeamImage = async (fileKey: string | null, imageName: string) => {
    setFieldValue(imageName, fileKey);
  };

  return (
    <>
      <Card className="card-profile team-image">
        <Card.Header
          backgroundURL={
            values?.team_logo_wide_image
              ? `/api/s3/uploads/${values.team_logo_wide_image}`
              : '//via.placeholder.com/374x144.png?text=+'
          }
        >
          <div className="banner-dropdown-container">
            <Dropdown
              className="cursor-pointer banner-dropdown"
              icon="camera"
              items={
                <UploadImageDropdown
                  imageName={values?.team_logo_wide_image}
                  onErrorHandler={onError}
                  onFinishedHandler={(response: { fileKey: string }) =>
                    onFinished(response, 'team_logo_wide_image')
                  }
                  callBack={(fileKey: string | null) =>
                    setTeamImage(fileKey, 'team_logo_wide_image')
                  }
                />
              }
              toggle={false}
            />
          </div>
        </Card.Header>
        <Card.Footer>
          <Text.Small color="primary">Logo Wide</Text.Small>
        </Card.Footer>
      </Card>
      <Card className="card-profile">
        <Card.Body className="text-center">
          <GalleryCard.Image
            className="product-image w-50"
            src={
              values?.team_logo_square_image
                ? `/api/s3/uploads/${values.team_logo_square_image}`
                : productImagePlaceholder
            }
          />
          <div className="banner-dropdown-container">
            <Dropdown
              className="cursor-pointer banner-dropdown"
              icon="camera"
              items={
                <UploadImageDropdown
                  imageName={values?.team_logo_square_image}
                  onErrorHandler={onError}
                  onFinishedHandler={(response: { fileKey: string }) =>
                    onFinished(response, 'team_logo_square_image')
                  }
                  callBack={(fileKey: string | null) =>
                    setTeamImage(fileKey, 'team_logo_square_image')
                  }
                />
              }
              toggle={false}
            />
          </div>
        </Card.Body>
        <Card.Footer>
          <Text.Small color="primary">Logo Square</Text.Small>
        </Card.Footer>
      </Card>
      {isAdmin && (
        <Card className="card-profile">
          <Card.Header
            backgroundURL={
              values?.team_promo_image
                ? `/api/s3/uploads/${values.team_promo_image}`
                : '//via.placeholder.com/374x144.png?text=+'
            }
          >
            <div className="banner-dropdown-container">
              <Dropdown
                className="cursor-pointer banner-dropdown"
                icon="camera"
                items={
                  <UploadImageDropdown
                    imageName={values?.team_promo_image}
                    onErrorHandler={onError}
                    onFinishedHandler={(response: { fileKey: string }) =>
                      onFinished(response, 'team_promo_image')
                    }
                    callBack={(fileKey: string | null) =>
                      setTeamImage(fileKey, 'team_promo_image')
                    }
                  />
                }
                toggle={false}
              />
            </div>
          </Card.Header>
          <Card.Footer>
            <Text.Small color="primary">Promo Image</Text.Small>
          </Card.Footer>
        </Card>
      )}
    </>
  );
};

export default observer(LogosStep);
