import { useQuery } from '@apollo/client';
import { useFormikContext } from 'formik';
import { GET_COACHES } from 'graphql/GET_COACHES';
import { GET_COACHES as GET_COACHES_TYPE } from 'graphql/types/GET_COACHES';
import autoCompleteStyles from 'modules/common/styles/autocompleteStyles';
import { NewTeamOnboardingFormValues } from 'modules/team/components/forms/NewTeamOnboardingForm';
import React, { useMemo } from 'react';
import Select from 'react-select';
import { Form } from 'tabler-react';
import { array, number, object, string } from 'yup';

export const coachesSchema = object().shape({
  coaches: array()
    .of(
      object().shape({
        value: number(),
        label: string()
      })
    )
    .test('coaches', 'Select not more than 5 coaches', (value) => {
      return value.length <= 5;
    })
});

const TeamAccountsStep = () => {
  const { errors, touched, values, setFieldValue } =
    useFormikContext<NewTeamOnboardingFormValues>();

  const { data: coachesData } = useQuery<GET_COACHES_TYPE>(GET_COACHES, {
    variables: {
      filter: {
        is_active: true
      }
    }
  });

  const coachRows = useMemo(
    () =>
      coachesData?.getCoaches.map(({ id, first_name, last_name }) => ({
        value: id,
        label: `${first_name} ${last_name} (${id})`
      })) ?? [],
    [coachesData?.getCoaches]  
  );

  const changeCoachHandle = (coaches) => {
    setFieldValue('coaches', coaches);
  };

  return (
    <Form.Group label="Coach User Accounts">
      <Select
        name="coaches"
        isMulti
        placeholder="Select Coach Users..."
        isClearable={true}
        options={coachRows}
        onChange={changeCoachHandle}
        value={values.coaches}
        styles={autoCompleteStyles}
        menuPortalTarget={document.body}
      />
      <span className="field-error text-danger">
        {errors.coaches && touched.coaches && errors.coaches}
      </span>
    </Form.Group>
  );
};

export default TeamAccountsStep;
