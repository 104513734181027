import { useQuery } from "@apollo/client";
import { Formik } from "formik";
import useGetCurrentUserType from "modules/common/hooks/useGetCurrentUserType";
import moment from "moment";
import React, { useMemo } from "react";
import { NumericFormat } from "react-number-format";
import PhoneInput from "react-phone-input-2";
import Select from "react-select";
import { Avatar, Form, Grid, Text } from "tabler-react";
import { GET_ORGS } from "../../../graphql/GET_ORGS";
import { formatHeight } from "../../../utils/numberFormat";
import { countries, genderList, SHIRT_SIZES } from "../../constants";
import FormField from "../../FormField";
import autocompleteStyles from "./autocompleteStyles";
import AvatarUpload from "./AvatarUpload";

interface IStudentRegistrationDetailsFormProps {
  studentProfileRequirement: any;
  studentId: number;
  refetch: any;
}

const StudentRegistrationDetailsForm = ({
  studentProfileRequirement,
  studentId,
  refetch,
}: IStudentRegistrationDetailsFormProps) => {
  const { data, loading } = useQuery(GET_ORGS, {
    variables: { org_type: "nac" },
  });
  const orgs = useMemo(() => {
    if (!loading && data) {
      return data.orgs
        .map((org) => {
          if (org.org_type === "nac") {
            return {
              name: org.name,
              slug: org.slug,
              value: org.id,
            };
          }

          return undefined;
        })
        .filter((org) => org !== undefined);
    }
    return [];
  }, [data, loading]);
  const { isStudent } = useGetCurrentUserType();

  const customModelFilter = (option, searchText) => {
    if (
      option.data.name.toLowerCase().includes(searchText.toLowerCase()) ||
      option.data.slug.toLowerCase().includes(searchText.toLowerCase())
    ) {
      return true;
    } else {
      return false;
    }
  };

  const phone_init = studentProfileRequirement?.phone
    ? studentProfileRequirement.phone.split(" ")
    : undefined;
  const countryCode_init = phone_init
    ? phone_init.shift().replace(/\D/g, "")
    : undefined;
  const renderForm = ({ setFieldValue, values }) => (
    <>
      {isStudent &&
        Object.keys(studentProfileRequirement).includes("profile_avatar") && (
          <Grid.Row>
            <Grid.Col className="mb-3">
              <AvatarUpload
                profileAvatar={studentProfileRequirement.profile_avatar}
                refetch={refetch}
                studentId={studentId}
              />
              <span className="field-error text-danger">
                {!values.profile_avatar && "Profile photo is required"}
              </span>
            </Grid.Col>
          </Grid.Row>
        )}
      {!isStudent &&
        Object.keys(studentProfileRequirement).includes("profile_avatar") && (
          <Grid.Row>
            <Grid.Col className="mb-3">
              <Avatar
                className="avatar"
                imageURL={
                  (studentProfileRequirement.profile_avatar &&
                    `/api/s3/uploads/${studentProfileRequirement.profile_avatar}`) ||
                  "//www.gravatar.com/avatar?d=mp"
                }
                size="xxl"
              />
              <span className="field-error text-danger">
                {!values.profile_avatar && "Profile photo is required"}
              </span>
            </Grid.Col>
          </Grid.Row>
        )}
      <Grid.Row>
        <Grid.Col md={6} sm={12}>
          <Form.Group className="mb-0">
            <Form.Input
              type={"email"}
              label={"Email"}
              name={"email"}
              value={values.email}
              onChange={(e) => {
                setFieldValue("email", e.target.value);
                studentProfileRequirement.email = e.target.value;
              }}
            />
            <span className="field-error text-danger">
              {!values.email && "This field is required"}
            </span>
          </Form.Group>
        </Grid.Col>
        {Object.keys(studentProfileRequirement).includes("phone") && (
          <Grid.Col md={6} sm={12}>
            <Form.Group label="Phone">
              <PhoneInput
                inputProps={{
                  className: "form-control w-100",
                  name: "phone",
                  onChange: (e) => {
                    e.preventDefault();
                    const target = e.target;

                    const phone = target.value.split(" ");
                    const countryCode = phone.shift().replace(/\D/g, "");

                    setFieldValue("phone", phone.join("").replace(/\D/g, ""));
                    setFieldValue("phone_code", Number(countryCode));
                    studentProfileRequirement.phone = e.target.value;
                  },
                }}
                value={`${values.phone_code}${values.phone}`}
              />
              <span className="field-error text-danger">
                {!values.phone && "This field is required"}
              </span>
            </Form.Group>
          </Grid.Col>
        )}
      </Grid.Row>

      <Grid.Row>
        {Object.keys(studentProfileRequirement).includes("height") && (
          <Grid.Col md={3} sm={12}>
            <FormField
              appendright={
                <Form.InputGroupAppend>
                  <Form.InputGroupText>in</Form.InputGroupText>
                </Form.InputGroupAppend>
              }
              appendbottom={
                values.height > 0 ? (
                  <Text.Small className={"ml-1"}>
                    {formatHeight(values.height)}
                  </Text.Small>
                ) : null
              }
              type={"number"}
              label={"Height"}
              name={"height"}
              value={values.height}
              onChange={(e) => {
                setFieldValue("height", e.target.value);
                studentProfileRequirement.height = e.target.value;
              }}
            />
            <span className="field-error text-danger">
              {!values.height && "This field is required"}
            </span>
          </Grid.Col>
        )}

        {Object.keys(studentProfileRequirement).includes("weight") && (
          <Grid.Col md={3} sm={12}>
            <FormField
              appendright={
                <Form.InputGroupAppend>
                  <Form.InputGroupText>lbs</Form.InputGroupText>
                </Form.InputGroupAppend>
              }
              appendbottom={
                values.weight > 0 ? (
                  <Text.Small className={"ml-1"}>
                    {(values.weight * 0.45).toFixed(1) + " kg"}
                  </Text.Small>
                ) : null
              }
              type={"number"}
              label={"Weight"}
              name={"weight"}
              value={values.weight}
              onChange={(e) => {
                setFieldValue("weight", e.target.value);
                studentProfileRequirement.weight = e.target.value;
              }}
            />
            <span className="field-error text-danger">
              {!values.weight && "This field is required"}
            </span>
          </Grid.Col>
        )}

        {Object.keys(studentProfileRequirement).includes("shirt_size") && (
          <Grid.Col md={4} sm={12}>
            <Form.Group className="mb-0" label="Shirt Size">
              <Select
                backspaceRemovesValue={true}
                escapeClearsValue={true}
                getOptionLabel={(option: { name: string }) => option.name}
                getOptionValue={(option: { value: number }) => option.value}
                isClearable={true}
                name="shirt_size"
                options={SHIRT_SIZES}
                onChange={(params: { value: number }) => {
                  setFieldValue("shirt_size", params?.value ?? null);
                  studentProfileRequirement.shirt_size = params?.value ?? null;
                }}
                styles={autocompleteStyles}
                value={
                  values.shirt_size &&
                  SHIRT_SIZES.filter((size) => size.value === values.shirt_size)
                }
              />
            </Form.Group>
            <span className="field-error text-danger">
              {!values.shirt_size && "This field is required"}
            </span>
          </Grid.Col>
        )}
      </Grid.Row>
      <Grid.Row>
        {Object.keys(studentProfileRequirement).includes("dob") && (
          <Grid.Col md={3} sm={12}>
            <Form.Group label="DOB">
              <Form.MaskedInput
                placeholder="MM/DD/YYYY"
                mask={[
                  /\d/,
                  /\d/,
                  "/",
                  /\d/,
                  /\d/,
                  "/",
                  /\d/,
                  /\d/,
                  /\d/,
                  /\d/,
                ]}
                name="dob"
                value={values.dob}
                onChange={(e) => {
                  setFieldValue("dob", e.target.value);
                  if (moment(e.target.value, "MM/DD/YYYY").format("YYYY-MM-DD"))
                    studentProfileRequirement.dob = e.target.value;
                  else studentProfileRequirement.dob = null;
                }}
              />
              <span className="field-error text-danger">
                {!values.dob && "This field is required"}
              </span>
            </Form.Group>
          </Grid.Col>
        )}

        {Object.keys(studentProfileRequirement).includes("gender") && (
          <Grid.Col md={3} sm={12}>
            <Form.Group label="Gender">
              <Select
                styles={autocompleteStyles}
                options={genderList}
                getOptionValue={(option: { value: number }) => option.value}
                value={genderList.find((g) => g.value === values.gender)}
                onChange={(e: { value: number }) => {
                  setFieldValue("gender", e?.value ?? null);
                  studentProfileRequirement.gender = e?.value ?? null;
                }}
              />
              <span className="field-error text-danger">
                {!values.gender && "This field is required"}
              </span>
            </Form.Group>
          </Grid.Col>
        )}

        {Object.keys(studentProfileRequirement).includes("country") && (
          <Grid.Col xs={12} sm={12} md={4} lg={6}>
            <Form.Group label={"Country"}>
              <Select
                backspaceRemovesValue={true}
                escapeClearsValue={true}
                getOptionLabel={(option: { name: string }) => option.name}
                getOptionValue={(option: { name: string }) => option.name}
                isClearable={true}
                name="country"
                options={countries}
                onChange={(params: { name: string }) => {
                  setFieldValue("country", params?.name ?? null);
                  studentProfileRequirement.country = params?.name ?? null;
                }}
                styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
                menuPortalTarget={document.body}
                value={
                  values.country &&
                  countries[
                    Object.keys(countries).find(
                      (key) => countries[key].name === values.country,
                    )
                  ]
                }
              />
              <span className="field-error text-danger">
                {!values.country && "This field is required"}
              </span>
            </Form.Group>
          </Grid.Col>
        )}
      </Grid.Row>
      <Grid.Row>
        {Object.keys(studentProfileRequirement).includes("nac") && (
          <Grid.Col xs={12} sm={12} md={4} lg={6}>
            <Form.Group label={"NAC"}>
              <Select
                getOptionLabel={(option) => (
                  <span>
                    <b>{option.slug.toUpperCase()}</b>
                    <span className="ml-2">{option.name}</span>
                  </span>
                )}
                options={orgs}
                value={orgs.find(
                  (org) => Number(org.value) === Number(values.nac),
                )}
                onChange={(e) => {
                  setFieldValue("nac", e?.value ?? null);
                  studentProfileRequirement.nac = e?.value ?? null;
                }}
                styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
                menuPortalTarget={document.body}
                filterOption={customModelFilter}
              />
              <span className="field-error text-danger">
                {!values.nac && "This field is required"}
              </span>
            </Form.Group>
          </Grid.Col>
        )}

        {Object.keys(studentProfileRequirement).includes("jump_count") && (
          <Grid.Col md={6} sm={12}>
            <Form.Group className="mb-0">
              <NumericFormat
                customInput={FormField}
                name="jump_count"
                label="Jump Count"
                placeholder="Estimate number of jumps"
                value={values.jump_count}
                suffix=" jumps"
                thousandSeparator=","
                onChange={(e) => {
                  const val = parseFloat(e.target.value.replaceAll(",", ""));
                  setFieldValue("jump_count", val);
                  studentProfileRequirement.jump_count = val;
                }}
              />
              <span className="field-error text-danger">
                {!values.jump_count && "This field is required"}
              </span>
            </Form.Group>
          </Grid.Col>
        )}
        {Object.keys(studentProfileRequirement).includes("tunnel_time") && (
          <Grid.Col md={6} sm={12}>
            <Form.Group className="mb-0">
              <NumericFormat
                customInput={FormField}
                name="tunnel_time"
                label="Tunnel Time"
                placeholder="Estimate hours in tunnel"
                value={values.tunnel_time}
                suffix=" hours"
                thousandSeparator=","
                onChange={(e) => {
                  const val = parseFloat(e.target.value.replaceAll(",", ""));
                  setFieldValue("tunnel_time", val);
                  studentProfileRequirement.tunnel_time = val;
                }}
              />
              <span className="field-error text-danger">
                {!values.tunnel_time && "This field is required"}
              </span>
            </Form.Group>
          </Grid.Col>
        )}
      </Grid.Row>
    </>
  );

  return (
    <Formik
      enableReinitialize={true}
      initialValues={{
        student_id: studentId ?? "",
        profile_avatar: studentProfileRequirement?.profile_avatar ?? "",
        height: studentProfileRequirement?.height ?? "",
        weight: studentProfileRequirement?.weight ?? "",
        dob: studentProfileRequirement?.dob ?? "",
        email: studentProfileRequirement?.email ?? "",
        phone: phone_init ?? "",
        phone_code: countryCode_init ?? "",
        shirt_size: studentProfileRequirement?.shirt_size ?? "",
        nac: studentProfileRequirement?.nac ?? "",
        gender: studentProfileRequirement?.gender ?? "",
        jump_count: studentProfileRequirement?.jump_count ?? "",
        tunnel_time: studentProfileRequirement?.tunnel_time ?? "",
        wing_load: studentProfileRequirement?.wing_load ?? "",
        country: studentProfileRequirement?.country ?? "",
      }}
      onSubmit={() => {}}
    >
      {(formikData) => renderForm(formikData)}
    </Formik>
  );
};

export default StudentRegistrationDetailsForm;
