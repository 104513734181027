import Modal from 'components/Modal'
import { observer } from 'mobx-react'
import ConfirmEmailMeritInstructionsButton from 'modules/auth-merit/components/buttons/ConfirmEmailMeritInstructionsButton'
import React, { useState } from 'react'
import { Button, Icon } from 'tabler-react'

const EmailMeritsInstructionsButton = () => {
//  const { user } = useContext(UserStoreContext);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  return (
    <>
      <Button
        color="white"
        className="d-block p-0"
        //  disabled={!user?.email}
        onClick={toggleModal}
        disabled
      >
        <Icon className="mr-2 text-success" name="send" />
        Email Merit Instructions
      </Button>
      <Modal
        actions={
          <Button.List align="center d-flex">
            <Button color="secondary" onClick={toggleModal}>
              No
            </Button>
            <ConfirmEmailMeritInstructionsButton
              toggleConfirmationModal={toggleModal}
            />
          </Button.List>
        }
        content="Are you sure you want to send email merit instructions?"
        open={isModalOpen}
        onClose={toggleModal}
        title="Confirm"
      />
    </>
  );
};

export default observer(EmailMeritsInstructionsButton);
