import gql from 'graphql-tag'

export const CREATE_USER_REGISTRATION_OPTION = gql`
  mutation CREATE_USER_REGISTRATION_OPTION(
    $createUserRegistrationOptionInput: CreateUserRegistrationOptionInput!
  ) {
    createUserRegistrationOption(
      createUserRegistrationOptionInput: $createUserRegistrationOptionInput
    )
  }
`
