import React from 'react';
import {useSortable} from '@dnd-kit/sortable';
import {CSS} from '@dnd-kit/utilities';
import {Icon} from 'tabler-react'


const TeamRegistrationQuestionsItem = ({question, setRegistrationQuestion, handleToggleModal}) => {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    setActivatorNodeRef
  } = useSortable({id: question.id});

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };
  return (
    <div

      ref={setNodeRef} style={style} {...attributes}
    >
      <div style={{width: '80%'}} className={"d-inline-block"}>
        <p className="mb-0">
          <strong className="cursor-pointer" onClick={(e) => {
            e.preventDefault();
            setRegistrationQuestion(question);
            handleToggleModal();
          }}>{question.name}</strong>
        </p>
        <p>{question.description}</p>

      </div>
      <button  {...listeners} ref={setActivatorNodeRef} className={"float-right btn btn-white"}><Icon name={"align-justify"}/></button>



    </div>
  );
};

export default TeamRegistrationQuestionsItem;
