import gql from 'graphql-tag';

export const UPDATE_TRANSACTION = gql`
  mutation UPDATE_TRANSACTION($updateTransactionInput: UpdateTransactionInput!) {
    updateTransaction(updateTransactionInput: $updateTransactionInput){
      id
      created_on
      minutes
      notes
      timeAdded
      teamName
      transactionTypeId
      transactionTypeName
      transactionTypeSlug
      accountTypeSlug
      studentFirstName
      studentLastName
      studentId
      studentProfileAvatar
      coachFirstName
      coachLastName
    }
  }
`;
