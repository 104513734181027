import { useMutation, ApolloError } from '@apollo/client'
import { SEND_ACTIVATION_EMAIL } from 'graphql/SEND_ACTIVATION_EMAIL'
import { toast } from 'react-toastify'

const useSendActivationEmail = () => {
  const [sendActivationEmail] = useMutation(SEND_ACTIVATION_EMAIL, {
    onCompleted: () => toast.success('Activation email sent.'),
    onError: (error: ApolloError) => {
      toast.error(error?.graphQLErrors[0]?.message)
    }
  })

  return {
    sendActivationEmail
  }
}

export default useSendActivationEmail
