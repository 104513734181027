import gql from 'graphql-tag';

export const SEND_EMAIL_INVOICE = gql`
  mutation SEND_EMAIL_INVOICE(
    $invoiceId: Int!
    $studentEmail: String
    $updateStudentEmail: Boolean
  ) {
    sendEmailInvoice(
      invoiceId: $invoiceId
      studentEmail: $studentEmail
      updateStudentEmail: $updateStudentEmail
    )
  }
`;
