import { EventStoreContext } from "contexts/eventStoreContext";
import { observer } from "mobx-react";
import React, { useContext } from "react";
import { Form, Grid } from "tabler-react";

interface EventEntryFormProps {
  mstHandleChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  errorMessages?: string;
}
const EventEntryForm = ({ mstHandleChange, errorMessage }) => {
  const { eventEntry } = useContext(EventStoreContext);

  return (
    <>
      <Grid.Row>
        <Grid.Col xs="auto">
          <Form.Group label="Name">
            <Form.Input
              name="camp_name"
              placeholder="Event Name"
              onChange={mstHandleChange}
              required={true}
            />
          </Form.Group>
        </Grid.Col>
      </Grid.Row>

      <Grid.Row>
        <Grid.Col>
          <div className="form-group">
            <div className="form-label">Start Date</div>
            <input
              className={`form-control ${errorMessage ? "is-invalid" : ""}`}
              type="date"
              name="start_date"
              value={eventEntry.start_date}
              onChange={mstHandleChange}
              required={true}
            />
            <div className="invalid-feedback">{errorMessage}</div>
          </div>
        </Grid.Col>
        <Grid.Col>
          <div className="form-group">
            <div className="form-label">End Date</div>
            <input
              className="form-control"
              type="date"
              name="end_date"
              value={eventEntry.end_date}
              onChange={mstHandleChange}
              required={true}
            />
          </div>
        </Grid.Col>
      </Grid.Row>

      {!eventEntry.all_day && (
        <Grid.Row>
          <Grid.Col>
            <div className="form-group">
              <div className="form-label">Start Time</div>
              <input
                className="form-control"
                type="time"
                name="start_time"
                step="900"
                value={eventEntry.start_time}
                onChange={mstHandleChange}
                required={true}
              />
            </div>
          </Grid.Col>
          <Grid.Col>
            <div className="form-group">
              <div className="form-label">End Time</div>
              <input
                className="form-control"
                type="time"
                name="end_time"
                step="900"
                value={eventEntry.end_time}
                onChange={mstHandleChange}
                required={true}
              />
            </div>
          </Grid.Col>
        </Grid.Row>
      )}
    </>
  );
};

export default observer(EventEntryForm);
