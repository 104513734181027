import { useQuery } from '@apollo/client';
import UserCard from 'components/User/Profile/UserCard';
import { GET_STUDENT_BY_TOKEN } from 'graphql/GET_STUDENT_BY_TOKEN';
import { GET_STUDENT_BY_TOKEN as GET_STUDENT_BY_TOKEN_TYPE } from 'graphql/types/GET_STUDENT_BY_TOKEN';
import React from 'react';
import { useParams } from 'react-router';
import { Grid } from 'tabler-react';

import Wrapper from '../layout';

const UserDetailsPage = (props) => {
  const { token } = useParams<{ token: string }>();

  const { data } = useQuery<GET_STUDENT_BY_TOKEN_TYPE>(GET_STUDENT_BY_TOKEN, {
    variables: {
      token
    }
  });

  return (
    <Wrapper {...props} title="Profile">
      <div className="container">
        <Grid.Row>
          <Grid.Col lg={4} sm={12} xs={12}>
            <UserCard
              firstName={data?.getStudentByToken?.first_name}
              lastName={data?.getStudentByToken?.last_name}
              profileAvatar={data?.getStudentByToken?.profile_avatar}
              profilePicture={data?.getStudentByToken?.profile_picture}
              goToStudentDetails={true}
              studentData={data?.getStudentByToken}
            />
          </Grid.Col>
        </Grid.Row>
      </div>
    </Wrapper>
  );
};

export default UserDetailsPage;
