import React from 'react';
import { Container } from 'tabler-react';

import TeamDetails from '../../components/Team/Form/TeamEdit';
import { TeamStoreProvider } from '../../contexts/teamStoreContext';
import Wrapper from '../layout';

const TeamDetailsPage = (props) => {
  return (
    <Wrapper {...props} title="Team Details">
      <Container>
        <TeamStoreProvider>
          <TeamDetails />
        </TeamStoreProvider>
      </Container>
    </Wrapper>
  );
};
export default TeamDetailsPage;
