import { observer } from 'mobx-react'
import SiteLogo from 'pages/layout/SiteLogo'
import { useState } from 'react'
import { Link } from 'react-router-dom'
import StudentSignupGearRig from '../Gear/StudentSignupGearRig'
import StudentSignupActivity from '../Students/Forms/StudentSignupActivity'
import StudentSignupForm from '../Students/Forms/StudentSignupForm'
import StudentSignupMerits from '../Students/Forms/StudentSignupMerits'

const StudentSignup = () => {
  const [step, setStep] = useState(0)

  return (
    <>
      {step === 0 && (
        <div className="col col-login mx-auto">
          <div className="text-center mb-6">
            <Link to="/">
              <SiteLogo />
            </Link>
          </div>
          <StudentSignupForm setStep={setStep} />
        </div>
      )}

      {step === 1 && (
        <div className="col mx-auto">
          <div className="text-center mb-6">
            <Link to="/">
              <SiteLogo />
            </Link>
          </div>
          <StudentSignupActivity setStep={setStep} />
        </div>
      )}

      {step === 2 && (
        <div className="col mx-auto">
          <div className="text-center mb-6">
            <Link to="/">
              <SiteLogo />
            </Link>
          </div>
          <StudentSignupGearRig setStep={setStep} />
        </div>
      )}

      {step === 3 && (
        <div className="col mx-auto">
          <div className="text-center mb-6">
            <Link to="/">
              <SiteLogo />
            </Link>
          </div>
          <StudentSignupMerits setStep={setStep} />
        </div>
      )}

      {step === 4 && (
        <div className="col col-login mx-auto text-center">
          <div className="text-center mb-6">
            <Link to="/">
              <SiteLogo />
            </Link>
          </div>
          Thank you!
        </div>
      )}
    </>
  )
}

export default observer(StudentSignup)
