import { useQuery } from "@apollo/client";
import React, { useEffect } from "react";
import Select from "react-select";
import { GET_CAMP_DISCIPLINES } from "../../../../graphql/GET_CAMP_DISCIPLINES";

interface CampDisciplineSelectProps {
  campId: string;
  setCampDisciplines: (_: any) => void;
}

const CampDisciplineSelect = ({
  campId,
  setCampDisciplines,
}: CampDisciplineSelectProps) => {
  const [selectedDisciplines, setSelectedDisciplines] = React.useState([]);
  const [options, setOptions] = React.useState([]);

  const { data: campDisciplinesData } = useQuery(GET_CAMP_DISCIPLINES, {
    variables: { campId },
  });
  useEffect(() => {
    if (campDisciplinesData?.campDisciplines) {
      const selected = campDisciplinesData.campDisciplines
        .filter((discipline) => discipline.isExists)
        .map((discipline) => discipline.disciplineId);
      setSelectedDisciplines(selected);
      setCampDisciplines(selected);
      setOptions(
        campDisciplinesData?.campDisciplines.map((discipline) => ({
          label: discipline.name,
          value: discipline.disciplineId,
        })),
      );
    }
  }, [campDisciplinesData]);

  const changeDisciplineHandle = (selectedDisciplinesValue) => {
    const selected = selectedDisciplinesValue.map(
      (discipline) => discipline.value,
    );
    setSelectedDisciplines(selected);
    setCampDisciplines(selected);
  };
  return (
    <Select
      options={options}
      isMulti
      onChange={changeDisciplineHandle}
      value={options.filter((role) => selectedDisciplines.includes(role.value))}
    />
  );
};

export default CampDisciplineSelect;
