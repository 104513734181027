import Modal from 'components/Modal';
import React from 'react';
import { Button } from 'tabler-react';

interface DeletePaymentModalProps {
  isModalOpen: boolean;
  onConfirm: () => void;
  toggleModal: () => void;
}

const DeletePaymentModal = ({
  isModalOpen,
  onConfirm,
  toggleModal
}: DeletePaymentModalProps) => {
  return (
    <Modal
      actions={
        <Button.List align="center">
          <Button pill color="white" size="sm" onClick={toggleModal}>
            CANCEL
          </Button>
          <Button pill color="danger" size="sm" onClick={onConfirm}>
            DELETE
          </Button>
        </Button.List>
      }
      content="Are you sure you want to delete this payment?"
      open={isModalOpen}
      title=""
      onClose={toggleModal}
    />
  );
};

export default DeletePaymentModal;
