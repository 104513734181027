import gql from 'graphql-tag';

export const GET_USER_ROLES = gql`
  query GET_USER_ROLES($params: UserRoleByTeamIdParams) {
    retrieveUserRoleOptions {
      id
      key
      name
    }
    getUserRolesByTeamId(params: $params) {
      id
      key
      name
      compensation
    }
  }
`;
