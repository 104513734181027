import gql from 'graphql-tag';

export const GET_TRANSACTIONS = gql`
  query GET_TRANSACTIONS(
    $limit: Int!
    $page: Int!
    $filter: PaginateUserEventsFilter
  ) {
    getTransactions(limit: $limit, page: $page, filter: $filter) {
      transactions {
        id
        created_on
        minutes
        notes
        timeAdded
        teamName
        teamId
        transactionTypeId
        transactionTypeName
        transactionTypeSlug
        accountTypeSlug
        studentFirstName
        studentLastName
        studentId
        studentProfileAvatar
        coachFirstName
        coachLastName
      }
      page
      totalCount
    }
  }
`;
