import gql from 'graphql-tag';

export const GET_GEARS_ADMIN = gql`
    query GET_GEARS_ADMIN($getAllUserGearOptionsAdmin: GetAllUserGearOptionsAdmin!) {
        getallUserGears(getAllUserGearOptionsAdmin: $getAllUserGearOptionsAdmin) {
            id
            name
            student_id
            team_id
            student{
                address1
                address2
                city
                country
                dob
                email
                first_name
                id
                jump_weight
                last_name
                phone_number
                phone_country_code
                postal_code
                profile_avatar
                province
                registration_id
                weight

                uspa {
                    merits_uspa_member_id
                    merits_uspa_member_expiration
                    template_id
                    template_title
                }
            }

            team{
                id
                name
            }
            gear_model{
                id
                name
                gear_type_id
                gear_manufacturer_id
                gear_type_name
                gear_manufacturer_name
            }
            gear_dom
            gear_canopy_size
            gear_serial
            gear_notes
            total
        }
    }
`;
