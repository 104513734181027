import React from 'react';
import Modal from '../Modal';
import { Button, Form } from 'tabler-react';
import { Formik } from 'formik';
import { useMutation } from '@apollo/client';
import { UPDATE_JOB } from '../../graphql/UPDATE_JOB';
import { GET_JOB_LIST } from '../../graphql/GET_JOB_LIST';

interface JobNotesProps {
  job: any;
  toggleModal: any;
  isModalOpen: any;
  invoice_id?: number;
}

const JobNotesEditForm = ({ job, toggleModal, isModalOpen, invoice_id }: JobNotesProps) => {

  const [updateJob] = useMutation(UPDATE_JOB);
  const renderForm = ({ values, handleSubmit, handleChange, errors, setFieldValue }) => (
    <>
      <Form.Textarea
        name='job_notes'
        placeholder='Job notes'
        rows={3}
        type='text'
        onChange={handleChange}
        value={values.job_notes}
      />
      {errors.job_notes && (
        <span className='field-error text-danger'>{errors.job_notes}</span>
      )}
      <Button.List className='mt-4 mb-7 float-right' align='right'>
        <Button
          pill
          color='white'
          size='sm'
          onClick={(e) => {
            e.preventDefault();
            toggleModal(!isModalOpen);
          }
          }
        >
          CANCEL
        </Button>
        <Button
          pill
          type='submit'
          form='registrationRequirementForm'
          className='btn btn-primary'
          color='gray-dark'
          size='sm'
          onClick={() => handleSubmit()}
        >
          SAVE
        </Button>
      </Button.List>

    </>);

  return (<Modal
    content={
      <>
        <Formik
          enableReinitialize={true}
          initialValues={{
            id: job.id,
            job_notes: job.job_notes
          }}
          onSubmit={async (values, { setFieldValue, resetForm }) => {

            await updateJob({
              variables: {
                updateJobInput: {
                  ...values
                }
              },
              refetchQueries: [
                {
                  query: GET_JOB_LIST,
                  variables: {
                    jobListFilter: {
                      invoice_id: invoice_id
                    }
                  }
                }
              ]
            });
            resetForm();
            toggleModal(!isModalOpen);

          }}
        >
          {(formikData) => renderForm(formikData)}
        </Formik>
      </>
    }
    open={isModalOpen}
    onClose={() => toggleModal(!isModalOpen)}
  />);
};

export default JobNotesEditForm;
