import { useMutation } from "@apollo/client";
import { Formik } from "formik";
import { ADD_STUDENT_ORG_CREDENTIAL } from "graphql/ADD_STUDENT_ORG_CREDENTIAL";
import { EDIT_STUDENT_ORG_CREDENTIAL } from "graphql/EDIT_STUDENT_ORG_CREDENTIAL";
import { useRootStore } from "hooks";
import { useGetUserIsStudent } from "modules/common/hooks/useGetCurrentUserType";
import moment from "moment";
import { toast } from "react-toastify";
import { Button, Form } from "tabler-react";
import * as Yup from "yup";

type AddMembershipFormProps = {
  orgId: number;
  orgName: string;
  studentId: number;
  membership?: string;
  expires?: string;
  id?: number;
  toggle?: () => void;
};

const AddMembershipFormValidationSchema = Yup.object().shape({
  membership: Yup.string().required("Member number is required"),
  expires: Yup.date().required("Expiration date is required"),
});
const AddMembershipForm = ({
  orgId,
  orgName,
  studentId,
  membership,
  expires,
  id,
  toggle,
}: AddMembershipFormProps) => {
  const isStudent = useGetUserIsStudent();
  const currentUser = useRootStore().currentUser;
  const [addStudentOrgCredential] = useMutation(ADD_STUDENT_ORG_CREDENTIAL, {
    refetchQueries: ["GET_ORG_CREDENTIALS", "GET_USER_REGISTRATIONS_BY_IDS"],
    onCompleted: () => {
      toast.success("Credential added successfully");
    },
  });

  const [updateOrgCredential] = useMutation(EDIT_STUDENT_ORG_CREDENTIAL, {
    refetchQueries: ["GET_ORG_CREDENTIALS", "GET_USER_REGISTRATIONS_BY_IDS"],
    onCompleted: () => {
      toast.success("Credential added successfully");
    },
  });

  const renderForm = ({
    values,
    errors,
    touched,
    handleChange,
    handleSubmit,
  }) => {
    return (
      <Form onSubmit={handleSubmit}>
        <Form.Group label={orgName}></Form.Group>
        <Form.Group label="Membership Number">
          <Form.Input
            name="membership"
            value={values.membership}
            onChange={handleChange}
          />
          <span className="field-error text-danger">
            {errors.membership && touched.membership && errors.membership}
          </span>
        </Form.Group>
        <Form.Group label="Expires">
          <Form.Input
            valid={moment().isBefore(values.expires)}
            tick={moment().isBefore(values.expires)}
            invalid={moment().isAfter(values.expires)}
            cross={moment().isAfter(values.expires)}
            type="date"
            name="expires"
            value={values.expires}
            onChange={handleChange}
            disabled={isStudent && values.id}
          />
          <span className="field-error text-danger">
            {errors.expires && touched.expires && errors.expires}
          </span>
        </Form.Group>
        <Button pill size="sm" color="gray-dark" className={"float-right"}>
          SUBMIT
        </Button>
      </Form>
    );
  };
  return (
    <Formik
      validationSchema={AddMembershipFormValidationSchema}
      enableReinitialize={true}
      initialValues={{
        org_id: orgId,
        student_id: studentId,
        membership: membership || "",
        expires: expires || "",
        verified_on: null,
        verified_by: null,
      }}
      onSubmit={async (values, { setSubmitting }) => {
        values.expires = moment(values.expires).format("YYYY-MM-DD");
        if (!isStudent) {
          values.verified_by = currentUser.id.toString();
          values.verified_on = moment().format("YYYY-MM-DD");
        }
        if (!id) {
          await addStudentOrgCredential({
            variables: {
              addOrgCredentialInput: values,
            },
          });
        } else {
          await updateOrgCredential({
            variables: {
              updateOrgCredentialInput: {
                ...values,
                id,
              },
            },
          });
        }
        setSubmitting(false);
        if (typeof toggle === "function") {
          toggle();
        }
      }}
    >
      {(formikData) => renderForm(formikData)}
    </Formik>
  );
};

export default AddMembershipForm;
