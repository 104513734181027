import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { Avatar, Dropdown, Grid, Text, Icon } from 'tabler-react';
import useReactRouter from 'use-react-router';

import { UserStoreContext } from '../../contexts/userStoreContext';
import { Types } from '../../types/graphql'

interface IRecentStudentListItemProps {
  student: Types.Student;
}

const MAX_TEXT_LENGTH = 25;


const RecentStudentListItem = ({ student }: IRecentStudentListItemProps) => {
  const userStore = useContext(UserStoreContext);

  const { history } = useReactRouter();

  const routeToStudent = (student: Types.Student) => {
    userStore.loadUser(student);
    history.push('/user-details');
  };

  const fullName = student.first_name + ' ' + student.last_name;

  return (
    <li className="list-separated-item">
      <Grid.Row className="align-items-center">
        <Grid.Col>
          <div>
            <Link
              className="text-inherit d-block"
              onClick={(e) => {
                e.preventDefault();
                routeToStudent(student);
              }}
              to="#"
            >
              <Avatar
              status={student.registration_id && ('success')}
              size="md"
              className="float-left mr-3"
              imageURL={
                (student.profile_avatar &&
                  `/api/s3/uploads/${student.profile_avatar}`) ||
                '//www.gravatar.com/avatar?d=mp'
              }
            />
            {fullName.length > MAX_TEXT_LENGTH ? (
                <>
                  <span
                    dangerouslySetInnerHTML={{
                      __html: fullName.substring(
                        0,
                        MAX_TEXT_LENGTH
                      )
                    }}
                  />
                  ...
                </>
              ) : (
                fullName
              )}
            </Link>
          </div>
            <>
            <Text.Small className="">
              {student.email && <a href={'mailto:' + student.email}><Icon name='mail' className='mr-2' /></a>}
              {student.phone_number && <a href={'tel:' + student.phone_number}><Icon name='phone' /></a>}
              <span className='text-muted ml-2'>{student.city && student.city + ', '}
              {student.province?.length > MAX_TEXT_LENGTH ? (
                <>
                  <span
                    dangerouslySetInnerHTML={{
                      __html: student.province.substring(
                        0,
                        MAX_TEXT_LENGTH
                      )
                    }}
                  />
                  ...
                </>
              ) : (
                student.province
              )}
              </span>
            </Text.Small>
            </>
        </Grid.Col>
        <Grid.Col auto>
          <Dropdown
            trigger={<Dropdown.Trigger icon="more-vertical" toggle={false} />}
            position="right"
            items={
              <>
                <Dropdown.Item
                  className="cursor-pointer"
                  icon="user"
                  onClick={(e: { preventDefault: () => void }) => {
                    e.preventDefault();
                    routeToStudent(student);
                  }}
                >
                  View
                </Dropdown.Item>
                <Dropdown.Item
                  icon="mail"
                >
                  {student.email && <a href={'mailto:' + student.email}>Email</a>}
                </Dropdown.Item>
                <Dropdown.Item
                  icon="phone"
                >
                  {student.phone_number && <a href={'tel:' + student.phone_number}>Phone</a>}
                </Dropdown.Item>
                <Dropdown.ItemDivider />
                <Dropdown.Item
                  icon="x-circle text-danger"
                  to="#!"
                  className="text-danger"
                >
                  Disable
                </Dropdown.Item>
              </>
            }
          />
        </Grid.Col>
      </Grid.Row>
    </li>
  );
};

export default RecentStudentListItem;
