import Modal from 'components/Modal';
import React, { FC } from 'react';
import { Button } from 'tabler-react';

interface RefundPaymentModalProps {
  isModalOpen: boolean;
  onConfirm: () => void;
  toggleModal: () => void;
}

const RefundPaymentModal: FC<RefundPaymentModalProps> = ({
  isModalOpen,
  onConfirm,
  toggleModal
}) => (
  <Modal
    actions={
      <Button.List align="center">
        <Button pill color="white" size="sm" onClick={toggleModal}>
          CANCEL
        </Button>
        <Button pill color="danger" size="sm" onClick={onConfirm}>
          REFUND
        </Button>
      </Button.List>
    }
    content="Are you sure you want to refund this payment?"
    open={isModalOpen}
    title=""
    onClose={toggleModal}
  />
);

export default RefundPaymentModal;
