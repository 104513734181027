import { useQuery } from '@apollo/client';
import AccountsInvoice from "components/Payments/AccountsInvoice";
import AccountBalance from "components/User/AccountBalance";
import { GET_ACCOUNT_BALANCE } from "graphql/GET_ACCOUNT_BALANCE";
import { GET_STUDENT_TIME_TRANSACTION_HISTORY } from "graphql/GET_STUDENT_TIME_TRANSACTION_HISTORY";
import { GET_TEAMS_BY_STUDENT_ID } from "graphql/GET_TEAMS_BY_STUDENT_ID";
import { GET_ACCOUNT_BALANCE as GET_ACCOUNT_BALANCE_TYPE } from "graphql/types/GET_ACCOUNT_BALANCE";
import {
  GET_STUDENT_TIME_TRANSACTION_HISTORY as GET_STUDENT_TIME_TRANSACTION_HISTORY_TYPE,
} from "graphql/types/GET_STUDENT_TIME_TRANSACTION_HISTORY";
import { GET_TEAMS_BY_STUDENT_ID as GET_TEAMS_BY_STUDENT_ID_TYPE } from "graphql/types/GET_TEAMS_BY_STUDENT_ID";
import { useRootStore } from "hooks/useRootStore";
import StudentPayments from "modules/student/components/home/StudentPayments";
import moment from "moment";
import Wrapper from "pages/layout";
import React, { useEffect, useMemo, useState } from "react";
import {
  Alert,
  Card,
  Container,
  Dimmer,
  Form,
  Grid,
  Icon,
  Table,
  Text,
  Tooltip,
} from "tabler-react";
import { formatAccountBalance } from "utils/formatAccountBalance";
import { Types } from '../../types/graphql'

const TransactionIcons = {
  cancel: "x",
  used: "minus",
  expiry: "minus",
  purchased: "plus",
  credit: "plus",
  purchase: "credit-card",
  transfer: "arrow-right",
};

const StudentAccountsPage = (props) => {
  const { currentUser } = useRootStore();

  const { data, loading } = useQuery<GET_TEAMS_BY_STUDENT_ID_TYPE>(
    GET_TEAMS_BY_STUDENT_ID,
    {
      variables: {
        student_id: currentUser?.id.toString(),
      },
    }
  );

  const teamsRow = useMemo(
    () =>
      data?.getTeamsByStudentId.map(({ id, name }) => ({
        value: id,
        label: name,
      })) ?? [], // Eslint-disable-next-line
    [data?.getTeamsByStudentId, loading]
  );

  const [teamId, setTeamId] = useState(null);

  // Set teamId on first load
  useEffect(() => {
    if (teamsRow.length > 0) {
      setTeamId(teamsRow[0]?.value);
    }
  }, [teamsRow]);

  const { data: accountBalance } = useQuery<GET_ACCOUNT_BALANCE_TYPE>(
    GET_ACCOUNT_BALANCE,
    {
      variables: {
        studentId: currentUser?.id.toString(),
        teamId: Number(teamId),
      },
    }
  );

  const { data: transactions, loading: transactionsLoading } =
    useQuery<GET_STUDENT_TIME_TRANSACTION_HISTORY_TYPE>(
      GET_STUDENT_TIME_TRANSACTION_HISTORY,
      {
        variables: {
          studentId: currentUser?.id.toString(),
          teamId: Number(teamId),
          userType: currentUser.type,
        },
        fetchPolicy: "network-only",
      }
    );

  // Group by year, sort by latest year
  const transactionHistoryByYear = useMemo(() => {
    const grouped = transactions?.getStudentTimeTransactionHistory.reduce(
      (acc, transaction) => {
        const year = moment(transaction.createdOn).year();
        const yearGroup = acc[year] ?? [];

        return {
          ...acc,
          [year]: [...yearGroup, transaction],
        };
      },
      {}
    );

    return Object.entries(grouped ?? {})
      .sort(([a], [b]) => parseInt(b) - parseInt(a))
      .map(([year, transactions]) => ({
        year,
        transactions,
      }));
    // Eslint-disable-next-line
  }, [transactions?.getStudentTimeTransactionHistory]);

  return (
    <Wrapper {...props} title="Accounts">
      <Container>
        <Grid.Row>
          <Grid.Col>
            <Card>
              <Card.Header>
                <Card.Title>
                  <Icon name="book" className="mr-2 ml-0 text-blue" />
                  Accounts
                </Card.Title>
                <Card.Options>
                  {!!teamId && (
                    <Form.Select
                      name="manual_payment"
                      onChange={(e) => {
                        e.preventDefault();
                        setTeamId(e.target.value);
                      }}
                    >
                      {teamsRow.map((team) => (
                        <option value={team.value} key={team.value}>
                          {team.label}
                        </option>
                      ))}
                    </Form.Select>
                  )}
                </Card.Options>
              </Card.Header>
              {accountBalance?.getAccountBalance?.total_time && (
                <Card.Header className="gap-1">
                  Available Balance:
                  <AccountBalance
                    accountBalance={
                      accountBalance?.getAccountBalance?.total_time ?? 0
                    }
                    slug={accountBalance?.getAccountBalance?.account_type_slug}
                  />
                </Card.Header>
              )}
            </Card>
          </Grid.Col>
        </Grid.Row>
        {!teamId && (
          <Grid.Row>
            <Grid.Col>
              <Alert type="info text-center">
                <strong>No Accounts</strong>
              </Alert>
            </Grid.Col>
          </Grid.Row>
        )}
        {teamId && (
          <Grid.Row>
            <>
              <Grid.Col lg={4} sm={12} xs="auto">
                <StudentPayments teamId={Number(teamId)} />
                <AccountsInvoice teamId={Number(teamId)} />
              </Grid.Col>
            </>
            <Grid.Col>
              <Card statusColor="gray-dark">
                <Card.Header>
                  <Card.Title>Transactions</Card.Title>
                </Card.Header>
                <Dimmer
                  active={transactionsLoading}
                  loader={transactionsLoading}
                >
                  <Table className="card-table">
                    <Table.Body>
                      {transactionHistoryByYear.length > 0 ? (
                        transactionHistoryByYear.map(
                          ({
                            year,
                            transactions,
                          }: {
                            transactions: Types.StudentTimeTransactionHistory[];
                            year: string;
                          }) => (
                            <React.Fragment key={year}>
                              <Table.Row className="bg-light">
                                <Table.ColHeader>{year}</Table.ColHeader>
                                <Table.ColHeader></Table.ColHeader>
                                <Table.ColHeader className="text-right"></Table.ColHeader>
                              </Table.Row>
                              {transactions.map((transaction) => (
                                <Table.Row key={transaction.id}>
                                  <Table.Col>
                                    <Icon
                                      className={`float-left mr-3 mt-3 ${
                                        transaction.transactionSlug === "used"
                                          ? "text-secondary"
                                          : transaction.transactionSlug ===
                                            "purchased"
                                          ? "text-success"
                                          : transaction.transactionSlug ===
                                            "purchase"
                                          ? "text-success"
                                          : "text-muted"
                                      }`}
                                      name={
                                        TransactionIcons[
                                          transaction.transactionSlug
                                        ]
                                      }
                                    />
                                    <Text
                                      className="d-flex flex-column"
                                      muted={
                                        transaction.transactionSlug === "cancel"
                                      }
                                    >
                                      <span>
                                        {new Intl.DateTimeFormat("en-US", {
                                          month: "short",
                                          day: "numeric",
                                        }).format(
                                          new Date(transaction.createdOn)
                                        )}
                                      </span>
                                      <div className="d-flex flex-row align-items-center gap-1">
                                        <Text.Small>
                                          {new Intl.DateTimeFormat("en-US", {
                                            hour: "numeric",
                                            minute: "numeric",
                                          }).format(
                                            new Date(transaction.createdOn)
                                          )}{" "}
                                          | {transaction.coachFirstName}{" "}
                                          {transaction?.transactionName
                                            ? `- ${
                                                transaction.transactionSlug ===
                                                "cancel"
                                                  ? `voided by ${
                                                      transaction.coachFirstName
                                                    } ${
                                                      transaction.coachLastName
                                                    } ${
                                                      transaction?.cancelledOn
                                                        ? `on ${new Intl.DateTimeFormat(
                                                            "en-US",
                                                            {
                                                              month: "2-digit",
                                                              day: "2-digit",
                                                              year: "2-digit",
                                                              hour: "numeric",
                                                              minute: "numeric",
                                                            }
                                                          ).format(
                                                            new Date(
                                                              transaction.cancelledOn
                                                            )
                                                          )}`
                                                        : ""
                                                    }`
                                                  : transaction.transactionName
                                              }`
                                            : ""}
                                        </Text.Small>
                                        {transaction?.notes ? (
                                          <Tooltip
                                            content={transaction.notes}
                                            placement="top"
                                          >
                                            <span>
                                              <Icon name="file" thin={true} />
                                            </span>
                                          </Tooltip>
                                        ) : (
                                          ""
                                        )}
                                      </div>
                                    </Text>
                                  </Table.Col>
                                  <Table.Col></Table.Col>
                                  <Table.Col className="text-right">
                                    <Text
                                      color={
                                        transaction.timeAdded
                                          ? "success"
                                          : "secondary"
                                      }
                                      muted={
                                        transaction.transactionSlug === "cancel"
                                      }
                                    >
                                      {transaction.timeAdded ? "+" : "-"}
                                      {formatAccountBalance(
                                        transaction.minutes,
                                        accountBalance?.getAccountBalance
                                          ?.account_type_slug
                                      )}
                                    </Text>
                                  </Table.Col>
                                </Table.Row>
                              ))}
                            </React.Fragment>
                          )
                        )
                      ) : (
                        <Table.Row>
                          <Table.Col colSpan="8">
                            <Alert type="info text-center">
                              <strong>No Transactions</strong>
                            </Alert>
                          </Table.Col>
                        </Table.Row>
                      )}
                    </Table.Body>
                  </Table>
                </Dimmer>
              </Card>
              <Text.Small className="text-muted">
                This team has access to some of your personal information,
                including your name, phone number, and skydiving credentials (if
                available). If you wish to no longer share this information,
                please{" "}
                <a href="/help" target="_blank" rel="noopener noreferrer">
                  contact us
                </a>
                . You can also read more about our privacy policy{" "}
                <a
                  href="http://www.skycru.com/privacy"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  here
                </a>
                .
              </Text.Small>
            </Grid.Col>
          </Grid.Row>
        )}
      </Container>
    </Wrapper>
  );
};

export default StudentAccountsPage;
