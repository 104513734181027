import React from "react";
import { observer } from "mobx-react";
import { useRootStore } from "hooks";

// Handle Page Load State
export const Page: React.FC = observer(({ children }) => {
  const { pageState } = useRootStore();

  if (pageState !== "START" && pageState !== "LOADING") {
    return <>{children}</>;
  }
});
