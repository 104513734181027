import gql from 'graphql-tag';

export const ADD_USER_RESERVE_LOGS = gql`
  mutation ADD_USER_RESERVE_LOGS($reserveLog: ReserveLogInput!) {
    createReserveLog(reserveLog: $reserveLog) {
      id
      event_date
      user_id
      student_id
      user_gear_id
      coach_id
      reserve_notes
      location_id
      total
      rigger_id
      team_id
      user_rigger_seal_id
      user_rigger_seal{
        id
        rigger_seal
        coach{
          first_name
          last_name
        }
      }
      student{
        id
        address1
        address2
        city
        country
        province
        dob
        email
        first_name
        jump_weight
        last_name
        phone_country_code
        phone_number
        phone_verified
        postal_code
        registration_code
        registration_id
        weight

        uspa {
          merits_uspa_member_id
          merits_uspa_member_expiration
          template_id
          template_title
        }
      }
      coach{
        first_name
        last_name
        id
      }
      rigger{
        first_name
        last_name
        id
      }
      team{
        name
        id
      }
      location{
        id
        name
      }
    }
  }
`;
