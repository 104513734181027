import { useMutation } from '@apollo/client'
import { observer } from 'mobx-react'
import moment from 'moment/moment'
import React, { useState } from 'react'
import { toast } from 'react-toastify'
import { Button, Card, Dropdown, Icon, Text } from 'tabler-react'
import useGetCurrentUserType from 'modules/common/hooks/useGetCurrentUserType'
import { useRootStore } from 'hooks'
import { DELETE_USER_POST } from 'graphql/DELETE_USER_POST'
import { GET_USER_POSTS } from 'graphql/GET_USER_POSTS'
import { DEFAULT_LIMIT } from 'components/constants'
import UserPostEditForm from 'components/User/Forms/UserPostEditForm'
import { EUserTypes } from 'components/User/userTypes'
import {
  IconBrandWhatsapp,
  IconMail,
  IconMessage,
  IconPhone
} from '@tabler/icons-react'
import ReactHtmlParser from 'react-html-parser'
import * as DOMPurify from 'dompurify'

interface IUserPostListItemProps {
  userPost: any
}

const UserPostListItem = ({ userPost }: IUserPostListItemProps) => {
  const { currentUser } = useRootStore();
  const userPostClone = { ...userPost };
  const [editting, setEditting] = useState(false);

  const isAuthorPost = currentUser.id === userPostClone.coach_id;
  const { isAdmin, isCoachAdmin } = useGetCurrentUserType();

  const handleEditLink = (param) => {
    setEditting(param);
  };

  const author = userPostClone?.coach
    ? userPostClone?.coach.first_name + ' ' + userPostClone?.coach.last_name
    : userPostClone?.student.first_name +
      ' ' +
      userPostClone?.student.last_name;

  const [deleteUserPost] = useMutation(DELETE_USER_POST, {
    onCompleted: (result) => {
      if (result.deleteUserPost) {
        toast.success('Post deleted!');
      }
    }
  });

  const deletePost = () => {
    if (window.confirm('Delete post?')) {
      deleteUserPost({
        variables: {
          id: userPost.id
        },
        refetchQueries: [
          {
            query: GET_USER_POSTS,
            variables: {
              userPostOptions: {
                camp_id: userPost.camp_id,
                limit: DEFAULT_LIMIT,
                offset: 0
              }
            }
          }
        ]
      });
    }
  };

  return (
    <>
      <div>
        <Card className="p-0 mb-0">
          <Card.Body className="pl-3 pr-3">
            {editting ? (
              <UserPostEditForm
                userPost={userPostClone}
                setEditting={setEditting}
              />
            ) : (
              <>
                {currentUser.type !== EUserTypes.student && (
                  <Dropdown
                    className="btn btn-white text-muted btn-sm pl-0 pr-0 float-right"
                    toggle={false}
                    icon="more-vertical"
                    isNavLink={true}
                    position="bottom-end"
                    arrow={true}
                    arrowPosition="right"
                    items={
                      <>
                        {[
                          userPostClone.coach_id,
                          userPostClone.student_id
                        ].includes(currentUser.id) && (
                          <Dropdown.Item onClick={() => handleEditLink(true)}>
                            <Icon name="edit" /> Edit
                          </Dropdown.Item>
                        )}

                        {(isAdmin || isAuthorPost || isCoachAdmin) && (
                          <Dropdown.Item onClick={() => deletePost()}>
                            <Icon name="trash" /> Delete
                          </Dropdown.Item>
                        )}
                      </>
                    }
                  />
                )}
                <h6 className="mt-2">{author}</h6>
                <Text
                  className="cursor-pointer ws-prewrap textEditor-display"
                  onClick={() => handleEditLink(true)}
                >
                  {ReactHtmlParser(DOMPurify.sanitize(userPost.content))}
                </Text>
              </>
            )}
            {isAdmin && (
              <Button.List align="right" className="mr-3">
                {userPostClone.email && ''}
                <IconMail
                  size={20}
                  className={
                    userPostClone.email
                      ? 'text-info cursor-pointer'
                      : ' text-muted ml-4'
                  }
                  onClick={(e) => {
                    e.preventDefault()
                    window.location.href = 'mailto:' + userPostClone.email
                  }}
                />
                <IconPhone
                  size={20}
                  className={
                    userPostClone.phone_number
                      ? 'text-info cursor-pointer'
                      : ' text-muted ml-4'
                  }
                  onClick={(e) => {
                    e.preventDefault()
                    window.location.href = 'tel:' + userPostClone.phone_number
                  }}
                />
                <IconMessage
                  size={20}
                  className={
                    userPostClone.phone_number
                      ? 'text-info cursor-pointer'
                      : ' text-muted ml-4'
                  }
                  onClick={(e) => {
                    e.preventDefault()
                    window.location.href = 'sms:' + userPostClone.phone_number
                  }}
                />
                <IconBrandWhatsapp
                  size={20}
                  className={
                    userPostClone.whatsapp_number
                      ? 'text-info cursor-pointer'
                      : ' text-muted  ml-4'
                  }
                  onClick={(e) => {
                    e.preventDefault()
                    window.location.href = 'https://wa.me/18588881152'
                  }}
                />
              </Button.List>
            )}
          </Card.Body>
        </Card>
        <Text.Small className="float-left text-muted m-1 pb-3">
          <i className="fe fe-calendar mr-2 text-muted" />
          {moment(userPost.created_on).format('MMM DD, YYYY - h:mm')}
          {userPost.updated_on && ' Edited'}
        </Text.Small>
      </div>
    </>
  )
};

export default observer(UserPostListItem);
