import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { EUserTypes } from '../User/userTypes';
import { useQuery } from '@apollo/client';
import { useRootStore } from '../../hooks';
import { Card, Dimmer, Form, Grid, Icon } from 'tabler-react';
import JobGearStorageItem from './JobGearStorageItem';
import { GET_JOBS_BY_STORAGE } from 'graphql/GET_JOBS_BY_STORAGE';
import { Types } from '../../types/graphql'

const JobGearStorage = () => {
  const rootStore = useRootStore();
  const { currentUser, currentCoachTeam } = rootStore;

  const [jobs, setJobs] = useState<Types.JobContainer[]>([]);
  const [nameFilter, setNameFilter] = useState('');

  const filterOption =
    currentUser.type !== EUserTypes.admin
      ? { team_id: currentCoachTeam?.id }
      : {};
  const { loading, data } = useQuery(GET_JOBS_BY_STORAGE, {
    variables: {
      jobListFilter: {
        order: 'asc',
        ...filterOption
      }
    }
  });

  const groupBy = (data) => {
    return data.reduce(function (storage, item) {
      if (item?.job_storage) {
        const group = item?.job_storage;
        storage[group] = storage[group] || [];
        storage[group].push(item);
      }
      return storage;
    }, {});
  };

  useEffect(() => {
    if (!loading && data) {
      const jobItems: Types.JobContainer[] = Object.values(
        groupBy(
          data.jobsContainerList.filter((job) => {
            const fullName = `${job.student.first_name.toLowerCase()} ${job.student.last_name.toLowerCase()}`;

            return fullName.includes(nameFilter.toLowerCase());
          })
        )
      );
      setJobs(jobItems);
    }
  }, [data, loading, nameFilter]);
  return (
    <>
      <Grid.Row>
        <Grid.Col width={12}>
          <Card>
            <Card.Header>
              <Card.Title>
                <Icon name="box" className="mr-2 ml-0 text-blue" />
                Storage Bins
              </Card.Title>
              <Card.Options>
                <Form.Group className="mb-0 mr-2">
                  <Form.Input
                    autoFocus
                    type="search"
                    placeholder="Search by customer..."
                    name="search"
                    value={nameFilter}
                    onChange={(e) => {
                      setNameFilter(e.target.value);
                      e.persist();
                    }}
                  />
                </Form.Group>
              </Card.Options>
            </Card.Header>
          </Card>
        </Grid.Col>
      </Grid.Row>
      <Dimmer active={loading} loader={loading}>
        <Grid.Row>
          <Grid.Col width={12}>
            <Grid.Row>
              {jobs?.map((joblist, index) => (
                <Grid.Col key={index} xs={12} sm={12} md={6} lg={4}>
                  <JobGearStorageItem jobList={joblist} />
                </Grid.Col>
              ))}
            </Grid.Row>
          </Grid.Col>
        </Grid.Row>
      </Dimmer>
    </>
  );
};

export default observer(JobGearStorage);
