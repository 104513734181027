import { useQuery } from '@apollo/client'
import { DEFAULT_LIMIT } from 'components/constants'
import { GET_STUDENT_SEARCH_QUERY } from 'graphql/GET_STUDENT_SEARCH_QUERY'
import { GET_STUDENT_SEARCH_QUERY as GET_STUDENT_SEARCH_QUERY_TYPE } from 'graphql/types/GET_STUDENT_SEARCH_QUERY'
import { QueryParams } from 'modules/common/constants/serviceParams'
import { useEffect, useState } from 'react'

const useGetStudentSearchQuery = () => {
  const [queryData, setQueryData] = useState<GET_STUDENT_SEARCH_QUERY_TYPE>()

  const [queryFilter, setQueryFilter] = useState<QueryParams>({
    q: '',
    limit: DEFAULT_LIMIT
  })

  const { data, loading, error, refetch } =
    useQuery<GET_STUDENT_SEARCH_QUERY_TYPE>(GET_STUDENT_SEARCH_QUERY, {
      variables: {
        q: queryFilter.q
      }
    })

  useEffect(() => {
    if (!loading) {
      setQueryData(data)
    }
  }, [data, loading])

  useEffect(() => {
    void refetch()
  }, [queryFilter, refetch])

  return {
    data: queryData,
    loading,
    error,
    queryFilter,
    setQueryFilter
  }
}

export default useGetStudentSearchQuery
