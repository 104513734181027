import React, { FC } from 'react';
import { Button } from 'tabler-react';
import { usePDF } from '@react-pdf/renderer';
import JobsPDF from '../../modules/job/JobsPDF';
import { Types } from '../../types/graphql'

interface PrintInvoiceButtonProps {
  jobList: Types.Job[];
  title: string;
}

const RiggerJobListPdfButton: FC<PrintInvoiceButtonProps> = ({ jobList, title }) => {
  const [instance] = usePDF({
    document: JobsPDF(jobList)
  });

  return (
    <a href={instance.url} download={`Jobs ${title}.pdf`}>
      <Button
        className='mr-2'
        icon='download'
        size='sm'
        color='primary'
      >
        PDF
      </Button>
    </a>
  );
};

export default RiggerJobListPdfButton;
