import { useMutation, useQuery } from "@apollo/client";
import {
  closestCenter,
  DndContext,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import React, { useEffect, useState } from "react";
import { Button, Card } from "tabler-react";
import { GET_REGISTRATION_QUESTIONS } from "../../graphql/GET_REGISTRATION_QUESTIONS";
import { UPDATE_REGISTRATION_QUESTION_SEQUENCE } from "../../graphql/UPDATE_REGISTRATION_QUESTION_SEQUENCE";
import { noData } from "./Form/TeamEdit";
import RegistrationQuestionsModal from "./Modals/RegistrationQuestionsModal";
import TeamRegistrationQuestionsItem from "./TeamRegistrationQuestionsItem";

interface TeamRegistrationQuestionsProps {
  teamId: string;
  team: any;
}
const TeamRegistrationQuestions = ({
  teamId,
  team,
}: TeamRegistrationQuestionsProps) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [registrationQuestion, setRegistrationQuestion] = useState<any>(null);
  const [registrationQuestions, setRegistrationQuestions] = useState<any>([]);
  const [updateSequence] = useMutation(UPDATE_REGISTRATION_QUESTION_SEQUENCE, {
    refetchQueries: ["GET_REGISTRATION_QUESTIONS"],
  });

  function handleDragEnd(event) {
    const { active, over } = event;

    if (active.id !== over.id) {
      setRegistrationQuestions((items) => {
        const oldIndex = items.findIndex((user) => user.id === active.id);
        const newIndex = items.findIndex((user) => user.id === over.id);

        const result = arrayMove(items, oldIndex, newIndex);

        handleUpdateSequence(result);

        return result;
      });
    }
  }

  function handleUpdateSequence(questions) {
    updateSequence({
      variables: {
        questions: questions.map((question, index) => ({
          id: question.id,
          seq: index,
        })),
      },
    });
  }

  const handleToggleModal = () => setIsModalOpen(!isModalOpen);

  const { data: questionData } = useQuery(GET_REGISTRATION_QUESTIONS, {
    variables: {
      filter: {
        teamId,
      },
    },
  });

  useEffect(() => {
    setRegistrationQuestions(questionData?.registrationQuestions ?? []);
  }, [questionData]);

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    }),
  );

  return (
    <Card>
      <Card.Header>
        <Card.Title>
          Registration Questions
        </Card.Title>
        <Card.Options>
          <Button
            outline
            icon="plus"
            color="primary"
            size="sm"
            onClick={(e) => {
              e.preventDefault();
              handleToggleModal();
            }}
          />
        </Card.Options>
      </Card.Header>
      <Card.Body>
        {!registrationQuestions?.length ? (
          noData
        ) : (
          <DndContext
            sensors={sensors}
            collisionDetection={closestCenter}
            onDragEnd={handleDragEnd}
          >
            <SortableContext
              items={registrationQuestions}
              strategy={verticalListSortingStrategy}
            >
              {registrationQuestions.map((question) => (
                <TeamRegistrationQuestionsItem
                  key={question.id}
                  question={question}
                  setRegistrationQuestion={setRegistrationQuestion}
                  handleToggleModal={handleToggleModal}
                />
              ))}
            </SortableContext>
          </DndContext>
        )}
      </Card.Body>
      <RegistrationQuestionsModal
        isModalOpen={isModalOpen}
        handleToggleModal={handleToggleModal}
        registrationQuestion={registrationQuestion}
        setRegistrationQuestion={setRegistrationQuestion}
        team_id={Number(team.id)}
      />
    </Card>
  );
};

export default TeamRegistrationQuestions;
