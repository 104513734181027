import { useMutation, useQuery, ApolloError } from '@apollo/client';
import productImagePlaceholder from 'assets/images/product-image-placeholder.png';
import { Formik } from 'formik';
import { observer } from 'mobx-react';
import { applySnapshot } from 'mobx-state-tree';
import React from 'react';
import ReactS3Uploader from 'react-s3-uploader';
import { toast } from 'react-toastify';
import {
  Button,
  Card,
  Dropdown,
  Form,
  GalleryCard,
  Grid,
  Text
} from 'tabler-react';
import Select from 'react-select';
import { EUserTypes } from 'components/User/userTypes';
import { NumericFormat } from 'react-number-format';
import { useRootStore } from 'hooks';
import { GET_PRODUCT_TYPES } from 'graphql/GET_PRODUCT_TYPES';
import { GET_ALL_ACCOUNT_TYPES } from 'graphql/GET_ALL_ACCOUNT_TYPES';
import { UPDATE_PRODUCT_MUTATION } from 'graphql/UPDATE_PRODUCT_MUTATION';
import FormField from 'components/FormField';
import { GET_PRODUCTS_BY_FILTER_QUERY } from 'graphql/GET_PRODUCTS_BY_FILTER_QUERY';
import { EOrderOptions } from 'hooks/useAutocomplete';
import TextEditor from 'components/text-editor/TextEditor';
import { Types } from '../../../types/graphql'

export const ProductStatus = [
  { label: 'Active', value: 'active' },
  { label: 'Inactive', value: 'inactive' },
  { label: 'Account', value: 'account' }
];

const ProductListItemEdit = ({ product, productClone, setIsEditing }) => {
  const rootStore = useRootStore();
  const { currentCoachTeam, currentUser } = rootStore;

  const { data } = useQuery(GET_PRODUCT_TYPES);
  const { data: accountData } = useQuery(GET_ALL_ACCOUNT_TYPES);

  const [updateProduct] = useMutation(UPDATE_PRODUCT_MUTATION, {
    onError: (error: ApolloError) =>
      toast.error(error?.graphQLErrors[0]?.message)
  });

  const onError = (error) => {
    toast.error(error);
    rootStore.setLoading(false);
  };

  const onFinished = (response: { fileKey: string }) => {
    updateProductImage(response.fileKey);
    rootStore.setLoading(false);
  };

  const updateProductImage = async (fileKey: string | null) =>
    productClone.set('image', fileKey);

  const renderForm = ({
    values,
    handleChange,
    handleSubmit,
    setFieldValue
  }) => (
    <Form onSubmit={handleSubmit}>
      <Grid.Row className="product-details">
        <Grid.Col lg={9} sm={12} xs={12}>
          <Card>
            <Card.Header>
              <Card.Title>
                <FormField
                  name="title"
                  additionalgroupclasses={['mb-3 mt-3']}
                  placeholder="Title"
                  type="text"
                  value={values.title}
                  onChange={handleChange}
                />
              </Card.Title>
            </Card.Header>
            <Card.Body>
              <Grid.Row className="mb-3 ws-prewrap">
                <Grid.Col>
                  <TextEditor
                    placeholder="Description"
                    value={values.description}
                    onChange={(value) => setFieldValue('description', value)}
                  />
                </Grid.Col>
              </Grid.Row>

              <Grid.Row className="mb-3">
                <Grid.Col xs={12} md={6}>
                  <Form.Group label="Status">
                    <Select
                      name="status"
                      isMulti={false}
                      options={ProductStatus}
                      onChange={(param) => {
                        setFieldValue('status', param.value);
                      }}
                      value={
                        values.status &&
                        ProductStatus.filter(
                          (productStatus) =>
                            productStatus.value === values.status
                        )
                      }
                    />
                  </Form.Group>
                </Grid.Col>

                {currentUser.type === EUserTypes.admin && (
                  <Grid.Col xs={12} md={6}>
                    <Form.Group label="Product type">
                      <Select
                        name="product_type_id"
                        isMulti={false}
                        getOptionLabel={(
                          option: Types.ProductType
                        ) => option.name}
                        getOptionValue={(
                          option: Types.ProductType
                        ) => option.id}
                        options={data?.productTypes}
                        onChange={(param) => {
                          setFieldValue('product_type_id', param.id);
                        }}
                        value={
                          values.product_type_id &&
                          data?.productTypes?.filter(
                            (productType) =>
                              productType.id ===
                              parseInt(values.product_type_id)
                          )
                        }
                      />
                    </Form.Group>
                  </Grid.Col>
                )}
              </Grid.Row>

              <Grid.Row className="mb-3">
                <Grid.Col lg={3} xs={12}>
                  <NumericFormat
                    customInput={FormField}
                    name="amount"
                    label="Amount"
                    placeholder="Amount"
                    additionalgroupclasses={['mb-0']}
                    value={values.amount}
                    prefix="$"
                    thousandSeparator=","
                    onChange={(e) =>
                      setFieldValue(
                        'amount',
                        parseFloat(
                          e.target.value.replace('$', '').replaceAll(',', '')
                        )
                      )
                    }
                  />

                  <Text>
                    <Text.Small color="secondary" disabled>
                      The purchase amount
                    </Text.Small>
                  </Text>
                </Grid.Col>

                {values.status === 'account' && (
                  <Grid.Col lg={3} xs={12}>
                    <FormField
                      name="quantity"
                      label="Quantity"
                      placeholder="Quantity"
                      type="number"
                      additionalgroupclasses={['mb-0']}
                      value={values.quantity}
                      onChange={handleChange}
                    />
                    <Text>
                      <Text.Small color="secondary" disabled>
                        The account value
                      </Text.Small>
                    </Text>
                  </Grid.Col>
                )}

                {currentUser.type === EUserTypes.admin && (
                  <Grid.Col xs={12} md={6}>
                    <Form.Group label="Account type">
                      <Select
                        name="account_type_id"
                        isMulti={false}
                        getOptionLabel={(
                          option: Types.ProductType
                        ) => option.name}
                        getOptionValue={(
                          option: Types.ProductType
                        ) => option.id}
                        options={accountData?.getAllAccountTypes}
                        onChange={(param) => {
                          setFieldValue('account_type_id', param.id);
                        }}
                        value={
                          values.account_type_id &&
                          accountData?.getAllAccountTypes?.filter(
                            (accountType) =>
                              accountType.id ===
                              parseInt(values.account_type_id)
                          )
                        }
                      />
                    </Form.Group>
                  </Grid.Col>
                )}
              </Grid.Row>

              {currentUser.type === EUserTypes.admin && (
                <Grid.Row className="mb-3">
                  <Grid.Col lg={6} xs={12}>
                    <FormField
                      name="currency"
                      label="Currency"
                      placeholder="Currency"
                      type="text"
                      value={values.currency}
                      onChange={handleChange}
                    />
                  </Grid.Col>

                  <Grid.Col lg={6} xs={12}>
                    <FormField
                      name="code"
                      label="Code"
                      placeholder="Code"
                      type="text"
                      value={values.code}
                      onChange={handleChange}
                    />
                  </Grid.Col>
                </Grid.Row>
              )}
            </Card.Body>
            <Card.Footer>
              <Button.List align="right">
                {productClone.status !== 'archived' && (
                  <Button
                    color="secondary"
                    size="sm"
                    className="btn-pill float-left"
                    onClick={(e) => {
                      e.preventDefault();
                      if (window.confirm('Archive product?')) {
                        values.status = 'archived';
                        handleSubmit();
                      }
                    }}
                  >
                    ARCHIVE
                  </Button>
                )}
                <Button
                  color="white"
                  size="sm"
                  pill
                  onClick={() => setIsEditing(false)}
                >
                  CANCEL
                </Button>
                <Button
                  color="gray-dark"
                  className="ml-2 btn-pill"
                  size="sm"
                  type="submit"
                >
                  UPDATE
                </Button>
              </Button.List>
            </Card.Footer>
          </Card>
        </Grid.Col>

        <Grid.Col lg={3} sm={12} xs={12}>
          <GalleryCard>
            <GalleryCard.Image
              className="product-image w-100"
              src={
                productClone.image
                  ? `/api/s3/uploads/${productClone.image}`
                  : productImagePlaceholder
              }
            />

            <Dropdown
              items={
                <>
                  {rootStore.loading ? (
                    <Button
                      loading
                      color="secondary"
                      className="border-0 p-1 shadow-none"
                      block
                    >
                      Loading...
                    </Button>
                  ) : (
                    <label
                      htmlFor="s3-upload"
                      className="dropdown-item cursor-pointer mb-0"
                    >
                      {productClone.image ? 'Change' : 'Upload'}
                    </label>
                  )}
                  <ReactS3Uploader
                    accept="image/*"
                    autoUpload={true}
                    className="d-none"
                    contentDisposition="auto"
                    id="s3-upload"
                    signingUrl="/api/s3/sign"
                    onError={onError}
                    onFinish={onFinished}
                    onProgress={() => rootStore.setLoading(true)}
                    uploadRequestHeaders={{
                      'x-amz-acl': 'private'
                    }}
                  />
                  {productClone.image && (
                    <Dropdown.Item
                      to="#!"
                      onClick={(e) => {
                        e.preventDefault();
                        updateProductImage(null);
                      }}
                    >
                      Remove
                    </Dropdown.Item>
                  )}
                </>
              }
              toggle={false}
              triggerContent={
                <Button block color="primary" size="sm">
                  Image Options
                </Button>
              }
            />
          </GalleryCard>
        </Grid.Col>
      </Grid.Row>
    </Form>
  );

  return (
    <Formik
      enableReinitialize={true}
      initialValues={{
        id: productClone.id,
        team_id:
          currentUser.type === EUserTypes.admin
            ? productClone.team_id
            : currentCoachTeam.id,
        image: productClone.image,
        title: productClone.title,
        description: productClone.description,
        amount: productClone.amount,
        code: productClone.code,
        currency: productClone.currency,
        status: productClone.status,
        product_type_id: productClone.product_type_id,
        quantity: productClone.quantity ? productClone.quantity : '',
        account_type_id: productClone.account_type_id
      }}
      onSubmit={async (values, { setSubmitting, resetForm, setFieldError }) => {
        if (values.status === 'account' && values.quantity <= 0) {
          setFieldError('quantity', 'Quantity is required');
          setSubmitting(false);
          return false;
        } else if (values.status !== 'account') {
          values.quantity = '';
        }

        await updateProduct({
          refetchQueries: [
            {
              query: GET_PRODUCTS_BY_FILTER_QUERY,
              variables: {
                order: EOrderOptions.alphabet,
                filter: {
                  team_id:
                    currentUser.type === EUserTypes.admin
                      ? productClone.team_id
                      : currentCoachTeam.id
                }
              }
            }
          ],
          variables: {
            product: {
              id: values.id,
              image: values.image,
              title: values.title,
              description: values.description,
              amount: values.amount,
              currency: values.currency,
              code: values.code,
              status: values.status,
              product_type_id: values.product_type_id.toString(),
              quantity: values.quantity ? values.quantity.toString() : null,
              account_type_id: values.account_type_id
            },
            team_id: parseInt(values.team_id)
          }
        });

        applySnapshot(product, {
          ...productClone,
          ...values,
          quantity: values.quantity.toString(),
          product_type_id: values.product_type_id.toString()
        });

        setSubmitting(false);
        resetForm();
        toast.success('Product Updated.');
        setIsEditing(false);
      }}
    >
      {(formikData) => renderForm(formikData)}
    </Formik>
  );
};

export default observer(ProductListItemEdit);
