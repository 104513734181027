import React from 'react';
import { Page } from "tabler-react";

import Wrapper from 'pages/layout';

const UserListPage = (props) => {
  return (
    <Wrapper {...props} title="User Accounts">
      <Page.Content title="Reports">
        <div className="container">User Accounts</div>
      </Page.Content>
    </Wrapper>
  );
};

export default UserListPage;
