import React from 'react';
import { Container } from 'tabler-react';

import ProductListItemView from '../../../components/Product/ProductListItemView';
import { ProductStoreProvider } from '../../../contexts/productContext';
import Wrapper from '../../layout';

const ShowProductPage = (props) => {
  return (
    <Wrapper {...props} title="Products & Services">
      <Container>
        <ProductStoreProvider>
          <ProductListItemView />
        </ProductStoreProvider>
      </Container>
    </Wrapper>
  );
};

export default ShowProductPage;
