import { useFormikContext } from 'formik';
import autoCompleteStyles from 'modules/common/styles/autocompleteStyles';
import useDebounceCallback from 'modules/common/utils/useDebounceCallback';
import useGetLocations from 'modules/location/hooks/useGetLocations';
import { NewTeamOnboardingFormValues } from 'modules/team/components/forms/NewTeamOnboardingForm';
import React, { useMemo, useState } from 'react';
import Select from 'react-select';
import { Form } from 'tabler-react';

const LocationStep = () => {
  const { values, setFieldValue } =
    useFormikContext<NewTeamOnboardingFormValues>();

  const [value, setValue] = useState('');

  const types = useMemo(() => {
    const uniqueTypes = new Set(
      values.activities.map((activity) => activity.locationType)
    );
    return Array.from(uniqueTypes);
  }, [values.activities]);  

  const { data, queryFilter, setQueryFilter } = useGetLocations(types);

  const locationOptions = useMemo(
    () =>
      data?.locations.map(({ id, name }) => ({
        label: name,
        value: Number(id)
      })) ?? [],
    [data?.locations]  
  );

  const onChange = () => {
    setQueryFilter({ ...queryFilter, q: value });
  };

  const debouncedOnChange = useDebounceCallback(onChange);

  return (
    <Form.Group label="Default Location">
      <Select
        backspaceRemovesValue={true}
        className="input-group"
        components={{
          DropdownIndicator: () => null,
          IndicatorSeparator: () => null,
          LoadingIndicator: () => null
        }}
        escapeClearsValue={true}
        isClearable={true}
        options={locationOptions}
        name="default_location"
        onChange={(input) => {
          if (!input) {
            return;
          }

          setFieldValue('default_location', input);
        }}
        onInputChange={(inputValue) => {
          debouncedOnChange();
          setValue(inputValue);
        }}
        placeholder="Search for default location"
        styles={autoCompleteStyles}
        menuPortalTarget={document.body}
        value={values.default_location}
      />
    </Form.Group>
  );
};

export default LocationStep;
