import React from 'react';
import { Button, Form } from 'tabler-react';
import { Formik } from 'formik';
import { useMutation } from '@apollo/client';
import { observer } from 'mobx-react';
import { GET_TEAM_PROGRESSIONS } from '../../../graphql/GET_TEAM_PROGRESSIONS';
import { EDIT_TEAM_PROGRESSION } from '../../../graphql/EDIT_TEAM_PROGRESSION';

interface ITeamProgressionTitleEdit {
  progression: any;
  setEditting: any;
}

const TeamProgressionTitleEdit = ({ progression, setEditting }: ITeamProgressionTitleEdit) => {
  const [editTeamProgression] = useMutation(EDIT_TEAM_PROGRESSION);


  const renderForm = ({ values, handleSubmit, handleChange, errors }) => (
    <>
      <Form.Input
        name='title'
        placeholder='Title'
        type='text'
        onChange={handleChange}
        value={values.title}
      />
      {errors.title && (
        <span className='field-error text-danger'>{errors.title}</span>
      )}
      <Button.List className='mt-4' align='right'>
        <Button
          pill
          icon='x'
          color='white'
          size='sm'
          onClick={() => setEditting(false)}
        />
        <Button
          pill
          icon='save'
          color='primary'
          size='sm'
          type='submit'
          onClick={() => handleSubmit()}
        />
      </Button.List>
    </>
  );
  return (
    <>
      <Formik
        enableReinitialize={true}
        initialValues={{
          id: progression.id,
          title: progression.title
        }}
        onSubmit={async (values) => {

          await editTeamProgression({
            variables: {
              editProgressionInput: {
                id: values.id,
                title: values.title
              }
            },
            refetchQueries: [
              {
                query: GET_TEAM_PROGRESSIONS, variables: {
                  teamId: progression.team_id
                }
              }
            ]
          });
          setEditting(false);
        }}
      >
        {(formikData) => renderForm(formikData)}
      </Formik>

    </>
  );
};

export default observer(TeamProgressionTitleEdit);
