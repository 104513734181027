import { useMutation } from '@apollo/client';
import React from 'react';
import { toast } from 'react-toastify';
import { Button, Card } from 'tabler-react';
import { observer } from 'mobx-react';
import { INSERT_DELETE_USER_SPORT } from 'graphql/INSERT_DELETE_USER_SPORT';
import { GET_STUDENT_SPORTS } from 'graphql/GET_STUDENT_SPORTS';

interface IUserActivityToggleProps {
  sports: any;
  userObj: any;
}

const UserActivityToggle = ({ sports, userObj }: IUserActivityToggleProps) => {
  const [insertDeleteUserSport] = useMutation(INSERT_DELETE_USER_SPORT, {
    onCompleted: () => {
      toast.success('Activity Updated');
    },
    refetchQueries: [
      {
        query: GET_STUDENT_SPORTS,
        variables: {
          student_id: userObj.id.toString()
        }
      }
    ]
  });

  return (
    <Card statusColor="blue" title="Activities">
      <Card.Body>
        <Button.List>
          {sports &&
            sports.map((sportType) => {
              return (
                <Button
                  pill
                  color={sportType.is_active ? 'primary' : 'secondary'}
                  size="sm"
                  key={sportType.id}
                  onClick={async () => {
                    await insertDeleteUserSport({
                      variables: {
                        student_id: Number(userObj.id),
                        sport_type_id: sportType.id
                      }
                    });
                  }}
                >
                  {sportType.name}
                </Button>
              );
            })}
        </Button.List>
      </Card.Body>
    </Card>
  );
};

export default observer(UserActivityToggle);
