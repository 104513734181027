import React, { useMemo, useState } from 'react';
import { GET_SEAL_BY_COACH } from '../../../graphql/GET_SEAL_BY_COACH';
import Modal from '../../Modal';
import { useMutation, useQuery } from '@apollo/client';
import { Button, Card, Form, Grid } from 'tabler-react';
import { ADD_SEAL } from '../../../graphql/ADD_SEAL';
import { toast } from 'react-toastify';
import { EDIT_SEAL } from '../../../graphql/EDIT_SEAL';
import { TEAM_DETAILS_QUERY } from '../../../graphql/TEAM_DETAILS_QUERY';
import { Types } from '../../../types/graphql'

interface CoachSealEditModalProps {
  teamId: number;
  coachId: number;
  isModalOpen: boolean;
  toggleModal: (isOpen: boolean) => void;
}

const CoachSealEditModal = ({
  teamId,
  coachId,
  toggleModal,
  isModalOpen
}: CoachSealEditModalProps) => {
  const { data, loading, error } = useQuery(GET_SEAL_BY_COACH, {
    variables: {
      coach_id: coachId
    }
  });
  const [addSeal] = useMutation(ADD_SEAL);
  const [editSeal] = useMutation(EDIT_SEAL);
  const [seal, setSeal] = useState('');

  const coachSeal: null | Types.UserRiggerSeal = useMemo(() => {
    if (loading || !data || error) {
      return null;
    }
    setSeal(data?.sealByCoach?.rigger_seal);
    return data?.sealByCoach;
  }, [data, loading, error]);

  const handleSubmit = () => {
    const refetchQueries = [
      { query: GET_SEAL_BY_COACH, variables: { coach_id: coachId } },
      {
        query: TEAM_DETAILS_QUERY,
        variables: {
          team_id: teamId
        }
      }
    ];
    if (coachSeal) {
      editSeal({
        variables: {
          editSealInput: {
            id: coachSeal.id,
            rigger_seal: seal
          }
        },
        refetchQueries
      });

      toast.success('Seal updated successfully');
    } else {
      addSeal({
        variables: {
          addSealInput: {
            coach_id: coachId,
            rigger_seal: seal
          }
        },
        refetchQueries
      });
      toast.success('Seal added successfully');
    }

    toggleModal(false);
  };
  return (
    <Modal
      content={
        <Card>
          <Card.Body>
            <Grid.Row>
              <Grid.Col>
                <Form.Group>
                  <Form.Label>Rigger Seal</Form.Label>
                  <Form.Input
                    type="text"
                    value={seal}
                    onChange={(e) => {
                      setSeal(e.target.value);
                    }}
                  />
                </Form.Group>
              </Grid.Col>
            </Grid.Row>
          </Card.Body>
          <Card.Footer>
            <Button
              pill
              className="btn-gray-dark float-right btn-sm"
              onClick={handleSubmit}
            >
              Submit
            </Button>
            <Button
              pill
              color="white"
              className="float-right btn-sm"
              onClick={() => toggleModal(false)}
            >
              Cancel
            </Button>
          </Card.Footer>
        </Card>
      }
      open={isModalOpen}
      onClose={() => toggleModal(false)}
    />
  );
};

export default CoachSealEditModal;
