import React from 'react';
import { Container } from 'tabler-react';

import StaffList from '../../components/Staff/StaffList';
import Wrapper from '../../pages/layout';
import StaffCoachList from '../../components/Staff/StaffCoachList';

const StaffListPage = (props) => {
  return (
    <Wrapper {...props} title="Accounts">
      <Container>
        <StaffCoachList />
        <StaffList />
      </Container>
    </Wrapper>
  );
};

export default StaffListPage;
