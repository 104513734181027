import React, { useMemo } from 'react'
import { GET_TEAM_REGISTRATION_STATUSES } from '../../../graphql/GET_TEAM_REGISTRATION_STATUSES'
import { useQuery } from '@apollo/client'
import RegistrationStatusItem from './RegistrationStatusItem'

const RegistrationStatus = ({ team }) => {
  const { data, loading } = useQuery(GET_TEAM_REGISTRATION_STATUSES, {
    variables: { teamId: team.id }
  })

  const statusList = useMemo(() => {
    if (!loading && data?.teamStatusList) {
      return data?.teamStatusList
    }
    return []
  }, [data, loading])

  return (
    <>
      {statusList.map((status) => (
        <RegistrationStatusItem
          status={status}
          team={team}
          loading={loading}
          key={status.id}
        />
      ))}
    </>
  )
}

export default RegistrationStatus
