import gql from 'graphql-tag';

export const GET_ALL_GEAR_TYPES = gql`
  query GET_ALL_GEAR_TYPES {
    getGearTypes {
      id
      name
    }
  }
`;
