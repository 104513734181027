import gql from 'graphql-tag';

export const ADD_JOB = gql`
  mutation ADD_JOB($addJobInput: AddJobInput!) {
    addJob(addJobInput: $addJobInput) {
      id
      user_gear_id
      product_id
      rigger_id
      job_type_id
      priority
      status
      job_notes
      job_storage
      created_by
    }
  }
`;
