import { useQuery } from '@apollo/client';
import { DEFAULT_LIMIT } from 'components/constants';
import { GET_LOCATIONS } from 'graphql/GET_LOCATIONS';
import { GET_LOCATIONS as GET_LOCATIONS_TYPE } from 'graphql/types/GET_LOCATIONS';
import { QueryParams } from 'modules/common/constants/serviceParams';
import { ELocationOrder } from 'modules/location/constants';
import { LocationType } from 'modules/team/constants/api';
import { useEffect, useState } from 'react';

const useGetLocations = (types?: LocationType[]) => {
  const [locationsData, setLocationsData] = useState<GET_LOCATIONS_TYPE>();

  const [queryFilter, setQueryFilter] = useState<QueryParams>({
    limit: DEFAULT_LIMIT
  });

  const { loading, error, data, refetch } = useQuery<GET_LOCATIONS_TYPE>(
    GET_LOCATIONS,
    {
      variables: {
        order: ELocationOrder.name,
        filter: {
          types,
          search: queryFilter.q,
          limit: queryFilter.limit
        }
      }
    }
  );

  useEffect(() => {
    if (!loading) {
      setLocationsData(data);
    }
  }, [data, loading]);

  useEffect(() => {
    void refetch();
  }, [queryFilter, refetch]);

  return {
    data: locationsData,
    loading,
    error,
    queryFilter,
    setQueryFilter
  };
};

export default useGetLocations;
