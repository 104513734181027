import gql from 'graphql-tag';

export const CREATE_NEW_LOAD = gql`
  mutation CREATE_NEW_LOAD($load: NewLoadInput!) {
    createNewLoad(load: $load) {
      id
      load_name
      status {
        slug
        name
      }
      load_departure
      load_complete
      team_id
      load_notes
      aircraft {
        id
        name
        tail_number
        aircraft_type
        aircraft_notes
        capacity_max
        capacity_min
      }
      location {
        id
        type
        name
      }
      created_on
    }
  }
`;
