import gql from 'graphql-tag';

export const GET_ACCOUNT_TYPE_BY_TEAM_ID = gql`
  query GET_ACCOUNT_TYPE_BY_TEAM_ID($teamId: Int!) {
    getAccountTypeByTeamId(teamId: $teamId) {
      id
      slug
    }
  }
`;
