import { observer } from "mobx-react";
import React from "react";
import { Container } from "tabler-react";
import Manifest from "../../components/manifest/manifest";
import { LoadStoreProvider } from "../../contexts/LoadStoreContext";
import Wrapper from "../../pages/layout";

const ManifestPage = (props) => {
  return (
    <>
      <Wrapper {...props} title="Manifest">
        <Container>
          <LoadStoreProvider>
            <Manifest />
          </LoadStoreProvider>
        </Container>
      </Wrapper>
    </>
  );
};

export default observer(ManifestPage);
