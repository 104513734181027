import React, { useState } from "react";
import { Form, Grid } from "tabler-react";
import { Types } from "../../types/graphql";

interface RegistrationQuestionFieldMultiProps {
  question: Types.RegistrationQuestion;
  responseChangeHandler: (e: string, questionId: number) => void;
  registrationQuestionsResponse: string;
  validateQuestion: boolean;
}

const RegistrationQuestionFieldMulti = ({
  question,
  responseChangeHandler,
  registrationQuestionsResponse,
  validateQuestion,
}: RegistrationQuestionFieldMultiProps) => {
  const [userAnswer, setUserAnswer] = useState<string[]>([]);

  const handleResponseChange = (answer: string | string[]) => {
    if (Array.isArray(answer)) {
      setUserAnswer(answer);
      responseChangeHandler(answer.join(";"), question.id);
    } else {
      const isExist = userAnswer.includes(answer);
      let response = userAnswer;
      if (isExist) {
        response = userAnswer.filter((item) => item !== answer);
      } else {
        response = [...userAnswer, answer];
      }
      setUserAnswer(response);
      responseChangeHandler(response.join(";"), question.id);
    }
  };

  return (
    <Grid.Col>
      <div className={`form-group mb-0`}>
        <label className="form-label">
          {question.description}
          {question.is_required && "*"}
        </label>
        <div className="input-group">
          <>
            {question?.question_options?.map((option) => (
              <Form.Checkbox
                key={`${option.id}-${option.name}`}
                className="d-block cursor-pointer ml-2"
                label={option.name}
                name={`question${question.id}`}
                value={option.name}
                checked={userAnswer.includes(option.name)}
                onChange={() => {
                  handleResponseChange(option.name);
                }}
              />
            ))}
          </>
        </div>

        <span className="field-error text-danger">
          {!registrationQuestionsResponse &&
            validateQuestion &&
            question.is_required &&
            "This field is required"}
        </span>
      </div>
    </Grid.Col>
  );
};

export default RegistrationQuestionFieldMulti;
