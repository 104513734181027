import gql from 'graphql-tag';

export const ADD_USER_NOTE = gql`
  mutation ADD_USER_NOTE($addUserNoteInput: AddUserNoteInput) {
    addUserNote(addUserNoteInput: $addUserNoteInput) {
      id
      student_id
      team_id
      registration_id
      note
      is_public
      created_on
      created_by
    }
  }
`;
