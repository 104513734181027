import { observer } from 'mobx-react';
import React from 'react';
import Wrapper from '../layout';
import UserSettings from '../../components/User/UserSettings';

const UserSettingsPage = (props) => {
  return (
    <Wrapper {...props} title="Settings">
      <div className="container">
        <UserSettings />
      </div>
    </Wrapper>
  );
};

export default observer(UserSettingsPage);
