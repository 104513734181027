import React from 'react';
import { Formik } from 'formik';
import FormField from '../../FormField';
import { Button, Form, Grid } from 'tabler-react';
import Select from "react-select";
import { useMutation, useQuery } from '@apollo/client';
import { toast } from 'react-toastify';
import { GET_ALL_GEAR_TYPES } from '../../../graphql/GET_ALL_GEAR_TYPES';
import { GET_ALL_GEAR_MANUFACTURERS } from '../../../graphql/GET_ALL_GEAR_MANUFACTURERS';
import * as Yup from 'yup';
import { GET_ALL_GEAR_MODELS } from '../../../graphql/GET_ALL_GEAR_MODELS';
import { ADD_GEAR_MODEL } from '../../../graphql/ADD_GEAR_MODEL';
import { Types } from '../../../types/graphql'

interface GearModelFormProps {
  toggleForm: any;
}

const addGearModelValidationSchema = Yup.object().shape({
  gear_type_id: Yup.number().required('This field is required.')
});

const GearModelForm = ({
                         toggleForm
                       }: GearModelFormProps) => {
  const [addGearModel] = useMutation(ADD_GEAR_MODEL);

  const { data: typeData } = useQuery(GET_ALL_GEAR_TYPES);
  const { data: manufacturerData } = useQuery(GET_ALL_GEAR_MANUFACTURERS);

  const handleClose = () => {
    toggleForm(false);
  };

  const renderForm = ({ values, handleSubmit, isSubmitting, handleChange, setFieldValue, errors, touched }) => (
    <form onSubmit={handleSubmit}>
      <Grid.Row>
        <Grid.Col width={6}>
          <Form.Group label=''>
            <Form.InputGroup>
              <FormField
                type='text'
                name='name'
                placeholder='Model Name'
                onChange={handleChange}
                value={values.name}
              />
            </Form.InputGroup>
          </Form.Group>
        </Grid.Col>
      </Grid.Row>
      <Grid.Row className='mb-4'>
        <Grid.Col width={6}>
          <Form.Group label=''>
            <Select
              name='gear_type_id'
              isMulti={false}
              getOptionLabel={(
                option: Types.GearType
              ) => option.name}
              getOptionValue={(
                option: Types.GearType
              ) => option.id}
              options={typeData?.getGearTypes}
              placeholder="Select Gear Type"
              onChange={(param) => {
                setFieldValue('gear_type_id', param.id);
              }}
              value={typeData?.getGearTypes.filter((gearType) => gearType.id === values?.gear_type_id)}
            />
            <span className='field-error text-danger'>
          {errors.gear_type_id && touched.gear_type_id && errors.gear_type_id}
        </span>
          </Form.Group>
        </Grid.Col>
        <Grid.Col width={6}>
          <Form.Group label=''>
            <Select
              name='gear_manufacturer_id'
              isMulti={false}
              getOptionLabel={(
                option: Types.GearManufacturer
              ) => option.name}
              getOptionValue={(
                option: Types.GearManufacturer
              ) => option.id}
              options={manufacturerData?.getGearManufacturers}
              placeholder="Select Manufacturer"
              onChange={(param) => setFieldValue('gear_manufacturer_id', param.id)}
              value={manufacturerData?.getGearManufacturers.filter((gearManufacturer) => gearManufacturer.id === values?.gear_manufacturer_id)}
            />
            <span className='field-error text-danger'>
          {errors.gear_model_id && touched.gear_model_id && errors.gear_model_id}
        </span>
          </Form.Group>
        </Grid.Col>
      </Grid.Row>
      <Button
        type='submit'
        disabled={isSubmitting}
        pill
        size="sm"
        className='btn-primary float-right btn-sm'
      >
        {isSubmitting
          ? 'Processing...'
          : 'SAVE'}
      </Button>
      <Button
        pill
        color='secondary'
        size="sm"
        className='float-right btn-sm'
        onClick={(e) => {
          e.preventDefault();
          handleClose();
        }}
      >
        CANCEL
      </Button>
    </form>
  );

  return (
    <Formik
      enableReinitialize={true}
      validationSchema={addGearModelValidationSchema}
      initialValues={{
        name: '',
        gear_type_id: '',
        gear_manufacturer_id: ''
      }}
      onSubmit={async (values, { resetForm, setSubmitting }) => {

        const isUpdated = await addGearModel({
          variables: {
            addGearModelInput: {
              name: values?.name,
              gear_type_id: values?.gear_type_id,
              gear_manufacturer_id: values?.gear_manufacturer_id
            }
          },
          refetchQueries: [
            { query: GET_ALL_GEAR_MODELS }
          ]
        });

        if (isUpdated.data?.addGearModel) {
          handleClose();
          toast.success('Gear model updated.');
          setSubmitting(false);
          resetForm();
        } else {
          toast.error('Update failed');
        }

      }}
    >
      {(formikData) => renderForm(formikData)}
    </Formik>
  );
};

export default GearModelForm;
