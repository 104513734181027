import { useMutation } from '@apollo/client';
import Modal from 'components/Modal';
import { UserStoreContext } from 'contexts/userStoreContext';
import { SEND_EMAIL_MERIT_INSTRUCTIONS } from 'graphql/SEND_EMAIL_MERIT_INSTRUCTIONS';
import React, { useContext, useState } from 'react';
import { toast } from 'react-toastify';
import { Button } from 'tabler-react';

type Props = {
  toggleConfirmationModal: () => void;
};

const ConfirmEmailMeritInstructionsButton = ({
  toggleConfirmationModal
}: Props) => {
  const { user } = useContext(UserStoreContext);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  const [sendEmailMeritInstruction] = useMutation(
    SEND_EMAIL_MERIT_INSTRUCTIONS
  );

  const handleSendEmail = async () => {
    setIsSubmitting(true);
    try {
      const response = await sendEmailMeritInstruction({
        variables: {
          studentId: Number(user.id)
        }
      });

      if (response.data.sendAuthMeritsEmailInstructions) {
        setIsSubmitting(false);
        toast.success('Email sent successfully.');

        toggleModal();
        toggleConfirmationModal();
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div>
      <Button color="success" onClick={toggleModal}>
        Yes
      </Button>
      <Modal
        actions={
          <Button.List align="center">
            <Button color="secondary" onClick={toggleConfirmationModal}>
              No
            </Button>
            <Button
              color="success"
              onClick={handleSendEmail}
              disabled={isSubmitting}
            >
              {isSubmitting ? 'Sending...' : 'Yes'}
            </Button>
          </Button.List>
        }
        content="This will now send email instructions"
        open={isModalOpen}
        onClose={toggleModal}
        title="Send email instructions"
      />
    </div>
  );
};

export default ConfirmEmailMeritInstructionsButton;
