import { useMutation } from '@apollo/client';
import { Formik } from 'formik';
import { observer } from 'mobx-react';
import moment from 'moment';
import React, { useContext, useState } from 'react';
import { Button, Form } from 'tabler-react';
import * as Yup from 'yup';
import { UserStoreContext } from '../../../contexts/userStoreContext';
import { ADD_USER_NOTE } from '../../../graphql/ADD_USER_NOTE';
import { GET_USER_NOTES } from '../../../graphql/GET_USER_NOTES';
import { useRootStore } from '../../../hooks';
import FeatureAccessGate from 'modules/common/components/FeatureAccessGate';
import Select from 'react-select';
import { CRM_CONTACT_TYPES } from '../../constants';
import useGetCurrentUserType from 'modules/common/hooks/useGetCurrentUserType';
import { ADD_USER_EVENT } from '../../../graphql/ADD_USER_EVENT';
import { EUserEventsId } from '../../User/UserEvents';
import { GET_ALL_USER_REGISTRATION_EVENTS } from '../../../graphql/GET_ALL_USER_REGISTRATION_EVENTS';

const addUserNoteValidationSchema = Yup.object().shape({
  note: Yup.string().required('This field is required.')
});

interface IStudentNoteForm {
  studentNote: {
    id?: number;
    registration_id: number;
    student_id: number;
    team_id?: number;
    note?: string;
    created_by?: number;
    crm_contact_type_id?: number;
  };
  filter: {
    student_id: number;
    registration_id: number;
    team_id: number;
    limit: number;
    offset: number;
  };
}

const StudentNoteForm = ({ studentNote, filter }: IStudentNoteForm) => {
  const userStore = useContext(UserStoreContext);
  const rootStore = useRootStore();
  const { currentCoachTeam, currentUser } = rootStore;
  const { isAdmin } = useGetCurrentUserType();
  const { user } = userStore;
  const [note, setNote] = useState(studentNote.note);
    const [userEvent] = useMutation(ADD_USER_EVENT);
    const [addUserNote] = useMutation(ADD_USER_NOTE, {
      onCompleted: (result) => {
        if (result.addUserNote) {
          userEvent({
            variables: {
              userEvent: {
                user_event_type_id: EUserEventsId.unote_added,
                student_id: studentNote.student_id,
                status: note,
                team_id: filter.team_id,
                user_registration_id: Number(studentNote.registration_id)
              }
            },
            refetchQueries: [
              {
                query: GET_ALL_USER_REGISTRATION_EVENTS,
                variables: {
                  filter: {
                    userRegistrationId: studentNote.registration_id
                  },
                  limit: 20,
                  page: 1
                }
              }
            ]
          });
        }
      }
    });

    const initValues = {
      id: studentNote?.id ?? undefined,
      registration_id: Number(studentNote?.registration_id) ?? undefined,
      student_id: Number(studentNote?.student_id) ?? parseInt(user.id),
      team_id: studentNote?.team_id ?? currentCoachTeam?.id,
      note: studentNote?.note ?? '',
      created_by: studentNote?.created_by ?? currentUser.id,
      crm_contact_type_id: studentNote?.crm_contact_type_id ?? 0
    };

    const renderForm = ({
      values,
      handleSubmit,
      handleChange,
      errors,
      setFieldValue
    }) => (
      <>
        <Form.Textarea
          name="note"
          placeholder="Add notes"
          rows={values.note ? '3' : '1'}
          type="text"
          onChange={handleChange}
          value={values.note}
          disabled={isAdmin}
        />
        {errors.note && values.note && (
          <span className="field-error text-danger">{errors.note}</span>
        )}
        {values.note &&
        <Button.List className="mt-4 mb-7 float-right" align="right">
          <Button
            type="submit"
            form="registrationRequirementForm"
            color="primary"
            onClick={() => handleSubmit()}
            disabled={isAdmin || !values.note}
          >
            SAVE
          </Button>
        </Button.List>
        }
        <FeatureAccessGate feature="feature_crm">
          <div className={'float-right w-50 mt-4 mr-2 mb-7'}>
            <Select
              name="crm_contact_type_id"
              placeholder={'Contact method'}
              isClearable={true}
              isSearchable={false}
              isMulti={false}
              options={CRM_CONTACT_TYPES}
              onChange={(crm_type) => {
                setFieldValue(
                  'crm_contact_type_id',
                  Number(crm_type?.value) ?? 0
                );
              }}
              value={
                CRM_CONTACT_TYPES.find(
                  (crm_type) =>
                    crm_type?.value === Number(values.crm_contact_type_id)
                ) ?? ''
              }
            />
          </div>
        </FeatureAccessGate>
      </>
    );
    return (
      <>
        <Formik
          enableReinitialize={true}
          validationSchema={addUserNoteValidationSchema}
          initialValues={initValues}
          onSubmit={async (values, { setFieldValue, resetForm }) => {
            setNote(values.note);
            if (values.crm_contact_type_id === 0)
              values.crm_contact_type_id = null;
            await addUserNote({
              variables: {
                addUserNoteInput: {
                  created_on: moment(),
                  ...values
                }
              },
              refetchQueries: [
                {
                  query: GET_USER_NOTES,
                  variables: {
                    userNoteOptions: filter
                  }
                }
              ]
            });
            setFieldValue('note', ' ');
            setFieldValue('crm_contact_type_id', null);
            resetForm({ values: initValues });
          }}
        >
          {(formikData) => renderForm(formikData)}
        </Formik>
      </>
    );
};

export default observer(StudentNoteForm);
