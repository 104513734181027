import { useLazyQuery } from "@apollo/client";
import { EUserFilterOptions } from "../../../components/enum";
import { GET_STUDENTS_BY_FILTER_QUERY } from "../../../graphql/GET_STUDENTS_BY_FILTER_QUERY";
import {
  GET_STUDENTS_BY_FILTER_QUERY as GET_STUDENTS_BY_FILTER_QUERY_TYPE
} from "../../../graphql/types/GET_STUDENTS_BY_FILTER_QUERY";
import { useRootStore } from "../../../hooks";
import { EOrderOptions } from "../../../hooks/useAutocomplete";

const UseGetCoachStudents = () => {
  const { currentCoachTeam } = useRootStore();
  const [loadStudents, { data, loading }] =
    useLazyQuery<GET_STUDENTS_BY_FILTER_QUERY_TYPE>(
      GET_STUDENTS_BY_FILTER_QUERY,
    );

  const loadData = async () => {
    await loadStudents({
                         variables: {
                           order: EOrderOptions.newest,
                           filter: {
                             team_id: currentCoachTeam?.id.toString(),
                             filter_by: EUserFilterOptions.enabled,
                           },
                         },
                       });
  };

  return {data, loading, loadData};
}

export { UseGetCoachStudents };
