import { onSnapshot, applySnapshot } from 'mobx-state-tree';
import React, { createContext, ReactNode, useState } from 'react';

import {
  TeamStore,
  TeamStoreType,
  TeamStoreDefault
} from '../models/TeamStore';

export const TeamStoreContext = createContext<TeamStoreType | null>(null);

interface TeamStoreProvider {
  children: ReactNode;
}

const getOrCreateStore = () => {
  const teamStore = TeamStoreDefault();
  const initData = localStorage.getItem('teams');

  if (initData) {
    applySnapshot(teamStore, JSON.parse(initData));
    return teamStore;
  } else {
    return TeamStore.create();
  }
};

export const TeamStoreProvider = (props: TeamStoreProvider) => {
  const [teamStore] = useState(() => getOrCreateStore());

  onSnapshot(teamStore, (_snapshot) => {
    const snapshot = { ..._snapshot };
    const data = JSON.stringify(snapshot);
    localStorage.setItem('teams', data);
  });

  return (
    <TeamStoreContext.Provider value={teamStore}>
      {props.children}
    </TeamStoreContext.Provider>
  );
};
