import { observer } from 'mobx-react';
import React from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { Dimmer, Grid, Table } from 'tabler-react';
import ProductListItemAdmin from './ProductListItemAdmin';
import { useQuery } from '@apollo/client';
import { GET_PRODUCT_TYPES } from '../../graphql/GET_PRODUCT_TYPES';
import { GET_ALL_ACCOUNT_TYPES } from '../../graphql/GET_ALL_ACCOUNT_TYPES';

interface IProductsListProps {
  products: any;
  archivedProducts: any;
  loading: any;
  onFetchMore: any;
  total: number;
}

const ProductsList = ({ products, archivedProducts, loading, onFetchMore, total }: IProductsListProps) => {
  const { loading: productTypeLoading, data: productTypeData } =
    useQuery(GET_PRODUCT_TYPES);
  const { loading: accountTypeLoading, data: accountTypeData } = useQuery(
    GET_ALL_ACCOUNT_TYPES
  );

  return (
    <Dimmer active={loading} loader={loading}>

      <InfiniteScroll
        className="overflow-visible pb-3"
        dataLength={products.length}
        hasMore={products.length < (total || 0)}
        loader={<Dimmer active={true} loader={true} />}
        next={onFetchMore}
        scrollThreshold="210px"
      >
        <Grid.Col width={12}>
          <Table
            responsive
            className='card-table table-vcenter text-nowrap'
          >
            <Table.Header>
              <Table.Row>
                <Table.ColHeader></Table.ColHeader>
                <Table.ColHeader>Status</Table.ColHeader>
                <Table.ColHeader>Price</Table.ColHeader>
                <Table.ColHeader></Table.ColHeader>
                <Table.ColHeader></Table.ColHeader>
                <Table.ColHeader></Table.ColHeader>
                <Table.ColHeader></Table.ColHeader>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {!productTypeLoading &&
                !accountTypeLoading &&
                products.map((product) => (
                  <Table.Row key={product.id}>
                    <ProductListItemAdmin
                      product={product}
                      productType={productTypeData.productTypes}
                      accountType={accountTypeData.getAllAccountTypes}
                    />
                  </Table.Row>
                ))}
            </Table.Body>
          </Table>
        </Grid.Col>
      </InfiniteScroll>


    </Dimmer>
  );
};

export default observer(ProductsList);
