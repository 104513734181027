import { useMutation, useQuery } from '@apollo/client';
import { Formik } from 'formik';
import PhoneInput from 'react-phone-input-2';
import React from 'react';
import { toast } from 'react-toastify';
import { Button, Card, Dimmer, Form } from 'tabler-react';
import * as Yup from 'yup';

import { UPDATE_USER_CONTACT } from '../../graphql/UPDATE_USER_CONTACT';
import { GET_USER_CONTACTS_BY_STUDENT_ID } from '../../graphql/GET_USER_CONTACTS_BY_STUDENT_ID';

const userContactValidationSchema = Yup.object().shape({
  name: Yup.string().required('This field is required.'),
  phone: Yup.string().required('This field is required.')
});

interface IUserEmergencyContactProps {
  user: any;
}

const UserEmergencyContact = ({ user }: IUserEmergencyContactProps) => {
  const { loading, error, data } = useQuery(GET_USER_CONTACTS_BY_STUDENT_ID, {
    variables: { student_id: user.id }
  });

  const [updateUserContact] = useMutation(UPDATE_USER_CONTACT, {
    onCompleted: () => toast.success('Emergency Contact Updated.')
  });

  if (error) {
    return <p>{`Error: ${error.message}`}</p>;
  }

  return (
    <Formik
      enableReinitialize={true}
      initialValues={{
        name: data?.getUserContactsByStudentId?.name || '',
        phone: data?.getUserContactsByStudentId?.phone || '',
        student_id: user.id
      }}
      validationSchema={userContactValidationSchema}
      onSubmit={async (values, { setSubmitting }) => {
        await updateUserContact({
          refetchQueries: [
            {
              query: GET_USER_CONTACTS_BY_STUDENT_ID,
              variables: {
                student_id: user.id
              }
            }
          ],
          variables: {
            userContact: values
          }
        });
        setSubmitting(false);
      }}
    >
      {({
        values,
        errors,
        touched,
        isSubmitting,
        handleBlur,
        handleChange,
        handleSubmit
      }) => (
        <form onSubmit={handleSubmit}>
          <Card statusColor="">
            <Card.Header>
              <Card.Title>Emergency Contact</Card.Title>
            </Card.Header>
            <Card.Body>
              <Dimmer active={loading} loader={loading}>
                <Form.Group label="Contact">
                  <Form.Input
                    className={`${
                      (errors.name || !values.name) &&
                      'is-invalid state-invalid'
                    }`}
                    name="name"
                    type="text"
                    placeholder="Name"
                    onBlur={handleBlur}
                    value={values.name}
                    onChange={handleChange}
                  />
                  {errors.name && touched.name && (
                    <span className="field-error text-danger">
                      {errors.name}
                    </span>
                  )}
                </Form.Group>
                <Form.Group label="Contact Phone">
                  <PhoneInput
                    inputProps={{
                      className: `form-control w-100 ${
                        (errors.phone || !values.phone) &&
                        'is-invalid state-invalid'
                      }`,
                      name: 'phone',
                      onChange: handleChange
                    }}
                    country={'us'}
                    value={values.phone}
                  />
                  {errors.phone && touched.phone && (
                    <span className="field-error text-danger">
                      {errors.phone}
                    </span>
                  )}
                </Form.Group>
              </Dimmer>
            </Card.Body>
            <Card.Footer>
              <Button.List align="right">
                <Button
                  disabled={isSubmitting}
                  loading={isSubmitting}
                  pill
                  color="gray-dark"
                  size="sm"
                  type="submit"
                >
                  UPDATE
                </Button>
              </Button.List>
            </Card.Footer>
          </Card>
        </form>
      )}
    </Formik>
  );
};

export default UserEmergencyContact;
