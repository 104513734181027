import gql from 'graphql-tag';

export const GET_STUDENT_TEAM = gql`
  query GET_STUDENT_TEAM($student_id: Int!, $team_id: Int!) {
    getStudentTeam(studentId: $student_id, teamId: $team_id) {
      id
      student_id
      team_id
      total_time
      updated_on
      is_disabled
    }
  }
`;
