import Modal from 'components/Modal'
import React from 'react'
import { Button } from 'tabler-react'

interface DeletePaymentModalProps {
  isModalOpen: boolean
  onConfirm: () => void
  toggleModal: () => void
  status: string
}

const UpdatePayementModal = ({
  isModalOpen,
  onConfirm,
  toggleModal,
  status
}: DeletePaymentModalProps) => {
  return (
    <Modal
      actions={
        <Button.List align="center">
          <Button pill color="white" size="sm" onClick={toggleModal}>
            CANCEL
          </Button>
          <Button pill color="danger" size="sm" onClick={onConfirm}>
            {status.toUpperCase()}
          </Button>
        </Button.List>
      }
      content={`Are you sure you want to update this payment status to ${status}?`}
      open={isModalOpen}
      title=""
      onClose={toggleModal}
    />
  )
}

export default UpdatePayementModal
