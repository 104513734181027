import gql from 'graphql-tag';

export const GET_TEAM_LOAD_PAYMENT_TYPES = gql`
  query GET_TEAM_LOAD_PAYMENT_TYPES($teamId: ID!) {
    getTeamLoadPaymentTypes(teamId: $teamId) {
      id
      team_id
      amount
      name
      load_payment_type_id
    }
  }
`;
