import Modal from 'components/Modal';
import { calcTotalPaid } from 'modules/payment/utils/calcTotalPaid';
import RecipientListModal from 'modules/user/registrations/components/modals/RecipientListModal';
import {
  PaymentStatusType,
  RegistrationStatusType,
  USPAStatusType
} from 'modules/user/registrations/constants/api';
import useGetRegisteredStudents from 'modules/user/registrations/hooks/useGetRegisteredStudents';
import { calcTotalFees } from 'modules/user/registrations/utils/calcTotalFees';
import React, { useMemo, useState } from 'react';
import { useParams } from 'react-router';
import { Text } from 'tabler-react';

type Props = {
  checkedState: Record<string, boolean>;
  isAllChecked: boolean;
  setCheckedState: React.Dispatch<
    React.SetStateAction<Record<string, boolean>>
  >;
  setIsAllChecked: React.Dispatch<React.SetStateAction<boolean>>;
};

const ToRecipientsText = ({
  checkedState,
  isAllChecked,
  setCheckedState,
  setIsAllChecked
}: Props) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [recipientsCount, setRecipientsCount] = useState(0);
  const [uspaStatus, setUspaStatus] = useState<USPAStatusType>();
  const [paymentStatus, setPaymentStatus] = useState<PaymentStatusType>();
  const [registrationStatus, setRegistrationStatus] =
    useState<RegistrationStatusType>();

  const { campId } = useParams<{ campId: string }>();

  const { data } = useGetRegisteredStudents({ campId });

  let registeredStudentRows = useMemo(
    () => data?.getRegisteredStudents.map((student) => student) ?? [],
    [data?.getRegisteredStudents]  
  );

  // If uspaStatus is set, filter the registered students by uspaStatus
  if (uspaStatus) {
    registeredStudentRows = registeredStudentRows.filter(
      (student) => student.uspa.uspaStatus === uspaStatus
    );
  }

  // If paymentStatus is set, filter the registered students by paymentStatus
  if (paymentStatus) {
    registeredStudentRows = registeredStudentRows.filter((student) => {
      const balance =
        calcTotalFees(student) -
        calcTotalPaid(
          student.payments.map(({ amount, status }) => ({
            amount,
            status
          }))
        );

      const paidStatus = balance > 0 ? 'unpaid' : 'paid';

      return paidStatus === paymentStatus;
    });
  }

  // If registrationStatus is set, filter the registered students by registrationStatus
  if (registrationStatus) {
    registeredStudentRows = registeredStudentRows.filter(
      (student) => student.status === registrationStatus
    );
  }

  const handleSetUspaStatus = (status: USPAStatusType) => {
    setUspaStatus(status);
  };

  const handleSetPaymentStatus = (status: PaymentStatusType) => {
    setPaymentStatus(status);
  };

  const handleSetRegistrationStatus = (status: RegistrationStatusType) => {
    setRegistrationStatus(status);
  };

  // Function to handle individual checkbox changes
  const handleCheckChange = (id) => {
    setCheckedState((prevState) => {
      const newState = { ...prevState };
      newState[id] = !prevState[id];

      recipientsCountHandler(
        Object.keys(newState).filter((key) => newState[key]).length
      );

      return newState;
    });
  };

  // Function to handle "Check All" checkbox change
  const handleCheckAllChange = (event) => {
    setIsAllChecked(event.target.checked);
    setCheckedState((prevState) => {
      const newState = { ...prevState };

      registeredStudentRows.forEach((student) => {
        newState[student.id] = isAllChecked ? false : true;
      });

      recipientsCountHandler(
        Object.keys(newState).filter((key) => newState[key]).length
      );

      return newState;
    });
  };

  const recipientsCountHandler = (count: number) => {
    setRecipientsCount(count);
  };

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  return (
    <>
      <Text>
        To:
        <span
          className="cursor-pointer text-primary font-weight-bold ml-1"
          onClick={toggleModal}
        >
          Recipients ({recipientsCount})
        </span>
      </Text>
      <Modal
        content={
          <RecipientListModal
            checkedState={checkedState}
            handleCheckAllChange={handleCheckAllChange}
            handleCheckChange={handleCheckChange}
            handleSetPaymentStatus={handleSetPaymentStatus}
            handleSetRegistrationStatus={handleSetRegistrationStatus}
            handleSetUspaStatus={handleSetUspaStatus}
            isAllChecked={isAllChecked}
            paymentStatus={paymentStatus}
            registeredStudentRows={registeredStudentRows}
            registrationStatus={registrationStatus}
            toggleModal={toggleModal}
            uspaStatus={uspaStatus}
          />
        }
        open={isModalOpen}
        onClose={toggleModal}
        title={`Select Recipients (${recipientsCount})`}
        maxWidth="md"
      />
    </>
  );
};

export default ToRecipientsText;
