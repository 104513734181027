import React, { useEffect, useState } from 'react';
import Modal from '../../Modal';
import ReactS3Uploader from 'react-s3-uploader';
import { toast } from 'react-toastify';
import { useRootStore } from '../../../hooks';
import { Button, Icon, Text } from 'tabler-react';
import { ADD_OR_EDIT_MEMBER_IMAGE } from '../../../graphql/ADD_OR_EDIT_MEMBER_IMAGE';
import { useMutation } from '@apollo/client';

const MemberCredentialImageUploadModal = ({ isOpen, toggle, membership }) => {
  const rootStore = useRootStore()
  const [memberImage, setMemberImage] = useState(null)
  const onError = (error) => {
    toast.error(error)
    rootStore.setLoading(false)
  }

  useEffect(() => {
    if (membership?.image) {
      setMemberImage(membership?.image)
    }
  }, [membership])

  const [uploadImage] = useMutation(ADD_OR_EDIT_MEMBER_IMAGE, {
    onCompleted: () => {
      toast.success('Image uploaded successfully!')
    },
    refetchQueries: ['GET_ORG_CREDENTIALS']
  })
  const handleSubmit = async () => {
    await uploadImage({
      variables: {
        addOrEditOrgCredentialImageInput: {
          org_credential_id: membership.id,
          image: memberImage
        }
      }
    })

    rootStore.setLoading(false)
    toggle()
  }
  return (
    <Modal
      content={
        <div>
          {memberImage ? (
            <div>
              <img
                alt={''}
                className={'d-flex mb-8 mt-2'}
                src={`/api/s3/uploads/${memberImage}`}
                width={'100%'}
              />
            </div>
          ) : (
            <Text className="mb-3">
              <Text.Small className="text-muted d-none d-lg-block">
                Upload a photo of your membership card for faster verification. Files must be image files (no PDF).
              </Text.Small>
              <Text.Small className="text-muted d-block d-lg-none">
                Take a photo of your membership card, or upload an image file for faster verification. Files must be image files (no PDF).
              </Text.Small>
            </Text>
          )}
          <ReactS3Uploader
            accept="image/*"
            autoUpload={true}
            className="d-none"
            contentDisposition="auto"
            id="s3-upload"
            signingUrl="/api/s3/sign"
            onError={onError}
            onFinish={(response) => setMemberImage(response.fileKey)}
            onProgress={() => rootStore.setLoading(true)}
            uploadRequestHeaders={{
              'x-amz-acl': 'private'
            }}
          />
          <label htmlFor="s3-upload" className="cursor-pointer">
            <Icon name={'image'} className="mr-2" />
            {memberImage ? 'Change' : 'Upload'}
          </label>
          {memberImage && (
            <Button
              icon={'x'}
              className={'d-inline ml-2 text-danger'}
              color={'white'}
              onClick={() => setMemberImage(null)}
              size={'sm'}
            >
              Remove
            </Button>
          )}
          <Button
            onClick={handleSubmit}
            color="primary"
            className="float-right"
            style={{ marginRight: '10px' }}
          >
            SAVE
          </Button>
          <Button
            color="white"
            className="float-right mr-2"
            onClick={(e) => {
              e.preventDefault();
              toggle(false);
            }}
          >
            CANCEL
          </Button>
        </div>
      }
      open={isOpen}
      onClose={toggle}
      title={'Credentials'}
    />
  )
}

export default MemberCredentialImageUploadModal
