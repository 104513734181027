import { useQuery } from '@apollo/client';
import FeatureAccessGate from 'modules/common/components/FeatureAccessGate';
import moment from 'moment';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import { Grid, StampCard } from 'tabler-react';
import { UserStoreContext } from '../../../contexts/userStoreContext';
import { GET_LATEST_USER_EVENT_BY_TYPE_IDS } from '../../../graphql/GET_LATEST_USER_EVENT_BY_TYPE_IDS';
import { GET_STUDENT_RECENT_LOGBOOK_LOG } from '../../../graphql/GET_STUDENT_RECENT_LOGBOOK_LOG';
import { GET_STUDENT_TIME_TRANSACTION_HISTORY } from '../../../graphql/GET_STUDENT_TIME_TRANSACTION_HISTORY';
import { GET_USER_MERITS_BY_USER_ID } from '../../../graphql/GET_USER_MERITS_BY_STUDENT_ID';
import { useRootStore } from '../../../hooks';
import { EUSPATitles, lifetimeMemberTemplateId } from '../../constants';
import AccountBalance from '../AccountBalance';
import { daysBefore180 } from '../Forms/UserReserveLogForm';
import { EUserEventsId } from '../UserEvents';
import { EUserTypes } from '../userTypes';
import useReactRouter from 'use-react-router';
import { GET_USER_RESERVE_LOGS } from '../../../graphql/GET_USER_RESERVE_LOGS';
import useGetCurrentUserType from '../../../modules/common/hooks/useGetCurrentUserType';
import { GET_TEAM_ORGS } from '../../../graphql/GET_TEAM_ORGS';
import { GET_ORG_CREDENTIALS } from '../../../graphql/GET_ORG_CREDENTIALS';
import { Types } from '../../../types/graphql'

interface DzJumpReadyProps {
  accountBalance: any;
  toggleCardDisplay: (cardName: string) => void;
}

// TO DO: Currency Date - isCurrencyExpired
// If tunnel (sport_type_id=1) then currency is always within 365 days, and the color should be "warning" if expired (not danger)
// If skydiving (sport_type_id=2), then currency depends on the highest license (color is still danger if expired):

// D-License or C-License - 180 days (last jump must be within 180 days before today)
// B License - 90 days (last jump must be within 90 days before today)
// A License - 60 days (last jump must be within 60 days before today)
// Unknown License (ELSE) - 30 days (last jump must be within 30 days before today)

// display date format as days ("45 days", or "3 days")

export const isCurrencyExpired = (dateProp) => {
  const myDate = new Date(moment(dateProp).format('MM/DD/YYYY'));
  myDate.setDate(myDate.getDate() - 30);
  return new Date(moment(dateProp).format('MM/DD/YYYY')) < myDate;
};

const DzJumpReady = ({
  accountBalance,
  toggleCardDisplay
}: DzJumpReadyProps) => {
  const rootStore = useRootStore();
  const { currentUser, currentCoachTeam } = rootStore;
  const userStore = useContext(UserStoreContext);
  const { user } = userStore;
  const { history } = useReactRouter();
  const { isAdmin, isRigger, isStudent } = useGetCurrentUserType();

  const [userLogbookLog, setUserLogbookLog] =
    useState<Types.LogbookLog>();
  const [studentTimes, setStudentTimes] =
    useState<Types.StudentTimeTransactionHistory>();

  const { data: meritsData } = useQuery(GET_USER_MERITS_BY_USER_ID, {
    variables: {
      userId: currentUser.id.toString(),
      userType: EUserTypes.student
    }
  });

  const variables = {
    student_id: parseInt(user.id),
    team_id: currentCoachTeam?.id,
    user_event_type_ids: [EUserEventsId.physical_waiver]
  };

  const { data: dataWaiver } = useQuery(GET_LATEST_USER_EVENT_BY_TYPE_IDS, {
    variables
  });

  const { loading: studentLogbookLogLoading, data: studentLogbookLogData } =
    useQuery(GET_STUDENT_RECENT_LOGBOOK_LOG, {
      variables: {
        logbookLogFilter: {
          student_id: parseInt(user.id),
          sport_types_id: currentUser.type === EUserTypes.dzm ? 2 : 1
        }
      }
    });

  const { loading: studentTimesLoading, data: studentTimesData } = useQuery(
    GET_STUDENT_TIME_TRANSACTION_HISTORY,
    {
      variables: {
        studentId: parseInt(user.id),
        teamId: currentCoachTeam?.id,
        userType: EUserTypes.coach.toString()
      }
    }
  );

  const option = { student_id: user.id };
  if (currentUser.type === EUserTypes.student)
    option.student_id = currentUser.id.toString();

  const { data: userReserveLogData, loading: reserveLogLoading } = useQuery(
    GET_USER_RESERVE_LOGS,
    {
      variables: {
        order: 'newest',
        reserveLogOptions: { ...option }
      }
    }
  );

  const reservelog = useMemo(() => {
    return !reserveLogLoading && userReserveLogData?.getReserveLogs[0]
      ? userReserveLogData?.getReserveLogs[0]
      : null;
  }, [userReserveLogData, reserveLogLoading]);

  useEffect(() => {
    if (!studentLogbookLogLoading && studentLogbookLogData) {
      setUserLogbookLog(studentLogbookLogData?.getRecentLogbookLog);
    } else {
      setUserLogbookLog(null);
    }  
  }, [studentLogbookLogData]);

  useEffect(() => {
    let filtered = [];
    if (!studentTimesLoading && studentTimesData) {
      filtered = studentTimesData?.getStudentTimeTransactionHistory.filter(
        (transaction) => transaction.transactionSlug === 'used'
      );
      if (filtered.length > 0) setStudentTimes(filtered[0]);
    }  
  }, [studentTimesData]);

  const hasLifetimeMembership = user?.uspa.some(
    (item) => item.template_id === lifetimeMemberTemplateId
  );

  const memberLicense = useMemo(() => {
    if (hasLifetimeMembership) {
      return user?.uspa.find(
        (item) => item.template_title === 'Lifetime Member'
      );
    }

    return user?.uspa.find(
      (item) => item.template_title === EUSPATitles.Member
    );
  }, [hasLifetimeMembership, user?.uspa]);  

  const isMemberLicenseExpired = useMemo(
    () =>
      memberLicense?.merits_uspa_member_expiration &&
      new Date(memberLicense.merits_uspa_member_expiration) < new Date(),
    [memberLicense]
  );

  const userMerits = useMemo(
    () =>
      meritsData?.userMerits
        .filter((item) =>
          ['A-License', 'B-License', 'C-License', 'D-License'].includes(
            item.template_title
          )
        )
        .sort((a, b) => a.template_title.localeCompare(b.template_title)),
    [meritsData?.userMerits]  
  );

  const ADLicenses = user?.uspa
    .filter((item) =>
      ['A-License', 'B-License', 'C-License', 'D-License'].includes(
        item.template_title
      )
    )
    .sort((a, b) => a.template_title.localeCompare(b.template_title));

  const documentExpire = (dateProp) => {
    const yearAgo = new Date(
      new Date().setFullYear(new Date().getFullYear() - 1)
    );
    const pastDate = new Date(dateProp);

    return yearAgo <= pastDate;
  };

  //ORG CODE
  const orgFilter = !isAdmin && !isStudent ? currentCoachTeam.id : null;
  const { data: orgData, loading: orgLoading } = useQuery(GET_TEAM_ORGS, {
    variables: { teamId: orgFilter }
  });
  const { data: studentManualCredentials, loading: manualCredentialsLoading } =
    useQuery(GET_ORG_CREDENTIALS, {
      variables: { studentId: isStudent ? currentUser.id : user.id }
    });

  const primaryOrg = useMemo(() => {
    if (
      !orgLoading &&
      orgData &&
      !manualCredentialsLoading &&
      studentManualCredentials
    ) {
      //get primary = true/1
      const prime = orgData.teamOrgs.find((teamOrg: Types.TeamOrg) => {
        return teamOrg.is_primary;
      });
      const primeCredentialExist = studentManualCredentials.orgCredentials.find(
        (oc) => oc.org_id === prime?.org_id
      );
      if (primeCredentialExist) return prime;

      //return any existing manual org that has credential
      return orgData.teamOrgs.find((teamOrg: Types.TeamOrg) => {
        return studentManualCredentials.orgCredentials.find(
          (oc) => oc.org_id === teamOrg.org_id
        );
      });
    }

    return null;
  }, [orgData, orgLoading, studentManualCredentials, manualCredentialsLoading]);

  const orgCredentialMembership: Types.OrgCredential =
    useMemo(() => {
      if (studentManualCredentials && !manualCredentialsLoading) {
        const orgCredentials = studentManualCredentials.orgCredentials.filter(
          (oc) => oc.org_id === primaryOrg?.org_id
        );
        return orgCredentials.find((oc) => oc.membership);
      }
      return null;
    }, [studentManualCredentials, manualCredentialsLoading, primaryOrg]);

  const footerContent = () => {
    if (orgCredentialMembership) {
      return (
        <>
          {orgCredentialMembership.membership}
          <span
            className={
              moment().isBefore(orgCredentialMembership.expires)
                ? 'text-success ml-2'
                : 'text-danger ml-2 font-weight-bold'
            }
          >
            {moment(orgCredentialMembership.expires).format('MMM D, YYYY')}
          </span>
          {!orgCredentialMembership.verified_on &&
            <i className='fe fe-alert-circle text-danger ml-2' />
          }
        </>
      );
    }

    if (memberLicense?.merits_uspa_member_id) {
      return hasLifetimeMembership ? (
        memberLicense?.merits_uspa_member_id + ' Lifetime Member'
      ) : (
        <>
          {memberLicense?.merits_uspa_member_id}{' '}
          <span
            className={
              !isMemberLicenseExpired
                ? 'text-success ml-2'
                : 'text-danger ml-2 font-weight-bold'
            }
          >
            {moment(memberLicense?.merits_uspa_member_expiration).format(
              'MMM D, YYYY'
            )}
          </span>
        </>
      );
    }

    return <>Unknown</>;
  };

  return (
    <>
      <Grid.Row>
        <>
          <Grid.Col sm={6} lg={4} xs={12} className="mb-0">
            <Link
              className="text-decoration-none text-gray-dark"
              to={`#`}
              onClick={() => toggleCardDisplay('showUSPA')}
            >
              <StampCard
                color={
                  (memberLicense?.merits_uspa_member_expiration &&
                    !isMemberLicenseExpired) ||
                  hasLifetimeMembership
                    ? 'success'
                    : 'danger'
                }
                icon={
                  (memberLicense?.merits_uspa_member_expiration &&
                    !isMemberLicenseExpired) ||
                  hasLifetimeMembership
                    ? 'user-check'
                    : 'user-x'
                }
                header={primaryOrg ? primaryOrg?.slug.toUpperCase() : 'USPA'}
                footer={<>{footerContent()}</>}
              />
            </Link>
          </Grid.Col>
        </>
        {EUserTypes.rigger !== currentUser.type && (
          <Grid.Col sm={6} lg={4} xs={12} className="mb-0">
            <Link
              className="text-decoration-none text-gray-dark"
              to={`#`}
              onClick={() => toggleCardDisplay('showCurrency')}
            >
              <StampCard
                color={
                  userLogbookLog && !isCurrencyExpired(userLogbookLog?.date)
                    ? 'success'
                    : 'danger'
                }
                icon={
                  userLogbookLog && !isCurrencyExpired(userLogbookLog?.date)
                    ? 'user-check'
                    : 'user-x'
                }
                header={'Currency'}
                footer={
                  userLogbookLog
                    ? moment(userLogbookLog?.date).format('MMM D, YYYY')
                    : studentTimes
                    ? moment(studentTimes.createdOn).format('MMM D, YYYY')
                    : ' Unknown'
                }
              />
            </Link>
          </Grid.Col>
        )}
        {EUserTypes.admin !== currentUser.type && (
          <FeatureAccessGate feature="feature_accounting">
            <Grid.Col sm={6} lg={4} xs={12} className="mb-0">
              <Link
                className="text-decoration-none text-gray-dark"
                to={'#'}
                onClick={(e) => {
                  e.preventDefault();
                  if (currentUser.type === EUserTypes.admin) {
                    return;
                  }
                  history.push(
                    `/user-details/${user.id}/student-teams/${currentCoachTeam?.id}`
                  );
                }}
              >
                <StampCard
                  color={
                    accountBalance
                      ? accountBalance?.getAccountBalance?.total_time > 0
                        ? 'success'
                        : accountBalance?.getAccountBalance?.total_time < 0
                        ? 'danger'
                        : 'danger'
                      : ''
                  }
                  icon={
                    accountBalance?.getAccountBalance?.total_time > 0
                      ? 'user-check'
                      : 'user-x'
                  }
                  header={'Account'}
                  footer={
                    <AccountBalance
                      accountBalance={
                        accountBalance?.getAccountBalance?.total_time ?? 0
                      }
                      slug={currentCoachTeam?.account_type_slug}
                    />
                  }
                />
              </Link>
            </Grid.Col>
          </FeatureAccessGate>
        )}
        {EUserTypes.dzm === currentUser.type && (
          <>
            <Grid.Col sm={6} lg={4} xs={12} className="mb-0">
              <StampCard
                color={
                  ADLicenses?.length > 0
                    ? 'success'
                    : userMerits?.length > 0
                    ? 'success'
                    : 'danger'
                }
                icon={
                  ADLicenses?.length > 0
                    ? 'user-check'
                    : userMerits?.length > 0
                    ? 'user-check'
                    : 'user-x'
                }
                header={'License'}
                footer={
                  ADLicenses?.length > 0
                    ? ADLicenses[ADLicenses.length - 1].template_title
                    : userMerits?.length > 0
                    ? userMerits[userMerits.length - 1].template_title
                    : 'Unknown'
                }
              />
            </Grid.Col>

            <Grid.Col sm={6} lg={4} xs={12} className="mb-0">
              <Link
                className="text-decoration-none text-gray-dark"
                to={`#`}
                onClick={() => toggleCardDisplay('showGearAndReserve')}
              >
                <StampCard
                  color={
                    reservelog && daysBefore180(reservelog?.event_date) > 0
                      ? 'success'
                      : 'danger'
                  }
                  icon={
                    reservelog && daysBefore180(reservelog?.event_date) > 0
                      ? 'user-check'
                      : 'user-x'
                  }
                  header={'Gear Check'}
                  // display how many days until reserve is expired, if expired display how many days past. Display "Unknown" if no reserve records.
                  footer={
                    reservelog && daysBefore180(reservelog?.event_date) > 0 ? (
                      daysBefore180(reservelog?.event_date) + ' days'
                    ) : (
                      <span className="text-danger">
                        {reservelog
                          ? daysBefore180(reservelog?.event_date) + ' days'
                          : 'Unknown'}
                      </span>
                    )
                  }
                />
              </Link>
            </Grid.Col>
          </>
        )}
        {(isAdmin || EUserTypes.rigger === currentUser.type) && (
          <>
            <Grid.Col sm={6} lg={4} xs={12} className="mb-0">
              <Link
                className="text-decoration-none text-gray-dark"
                to={'#'}
                onClick={() => toggleCardDisplay('showReservelog')}
              >
                <StampCard
                  color={
                    userLogbookLog && !isCurrencyExpired(userLogbookLog?.date)
                      ? 'success'
                      : 'danger'
                  }
                  icon={
                    userLogbookLog && !isCurrencyExpired(userLogbookLog?.date)
                      ? 'user-check'
                      : 'user-x'
                  }
                  header={'Reserve'}
                  footer={
                    userLogbookLog
                      ? moment(userLogbookLog?.date).format('MMM D, YYYY')
                      : studentTimes
                      ? moment(studentTimes.createdOn).format('MMM D, YYYY')
                      : ' Unknown'
                  }
                />
              </Link>
            </Grid.Col>
            <Grid.Col sm={6} lg={4} xs={12} className="mb-0">
              <Link
                className="text-decoration-none text-gray-dark"
                to={`#`}
                onClick={() =>
                  isRigger
                    ? toggleCardDisplay('showGears')
                    : toggleCardDisplay('showGearAndReserve')
                }
              >
                <StampCard
                  color={'success'}
                  icon={'briefcase'}
                  header={'Gear'}
                  footer={'View Gear'}
                />
              </Link>
            </Grid.Col>
          </>
        )}
        <FeatureAccessGate feature="feature_waivers">
          <Grid.Col sm={6} lg={4} xs={12} className="mb-0">
            <StampCard
              color={
                dataWaiver?.getLatestUserEventByTypeIds?.created_on &&
                documentExpire(
                  dataWaiver?.getLatestUserEventByTypeIds?.created_on
                )
                  ? 'success'
                  : 'danger'
              }
              icon={
                dataWaiver?.getLatestUserEventByTypeIds?.created_on
                  ? 'user-check'
                  : 'user-x'
              }
              header={
                <Link
                  className="text-decoration-none"
                  to={`#`}
                  onClick={() => toggleCardDisplay('showWaiver')}
                >
                  Waiver
                </Link>
              }
              footer={
                dataWaiver?.getLatestUserEventByTypeIds?.created_on
                  ? moment(
                      dataWaiver?.getLatestUserEventByTypeIds?.created_on
                    ).format('MMM D, YYYY')
                  : 'Unknown'
              }
            />
          </Grid.Col>
        </FeatureAccessGate>
      </Grid.Row>
    </>
  );
};

export default DzJumpReady;
