import gql from 'graphql-tag';

export const ADD_INVOICE = gql`
  mutation ADD_INVOICE($addInvoiceInput: AddInvoiceInput!) {
    addInvoice(addInvoiceInput: $addInvoiceInput) {
      id
      student_id
      invoice_type_id
      status
      invoice_notes
      created_by
      company_name
      company_phone
      company_email
      customer_name
      customer_phone
      customer_email
      student{
        first_name
        last_name
      }
    }
  }
`;
