export const baseStatusList = [
  'Active',
  'Open',
  'Progress',
  'Complete',
  'Hold',
  'Parts',
  'Selling'
]

export const adminStatusList = baseStatusList.concat([
  'Invoice',
  'Delete',
  'Closed'
])
