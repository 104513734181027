import { observer } from 'mobx-react';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Dropdown, Icon, Table, Tag, Text } from 'tabler-react';
import useReactRouter from 'use-react-router';

import UpdateInlineLocation from './Form/UpdateInlineLocation';

const PARAGRAPH_MAX_LIMIT = 30;

interface ILocationListItemProps {
  location: any;
}

const LocationListItem = ({ location }: ILocationListItemProps) => {
  const { history } = useReactRouter();
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [locationClone] = useState(location);

  const completeAddress = [
    location.address,
    location.city,
    location.province,
    location.postal
  ].join(' ');

  return isEditing ? (
    <UpdateInlineLocation
      locationClone={locationClone}
      location={location}
      setIsEditing={setIsEditing}
    />
  ) : (
    <>
      <Table.Col>
      {location.is_frozen && ( <Icon link name="lock" /> )
          }
      </Table.Col>
      <Table.Col>
        <Link to={`/locations/${location.id}`}>{location.name}</Link>
        <Text.Small className="d-block">{location.type}</Text.Small>
      </Table.Col>
      <Table.Col>
        {completeAddress.length > PARAGRAPH_MAX_LIMIT
          ? completeAddress.substring(0, PARAGRAPH_MAX_LIMIT) + '...'
          : completeAddress}
        <Text.Small className="d-block">{location.city} {location.province} {location.postal_code} {location.country}</Text.Small>
      </Table.Col>
      <Table.Col>
        {location.open_since &&
          new Date(location.open_since).toLocaleDateString()}
      </Table.Col>
      <Table.Col>
        <React.Fragment>
          {location.status ? (
            <Tag color="primary">
              {location.status}
            </Tag>
          ) : null}
        </React.Fragment>
      </Table.Col>
      <Table.Col>
        <Dropdown
          triggerContent="Actions"
          items={
            <Dropdown.Item
              className="cursor-pointer"
              onClick={(e) => {
                e.preventDefault();
                history.push(`/admin/location-details/${location.id}`);
              }}
            >
              Update
            </Dropdown.Item>
          }
        />
      </Table.Col>
    </>
  );
};
export default observer(LocationListItem);
