import React from 'react';
import { Container } from 'tabler-react';

import OnDeckList from '../../components/OnDeck/CoachDashboard';
import Wrapper from '../../pages/layout';

const OnDeckPage = (props) => {
  return (
    <Wrapper {...props} title="Dashboard">
      <Container>
        <OnDeckList />
      </Container>
    </Wrapper>
  );
};

export default OnDeckPage;
