import gql from 'graphql-tag';

export const GET_STUDENT_TEAM_ACCOUNT_BALANCE_HISTORY = gql`
  query GET_STUDENT_TEAM_ACCOUNT_BALANCE_HISTORY(
    $studentId: ID!
    $teamId: Int!
    $userType: String!
  ) {
    getStudentTeamAccountBalanceHistory(
      studentId: $studentId
      teamId: $teamId
      userType: $userType
    ) {
      id
      account_type_slug
      team_name
      total_time
      updated_on
    }
  }
`;
