import { useMutation } from "@apollo/client";
import { UserStoreContext } from "contexts/userStoreContext";
import { Formik } from "formik";
import { GET_STUDENT_TEAM_ACCOUNT_BALANCE_HISTORY } from "graphql/GET_STUDENT_TEAM_ACCOUNT_BALANCE_HISTORY";
import { GET_STUDENT_TIME_TRANSACTION_HISTORY } from "graphql/GET_STUDENT_TIME_TRANSACTION_HISTORY";
import { GET_STUDENT_TRANSACTIONS_BY_DATE } from "graphql/GET_STUDENT_TRANSACTIONS_BY_DATE";
import { GET_STUDENTS_ON_DASHBOARD } from "graphql/GET_STUDENTS_ON_DASHBOARD";
import { observer } from "mobx-react";
import moment from "moment";
import { useContext } from "react";
import Select from "react-select";
import { toast } from "react-toastify";
import { Button, Form, Grid } from "tabler-react";
import { UPDATE_TRANSACTION } from "../../../graphql/UPDATE_TRANSACTION";
import { useRootStore } from "../../../hooks";
import { accountTransactionTypes } from "../../constants";
import Modal from "../../Modal";

interface IEditTransactionFormProps {
  isModalOpen: boolean;
  toggleModal: any;
  transaction: any; //GET_TRANSACTIONS_getTransactions_transactions;
}

const EditTransactionForm = ({
  isModalOpen,
  toggleModal,
  transaction,
}: IEditTransactionFormProps) => {
  const userStore = useContext(UserStoreContext);
  const { user } = userStore;
  const rootStore = useRootStore();
  const { currentUser, currentCoachTeam } = rootStore;

  const [updateTransaction] = useMutation(UPDATE_TRANSACTION);
  const handleClose = () => {
    toggleModal(false);
  };
  const renderForm = ({
    values,
    handleSubmit,
    isSubmitting,
    handleChange,
    setFieldValue,
    errors,
  }) => (
    <form onSubmit={handleSubmit}>
      <Grid.Row className="mb-4">
        <Grid.Col xs={12} sm={12} lg={12}>
          <Form.Group label="">
            {(values.transaction_type_id === 1 ||
              values.transaction_type_id === 2 ||
              values.transaction_type_id === 5) && (
              <Select
                name="transaction_type_id"
                placeholder="Select transaction type..."
                isMulti={false}
                getOptionValue={(option) => option.id}
                options={accountTransactionTypes[transaction.timeAdded]}
                onChange={(param) =>
                  setFieldValue("transaction_type_id", param.id)
                }
                value={accountTransactionTypes[transaction.timeAdded].filter(
                  (transactionType) =>
                    transactionType.id === values.transaction_type_id,
                )}
              />
            )}
            {errors.transaction_type_id && (
              <span className="field-error text-danger">
                {errors.transaction_type_id}
              </span>
            )}
          </Form.Group>
        </Grid.Col>
        <Grid.Col xs={12} sm={12} lg={12}>
          <Form.Group label="">
            <Form.Textarea
              //  className={errors.note && 'is-invalid'}
              name="notes"
              placeholder="Add notes"
              rows={3}
              type="text"
              onChange={handleChange}
              value={values.notes}
            />
            {errors.notes && (
              <span className="field-error text-danger">{errors.notes}</span>
            )}
          </Form.Group>
        </Grid.Col>
      </Grid.Row>
      <Grid.Row className="mb-5"></Grid.Row>

      <Button
        disabled={isSubmitting}
        pill
        className="btn-gray-dark float-right btn-sm"
        onClick={handleSubmit}
      >
        {isSubmitting ? "Processing..." : "UPDATE"}
      </Button>
      {values.transaction_type_id !== 3 && !transaction?.cancelledOn && (
        <Button
          pill
          color="danger"
          className="btn-sm"
          onClick={(e) => {
            e.preventDefault();
            setFieldValue("transaction_type_id", 6);
            handleSubmit();
          }}
        >
          VOID
        </Button>
      )}
    </form>
  );
  return (
    <Modal
      content={
        <Formik
          enableReinitialize={true}
          initialValues={{
            id: transaction?.id ?? null,
            notes: transaction?.notes ?? "",
            transaction_type_id: transaction?.transactionTypeId ?? undefined,
          }}
          onSubmit={async (values, { resetForm, setSubmitting }) => {
            const canceled_options = {
              canceled_by: undefined,
              canceled_on: undefined,
            };
            if (values.transaction_type_id === 6 && !transaction?.cancelledOn) {
              canceled_options.canceled_by = currentUser.id;
              canceled_options.canceled_on = moment();
            }
            await updateTransaction({
              variables: {
                updateTransactionInput: {
                  id: parseInt(values.id),
                  notes: values.notes,
                  transaction_type_id: values.transaction_type_id,
                  ...canceled_options,
                },
              },
              refetchQueries: [
                {
                  query: GET_STUDENT_TRANSACTIONS_BY_DATE,
                  variables: {
                    studentId: user.id,
                    teamId: currentCoachTeam.id,
                    date: new Date().toISOString().split("T")[0],
                  },
                },
                {
                  query: GET_STUDENT_TIME_TRANSACTION_HISTORY,
                  variables: {
                    studentId: Number(user.id),
                    teamId: currentCoachTeam.id,
                    userType: currentUser.type,
                  },
                },
                {
                  query: GET_STUDENT_TEAM_ACCOUNT_BALANCE_HISTORY,
                  variables: {
                    studentId: user.id,
                    teamId: currentCoachTeam.id,
                    userType: currentUser.type,
                  },
                },
                {
                  query: GET_STUDENTS_ON_DASHBOARD,
                  variables: {
                    team_id: currentCoachTeam.id,
                  },
                },
              ],
            });

            setSubmitting(false);
            resetForm();
            toast.success("Transaction updated!");
            handleClose();
          }}
        >
          {(formikData) => renderForm(formikData)}
        </Formik>
      }
      open={isModalOpen}
      onClose={() => toggleModal(!isModalOpen)}
      title={`Transaction ID: ${transaction.id}`}
    />
  );
};

export default observer(EditTransactionForm);
