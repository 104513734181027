export enum ECampFeeTypes {
  slot = "per slot",
  hour = "per hour",
  day = "per day",
  event = "per event",
  jump = "per jump",
  competitor = "per competitor",
  group = "per group",
  discount = "fee discount",
}

export const CURRENCIES = [
  { value: "AED", label: "AED" },
  { value: "AUD", label: "AUD" },
  { value: "BRL", label: "BRL" },
  { value: "CAD", label: "CAD" },
  { value: "CHF", label: "CHF" },
  { value: "CNY", label: "CNY" },
  { value: "CZK", label: "CZK" },
  { value: "EUR", label: "EUR" },
  { value: "GBP", label: "GBP" },
  { value: "JPY", label: "JPY" },
  { value: "MOP", label: "MOP" },
  { value: "PHP", label: "PHP" },
  { value: "USD", label: "USD" },
  { value: "ZAR", label: "ZAR" },
];
