import { useQuery } from '@apollo/client'
import { GET_CAMP_BY_ID } from 'graphql/GET_CAMP_BY_ID'
import { GET_CAMP_BY_ID as GET_CAMP_BY_ID_TYPE } from 'graphql/types/GET_CAMP_BY_ID'

const useGetCampById = (campId: number) => {
  const { data, loading } = useQuery<GET_CAMP_BY_ID_TYPE>(GET_CAMP_BY_ID, {
    variables: {
      campId
    }
  })

  return { data, loading }
}

export default useGetCampById
