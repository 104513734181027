import { convertToTime } from 'utils/convertToTime';

const locales: { [key: string]: string } = {
  USD: 'en-US',
  CAD: 'en-CA',
  EUR: 'en-IE'
};

export const formatAccountBalance = (accountBalance: number, slug: string) => {
  switch (slug) {
    case 'time': {
      return convertToTime(accountBalance);
    }

    case 'USD':
    case 'CAD':
    case 'EUR': {
      // map slug to locale
      return accountBalance.toLocaleString(locales[slug], {
        style: 'currency',
        currency: slug
      });
    }

    default: {
      break;
    }
  }

  return accountBalance;
};
