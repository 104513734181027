export enum EDZMUserRoles {
  admin = 'Admin',
  camera = 'Camera',
  customer = 'Customer',
//  dzo = 'DZ Operator',
  go = 'Group Organizer',
  lo = 'Load Organizer',
  riggermast = 'Master Rigger',
  packer = 'Packer',
  pilot = 'Pilot',
  rigger = 'Senior Rigger',
  jumper = 'Sport Jumper',
  staff = 'Staff',
  student = 'Student',
  tandem = 'Tandem Master',
  ctand = 'Tandem'
}

export const DZMUserRoleMap = [
  { value: 1, label: 'Admin' },
  { value: 11, label: 'Camera' },
  { value: 7, label: 'Customer' },
  //  { value: 4, label: 'DZ Operator' },
  { value: 13, label: 'Group Organizer' },
  { value: 12, label: 'Load Organizer' },
  { value: 14, label: 'Master Rigger' },
  { value: 10, label: 'Packer' },
  { value: 6, label: 'Pilot' },
  { value: 9, label: 'Senior Rigger' },
  { value: 33, label: 'Sport Jumper' },
  { value: 5, label: 'Staff' },
  { value: 2, label: 'Student' },
  { value: 17, label: 'Tandem' },
  { value: 8, label: 'Tandem Master' }
];

export enum ECoachUserRoles {
  admin = 'Admin',
  staff = 'Staff',
  coach = 'Coach',
}

export enum EAcademyUserRoles {
  admin = 'Admin',
  staff = 'Staff',
  trainer = 'Trainer',
  fighter = 'Fighter',
}

export enum ERiggerUserRoles {
  admin = 'Admin',
  riggermast = 'Master Rigger',
  packer = 'Packer',
  rigger = 'Senior Rigger',
  staff = 'Staff'
}

export enum EEventsUserRoles {
  admin = 'Admin',
  go = 'Group Organizer',
  lo = 'Load Organizer',
  org = 'Organizer',
  packer = 'Packer',
  staff = 'Staff'
}

export enum EWtmUserRoles {
  admin = 'Admin',
  coach = 'Coach',
  ftf = 'First Time Flyer',
  flyer = 'Sport Flyer',
  staff = 'Staff'
}

export enum EManufacturerUserRoles {
  admin = 'Admin',
  athlete = 'Athlete',
  staff = 'Staff'
}

export enum EOrgUserRoles {
  admin = 'Admin',
  finance = 'Finance Secretary',
  controller = 'Controller',
  staff = 'Staff'
}
