import React, { useMemo } from 'react';
import { Button, Card, Dimmer, Table, Text } from 'tabler-react';
import { GET_AIRCRAFTS_BY_TEAM_ID } from '../../graphql/GET_AIRCRAFTS_BY_TEAM_ID';
import { useMutation, useQuery } from '@apollo/client';
import AddTeamAircraftModal from './Modal/AddTeamAircraftModal';
import { DELETE_TEAM_AIRCRAFT } from '../../graphql/DELETE_TEAM_AIRCRAFT';
import { toast } from 'react-toastify';

const AircraftList = ({ teamId }) => {
  const [selectedAircraft, setSelectedAircraft] = React.useState(null);
  const { data, loading } = useQuery(GET_AIRCRAFTS_BY_TEAM_ID, {
    variables: { team_id: teamId }
  });

  const aircraftList = useMemo(() => {
    if (!loading && data.teamAircrafts.length > 0) return data.teamAircrafts;

    return [];
  }, [data, loading]);
  const [open, setOpen] = React.useState(false);

  const [deleteTeamAirCraft] = useMutation(DELETE_TEAM_AIRCRAFT, {
    onCompleted: (data) => {
      toast.success('Aircraft deleted successfully');
    },
    refetchQueries: ['GET_AIRCRAFTS_BY_TEAM_ID', 'GET_AIRCRAFTS']
  });
  return (
    <>
      <Card>
        <Card.Header>
          <Card.Title>Aircraft Management</Card.Title>
          <Card.Options>
            <Button
              icon={'plus'}
              color="primary"
              onClick={() => {
                setSelectedAircraft(null);
                setOpen(true);
              }}
            >
              AIRCRAFT
            </Button>
          </Card.Options>
        </Card.Header>
        <Card.Body>
          <Dimmer active={loading}>
            <Table>
              <Table.Header>
                <Table.Row>
                  <Table.ColHeader>Name</Table.ColHeader>
                  <Table.ColHeader>Tail</Table.ColHeader>
                  <Table.ColHeader>Type</Table.ColHeader>
                  <Table.ColHeader>Capacity</Table.ColHeader>
                  <Table.ColHeader>Action</Table.ColHeader>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {!loading &&
                  aircraftList.map((aircraft) => (
                    <Table.Row key={aircraft.id}>
                      <Table.Col>{aircraft.name}</Table.Col>
                      <Table.Col>{aircraft.tail_number}</Table.Col>
                      <Table.Col>{aircraft.aircraft_type}</Table.Col>
                      <Table.Col>
                        <Text>Min: {aircraft.capacity_min}</Text>
                        <Text>Max: {aircraft.capacity_max}</Text>
                      </Table.Col>
                      <Table.Col>
                        <Button
                          icon="edit"
                          color="white"
                          className="text-primary"
                          size="sm"
                          onClick={() => {
                            setSelectedAircraft(aircraft);
                            setOpen(true);
                          }}
                        >
                          Edit
                        </Button>
                        <Button
                          icon="x-circle"
                          color="white"
                          className="text-muted"
                          size="sm"
                          onClick={() => {
                            if (
                              window.confirm(
                                'Are you sure you want to delete this aircraft from the team?'
                              )
                            ) {
                              // Delete aircraft
                              deleteTeamAirCraft({
                                variables: {
                                  teamId,
                                  aircraftId: aircraft.id
                                }
                              });
                            }
                          }}
                          disabled
                        >
                          Delete
                        </Button>
                      </Table.Col>
                    </Table.Row>
                  ))}
              </Table.Body>
            </Table>
          </Dimmer>
        </Card.Body>
      </Card>
      <AddTeamAircraftModal
        open={open}
        onClose={() => setOpen(false)}
        aircraft={selectedAircraft}
        teamId={teamId}
      />
    </>
  );
};

export default AircraftList;
