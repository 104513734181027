import { useLazyQuery } from "@apollo/client";
import { GET_PAYMENTS } from "graphql/GET_PAYMENTS";
import { GET_PAYMENTS as GET_PAYMENTS_TYPE } from "graphql/types/GET_PAYMENTS";
import { EUserFilterOptions } from "../../../components/enum";
import { GET_STUDENTS_BY_FILTER_QUERY } from "../../../graphql/GET_STUDENTS_BY_FILTER_QUERY";
import { GET_STUDENTS_BY_FILTER_QUERY as GET_STUDENTS_BY_FILTER_QUERY_TYPE } from "../../../graphql/types/GET_STUDENTS_BY_FILTER_QUERY";
import { useRootStore } from "../../../hooks";
import { EOrderOptions } from "../../../hooks/useAutocomplete";

const UseGetPayments = ({ dateFilter, manualPayment, cardBrand }) => {
  const { currentCoachTeam } = useRootStore();
  const [getPayments, { data, loading }] =
    useLazyQuery<GET_PAYMENTS_TYPE>(GET_PAYMENTS);

  const loadData = async () => {
    await getPayments({
      variables: {
        params: {
          team_id: currentCoachTeam?.id,
          date_filter: dateFilter,
          manual_payment: manualPayment,
          card_brand: cardBrand,
        },
      },
    });
  };

  return { data, loading, loadData };
};

export { UseGetPayments };
