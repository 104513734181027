import gql from 'graphql-tag';

export const GET_RIGS = gql`
  query GET_RIGS($rigListFilter: RigListFilter!) {
    rigList(filter: $rigListFilter) {
      id
      name
      student_id
      team_id
      rig_gear {
        user_rig_id
        user_gear_id
        gear_type_id
        gear_model_name
        gear_canopy_size
        event_date
      }
      student {
        id
        first_name
        last_name
      }
      team {
        id
        name
      }
    }
  }
`;
