import gql from 'graphql-tag';

export const GET_CRM_MESSAGES = gql`
  query GET_CRM_MESSAGES($studentId: Int!, $userRegId: Int!) {
    getCRMMessages(studentId: $studentId, userRegId: $userRegId) {
      id
      message
      coach_first_name
      coach_last_name
      created_on
    }
  }
`;
