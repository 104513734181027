import { EPaymentStatus } from 'modules/payment/constants/paymentStatus'

interface Payment {
  status: string
  amount: number
}

export const calcTotalPaid = (payments: Payment[]) => {
  return payments.reduce((paidTotal, current) => {
    if (current.status === EPaymentStatus.paid) {
      return paidTotal + current.amount
    }
    return paidTotal
  }, 0)
}

export const calcTotalByStatus = (payments: Payment[], status: string) => {
  return payments.reduce((total, current) => {
    if (current.status === status) {
      return total + current.amount
    }
    return total
  }, 0)
}
