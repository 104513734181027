import React from 'react';
import { Container } from 'tabler-react';

import Wrapper from 'pages/layout';
import AdminGearsInformation from '../../components/Admin/AdminGearsInformation';

const GearListPage = (props) => {
  return (
    <Wrapper {...props} title="Admin Gear">
      <Container>
        <AdminGearsInformation />
      </Container>
    </Wrapper>
  );
};
export default GearListPage;
