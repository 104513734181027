import moment from 'moment';
import React from 'react';
import { Button, Table, Text } from 'tabler-react';
import Modal from '../Modal';
import { Types } from '../../types/graphql'

const ConfirmedSlotsModal = ({
  userRegistrationDetails,
  isModalOpen,
  toggleModal
}) => {
  const { confirmedSlots } = userRegistrationDetails;

  return (
    <Modal
      actions={
        <>
          <Button
            icon="calendar"
            color="secondary"
            href="#"
            disabled
          >
            SLOTS
          </Button>
          <Button
            color="primary"
            href="#"
            onClick={(e) => {
              e.preventDefault();
              toggleModal();
            }}
          >
            CLOSE
          </Button>
        </>
      }
      className="slots-confirmed-modal"
      content={
        <Table className="mb-0">
          <Table.Body>
            {confirmedSlots.map(
              (
                slot: Types.CampStudent,
                idx: string | number
              ) => {
                const { slotCoach, slot_option, start, user_duration } = slot;

                return (
                  <Table.Row key={idx}>
                    <Table.Col className="d-flex align-items-center">
                      <span>
                        {moment(start).format('ddd, MMM D - h:mma')}
                        <div>
                          <small>
                            <i>{slot_option[0].name}</i>
                          </small>{' '}
                          {slotCoach.length > 0 &&
                            `| ${slotCoach[0].first_name} ${slotCoach[0].last_name}`}
                        </div>
                      </span>
                      <Text className="text-success ml-auto">
                        {user_duration} mins
                      </Text>
                    </Table.Col>
                  </Table.Row>
                );
              }
            )}
          </Table.Body>
        </Table>
      }
      open={isModalOpen}
      title=""
      onClose={toggleModal}
    />
  );
};

export default ConfirmedSlotsModal;
