export const validateQuestionResponse = (question, userAnswer) => {
  if (!question.is_required) return true;

  // min number
  if (question.registration_question_type_id === 4) {
    const floatValue = parseFloat(userAnswer);
    return floatValue >= question.answer_restriction;
  }

  //max number
  if (question.registration_question_type_id === 5) {
    const floatValue = parseFloat(userAnswer);
    return floatValue <= question.answer_restriction;
  }

  // min characters
  if (question.registration_question_type_id === 6) {
    return userAnswer.length >= question.answer_restriction;
  }

  //max characters
  if (question.registration_question_type_id === 7) {
    return userAnswer.length <= question.answer_restriction;
  }

  return !!userAnswer;
};
