import { useMutation } from "@apollo/client";
import EventGroupsListItemEditName from "components/Calendar/Forms/EventGroupsListItemEditName";
import GroupFormModal from "components/Calendar/Modal/GroupFormModal";
import { DELETE_GROUP } from "graphql/DELETE_GROUP";
import { EDIT_GROUP } from "graphql/EDIT_GROUP";
import { GET_GROUPS } from "graphql/GET_GROUPS";
import React, { useMemo } from "react";
import { CSVLink } from "react-csv";
import { toast } from "react-toastify";
import { Button, Card, Dropdown, Icon, Tag, Text } from "tabler-react";
import useRouter from "use-react-router";
import { Types } from "../../types/graphql";

interface EventGroupsListItemProps {
  group: Types.Group;
  groupList: Types.Group[];
}

const CompetitionGroupsListItem = ({
  group,
  groupList,
}: EventGroupsListItemProps) => {
  const { history } = useRouter();
  const [isEditting, setIsEditting] = React.useState(false);
  const [deleteGroup] = useMutation(DELETE_GROUP, {
    onCompleted: () => {
      toast.success("Group deleted");
    },
    refetchQueries: ["GET_GROUPS"],
  });
  const [isEditModalOpen, setIsEditModalOpen] = React.useState(false);
  const [removeGroup] = useMutation(EDIT_GROUP, {
    onCompleted: () => {
      toast.success("Group updated");
    },
  });

  const groupMembersLength = useMemo(() => {
    return group.members.length;
  }, [group.members]);

  const handleDelete = () => {
    if (groupMembersLength > 0) {
      return;
    }
    if (window.confirm("Are you sure you want to delete this group?")) {
      deleteGroup({
        variables: {
          id: group.id,
        },
      });
    }
  };

  const handleRemove = () => {
    if (
      window.confirm(
        "Are you sure you want to move this group to the dashboard?",
      )
    ) {
      removeGroup({
        variables: {
          updateGroupInput: {
            id: group.id,
            camp_id: null,
          },
        },
        refetchQueries: [
          {
            query: GET_GROUPS,
            variables: {
              filter: {
                camp_id: Number(group.camp_id),
                team_id: Number(group.team_id),
                status: "Active",
              },
            },
          },
        ],
      });
    }
  };

  const handleArchive = () => {
    if (window.confirm("Are you sure you want to archive this group?")) {
      removeGroup({
        variables: {
          updateGroupInput: {
            id: group.id,
            status: "Archived",
          },
        },
        refetchQueries: [
          {
            query: GET_GROUPS,
            variables: {
              filter: {
                camp_id: Number(group.camp_id),
                team_id: Number(group.team_id),
                status: "Active",
              },
            },
          },
        ],
      });
    }
  };

  const groupMembers = useMemo(
    () =>
      group.members.map((member) => ({
        id: member.id,
        first_name: member.first_name,
        last_name: member.last_name,
        phone_country_code: member.phone_country_code,
        phone_number: member.phone_number,
        email: member.email,
      })),
    [group.members],
  );

  return (
    <>
      <Card className="group-card">
        <Card.Header
          backgroundURL={group.image && `/api/s3/uploads/${group.image}`}
          className={
            !group.image && group.color
              ? "px-3 py-1 text-white bg-" + group.color
              : "px-3 py-1 text-white opacity bg-gray-dark"
          }
          style={{ maxHeight: "100%", maxWidth: "10px" }}
        >
          <Card.Title
            className={
              group.color
                ? "cursor-pointer px-3 py-1 text-white bg-" + group.color
                : ""
            }
          >
            {isEditting && (
              <EventGroupsListItemEditName
                group={group}
                toggleEdit={setIsEditting}
              />
            )}
            {!isEditting && (
              <>
                <Text onClick={() => setIsEditting(true)}>
                  {group.name}
                  <Tag color={group.color} className="ml-2">
                    {groupMembersLength}
                  </Tag>
                </Text>
                <Text.Small className={"text-white mb-0 mt-1"}>
                  {group.discipline}
                </Text.Small>
              </>
            )}
          </Card.Title>
          <Card.Options>
            <Dropdown
              className={
                "float-right cursor-pointer " + (group.color && "text-white")
              }
              toggle={false}
              arrow
              triggerContent={<Icon name="more-vertical" />}
              items={[
                <>
                  <Dropdown.Item icon="download-cloud" as="Button">
                    <CSVLink
                      data={groupMembers}
                      filename="event_group_members.csv"
                      className="ml-0 text-dark"
                    >
                      CSV
                    </CSVLink>
                  </Dropdown.Item>
                  <Dropdown.Item
                    icon="edit"
                    onClick={() => setIsEditModalOpen(true)}
                  >
                    Edit
                  </Dropdown.Item>
                  <Dropdown.Item icon="home" onClick={handleRemove}>
                    Move
                  </Dropdown.Item>
                  <Dropdown.Item icon="minus-circle" onClick={handleArchive}>
                    Archive
                  </Dropdown.Item>
                  <Dropdown.Item icon="x-circle" onClick={handleDelete}>
                    Delete
                  </Dropdown.Item>
                </>,
              ]}
            />
          </Card.Options>
        </Card.Header>
        <Card.Body>
          {group.members.map((member) => {
            // Check if student is in multiple groups
            // If so, display indicator icon
            const studentGroups = groupList.filter((group) =>
              group.members.some((student) => student.id === member.id),
            );

            return (
              <div key={member.id}>
                <Button
                  block
                  color="primary"
                  className="registered-student mb-1 p-1 text-left d-flex align-items-center w-100"
                  size="sm"
                  title={`${member.first_name} ${member.last_name}`}
                  onClick={() => {
                    history.push(
                      `/events/registrations/${member.registration_id}/${member.id}`,
                    );
                  }}
                >
                  <div className="d-block w-100">
                    <span>
                      {studentGroups.length > 1 ? (
                        <Icon name="users" className="mr-1" />
                      ) : (
                        <Icon name="user" className="mr-1" />
                      )}
                      {`${member.first_name} ${member.last_name}`}
                    </span>
                  </div>
                </Button>
              </div>
            );
          })}
        </Card.Body>
      </Card>
      <GroupFormModal
        isOpen={isEditModalOpen}
        toggle={setIsEditModalOpen}
        group={group}
      />
    </>
  );
};
export default CompetitionGroupsListItem;
