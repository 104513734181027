import { useMutation } from '@apollo/client';
import FormField from 'components/FormField';
import { countries, locationStatusOptions } from 'components/constants';
import { Formik } from 'formik';
import { GET_LOCATIONS } from 'graphql/GET_LOCATIONS';
import { UPDATE_LOCATION } from 'graphql/UPDATE_LOCATION';
import { GET_LOCATIONS as GET_LOCATIONS_TYPE } from 'graphql/types/GET_LOCATIONS';
import { observer } from 'mobx-react';
import { applySnapshot, getSnapshot } from 'mobx-state-tree';
import autocompleteStyles from 'modules/common/styles/autocompleteStyles';
import {
  DEFAULT_LIMIT,
  DEFAULT_PAGE,
  ELocationOrder
} from 'modules/location/constants';
import React from 'react';
import Select from 'react-select';
import { toast } from 'react-toastify';
import { Form, Grid, Icon, Table } from 'tabler-react';
import { formatDate } from 'utils/dateFormat';
import { Types } from '../../../types/graphql'

enum EStatusOpts {
  open_since = 'open_since',
  status = 'status',
  type = 'type'
}

const UpdateInlineLocation = ({
  setIsEditing,
  location,
  locationClone
}) => {
  const [updateLocation] = useMutation(UPDATE_LOCATION, {
    onCompleted: () => toast.success('Location Updated.')
  });

  const mstHandleChange = (e, inputName: string = '') => {
    if (inputName) {
      locationClone.set(inputName, e.value);
    } else {
      locationClone.set(e.target.name, e.target.value);
    }
  };

  return (
    <Formik
      enableReinitialize={true}
      initialValues={{
        id: locationClone.id,
        name: locationClone.name,
        status: locationClone.status,
        address: locationClone.address,
        city: locationClone.city,
        province: locationClone.province,
        postal_code: locationClone.postal_code,
        country: locationClone.country,
        open_since: locationClone.open_since
      }}
      onSubmit={async (values, { setSubmitting, resetForm }) => {
        await updateLocation({
          variables: {
            location: {
              ...values,
              open_since: locationClone.open_since || null
            }
          },

          update: (cache, { data: { updateLocation } }) => {
            // Update mobx state tree
            applySnapshot(location, getSnapshot(locationClone));

            if (!updateLocation) {
              return cache;
            }

            const getLocationsQuery = {
              query: GET_LOCATIONS,
              variables: {
                order: ELocationOrder.name,
                filter: {
                  type: location.type,
                  is_frozen: false,
                  limit: DEFAULT_LIMIT
                },
                page: DEFAULT_PAGE
              }
            };

            const { locations } = cache.readQuery<GET_LOCATIONS_TYPE>({ ...getLocationsQuery });

            const updatedLocations = locations.map(
              (location: Types.Location) => {
                if (location.id === locationClone.id) {
                  return {
                    ...updateLocation,
                    open_since: formatDate(
                      new Date(Number(updateLocation.open_since))
                    )
                  };
                } else {
                  return location;
                }
              }
            );

            // Update apollo cache
            return cache.writeQuery({
              data: {
                locations: updatedLocations
              },
              ...getLocationsQuery
            });
          }
        });

        setSubmitting(false);
        resetForm();
        setIsEditing(false);
      }}
    >
      {({ values, handleBlur, handleSubmit, setFieldValue }) => (
        <>
          <Table.Col>
            <FormField
              additionalgroupclasses={['mb-0']}
              name="name"
              onBlur={handleBlur}
              onChange={mstHandleChange}
              value={values.name}
              type="text"
            />
          </Table.Col>

          <Table.Col>
            <FormField
              additionalgroupclasses={['mb-0']}
              name="address"
              onBlur={handleBlur}
              onChange={mstHandleChange}
              value={values.address}
              type="text"
            />
          </Table.Col>

          <Table.Col>
            <FormField
              placeholder="City"
              additionalgroupclasses={['mb-0']}
              name="city"
              onBlur={handleBlur}
              onChange={mstHandleChange}
              value={values.city}
              type="text"
            />
          </Table.Col>

          <Table.Col>
            <FormField
              placeholder="Province"
              additionalgroupclasses={['mb-0']}
              name="province"
              onBlur={handleBlur}
              onChange={mstHandleChange}
              value={values.province}
              type="text"
            />
          </Table.Col>

          <Table.Col>
            <FormField
              placeholder="Postal code"
              additionalgroupclasses={['mb-0']}
              name="postal_code"
              onBlur={handleBlur}
              onChange={mstHandleChange}
              value={values.postal_code}
              type="text"
            />
          </Table.Col>

          <Table.Col>
            <Form.Group className="mb-0">
              <Select
                backspaceRemovesValue={true}
                escapeClearsValue={true}
                getOptionLabel={(option: { name: string }) => option.name}
                getOptionValue={(option: { name: string }) => option.name}
                isClearable={true}
                name="country"
                options={countries}
                onChange={(params: { name: string }) => {
                  setFieldValue('country', params.name);
                }}
                styles={autocompleteStyles}
                value={
                  values.country &&
                  countries[
                    Object.keys(countries).find(
                      (key) => countries[key].name === values.country
                    )
                  ]
                }
              />
            </Form.Group>
          </Table.Col>

          <Table.Col>
            <input
              className="form-control"
              type="date"
              name="open_since"
              value={values.open_since}
              onChange={mstHandleChange}
              required={true}
            />
          </Table.Col>

          <Table.Col colSpan="2">
            <Select
              name="status"
              options={locationStatusOptions}
              onChange={(value) => mstHandleChange(value, EStatusOpts.status)}
              value={
                values.status &&
                locationStatusOptions[
                  Object.keys(locationStatusOptions).find(
                    (key) => locationStatusOptions[key].value === values.status
                  )
                ]
              }
            />
          </Table.Col>

          <Table.Col>
            <Grid.Row>
              <Grid.Col>
                <Icon
                  link
                  name="save"
                  onClick={() => {
                    handleSubmit();
                  }}
                />
              </Grid.Col>
              <Grid.Col>
                <Icon
                  link
                  name="x-circle"
                  onClick={() => setIsEditing(false)}
                />
              </Grid.Col>
            </Grid.Row>
          </Table.Col>
        </>
      )}
    </Formik>
  );
};

export default observer(UpdateInlineLocation);
