import { useQuery } from '@apollo/client'
import { Loading } from 'components/Loading'
import { GET_CAMP_TOTALS } from 'graphql/GET_CAMP_TOTALS'
import { GET_CAMP_TOTALS as GET_CAMP_TOTALS_TYPE } from 'graphql/types/GET_CAMP_TOTALS'
import { useParams } from 'react-router-dom'
import { Grid, StampCard, Text } from 'tabler-react'
import { formatMoney } from 'utils/numberFormat'

const EventRegistrationTotalPendingRevenue = () => {
  const { campId } = useParams<{ campId: string }>()
  const { loading, error, data } = useQuery<GET_CAMP_TOTALS_TYPE>(
    GET_CAMP_TOTALS,
    {
      variables: {
        campId: Number(campId)
      }
    }
  )

  if (loading || !data) {
    return <Loading />
  }

  if (error) {
    console.log(error)
    return <p>Error: {error.message}</p>
  }

  const payments = data.payments ?? []
  const pending = data.pending ?? {}
  return (
    <EventRegistrationTotalPendingRevenueInner
      pending={pending}
      payments={payments}
    />
  )
}

const EventRegistrationTotalPendingRevenueInner = ({ pending, payments }) => {
  return (
    <Grid.Col sm={6} lg={3} xs={12} className="pl-0">
      <span>
        <StampCard
          className="mb-0"
          color="secondary"
          icon="dollar-sign"
          // Total number of participants that have not paid registration fee in full
          header={
            <Text>
              {payments.pendingPaymentsCount} <small>Have Balance</small>
            </Text>
          }
          // Total amount of registration fees that have not been collected
          footer={<Text>{formatMoney(payments.pendingPaymentsAmount)}</Text>}
        />
      </span>
    </Grid.Col>
  )
}
export default EventRegistrationTotalPendingRevenue
