import { observer } from 'mobx-react';
import React from 'react';
import AdminHome from 'views/home/AdminHome';
import CoachHome from 'views/home/CoachHome';
import DZMHome from 'views/home/DZMHome';
import RiggerHome from 'views/home/RiggerHome';
import StudentHome from 'views/home/StudentHome';
import EventsHome from 'views/home/EventsHome';
import { EUserTypes } from '../components/User/userTypes';
import { useRootStore } from '../hooks/useRootStore';
import Wrapper from './layout';

const HomeViews = {
  [EUserTypes.admin]: AdminHome,
  [EUserTypes.student]: StudentHome,
  [EUserTypes.coach]: CoachHome,
  [EUserTypes.dzm]: DZMHome,
  [EUserTypes.rigger]: RiggerHome,
  [EUserTypes.events]: EventsHome,
  [EUserTypes.manufacturer]: EventsHome,
  [EUserTypes.org]: EventsHome,
  [EUserTypes.wtm]: EventsHome
};

const HomePage = (props) => {
  const { currentUser, currentCoachTeam } = useRootStore();

  const HomeView =
    HomeViews[
      [EUserTypes.admin, EUserTypes.student].includes(
        currentUser?.type as EUserTypes
      )
        ? currentUser?.type
        : currentCoachTeam?.coach_business_type
    ] ?? HomeViews[EUserTypes.events];

  return (
    <Wrapper {...props} title="Home">
      <HomeView />
    </Wrapper>
  );
};

export default observer(HomePage);
