import React, { useEffect, useState } from 'react'
import { Button, Card, Icon } from 'tabler-react'
import { observer } from 'mobx-react'
import { useQuery } from '@apollo/client'
import { GET_TEAM_PROGRESSIONS } from '../../graphql/GET_TEAM_PROGRESSIONS'
import TeamProgressionsHeading from './TeamProgressionsHeading'
import FeatureAccessGate from 'modules/common/components/FeatureAccessGate'

const TeamProgressions = ({ teamId }) => {
  const [progressions, setProgressions] = useState([])
  const { data: progressionsData, loading } = useQuery(GET_TEAM_PROGRESSIONS, {
    variables: { teamId: parseInt(teamId) }
  })

  const groupByHeader = (data) => {
    return data.reduce(function (storage, item) {
      const key = item?.heading
      storage[key] = storage[key] || []
      storage[key].push(item)
      return storage
    }, {})
  }

  useEffect(() => {
    if (!loading && progressionsData?.getTeamProgressions) {
      setProgressions(groupByHeader(progressionsData?.getTeamProgressions))
    }
  }, [progressionsData, loading])
  return (
    <Card statusColor="blue">
      <Card.Header>
        <Card.Title>
          <Icon name="check-circle" className="mr-2 ml-0 text-primary" />
          Skills Progression
        </Card.Title>
        <FeatureAccessGate feature="feature_student_progression">
          <Card.Options>
            <Button.List>
              <Button
                icon="plus"
                color="secondary"
                size="sm"
                className="text-primary"
                disabled
              />
            </Button.List>
          </Card.Options>
        </FeatureAccessGate>
      </Card.Header>
      <Card.Body>
        {Object.keys(progressions).map((key) => (
          <TeamProgressionsHeading
            heading={key}
            progressHeading={progressions[key]}
            key={key}
          />
        ))}
      </Card.Body>
    </Card>
  )
}

export default observer(TeamProgressions);
