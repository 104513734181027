import InvoiceEditNotesForm from 'components/Invoices/InvoiceEditNotesForm';
import React, { useState } from 'react';
import { Button, Card, Icon, Text } from 'tabler-react';
import { Types } from '../../../../types/graphql'

type InvoiceNotesProps = {
  invoice: Types.Invoice;
};

const InvoiceNotes = ({ invoice }: InvoiceNotesProps) => {
  const [isEdittingNotes, setIsEdittingNotes] = useState(false);

  return (
    <>
      <Card className="mb-2 d-print-none">
        <Card.Header>
          <Card.Title>
            <Icon name="file" className="mr-2 ml-0 text-blue" />
            Notes/Terms
          </Card.Title>
          <Card.Options>
            {!isEdittingNotes && (
              <Button
                className={'btn-secondary btn-sm'}
                onClick={() => setIsEdittingNotes(true)}
              >
                EDIT
              </Button>
            )}
          </Card.Options>
        </Card.Header>
        <Card.Body>
          {isEdittingNotes ? (
            <InvoiceEditNotesForm
              invoice={invoice}
              setIsEdittingNotes={setIsEdittingNotes}
            />
          ) : (
            invoice.invoice_notes
          )}
        </Card.Body>
      </Card>
      <Text.Small className="ml-2 text-muted d-print-none">
        <Icon name="lock" className="mr-2" />
        <i>Notes/Terms are internal only, and not shared with the customer.</i>
      </Text.Small>
    </>
  );
};

export default InvoiceNotes;
