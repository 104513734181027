import gql from 'graphql-tag';

export const UPDATE_PRODUCT_MUTATION = gql`
  mutation UPDATE_PRODUCT_MUTATION(
    $product: UpdateProductInput!
    $team_id: Int!
  ) {
    updateProduct(product: $product, team_id: $team_id)
  }
`;
