import React from 'react';
import { Card, Header, Tag, Text } from 'tabler-react';
import { formatDate } from '@fullcalendar/react';
import formatSeal from '../../utils/formatSeal';


interface RigCardParams {
  reservelog: any;
}

const ReservelogCardItem = ({ reservelog }: RigCardParams) => {
  return (
    <Card>
      <Card.Body>
        {/* 
        <Dropdown
          className='float-right cursor-pointer'
          toggle={false}
          arrow
          triggerContent={<Icon name='chevron-down' />}
          itemsObject={[
            {
              value: 'View'
            }
          ]}
        />
*/}
        <Text muted>{formatDate(reservelog?.event_date)}</Text>
        <Header.H4 className="mb-1">{reservelog?.location.name}</Header.H4>
        {reservelog?.user_rigger_seal && (
          <>
            <Text.Small className='text-muted'>
                {reservelog?.user_rigger_seal.coach?.first_name}{' '}
                {reservelog?.user_rigger_seal.coach?.last_name}{' '}
                {formatSeal(reservelog?.user_rigger_seal.rigger_seal)}
            </Text.Small>
          </>
        )}

        <Text.Small className={`d-block text-black`}>
          <Tag className="mr-1">{reservelog?.team.name}</Tag>
        </Text.Small>
      </Card.Body>
    </Card>
  );
};

export default ReservelogCardItem;
