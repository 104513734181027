import React from 'react';
import Modal from '../../Modal';
import { toast } from 'react-toastify';
import { Formik } from 'formik';
import { Button, Form, Grid } from 'tabler-react';
import { useRootStore } from '../../../hooks';
import { useMutation, useQuery } from '@apollo/client';
import * as Yup from 'yup';
import { ADD_INVOICE_PRODUCT } from '../../../graphql/ADD_INVOICE_PRODUCT';
import { GET_PRODUCTS_BY_TEAM } from '../../../graphql/GET_PRODUCTS_BY_TEAM';
import Select from 'react-select';
import { GET_INVOICE_ITEMS } from '../../../graphql/GET_INVOICE_ITEMS';
import { GET_INVOICE } from '../../../graphql/GET_INVOICE';
import { UPDATE_INVOICE } from '../../../graphql/UPDATE_INVOICE';
import { formatMoney } from '../../../utils/numberFormat';
import { Types } from '../../../types/graphql'

interface EstimateModalFormProps {
  invoice: any;
  toggleModal: any;
  isModalOpen: any;
}

const addEstimateValidation = Yup.object().shape({
  product_id: Yup.number().required('This field is required.')
});

const AddInvoiceItemModalForm = ({ invoice, toggleModal, isModalOpen }: EstimateModalFormProps) => {

  const rootStore = useRootStore();
  const { currentCoachTeam } = rootStore;
  const [addInvoiceProduct] = useMutation(ADD_INVOICE_PRODUCT);
  const [updateInvoice] = useMutation(UPDATE_INVOICE);

  const { data: productData } = useQuery(GET_PRODUCTS_BY_TEAM, {
    variables: {
      filter: {
        team_id: currentCoachTeam?.id,
        status: 'active'
      }
    }
  });

  const renderForm = ({ values, handleSubmit, isSubmitting, errors, touched, setFieldValue }) => (
    <form onSubmit={handleSubmit}>

      <Grid.Row>
        <Grid.Col xs={12} sm={12} lg={12}>
          <Form.Group label='Job/Service:'>
            <Select
              menuPortalTarget={document.body}
              styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
              name='product_id'
              placeholder='Select a service/product...'
              isMulti={false}
              getOptionLabel={(
                option: Types.Product
              ) => {
                return `${option.title} - ${formatMoney(option.amount)}`;
              }}

              getOptionValue={(
                option: Types.Product
              ) => option.id}
              options={productData?.getProductsByTeam}
              onChange={(param: Types.Product) => {
                setFieldValue('name', param.title);
                setFieldValue('unit_price', param.amount);
                setFieldValue('product_id', param.id);
                setFieldValue(
                  'job_type_id',
                  param.title === 'Reserve Repack' ? 2 : 1
                );
              }}
              value={productData?.getProductsByTeam.filter(
                (product: Types.Product) =>
                  product.id === values?.product_id
              )}
            />
            <span className='field-error text-danger'>
              {errors.product_id && touched.product_id && errors.product_id}
            </span>
          </Form.Group>
        </Grid.Col>
      </Grid.Row>

      <Button
        disabled={isSubmitting}
        pill
        className='btn-gray-dark float-right btn-sm'
        onClick={handleSubmit}
      >
        {isSubmitting
          ? 'Processing...'
          : 'ADD'}
      </Button>

      <Button
        pill
        color='white'
        className='float-right btn-sm'
        onClick={(e) => {
          e.preventDefault();
          toggleModal(false);
        }}
      >
        CANCEL
      </Button>
    </form>);
  return (<Modal
    content={
      <Formik
        enableReinitialize={true}
        validationSchema={addEstimateValidation}
        initialValues={{
          invoice_id: parseInt(invoice?.id),
          product_id: '',
          unit_price: 0
        }}
        onSubmit={async (values, { resetForm, setSubmitting }) => {

          if (await addInvoiceProduct({
            variables: {
              invoiceId: values.invoice_id,
              productId: values.product_id


            }, refetchQueries: [
              {
                query: GET_INVOICE_ITEMS, variables: {
                  invoice_id: parseInt(invoice?.id)
                }
              }
            ]
          })) {
            await updateInvoice({
              variables: {
                updateInvoiceInput: {
                  id: values.invoice_id,
                  invoice_amount: invoice.invoice_amount + values.unit_price
                }
              },
              refetchQueries: [
                {
                  query: GET_INVOICE,
                  variables: {
                    id: values.invoice_id
                  }
                }
              ]
            });
          }

          setSubmitting(false);
          resetForm();
          toast.success("Product added!");
          toggleModal(!isModalOpen);

        }}
      >
        {(formikData) => renderForm(formikData)}
      </Formik>
    }
    open={isModalOpen}
    onClose={() => toggleModal(!isModalOpen)}
    style={{ overflow: 'visible' }}
  />);
};

export default AddInvoiceItemModalForm;
