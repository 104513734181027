import React from "react";
import Modal from "../../Modal";
import ChangePasswordForm from "../Forms/ChangePasswordForm";

interface IUserPasswordChangeModalProps {
  isModalOpen: boolean;
  toggleModal: () => void;
}

const UserPasswordChangeModal = ({
  isModalOpen,
  toggleModal,
}: IUserPasswordChangeModalProps) => {
  return (
    <Modal
      content={
        <>
          <ChangePasswordForm isModal={true} toggleModal={toggleModal} />
        </>
      }
      open={isModalOpen}
      title={"Update Password"}
      onClose={toggleModal}
    />
  );
};

export default UserPasswordChangeModal;
