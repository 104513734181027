import { useQuery } from "@apollo/client";
import { Formik } from "formik";
import { useRootStore } from "hooks/useRootStore";
import { observer } from "mobx-react";
import moment from "moment/moment";
import React, { useContext, useEffect, useState } from "react";
import { Button, Dimmer, Form, Grid } from "tabler-react";
import { UserStoreContext } from "../../../contexts/userStoreContext";
import { GET_LOCATION_BY_ID } from "../../../graphql/GET_LOCATION_BY_ID";
import { GET_USER_RESERVE_LOGS } from "../../../graphql/GET_USER_RESERVE_LOGS";
import useGetCurrentUserType from "../../../modules/common/hooks/useGetCurrentUserType";
import { Types } from "../../../types/graphql";
import FormField from "../../FormField";
import ReserveLogAdminForm from "../../ReserveLogs/Forms/ReserveLogAdminForm";

export const daysBefore180 = (dateProp) => {
  const difference =
    new Date().getTime() -
    new Date(moment(dateProp).format("MM/DD/YYYY")).getTime();
  return 180 - Math.ceil(difference / (1000 * 3600 * 24));
};

const UserReserveLogForm = () => {
  const { user } = useContext(UserStoreContext);
  const { isAdmin } = useGetCurrentUserType();
  const { currentUser, currentCoachTeam } = useRootStore();
  const [userReserveLog, setUserReserveLog] =
    useState<Types.ReserveLog>(null);
  const [reserveLogClone, setReservelog] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const {
    loading: userReserveLogLoading,
    data: userReserveLogData,
    refetch
  } = useQuery(GET_USER_RESERVE_LOGS, {
    variables: {
      order: 'newest',
      reserveLogOptions: { student_id: user.id }
    }
  });

  const { data: locationData } = useQuery(GET_LOCATION_BY_ID, {
    variables: {
      id: currentCoachTeam?.default_location_id.toString()
    }
  });

  useEffect(() => {
    if (!userReserveLogLoading && userReserveLogData) {
      if (userReserveLogData.getReserveLogs.length > 1)
        userReserveLogData.getReserveLogs.toSorted((a, b) => {
          const da = new Date(a.event_date),
            db = new Date(b.event_date);

          return da > db ? -1 : 1;
        });
      setUserReserveLog(userReserveLogData.getReserveLogs[0]);
    }
  }, [userReserveLogData]);  

  const handleToggleModal = () => {
    refetch();
    setIsModalOpen(!isModalOpen);
  };

  return (
    <>
      <Dimmer active={userReserveLogLoading} loader={userReserveLogLoading}>
        <Grid.Row>
          <Grid.Col sm={12} lg={12} ignoreCol={true}>
            <Formik
              enableReinitialize={true}
              initialValues={{
                event_date: userReserveLog?.event_date
                  ? moment(userReserveLog?.event_date).format('MM/DD/YYYY')
                  : '',
                student_id: user.id,
                team: { id: currentCoachTeam?.id },
                location_id: currentCoachTeam?.default_location_id,
                location: locationData?.location,
                created_by: currentUser.id
              }}
              onSubmit={async (values, { setSubmitting }) => {
                values.event_date = '';
                values['student'] = {
                  first_name: user.first_name,
                  last_name: user.last_name
                };
                setReservelog(values);
                setIsModalOpen(true);
                setSubmitting(false);
              }}
            >
              {({ values, handleSubmit, handleChange }) => (
                <form onSubmit={handleSubmit}>
                  <Form.Group className="mb-0" label="">
                    <Form.InputGroup>
                      <FormField
                        additionalinputclasses={[
                          userReserveLog &&
                          daysBefore180(userReserveLog?.event_date) > 0
                            ? 'is-valid state-valid mb-0'
                            : 'is-invalid state-invalid'
                        ]}
                        appendright={
                          <Button
                            color={`${
                              userReserveLog &&
                              daysBefore180(userReserveLog?.event_date) > 0
                                ? daysBefore180(userReserveLog?.event_date) > 10
                                  ? 'success'
                                  : 'warning'
                                : 'danger'
                            }`}
                            className="text-white"
                            // SAVE FOR LATER, we must allow new reserve logs if student has multiple rigs or reserve canopies
                            // disabled={userReserveLog && daysBefore180(userReserveLog?.event_time) > 45}
                            size="sm"
                            type="submit"
                            disabled={isAdmin}
                          >
                            {userReserveLog &&
                            daysBefore180(userReserveLog.event_date) > 0
                              ? `${daysBefore180(
                                  userReserveLog.event_date
                                )} days`
                              : 'Add Repack'}
                          </Button>
                        }
                        name="event_date"
                        value={values.event_date}
                        onChange={handleChange}
                        placeholder="Unknown"
                        disabled
                      />
                    </Form.InputGroup>
{/* temporarily hide
                    <Text>
                      <Text.Small className="text-muted text-secondary">
                        {userReserveLog?.location.name}{' '}
                        {userReserveLog?.user_rigger_seal?.rigger_seal}
                      </Text.Small>
                    </Text>
*/}
                  </Form.Group>
                </form>
              )}
            </Formik>
          </Grid.Col>
        </Grid.Row>
      </Dimmer>

      <ReserveLogAdminForm
        isModalOpen={isModalOpen}
        userReserveLog={reserveLogClone}
        toggleModal={() => handleToggleModal()}
      />
    </>
  );
};

export default observer(UserReserveLogForm);
