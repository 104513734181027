import React from 'react';
import InvoiceDetails from '../InvoiceDetails';
import Modal from '../../Modal';

interface InvoiceDetailsModalProps {
  invoiceId: number;
  isOpen: boolean;
  closeModal: (b: boolean) => void;
}

const InvoiceDetailsModal = ({
  invoiceId,
  closeModal,
  isOpen
}: InvoiceDetailsModalProps) => {
  return (
    <Modal
      title={`Invoice #${invoiceId}`}
      open={isOpen}
      onClose={() => closeModal(false)}
      content={<InvoiceDetails invoiceId={invoiceId} />}
    />
  );
};

export default InvoiceDetailsModal;
