import { Button } from 'tabler-react';
import React from 'react';

export const Loading = () => {
  return (
    <Button
    loading
    color="secondary"
    className="border-0 p-1 shadow-none"
    block
    >
    Loading...
      </Button>
  )
}
