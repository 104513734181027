import gql from 'graphql-tag';

export const GET_USER_NOTES = gql`
  query GET_USER_NOTES(
    $userNoteOptions: UserNoteOptions!
  ) {
    userNotes(userNoteOptions: $userNoteOptions) {
      id
      student_id
      team_id
      registration_id
      note
      is_public
      created_on
      created_by
      camp_name
      crm_contact_type_id
      coach{
        id
        first_name
      }
    }
  }
`;
