import { useMutation } from '@apollo/client';
import { teamRegistrationOpts } from 'components/Team/Form/teamContants';
import { Formik } from 'formik';
import { UPDATE_TEAM_BY_ID } from 'graphql/UPDATE_TEAM_BY_ID';
import { observer } from 'mobx-react';
import { applySnapshot, getSnapshot } from 'mobx-state-tree';
import React from 'react';
import { toast } from 'react-toastify';
import { Button, Card, Form } from 'tabler-react';
import * as Yup from 'yup';

const featureValidation = Yup.object().shape({
  feature_require_uspa: Yup.boolean().oneOf([false, true]),
  student_contact_required: Yup.boolean().oneOf([false, true])
});

const TeamRegistrationSettings = ({ team, teamClone }) => {
  const [updateTeamById] = useMutation(UPDATE_TEAM_BY_ID);

  const mstHandleChange = (e) => {
    const target = e.target;

    teamClone.set(
      target.name,
      target.type === 'checkbox' ? target.checked : target.value
    );
  };

  return (
    <Formik
      enableReinitialize={true}
      initialValues={{
        id: parseInt(teamClone.id),
        feature_require_uspa: teamClone.feature_require_uspa,
        student_contact_required: teamClone.student_contact_required
      }}
      validationSchema={featureValidation}
      onSubmit={async (values, { setSubmitting }) => {
        await updateTeamById({
          variables: {
            team: {
              ...values
            }
          },
          update(_, { data: { updateTeamById } }) {
            if (updateTeamById) {
              applySnapshot(team, getSnapshot(teamClone));
              toast.success('Team Updated.');
            }
          }
        });

        setSubmitting(false);
      }}
    >
      {({ values, isSubmitting, handleSubmit }) => (
        <form onSubmit={handleSubmit}>
          <Form.Group>
            {teamRegistrationOpts.map(
              (feature: { label: string; value: string }) => (
                <Form.Checkbox
                  checked={values[feature.value]}
                  key={feature.value}
                  label={feature.label}
                  onChange={(e) => mstHandleChange(e)}
                  name={feature.value}
                />
              )
            )}
          </Form.Group>
          <Card.Footer className="px-0">
            <Button.List align="right">
              <Button
                size="sm"
                color="primary"
                disabled={isSubmitting}
                loading={isSubmitting}
                type="submit"
              >
                Update
              </Button>
            </Button.List>
          </Card.Footer>
        </form>
      )}
    </Formik>
  );
};

export default observer(TeamRegistrationSettings);
