const formatSeal = (seal) => {

  if (!seal)
    return '';
  const serial = seal.substr(0, seal.length - 3);
  const alpha = seal.substr(seal.length - 3);
  return `${serial} ${alpha}`;
};

export default formatSeal;
