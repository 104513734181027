import React from 'react';
import { Container } from 'tabler-react';

import TeamEntry from '../../components/Team/Form/TeamEntry';
import { TeamStoreProvider } from '../../contexts/teamStoreContext';
import Wrapper from '../layout';

const TeamFormPage = (props) => {
  return (
    <Wrapper {...props} title="Team Form">
      <Container>
        <TeamStoreProvider>
          <TeamEntry />
        </TeamStoreProvider>
      </Container>
    </Wrapper>
  );
};
export default TeamFormPage;
