import { HOSTNAMES } from 'components/constants'
import React from 'react'
import logo from '../../assets/images/logo.png'
import combat_logo from '../../assets/images/combat_logo.png'
import mxcru_logo from '../../assets/images/mxcru_logo.jpg'

const SiteLogo: React.FC = () => {
  const getLogoSrc = () => {
    const envHost = HOSTNAMES.find(
      (host) => host?.host === window.location.host
    )
    switch (envHost?.name) {
      case 'LOCALHOST':
        return combat_logo
      case 'MXCRU':
        return mxcru_logo
      case 'COMBAT':
        return combat_logo
      default:
        return logo
    }
  }

  return <img src={getLogoSrc()} className="w-75" alt="logo" />
}

export default SiteLogo
