import gql from 'graphql-tag';

export const GET_SPORTS_BY_STUDENT_ID = gql`
  query GET_SPORTS_BY_STUDENT_ID($student_id: ID!) {
    getSportsByStudentId(student_id: $student_id) {
      id
      name
      slug
      is_active
    }
  }
`;
