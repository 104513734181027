import { observer } from 'mobx-react';
import React from 'react';
import { Container } from 'tabler-react';
import Wrapper from '../layout';
import InvoiceDetails from '../../components/Invoices/InvoiceDetails';
import { useParams } from 'react-router';
import { useRootStore } from 'hooks';
import PageNoAccess from 'pages/PageNoAccess';
import useGetInvoice from 'modules/invoice/hooks/useGetInvoice';
import useGetCurrentUserType from 'modules/common/hooks/useGetCurrentUserType';

const Invoice = (props) => {
  const { invoiceId } = useParams<{ invoiceId: string }>();

  const { isStudent, isAdmin } = useGetCurrentUserType();
  const { currentUser, currentCoachTeam } = useRootStore();

  const { data, loading: invoiceLoading } = useGetInvoice(Number(invoiceId));

  if (
    !invoiceLoading &&
    isStudent &&
    currentUser.id !== data?.invoice.student_id
  ) {
    return <PageNoAccess />;
  }

  if (
    !invoiceLoading &&
    !isAdmin &&
    !isStudent &&
    currentCoachTeam.id !== data?.invoice.team_id
  ) {
    return <PageNoAccess />;
  }

  return (
    <Wrapper {...props} title="Invoice">
      <Container>
        <InvoiceDetails invoiceId={Number(invoiceId)} />
      </Container>
    </Wrapper>
  );
};
export default observer(Invoice);
