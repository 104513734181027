import React from 'react';
import { Button, Form, Grid } from 'tabler-react';
import Modal from '../../Modal';
import { Formik } from 'formik';
import { toast } from 'react-toastify';
import { useMutation } from '@apollo/client';
import { ADD_GEAR_MANUFACTURER } from '../../../graphql/ADD_GEAR_MANUFACTURER';
import FormField from 'components/FormField';
import * as Yup from 'yup';
import GearManufacturerImageUpload from '../Form/GearManufacturerImageUpload';
import { useRootStore } from '../../../hooks';
import { Types } from '../../../types/graphql'

interface IGearManufacturerModalProps {
  manufacturer: Types.GearManufacturer | null;
  isOpen: boolean;
  toggleOpen: (editing: boolean) => void;
}

const addGearManufacturerValidationSchema = Yup.object().shape({
  name: Yup.string().required('This field is required.'),
  slug: Yup.string().required('This field is required.')
});

const GearManufacturerModal = ({
  manufacturer,
  isOpen,
  toggleOpen
}: IGearManufacturerModalProps) => {
  const rootStore = useRootStore();
  const handleClose = () => {
    toggleOpen(false);
  };

  const [addGearManufacturer] = useMutation(ADD_GEAR_MANUFACTURER, {
    onCompleted: (response) => {
      if (response.addGearManufacturer) {
        toast.success('Manufacturer added successfully');
        handleClose();
      }
    }
  });
  const renderForm = ({
    values,
    isSubmitting,
    handleSubmit,
    handleChange,
    setFieldValue
  }) => {
    return (
      <>
        <Grid.Row>
          <Grid.Col>
            <Form.Group label="Name">
              <FormField
                name={'name'}
                value={manufacturer?.name}
                onChange={handleChange}
              />
            </Form.Group>
          </Grid.Col>
          <Grid.Col>
            <Form.Group label="Slug">
              <FormField
                name={'slug'}
                value={manufacturer?.slug}
                onChange={handleChange}
              />
            </Form.Group>
          </Grid.Col>
        </Grid.Row>

        <Grid.Row>
          <Grid.Col>
            <Form.Group label="Image">
              <GearManufacturerImageUpload
                rootStore={rootStore}
                setImage={(e: string) => {
                  setFieldValue('image', e);
                }}
                manufacturerId={
                  manufacturer ? manufacturer.id.toString() : 'new'
                }
              />
            </Form.Group>
          </Grid.Col>
          <Grid.Col>
            {values.image && (
              <img
                src={`/api/s3/uploads/${values.image}`}
                width={'100px'}
                alt={'Manufacturer'}
              />
            )}
          </Grid.Col>
        </Grid.Row>
        <Grid.Row>
          <Grid.Col>
            <Button
              disabled={isSubmitting}
              pill
              className="btn-gray-dark float-right btn-sm"
              onClick={handleSubmit}
            >
              {isSubmitting ? 'Processing...' : 'SUBMIT'}
            </Button>
            <Button
              pill
              color="white"
              className="float-right btn-sm"
              onClick={(e) => {
                e.preventDefault();
                handleClose();
              }}
            >
              CANCEL
            </Button>
          </Grid.Col>
        </Grid.Row>
      </>
    );
  };
  return (
    <Modal
      open={isOpen}
      onClose={() => toggleOpen(false)}
      content={
        <Formik
          validationSchema={addGearManufacturerValidationSchema}
          enableReinitialize={true}
          initialValues={{
            name: manufacturer?.name,
            slug: manufacturer?.slug,
            image: manufacturer?.image
          }}
          onSubmit={(values, actions) => {
            addGearManufacturer({
              variables: {
                manufacturer: {
                  name: values.name,
                  slug: values.slug,
                  image: values.image
                }
              },
              refetchQueries: ['GET_ALL_GEAR_MANUFACTURERS']
            });

            actions.setSubmitting(false);
            handleClose();
          }}
        >
          {(formikData) => renderForm(formikData)}
        </Formik>
      }
    />
  );
};

export default GearManufacturerModal;
