import React from 'react';
import FreshChat from 'react-freshchat';

interface IFreshChatWidgetProps {
  user?: {
    id: number;
    email: string;
    first_name: string;
    last_name: string;
    phone_number: string;
    type: string;
  };
}

const FreshChatWidget = ({ user }: IFreshChatWidgetProps) => {
  if (process.env.NODE_ENV === 'production') {
    return null;
  }

  return (
    <FreshChat
      token="d8a8a507-49d7-4dbc-bc2f-746172d7dda6"
      email={user?.email || ''}
      externaIId={`${user?.type} - ${user?.id}`}
      firstName={user?.first_name || ''}
      lastName={user?.last_name || ''}
      phone={user?.phone_number || ''}
    />
  );
};

export default FreshChatWidget;
