import { usePDF } from '@react-pdf/renderer';
import InvoicePDF from 'modules/invoice/components/InvoicePDF';
import React, { FC, memo, useEffect } from 'react';
import { Button } from 'tabler-react';

interface PrintInvoiceButtonProps {
  invoiceId: number;
  invoiceItems: any;
  pdfDetails: {
    company_name: string;
    company_phone: string;
    company_email: string;
    customer_name: string;
    customer_phone: string;
    customer_email: string;
    created_on: string;
    invoice_type: number;
    invoice_terms: string
  };
  total: number;
}

const PrintInvoiceButton: FC<PrintInvoiceButtonProps> = memo(
  ({ invoiceId, invoiceItems, pdfDetails, total }) => {
    const document = InvoicePDF({ invoiceItems, invoiceId, pdfDetails, total })
    const [instance, updateInstance] = usePDF({document})

    useEffect(() => {
      updateInstance(document)
      },   
      [invoiceItems]);

    return (
      <a
        href={instance.url}
        download={`Invoice ${invoiceId}.pdf`}
        key={invoiceId}
        className="d-print-none"
      >
        <Button icon="download" color="primary" size="md">
          PDF
        </Button>
      </a>
    );
  },
  (prevProps, nextProps) => {
    return prevProps.invoiceItems === nextProps.invoiceItems;
  }
);

export default PrintInvoiceButton;
