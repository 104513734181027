import {
  CircularProgress,
  makeStyles,
  Step,
  StepLabel,
  Stepper
} from '@material-ui/core';
import { Form, Formik, FormikConfig, FormikValues } from 'formik';
import { FormikStepProps } from 'modules/common/components/forms/FormikStep';
import React, { useState } from 'react';
import { Button } from 'tabler-react';

const useStyles = makeStyles(() => ({
  root: {
    '& .MuiStepIcon-active': {
      color: '#467fcf'
    },
    '& .MuiStepIcon-completed': {
      color: '#467fcf'
    }
  }
}));

const FormikStepper = ({ children, ...props }: FormikConfig<FormikValues>) => {
  const classes = useStyles();

  const childrenArray = React.Children.toArray(
    children
  ) as React.ReactElement<FormikStepProps>[];
  const [step, setStep] = useState(0);
  const [completed, setCompleted] = useState(false);

  const currentChild = childrenArray[step];

  function isLastStep() {
    return step === childrenArray.length - 1;
  }

  return (
    <Formik
      {...props}
      validationSchema={currentChild.props.validationSchema}
      onSubmit={async (values, helpers) => {
        if (isLastStep()) {
          await props.onSubmit(values, helpers);
          setCompleted(true);
        } else {
          setStep((s) => s + 1);

          helpers.setTouched({});
        }
      }}
    >
      {({ isSubmitting }) => (
        <Form autoComplete="off">
          <Stepper alternativeLabel activeStep={step} className={classes.root}>
            {childrenArray.map((child, index) => (
              <Step
                key={child.props.label}
                completed={step > index || completed}
              >
                <StepLabel>{child.props.label}</StepLabel>
              </Step>
            ))}
          </Stepper>

          {currentChild}

          <Button.List align="right">
            {step > 0 ? (
              <Button
                disabled={isSubmitting}
                variant="contained"
                color="primary"
                onClick={(e: { preventDefault: () => void }) => {
                  e.preventDefault();
                  setStep((s) => s - 1);
                }}
              >
                Back
              </Button>
            ) : null}
            <Button
              startIcon={isSubmitting ? <CircularProgress size="1rem" /> : null}
              disabled={isSubmitting}
              variant="contained"
              color="primary"
              type="submit"
            >
              {isSubmitting ? 'Submitting' : isLastStep() ? 'Submit' : 'Next'}
            </Button>
          </Button.List>
        </Form>
      )}
    </Formik>
  );
};

export default FormikStepper;
