import { useQuery } from "@apollo/client";
import { useEffect, useState } from "react";
import { GET_STUDENT_BY_NAME } from "../../../graphql/GET_STUDENT_BY_NAME";
import { GET_STUDENT_BY_NAMEQuery } from "../../../graphql/new/graphql";
import { useRootStore } from "../../../hooks";

const useGetStudentByName = () => {
  const rootStore = useRootStore();
  const { currentCoachTeam } = rootStore;
  const [name, setName] = useState({
    firstName: "",
    lastName: "",
  });
  const [duplicateList, setDuplicateList] = useState([]);
  const { data, loading, refetch } = useQuery<GET_STUDENT_BY_NAMEQuery>(
    GET_STUDENT_BY_NAME,
    {
      variables: {
        ...name,
        teamId: currentCoachTeam?.id,
      },
      skip: !name.firstName || !name.lastName,
    },
  );

  useEffect(() => {
    if (data?.getStudentByname && !loading) {
      setDuplicateList(data.getStudentByname);
    }
  }, [data, loading]);

  useEffect(() => {
    if (name.firstName && name.lastName) void refetch();
  }, [refetch, name]);

  return {
    data: duplicateList ?? [],
    loading,
    setName,
    name,
  };
};

export default useGetStudentByName;
