import gql from "graphql-tag";

export const GET_STUDENT_TEAM_PROGRESSION = gql`
  query GET_STUDENT_TEAM_PROGRESSION(
    $studentProgressionInput: StudentProgressionInput!
  ) {
    getStudentTeamProgressions(
      studentProgressionInput: $studentProgressionInput
    ) {
      id
      heading
      title
      is_disabled
      subheading
      team_id
      camp_id
      studentProgressionId
      studentProgressionStatus
      studentProgressionNotes
      is_exist
      rating
    }
  }
`;
