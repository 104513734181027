import gql from 'graphql-tag';

export const GET_ALL_USER_INVOICE_EVENTS = gql`
  query GET_ALL_USER_INVOICE_EVENTS(
    $limit: Int!
    $page: Int!
    $filter: PaginateUserEventsFilter
  ) {
    getInvoiceEvents(limit: $limit, page: $page, filter: $filter) {
      userEvents {
        created_on
        end
        first_name
        last_name
        slug
        start
        status
        student_id
        user_duration
        user_events_id
        user_registration_id
        invoice_id
        invoice {
          id
          invoice_amount
          status
        }
      }
      page
      totalCount
    }
  }
`;
