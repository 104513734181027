import gql from 'graphql-tag'

export const UPDATE_TEAM_REGISTRATION_STATUS = gql`
  mutation UPDATE_TEAM_REGISTRATION_STATUS(
    $teamRegistrationStatusInput: TeamRegistrationStatusInput!
  ) {
    updateTeamRegistrationStatus(
      teamRegistrationStatusInput: $teamRegistrationStatusInput
    )
  }
`
