import gql from 'graphql-tag';

export const GET_COMPENSATION_TYPE_LIST = gql`
  query GET_COMPENSATION_TYPE_LIST($filter: FilterCompensationType!) {
    compensationTypesList(filter: $filter) {
      id
      name
      amount
      is_added
      team_id
      role_id
      load_association_type_id
      jump_type_id
      jumpTypeName
      loadAssociationTypeName
      roleName
    }
  }
`;
