import React from 'react';
import Modal from '../../Modal';
import NewLocationForm from '../Form/NewLocationForm';

const AddLocationModal = ({ isOpen, toggle }) => {
  return (
    <Modal
      content={<NewLocationForm toggle={toggle} />}
      open={isOpen}
      onClose={toggle}
    />
  );
};

export default AddLocationModal;
