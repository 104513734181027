import { useAutocomplete } from '../../../hooks/useAutocomplete'
import { GET_LOCATIONS_BY_FILTER_QUERY } from '../../../graphql/GET_LOCATIONS_BY_FILTER_QUERY'
import autoCompleteStyles from './autocompleteStyles'
import React from 'react'
import AsyncSelect from 'react-select/async'
import useReactRouter from 'use-react-router'
import { Types } from '../../../types/graphql'

const LocationSearchField = () => {
  const { history } = useReactRouter()
  const { loadOptions } = useAutocomplete({
    query: GET_LOCATIONS_BY_FILTER_QUERY,
    minInputLength: 1
  })
  return (
    <AsyncSelect
      backspaceRemovesValue={true}
      components={{
        DropdownIndicator: () => null,
        IndicatorSeparator: () => null,
        LoadingIndicator: () => null
      }}
      escapeClearsValue={true}
      getOptionLabel={(
        option: Types.Location
      ) => option.name}
      getOptionValue={(
        option: Types.Location
      ) => option.id}
      isClearable={true}
      loadOptions={loadOptions}
      onChange={(e) => {
        if (e) {
          history.push(`/locations/${e.id}`)
        }
      }}
      placeholder={'Search locations...'}
      styles={autoCompleteStyles}
    />
  )
}

export default LocationSearchField;
