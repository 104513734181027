import React from 'react';
import { Container } from 'tabler-react';

import SlotCalendarView from '../../components/Calendar/SlotCalendarView';
import Wrapper from '../layout';

const SlotCalendarPagePage = (props) => {
  return (
    <Wrapper {...props} title="Slot Calendar">
      <Container>
        <SlotCalendarView />
      </Container>
    </Wrapper>
  );
};

export default SlotCalendarPagePage;
