import React from 'react';
import { Alert, Button, Card, Container, Grid, Text } from 'tabler-react';


import Wrapper from 'pages/layout';

const StyleGuidePage = (props) => {
  return (
    <Wrapper {...props} title="Style Guide">
      <Container>
        <h1 className="container">Style Guide</h1>
        <Alert type="danger" className="text-center">
          Danger Alert
        </Alert>
        <Grid.Row className="my-2">
          <Grid.Col>
            <Button.List align="center">
              <Button color="primary" size="sm" disabled>Primary Disabled</Button>
              <Button color="secondary" size="sm">Secondary Small</Button>
              <Button color="success" size="sm">Success Small</Button>
              <Button color="danger" size="sm">Danger Small</Button>
              <Button color="info" size="sm">Info Small</Button>
              <Button color="warning" size="sm">Warning Small</Button>
              <Button color="light" size="sm">Light Small</Button>
              <Button color="white" size="sm">White Small</Button>
            </Button.List>
          </Grid.Col>
        </Grid.Row>
        <Grid.Row className="my-2">
          <Grid.Col sm={12} md={4} lg={3}>
            <Card title="Card">
              <Card.Body>
                <Text>Card Body</Text>
              </Card.Body>
            </Card>
          </Grid.Col>
        </Grid.Row>
      </Container>
    </Wrapper>
  );
};

export default StyleGuidePage;
