const isNumberExp = new RegExp('^[0-9]+$');
export const parseIntNullable = (
  value?: string | number | null
): number | null => {
  if (typeof value === 'number') {
    return isNaN(value) ? null : value;
  }

  if (!value || !isNumberExp.test(value)) {
    return null;
  }

  const intValue = parseInt(value, 10);

  return isNaN(intValue) ? null : intValue;
};
