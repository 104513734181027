import { useFormikContext } from 'formik';
import { NewTeamOnboardingFormValues } from 'modules/team/components/forms/NewTeamOnboardingForm';
import React from 'react';
import { Form } from 'tabler-react';

const EventsStep = () => {
  const { values, setFieldValue } =
    useFormikContext<NewTeamOnboardingFormValues>();

  return (
    <div>
      <Form.Group>
        <Form.Switch
          type="checkbox"
          name="is_feature_camps"
          onChange={(e) => setFieldValue('is_feature_camps', e.target.checked)}
          checked={values.is_feature_camps ? true : false}
          label="Are you planning to create events?"
        />
      </Form.Group>

      <Form.Group>
        <Form.Switch
          type="checkbox"
          name="is_feature_registrations"
          onChange={(e) =>
            setFieldValue('is_feature_registrations', e.target.checked)
          }
          checked={values.is_feature_registrations ? true : false}
          label="Are you planning to have users register for events?"
        />
      </Form.Group>
    </div>
  );
};

export default EventsStep;
