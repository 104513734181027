import { CardElement, useStripe } from '@stripe/react-stripe-js';
import CurrencyInputField from 'components/CurrencyInputField';
import FormField from 'components/FormField';
import { FormikProps, FormikValues } from 'formik';
import React from 'react';
import { Form, Grid, Text } from 'tabler-react';
import { formatMoney } from 'utils/numberFormat';

type StripePaymentFormProps = {
  currency: string;
  invoiceId: number;
  paymentFee: number;
} & FormikProps<FormikValues>;

const StripePaymentForm = ({
  currency,
  invoiceId,
  errors,
  values,
  isSubmitting,
  paymentFee,
  handleSubmit,
  setFieldValue
}) => {
  const stripe = useStripe();

  const fee = values.total * paymentFee
  const totalWithFee = values.total + fee

  return (
    <form onSubmit={handleSubmit} className="stripe-form">
      <fieldset disabled={isSubmitting}>
      <Grid.Row className="mb-4">
        <Grid.Col width={6}>
          <Form.Group className="mb-0" label="Invoice:" />
        </Grid.Col>
        <Grid.Col width={6}>
          <Text>{invoiceId}</Text>
        </Grid.Col>
      </Grid.Row>

      <Grid.Row className="mb-4">
        <Grid.Col width={6}>
          <Form.Group className="mb-0" label="Total" />
        </Grid.Col>
        <Grid.Col width={6}>
          <Form.Group>
            <Form.InputGroup>
              <Form.InputGroupPrepend>
                <Form.InputGroupText>$</Form.InputGroupText>
              </Form.InputGroupPrepend>
              <CurrencyInputField
                name="total"
                placeholder="0.00"
                value={formatMoney(values.total)}
                disabled={values.productId}
                setFieldValue={setFieldValue}
                prefix=""
                customInput={undefined}
                className="form-control"
              />
            </Form.InputGroup>
            <span className="field-error text-danger">
              {errors.total && errors.total}
            </span>
          </Form.Group>
        </Grid.Col>
      </Grid.Row>

      <Grid.Row className="mb-4">
        <Grid.Col width={6}>
          <Form.Group className="mb-0" label="Fees" />
        </Grid.Col>
        <Grid.Col width={6}>
          <Text>
            {formatMoney(fee)}{' '}
            <span
              title={`${(
                paymentFee * 100
              ).toString()}% includes all processing fees, taxes, etc.`}
            >
              <i className="fe fe-help-circle ml-2 text-secondary" />
            </span>
          </Text>
        </Grid.Col>
      </Grid.Row>

      <Grid.Row className="mb-4">
        <Grid.Col width={6}>
          <Form.Group className="mb-0" label="Email" />
        </Grid.Col>
        <Grid.Col width={12}>
          <FormField name="email" type="email" />
        </Grid.Col>
      </Grid.Row>

      <div className="form-group">
        <FormField name="name" label="Card Member Name" type="text" />
      </div>
      <CardElement
        options={{
          style: {
            base: {
              fontSize: '16px',
              color: '#424770',
              '::placeholder': {
                color: '#aab7c4'
              },
              lineHeight: '1.5rem'
            },
            invalid: {
              color: '#9e2146'
            }
          },
          classes: {
            base: 'form-group form-control'
          }
        }}
      />
      <button
        type="submit"
        disabled={!stripe || isSubmitting}
        className="btn btn-block btn-primary"
      >
        {isSubmitting
          ? 'Processing...'
          : `Pay ${formatMoney(totalWithFee, currency)}`}
      </button>
      </fieldset>
    </form>
  );
};

export default StripePaymentForm;
