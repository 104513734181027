export const statusColor = {
  Open: 'btn-secondary',
  Active: 'btn-orange',
  Hold: 'btn-teal',
  Delete: 'btn-danger',
  Complete: 'btn-success',
  Selling: 'btn-warning',
  Progress: 'btn-orange',
  Parts: 'btn-teal',
  Closed: 'btn-success'
};
