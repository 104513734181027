import gql from 'graphql-tag';

export const SEND_EMAIL_TO_EVENT_REGISTERED_USERS = gql`
  mutation SEND_EMAIL_TO_EVENT_REGISTERED_USERS(
    $coachId: Int!
    $campId: Int!
    $message: String!
    $subject: String!
    $studentIds: [Int!]
  ) {
    sendEmailToEventRegisteredUsers(
      coachId: $coachId
      campId: $campId
      message: $message
      subject: $subject
      studentIds: $studentIds
    )
  }
`;
