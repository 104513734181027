import { useMutation, useQuery } from '@apollo/client';
import { Box } from '@material-ui/core';
import { jobsStatusList } from 'components/Jobs/JobList';
import Modal from 'components/Modal';
import { Formik } from 'formik';
import { ADD_INVOICE } from 'graphql/ADD_INVOICE';
import { GET_INVOICE_LIST } from 'graphql/GET_INVOICE_LIST';
import { GET_JOB_LIST } from 'graphql/GET_JOB_LIST';
import { GET_TEAM_BY_ID } from 'graphql/GET_TEAM_BY_ID';
import { GET_TEAM_BY_ID as GET_TEAM_BY_ID_TYPE } from 'graphql/types/GET_TEAM_BY_ID';
import { UPDATE_INVOICE } from 'graphql/UPDATE_INVOICE';
import { UPDATE_INVOICE_ITEMS } from 'graphql/UPDATE_INVOICE_ITEMS';
import { useRootStore } from 'hooks';
import useGetCurrentUserType from 'modules/common/hooks/useGetCurrentUserType';
import { statusColor } from 'modules/job/constants/statusColor';
import moment from 'moment';
import React, { useMemo, useState } from 'react';
import { toast } from 'react-toastify';
import { Button, Card, Form, Grid, Tag, Text } from 'tabler-react';
import { formatMoney } from 'utils/numberFormat';
import { Types } from '../../../types/graphql'

interface InvoiceModalFormProps {
  completedJobIds: Types.Job['id'][];
  invoice: any;
  jobs: Types.Job[];
  isModalOpen: boolean;
  toggleModal: (isOpen: boolean) => void;
  onSubmit: () => void;
}

const InvoiceModalForm = ({
  completedJobIds,
  invoice,
  jobs,
  onSubmit,
  isModalOpen,
  toggleModal
}: InvoiceModalFormProps) => {
  const { currentUser, currentCoachTeam } = useRootStore();
  const { isAdmin } = useGetCurrentUserType();

  const [selectedJobIds, setSelectedJobIds] = useState(completedJobIds);
  const [isAllChecked, setIsAllChecked] = useState(false);

  const handleCheckAllChange = (e) => {
    setIsAllChecked(e.target.checked);

    if (e.target.checked) {
      setSelectedJobIds(jobs.map((job) => job.id));
    } else {
      setSelectedJobIds([]);
    }
  };

  const [addInvoice] = useMutation(ADD_INVOICE);
  const [updateInvoice] = useMutation(UPDATE_INVOICE);
  const [updateInvoiceItems] = useMutation(UPDATE_INVOICE_ITEMS);

  const { data: teamData } = useQuery<GET_TEAM_BY_ID_TYPE>(GET_TEAM_BY_ID, {
    variables: {
      teamId: currentCoachTeam?.id
    }
  });

  const handleCheckChange = (jobId: number) => {
    if (selectedJobIds.includes(jobId)) {
      setSelectedJobIds(selectedJobIds.filter((id) => id !== jobId));
    } else {
      setSelectedJobIds([...selectedJobIds, jobId]);
    }
  };

  const invoiceTotal = useMemo(() => {
    const selectedJobs = jobs.filter((job) => selectedJobIds.includes(job.id));
    return selectedJobs.reduce(
      (total, job) => total + job.quantity * job.unit_price,
      0
    );
  }, [selectedJobIds]);  

  const renderForm = ({
    values,
    handleSubmit,
    isSubmitting,
    handleChange,
    errors,
    touched
  }) => (
    <form onSubmit={handleSubmit}>
      <Card.Header>
        <Card.Title>
          {values.customer_name}
        </Card.Title>
        <Card.Options className="gap-2 font-weight-bold">
          {jobs[0].user_gear?.rig?.name &&
            <Tag color="primary">{jobs[0].user_gear?.rig?.name}</Tag>
          }
          <Text>Invoice Total</Text>
          <Text size="md" color="success">
            {formatMoney(invoiceTotal)}
          </Text>
        </Card.Options>
      </Card.Header>

      <Card.Body>
        <Text.Small className="text-muted d-block mb-3">
          Selected jobs will be added to this invoice.
        </Text.Small>
          <Form.Checkbox
            checked={isAllChecked}
            onChange={handleCheckAllChange}
            label={
              <Text className="cursor-pointer">
                <strong>Select All</strong>
              </Text>
            }
            className="text-muted"
          />
          {jobs.map((job) => (
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              key={job.id}
            >
              <Box className="py-1">
                <Form.Checkbox
                  className="mb-0"
                  checked={selectedJobIds.includes(job.id)}
                  onChange={() => handleCheckChange(job.id)}
                  label={
                    <Text className="cursor-pointer">
                      <Text className="text-muted mr-2">
                        {job.name}
                        <span className="text-success ml-2 mr-2">
                          {formatMoney(job.quantity * job.unit_price)}
                        </span>
                        {job.quantity !== 1 && (
                          <span className="text-muted mr-4">
                            (${job.unit_price} x {job.quantity})
                          </span>
                        )}
                      </Text>
                      <Text.Small className="mr-4 cursor-pointer text-muted">
                        <i className="fe fe-calendar mr-1" />{' '}
                        {moment(job.created_on).format('MMM D')}
                      </Text.Small>
                      <Text.Small className="mr-4 cursor-pointer text-muted">
                        <i className="fe fe-user mr-1 text-muted" />
                        {job?.rigger?.first_name} {job?.rigger?.last_name}
                      </Text.Small>
                      {job.user_gear_id && (
                        <Text.Small className="mr-4 cursor-pointer text-muted">
                          <i className="fe fe-shopping-bag mr-1" />
                          <span className="text-primary">
                            {job.user_gear?.gear_serial}{' '}
                          </span>
                        </Text.Small>
                      )}
                    </Text>
                  }
                />
              </Box>

              <Box className="py-1">
                <Button
                  className={`btn-sm ${statusColor[job.status]}`}
                  onClick={(e) => e.preventDefault()}
                >
                  {job.status}
                </Button>
              </Box>
            </Box>
          ))}

        <Grid.Row>
          <Grid.Col xs={12} sm={12} lg={12}>
            <Form.Group label="Notes/Terms">
              <Form.Textarea
                type="text"
                name="invoice_notes"
                placeholder="Notes"
                rows={3}
                onChange={handleChange}
                value={values.invoice_notes}
              />
              <span className="field-error text-danger">
                {errors.invoice_notes &&
                  touched.invoice_notes &&
                  errors.invoice_notes}
              </span>
            </Form.Group>
          </Grid.Col>
        </Grid.Row>
      </Card.Body>

      <Card.Footer>
        <Button
          disabled={isSubmitting || !selectedJobIds.length}
          pill
          className="btn-gray-dark float-right btn-sm"
          onClick={handleSubmit}
        >
          {isSubmitting ? 'Processing...' : 'CREATE'}
        </Button>

        <Button
          pill
          color="white"
          className="float-right btn-sm"
          onClick={(e) => {
            e.preventDefault();
            toggleModal(false);
          }}
        >
          CANCEL
        </Button>
      </Card.Footer>
    </form>
  );

  return (
    <Modal
      content={
        <Formik
          enableReinitialize={true}
          initialValues={{
            id: invoice?.id ?? null,
            invoice_type_id: invoice?.invoice_type_id ?? 1,
            student_id: parseInt(invoice?.student_id),
            invoice_amount: invoiceTotal,
            status: invoice?.status ?? 'Pending',
            invoice_notes: invoice?.invoice_notes ?? '',
            created_by: invoice?.created_by ?? currentUser.id,
            company_name: invoice?.company_name ?? teamData?.team.name,
            company_phone: invoice?.company_phone ?? teamData?.team.team_phone,
            company_email: invoice?.company_email ?? teamData?.team.team_email,
            customer_name:
              invoice?.customer_name ??
              `${invoice?.student.first_name} ${invoice?.student.last_name}`,
            customer_phone:
              invoice?.customer_phone ?? invoice?.student.phone_number,
            customer_email: invoice?.customer_email ?? invoice?.student.email,
            team_id: invoice?.team_id ?? currentCoachTeam?.id
          }}
          onSubmit={async (values, { resetForm, setSubmitting }) => {
            const { created_by, id, ...restValues } = values;

            if (values.id) {
              await updateInvoice({
                variables: {
                  updateInvoiceInput: {
                    id,
                    ...restValues
                  }
                }
              });
            } else {
              const newInvoice = await addInvoice({
                variables: {
                  addInvoiceInput: {
                    created_by,
                    ...restValues
                  }
                }
              });

              if (newInvoice) {
                await updateInvoiceItems({
                  variables: {
                    updateInvoiceItemsInput: {
                      invoice_id: newInvoice.data.addInvoice.id,
                      job_ids: selectedJobIds
                    }
                  },
                  refetchQueries: [
                    {
                      query: GET_INVOICE_LIST,
                      variables: {
                        invoiceListFilter: {
                          status: 'Pending',
                          team_id: currentCoachTeam?.id,
                          limit: 5
                        }
                      }
                    },
                    {
                      query: GET_JOB_LIST,
                      variables: {
                        jobListFilter: {
                          status: Object.keys(jobsStatusList).map((stat) => {
                            if (jobsStatusList[stat].adminOnly && !isAdmin) {
                              return undefined;
                            }

                            return stat;
                          }),
                          order: 'desc',
                          team_id: !isAdmin ? currentCoachTeam?.id : undefined
                        }
                      }
                    }
                  ]
                });
              }
            }

            setSubmitting(false);
            resetForm();
            toast.success('Invoice saved!');
            onSubmit()
            toggleModal(!isModalOpen);
          }}
        >
          {(formikData) => renderForm(formikData)}
        </Formik>
      }
      maxWidth="md"
      open={isModalOpen}
      onClose={() => toggleModal(!isModalOpen)}
    />
  );
};

export default InvoiceModalForm;
