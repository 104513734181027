import gql from "graphql-tag";

export const TEAM_DETAILS_QUERY = gql`
  query TEAM_DETAILS_QUERY($team_id: Int!) {
    getAllAccountTypes {
      id
      name
      slug
    }

    geCoachesByTeamId(team_id: $team_id) {
      id
      facebook_account_id
      first_name
      last_name
      email
      phone_number
      phone_country_code
      profile_avatar
      is_active
      is_disabled_on

      seal {
        rigger_seal
      }
    }
  }
`;
