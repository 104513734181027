import gql from "graphql-tag";

export const GET_GROUPS = gql`
  query GET_GROUPS($filter: GroupFilter) {
    groupList(filter: $filter) {
      id
      name
      camp_id
      discipline_id
      discipline
      team_id
      seq
      sport_type_id
      group_code
      user_registration_id
      image
      status
      color
      members {
        first_name
        last_name
        id
        email
        phone_number
        phone_country_code
        profile_avatar
        roleName
        roleId
        registration_id
      }
    }
  }
`;
