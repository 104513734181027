import { useQuery } from "@apollo/client";
import { useEffect } from "react";
import Select from "react-select";
import { GET_TEAM_ORGS } from "../../../graphql/GET_TEAM_ORGS";
import { Types } from "../../../types/graphql";

const TeamOrgSelect = ({ teamId, setSelectedOrgs, selectedOrgs }) => {
  const { data, loading } = useQuery(GET_TEAM_ORGS, {
    variables: { teamId },
  });

  useEffect(() => {
    if (!loading && data) {
      setSelectedOrgs(
        data.teamOrgs
          .filter((teamOrg: Types.TeamOrg) => teamOrg.exist)
          .map((teamOrg) => Number(teamOrg.org_id)),
      );
    }
  }, [data, loading]);

  const changeTeamOrgHandle = (selectedOrgsValue: Types.TeamOrg[]) => {
    if (selectedOrgsValue) {
      const selected: number[] = selectedOrgsValue.map((org: Types.TeamOrg) => {
        return Number(org.org_id);
      });
      setSelectedOrgs(selected);
    } else {
      setSelectedOrgs([]);
    }
  };

  const customFilter = (option, searchText) => {
    if (
      option.data.name.toLowerCase().includes(searchText.toLowerCase()) ||
      option.data.slug.toLowerCase().includes(searchText.toLowerCase())
    ) {
      return true;
    } else {
      return false;
    }
  };
  return (
    <div>
      <Select
        name="team_orgs"
        isMulti
        getOptionLabel={(option: Types.TeamOrg) => option.name}
        getOptionValue={(option: Types.TeamOrg) => option.org_id}
        options={data?.teamOrgs}
        onChange={changeTeamOrgHandle}
        filterOption={customFilter}
        value={selectedOrgs.map((orgId: number) => {
          return data?.teamOrgs.find(
            (org: Types.TeamOrg) => Number(org.org_id) === orgId,
          );
        })}
      />
    </div>
  );
};

export default TeamOrgSelect;
