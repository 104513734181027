import React, { useState } from 'react';
import { Container } from 'tabler-react';

import CalendarView from '../../../components/Calendar/CalendarView';
import EventEntryModal from '../../../components/Calendar/Forms/EventEntryModal';
import { EventStoreProvider } from '../../../contexts/eventStoreContext';
import Wrapper from '../../layout';

const CalendarPage = (props) => {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  const toggleModal = () => setIsModalOpen(!isModalOpen);

  return (
    <Wrapper {...props} title="Calendar">
      <Container>
        <EventStoreProvider>
          <CalendarView toggleModal={toggleModal} />
          <EventEntryModal
            isModalOpen={isModalOpen}
            toggleModal={toggleModal}
          />
        </EventStoreProvider>
      </Container>
    </Wrapper>
  );
};

export default CalendarPage;
