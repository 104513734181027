import React from 'react'
import Modal from '../../Modal'
import MainGearForm from '../Form/MainGearForm'

interface MainGearModalFormProps {
  isModalOpen: boolean
  toggleModal: any
  student_id: number
  team_id: number
  student: any
}


const MainGearModalForm = ({
                             isModalOpen,
                             toggleModal,
                             student_id,
                             team_id,
                             student
                           }: MainGearModalFormProps) => {

  //const [showModelForm, setShowModelForm] = useState<boolean>(false);

  const handleClose = () => {
    toggleModal(false);
  };




  return (
    <Modal
      content={
        <MainGearForm
          toggle={handleClose}
          student_id={student_id}
          team_id={team_id}
        />
      }
      open={isModalOpen}
      title={`${student.first_name} ${student.last_name}`}
      onClose={() => toggleModal(!isModalOpen)}
    />
  )
};

export default MainGearModalForm;
