import React from 'react';
import { Form } from 'tabler-react';
import { useMutation } from '@apollo/client';
import { EDIT_GROUP } from '../../../graphql/EDIT_GROUP';
import { toast } from 'react-toastify';
import { GET_GROUPS } from '../../../graphql/GET_GROUPS';
import { Types } from '../../../types/graphql'

interface EventGroupsListItemEditNameProps {
  group: Types.Group
  toggleEdit: (i: boolean) => void;
}

const EventGroupsListItemEditName = ({
  group,
  toggleEdit
}: EventGroupsListItemEditNameProps) => {
  const [name, setName] = React.useState(group.name);
  const [editGroup] = useMutation(EDIT_GROUP, {
    onCompleted: () => {
      toast.success('Group name updated');
    }
  });
  const handleBlur = () => {
    if (name === group.name) {
      toggleEdit(false);
      return;
    }
    editGroup({
      variables: {
        updateGroupInput: {
          id: group.id,
          name
        }
      },
      refetchQueries: [
        {
          query: GET_GROUPS,
          variables: {
            filter: { camp_id: group.camp_id, team_id: group.team_id }
          }
        }
      ]
    });

    toggleEdit(false);
  };
  return (
    <Form.Input
      value={name}
      onChange={(e) => setName(e.target.value)}
      onBlur={handleBlur}
    />
  );
};

export default EventGroupsListItemEditName;
