import gql from 'graphql-tag';

export const GET_REGISTRATION_QUESTIONS = gql`
  query GET_REGISTRATION_QUESTIONS($filter: RegistrationQuestionsFilter!) {
    registrationQuestions(filter: $filter) {
      id
      name
      description
      camp_id
      location_id
      sport_type_id
      team_id
      config
      location_name
      is_exist
      is_required
      is_camp_required
      user_answer
      user_registration_question_id
      registration_question_type_id
      answer_restriction
      question_options {
        id
        registration_question_id
        name
      }
    }
  }
`;



