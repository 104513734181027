import gql from 'graphql-tag';

export const ADD_LOGBOOK_LOG = gql`
  mutation ADD_LOGBOOK_LOG($addLogbookLog: AddLogbookLog!) {
    addLogbookLog(addLogbookLog: $addLogbookLog) {
      id
      date
    }
  }
`;
