import { useMutation, useQuery } from '@apollo/client';
import FormField from 'components/FormField';
import { Formik } from 'formik';
import { ADD_COACH } from 'graphql/ADD_COACH';
import { GET_TEAMS } from 'graphql/GET_TEAMS';
import { INSERT_COACH_TO_TEAM_MUTATION } from 'graphql/INSERT_COACH_TO_TEAM_MUTATION';
import { TEAM_DETAILS_QUERY } from 'graphql/TEAM_DETAILS_QUERY';
import { GET_TEAMS as GET_TEAMS_TYPE } from 'graphql/types/GET_TEAMS';
import { useRootStore } from 'hooks';
import React, { FC, useMemo } from 'react';
import Select from 'react-select';
import { toast } from 'react-toastify';
import { Button, Card, Form, Grid } from 'tabler-react';
import * as Yup from 'yup';
import PhoneInput from "react-phone-input-2";
import { capitalizeName } from "utils/stringFormat";

const addCoachValidationSchema = Yup.object().shape({
  first_name: Yup.string().required("This field is required."),
  last_name: Yup.string().required("This field is required."),
  email: Yup.string().email("Invalid email.").nullable(),
  phone_number: Yup.string().nullable(),
  phone_country_code: Yup.number().nullable(),
  teamId: Yup.number().required("This field is required."),
});

const initialValues = {
  first_name: "",
  last_name: "",
  email: null,
  phone_number: null,
  phone_country_code: null,
};

interface AddCoachFormProps {
  toggleModal: () => void;
}

const AddCoachForm: FC<AddCoachFormProps> = ({ toggleModal }) => {
  const rootStore = useRootStore();
  const { currentCoachTeam } = rootStore;

  const { data } = useQuery<GET_TEAMS_TYPE>(GET_TEAMS, {
    variables: {
      filter: {},
    },
  });

  const teamsRow = useMemo(
    () => data?.teams.map(({ id, name }) => ({ id, name })) ?? [],
    [data?.teams]  
  );

  const [addCoach] = useMutation(ADD_COACH, {
    onError: (error) => toast.error(error?.graphQLErrors[0]?.message),
    onCompleted: () => toast.success("Coach Added."),
  });

  const [insertCoachToTeam] = useMutation(INSERT_COACH_TO_TEAM_MUTATION);

  return (
    <Formik
      enableReinitialize={true}
      initialValues={{
        ...initialValues,
        teamId: currentCoachTeam?.id,
      }}
      validationSchema={addCoachValidationSchema}
      onSubmit={async (values, { setSubmitting, resetForm }) => {
        const { teamId, ...rest } = values;

        const newCoach = await addCoach({
          variables: {
            coach: {
              ...rest,
              first_name: capitalizeName(values.first_name),
              last_name: capitalizeName(values.last_name),
            },
          },
        });

        await insertCoachToTeam({
          variables: {
            team_id: Number(teamId),
            coach_id: newCoach.data.addCoach.id,
          },
          refetchQueries: [
            {
              query: TEAM_DETAILS_QUERY,
              variables: {
                team_id: currentCoachTeam?.id ?? Number(teamId),
              },
            },
          ],
        });

        // Add new coach to mst
        rootStore.addCoach(newCoach.data.addCoach);
        rootStore.sortCoaches();

        setSubmitting(false);
        resetForm();
        toggleModal();
      }}
    >
      {({
        errors,
        touched,
        values,
        isSubmitting,
        handleBlur,
        handleChange,
        handleSubmit,
        setFieldValue,
      }) => (
        <form className="" onSubmit={handleSubmit}>
          <Card.Body className="p-0">
            <Grid.Row>
              <Grid.Col md={6} sm={12} xs={12}>
                <FormField
                  name="first_name"
                  label="First Name*"
                  placeholder="First Name"
                  type="text"
                  value={values.first_name}
                  onBlur={handleBlur}
                  onChange={handleChange}
                />
              </Grid.Col>
              <Grid.Col md={6} sm={12} xs={12}>
                <FormField
                  name="last_name"
                  label="Last Name*"
                  placeholder="Last Name"
                  type="text"
                  value={values.last_name}
                  onBlur={handleBlur}
                  onChange={handleChange}
                />
              </Grid.Col>
              <Grid.Col md={6} sm={12} xs={12}>
                <FormField
                  name="email"
                  label="Email"
                  placeholder="Email"
                  type="email"
                  value={values.email}
                  onBlur={handleBlur}
                  onChange={handleChange}
                />
              </Grid.Col>
              <Grid.Col md={6} sm={12} xs={12}>
                <Form.Group label="Phone">
                  <PhoneInput
                    inputProps={{
                      className: "form-control w-100",
                      name: "phone_number",
                      required: true,

                      onChange: (e) => {
                        e.preventDefault();
                        const target = e.target;
                        const phone = target.value.split(" ");
                        const countryCode = phone.shift().replace(/\D/g, "");
                        const phoneNumber = phone.join("").replace(/\D/g, "");
                        setFieldValue("phone_number", phoneNumber);
                        setFieldValue(
                          "phone_country_code",
                          Number(countryCode)
                        );
                      },
                    }}
                    value={`${values.phone_country_code}${values.phone_number}`}
                  />
                </Form.Group>
              </Grid.Col>
              <Grid.Col>
                <Select
                  name="teamId"
                  getOptionLabel={(option) => option.name}
                  getOptionValue={(option) => option.id}
                  options={teamsRow}
                  onChange={(param) => setFieldValue("teamId", param.id)}
                  value={
                    teamsRow.find(
                      (team) => Number(team.id) === values.teamId
                    ) ?? null
                  }
                  styles={{
                    menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                  }}
                  menuPortalTarget={document.body}
                  isDisabled={true}
                />
                <span className="field-error text-danger">
                  {errors.teamId && touched.teamId && errors.teamId}
                </span>
              </Grid.Col>
            </Grid.Row>
          </Card.Body>
          <Card.Footer className="text-right px-0 pb-0">
            <Button
              className="btn btn-primary"
              disabled={isSubmitting}
              loading={isSubmitting}
              type="submit"
            >
              ADD{isSubmitting ? "ING" : ""} ACCOUNT
            </Button>
          </Card.Footer>
        </form>
      )}
    </Formik>
  );
};

export default AddCoachForm;
