import React, { useMemo, useState } from 'react';
import { Button, Card } from 'tabler-react';
import { GET_COMPENSATION_TYPE_LIST } from '../../graphql/GET_COMPENSATION_TYPE_LIST';
import { useQuery } from '@apollo/client';
import CompensationTypeModalForm from './Forms/CompensationTypeModalForm';
import CompensationTypeListItem from './CompensationTypeListItem';
import { Types } from '../../types/graphql'

interface CompensationTypeListProps {
  teamId: number;
}

const CompensationTypeList = ({ teamId }: CompensationTypeListProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const { data, loading } = useQuery(GET_COMPENSATION_TYPE_LIST, {
    variables: { filter: { team_id: teamId } }
  });
  const compensationTypes = useMemo(() => {
    if (!loading && data) {
      return data.compensationTypesList;
    }

    return [];
  }, [data, loading]);
  return (
    <Card>
      <Card.Status color="primary" />
      <Card.Header>
        <Card.Title>Compensation Types</Card.Title>
        <Card.Options>
          <Button
            color={'white'}
            size={'sm'}
            icon={'plus'}
            onClick={() => setIsOpen(true)}
          />
        </Card.Options>
      </Card.Header>
      <Card.Body>
        {compensationTypes.map(
          (
            compensationType: Types.CompensationType
          ) => {
            return (
              <CompensationTypeListItem
                compensationType={compensationType}
                key={compensationType.id}
              />
            );
          }
        )}
      </Card.Body>
      <CompensationTypeModalForm
        toggleModal={() => setIsOpen(false)}
        isOpen={isOpen}
        teamId={teamId}
      />
    </Card>
  );
};

export default CompensationTypeList;
