import { useMutation, useQuery } from "@apollo/client";
import Modal from "components/Modal";
import { Formik } from "formik";
import { GET_CAMP_DISCIPLINES } from "graphql/GET_CAMP_DISCIPLINES";
import { GET_CAMP_ROLES } from "graphql/GET_CAMP_ROLES";
import { UPDATE_USER_REGISTRATION } from "graphql/UPDATE_USER_REGISTRATION";
import { useRootStore } from "hooks";
import React, { useMemo, useState } from "react";
import Select from "react-select";
import { Button, Form } from "tabler-react";

interface RegistrationEditRoleDisciplineModalProps {
  isOpen: boolean;
  toggle: () => void;
  registrationId: number;
  userRegistrationDetails: any;
  campId: number;
  campRequirements?: any;
}

const RegistrationEditRoleDisciplineModal: React.FC<
  RegistrationEditRoleDisciplineModalProps
> = ({
  isOpen,
  toggle,
  registrationId,
  userRegistrationDetails,
  campId,
  campRequirements,
}) => {
  const { currentUser } = useRootStore();
  const { data: rolesData } = useQuery(GET_CAMP_ROLES, {
    variables: {
      campId: campId,
    },
  });

  const { data: disciplinesData, loading: disciplineLoading } = useQuery(
    GET_CAMP_DISCIPLINES,
    {
      variables: {
        campId: campId,
      },
    },
  );

  const [updateUserRegistration] = useMutation(UPDATE_USER_REGISTRATION, {
    refetchQueries: ["GET_USER_REGISTRATIONS_BY_IDS"],
  });

  const [showDisciplineInput, setShowDisciplineInput] = useState(
    campRequirements &&
      campRequirements.includes("role") &&
      campRequirements.includes("discipline"),
  );
  const setShowDisciplineInputCallback = (value, setValue) => {
    if (!campRequirements) {
      setShowDisciplineInput(false);
    } else {
      const isDisciplineRequired = campRequirements.includes("discipline");
      const isRoleRequired = campRequirements.includes("role");
      const roleDisciplineList = [
        19, //Competitor
        32, // Alternate
      ];
      const isRoleRequiringDiscipline =
        isRoleRequired && roleDisciplineList.includes(Number(value));
      const isShow =
        (isDisciplineRequired && isRoleRequiringDiscipline) ||
        (isDisciplineRequired && !isRoleRequired);
      setShowDisciplineInput(isShow);
      if (!isShow) {
        setValue("discipline_id", null);
      }
    }
  };

  const rolesOption = useMemo(() => {
    if (rolesData)
      return rolesData?.campRoles
        .filter((role) => role.isExists)
        .map((role) => ({
          label: role.name,
          value: role.roleId,
        }));

    return [];
  }, [rolesData]);

  const disciplineOptions = useMemo(() => {
    if (disciplineLoading) return [];
    return disciplinesData?.campDisciplines
      .filter((discipline) => discipline.isExists)
      .map((discipline) => ({
        label: discipline.name,
        value: discipline.disciplineId,
      }));
  }, [disciplinesData, disciplineLoading]);

  const renderForm = ({ values, handleSubmit, setFieldValue }) => {
    return (
      <Form onSubmit={handleSubmit}>
        <Form.Group label={"Role"}>
          <Select
            styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
            menuPortalTarget={document.body}
            options={rolesOption}
            name="role_id"
            onChange={(option) => {
              setFieldValue("role_id", option.value);
              setShowDisciplineInputCallback(option.value, setFieldValue);
            }}
            value={rolesOption.find((i) => Number(i.value) === values.role_id)}
          />
          <span className="field-error text-danger">
            {!values.role_id && "This field is required"}
          </span>
        </Form.Group>
        {showDisciplineInput && (
          <Form.Group label={"Discipline"}>
            <Select
              styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
              menuPortalTarget={document.body}
              options={disciplineOptions}
              name={"discipline_id"}
              onChange={(option) => {
                setFieldValue("discipline_id", Number(option.value));
              }}
              value={disciplineOptions.find(
                (i) => Number(i.value) === values.discipline_id,
              )}
            />
            <span className="field-error text-danger">
              {!values.discipline_id && "This field is required"}
            </span>
          </Form.Group>
        )}

        <Button
          color="gray-dark"
          type="submit"
          pill
          size="sm"
          className={"float-right mr-2"}
        >
          Save
        </Button>

        <Button
          color="white"
          type="button"
          pill
          size="sm"
          onClick={toggle}
          className={"float-right "}
        >
          Cancel
        </Button>
      </Form>
    );
  };
  return (
    <Modal
      open={isOpen}
      title={`${userRegistrationDetails.first_name} ${userRegistrationDetails.last_name}: Primary Role and Discipline`}
      onClose={toggle}
      content={
        <Formik
          initialValues={{
            role_id: userRegistrationDetails.roleId,
            discipline_id: userRegistrationDetails.disciplineId,
          }}
          onSubmit={(values) => {
            updateUserRegistration({
              variables: {
                userRegistration: {
                  id: registrationId,
                  coach_id: currentUser?.id,
                  ...values,
                },
              },
            });
            toggle();
          }}
        >
          {(formProps) => renderForm(formProps)}
        </Formik>
      }
    />
  );
};

export default RegistrationEditRoleDisciplineModal;
