import moment from 'moment';
import React, { useContext } from 'react';
import { Table, Text } from 'tabler-react';
import useReactRouter from 'use-react-router';
import { formatMoney } from 'utils/numberFormat';
import { UserStoreContext } from '../../contexts/userStoreContext';

interface RiggerJobsListItemProp {
  job: any; //Types.Job;
}

const RiggerJobsListItem = ({ job }: RiggerJobsListItemProp) => {
  const userStore = useContext(UserStoreContext);
  const { user } = userStore;
  const { history } = useReactRouter();

  const routeToStudent = () => {
    userStore.detach(user);
    userStore.loadUser(job.student);
    history.push('/user-details');
  };

  return (
    <Table.Row>
      <Table.Col>
        <Text className={'cursor-pointer'} onClick={routeToStudent}>
          {job.student.first_name} {job.student.last_name}
        </Text>
        <Text.Small className="d-block text-muted">
          {job.completed_on && moment(job.completed_on).format('MM/DD/YYYY')}
        </Text.Small>
      </Table.Col>
      <Table.Col>
        <Text>{job.product.title}</Text>
        <Text.Small className='d-block text-muted'>{formatMoney(job.unit_price * job.quantity)}</Text.Small>
      </Table.Col>
      <Table.Col>{job?.user_gear?.gear_model?.name}
        <Text.Small className='d-block text-muted'>
          {job?.user_gear?.gear_serial}
        </Text.Small>
      </Table.Col>
      <Table.Col>
        <span
          className={
            job.status === 'Complete'
              ? 'ml-2 status-icon bg-success'
              : 'ml-2 status-icon bg-secondary'
          }
        />
        {job.status}
      </Table.Col>
      <Table.Col>{formatMoney((job.unit_price * job.quantity) * 0.75)}</Table.Col>
    </Table.Row>
  );
};

export default RiggerJobsListItem;
