import { useMutation } from '@apollo/client';
import { Formik, FormikValues } from 'formik';
import { observer } from 'mobx-react';
import React, { useContext } from 'react';
import Select from 'react-select';
import { toast } from 'react-toastify';
import { Button, Form, Grid } from 'tabler-react';
import * as Yup from 'yup';

import FormField from '../../../components/FormField';
import Modal from '../../../components/Modal';
import autocompleteStyles from '../../../components/Students/Forms/autocompleteStyles';
import { TeamStoreContext } from '../../../contexts/teamStoreContext';
import { CREATE_REGISTRATION_OPTION } from '../../../graphql/CREATE_REGISTRATION_OPTION';
import { GET_REGISTRATION_OPTIONS } from '../../../graphql/GET_REGISTRATION_OPTIONS';
import { UPDATE_REGISTRATION_OPTION } from '../../../graphql/UPDATE_REGISTRATION_OPTION';

const registrationOptionValidationSchema = Yup.object().shape({
  name: Yup.string().required('This field is required.'),
  description: Yup.string().required('This field is required.'),
  fee_type_id: Yup.string().required('This field is required.'),
  option_type_id: Yup.string().required('This field is required.'),
  option_value: Yup.string().required('This field is required.')
});

const RegistrationOptionsModal = ({
  isModalOpen,
  handleToggleModal,
  registrationOption,
  setRegistrationOption,
  team
}) => {
  const { fee_types, registration_option_types } = useContext(TeamStoreContext);
  const refetchQueries = [
    {
      query: GET_REGISTRATION_OPTIONS,
      variables: {
        teamId: team.id
      }
    }
  ];

  const [createNewRegistrationOption] = useMutation(
    CREATE_REGISTRATION_OPTION,
    {
      onCompleted: () => toast.success(`Registration option saved.`)
    }
  );
  const [updateRegistrationOption] = useMutation(UPDATE_REGISTRATION_OPTION, {
    onCompleted: () => toast.success(`Registration option updated.`)
  });

  const renderForm = ({
    values,
    errors,
    touched,
    handleChange,
    handleSubmit,
    setFieldValue
  }: FormikValues) => (
    <form id="registrationOptionForm" onSubmit={handleSubmit}>
      <Grid.Row>
        <Grid.Col>
          <FormField
            name="name"
            label="Name"
            placeholder="Name"
            onChange={handleChange}
            value={values.name}
          />
        </Grid.Col>
      </Grid.Row>

      <Grid.Row>
        <Grid.Col>
          <FormField
            multiline="true"
            name="description"
            label="Description"
            placeholder="Description"
            onChange={handleChange}
            value={values.description}
          />
        </Grid.Col>
      </Grid.Row>

      <Grid.Row>
        <Grid.Col>
          <Form.Group>
            <Form.Label>Option Type</Form.Label>
            <Select
              backspaceRemovesValue={true}
              escapeClearsValue={true}
              getOptionLabel={(option) => option.name}
              getOptionValue={(option) => option.id}
              isClearable={true}
              name="option_type_id"
              options={registration_option_types}
              onChange={(params) => {
                setFieldValue('option_type_id', params.id);
              }}
              styles={autocompleteStyles}
              value={
                values.option_type_id &&
                registration_option_types.find(
                  (regOptType) => regOptType.id === values.option_type_id
                )
              }
            />
            <span className="field-error text-danger">
              {errors.option_type_id &&
                touched.option_type_id &&
                errors.option_type_id}
            </span>
          </Form.Group>
        </Grid.Col>
      </Grid.Row>

      <Grid.Row>
        <Grid.Col>
          <FormField
            name="option_value"
            label="Option Value"
            placeholder="$0.00"
            onChange={handleChange}
            type="number"
            value={values.option_value}
          />
        </Grid.Col>

        <Grid.Col>
          <Form.Group>
            <Form.Label>Fee Type</Form.Label>
            <Select
              backspaceRemovesValue={true}
              escapeClearsValue={true}
              getOptionLabel={(option) => option.name}
              getOptionValue={(option) => option.id}
              isClearable={true}
              name="fee_type_id"
              options={fee_types}
              onChange={(params) => {
                setFieldValue('fee_type_id', params.id);
              }}
              styles={autocompleteStyles}
              value={
                values.fee_type_id &&
                fee_types.find((feeType) => feeType.id === values.fee_type_id)
              }
            />
            <span className="field-error text-danger">
              {errors.fee_type_id && touched.fee_type_id && errors.fee_type_id}
            </span>
          </Form.Group>
        </Grid.Col>
      </Grid.Row>
      <Grid.Row>
        <Grid.Col>
          <Button.List align='right'>
            <Button
              pill
              color='white'
              size='sm'
              onClick={(e) => {
                e.preventDefault();
                handleToggleModal();

              }}
            >
              CANCEL
            </Button>
            <Button
              pill
              type='submit'
              form='registrationOptionForm'
              className='btn btn-primary'
              color='gray-dark'
              size='sm'
            >
              SAVE
            </Button>
          </Button.List>
        </Grid.Col>
      </Grid.Row>
    </form>
  );

  return (
    <Modal
      className="overflow-visible"
      content={
        <Formik
          enableReinitialize={true}
          validationSchema={registrationOptionValidationSchema}
          initialValues={{
            name: registrationOption?.name || '',
            description: registrationOption?.description || '',
            fee_type_id: registrationOption?.fee_type_id || '',
            option_type_id: registrationOption?.option_type_id || '',
            option_value: registrationOption?.option_value || ''
          }}
          onSubmit={async (values, { setSubmitting, resetForm }) => {
            if (registrationOption) {
              await updateRegistrationOption({
                refetchQueries,
                variables: {
                  id: registrationOption.id,
                  name: values.name,
                  description: values.description,
                  fee_type_id: values.fee_type_id,
                  option_value: String(values.option_value),
                  option_type_id: values.option_type_id
                }
              });
            } else {
              await createNewRegistrationOption({
                refetchQueries,
                variables: {
                  name: values.name,
                  description: values.description,
                  fee_type_id: values.fee_type_id,
                  option_value: String(values.option_value),
                  option_type_id: values.option_type_id,
                  team_id: team.id
                }
              });
            }

            setSubmitting(false);
            resetForm();
            setRegistrationOption(null);
            handleToggleModal();

          }}
        >
          {(formikData) => renderForm(formikData)}
        </Formik>
      }
      open={isModalOpen}
      title={''}
      onClose={() => {
        setRegistrationOption(null);
        handleToggleModal();

      }}
    />
  );
};

export default observer(RegistrationOptionsModal);
