import React from 'react';
import { Link } from 'react-router-dom';
import { Grid, StampCard } from 'tabler-react';
import formatNumber, { formatMoney } from 'utils/numberFormat';

type PaymentListHeaderProps = {
  total: number | null;
  totalComp: number | null;
  totalPaid: number | null;
};

const PaymentListHeader = ({
  total,
  totalComp,
  totalPaid
}: PaymentListHeaderProps) => {
  return (
    <>
    <Grid.Col sm={6} lg={3} xs={12} className="">
      <StampCard
        color="success"
        icon="dollar-sign"
        header={
          <Link to={`/payments`}>
            {formatNumber(total)} <small>Payments</small>
          </Link>
        }
        footer={`${formatMoney(totalPaid)} PAID`}
      />
    </Grid.Col>
    </>
  );
};

export default PaymentListHeader;
