import gql from 'graphql-tag';

export const GET_USER_MERITS_BY_TEMPLATE_TITLE = gql`
  query GET_USER_MERITS_BY_TEMPLATE_TITLE(
    $student_id: ID!
    $template_title: String!
  ) {
    getUserMeritsByTemplateTitle(
      student_id: $student_id
      template_title: $template_title
    ) {
      id
      user_id
      user_type
      template_id
      template_title
      email
      first_name
      last_name
      updated_on
      merits_uspa_member_id
      merits_uspa_member_expiration
    }
  }
`;
