import gql from 'graphql-tag';

export const GET_CAMP_TOTALS = gql`
  query GET_CAMP_TOTALS($campId: Int!) {
    pending: getTotalPendingRevenue(campId: $campId) {
      total_pending_revenue
      potential_total
      potential_total_count
    }
    payments: getCampPayments(params: {camp_id: $campId}) {
      total
      totalComp
      totalCompCount
      totalPaid
      totalPaidCount
      totalPaidConfirmed
      totalPaidConfirmedCount
      pendingPaymentsCount
      pendingPaymentsAmount
      confirmedRegistrationsCount
      pendingRegistrationsCount
    }
  }
`

