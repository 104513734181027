import React, { useMemo } from 'react'
import { Button } from 'tabler-react'
import { useRootStore } from '../../../hooks'
import { useMutation, useQuery } from '@apollo/client';
import { GET_STAR_LOCATION } from '../../../graphql/GET_STAR_LOCATION'
import { UPDATE_USER_LOCATION } from '../../../graphql/UPDATE_USER_LOCATION'
import { toast } from 'react-toastify'
import { DELETE_USER_LOCATION } from '../../../graphql/DELETE_USER_LOCATION'
import { GET_USER_LOCATIONS } from '../../../graphql/GET_USER_LOCATIONS'
import { IconStar, IconStarFilled } from '@tabler/icons-react'

interface IStarLocationProps {
  locationId: number
}

const StarLocation = ({ locationId }: IStarLocationProps) => {
  const { currentUser } = useRootStore();
  const { data, loading } = useQuery(GET_STAR_LOCATION, {
    variables: {
      student_id: Number(currentUser?.id),
      location_id: locationId
    }
  });

  const [addUserLocation] = useMutation(UPDATE_USER_LOCATION, {
    onCompleted: (result) => {
      if (result.updateUserLocation) {
        toast.success("Location saved");
      }
    }
  });
  const [deleteUserLocation] = useMutation(DELETE_USER_LOCATION, {
    onCompleted: (result) => {
      if (result.deleteUserLocation) {
        toast.success("Location un-saved");
      }
    }
  });

  const starred = useMemo(() => {
    if (!loading && !!data.getStudentStarLocation)
      return data.getStudentStarLocation

    return null
  }, [data, loading])
  return (
    <Button
      color="white"
      className="mt-1 p-0"
      disabled={loading}
      onClick={() => {
        if (!starred) {
          addUserLocation({
            variables: {
              options: {
                student_id: currentUser.id,
                location_id: locationId,
                flag_type_id: 1
              }
            },
            refetchQueries: [
              {
                query: GET_STAR_LOCATION,
                variables: {
                  student_id: currentUser.id,
                  location_id: locationId
                }
              },
              {
                query: GET_USER_LOCATIONS,
                variables: {
                  userLocationFilter: {
                    student_id: currentUser.id,
                    flag_type_id: 1
                  }
                }
              }
            ]
          })
        } else {
          deleteUserLocation({
            variables: {
              id: starred.user_location_id
            },
            refetchQueries: [
              {
                query: GET_STAR_LOCATION,
                variables: {
                  student_id: currentUser.id,
                  location_id: locationId
                }
              },
              {
                query: GET_USER_LOCATIONS,
                variables: {
                  userLocationFilter: {
                    student_id: currentUser.id,
                    flag_type_id: 1
                  }
                }
              }
            ]
          })
        }
      }}
    >
      {starred ? (
        <IconStarFilled className="text-primary" />
      ) : (
        <IconStar stroke={2} className="text-muted" />
      )}
    </Button>
  )
};

export default StarLocation;
