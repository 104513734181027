import { observer } from 'mobx-react';
import { Loading } from 'components/Loading';
import React, { useEffect, useState } from "react";
import { Button, Card, Dimmer, Form, Grid, Text } from 'tabler-react';
import { Formik } from 'formik';
import { useRootStore } from "../../../hooks";
import moment from 'moment';
import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import { toast } from 'react-toastify';
import { EUserEvents, EUserEventsId } from '../../User/UserEvents';
import { ADD_USER_EVENT } from '../../../graphql/ADD_USER_EVENT';
import { ADD_USER_RESERVE_LOGS } from '../../../graphql/ADD_USER_RESERVE_LOG';
import { GET_USER_RESERVE_LOGS as GET_USER_RESERVE_LOGS_TYPE } from '../../../graphql/types/GET_USER_RESERVE_LOGS';
import Select from "react-select";
import AsyncSelect from "react-select/async";
import FormField from '../../FormField';
import Modal from '../../Modal';
import autoCompleteStyles from '../../Students/Forms/autocompleteStyles';
import { EOrderOptions, useAutocomplete } from '../../../hooks/useAutocomplete';
import { GET_LOCATIONS_BY_FILTER_QUERY } from '../../../graphql/GET_LOCATIONS_BY_FILTER_QUERY';
import { GET_TEAMS_BY_COACH_ID } from '../../../graphql/GET_TEAMS_BY_COACH_ID';
import { EDIT_USER_RESERVE_LOGS } from '../../../graphql/EDIT_USER_RESERVE_LOG';
import { EUserTypes } from '../../User/userTypes';
import { GET_STUDENT_GEARS } from '../../../graphql/GET_STUDENT_GEARS';
import StudentSearchField from '../../Students/Forms/StudentSearchField';
import * as Yup from 'yup';
import { DEFAULT_RESERVE_LOG_LIMIT } from '../ReserveLogsList';
import { GET_USER_RESERVE_LOGS } from '../../../graphql/GET_USER_RESERVE_LOGS';
import SealSearchForm from './SealSearchForm';
import SealSelectForm from './SealSelectForm';
import { DELETE_RESERVELOG } from '../../../graphql/DELETE_RESERVELOG';
import { Types } from '../../../types/graphql'

interface ReserveLogListItemProps {
  isModalOpen: boolean;
  userReserveLog?: Types.ReserveLog;
  toggleModal: any;
}

const reservelogValidationSchema = Yup.object().shape({
  student_id: Yup.number().required('This field is required.'),
  event_date: Yup.date().required('This field is required.'),
  user_rigger_seal_id: Yup.number().required('This field is required.')
});

const ReserveLogAdminForm = ({ userReserveLog, isModalOpen, toggleModal }: ReserveLogListItemProps) => {
  const { currentUser, currentCoachTeam } = useRootStore();
  const { data, loading, error } = useQuery(GET_TEAMS_BY_COACH_ID, {
    variables: {
      coachId: userReserveLog?.coach_id ? parseInt(userReserveLog?.coach_id) : currentCoachTeam?.id,
      fetch: 'all'
    },
    fetchPolicy: 'network-only'
  });

  if (loading || !data) {
    return <Loading />
  }

  if (error) {
    return <p>{`Error: ${error.message}`}</p>;
  }
  const teams = data.getTeamsByCoachId ?? []

  return <ReserveLogAdminFormInner
    userReserveLog={userReserveLog}
    isModalOpen={isModalOpen}
    toggleModal={toggleModal}
    teams={teams}
    />
}

const ReserveLogAdminFormInner = ({ userReserveLog, isModalOpen, toggleModal, teams }) => {
  const { currentUser, currentCoachTeam } = useRootStore();
  const [isDeleting, setIsDeleting] = useState(false);

  const options =
    currentUser.type === EUserTypes.admin
      ? {}
      : { team_id: currentCoachTeam.id };

  const [addUserEvent] = useMutation(ADD_USER_EVENT);
  const [addUserReserveLog] = useMutation(
    ADD_USER_RESERVE_LOGS, {
      onCompleted: async (data) => {
        toast.success('Reserve log saved.');
        await addUserEvent(
          {
            variables: {
              userEvent: {
                created_on: moment(data?.createReserveLog.event_date).format('YYYY-MM-DD'),
                location_id: data?.createReserveLog.location_id,
                status: EUserEvents.reserve_repack,
                student_id: data?.createReserveLog.student_id,
                team_id: data?.createReserveLog.team_id,
                user_event_type_id: EUserEventsId.reserve_repack
              }
            }
          }
        );
        toggleModal();
      }
    }
  );
  const [editUserReserveLog] = useMutation(
    EDIT_USER_RESERVE_LOGS, {
      onCompleted: () => {
        toast.success('Reserve log updated.');
        toggleModal();
      }
    });

  const [deleteReserveLog] = useMutation(
    DELETE_RESERVELOG,
    {
      onCompleted: () => {
        toast.error('Reserve log deleted.');
        toggleRemove();
        toggleModal();
      }
    }
  );
  const { loadOptions } = useAutocomplete({
    query: GET_LOCATIONS_BY_FILTER_QUERY,
    options: { filter: { field: 'type', value: "dropzone" } }
  });

  const [getStudentGears, {
    data: lazyStudentGearsData
  }] = useLazyQuery(GET_STUDENT_GEARS);

  const {
    data: StudentGearsData
  } = useQuery(GET_STUDENT_GEARS, {
    variables: {
      getUserGearOptions: { student_id: parseInt(userReserveLog?.student_id) }
    }
  });

  const toggleRemove = () => {
    setIsDeleting(!isDeleting);
  };

  const confirm = () => (
  <Card>
    <Card.Body>
      <Text className={'text-center font-weight-bold'}>ARE YOU SURE?</Text>
      <br />
      <Text className={'text-center'}>This will permanently delete data which can no longer be recovered.</Text>
    </Card.Body>
    <Card.Footer>
      <Button.List align='center'>
        <Button className='mr-3' size='sm' color='secondary'
                onClick={(e) => {
                  e.preventDefault();
                  toggleRemove();
                }}>CANCEL</Button>
        <Button
          className='mr-3'
          color='danger'
          size='sm'
          onClick={() => deleteReserveLog({
            variables: {
              id: parseInt(userReserveLog.id)
            }, refetchQueries: [{
              query: GET_USER_RESERVE_LOGS, variables: {
                order: EOrderOptions.newest,
                reserveLogOptions: {
                  ...options,
                  limit: DEFAULT_RESERVE_LOG_LIMIT,
                  offset: 0
                }
              }
            }]
          })}
        >
          DELETE
        </Button>
      </Button.List>
    </Card.Footer>
  </Card>);
  return (
    <>
      <Modal
        className='overflow-visible'
        content={
          (!isDeleting ?
          <Grid.Col width={12}>
            <Dimmer active={false} loader={false}>
              <Formik
                enableReinitialize={true}
                validationSchema={reservelogValidationSchema}
                initialValues={{
                  id: userReserveLog?.id,
                  event_date: userReserveLog?.event_date ?? '',
                  student_id: userReserveLog?.student_id ?? '',
                  coach_id: userReserveLog?.coach_id,
                  team_id: userReserveLog?.team?.id,
                  location_id: userReserveLog?.location_id ?? currentCoachTeam?.default_location_id,
                  reserve_notes: userReserveLog?.reserve_notes ?? '',
                  user_gear_id: userReserveLog?.user_gear_id,
                  user_rigger_seal_id: userReserveLog?.user_rigger_seal_id ?? ''
                }} onSubmit={async (values, { setSubmitting, setFieldError }) => {

                if (values.id) {
                  await editUserReserveLog({
                      update: (cache, { data: { updateReserveLog } }) => {
                        const { getReserveLogs } = cache.readQuery<GET_USER_RESERVE_LOGS_TYPE>({
                          query: GET_USER_RESERVE_LOGS,
                          variables: {
                            order: EOrderOptions.newest,
                            reserveLogOptions: {
                              ...options,
                              limit: DEFAULT_RESERVE_LOG_LIMIT,
                              offset: 0
                            }
                          }
                        });

                        return cache.writeQuery({
                          query: GET_USER_RESERVE_LOGS,
                          data: {
                            getReserveLogs: [updateReserveLog, ...getReserveLogs]
                          }
                        });
                      },
                      variables: {
                        reserveLog: {
                          id: values.id,
                          event_date: values.event_date,
                          student_id: parseInt(values.student_id),
                          coach_id: values.coach_id,
                          team_id: values.team_id,
                          location_id: values.location_id,
                          created_by: currentUser.id,
                          reserve_notes: values.reserve_notes === '' ? null : values.reserve_notes,
                          user_gear_id: values.user_gear_id,
                          user_rigger_seal_id: values.user_rigger_seal_id
                        }
                      }
                    }
                  );
                } else {
                // TEMPORARILY HIDE REQUIRED FIELD. MAY OR MAY NOT ADD BACK LATER.
                //  if (!values.user_gear_id) {
                //    setFieldError('user_gear_id', 'Gear is required.');
                //    setSubmitting(false);
                //    return false;
                //  }
                  await addUserReserveLog({
                      refetchQueries: [{
                        query: GET_USER_RESERVE_LOGS, variables: {
                          order: EOrderOptions.newest,
                          reserveLogOptions: {
                            ...options,
                            limit: DEFAULT_RESERVE_LOG_LIMIT,
                            offset: 0
                          }
                        }
                      }],
                      variables: {
                        reserveLog: {
                          id: values.id,
                          event_date: values.event_date,
                          student_id: parseInt(values.student_id),
                          coach_id: values.coach_id,
                          team_id: values.team_id,
                          location_id: values.location_id,
                          created_by: currentUser.id,
                          reserve_notes: values.reserve_notes === '' ? null : values.reserve_notes,
                          user_gear_id: values.user_gear_id,
                          user_rigger_seal_id: values.user_rigger_seal_id
                        }
                      }
                    }
                  );
                }

                setSubmitting(false);
              }}>

                {({ values, handleSubmit, handleChange, setFieldValue, errors, touched }) => (
                  <Form onSubmit={handleSubmit}>
                    <Grid.Row>
                      <Grid.Col md={6} sm={12} xs={12}>
                        <Form.Group label='Customer*'>
                          <StudentSearchField
                            autoFocus={true}
                             
                            isDisabled={userReserveLog?.student_id == values.student_id}
                            onChange={(param) => {
                              setFieldValue('student_id', param.id);
                              getStudentGears({
                                variables: {
                                  getUserGearOptions: { student_id: parseInt(param.id) }
                                }
                              });
                              setFieldValue('user_gear_id', null);
                            }}
                            defaultValue={userReserveLog?.student.first_name ? `${userReserveLog?.student?.first_name} ${userReserveLog?.student?.last_name}` : ''} />
                          <span className='field-error text-danger'>
          {errors.student_id && touched.student_id && errors.student_id}
        </span>
                        </Form.Group>
                      </Grid.Col>
                      <Grid.Col md={6} sm={12} xs={12}>
                        <Form.Group className='' label='Date*'>
                          <FormField
                            type='date'
                            name='event_date'
                            placeholder='Date'
                            onChange={(e) => {
                              const date = e.target.value.split('T')[0];
                              setFieldValue('event_date', date);
                            }}
                            value={values.event_date}
                            max={new Date().toISOString().split('T')[0]}
                          />
                        </Form.Group>
                      </Grid.Col>
                      {currentUser.type === EUserTypes.admin && (<Grid.Col md={6} sm={12} xs={12}>
                        <Form.Group className='mb-0' label='Team'>
                          <Select
                            name='team_id'
                            isMulti={false}
                            getOptionLabel={(
                              option
                            ) => option.name}
                            getOptionValue={(
                              option: Types.TeamCoach
                            ) => option.id}
                            options={teams}
                            onChange={(team: Types.TeamCoach) => setFieldValue('team_id', team.id)}
                            value={teams.filter(
                              (team: Types.TeamCoach) =>
                                team.id === parseInt(values.team_id)
                            )}
                          />
                        </Form.Group>
                      </Grid.Col>)}

                    </Grid.Row>
                    <Grid.Row className="mt-4">
                      <Grid.Col lg={6} md={6} sm={12} xs={12}>
                        <Form.Group label='Location*'>
                          <AsyncSelect
                            backspaceRemovesValue={true}
                            className='input-group'
                            isDisabled={currentUser.type.toString() !== EUserTypes.dzm}
                            components={{
                              DropdownIndicator: () => null,
                              IndicatorSeparator: () => null,
                              LoadingIndicator: () => null
                            }}
                            defaultValue={
                              userReserveLog && parseInt(userReserveLog?.location_id) !== 0
                                ? {
                                  id: userReserveLog?.location_id,
                                  name: userReserveLog?.location?.name
                                }
                                : null
                            }
                            escapeClearsValue={true}
                            getOptionLabel={(
                              option: Types.Location
                            ) => option.name}
                            getOptionValue={(
                              option: Types.Location
                            ) => option.id}
                            isClearable={true}
                            loadOptions={loadOptions}
                            name='location_id'
                            onChange={(e) => {
                              if (e)
                                setFieldValue(
                                  'location_id',
                                  e.id
                                );
                            }

                            }
                            placeholder='Search locations'
                            styles={autoCompleteStyles}
                          />
                        </Form.Group>
                      </Grid.Col>
                      <Grid.Col md={6} sm={12} xs={12}>
                        <Form.Group className='mb-0' label='Cert. No./Seal*'>
                          {EUserTypes.student !== currentUser.type && EUserTypes.rigger !== currentUser.type &&
                            <SealSearchForm setValue={setFieldValue} defaultValue={userReserveLog.user_rigger_seal} />}
                          {EUserTypes.rigger === currentUser.type &&
                            <SealSelectForm setValue={setFieldValue} defaultValue={userReserveLog.user_rigger_seal} />}
                          <span className='field-error text-danger'>
                            {errors.user_rigger_seal_id && touched.user_rigger_seal_id && errors.user_rigger_seal_id}
                          </span>
                        </Form.Group>

                      </Grid.Col>
                      <Grid.Col lg={6} md={6} sm={12} xs={12}>
                        <Form.Group label='Reserve Canopy'>
                          <Select
                            name='user_gear_id'
                            placeholder='Select reserve canopy'
                            isMulti={false}
                            getOptionLabel={(
                              option: Types.UserGear
                            ) => (option.gear_model.name + " " + option.gear_serial)}
                            getOptionValue={(
                              option: Types.UserGear
                            ) => option.id}
                            options={
                              userReserveLog?.student_id ? StudentGearsData?.getUserGears.filter((gear) => gear.gear_model.gear_type_id === 2) : lazyStudentGearsData?.getUserGears.filter((gear) => gear.gear_model.gear_type_id === 2)}
                            onChange={(gear: Types.UserGear) => setFieldValue('user_gear_id', gear.id)}
                            value={StudentGearsData?.getUserGears.filter(
                              (gear: Types.UserGear) =>
                                gear.id === parseInt(values.user_gear_id)
                            )}
                          />
                          <span className='field-error text-danger'>
                            {errors.user_gear_id && touched.user_gear_id && errors.user_gear_id}
                          </span>
                        </Form.Group>
                      </Grid.Col>
                    </Grid.Row>
                    <Grid.Row className="mb-5">
                      <Grid.Col md={12} sm={12} xs={12}>
                        <Form.Group className='mb-0' label='Remarks'>
                          <FormField
                            multiline='true'
                            name='reserve_notes'
                            placeholder='Reserve log notes...'
                            onChange={handleChange}
                          />
                        </Form.Group>
                      </Grid.Col>
                    </Grid.Row>
                    <Grid.Row>
                      <Grid.Col>
                        <Button.List align='left'>
                          <Button className='mr-3 text-muted' pill size='sm' color='secondary'
                                  onClick={(e) => {
                                    e.preventDefault();
                                    toggleRemove();
                                  }}>DELETE</Button>
                        </Button.List>
                      </Grid.Col>
                      <Grid.Col>
                        <Button.List align='right'>
                          <Button className='mr-3' pill size='sm' color='white'
                                  onClick={(e) => {
                                    e.preventDefault();
                                    toggleModal();
                                  }}>CANCEL</Button>
                          <Button
                            className='mr-3'
                            color='gray-dark'
                            size='sm'
                            pill
                          >
                            UPDATE
                          </Button>
                        </Button.List>
                      </Grid.Col>
                    </Grid.Row>
                  </Form>
                )}

              </Formik>
            </Dimmer>
          </Grid.Col>
              :
              confirm()
          )
        }
        open={isModalOpen}
        title=''
        onClose={() => {
          setIsDeleting(false);
          toggleModal();
        }}
      />
    </>
  );
};
export default observer(ReserveLogAdminForm);
