import gql from 'graphql-tag';

export const GET_CAMP_REGISTRATION_REQUIREMENTS = gql`
  query GET_CAMP_REGISTRATION_REQUIREMENTS($team_id: Int, $camp_id: Int) {
    campRegistrationRequirements(team_id: $team_id, camp_id: $camp_id) {
      id
      name
      description
      camp_id
      location_id
      team_id
      registration_requirement_id
      requirement_type_name
      requirement_type_slug
      sport_type_id
      config
      registration_id
      is_exist
      camp_id
    }
  }
`;



