import { useQuery } from "@apollo/client";
import { observer } from "mobx-react";
import React, { useContext, useEffect, useMemo, useState } from "react";
import { Button, Card, Icon } from "tabler-react";
import useReactRouter from "use-react-router";
import useRouter from "use-react-router";
import { UserStoreContext } from "../../contexts/userStoreContext";
import { GET_RIGS } from "../../graphql/GET_RIGS";
import { GET_STUDENT_GEARS } from "../../graphql/GET_STUDENT_GEARS";
import { useRootStore } from "../../hooks";
import { Types } from "../../types/graphql";
import GearCard from "../Gear/GearCard";
import RigCard from "../Gear/RigCard";
import RigGearModalButtons from "../Gear/RigGearModalButtons";
import ReservelogCard from "../ReserveLogs/ReservelogCard";
import { EUserTypes } from "./userTypes";

const UserCards = () => {
  const userStore = useContext(UserStoreContext);
  const rootStore = useRootStore();
  const { history } = useRouter();
  const { location } = useReactRouter();
  const { currentUser } = rootStore;
  const { user } = userStore;
  const student_id =
    currentUser.type === EUserTypes.student
      ? currentUser.id
      : parseInt(user.id);

  // const [userGears, setUserGears] = useState<Types.UserGear[]>(
  //   []
  // );
  const [rigs, setRigs] = useState<Types.Rig[]>([]);

  const { loading, error, data } = useQuery(GET_STUDENT_GEARS, {
    variables: {
      getUserGearOptions: { student_id: student_id },
    },
  });

  const { loading: userRigsLoading, data: userRigsData } = useQuery(GET_RIGS, {
    variables: {
      rigListFilter: {
        student_id: student_id,
      },
    },
  });

  useEffect(() => {
    if (!userRigsLoading && userRigsData) {
      setRigs(
        userRigsData.rigList.toSorted((a, b) => a.name.localeCompare(b.name)),
      );
    }
  }, [userRigsLoading, userRigsData]);

  const userGears = useMemo(() => {
    if (!loading && !error && data) {
      return data.getUserGears.toSorted((a, b) =>
        a?.gear_model.gear_type_name.localeCompare(
          b?.gear_model.gear_type_name,
        ),
      );
    }
    return [];
  }, [data, loading, error]);

  return (
    <>
      <Card statusColor="blue">
        <Card.Header>
          <Card.Title>
            <Icon name="briefcase" className="mr-2 ml-0 text-blue" />
            Gear
          </Card.Title>
          <Card.Options>
            {(rigs.length > 3 || userGears.length > 9) && (
              <Button
                icon="briefcase"
                color="info"
                className="mr-2"
                onClick={() => history.push(`/user/gear`)}
              >
                ALL GEAR
              </Button>
            )}
            <Button.List align="right">
              <RigGearModalButtons />
            </Button.List>
          </Card.Options>
        </Card.Header>
        <Card.Body>
          <RigCard rigs={rigs.slice(0, 3)} />
          <GearCard userGears={userGears.slice(0, 9)} />
        </Card.Body>
      </Card>

      {location.pathname.includes("/user/gear") && <ReservelogCard />}
    </>
  );
};

export default observer(UserCards);
