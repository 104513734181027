import { useQuery } from '@apollo/client';
import { useFormikContext } from 'formik';
import { GET_SPORT_TYPES } from 'graphql/GET_SPORT_TYPES';
import { GET_SPORT_TYPES as GET_SPORT_TYPES_TYPE } from 'graphql/types/GET_SPORT_TYPES';
import {
  SPORT_TYPE_LOCATION_MAPPING,
  SportRowType
} from 'modules/sport-type/constants';
import { NewTeamOnboardingFormValues } from 'modules/team/components/forms/NewTeamOnboardingForm';
import { LocationType } from 'modules/team/constants/api';
import React, { useMemo, useState } from 'react';
import { Button, Dimmer } from 'tabler-react';

const ActivitesStep = () => {
  const { values, setFieldValue } =
    useFormikContext<NewTeamOnboardingFormValues>();

  const [majorActivityIds, setMajorActivityIds] = useState<number[]>([]);

  const { data: sportTypesData, loading: sportTypesLoading } =
    useQuery<GET_SPORT_TYPES_TYPE>(GET_SPORT_TYPES);

  const sportTypeRows = useMemo(
    () =>
      sportTypesData?.getSportTypes
        .filter((sportType) => sportType.sub === null)
        ?.map(({ id, name, slug, sub }) => ({
          id,
          name,
          slug,
          sub,
          hasSub: sportTypesData?.getSportTypes.some(
            (sportType) => sportType.sub === id
          )
        })) ?? [],
    [sportTypesData?.getSportTypes]  
  );

  const subSportTypeRows = useMemo(() => {
    if (majorActivityIds.length === 0) {
      return [];
    }

    return majorActivityIds.flatMap(
      (majorActivityId) =>
        sportTypesData?.getSportTypes
          .filter((sportType) => sportType.sub === majorActivityId)
          ?.map(({ id, name }) => ({
            id,
            name,
            hasSub: sportTypesData?.getSportTypes.some(
              (sportType) => sportType.sub === id
            )
          })) ?? []
    );
  }, [majorActivityIds, sportTypesData?.getSportTypes]);  

  // Re-render if there are major activities in values.activities
  useMemo(() => {
    if (values.activities.length > 0) {
      setMajorActivityIds(
        values.activities.map((activity) => activity.sportTypeId)
      );
    }
  }, [values.activities]);

  const onSelectActivity = ({ id: sportTypeId, hasSub }: SportRowType) => {
    const sub = sportTypesData?.getSportTypes.find(
      (sportType) => sportType.id === sportTypeId
    )?.sub;

    const locationType = SPORT_TYPE_LOCATION_MAPPING.get(
      sub ?? sportTypeId
    ) as LocationType;

    const activityIndex = values.activities.findIndex(
      (activity) => activity.sportTypeId === sportTypeId
    );

    if (activityIndex === -1) {
      setFieldValue('activities', [
        ...values.activities,
        {
          sportTypeId,
          locationType
        }
      ]);

      setMajorActivityIds((prev) => {
        if (!hasSub) {
          return prev;
        }

        if (prev.includes(sportTypeId)) {
          return prev.filter((id) => id !== sportTypeId);
        }

        return [...prev, sportTypeId];
      });
    } else {
      // filter activities by locationType
      const filteredActivities = values.activities.filter(
        (activity) => activity.locationType === locationType
      );

      if (hasSub && filteredActivities.length > 1) {
        return;
      }

      setMajorActivityIds((prev) => prev.filter((id) => id !== sportTypeId));

      setFieldValue(
        'activities',
        values.activities.filter(
          (activity) => activity.sportTypeId !== sportTypeId
        )
      );
    }
  };

  return (
    <Dimmer
      active={sportTypesLoading}
      loader={sportTypesLoading}
      className="mb-4"
    >
      <Button.List align="center" className="my-4">
        {sportTypeRows.map((sportType) => {
          return (
            <Button
              pill
              color={
                values.activities.some(
                  (activity) => activity.sportTypeId === sportType.id
                )
                  ? 'primary'
                  : 'secondary'
              }
              size="sm"
              key={sportType.id}
              onClick={(e) => {
                e.preventDefault();

                onSelectActivity(sportType);
              }}
            >
              {sportType.name}
            </Button>
          );
        })}
      </Button.List>

      {subSportTypeRows.length > 0 && (
        <Button.List align="center" className="my-4">
          {subSportTypeRows.map((sportType) => {
            return (
              <Button
                pill
                color={
                  values.activities.some(
                    (activity) => activity.sportTypeId === sportType.id
                  )
                    ? 'primary'
                    : 'secondary'
                }
                size="sm"
                key={sportType.id}
                onClick={(e) => {
                  e.preventDefault();

                  onSelectActivity(sportType);
                }}
              >
                {sportType.name}
              </Button>
            );
          })}
        </Button.List>
      )}
    </Dimmer>
  );
};

export default ActivitesStep;
