import gql from 'graphql-tag';

export const RETRIEVE_TEAM_STAFF = gql`
  query RETRIEVE_TEAM_STAFF($team_id: ID!) {
    retrieveTeamStaff(teamId: $team_id) {
      team {
        id
        name
      }

      studentDetails {
        id
        address1
        address2
        aid
        balance
        camp_id
        camp_slot_user_status
        city
        country
        created_by
        created_on
        dob
        email
        email_verified
        facebook_account_id
        first_name
        flag_type_id
        follower_flag_type
        group_id
        is_camp_registered
        is_disabled_on
        is_followed
        is_follower
        is_on_dashboard
        is_paid
        jump_weight
        last_name
        note
        notification_type_id
        option_name
        phone_country_code
        phone_number
        phone_verified
        postal_code
        preference_24h
        preference_units
        profile_avatar
        profile_picture
        province
        registration_code
        registration_id
        status
        team_id
        total_time
        updated_by
        updated_on
        user_duration
        weight
      }

      roles {
        id
        key
        name
      }

      seal {
        rigger_seal
      }
    }
  }
`;
