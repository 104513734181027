import gql from "graphql-tag";

export const GET_ORG_CREDENTIALS = gql`
  query GET_ORG_CREDENTIALS($studentId: ID) {
    orgCredentials(studentId: $studentId) {
      id
      org_id
      student_id
      image
      membership
      expires
      license
      license_number
      rating
      verified_on
      verified_by
      created_on
      nac_id
    }
  }
`;
