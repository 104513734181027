export enum EManualPaymentOpts {
  cash = 'CASH',
  check = 'CHECK',
  comp = 'COMP',
  etrans = 'E-TRANSFER',
  paypal = 'PAYPAL',
  venmo = 'VENMO',
  zelle = 'ZELLE',
  other = 'OTHER'
}
