import { observer } from 'mobx-react';
import React from 'react';
import { Button, Card, Grid, Icon, Table, Text } from 'tabler-react';
import useReactRouter from 'use-react-router';

import CoachListItem from './CoachListItem';

const CoachList = ({ coaches }) => {
  const { history } = useReactRouter();

  const redirectToAddCoachForm = () => {
    history.push('/admin/add-coach-form');
  };

  return (
    <Card>
      <Card.Header>
        <Card.Title><Icon name='users' className='mr-2 ml-0 text-blue' /> Team Accounts (coaches)</Card.Title>
        <Card.Options>
          <Button
            className='mr-3 text-primary'
            color='white'
            icon='user-plus'
            size='MD'
            onClick={redirectToAddCoachForm}
          >
            ACCOUNT
          </Button>
        </Card.Options>
      </Card.Header>
      <Card.Body>
        <Grid.Row>
        <Text.Small className='text-muted mb-5 ml-3'>Team accounts are associated with teams (stored in the coaches table). Team accounts can be associated with one or multiple teams, and their permissions and access is limited to the teams they are associated with.</Text.Small>
          <Grid.Col>
            <Table>
              <Table.Body>
                {coaches.map((coach: { id: React.Key }) => (
                  <Table.Row key={coach.id}>
                    <CoachListItem coach={coach} />
                  </Table.Row>
                ))}
              </Table.Body>
            </Table>
          </Grid.Col>
        </Grid.Row>
      </Card.Body>
    </Card>
  );
};

export default observer(CoachList);
