import { Page } from 'components/Loader';
import useGetCurrentUserType from "modules/common/hooks/useGetCurrentUserType";
import useGetNodeEnvironment, {
  ENodeEnvironment
} from 'modules/common/hooks/useGetNodeEnvironment';
import React, { useEffect } from 'react';
import ReactGA from 'react-ga4';
import Routes from './routes'
import { HOSTNAMES } from 'components/constants'


const TRACKING_ID_LIVE = 'G-L1Q4XE9WQ5';
//const TRACKING_ID_STAGE = 'G-DYEPDSZQEH';
const TRACKING_ID_P1 = 'G-9LLLY5XWBD';
const TRACKING_ID_COMBAT = 'G-2R64WKEJ73';

const envHost = HOSTNAMES.find((host) => host?.host === window.location.host)


export const App = () => {
  const { isAdmin, isCoachSuperAdmin } = useGetCurrentUserType();
  const nodeEnv = useGetNodeEnvironment();

  useEffect(() => {
    // Check if the application is running in production mode
    const script = document.createElement('script');
    // HubSpot Tracking Code (SKYCRU)
    if (envHost?.name === 'SKYCRU') {
      script.src = '//js.hs-scripts.com/42005218.js';
    }
    // HubSpot Tracking Code (COMBAT ALLIANCE)
    if (envHost?.name === 'COMBAT') {
      script.src = '//js.hs-scripts.com/47967727.js';
    }
    script.async = true;
    script.defer = true;

    if (nodeEnv === ENodeEnvironment.PRODUCTION) {
      document.body.appendChild(script);
    }

    return () => {
      if (nodeEnv === ENodeEnvironment.PRODUCTION) {
        document.body.removeChild(script);
      }
    };
  }, [nodeEnv]);

  // google analytics conditions to display tracking code
  // TO DO: display code based on domain/url
  useEffect(() => {
    // use for non-admin users
    if (!isAdmin && !isCoachSuperAdmin && envHost?.name !== 'COMBAT') {
      ReactGA.initialize(TRACKING_ID_LIVE);
    }
    // use for admin users
    if ((isAdmin || isCoachSuperAdmin) && envHost?.name !== 'COMBAT') {
      ReactGA.initialize(TRACKING_ID_P1);
    }
    if (envHost?.name === 'COMBAT') {
      ReactGA.initialize(TRACKING_ID_COMBAT);
    }
  }, [isAdmin]);

  return <Page><Routes /></Page>
};
