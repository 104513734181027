export enum ENodeEnvironment {
  PRODUCTION = 'PRODUCTION ENV.',
  DEVELOPMENT = 'LOCAL ENV.',
  STAGING = 'STAGING ENV. (PROD DB)'
}

const useGetNodeEnvironment = () => {
  if (process.env.NODE_ENV === 'production') {
    return ENodeEnvironment.PRODUCTION;
  }

  if (process.env.NODE_ENV === 'development') {
    return ENodeEnvironment.DEVELOPMENT;
  }

  return ENodeEnvironment.STAGING;
};

export default useGetNodeEnvironment;
