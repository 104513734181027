import { Box } from '@material-ui/core';
import { IconParachute } from '@tabler/icons-react';
import JobNotes from 'components/Jobs/JobNotes';
import JobPrioritySelectForm from 'components/Jobs/JobPrioritySelectForm';
import JobStatusSelectForm from 'components/Jobs/JobStatusSelectForm';
import moment from 'moment';
import React, { useState } from 'react';
import { Button, Tag, Text } from 'tabler-react';
import { formatMoney } from 'utils/numberFormat';
import { Types } from '../../types/graphql'

interface JobItemProps {
  job: Types.Job;
  toggleModal: any;
  setJob: any;
}

const JobItem = ({ job, toggleModal, setJob }: JobItemProps) => {
  const [isJobModalOpen, setIsJobModalOpen] = useState<boolean>(false);

  const daysDue = moment(job.due_on).diff(moment(), 'days');

  return (
    <>
      <JobNotes
        notes={job.job_notes}
        toggleModal={setIsJobModalOpen}
        isModalOpen={isJobModalOpen}
      />
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Text
          className="mb-1 mt-2 cursor-pointer"
          onClick={() => {
            toggleModal(true);
            setJob(job);
          }}
        >
          <Text className="text-muted mr-2">
            {job.name}
            <span className="text-success ml-2 mr-2">
              {formatMoney(job.quantity * job.unit_price)}
            </span>
            {job.quantity !== 1 && (
              <span className="text-muted mr-4">
                (${job.unit_price} x {job.quantity})
              </span>
            )}
          </Text>
          <Text.Small className="mr-4 cursor-pointer text-muted">
            <i className="fe fe-calendar mr-1" />{' '}
            {moment(job.created_on).format('MMM D')}
          </Text.Small>
          <Text.Small
            className="mr-4 cursor-pointer text-muted"
            onClick={() => {
              toggleModal(true);
              setJob(job);
            }}
          >
            <i className="fe fe-user mr-1 text-muted" />
            {job?.rigger?.first_name} {job?.rigger?.last_name}
          </Text.Small>
          {job.user_gear_id && (
            <Text.Small className="mr-4 cursor-pointer text-muted">
              <i className="fe fe-shopping-bag mr-1" />
              <span className="text-primary">
                {job.user_gear?.gear_serial}{' '}
                <span className="text-muted">{job.user_gear?.rig?.name}</span>
              </span>
            </Text.Small>
          )}
        </Text>

        <div className="mb-1 mt-2">
          {job.job_notes && (
            <Button
              className="btn-sm btn-white"
              onClick={() => setIsJobModalOpen(true)}
            >
              <i className="fe fe-file text-muted" />
            </Button>
          )}
          <Tag
            className="mr-1 ml-1 text-gray-dark cursor-pointer font-weight-bold"
            size="sm"
            onClick={() => {
              toggleModal(true);
              setJob(job);
            }}
          >
            {job.job_storage ? job.job_storage : '+'}
          </Tag>
          <Button
            onClick={() => {
              toggleModal(true);
              setJob(job);
            }}
            className={`btn-sm btn-white
            ${
              daysDue <= 1
                ? 'text-danger'
                : daysDue >= 2 && daysDue <= 4
                ? 'text-orange'
                : 'text-secondary'
            }
          `}
          >
            Due: {moment(job.due_on).format('MMM D')}
          </Button>
          {job.name.startsWith('Reserve Repack') && (
            <Button className="text-primary" color="white" size="sm">
              <IconParachute size={20} />
            </Button>
          )}
          <JobStatusSelectForm job={job} />
          <JobPrioritySelectForm job={job} />
        </div>
      </Box>
    </>
  );
};

export default JobItem;
