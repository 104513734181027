import React from 'react';
import { Container } from 'tabler-react';

import UserEvents from '../../components/User/UserEvents';
import Wrapper from '../layout';

const UserEventsPage = (props) => {
  return (
    <Wrapper {...props} title="User Events">
      <Container>
        <UserEvents />
      </Container>
    </Wrapper>
  );
};

export default UserEventsPage;
