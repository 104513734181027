import gql from 'graphql-tag'

export const GET_TEAM_REGISTRATION_STATUSES = gql`
  query GET_TEAM_REGISTRATION_STATUSES($teamId: ID!) {
    teamStatusList(teamId: $teamId) {
      id
      color
      icon
      is_exists
      name
      slug
      team_id
      team_registration_status_id
      type
    }
  }
`
