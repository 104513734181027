import { useLazyQuery, useMutation } from "@apollo/client";
import React, { useContext } from "react";
import { toast } from "react-toastify";
import { Alert, Avatar, Button, Dropdown, Grid, Icon, Text } from "tabler-react";
import useReactRouter from "use-react-router";
import { UserStoreContext } from "../../contexts/userStoreContext";
import { DELETE_STUDENT_FROM_GROUP } from "../../graphql/DELETE_STUDENT_FROM_GROUP";
import { GET_STUDENT_BY_ID } from "../../graphql/GET_STUDENT_BY_ID";
import { GET_STUDENT_BY_ID as GET_STUDENT_BY_ID_TYPE } from "../../graphql/types/GET_STUDENT_BY_ID";
import { Types } from "../../types/graphql";
import Modal from "../Modal";
import AddStudentToRegistrationGroupModal from "./Modals/AddStudentToRegistrationGroupModal";
import EditRegistrationGroupMemberModal from "./Modals/EditRegistrationGroupMemberModal";

interface RegistrationGroupItemProps {
  group: Types.Group;
  campId?: number;
  isGroupManager?: boolean;
  groupClickHandle: (group) => void;
}

const RegistrationGroupItem = ({
  group,
  campId,
  isGroupManager,
  groupClickHandle,
}: RegistrationGroupItemProps) => {
  const [isOpen, setIsOpen] = React.useState(false);
  return (
    <>
      <Grid.Row>
        <Grid.Col className="mt-1 pr-0" width="12">
          {isGroupManager && (
            <Button
              icon={"user-plus"}
              color="white"
              size="sm"
              className={"float-right"}
              onClick={() => setIsOpen(true)}
            />
          )}
          <h4 className={"mb-0"}>
            <span
              className={"cursor-pointer"}
              onClick={() => groupClickHandle(group)}
            >
              {group.name}
            </span>
          </h4>
          <Text.Small className={"text-muted"}>{group.discipline}</Text.Small>
        </Grid.Col>
        <Grid.Col>
          <AddStudentToRegistrationGroupModal
            campId={campId}
            group={group}
            isOpen={isOpen}
            toggle={() => setIsOpen(false)}
          />
        </Grid.Col>
      </Grid.Row>
      <Grid.Row>
        <Grid.Col className={"ml-4 mt-4"}>
          {group?.members.length > 0 &&
            group?.members.map((member) => (
              <RegistrationGroupItemMember
                key={member.id}
                member={member}
                group={group}
                campId={campId}
                isGroupManager={isGroupManager}
              />
            ))}
          {group.members.length <= 0 && (
            <Alert type="info" className="text-center">
              <strong>No Members</strong>
            </Alert>
          )}
        </Grid.Col>
      </Grid.Row>
    </>
  );
};

export default RegistrationGroupItem;

const RegistrationGroupItemMember = ({
  member,
  group,
  campId,
  isGroupManager,
}) => {
  const userStore = useContext(UserStoreContext);
  const { history } = useReactRouter();
  const { user } = userStore;

  const [getStudentData] = useLazyQuery<GET_STUDENT_BY_ID_TYPE>(
    GET_STUDENT_BY_ID,
    {
      onCompleted(data) {
        if (data) {
          userStore.detach(user);
          userStore.loadUser(data.getStudentById);
          history.push("/user-details");
        }
      },
    },
  );
  const [isOpen, setIsOpen] = React.useState(false);
  const [isRemoveOpen, setIsRemoveOpen] = React.useState(false);
  const alternateRouteToStudent = () => {
    getStudentData({
      variables: {
        student_id: member.id,
      },
    });
  };

  const dropdownOptions = [
    {
      icon: "user",
      value: "VIEW",
      onClick: () => {
        alternateRouteToStudent();
      },
    },
    {
      icon: "edit",
      value: "EDIT",
      onClick: () => setIsOpen(true),
    },

    {
      icon: "x-circle",
      value: "DELETE",
      onClick: () => setIsRemoveOpen(true),
    },
  ].filter((option) => option);
  return (
    <div className="cursor-pointer">
      <Grid.Row className="mb-2">
        <Grid.Col className="ml-0 pl-0">
          <Avatar
            size="md"
            className="float-left mr-5 cursor-pointer"
            imageURL={
              (member.profile_avatar &&
                `/api/s3/uploads/${member.profile_avatar}`) ||
              "//www.gravatar.com/avatar?d=mp"
            }
          />
          <Text>
            <b>{`${member.first_name} ${member.last_name}`}</b>
          </Text>
          <Text>{member.roleName && `${member.roleName}`}</Text>
        </Grid.Col>
        <Grid.Col md={2} sm={2}>
          {isGroupManager && (
            <Dropdown
              className={
                "float-right cursor-pointer " + (group.color && "text-white")
              }
              toggle={false}
              arrow
              triggerContent={<Icon name="more-vertical" />}
              itemsObject={dropdownOptions}
            />
          )}
        </Grid.Col>
      </Grid.Row>
      <EditRegistrationGroupMemberModal
        member={member}
        isOpen={isOpen}
        toggle={() => setIsOpen(!isOpen)}
        groupId={group.id}
        campId={campId}
      />
      <ConfirmMemberRemovalModal
        isOpen={isRemoveOpen}
        toggle={() => setIsRemoveOpen(!isRemoveOpen)}
        member={member}
        group={group}
      />
    </div>
  );
};

const ConfirmMemberRemovalModal = ({ isOpen, toggle, member, group }) => {
  const [removeGroupMember] = useMutation(DELETE_STUDENT_FROM_GROUP, {
    onCompleted: () => {
      toast.success("Group member removed");
      toggle();
    },
    refetchQueries: ["GET_GROUPS"],
  });
  return (
    <Modal
      open={isOpen}
      onClose={toggle}
      title="Confirm Member Removal"
      content={
        <Grid.Row>
          <Grid.Col>
            <Text>
              Are you sure you want to remove {member.first_name}{" "}
              {member.last_name} from {group.name}?
            </Text>
          </Grid.Col>
        </Grid.Row>
      }
      actions={
        <Grid.Row>
          <Grid.Col>
            <Button
              color="white"
              onClick={() => {
                removeGroupMember({
                  variables: {
                    groupId: group.id,
                    studentId: member.id,
                  },
                });
              }}
            >
              Yes
            </Button>
            <Button color="white" onClick={toggle}>
              No
            </Button>
          </Grid.Col>
        </Grid.Row>
      }
    />
  );
};
