import React from 'react';
import { useQuery } from '@apollo/client';
import Modal from '../../Modal';
import UserEventContent from 'components/User/UserEventContent';
import { GET_ALL_USER_INVOICE_EVENTS } from '../../../graphql/GET_ALL_USER_INVOICE_EVENTS';
import { Types } from '../../../types/graphql'

interface IInvoiceActivityListModalProps {
  invoiceId: number;
  isOpen: boolean;
  setIsOpen: (e: boolean) => void;
}

const InvoiceActivityListModal = ({
  invoiceId,
  isOpen,
  setIsOpen
}: IInvoiceActivityListModalProps) => {
  const { data, loading } = useQuery(GET_ALL_USER_INVOICE_EVENTS, {
    variables: {
      limit: 20,
      page: 1,
      filter: {
        invoiceId
      }
    }
  });
  return (
    <Modal
      title="Invoice Activity"
      content={
        <div>
          {data && data.getInvoiceEvents.userEvents.length === 0 && (
            <p>No activity</p>
          )}
          {data &&
            !loading &&
            data.getInvoiceEvents.userEvents.map(
              (
                event: Types.UserEvents
              ) => {
                return (
                  <UserEventContent key={event.user_events_id} event={event} />
                );
              }
            )}
        </div>
      }
      open={isOpen}
      onClose={() => {
        setIsOpen(false);
      }}
    />
  );
};

export default InvoiceActivityListModal;
