import gql from "graphql-tag";

export const GET_LOAD = gql`
  query GET_LOAD($loadId: ID!) {
    load(loadId: $loadId) {
      created_on
      id
      load_complete
      load_departure
      team_id
      load_name
      load_notes

      aircraft {
        id
        name
        tail_number
        aircraft_type
        aircraft_notes
        capacity_max
        capacity_min
      }

      location {
        id
        type
        name
      }

      status {
        slug
        name
      }

      passengers {
        id
        studentDetails {
          id
          first_name
          last_name
          profile_avatar
        }
        sequence
        jump_type_name
        jump_type_id
        jumpType {
          id
          slug
          name
          seq
        }
        association_type_name
        association_type_id
        associationType {
          id
          slug
          name
        }
      }
    }
  }
`;
