import React, { useState } from 'react';
import { observer } from 'mobx-react';
import { Grid } from 'tabler-react';

import Wrapper from 'pages/layout';
import ReactS3Uploader from 'react-s3-uploader';

const UserUploadPage = (props) => {
  const [data, setData] = useState({
    error: '',
    x: {},
    done: false,
    url: '',
    progress: 0
  });

  const onProgress = (v) => {
    setData({ ...data, progress: v });
    console.log('progress', v);
  };

  const onError = (v) => {
    setData({ ...data, error: v });
    console.log('error', v);
  };

  const onSignedUrl = (v) => {
    setData({ ...data, x: v });
    console.log('signedurl', v);
  };

  const onFinished = (v) => {
    setData({ ...data, url: '/api/s3/uploads/' + v.filename, done: true });
    console.log('finished', v);
  };

  return (
    <Wrapper {...props} title="Upload">
      <div className="container">
        <Grid.Row>
          <Grid.Col lg={12} sm={12}>
            <h1>Test Upload</h1>
            <p>{data.progress && <b>Progress: {data.progress}</b>}</p>
            <p>{data.error && <b>Error: {data.error}</b>}</p>
            <p>{data.url && <b>URL: {data.url}</b>}</p>
            <p>{data.url && <img src={data.url} alt="data url" />}</p>
            <br />
            <ReactS3Uploader
              signingUrl="/api/s3/sign"
              accept="image/*"
              contentDisposition="auto"
              onSignedUrl={onSignedUrl}
              onFinish={onFinished}
              onProgress={onProgress}
              onError={onError}
              uploadRequestHeaders={{
                'x-amz-acl': 'private'
              }}
              autoUpload={true}
            />
          </Grid.Col>
        </Grid.Row>
      </div>
    </Wrapper>
  );
};

export default observer(UserUploadPage);
