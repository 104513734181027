import { useMutation } from "@apollo/client";
import { Formik } from "formik";
import React from "react";
import { toast } from "react-toastify";
import { Button } from "tabler-react";
import * as Yup from "yup";
import FormField from "../../../../components/FormField";
import { UPDATE_NOTIFICATION_EMAIL } from "../../../../graphql/UPDATE_NOTIFICATION_EMAIL";
import { Types } from "../../../../types/graphql";

type EditNotificationEmailFormProps = {
  notificationEmail: Types.NotificationEmail;
  toggleEditForm?: () => void;
};

const updateCampNotificationEmailValidationSchema = Yup.object().shape({
  email: Yup.string().email().required("This field is required."),
});

const EditNotificationEmailForm = ({
  notificationEmail,
  toggleEditForm,
}: EditNotificationEmailFormProps) => {
  const [updateCampNotificationEmail] = useMutation(UPDATE_NOTIFICATION_EMAIL, {
    onCompleted: () => {
      if (toggleEditForm) toggleEditForm();
      toast.success("Email updated");
    },
    refetchQueries: ["GET_NOTIFICATION_EMAILS"],
  });
  return (
    <Formik
      enableReinitialize={true}
      initialValues={{
        id: notificationEmail.id,
        email: notificationEmail.email,
        camp_id: notificationEmail.camp_id,
      }}
      validationSchema={updateCampNotificationEmailValidationSchema}
      onSubmit={async (values, { setSubmitting }) => {
        await updateCampNotificationEmail({
          variables: {
            updateCampNotificationEmailInput: values,
          },
        });
        setSubmitting(false);
      }}
    >
      {({ values, handleChange, handleSubmit, isSubmitting }) => (
        <form onSubmit={handleSubmit}>
          <FormField
            appendleft={<Button icon="mail" size="sm" color="secondary" disabled />}
            appendright={
              <Button
                icon="check"
                size="sm"
                color="success"
                disabled={isSubmitting}
                onClick={handleSubmit}
              />
            }
            name="email"
            type="email"
            onChange={handleChange}
            value={values.email}
          />
        </form>
      )}
    </Formik>
  );
};

export { EditNotificationEmailForm };
