import { useMutation } from '@apollo/client'
import { CAMP_HAS_JUMP_TICKETS } from 'graphql/CAMP_HAS_JUMP_TICKETS'
import { GET_REGISTERED_STUDENTS } from 'graphql/GET_REGISTERED_STUDENTS'
import { RESET_JUMP_TICKETS } from 'graphql/RESET_JUMP_TICKETS'
import ConfirmationModal from 'modules/common/components/modals/ConfirmationModal'
import React, { useState } from 'react'
import { useParams } from 'react-router'
import { toast } from 'react-toastify'
import { Dropdown } from 'tabler-react'
import useGetCurrentUserType from "modules/common/hooks/useGetCurrentUserType";

type Props = object

const ResetJumpTicketsButton = (props: Props) => {
  const { campId } = useParams<{ campId: string }>()
  const { isCoachSuperAdmin } = useGetCurrentUserType();

  const [isResetModalOpen, setIsResetModalOpen] = useState(false)

  const toggleResetModal = () => {
    setIsResetModalOpen(!isResetModalOpen)
  }

  const [resetJumpTickets, { loading: resetLoading }] = useMutation(
    RESET_JUMP_TICKETS,
    {
      onCompleted: () => {
        toast.success('Jump tickets reset successfully')
      }
    }
  )

  return (
    <>
    {isCoachSuperAdmin &&
      <Dropdown.Item
        icon="x-circle"
        color="white"
        className="text-danger float-left cursor-pointer"
        onClick={(e) => {
          e.preventDefault()
          toggleResetModal()
        }}
      >
        RESET JUMP TICKETS
      </Dropdown.Item>
      }
      <ConfirmationModal
        confirmText="RESET"
        content="Are you sure you want to reset all jump tickets?"
        isSubmitting={resetLoading}
        open={isResetModalOpen}
        title="Reset Jump Tickets"
        onClose={toggleResetModal}
        onConfirm={async () => {
          await resetJumpTickets({
            variables: {
              campId: Number(campId)
            },
            refetchQueries: [
              {
                query: GET_REGISTERED_STUDENTS,
                variables: {
                  campId: Number(campId)
                }
              },
              {
                query: CAMP_HAS_JUMP_TICKETS,
                variables: {
                  campId: Number(campId)
                }
              }
            ]
          })

          toggleResetModal()
        }}
      />
    </>
  )
}

export default ResetJumpTicketsButton
