import gql from 'graphql-tag';

export const GET_SPORTS_LOCATIONS_TEAMS_PROGRESSIONS_BY_STUDENT_ID = gql`
  query GET_SPORTS_LOCATIONS_TEAMS_PROGRESSIONS_BY_STUDENT_ID(
    $student_id: ID!
  ) {
    getSportsByStudentId(student_id: $student_id) {
      id
      name
      is_active
    }
    getLocationsByStudentId(student_id: $student_id) {
      id
      name
    }
    getTeamsByStudentId(student_id: $student_id) {
      id
      name
    }
    getStudentProgressions(student_id: $student_id) {
      heading
      total
      achieved
      verified
      percentage
    }
  }
`;
