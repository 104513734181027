import React from 'react';
import { Form, Grid, Text } from 'tabler-react';
import RegistrationQuestionField from '../../Registration/RegistrationQuestionField';
import RegistrationQuestionFieldMulti from '../../Registration/RegistrationQuestionFieldMulti';
import { Types } from '../../../types/graphql'

type QuestionResponse = {
  [key: number]: string
}

interface IStudentRegistrationQuestionsFormProps {
  registrationQuestions: Types.RegistrationQuestion[];
  registrationQuestionsResponse: object;
  setQuestionResponse: (QuestionResponse) => void;
  validateQuestion: boolean;
  studentId: number;
}

const StudentRegistrationQuestionsForm = ({
  registrationQuestions,
  registrationQuestionsResponse,
  setQuestionResponse,
  validateQuestion,
  studentId
}: IStudentRegistrationQuestionsFormProps) => {
  const handleResponseChange = (e: string, questionId: number) => {
    const stateClone = registrationQuestionsResponse;
    stateClone[questionId] = e;
    setQuestionResponse({
      ...registrationQuestionsResponse,
      prop: e
    });
  };
  return (
    <Form.Group label="">
      <p>
        <Text.Small className="text-muted font-italic">
          Previously answered questions are loaded by default. Update as needed.
        </Text.Small>
      </p>
      {registrationQuestions.map(
        (question: Types.RegistrationQuestion) => {
          return (
            <Grid.Row className="mb-3">
              {question.registration_question_type_id === 8 ? (
                <RegistrationQuestionFieldMulti
                  key={question.id}
                  question={question}
                  responseChangeHandler={handleResponseChange}
                  validateQuestion={validateQuestion}
                  registrationQuestionsResponse={
                    registrationQuestionsResponse[question.id]
                  }
                />
              ) : (
                <RegistrationQuestionField
                  key={question.id}
                  question={question}
                  responseChangeHandler={handleResponseChange}
                  validateQuestion={validateQuestion}
                  registrationQuestionsResponse={
                    registrationQuestionsResponse[question.id]
                  }
                  studentId={studentId}
                />
              )}
            </Grid.Row>
          );
        }
      )}
    </Form.Group>
  );
};

export default StudentRegistrationQuestionsForm;
