import { BulkLogPaymentModal } from 'components/Event/Modals/BulkPaymentLogForm'
import useGetCurrentUserType from 'modules/common/hooks/useGetCurrentUserType'
import { PaymentButtons } from 'modules/payment/components/buttons/PaymentButtons'
import RegistrationPaymentsModal from 'modules/registration/payments/modals/RegistrationPaymentsModal'
import { useMemo, useState } from 'react'
import { CSVLink } from 'react-csv'
import {
  Avatar,
  Button,
  Card,
  Form,
  Grid,
  Icon,
  Table,
  Text
} from 'tabler-react'
import { Types } from 'types/graphql'
import useReactRouter from 'use-react-router'
import { formatMoney } from '../../../utils/numberFormat'
import { calcTotalPaid } from '../../payment/utils/calcTotalPaid'
import { calcTotalFees } from '../../user/registrations/utils/calcTotalFees'

type EventFinanceProps = {
  campData: Types.Camp
  registeredStudents: Types.RegisteredStudents[]
}
const EventFinance = ({ campData, registeredStudents }: EventFinanceProps) => {
  const [isBulkLogPaymentModalOpen, setIsBulkLogPaymentModalOpen] =
    useState<boolean>(false)
  const [selectedParticipant, setSelectedParticipant] = useState<
    Types.RegisteredStudents[]
  >([])
  const { isAdmin, isCoachSuperAdmin } = useGetCurrentUserType()

  const participants = useMemo(() => {
    if (!registeredStudents) return []

    return registeredStudents.filter(
      (student) => student.status === 'Confirmed'
    )
  }, [registeredStudents])

  const participantsCSV = useMemo(() => {
    if (!registeredStudents) return []

    return registeredStudents
      .filter((student) => student.status === 'Confirmed')
      .map((student) => {
        const remainingBalance =
          calcTotalFees(student) -
          calcTotalPaid(
            student.payments.map(({ amount, status }) => ({
              amount,
              status
            }))
          )

        const compTotal = student.payments.reduce((acc, payment) => {
          if (payment.status === 'Comp') {
            return acc + payment.amount
          }
          return acc
        }, 0)

        const totalFees = calcTotalFees(student)

        let campGroupName = ''
        if (student.campGroups && student.campGroups.length > 0)
          campGroupName = student.campGroups[0].name

        return {
          userId: student.id,
          name: student.first_name + ' ' + student.last_name,
          registrationId: student.user_registrations_id,
          code: student.registration_code,
          team: campGroupName,
          discipline: student.discipline,
          total: formatMoney(totalFees, campData.registration_currency),
          paid: formatMoney(
            totalFees - remainingBalance,
            campData.registration_currency
          ),
          balance: formatMoney(
            remainingBalance > 0 ? remainingBalance : 0,
            campData.registration_currency
          )
        }
      })
  }, [registeredStudents])

  const [selectAll, setSelectAll] = useState<boolean>(false)

  const handleToggleSelectAll = (selectedAll) => {
    if (selectedAll) {
      setSelectedParticipant(
        registeredStudents.filter((student) => student.status === 'Confirmed')
      )
    } else {
      setSelectedParticipant([])
    }
  }

  const handleToggleSelected = (id: number) => {
    const pool = registeredStudents.filter(
      (student) => student.status === 'Confirmed'
    )
    if (
      selectedParticipant?.find((student) => {
        return student.user_registrations_id === id
      })
    ) {
      setSelectedParticipant(
        (prev) => prev?.filter((p) => p.user_registrations_id !== id) ?? []
      )
    } else {
      setSelectedParticipant((prev) => [
        ...(prev ?? []),
        pool.find((student) => student.user_registrations_id === id)
      ])
    }
  }

  return (
    <>
      <Card.Body>
        <Grid.Row className="p-3">
          <Grid.Col width={1} className="pt-2">
            <Form.Label>
              <input
                id={`selectAll`}
                name={'select_all'}
                type="checkbox"
                checked={selectAll}
                onClick={() => {
                  setSelectAll(!selectAll)
                  handleToggleSelectAll(!selectAll)
                }}
                onChange={() => {}}
                className="float-left pb-2"
              />
            </Form.Label>
          </Grid.Col>
          <Grid.Col width={11} className="text-right">
            <Button
              outline={selectedParticipant.length !== 0}
              icon="credit-card"
              color={selectedParticipant.length !== 0 ? 'secondary' : 'white'}
              size={'sm'}
              onClick={() => {
                setIsBulkLogPaymentModalOpen(true)
              }}
              className="mr-2"
              disabled={selectedParticipant.length === 0}
            >
              PAYMENTS
            </Button>
            {(isAdmin || isCoachSuperAdmin) && (
              <Button
                outline={selectedParticipant.length !== 0}
                icon="mail"
                color={selectedParticipant.length !== 0 ? 'secondary' : 'white'}
                size={'sm'}
                className="mr-2"
                //  onClick={() => {setIsBulkLogPaymentModalOpen(true)}}
                disabled
              >
                EMAIL
              </Button>
            )}
            <Button outline color="secondary" size="sm">
              <CSVLink
                data={participantsCSV}
                filename="finance_report.csv"
                className="ml-0 text-secondary"
              >
                <Icon name="download" className="mr-1" />
                CSV
              </CSVLink>
            </Button>
          </Grid.Col>
        </Grid.Row>
        <Grid.Row>
          <Grid.Col width={12}>
            <Table responsive className="table-vcenter text-nowrap">
              <Table.Body>
                {participants.map((student) => (
                  <Table.Row key={`registration-${student.id}`}>
                    <EventFinanceItem
                      student={student}
                      camp={campData}
                      checked={
                        !!selectedParticipant.find(
                          (p) =>
                            p.user_registrations_id ===
                            student.user_registrations_id
                        )
                      }
                      handleToggleSelected={handleToggleSelected}
                    />
                  </Table.Row>
                ))}
              </Table.Body>
            </Table>
          </Grid.Col>
        </Grid.Row>
      </Card.Body>
      {campData && (
        <BulkLogPaymentModal
          isOpen={isBulkLogPaymentModalOpen}
          toggle={() => setIsBulkLogPaymentModalOpen(false)}
          students={selectedParticipant}
          currency={campData.registration_currency}
          teamId={Number(campData.team_id)}
        />
      )}
    </>
  )
}

export default EventFinance

const EventFinanceItem = ({ student, camp, checked, handleToggleSelected }) => {
  {
    const [openPaymentModal, setOpenPaymentModal] = useState<boolean>(false)
    const { history } = useReactRouter()
    const { total, outstanding, totalPaid, totalFees, totalComp } =
      student.paymentDetail

    const paymentStatus = useMemo(() => {
      if (totalComp === total) {
        return 'Comp'
      }

      if (outstanding === 0) {
        return 'Paid'
      }
      return ''
    }, [totalComp, outstanding, totalFees])

    let campGroupName = ''
    if (student.campGroups && student.campGroups.length > 0)
      campGroupName = student.campGroups[0].name
    return (
      <>
        <Table.Col>
          <input
            id={`participant-${student.student_id}`}
            name={'asdasd'}
            type="checkbox"
            checked={checked}
            onClick={() => handleToggleSelected(student.user_registrations_id)}
            onChange={() => {}}
          />
        </Table.Col>
        <Table.Col>
          <Avatar
            size="md"
            className="cursor-pointer"
            status={student.status_color ?? 'danger'}
            imageURL={
              student?.profile_avatar
                ? `/api/s3/uploads/${student.profile_avatar}`
                : '//www.gravatar.com/avatar?d=mp'
            }
            onClick={() =>
              history.push(
                `/events/registrations/${student.user_registrations_id}/${student.id}`
              )
            }
          />
        </Table.Col>
        <Table.Col>
          <strong>
            <Text
              className="cursor-pointer d-block"
              onClick={() =>
                history.push(
                  `/events/registrations/${student.user_registrations_id}/${student.id}`
                )
              }
            >
              {student.first_name + ' ' + student.last_name}
            </Text>
          </strong>
        </Table.Col>
        <Table.Col>
          {campGroupName}
          <Text.Small className="text-muted">{student.discipline}</Text.Small>
        </Table.Col>
        <Table.Col>
          <PaymentButtons
            totalComp={totalComp}
            totalPaid={totalPaid}
            outstanding={outstanding}
            paymentStatus={paymentStatus}
            onClick={() => setOpenPaymentModal(true)}
            status={student.status}
          />

          {openPaymentModal && (
            <RegistrationPaymentsModal
              outstanding={outstanding / 100}
              totalPaid={totalPaid / 100}
              userRegistrationDetails={student}
              open={openPaymentModal}
              onClose={() => setOpenPaymentModal(false)}
            />
          )}
        </Table.Col>
        <Table.Col>
          {/*
          <Icon name="more-vertical" />
          */}
        </Table.Col>
      </>
    )
  }
}
