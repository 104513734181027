import React from 'react';
import { Button, Table } from 'tabler-react';
import ManufacturerListItemEditName from './Form/ManufacturerListItemEditName';
import ManufacturerListItemEditImage from './Form/ManufacturerListItemEditImage';
import { useMutation } from '@apollo/client';
import { UPDATE_GEAR_MANUFACTURER } from '../../graphql/UPDATE_GEAR_MANUFACTURER';
import { toast } from 'react-toastify';
import { Types } from '../../types/graphql'

interface ManufacturerListItemProps {
  manufacturer: Types.GearManufacturer;
}

const ManufacturerListItem = ({ manufacturer }: ManufacturerListItemProps) => {
  const [showEditName, setShowEditName] = React.useState<boolean>(false);
  const [updateGearManufacturer] = useMutation(UPDATE_GEAR_MANUFACTURER, {
    onCompleted: (response) => {
      if (response.updateGearManufacturer) {
        toast.success('Manufacturer updated successfully');
      }
    }
  });

  const updateImage = async (image: string | null) => {
    await updateGearManufacturer({
      variables: {
        manufacturer: {
          id: manufacturer.id,
          image
        }
      },
      refetchQueries: ['GET_ALL_GEAR_MANUFACTURERS']
    });
  };
  return (
    <Table.Row key={manufacturer.id}>
      <Table.Col>{manufacturer.id}</Table.Col>
      <Table.Col>
        {showEditName ? (
          <ManufacturerListItemEditName
            manufacturer={manufacturer}
            setEditing={setShowEditName}
          />
        ) : (
          <span
            className="cursor-pointer"
            onClick={() => setShowEditName(true)}
          >
            {manufacturer.name}
          </span>
        )}
      </Table.Col>
      <Table.Col className="cursor-pointer text-muted">
        {manufacturer?.slug}
      </Table.Col>
      <Table.Col>
        {manufacturer.image ? (
          <>
            <img
              src={`/api/s3/uploads/${manufacturer.image}`}
              width={'100px'}
              alt={'Manufacturer'}
            />
            <Button
              icon={'x'}
              size={'xs'}
              color={'white'}
              className={''}
              onClick={() => updateImage(null)}
            />
          </>
        ) : (
          <ManufacturerListItemEditImage
            manufacturer={manufacturer}
            updateImage={updateImage}
          />
        )}
      </Table.Col>
    </Table.Row>
  );
};

export default ManufacturerListItem;
