import React, { FC } from "react";
import { Container, Nav } from "tabler-react";
import { useGetUserIsAdmin } from "../../modules/common/hooks/useGetCurrentUserType";
import { UseIsMobile } from "../../utils/windowListener";
import AdminMobileNav from "./AdminMobileNav";

interface NavbarProps {
  collpase: boolean;
}

const Navbar: FC<NavbarProps> = ({ children, collpase }) => {
  const isAdmin = useGetUserIsAdmin();
  return (
    <>
      <div className={`header d-lg-flex p-0 ${collpase && "collapse"}`}>
        <Container>
          <Nav className="border-0 flex-column flex-lg-row">{children}</Nav>
        </Container>
      </div>
      {isAdmin && UseIsMobile() && <AdminMobileNav />}
    </>
  );
};

export default Navbar;
