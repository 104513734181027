import React from 'react';
import { Link } from 'react-router-dom';
import { Grid, StampCard } from 'tabler-react';
import { useRootStore } from 'hooks/useRootStore';

import formatNumber, { formatMoney } from '../../utils/numberFormat';
import { observer } from 'mobx-react';

const PaymentsTotal = () => {
  const rootStore = useRootStore();
  const { paymentsTotal, paymentProcessedTotal } = rootStore;

  return (
    <Grid.Col sm={6} lg={3} xs={12} className="mb-0">
      <StampCard
        color="success"
        icon="dollar-sign"
        header={
          <Link to={`/payments`}>
            {formatNumber(paymentsTotal)} <small>Payments</small>
          </Link>
        }
        footer={`${formatMoney(paymentProcessedTotal)}`}
      />
    </Grid.Col>
  );
};

export default observer(PaymentsTotal);
