import { useLazyQuery } from '@apollo/client';
import { observer } from 'mobx-react';
import { applySnapshot } from 'mobx-state-tree';
import React, { useEffect } from 'react';
import { Container, Dimmer } from 'tabler-react';

import CoachList from '../../components/Coaches/CoachList';
import { GET_COACHES } from '../../graphql/GET_COACHES';
import { useRootStore } from '../../hooks/useRootStore';
import Wrapper from '../layout';

const CoachListPage = (props) => {
  const { coaches } = useRootStore();

  const [getCoaches, { loading, error, data }] = useLazyQuery(GET_COACHES);

  // Run once on mount
  useEffect(() => {
    if (!coaches.length) {
      getCoaches();
    }
  }, []);  

  if (error) {
    return <p>{`Error: ${error.message}`}</p>;
  }

  if (!coaches.length && !loading && data) {
    applySnapshot(coaches, data.getCoaches);
  }

  return (
    <Wrapper {...props} title="Coach List">
      <Container>
        <Dimmer active={loading} loader={loading}>
          <CoachList coaches={coaches} />
        </Dimmer>
      </Container>
    </Wrapper>
  );
};
export default observer(CoachListPage);
