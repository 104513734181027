import { observer } from 'mobx-react';
import React from 'react';
import { Container } from 'tabler-react';
import Wrapper from '../layout';
import JobGearStorage from '../../components/Gear/JobGearStorage';

const JobGearStoragePage = (props) => {

  return (
    <Wrapper {...props} title='Job Storage'>
      <Container>
        <JobGearStorage />
      </Container>
    </Wrapper>
  );
};
export default observer(JobGearStoragePage);
