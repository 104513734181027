import gql from "graphql-tag";

export const GET_CAMPS = gql`
  query GET_CAMPS($filter: CampsFilter) {
    getCamps(filters: $filter) {
      id
      camp_name
      camp_description
      camp_image
      team_id
      location_id
      start
      end
      event_type_id
      sport_type_id
      camp_type_id
      status
      all_day
      created_on
      created_by
      updated_on
      updated_by
      is_public
      is_published
      camp_email
      camp_phone
      camp_url
      camp_fees
      camp_fee_type_id
      students_per_slot
      student_registration
      auto_approval
      score_cards
      slot_duration
      registration_currency
      registration_information
      registration_status
      registration_type
      registration_waitlist
      registrations_max
      location
      event_type_name
      team_name
      camp_start_time
      camp_end_time
      organizers {
        id
        coach_id
        coach_first_name
        coach_last_name
        coach_email
        coach_email_private
        coach_phone_country_code
        coach_phone
        coach_phone_private
        coach_profile_avatar
      }
      registered_students {
        id
        student_id
        phone_country_code
        camp_id
        first_name
        last_name
        profile_avatar
        registration_code
        user_registrations_id
      }
    }
  }
`;
