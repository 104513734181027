import gql from 'graphql-tag';

export const GET_TEAM_LOCATIONS_BY_SPORT_TYPE = gql`
  query GET_TEAM_LOCATIONS_BY_SPORT_TYPE(
    $team_id: Int!
    $sport_type_id: Int!
  ) {
    getTeamLocationsBySportType(
      team_id: $team_id
      sport_type_id: $sport_type_id
    ) {
      id
      name
    }
  }
`;
