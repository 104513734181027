import React from 'react';
import Modal from '../../Modal';
import { Card } from 'tabler-react';

interface ConfirmLoadPassengerModalProps {
  isOpen: boolean;
  toggleModal: () => void;
  passengers: any;
  onSubmit: () => void;
}

const ConfirmLoadPassengerModal = ({
  isOpen,
  toggleModal,
  passengers,
  onSubmit
}: ConfirmLoadPassengerModalProps) => {
  return (
    <Modal
      content={
        <Card>
          <Card.Body>
            {passengers.map((passenger: any) => (
              <div key={passenger.id}>
                <p>
                  {passenger.studentDetails.first_name}{' '}
                  {passenger.studentDetails.last_name} -{' '}
                  {passenger.association_type_name}{' '}
                </p>
              </div>
            ))}
          </Card.Body>
          <Card.Footer>
            <div className="d-flex">
              <button className="btn btn-primary mr-2" onClick={onSubmit}>
                Confirm
              </button>
              <button className="btn btn-secondary" onClick={toggleModal}>
                Cancel
              </button>
            </div>
          </Card.Footer>
        </Card>
      }
      open={isOpen}
      onClose={toggleModal}
      title={'Confirm Load Passenger'}
    />
  );
};

export default ConfirmLoadPassengerModal;
