import React from 'react';
import { Button, Card, Grid, Icon, Table } from 'tabler-react';
import useGetCurrentUserType from '../../modules/common/hooks/useGetCurrentUserType';


const LogbookList = () => {
const { isAdmin } = useGetCurrentUserType();

  return (
    <Card>
      <Card.Header>
        <Card.Title>
          <Icon name="calendar" className="mr-2 ml-0 text-blue" />
          Logbook
        </Card.Title>
        <Card.Options>
          <Button 
            icon="upload" 
            color="info" 
            size="sm" 
            className="mr-3"
            disabled={!isAdmin}
          >
            Import
          </Button>
          <Button 
            icon="plus" 
            color="primary" 
            size="sm" 
            className="mr-3"
          >
            Log
          </Button>
        </Card.Options>
      </Card.Header>
      <Card.Body>
        <Grid.Row>
          <Grid.Col width={12}>
            <Table
              responsive
              className="card-table table-vcenter text-nowrap"
            >
              <Table.Header>
                <Table.Row>
                  {isAdmin &&
                    <>
                      <Table.ColHeader>ID</Table.ColHeader>
                      <Table.ColHeader>Status</Table.ColHeader>
                      <Table.ColHeader>Name</Table.ColHeader>
                    </>
                  }
                  <Table.ColHeader>Number</Table.ColHeader>
                  <Table.ColHeader>Date</Table.ColHeader>
                  <Table.ColHeader>Details</Table.ColHeader>
                </Table.Row>
              </Table.Header>
            </Table>
          </Grid.Col>
        </Grid.Row>
      </Card.Body>
    </Card>
  );
};

export default LogbookList;
