import DateFnsUtils from '@date-io/date-fns';
import { styled } from '@material-ui/core/styles';
import {
  DatePickerProps,
  DatePicker as MaterialDatePicker,
  MuiPickersUtilsProvider
} from '@material-ui/pickers';
import { ParsableDate } from '@material-ui/pickers/constants/prop-types';
import 'date-fns';
import React from 'react';

const StyledDatePicker = styled(MaterialDatePicker)({
  margin: '0',
  '& div > input': {
    display: 'block',
    background: '#fff',
    padding: '0.375rem 0.75rem',
    fontSize: '0.9375rem',
    lineHeight: '1.6',
    color: '#495057',
    backgroundClip: 'padding-box',
    border: '1px solid rgba(0, 40, 100, 0.12)',
    borderRadius: '3px',
    transition: 'border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out',
    height: 'auto'
  },
  '& div::before': {
    content: 'none'
  },
  '& div::after': {
    content: 'none'
  },
  '&.is-invalid div > input': {
    borderColor: '#cd201f'
  },
  '&.state-invalid div > input': {
    paddingRight: '2rem',
    background: 'no-repeat center right 0.5rem/1rem'
  },
  '&.is-valid div > input': {
    borderColor: '#5eba00'
  },
  '&.state-valid div > input': {
    paddingRight: '2rem',
    background: 'no-repeat center right 0.5rem/1rem'
  }
});

interface IDatePickerProps extends DatePickerProps {
  autoOk?: boolean;
  className?: string;
  disablePast?: boolean;
  format?: string;
  maxDate?: Date;
  minDate?: Date;
  readOnly?: boolean;
  value: ParsableDate;
  onChange: (date: Date) => void;
}

const DatePicker = (props: IDatePickerProps) => {
  const {
    className,
    disablePast,
    format,
    minDate,
    maxDate,
    readOnly,
    value,
    autoOk,
    onChange,
    ...rest
  } = props;

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <StyledDatePicker
        margin="normal"
        className={className}
        format={format || 'MM/dd/yyyy'}
        disablePast={disablePast || false}
        minDate={minDate || undefined}
        maxDate={maxDate || undefined}
        value={value || null}
        onChange={(date) => onChange(date)}
        readOnly={readOnly || false}
        variant="inline"
        disableToolbar
        autoOk={autoOk || true}
        {...rest}
      />
    </MuiPickersUtilsProvider>
  );
};

export default DatePicker;
