import gql from 'graphql-tag';

export const CHARGE_STRIPE_V2 = gql`
  mutation CHARGE_STRIPE_V2($chargeParams: ChargeParamsV2!) {
    chargeStripeV2(chargeParams: $chargeParams) {
      success
    }
  }
`;

