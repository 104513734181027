import gql from 'graphql-tag';

export const ADD_REGISTRATION_STATUS_CONTENT = gql`
  mutation ADD_REGISTRATION_STATUS_CONTENT(
    $registrationStatusContentInput: RegistrationStatusContentInput!
  ) {
    addRegistrationStatusContent(
      registrationStatusContentInput: $registrationStatusContentInput
    )
  }
`;
