import React from 'react';
import Wrapper from '../layout';
import { observer } from 'mobx-react';
import OrgList from 'components/Orgs/OrgList';

const OrgListPage = (props) => {
  return (
    <Wrapper {...props} title="Admin Orgs">
      <div className="container">
        <OrgList />
      </div>
    </Wrapper>
  );
};

export default observer(OrgListPage);
