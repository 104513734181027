import { useQuery } from '@apollo/client';
import TeamListItem from 'components/Team/TeamListItem';
import { TeamStoreContext } from 'contexts/teamStoreContext';
import { GET_TEAMS } from 'graphql/GET_TEAMS';
import { GET_TEAMS as GET_TEAMS_TYPE } from 'graphql/types/GET_TEAMS';
import { observer } from 'mobx-react';
import { applySnapshot } from 'mobx-state-tree';
import NewTeamButton from 'modules/team/components/buttons/NewTeamButton';
import React, { useContext, useEffect } from 'react';
import { Button, Card, Dimmer, Grid, Icon } from 'tabler-react';
import TeamSearchField from '../../components/Students/Forms/TeamSearchField';


export enum ECampFeeType {
  per_slot = 'per slot',
  per_hour = 'per hour',
  per_day = 'per day',
  per_event = 'per event',
  per_jump = 'per jump'
}

export enum ERegistrationOptionType {
  payment = 'Payment Options',
  pricing = 'Pricing Options',
  addon = 'Add ons',
  agree = 'Agreements and Terms'
}

const TeamList = () => {
  const { fee_types, registration_option_types, teams } =
    useContext(TeamStoreContext);

  const { loading, error, data } = useQuery<GET_TEAMS_TYPE>(GET_TEAMS, {
    variables: {
      filter: {}
    }
  });

  useEffect(() => {
    if (!loading && !error && data) {
      applySnapshot(
        fee_types,
        data.feeTypes.filter(
          (feeType) =>
            feeType.name !== ECampFeeType.per_slot &&
            feeType.name !== ECampFeeType.per_day
        )
      );
      applySnapshot(
        registration_option_types,
        data.getRegistrationOptionTypes.filter(
          (regOptType) => regOptType.name !== ERegistrationOptionType.payment
        )
      );
      applySnapshot(teams, data.teams);
    }
  }, [loading, error, data]);

  if (error) {
    return <p>{`Error: ${error.message}`}</p>;
  }

  return (
    <Card>
      <Card.Header>
        <Card.Title className="d-none d-lg-block">
          <Icon name="users" className="mr-2 ml-0 text-blue" /> Teams
        </Card.Title>
        <Card.Options className="gap-2">
          <TeamSearchField />
          <NewTeamButton />
        </Card.Options>
      </Card.Header>
      <Card.Body>
        <Grid.Row>
          <Grid.Col>
            <Dimmer active={loading} loader={loading}>
              <Grid.Row cards deck>
                {teams.map((team) => (
                  <TeamListItem team={team} key={team.id} />
                ))}
              </Grid.Row>
            </Dimmer>
          </Grid.Col>
        </Grid.Row>
      </Card.Body>
    </Card>
  );
};

export default observer(TeamList);
