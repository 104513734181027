import { applySnapshot, onSnapshot } from 'mobx-state-tree';
import React, { createContext, ReactNode, useState } from 'react';

import {
  LocationStore,
  LocationStoreDefault,
  LocationStoreType
} from '../models/LocationStore';

export const locationStoreContext = createContext<LocationStoreType | null>(
  null
);

interface LocationStoreProvider {
  children: ReactNode;
}

const getOrCreateStore = () => {
  const locationStore = LocationStoreDefault();
  const initData = localStorage.getItem('locations');

  if (initData) {
    applySnapshot(locationStore, JSON.parse(initData));
    return locationStore;
  } else {
    return LocationStore.create();
  }
};

export const LocationStoreProvider = (props: LocationStoreProvider) => {
  const [locationStore] = useState(() => getOrCreateStore());

  onSnapshot(locationStore, (_snapshot) => {
    const snapshot = { ..._snapshot };
    const data = JSON.stringify(snapshot);
    localStorage.setItem('locations', data);
  });

  // listen to new snapshots
  onSnapshot(locationStore, (snapshot) => {
    console.dir('locationStore snapshot', snapshot);
  });

  return (
    <locationStoreContext.Provider value={locationStore}>
      {props.children}
    </locationStoreContext.Provider>
  );
};
