import TextEditor from 'components/text-editor/TextEditor';
import { useFormikContext } from 'formik';
import useDebounceCallback from 'modules/common/utils/useDebounceCallback';
import { NewTeamOnboardingFormValues } from 'modules/team/components/forms/NewTeamOnboardingForm';
import React, { useState } from 'react';
import PhoneInput from 'react-phone-input-2';
import { Form } from 'tabler-react';

const SlugInput = () => {
  const { errors, touched, values, setFieldValue } =
    useFormikContext<NewTeamOnboardingFormValues>();

  const [value, setValue] = useState(values.slug ?? '');

  const onChange = () => {
    setFieldValue('slug', value);
  };

  const debouncedOnChange = useDebounceCallback(onChange);

  return (
    <Form.Group>
      <Form.Input
        name="slug"
        placeholder="username"
        onChange={(e) => {
          debouncedOnChange();
          setValue(e.target.value);
        }}
        value={value}
      />
      <span className="field-error text-danger">
        {errors.slug && touched.slug && errors.slug}
      </span>
    </Form.Group>
  );
};

const TeamDetailsStep = () => {
  const { errors, touched, values, handleChange, setFieldValue } =
    useFormikContext<NewTeamOnboardingFormValues>();

  return (
    <>
      <Form.Group label="Name">
        <Form.Input
          name="name"
          onChange={handleChange}
          placeholder="Team Name"
          value={values.name}
        />
        <span className="field-error text-danger">
          {errors.name && touched.name && errors.name}
        </span>
      </Form.Group>

      <Form.Group label="Bio">
        <TextEditor
          placeholder="Add a team bio"
          value={values.team_bio}
          onChange={(content: string) => {
            setFieldValue('team_bio', content);
          }}
        />
        <span className="field-error text-danger">
          {errors.team_bio && touched.team_bio && errors.team_bio}
        </span>
      </Form.Group>

      <Form.Group label="Phone">
        <PhoneInput
          inputProps={{
            className: 'form-control w-100',
            name: 'team_phone',
            onChange: (e) => {
              const phone = e.target.value.split(' ').join('');
              setFieldValue('team_phone', phone);
            }
          }}
          value={`${values.team_phone}`}
        />
        <span className="field-error text-danger">
          {errors.team_phone && touched.team_phone && errors.team_phone}
        </span>
      </Form.Group>

      <Form.Group label="Email">
        <Form.Input
          icon="mail"
          name="team_email"
          onChange={(e) => setFieldValue('team_email', e.target.value)}
          placeholder="Email"
          value={values.team_email}
        />
        <span className="field-error text-danger">
          {errors.team_email && touched.team_email && errors.team_email}
        </span>
      </Form.Group>

      <Form.Group label="Website">
        <Form.Input
          icon="globe"
          name="team_url"
          onChange={(e) => setFieldValue('team_url', e.target.value)}
          placeholder="http://"
          value={values.team_url}
        />
        <span className="field-error text-danger">
          {errors.team_url && touched.team_url && errors.team_url}
        </span>
      </Form.Group>

      <Form.Group>
        <Form.Switch
          type="checkbox"
          name="preference_units"
          onChange={(e) => setFieldValue('preference_units', e.target.checked)}
          checked={values.preference_units ? true : false}
          label="Metric (km, kg)"
        />
      </Form.Group>

      <SlugInput />
    </>
  );
};

export default TeamDetailsStep;
