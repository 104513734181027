import { useMutation } from '@apollo/client';
import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { IconCircleCheck } from '@tabler/icons-react';
import Modal from 'components/Modal';
import { Formik } from 'formik';
import { GET_INVOICE } from 'graphql/GET_INVOICE';
import { GET_INVOICE_PAYMENTS } from 'graphql/GET_INVOICE_PAYMENTS';
import { INSERT_PAYMENT } from 'graphql/INSERT_PAYMENT';
import { CHARGE_STRIPE_V2 } from 'graphql/CHARGE_STRIPE_V2'
import { ADD_USER_EVENT } from 'graphql/ADD_USER_EVENT'
import { useRootStore } from 'hooks/useRootStore';
import StatusModal from 'modules/common/components/modals/StatusModal';
import { useGetUserIsStudent } from 'modules/common/hooks/useGetCurrentUserType';
import ManualPaymentForm from 'modules/invoice/components/forms/ManualPaymentForm';
import StripePaymentForm from 'modules/invoice/components/forms/StripePaymentForm';
import { EManualPaymentOpts } from 'modules/payment/constants/manualPaymentOptions';
import { EPaymentStatus } from 'modules/payment/constants/paymentStatus';
import { EUserEventsId } from 'components/User/UserEvents'
import React, { useState } from 'react';
import { toast } from 'react-toastify';
import { Tab, TabbedCard } from 'tabler-react';
import { formatMoney } from 'utils/numberFormat';
import * as Yup from 'yup';
import { Types } from '../../../../types/graphql'

export const stripeFormSchema = (data) =>
  Yup.object().shape({
    email: Yup.string()
      .email('Invalid email.')
      .required('This field is required.'),
    name: Yup.string().required('This field is required.'),
    total: (() => {
      let validation = Yup.number().required('This field is required.');
      if (data?.total) {
        validation = validation.max(
          data.total,
          `Max amount is ${formatMoney(data.total)}`
        );
      }
      return validation;
    })()
  });

export const manualFormSchema = Yup.object().shape({
  amount: Yup.number().required('This field is required.'),
  description: Yup.string()
});

interface InvoicePaymentModalProps {
  invoice: Types.Invoice
  amount: number;
  currency: string;
  email?: string;
  invoiceId: number;
  isModalOpen: boolean;
  jobIds: number[];
  name: string;
  paymentFee: number;
  stripeAccountId: string;
  studentId: number
  teamId?: number;
  toggleModal: () => void;
}

export function calculateFees(total: number, paymentFee: number) {
  return total * paymentFee
}

export function calculateTotalWithFees(total: number, paymentFee: number) {
  return total * (1.0 + paymentFee)
}

export function convertToCents(amount: number) {
  // to prevent rounding errors we multiply by 100 and a bit
  return Math.round(amount * 100.0000000001)
}

const InvoicePaymentModal = ({
  invoice,
  amount,
  currency,
  name: defaultName,
  email: defaultEmail,
  invoiceId,
  isModalOpen,
  jobIds,
  paymentFee,
  stripeAccountId,
  studentId,
  teamId,
  toggleModal
}: InvoicePaymentModalProps) => {
  const { currentUser, currentCoachTeam } = useRootStore();
  const stripe = useStripe();
  const elements = useElements();

  const isStudent = useGetUserIsStudent()

  const [isStatusModalOpen, setIsStatusModalOpen] = useState(false);

  const toggleStatusModal = () => {
    setIsStatusModalOpen((prev) => !prev);
  };

  const [userEvent] = useMutation(ADD_USER_EVENT)
  const [insertPayment] = useMutation(INSERT_PAYMENT)

  const onCompleted = () => {
    userEvent({
      variables: {
        userEvent: {
          user_event_type_id: EUserEventsId.pay_complete,
          student_id: studentId,
          status: 'Paid',
          //invoice_id: invoiceId,
          team_id: teamId,
        }
      },
      refetchQueries: [
        {
          query: GET_INVOICE,
          variables: {
            id: invoiceId
          }
        },
        {
          query: GET_INVOICE_PAYMENTS,
          variables: {
            invoice_id: invoiceId
          }
        }
      ]
    })
    toast.success('Payment saved.')
    toggleStatusModal()
  }

  const [chargeStripeV2] = useMutation(CHARGE_STRIPE_V2, {
    onError: (err) => {
      toast.error(err.message)
    }
  })

  const manualPaymentHandler = async ({
    description,
    manual_payment,
    total,
  }) => {
    await insertPayment({
      variables: {
        paymentParams: {
          description,
          manual_payment,
          amount: total,
          total,
          currency,
          created_by: currentUser.id,
          invoice_id: invoiceId,
          processed_by: 'Manual Payment',
          status:
            manual_payment === EManualPaymentOpts.comp
              ? EPaymentStatus.comp
              : EPaymentStatus.paid,
              student_id: studentId,
              team_id: currentCoachTeam.id
        }
      },
    })

    onCompleted()
  }

  const stripeTokenHandler = async ({
    amount,
    fees,
    total,
    token,
    name,
    email,
  }) => {
    const description = `INV: ${invoiceId} - ${name}`
    await chargeStripeV2({
      variables: {
        chargeParams: {
          source: token.id,
          total,
          fees,
          description,
          amount,
          currency,
          student_id: studentId,
          invoice_id: invoiceId,
          team_id: teamId
        }
      }
    })
    onCompleted()
  }

  const CardFormik = () => {
    return (
      <CreditCardForm
        stripe={stripe}
        elements={elements}
        defaultName={defaultName}
        defaultEmail={defaultEmail}
        currency={currency}
        stripeAccountId={stripeAccountId}
        invoiceId={invoiceId}
        amount={amount}
        paymentFee={paymentFee}
        handler={stripeTokenHandler}
        />
    )
  }

  const ManualFormik = () => {
    return (
      <Formik
      enableReinitialize={true}
      initialValues={{
        amount,
        manual_payment: EManualPaymentOpts.cash,
        description: defaultEmail
      }}
      validationSchema={manualFormSchema}
      onSubmit={async (values, { setSubmitting, resetForm }) => {
        const { manual_payment, description, amount: total } = values;
        manualPaymentHandler({
          description,
          manual_payment,
          total
        })
      }}
      >
      {(formikData) =>
        ManualPaymentForm({
          ...formikData,
          currency,
          invoiceId
        })
      }
      </Formik>
    )
  }

  const statusModal = () => {
    return (
      <StatusModal
        icon={<IconCircleCheck color="green" size="60" />}
        title="Payment Successful!"
        content={`A payment has been successfully processed.`}
        isModalOpen={isModalOpen}
        toggleModal={toggleModal}
      />
    )
  }

  if (isStatusModalOpen) {
    return statusModal()
  }

  return (
    <Modal
        content={
          <>
          {isStudent && (
            <CardFormik />
          )}

          {!isStudent && (
            <TabbedCard initialTab="Credit Card">
              <Tab title="Credit Card">
                <CardFormik />
              </Tab>
              <Tab title="Manual Payment">
                <ManualFormik />
              </Tab>
            </TabbedCard>
          )}
          </>
        }
        open={isModalOpen}
        title={defaultName}
        onClose={toggleModal}
      />
  )
}


interface CreditCardFormProps {
  stripe: any
  elements: any
  defaultName: string
  defaultEmail: string
  stripeAccountId: string
  currency: string
  invoiceId: number
  amount: number
  paymentFee: number
  handler: any
}

const CreditCardForm = ({
  stripe,
  elements,
  defaultName, defaultEmail, amount,
  currency,
  paymentFee,
  handler,
  stripeAccountId,
  invoiceId,
}: CreditCardFormProps ) => {
  return(
    <Formik
      enableReinitialize={true}
      validationSchema={stripeFormSchema({
        name: '',
        email: '',
        total: amount
      })}

      initialValues={{
        name: defaultName,
        email: defaultEmail ?? '',
        total: amount
      }}

      onSubmit={async (values, { resetForm, setSubmitting }) => {
        // total could be less than the billed amount
        const { name, email, total } = values;

        if (!stripe || !elements) {
          return;
        }

        const card = elements.getElement(CardElement);
        const result = await stripe.createToken(card, { name });

        if (result.error) {
          toast.error(result.error.message);
          return;
        }

        try {
          await handler({
            token: result.token,
            amount: calculateTotalWithFees(total, paymentFee),
            fees: calculateFees(total, paymentFee),
            name,
            email,
            total,
          })
        } catch (error) {
          toast.error(error)
        }
      }}
    >
      {(formikData) =>
        StripePaymentForm({
          ...formikData,
          currency,
          invoiceId,
          paymentFee
        })
      }
    </Formik>
  )
}
export default InvoicePaymentModal;
