import { useQuery } from '@apollo/client';
import { observer } from 'mobx-react';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Card, Form, Grid, Icon, Table, Text } from 'tabler-react';

import { GET_JOB_LIST } from '../../graphql/GET_JOB_LIST';
import { formatMoney } from '../../utils/numberFormat';
import RiggerJobListCard from './RiggerJobListCard';
import RiggerJobsListItem from './RiggerJobsListItem';

const makeGroup = (date) => {
  const dateObj = moment(date);
  const bracket =
    parseInt(dateObj.format('D')) <= 15
      ? '1-15'
      : '16-' + dateObj.endOf('month').format('D');
  return `${dateObj.format('YYYY_MM')}_${bracket}`;
};
const groupByDate = (data) => {
  const sorted = data.sort((a, b) => {
    if (a?.completed_on) {
      const da = new Date(a.completed_on),
        db = new Date(b.completed_on);

      return da > db ? -1 : 1;
    }

    return 1;
  });

  return sorted.reduce(function (storage, item) {
    if (item?.completed_on) {
      const group = makeGroup(item?.completed_on);
      storage[group] = storage[group] || [];
      storage[group].push(item);
    }

    return storage;
  }, {});
};

const getCurrentCutOff = () => {
  const today = moment();
  const t_month = today.month() + 1;
  const lastMonth = moment(t_month + '-1-' + today.year());

  if (today.date() > 15) {
    return moment(t_month + '-15-' + today.year()).format('YYYY-MM-DD');
  } else {
    return lastMonth.endOf('month').format('YYYY-MM-DD');
  }
};

interface RiggerJobListProps {
  riggerId: number;
  rigger?: any;
}

const RiggerJobList = ({ riggerId, rigger }: RiggerJobListProps) => {
  const [activeJobs, setActiveJobs] = useState([]);
  const [groupedJobs, setGroupedJobs] = useState([]);
  const [viewActiveJobs, setViewActiveJobs] = useState(true);
  const [coach, setCoach] = useState(rigger ?? null);

  const { loading, data } = useQuery(GET_JOB_LIST, {
    variables: {
      jobListFilter: {
        rigger_id: riggerId,
        status: [
          'Active',
          'Open',
          'Progress',
          'Closed',
          'Invoice',
          'Parts',
          'Complete',
          'Hold'
        ],
        order: 'desc'
      }
    }
  });
  useEffect(() => {
    if (!loading && data.jobsList.length > 0) {
      const jobListData = data.jobsList;
      setCoach(jobListData[0].rigger);
      setGroupedJobs(
        groupByDate(
          jobListData.filter(
            (job) => !!job.completed_on && job.completed_on < getCurrentCutOff()
          )
        )
      );
      setActiveJobs(
        jobListData.filter(
          (job) =>
            !job.completed_on ||
            (!!job.completed_on && job.completed_on > getCurrentCutOff())
        )
      );
    }
  }, [data, loading]);

  const handleToggleStatus = () => {
    setViewActiveJobs(!viewActiveJobs);
  };
  return (
    <>
      <Card>
        <Card.Header>
          <Card.Title>
            <Icon name="briefcase" className="mr-2 ml-0 text-blue" />
            {coach ? `${coach.first_name} ${coach.last_name} ` : 'My Jobs'}
          </Card.Title>
        </Card.Header>
      </Card>

      <Grid.Row>
        <Grid.Col xs={12} sm={12} lg={3}>
          <h5>Invoices</h5>
          <Form.Group>
            <Text className="float-right text-muted pt-1">
              {Object.keys(groupedJobs)
                .slice(0, 2)
                .reduce((total, key) => {
                  return total + groupedJobs[key].length;
                }, 0) + activeJobs.length}

              <br />

              {Object.keys(groupedJobs)
                .slice(2, groupedJobs.length)
                .reduce((total, key) => {
                  return total + groupedJobs[key].length;
                }, 0)}
            </Text>
            <Form.Radio
              label="Current"
              name="status"
              checked={viewActiveJobs}
              onChange={() => handleToggleStatus()}
            />
            <Form.Radio
              label="Past"
              name="status"
              checked={!viewActiveJobs}
              onChange={() => handleToggleStatus()}
            />
          </Form.Group>
        </Grid.Col>
        <Grid.Col xs={12} sm={12} lg={9}>
          {viewActiveJobs && (
            <>
              <Card title={`Active/Pending`}>
                <Card.Body>
                  <Table>
                    <Table.Header>
                      <Table.Row>
                        <Table.ColHeader>Customer</Table.ColHeader>
                        <Table.ColHeader>Service</Table.ColHeader>
                        <Table.ColHeader>Gear</Table.ColHeader>
                        <Table.ColHeader>Status</Table.ColHeader>
                        <Table.ColHeader>Amount</Table.ColHeader>
                      </Table.Row>
                    </Table.Header>
                    <Table.Body>
                      {activeJobs?.map((job, index) => (
                        <RiggerJobsListItem job={job} key={'job' + index} />
                      ))}
                    </Table.Body>
                  </Table>
                </Card.Body>
                <Card.Footer className="mr-5 font-weight-bold">
                  <Text className="float-right">
                    Total Pending:{' '}
                    {formatMoney(
                      activeJobs.reduce((total, job) => {
                        return total + job.unit_price * job.quantity * 0.75;
                      }, 0)
                    )}
                  </Text>
                </Card.Footer>
              </Card>
              {Object.keys(groupedJobs)
                .slice(0, 2)
                .map(function (keyName, keyIndex) {
                  const jobs = groupedJobs[keyName];
                  const period = keyName.split('_');
                  return (
                    <RiggerJobListCard
                      jobs={jobs}
                      period={period}
                      keyName={keyName}
                      key={keyName}
                    />
                  );
                })}
            </>
          )}

          {!viewActiveJobs &&
            Object.keys(groupedJobs)
              .slice(2, Object.keys(groupedJobs).length)
              .map(function (keyName, keyIndex) {
                const jobs = groupedJobs[keyName];
                const period = keyName.split('_');
                return (
                  <RiggerJobListCard
                    jobs={jobs}
                    period={period}
                    keyName={keyName}
                    key={keyName}
                  />
                );
              })}
        </Grid.Col>
      </Grid.Row>
    </>
  );
};

export default observer(RiggerJobList);
