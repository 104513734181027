import React from 'react';
import { Avatar, Grid, Icon, Text } from 'tabler-react';

interface IRecentCoachListLItemProps {
  coach: any;
}

const RecentCoachListLItem = ({ coach }: IRecentCoachListLItemProps) => {
  return (
    <li className="list-separated-item">
      <Grid.Row className="align-items-center">
        <Grid.Col auto>
          <Avatar
            status={coach.registration_id && ('success')}
            size="md"
            className="d-block"
            imageURL={
              (coach.profile_avatar &&
                `/api/s3/uploads/${coach.profile_avatar}`) ||
              '//www.gravatar.com/avatar?d=mp'
            }
          />
        </Grid.Col>
        <Grid.Col>
          <div>
            {coach.first_name} {coach.last_name}
          </div>
          {coach.email && (
            <Text.Small className="d-block">
              <a href={'mailto:' + coach.email}>{coach.email}</a>
            </Text.Small>
          )}
        </Grid.Col>
        <Grid.Col auto>
          <Icon
            link={true}
            name="check"
            className={coach.is_public && 'icon-success'}
          />
        </Grid.Col>
      </Grid.Row>
    </li>
  );
};

export default RecentCoachListLItem;
