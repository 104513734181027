import gql from 'graphql-tag';

export const ADD_OR_EDIT_MEMBER_IMAGE = gql`
  mutation ADD_OR_EDIT_MEMBER_IMAGE(
    $addOrEditOrgCredentialImageInput: AddOrEditOrgCredentialImageInput!
  ) {
    addOrEditMembershipImage(
      addOrEditOrgCredentialImageInput: $addOrEditOrgCredentialImageInput
    )
  }
`;
