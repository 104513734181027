import React from 'react';
import { Card } from 'tabler-react';
import Wrapper from 'pages/layout';

const TemplatePage = (props) => {
  return (
    <Wrapper {...props} title="Template Page">
      <div className="container">
        <Card>
          <Card.Header>
            <Card.Title>Templates</Card.Title>
          </Card.Header>
          <Card.Body>

          </Card.Body>
        </Card>
      </div>
    </Wrapper>
  );
};
export default TemplatePage;
