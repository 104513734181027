import React, { useState } from 'react';
import FormField from '../../FormField';
import { Button } from 'tabler-react';

const RegistrationQuestionOption = ({ questionOption, handleAddOption }) => {
  const [questionOptionValue, setQuestionOptionValue] = useState(
    questionOption?.name
  );

  return (
    <FormField
      name={'option' + (questionOption.id ?? '')}
      placeholder="Option"
      onChange={(e) => {
        setQuestionOptionValue(e.target.value);
        if (questionOption.id)
          handleAddOption(questionOptionValue, questionOption.id);
      }}
      value={questionOptionValue}
      appendright={
        !questionOption.id && (
          <Button
            color="white"
            onClick={(e) => {
              e.preventDefault();
              handleAddOption(questionOptionValue, questionOption.id);
              if (!questionOption.id) setQuestionOptionValue('');
            }}
          >
            {questionOption.id ? 'UPDATE' : 'ADD'}
          </Button>
        )
      }
    />
  );
};

export default RegistrationQuestionOption;
