import Modal from 'components/Modal';
import UserRoleForm, {
  UserRoleFormProps
} from 'modules/user/roles/components/forms/UserRoleForm';
import React, { FC } from 'react';

interface UserRoleModalProps {
  action: UserRoleFormProps['action'];
  coachId?: UserRoleFormProps['coachId'];
  isModalOpen: boolean;
  name: string;
  studentId?: UserRoleFormProps['studentId'];
  toggleModal: () => void;
}

const UserRoleModal: FC<UserRoleModalProps> = ({
  action,
  coachId,
  isModalOpen,
  name,
  studentId,
  toggleModal
}) => {
  return (
    <Modal
      content={
        <UserRoleForm coachId={coachId} studentId={studentId} action={action} />
      }
      open={isModalOpen}
      title={name}
      onClose={toggleModal}
    />
  );
};

export default UserRoleModal;
