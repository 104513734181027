import gql from 'graphql-tag';

export const ADD_STUDENT = gql`
  mutation ADD_STUDENT($student: addStudent) {
    addStudent(student: $student) {
      id
      first_name
      last_name
      address1
      address2
      city
      country
      dob
      email
      jump_weight
      phone_number
      phone_country_code
      postal_code
      province
      registration_id
      weight
    }
  }
`;
