import React from 'react';
import Modal from '../../Modal';
import { Grid } from 'tabler-react';
import UpdateUserRegistrationOptions from '../Forms/UpdateUserRegistrationOption';

interface IUpdateUserRegistrationOptionModalProps {
  isModalOpen: any;
  toggleModal: any;
  userData: {
    teamId: number;
    campId: number;
    userRegistrationOptions: any;
    userRegistrationId: number;
    defaultJumpCount: number;
  };
}

const UpdateUserRegistrationOptionModal = ({
                                             isModalOpen,
                                             toggleModal,
                                             userData
                                           }: IUpdateUserRegistrationOptionModalProps) => {
  return (<Modal
    content={
      <Grid.Row>
        <Grid.Col>
          <UpdateUserRegistrationOptions
            teamId={userData?.teamId}
            userRegistrationId={userData?.userRegistrationId}
            defaultJumpCount={userData?.defaultJumpCount ?? 1}
            campId={Number(userData?.campId)}
            userRegistrationOptions={userData?.userRegistrationOptions}
          />
        </Grid.Col>
      </Grid.Row>
    }
    open={isModalOpen}
    title={'Update Selected Options'}
    onClose={toggleModal}
  />);
};

export default UpdateUserRegistrationOptionModal;
