import { useMutation } from "@apollo/client";

import FormField from "components/FormField";
import { EUserTypes } from "components/User/userTypes";
import { Formik } from "formik";
import { useRootStore } from "hooks/useRootStore";
import React from "react";
import { toast } from "react-toastify";
import { Button, Grid } from "tabler-react";
import * as Yup from "yup";
import { UPDATE_STUDENT_MUTATION } from "../../../graphql/UPDATE_STUDENT_MUTATION";
import { UPDATE_USER_MUTATION } from "../../../graphql/UPDATE_USER_MUTATION";

const changePasswordValidationSchema = Yup.object().shape({
  password: Yup.string()
    .required("This field is required.")
    .min(8, "Password should have at least 8 characters.")
    .max(24, "Password should not exceed 24 characters."),
  passwordConfirm: Yup.string()
    .required("This field is required.")
    .oneOf([Yup.ref("password"), null], "Passwords do not match."),
});

interface IChangePasswordFormProps {
  toggleModal?: () => void;
  isModal?: boolean;
}

const ChangePasswordForm = ({
  toggleModal,
  isModal,
}: IChangePasswordFormProps) => {
  const rootStore = useRootStore();
  const { currentUser } = rootStore;

  const [updateStudent] = useMutation(UPDATE_STUDENT_MUTATION, {
    onCompleted: () => toast.success("Password Updated."),
  });

  const [updateUser] = useMutation(UPDATE_USER_MUTATION, {
    onCompleted: () => toast.success("Password Updated."),
  });

  return (
    <Formik
      initialValues={{
        password: "",
        passwordConfirm: "",
      }}
      validationSchema={changePasswordValidationSchema}
      onSubmit={async (values, { setSubmitting, resetForm }) => {
        delete values.passwordConfirm;

        if (currentUser.type === EUserTypes.student) {
          await updateStudent({
            variables: {
              student: {
                ...values,
                id: currentUser.id,
              },
            },
          });
        } else {
          await updateUser({
            variables: {
              user: {
                id: currentUser.id,
                password: values.password,
                type: currentUser.type,
              },
            },
          });
        }

        setSubmitting(false);
        resetForm();
        if (isModal) toggleModal();
      }}
    >
      {({ isSubmitting, handleBlur, handleChange, handleSubmit }) => {
        return (
          <form className="Update-Password" onSubmit={handleSubmit}>
            <Grid.Row>
              <Grid.Col md={12} sm={12} xs={12}>
                <FormField
                  name="password"
                  label=""
                  placeholder="New Password"
                  type="password"
                  onBlur={handleBlur}
                  onChange={handleChange}
                />
              </Grid.Col>
              <Grid.Col md={12} sm={12} xs={12}>
                <FormField
                  name="passwordConfirm"
                  placeholder="Confirm New Password"
                  type="password"
                  onBlur={handleBlur}
                  onChange={handleChange}
                />
              </Grid.Col>
            </Grid.Row>
            <Button.List align="right">
              <Button
                pill
                className="btn btn-gray-dark"
                disabled={isSubmitting}
                loading={isSubmitting}
                type="submit"
                size="sm"
              >
                UPDAT{isSubmitting ? "ING" : "E"}
              </Button>
            </Button.List>
          </form>
        );
      }}
    </Formik>
  );
};

export default ChangePasswordForm;
