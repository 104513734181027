import gql from 'graphql-tag';

export const GET_SEALS = gql`
    query GET_SEALS {
        getSealList {
            id
            rigger_seal
            coach_id
            coach{
                first_name
                last_name
            }
        }
    }`;
