import React from "react";
import Modal from "../../Modal";
import AddStudentForm from "../Forms/AddStudentForm";

interface IAddStudentCoachModalProps {
  isOpen: boolean;
  handleClose: () => void;
}

const AddStudentCoachModal = ({
  isOpen,
  handleClose,
}: IAddStudentCoachModalProps) => {
  return (
    <Modal
      content={<AddStudentForm handleClose={handleClose} />}
      open={isOpen}
      onClose={handleClose}
      title="Add New Student"
    />
  );
};

export default AddStudentCoachModal;
