import React, { useContext } from 'react';
import { UserStoreContext } from '../../../contexts/userStoreContext';
import UserCard from '../../User/Profile/UserCard';
import { Button, Card, Grid, Icon } from 'tabler-react';
import { useRootStore } from '../../../hooks';

interface IStudentSignupMeritsProps {
  setStep: any;
}

const StudentSignupMerits = ({ setStep }: IStudentSignupMeritsProps) => {
  const userStore = useContext(UserStoreContext);
  const { user } = userStore;
  const rootStore = useRootStore();
  const { currentUser } = rootStore;

  return (<Grid.Row>

    {currentUser && <Grid.Col lg={4} sm={12} xs={12}><UserCard
        firstName={user?.first_name}
        lastName={user?.last_name}
        profileAvatar={user?.profile_avatar}
        profilePicture={user?.profile_picture}
      />
    </Grid.Col>}

    <Grid.Col lg={currentUser ? 8 : 12} sm={12} xs={12}>
      <Card>
        <Card.Header>
          <Card.Title>
            <Icon name='refresh-cw' className='mr-2 ml-0 text-success' />
            Sync your USPA account (optional)
          </Card.Title>
        </Card.Header>
        <Card.Body>

        </Card.Body>
      </Card>
      <Button.List align='center'>
        <Button
          color='white'
          size='lg'
          icon='chevron-left'
          onClick={() => {
            setStep(2);
          }
          }
        >
          BACK
        </Button>
        <Button
          color='gray-dark'
          size='lg'
          icon='check-circle'
          onClick={() => {
            setStep(4);
          }
          }
        >
          DONE
        </Button>
      </Button.List>
    </Grid.Col>
  </Grid.Row>);
};

export default StudentSignupMerits;
