import { observer } from 'mobx-react';
import React from 'react';
import { Card, Grid, Icon } from 'tabler-react';

import TeamProfile from './TeamProfile';

const TeamEntry = () => {
  return (
    <Grid.Row>

      <Grid.Col lg={8} sm={12}>
        <Card statusColor='blue'>
          <Card.Header className='pl-3'>
            <Card.Title>
              <Icon name='check-circle' className='mr-2 ml-0 text-secondary' />
              Create Team
            </Card.Title>
          </Card.Header>
          <TeamProfile />
        </Card>
      </Grid.Col>
    </Grid.Row>
  );
};

export default observer(TeamEntry);
