import gql from 'graphql-tag';

export const GET_ALL_ACCOUNT_TYPES = gql`
    query GET_ALL_ACCOUNT_TYPES{
        getAllAccountTypes{
            id
            slug
            name
        }
    }
`;
