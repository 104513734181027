import gql from 'graphql-tag';

export const GET_USER_CAMP_REGISTRATION = gql`
  query GET_USER_CAMP_REGISTRATION(
    $studentId: Int!, $campId: Int!
  ) {
    getUserCampRegistration(
      studentId:$studentId , campId: $campId
    ) {
      camp_id
      camp_name
      student_id
      registration_code
      status
      user_registrations_id
    }
  }
`;
