import Modal from "components/Modal";
import PaymentHistory from "components/User/PaymentHistory";
import React from "react";

interface RegistrationPaymentsModalProps {
  open: boolean;
  onClose: () => void;
  userRegistrationDetails: any;
  outstanding: number;
  totalPaid: number;
}

const RegistrationPaymentsModal: React.FC<RegistrationPaymentsModalProps> = ({
  open,
  onClose,
  userRegistrationDetails,
  outstanding,
  totalPaid,
}) => {
  return (
    <Modal
      title="Registration Payments"
      open={open}
      onClose={onClose}
      content={
        <PaymentHistory
          paymentsHistory={userRegistrationDetails.payments}
          remainingBalance={outstanding}
          totalPaid={totalPaid}
        />
      }
    />
  );
};

export default RegistrationPaymentsModal;
