import ProductsList from './ProductsList';
import * as React from 'react';
import { useContext, useEffect, useState } from 'react';
import { Form, Grid, Text } from 'tabler-react';
import { useRootStore } from '../../hooks';
import { ProductStoreContext } from '../../contexts/productContext';
import { useQuery } from '@apollo/client';
import { GET_PRODUCTS_BY_FILTER_QUERY } from '../../graphql/GET_PRODUCTS_BY_FILTER_QUERY';
import { EOrderOptions } from '../../hooks/useAutocomplete';
import { DEFAULT_PRODUCT_LIMIT } from '../constants';
import { applySnapshot } from 'mobx-state-tree';
import { observer } from 'mobx-react';

const ProductPageContent = () => {
  const { currentCoachTeam } = useRootStore();
  const [statusFilter, setStatusFilter] = useState({
    active: false,
    inactive: false,
    archived: false
  });
  const [typeFilter, setTypeFilter] = useState({
    1: false,
    7: false,
    3: false,
    2: false,
    6: false,
    5: false
  });

  const [page, setPage] = useState(0);
  const { products } = useContext(ProductStoreContext);

  const { loading, error, data, fetchMore, refetch } = useQuery(
    GET_PRODUCTS_BY_FILTER_QUERY,
    {
      variables: {
        order: EOrderOptions.alphabet,
        filter: {
          status: Object.keys(statusFilter).filter((key) => statusFilter[key]),
          product_type_id: Object.keys(typeFilter).filter((key) => typeFilter[key]).map((item) => parseInt(item)),
          team_id: currentCoachTeam.id,
          limit: DEFAULT_PRODUCT_LIMIT,
          offset: 0
        }
      }
    }
  );

  const { data: archivedProduct } = useQuery(
    GET_PRODUCTS_BY_FILTER_QUERY,
    {
      variables: {
        order: EOrderOptions.alphabet,
        filter: {
          status: 'archived',
          product_type_id: Object.keys(typeFilter).filter((key) => typeFilter[key]).map((item) => parseInt(item)),
          team_id: currentCoachTeam.id,
          limit: DEFAULT_PRODUCT_LIMIT,
          offset: 0
        }
      }
    }
  );

  useEffect(() => {
    if (!loading && data) {
      setPage(1);
      applySnapshot(products, data.products);
    }
  }, [data]);  

  if (error) {
    return <p>{error.message}</p>;
  }

  const onFetchMore = () => {
    setPage((prevNum) => prevNum + 1);
    return fetchMore({
      updateQuery: (cache, { fetchMoreResult }) => {
        if (!fetchMoreResult) {
          return cache;
        }

        return {
          ...cache,
          products: [...cache.products, ...fetchMoreResult.products]
        };
      },
      variables: {
        order: EOrderOptions.alphabet,
        filter: {
          status: Object.keys(statusFilter).filter((key) => statusFilter[key]),
          product_type_id: Object.keys(typeFilter).filter((key) => typeFilter[key]).map((item) => parseInt(item)),
          team_id: currentCoachTeam.id,
          limit: DEFAULT_PRODUCT_LIMIT,
          offset: DEFAULT_PRODUCT_LIMIT * page
        }
      }
    });
  };

  const onRefetch = async () => {
    await refetch({
      order: EOrderOptions.alphabet,
      filter: {
        team_id: currentCoachTeam.id,
        status: Object.keys(statusFilter).filter((key) => statusFilter[key]),
        product_type_id: Object.keys(typeFilter).filter((key) => typeFilter[key]).map((item) => parseInt(item)),
        limit: DEFAULT_PRODUCT_LIMIT,
        offset: 0
      }
    });
  };

  const handleStatusChange = (e) => {
    setStatusFilter({
      ...statusFilter, [e.target.name]: e.target.checked
    });

    onRefetch();
  };

  const handleTypeChange = (e) => {
    setTypeFilter({
      ...typeFilter, [e.target.value]: e.target.checked
    });

    onRefetch();
  };

  const getStatusCount = (status) => {
    const statusItem = data?.countProductsStatus.find((item) => item.status === status);

    if (!statusItem)
      return 0;

    return statusItem.status_count;
  };

  const getTypeCount = (type) => {
    const productTypeItem = data?.countProductsType.find((item) => parseInt(item.product_type_id) === type);

    if (!productTypeItem)
      return 0;

    return productTypeItem.type_count;
  };

  return (
    <Grid.Row>
      <Grid.Col xs='12' sm='12' md='12' lg='2'>
        <Form.Group>
          <Form.MaskedInput
            autoFocus
            name='search'
            placeholder='Search...'
            disabled
          />
        </Form.Group>
        <h5>Status</h5>
        <Form.Group>
          <Text className='float-right text-muted pt-1'>{getStatusCount('active')}</Text>
          <Form.Checkbox
            name='active' value={'active'}
            checked={statusFilter.active}
            label='Active'
            onChange={(e) => handleStatusChange(e)}
          />
          <Text className='float-right text-muted pt-1'>{getStatusCount('inactive')}</Text>
          <Form.Checkbox
            name='inactive' value={'inactive'}
            checked={statusFilter.inactive}
            label='Inactive'
            onChange={(e) => handleStatusChange(e)}
          />
          <Text className='float-right text-muted pt-1'>{getStatusCount('archived')}</Text>
          <Form.Checkbox
            name='archived' value={'archived'}
            checked={statusFilter.archived}
            label='Archived'
            onChange={(e) => handleStatusChange(e)}
          />
        </Form.Group>
        <h5>Type</h5>
        <Form.Group>
          <Text className='float-right text-muted pt-1'>{getTypeCount(1)}</Text>
          <Form.Checkbox
            name='account balance'
            label='Account Balance'
            value={1}
            checked={typeFilter[1]}
            onChange={(e) => handleTypeChange(e)}
          />
          <Text className='float-right text-muted pt-1'>{getTypeCount(7)}</Text>
          <Form.Checkbox
            name='product_type_7'
            label='Fee'
            value={7}
            checked={typeFilter[7]}
            onChange={(e) => handleTypeChange(e)}
          />
          <Text className='float-right text-muted pt-1'>{getTypeCount(3)}</Text>
          <Form.Checkbox
            name='product_type_3'
            label='Gift Card'
            value={3}
            checked={typeFilter[3]}
            onChange={(e) => handleTypeChange(e)}
          />
          <Text className='float-right text-muted pt-1'>{getTypeCount(2)}</Text>
          <Form.Checkbox
            name='product_type_2'
            label='Registration'
            value={2} checked={typeFilter[2]}
            onChange={(e) => handleTypeChange(e)}
          />
          <Text className='float-right text-muted pt-1'>{getTypeCount(6)}</Text>
          <Form.Checkbox
            name='product_type_6'
            label='Schedule'
            value={6}
            checked={typeFilter[6]}
            onChange={(e) => handleTypeChange(e)}
          />
          <Text className='float-right text-muted pt-1'>{getTypeCount(5)}</Text>
          <Form.Checkbox n
                         name='product_type_5'
                         label='Service'
                         value={5}
                         checked={typeFilter[5]}
                         onChange={(e) => handleTypeChange(e)}
          />
        </Form.Group>
      </Grid.Col>
      <Grid.Col lg='10'>
        <ProductsList
          products={products}
          archivedProducts={archivedProduct?.products}
          loading={loading}
          onFetchMore={onFetchMore}
          total={data?.productsTotal?.total ?? 0}
        />
      </Grid.Col>
    </Grid.Row>);
};

export default observer(ProductPageContent);
