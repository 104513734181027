import React from 'react';
import { LoadStoreProvider } from '../../../contexts/LoadStoreContext';
import Wrapper from '../../layout';
import LoadPassengerList from '../../../components/Load/LoadPassengerList';
import { Container } from 'tabler-react';

const LoadPassengerListPage = (props) => {
  return (
    <Wrapper {...props} title="Load List">
      <Container>
        <LoadStoreProvider>
          <LoadPassengerList />
        </LoadStoreProvider>
      </Container>
    </Wrapper>
  );
};

export default LoadPassengerListPage;
