import { observer } from 'mobx-react';
import React from 'react';
import ReactS3Uploader from 'react-s3-uploader';
import { Button, Dropdown } from 'tabler-react';

import { useRootStore } from '../../../hooks/useRootStore';

export enum ETeamImageType {
  team_favicon = 'team_favicon',
  team_logo_header = 'team_logo_header',
  team_logo_wide_image = 'team_logo_wide_image',
  team_logo_square_image = 'team_logo_square_image',
  team_promo_image = 'team_promo_image'
}

interface ITeamCardDropdownProps {
  imageName?: string;
  imageType: ETeamImageType;
  onErrorHandler: (error, imageType: ETeamImageType) => void;
  onFinishedHandler: (response, imageType: ETeamImageType) => void;
  callBack: (_, imageTYpe: ETeamImageType) => void;
}

const TeamCardDropdown = ({
  imageName,
  imageType,
  onErrorHandler,
  onFinishedHandler,
  callBack
}: ITeamCardDropdownProps) => {
  const rootStore = useRootStore();

  return (
    <>
      {rootStore.loading ? (
        <Button
          loading
          color="secondary"
          className="border-0 p-1 shadow-none"
          block
        >
          Loading...
        </Button>
      ) : (
        <label
          htmlFor="s3-upload"
          className="dropdown-item cursor-pointer mb-0"
        >
          {imageName ? 'Change' : 'Upload'}
        </label>
      )}
      <ReactS3Uploader
        accept="image/*"
        autoUpload={true}
        className="d-none"
        contentDisposition="auto"
        id="s3-upload"
        signingUrl="/api/s3/sign"
        onError={(err) => onErrorHandler(err, imageType)}
        onFinish={(response) => onFinishedHandler(response, imageType)}
        onProgress={() => rootStore.setLoading(true)}
        uploadRequestHeaders={{
          'x-amz-acl': 'private'
        }}
      />
      {imageName && (
        <Dropdown.Item
          to="#!"
          onClick={() => {
            callBack(null, imageType);
          }}
        >
          Remove
        </Dropdown.Item>
      )}
    </>
  ) as JSX.Element;
};

export default observer(TeamCardDropdown);
