import { useMutation } from "@apollo/client";
import { Formik } from "formik";
import React from "react";
import PhoneInput from "react-phone-input-2";
import Select from "react-select";
import { toast } from "react-toastify";
import { Button, Card, Form, Grid, Icon } from "tabler-react";
import useReactRouter from "use-react-router";
import * as Yup from "yup";
import { ADD_LOCATION } from "../../../graphql/ADD_LOCATION";
import autocompleteStyles from "../../../modules/common/styles/autocompleteStyles";
import {
  countries,
  locationTypeOptions,
  objectTypeOptions,
} from "../../constants";
import FormField from "../../FormField";

const newLocationValidationSchema = Yup.object().shape({
  name: Yup.string().required("This field is required."),
  description: Yup.string().required("This field is required."),
  type: Yup.string().required("This field is required."),
});

function makeid(length) {
  let result = "";
  const characters = "abcdefghijklmnopqrstuvwxyz0123456789";
  const charactersLength = characters.length;
  let counter = 0;
  while (counter < length) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
    counter += 1;
  }
  return result;
}

interface NewLocationFormProps {
  toggle?: () => void;
}

const NewLocationForm = ({ toggle }: NewLocationFormProps) => {
  const { history } = useReactRouter();
  const [addLocation] = useMutation(ADD_LOCATION, {
    refetchQueries: ["GET_LOCATIONS", "GET_LOCATIONS_BY_FILTER_QUERY"],
  });
  const renderForm = ({
    values,
    handleSubmit,
    setFieldValue,
    handleChange,
    errors,
    touched,
    isSubmitting,
  }) => {
    return (
      <form onSubmit={handleSubmit}>
        <Card.Header className="pl-3">
          <Card.Title>
            <Icon name="map-pin" className={`mr-2 ml-0 text-success`} />
          </Card.Title>
          <Card.Options>
            <Form.Group className="mb-0 mt-2">
              <Form.Switch
                type="checkbox"
                name="is_frozen"
                onChange={(e) => setFieldValue("is_frozen", e.target.checked)}
                checked={values.is_frozen}
                value={values.is_frozen}
                label="Frozen"
              />
            </Form.Group>
          </Card.Options>
        </Card.Header>
        <Card.Body>
          <Grid.Row>
            <Grid.Col>
              <Form.Group label="Name">
                <Form.Input
                  name="name"
                  onChange={handleChange}
                  placeholder="Name"
                  value={values.name}
                />
                <span className="field-error text-danger">
                  {errors.name && touched.name && errors.name}
                </span>
              </Form.Group>
            </Grid.Col>
          </Grid.Row>
          <Grid.Row>
            <Grid.Col>
              <Form.Group label="Description">
                <Form.Textarea
                  name="description"
                  onChange={handleChange}
                  placeholder="description"
                  rows={8}
                  value={values.description || ""}
                />
                <span className="field-error text-danger">
                  {errors.description &&
                    touched.description &&
                    errors.description}
                </span>
              </Form.Group>
            </Grid.Col>
          </Grid.Row>
          <Grid.Row>
            <Grid.Col>
              <FormField
                label="Email"
                placeholder="Email"
                name="email"
                type="text"
                value={values.email}
                onChange={handleChange}
              />
            </Grid.Col>
            <Grid.Col>
              <Form.Group label="Phone number">
                <PhoneInput
                  inputProps={{
                    className: "form-control w-100",
                    name: "phone",
                    required: true,

                    onChange: (e) => {
                      e.preventDefault();
                      const target = e.target;
                      const phone = target.value.split(" ");
                      const countryCode = phone.shift().replace(/\D/g, "");
                      const phoneNumber = phone.join("").replace(/\D/g, "");
                      setFieldValue("phone", phoneNumber);
                      setFieldValue("phone_country_code", countryCode);
                    },
                  }}
                  value={`${values.phone_country_code}${values.phone}`}
                />
              </Form.Group>
            </Grid.Col>
          </Grid.Row>
          <Grid.Row>
            <Grid.Col>
              <FormField
                label="URL"
                placeholder="URL"
                name="url"
                type="text"
                value={values.url}
                onChange={handleChange}
              />
            </Grid.Col>
          </Grid.Row>
          <Grid.Row>
            <Grid.Col>
              <FormField
                label="Address"
                placeholder="Address"
                name="address"
                type="text"
                value={values.address}
                onChange={handleChange}
              />
            </Grid.Col>
            <Grid.Col>
              <FormField
                label="City"
                placeholder="City"
                name="city"
                type="text"
                value={values.city}
                onChange={handleChange}
              />
            </Grid.Col>
          </Grid.Row>
          <Grid.Row>
            <Grid.Col lg="6" sm="12">
              <FormField
                label="Province"
                placeholder="Province"
                name="province"
                type="text"
                value={values.province}
                onChange={handleChange}
              />
            </Grid.Col>
            <Grid.Col lg="3" sm="6">
              <FormField
                label="Postal code"
                placeholder="Postal code"
                name="postal_code"
                type="text"
                value={values.postal_code}
                onChange={handleChange}
              />
            </Grid.Col>
          </Grid.Row>
          <Grid.Row>
            <Grid.Col lg="6" sm="12">
              <Form.Group>
                <Form.Label>Country</Form.Label>
                <Select
                  backspaceRemovesValue={true}
                  escapeClearsValue={true}
                  getOptionLabel={(option: { name: string }) => option.name}
                  getOptionValue={(option: { name: string }) => option.name}
                  isClearable={true}
                  name="country"
                  options={countries}
                  onChange={(e) => setFieldValue("country", e.name)}
                  styles={autocompleteStyles}
                  value={
                    values.country &&
                    countries[
                      Object.keys(countries).find(
                        (key) => countries[key].name === values.country,
                      )
                    ]
                  }
                />
              </Form.Group>
            </Grid.Col>
          </Grid.Row>
          <Grid.Row>
            <Grid.Col lg="6" sm="12">
              <FormField
                label="Region"
                placeholder="Region"
                name="region"
                type="text"
                value={values.region}
                onChange={handleChange}
              />
            </Grid.Col>
            <Grid.Col lg="6" sm="12">
              <FormField
                label="Locality"
                placeholder="Locality"
                name="locality"
                type="text"
                value={values.locality}
                onChange={handleChange}
              />
            </Grid.Col>
          </Grid.Row>
          <Grid.Row className="mt-7">
            <Grid.Col lg="3" sm="6">
              <FormField
                label="Latitude"
                placeholder=""
                name="latitude"
                type="number"
                value={values.latitude}
                onChange={(e) => {
                  setFieldValue("latitude", e.target.value);
                }}
              />
            </Grid.Col>
            <Grid.Col lg="3" sm="6">
              <FormField
                label="Longitude"
                placeholder=""
                name="longitude"
                type="number"
                value={values.longitude}
                onChange={(e) => {
                  setFieldValue("longitude", e.target.value);
                }}
              />
            </Grid.Col>
          </Grid.Row>
          <Grid.Row className="mt-7">
            <Grid.Col lg="3" sm="6">
              <Form.Group label="Type">
                <Select
                  name="type"
                  styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
                  menuPortalTarget={document.body}
                  options={locationTypeOptions}
                  onChange={(value) => setFieldValue("type", value.value)}
                  value={
                    values.type &&
                    locationTypeOptions[
                      Object.keys(locationTypeOptions).find(
                        (key) => locationTypeOptions[key].value === values.type,
                      )
                    ]
                  }
                />
              </Form.Group>
            </Grid.Col>
            {values.type === "base" && (
              <Grid.Col lg="3" sm="6">
                <Form.Group label="Object type">
                  <Select
                    styles={{
                      menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                    }}
                    menuPortalTarget={document.body}
                    name="object_type"
                    options={objectTypeOptions}
                    onChange={(value) =>
                      setFieldValue("object_type", value.value)
                    }
                    value={
                      values.object_type &&
                      objectTypeOptions[
                        Object.keys(objectTypeOptions).find(
                          (key) =>
                            objectTypeOptions[key].value === values.object_type,
                        )
                      ]
                    }
                  />
                </Form.Group>
              </Grid.Col>
            )}
          </Grid.Row>
          {values.type === "tunnel" && (
            <Grid.Row>
              <Grid.Col>
                <FormField
                  label="Tunnel type"
                  placeholder="Tunnel type"
                  name="tunnel_type"
                  type="text"
                  value={values.tunnel_type}
                  onChange={handleChange}
                />
              </Grid.Col>
              <Grid.Col>
                <FormField
                  label="Manufacturer"
                  placeholder="Manufacturer"
                  name="manufacturer"
                  type="text"
                  value={values.manufacturer}
                  onChange={handleChange}
                />
              </Grid.Col>
              <Grid.Col>
                <FormField
                  label="Chamber diameter"
                  placeholder="Chamber diameter"
                  name="chamber_diameter"
                  type="number"
                  value={values.chamber_diameter}
                  onChange={(e) =>
                    setFieldValue(
                      "chamber_diameter",
                      parseFloat(e.target.value),
                    )
                  }
                />
              </Grid.Col>
              <Grid.Col>
                <FormField
                  label="Chamber height"
                  placeholder="Chamber height"
                  name="chamber_height"
                  type="number"
                  value={values.chamber_height}
                  onChange={(e) =>
                    setFieldValue("chamber_height", parseFloat(e.target.value))
                  }
                />
              </Grid.Col>
              <Grid.Col>
                <Form.Group label="Open since">
                  <input
                    className="form-control"
                    type="date"
                    name="open_since"
                    value={values.open_since}
                    onChange={handleChange}
                    required={true}
                  />
                </Form.Group>
              </Grid.Col>
            </Grid.Row>
          )}
        </Card.Body>
        <Card.Footer>
          <Button.List align="right">
            <Button
              pill
              color="white"
              size="sm"
              onClick={(e) => {
                e.preventDefault();
                history.goBack();
              }}
            >
              CANCEL
            </Button>
            <Button
              disabled={isSubmitting}
              loading={isSubmitting}
              pill
              color="gray-dark"
              size="sm"
              type="submit"
            >
              SUBMIT
            </Button>
          </Button.List>
        </Card.Footer>
      </form>
    );
  };

  return (
    <Formik
      enableReinitialize={true}
      validationSchema={newLocationValidationSchema}
      initialValues={{
        key: makeid(40),
        name: "",
        description: "",
        phone: "",
        phone_country_code: "1",
        email: "",
        address: "",
        country: "",
        city: "",
        province: "",
        postal_code: "",
        locality: "",
        region: "",
        latitude: "",
        longitude: "",
        is_frozen: false,
        type: "",
        object_type: "",
        tunnel_type: "",
        manufacturer: "",
        chamber_diameter: undefined,
        chamber_height: undefined,
        open_since: null,
        url: "",
      }}
      onSubmit={async (values, { resetForm }) => {
        const { longitude, latitude, ...rest } = values;
        const newLocation = await addLocation({
          variables: {
            location: {
              ...rest,
              longitude: Number(longitude),
              latitude: Number(latitude),
            },
          },
        });

        if (newLocation.data.addLocation?.id && !newLocation.errors) {
          toast.success("Location added successfully");
          resetForm();
          if (toggle) {
            toggle();
          }
        } else {
          toast.error("Error adding location");
        }
      }}
    >
      {(formData) => renderForm(formData)}
    </Formik>
  );
};

export default NewLocationForm;
