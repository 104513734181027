import React from 'react';
import Modal from '../../Modal';
import AddAircraftForm from '../Form/AddAircraftForm';

interface AddTeamAircraftModalProps {
  open: boolean;
  onClose: () => void;
  aircraft?: any;
  teamId: number;
}

const AddTeamAircraftModal = ({
  open,
  onClose,
  aircraft,
  teamId
}: AddTeamAircraftModalProps) => {
  const closeModal = () => {
    onClose();
  };
  return (
    <Modal
      content={
        <AddAircraftForm
          closeModal={closeModal}
          teamId={teamId}
          aircraft={aircraft}
        />
      }
      open={open}
      onClose={onClose}
      title={aircraft?.name ? aircraft?.name : "Add Aircraft"}
    />
  );
};

export default AddTeamAircraftModal;
