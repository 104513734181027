import Modal from 'components/Modal'
import React from 'react'
import { Button } from 'tabler-react'

type ConfirmationModalProps = {
  confirmText?: string
  content: React.ReactNode
  open: boolean
  isSubmitting: boolean
  title?: string
  onClose: () => void
  onConfirm: () => void
}

const ConfirmationModal = ({
  confirmText,
  content,
  open,
  isSubmitting,
  title,
  onClose,
  onConfirm
}: ConfirmationModalProps) => {
  return (
    <Modal
      content={content}
      open={open}
      onClose={onClose}
      title={title ?? 'Confirm'}
      actions={
        <>
          <Button color="white" className="" onClick={onClose}>
            CANCEL
          </Button>
          <Button
            className="btn btn-primary"
            disabled={isSubmitting}
            onClick={onConfirm}
          >
            {isSubmitting ? 'Loading...' : confirmText ?? 'CONFIRM'}
          </Button>
        </>
      }
    />
  )
}

export default ConfirmationModal
